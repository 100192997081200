import { getAuth } from "firebase/auth";
import { getFirestore, addDoc, doc, setDoc, collection, deleteDoc, getDoc, query, getDocs, updateDoc, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { FirestoreActions } from "./FirestoreActions"
import { PostSystem } from "./PostSystem"
import { AuthSystem } from "./AuthSystem"

export class WallSystem {
  constructor(app) {
    this.auth = getAuth(app);
    this.firestore = getFirestore(app);
    this.storage = getStorage(app);

    this.loadedLevelSystem = null;
  }

  async createAWall(wallID, wallName, owner = "", gridWidth = 256, gridHeight = 256, totalColumns = 100, totalRows = 4,  isPublic = false, isHorizontal = true) {
    const sPath = `boards/${wallID}/columns`
    await this.uploadFireStoreInfoData(wallID, wallName, owner, gridWidth, gridHeight, totalColumns, totalRows, isPublic, isHorizontal) 				// Firestore: Create a new document under "boards" collection
    await this.createFireStoreSubcollections(wallID, isPublic)
  }

  async uploadFireStoreInfoData(wallID, displayName, owner = "", gridWidth, gridHeight, totalColumns, totalRows, isPublic, isHorizontal) {
    const realm = isPublic ? "public" : "users";
    const wallDocRef = doc(this.firestore, "boards", wallID);
    await setDoc(wallDocRef, {
      owner: owner,
      displayName: displayName,
      description: "...",
      previewImage: "https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fcity4_160x160.png?alt=media&token=cd3fad47-ef7c-405b-acb0-64af6bc27b05",
      createdData: Date.now(),
      likes: 0,
      views: 0,
      spentLikes: 0,
      spentViews: 0,
      isHorizontal: isHorizontal,
      realm: realm,
      gridWidth: gridWidth,
      gridHeight: gridHeight,
      totalRows: totalRows,
      totalColumns: totalColumns,
      fenceMinLength: 128,
      fenceMaxLength: 6400,
    });
  }

  async updataWallParts(wallID, realm, place, imageFile) {
    const partRef = ref(this.storage, `/boards/${wallID}/displaySetting/${place}`);
    await uploadBytes(partRef, imageFile);
    const url = await getDownloadURL(partRef);
    return url;
  }

  async updateWallData(wallID, updateData) {
    const wallDocRef = doc(this.firestore, "boards", wallID);
    await updateDoc(wallDocRef, updateData);
  }

  // no need for firebase.
  // create the placeholder for subcollections.
  async createFireStoreSubcollections(wallID, isPublic) {
    const peopleRef = collection(this.firestore, "boards", wallID, "people");
    const ownerID = isPublic ? "system" : wallID;
    setDoc(doc(peopleRef, ownerID), {
      userID: ownerID,
      roleID: "owner",
      power: 99999,
    })
  }

  defaultRole = {
    userID: "none",
    roleID: "none",
    // displayName: "wonderer",
    power: 0,
  }

  async getUserRole(wallID, userID = "") {
    if (!userID) {
      return {...this.defaultRole}
    }
    const userRole = await FirestoreActions.static_fetchFromFirestore(`boards/${wallID}/people/${userID}`, "userID"); // get use' role id
    if (userRole) {
      return userRole
    }
    return {
      userID: userID,
      roleID: "none",
      // displayName: "wonderer",
      power: 0,
    }
  }


  powerToFence(power) {
    if (power > 9999) {
      return [999999999, 999999999];
    }
    return [power + 1, power * 640];
  }

  // userRole is obtained by getUserRole()
  // fence: {creatorID, start, end, createdDate, protectLevel}
  async uploadFence(wallID, userData, userRole, fenceStart, fenceEnd) {
    const userID = userRole.userID;
    const power = userRole.power;
    // const username = userData.username;
    if (userID === -1) {
      return "Cannot upload fence";
    }

    const userFences = await this.loadFenceByUser(wallID, userID);
    const numberOfFences = userFences.length;
    const newFenceLength = fenceEnd - fenceStart;
    let totalFencesLength = 0;
    for (let fence of userFences) {
      totalFencesLength += (fence.end - fence.start);
    }

    const [fenceAmount, fenceLength] = this.powerToFence(power);

    if (numberOfFences >= fenceAmount) {
      return `can't place more fences. You can only place ${fenceAmount} piece of fences`
    }
    if (totalFencesLength + newFenceLength >= fenceLength) {
      return `can't place more fences. You have reached your total fence length limit of ${fenceLength}`
    }

    const newFence = {
      creatorID: userID,
      // username,
      rules: 7, // allow self drawing, self adding canvases, self posting.
      start: fenceStart,
      end: fenceEnd,
      createdDate: Date.now(),
      protectLevel: userRole.power
    }
    const fenceRef = collection(this.firestore, "boards", wallID, "fences");
    const docRef = await addDoc(fenceRef, newFence);
    newFence.fenceID = docRef.id;
    return newFence;
  }

  async deleteAFence(wallID, userData, userRole, fenceData) {
    if (fenceData.creatorID !== userData.userID) {
      if (fenceData.protectLevel >= userRole.power) {
        return false;
      }
    }
    // deletion allowed
    const fenceDocRef = doc(this.firestore, `boards/${wallID}/fences/${fenceData.fenceID}`);
    await deleteDoc(fenceDocRef);
    return true; // need more verification here.
  }

  async loadFenceByUser(wallID, userID) {
    const fences = [];
    try {
      const parentDocRef = doc(this.firestore, "boards", wallID);
      const collectionRef = collection(parentDocRef, "fences");
      const collectionQuery = query(
        collectionRef,
        where('creatorID', '==', userID)
      );
      const snapshot = await getDocs(collectionQuery);
      snapshot.forEach(doc => {
        fences.push({ fenceID: doc.id, ...doc.data() });
      });
    } catch (error) {
      console.error("Error fetching fences: ", error);
    }
    return fences;
  }

  async loadFenceByRange(wallData, loadMinCoord, loadMaxCoord) {
    const wallID = wallData["wallID"]
    const fences = [];
    try {
      const parentDocRef = doc(this.firestore, "boards", wallID);
      const collectionRef = collection(parentDocRef, "fences");
      const collectionQuery = query(
        collectionRef,
        where('start', '<=', loadMaxCoord),
        where('start', '>=', loadMinCoord - wallData["fenceMaxLength"])
      );
      const snapshot = await getDocs(collectionQuery);

      snapshot.forEach(doc => {
        fences.push({ fenceID: doc.id, ...doc.data() });
      });
    } catch (error) {
      console.error("Error fetching fences:", error);
    }
    return fences;
  }

  async getAllPostsOfUser(userID) {
    const postsQuery = query(collection(this.firestore, `users/${userID}/posts`));
    const querySnapshot = await getDocs(postsQuery);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }

  // does not enforce the boundary check
  loadImageURL(storageWallColumnsPath, row, column) {
    return new Promise((resolve, reject) => {
      const imagePath = storageWallColumnsPath + `/grid_${row}_${column}`;
      // Get the download URL from Firebase Storage
      getDownloadURL(ref(this.storage, imagePath))
        .then((url) => {
          resolve(url);
        })
        .catch((error) => {
          resolve('none');
        });
    });
  };

  async getMemberInfoOfWall(wallID) {
    const owner = FirestoreActions.static_fetchFromFirestore(`boards/${wallID}`)
    const blockedList = FirestoreActions.static_fetchFromFirestore(`boards/${wallID}/blockedList`)
    const members = FirestoreActions.static_fetchFromFirestore(`boards/${wallID}/members`)
    await Promise.all([
      owner,
      blockedList, 	// Create a folder for customization and upload a dummy file
      members
    ]);

    return {
      "owner": owner.owner,
      "blockedList": blockedList,
      "members": members
    }
  }

  async getWallContentMarkers(wallID, coordinate, range) {
    await FirestoreActions.static_fetchFromFirestore(`boards/${wallID}/contents`);
  }

  async getWallPosts(wallID, coordinate, range) {
    await FirestoreActions.static_fetchFromFirestore(`boards/${wallID}/posts`);
  }
}

async function createImageBitmap(imageData) {
  const canvas = document.createElement("canvas");
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  const ctx = canvas.getContext("2d");
  ctx.putImageData(imageData, 0, 0);
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject("Failed to create an empty image");
      }
    }, "image/png");
  });
}