import React, { useState, useContext, useEffect } from 'react';
import { SystemOperationsContext } from "../../context/SystemRunnerContext";
import { searchInMarks } from '../../home_page_components/BoardSearch.jsx';
// import './styles/ContentList.scss';

export const MarkList = () => {
  const { system_operations } = useContext(SystemOperationsContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [selection, setSelection] = useState(null);
  const [marks, setMarks] = useState([])

  useEffect(() => {
    searchInMarks(system_operations.coordinator.getWallID(), "").then((results) => {
      setMarks(results);
    })
  }, [])

  const handleJump = async (mark) => {
    let jumpTo = 0;
    if (mark !== null) {
      const isHorizontal = system_operations.coordinator.isHorizontal;
      if (mark.baseType === "fc") {
        const canvas = await system_operations.firestoreActions.fetchFromFirestore(`boards/${system_operations.coordinator.getWallID()}/canvases/${mark.baseID}`, "canvasID");
        jumpTo = isHorizontal ? canvas.x : canvas.y;
      } else if (mark.baseType === "pi") {
        const post = await system_operations.firestoreActions.fetchFromFirestore(`boards/${system_operations.coordinator.getWallID()}/posts/${mark.baseID}`, "postID");
        jumpTo = isHorizontal ? post.x : post.y;
      } else if (mark.baseType === "none" && mark.coord) {
        jumpTo = mark.coord;
      }
      // jumpTo = Math.max(Math.min(jumpTo-(isHorizontal? window.innerWidth : window.innerHeight)/2 ,system_operations.coordinator.pixelBoundary[1]), 0);
      jumpTo = Math.max(Math.min(jumpTo, system_operations.coordinator.pixelBoundary[1]), 0);

      if (jumpTo || jumpTo === 0) {
        system_operations.coordinator.setGlobalOffsetTheo(jumpTo, true);
      } else {
        system_operations.enqueueSnackbarHook("The content mark' base is deleted...", "error");
      }
    }
  }

  const handleSelect = (mark) => {
    setSelection(mark);
  }

  const handleSearchKeyDone = (e) => {
    if (e.key === 'Enter') {
      searchInMarks(system_operations.coordinator.getWallID(), searchTerm).then((results) => {
        setMarks(results);
      })
    }
  }

  const handleSearchButtonClick = () => {
    searchInMarks(system_operations.coordinator.getWallID(), searchTerm).then((results) => {
      setMarks(results);
    })
  }

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const normalStyle = {
    boxSizing: 'border-box',
    borderRadius: "5px",
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#e0e0e0",
    border: '2px solid #e0e0e0',
    cursor: "pointer",
    fontSize: "14px",
    textAlign: "center",
    height: "20px",
    width: "100%",
  };
  
  const hoverStyle = {
    boxSizing: 'border-box',
    borderRadius: "5px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#ffffff",
    border: '2px solid #ffffff',
    cursor: "pointer",
    fontSize: "14px",
    textAlign: "center",
    height: "20px",
    width: "100%",
  };

  return (
    <div style={{marginTop: "5px"}}>
      <div style={{ display: 'flex', width: '100%', marginBottom: "5px" }}>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchInputChange}
          onKeyDown={handleSearchKeyDone}
          style={{ flexGrow: 1, width: "calc(100% - 50px)", marginRight: '5px' }}
        />
        <button className="search-button" onClick={handleSearchButtonClick}>
          {"GO"}
        </button>
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        border: '2px solid #bbbbbb',
        boxSizing: 'border-box',
        maxHeight: "600px",
        border: '2px solid #bbbbbb', borderRadius: "5px", padding: "2px",
      }}>
        {marks.map((mark, index) => (
          <ContentMarkButton
            mark={mark}
            handleJump={handleJump}
            normalStyle={normalStyle} hoverStyle={hoverStyle}
            key={index}
          >
            {mark.title}
          </ContentMarkButton>
        ))}
      </div>
    </div>
  );
};


const ContentMarkButton = ({ children, mark, handleJump, normalStyle, hoverStyle }) => {
  const [hover, setHover] = useState(false);
  return (
    <button
      onMouseEnter={() => { setHover(true); }}
      onMouseLeave={() => { setHover(false); }}
      onClick={() => { handleJump(mark) }}
      style={hover ? hoverStyle : normalStyle}
    >
      {children}
    </button>
  );
}

export default MarkList;