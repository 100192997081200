import React, { useRef, useEffect } from 'react';

function MediaPlayer({ mediaType, mediaUrl, maxWidthx = "100%", maxHeight = "100%" }) {
  const mediaRef = useRef(null);
  
  useEffect(() => {
    if (mediaRef.current) {
      mediaRef.current.src = mediaUrl;
    }
  }, [mediaUrl]);
  
  const handlePlayPause = () => {
    if (mediaRef.current.paused) {
      mediaRef.current.play();
    } else {
      mediaRef.current.pause();
    }
  };
  
  const handleRestart = () => {
    mediaRef.current.currentTime = 0;
    mediaRef.current.play();
  };
  
  const handleDoubleClick = () => {
    if (mediaRef.current.requestFullscreen) {
      mediaRef.current.requestFullscreen();
    } else if (mediaRef.current.mozRequestFullScreen) { 
      mediaRef.current.mozRequestFullScreen(); 
    } else if (mediaRef.current.webkitRequestFullscreen) { 
      mediaRef.current.webkitRequestFullscreen();
    } else if (mediaRef.current.msRequestFullscreen) { 
      mediaRef.current.msRequestFullscreen();
    }
  };

  return (
    <div>
      {mediaType === "audio" &&     
        <audio style={{ width: maxWidthx }} ref={mediaRef} controls onDoubleClick={handleDoubleClick} />
      }
      {/*video can't just use maxWidth as width(also height) because there is a prefered size set by the system. however, audio bar can be as long as you want it.*/}
      {mediaType === "video" && 
        <video style={{ maxWidth: maxWidthx, maxHeight: maxHeight }} ref={mediaRef} controls onDoubleClick={handleDoubleClick} />
      }
    </div>
  );
}

export default MediaPlayer;