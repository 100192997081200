import React, { useState, useRef, useContext, useEffect } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { UserShortCardToolTip } from "../components/UserDisplay";
import ButtonGroups from "../cross_page_components/ButtonGroups.jsx";
import { FloatingCanvasText } from '../components/FloatingCanvasText.jsx';
import { ContentMarkPrompt } from '../components/ContentMarkPrompt.jsx';
import { ActionButtonControlPanel } from '../components/ActionButtonControlPanel.jsx';
import { RequestDataMaker } from '../system/RestServerConnector.js';

import { SketchPicker } from 'react-color';

import { useTheme } from '@mui/system';
import { IconButton, Badge } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import PaletteIcon from '@mui/icons-material/Palette';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import FlipToFrontRoundedIcon from '@mui/icons-material/FlipToFrontRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import ImageNotSupportedRoundedIcon from '@mui/icons-material/ImageNotSupportedRounded';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import TitleIcon from '@mui/icons-material/Title';
import SettingsIcon from '@mui/icons-material/Settings';
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded';
import BrowserNotSupportedRoundedIcon from '@mui/icons-material/BrowserNotSupportedRounded';
import Divider from '@mui/material/Divider';
import { DialogTitle } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import PhotoFilterRoundedIcon from '@mui/icons-material/PhotoFilterRounded';
import AutoAwesomeMotionRoundedIcon from '@mui/icons-material/AutoAwesomeMotionRounded';


export const FloatingCanvasReactionBar = ({ canvasRect, editingMode, focusOnMode, tagAttributes = {}, presistData = {} }) => {
  const canvasData = canvasRect.getData();
  const { system_operations } = useContext(SystemOperationsContext);
  const [creator, setCreator] = useState({ username: canvasData.creatorID, avatar: "none" });
  const [liked, setLiked] = useState(false);
  const [isMarked, setIsMarked] = useState(canvasData.marked);
  const [interactable, setInteractable] = useState(canvasRect.interactable);
  const [extensionConsoleOn, setExtensionConsoleOn] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    system_operations.coordinator.likeSystem.checkLiked("canvases", system_operations.coordinator.getWallID(), canvasData.canvasID).then((likedByUser) => {
      if (likedByUser !== liked) {
        setLiked(likedByUser);
      }
    });
  }, [])

  const deletionWarningButtons = useRef({
    save: {
      name: 'Yes',
      color: "white",
      backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
      checkDisable: () => { return false },
      onClick: async () => {
        system_operations.set_loading(true);
        await system_operations.coordinator.pendingCanvasSystem.deleteAPendingItem(canvasRect);
        system_operations.set_loading(false);
        system_operations.setContentWindow(null)
      },
    },
    dump: {
      name: "No",
      color: "white",
      backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
      checkDisable: () => { return false },
      onClick: async () => { system_operations.setContentWindow(null) },
    }
  });

  useEffect(() => {
    system_operations.usersData.getUserDataByID(canvasData.creatorID).then((userData) => {
      setCreator(userData);
    })
  }, []);

  const deleteWarning = () => {
    system_operations.setContentWindow((
      <div className='float-container'>
        Delete The Sticker Canvas?
        <ButtonGroups buttons={deletionWarningButtons.current} />
      </div>
    ));
  }

  const likeButtonClicked = () => {
    if (!liked) {
      setLiked(true);
      system_operations.coordinator.likeSystem.likeAFloatingCanvas("canvases", canvasData.canvasID);
    } else {
      setLiked(false);
      system_operations.coordinator.likeSystem.dislikeAFloatingCanvas("canvases", canvasData.canvasID);
    }
  }

  const triggerBackgroundUploadInput = () => {
    if (presistData?.backgroundImageUploadRef?.current) { presistData.backgroundImageUploadRef.current.click(); }
  }

  const moveLayerUpOrDown = (up) => {
    canvasRect.moveLayerUpOrDown(up); // if success, it will handle the rest.
    if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
    if (canvasRect.func?.rectRefresh) { canvasRect.func.rectRefresh(); }
  }

  const setTextMode = async () => {
    if (canvasData.showText) {
      canvasData.showText = false;

      const dataJson = await RequestDataMaker.dataToUpdateFloatingCanvas(system_operations, canvasData.canvasID, { showText: false })
      await system_operations.restServerConnector.requestToUpdateFloatingCanvas(dataJson);
    } else {
      canvasData.showText = true;
      system_operations.setContentWindow((<FloatingCanvasText canvasRect={canvasRect} />));
      const dataJson = await RequestDataMaker.dataToUpdateFloatingCanvas(system_operations, canvasData.canvasID, { showText: true })
      await system_operations.restServerConnector.requestToUpdateFloatingCanvas(dataJson);

    }
    if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
  }

  const openContentMarkWindow = async () => {
    const contentMarker = system_operations.coordinator.sectionData.getItemFromContentMark(`canvas_${canvasData.canvasID}`)
    if (!contentMarker) {
      system_operations.setContentWindow(<ContentMarkPrompt rectRef={canvasRect} baseType="fc" />);
    } else {
      system_operations.set_loading(true);
      system_operations.firestoreActions.removeDocumentAt(`boards/${system_operations.coordinator.getWallID()}/marks/canvas_${canvasData.canvasID}`);
      // system_operations.coordinator.sectionData.deleteItemFromContentMarkLoader(contentMarker);
      setIsMarked(false);
      system_operations.set_loading(false);
    }
  }

  const changeInteractive = () => {
    const willBeInteractable = !interactable;
    setInteractable(willBeInteractable);
    canvasRect.func.setInteractable(willBeInteractable);
  }

  let mainConsole = null;
  const isOwner = canvasData.creatorID === system_operations.getUserID()

  if (isOwner && editingMode) {
    mainConsole = (
      <div style={{
        display: 'inline-flex', alignItems: 'center', paddingRight: 5,
        backgroundColor: theme.backgroundColor.wallPageUIBase, borderRadius: theme.borderRadius.wallPageUI,
      }}>
        <div style={{
          display: 'inline-flex'
        }}>
          {!canvasRect.isNotUploaded &&
            <>
              <IconButton {...tagAttributes} color="primary" onClick={() => triggerBackgroundUploadInput()}>
                <ImageRoundedIcon />
              </IconButton>
              {
                canvasData.hasBackground &&
                <IconButton {...tagAttributes} color="primary" onClick={() => presistData.clearBackgroundImage()}>
                  <ImageNotSupportedRoundedIcon />
                </IconButton>
              }
            </>
          }
          {
            canvasData.creatorID === system_operations.getUserID() &&
            <IconButton color="primary" onClick={() => system_operations.coordinator.pendingCanvasSystem.startPlacementMode(canvasRect)}>
              <PanToolRoundedIcon />
            </IconButton>
          }
          <IconButton {...tagAttributes} color="primary" onClick={() => moveLayerUpOrDown(true)}>
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton {...tagAttributes} color="primary" onClick={() => moveLayerUpOrDown(false)}>
            <ArrowDownwardIcon />
          </IconButton>
          <IconButton
            {...tagAttributes}
            color={isMarked ? "warning" : "primary"}
            onClick={() => openContentMarkWindow()}
          >
            {isMarked ? (
              <StarRoundedIcon sx={{ color: "yellow" }} />
            ) : (
              <StarRoundedIcon />
            )}
          </IconButton>
          {(canvasData.protectLevel < system_operations.coordinator.getUserRoleInWall().power || canvasData.creatorID === system_operations.getUserID()) &&
            <IconButton {...tagAttributes} color="secondary" aria-label="delete" onClick={deleteWarning}>
              <DeleteIcon />
            </IconButton>}
          <IconButton {...tagAttributes} color="primary" onClick={() => setExtensionConsoleOn(!extensionConsoleOn)}>
            <MoreHorizIcon />
          </IconButton>
        </div>
      </div >
    );
  } else if (!editingMode) {
    mainConsole = (
      <div style={{
        display: 'inline-flex', alignItems: 'center', paddingRight: 5,
        backgroundColor: theme.backgroundColor.wallPageUIBase, borderRadius: theme.borderRadius.wallPageUI,
      }}>
        <IconButton {...tagAttributes} color={liked ? "secondary" : "primary"} onClick={likeButtonClicked}>
          <ThumbUpIcon />
        </IconButton>
        <Badge badgeContent={0} color="secondary" overlap="circular">
          <div />
        </Badge>
        <IconButton {...tagAttributes} color="primary" aria-label="delete" onClick={changeInteractive} sx={{ color: interactable ? "" : "red" }}>
          <BrowserNotSupportedRoundedIcon />
        </IconButton>
        <IconButton {...tagAttributes} color="primary" aria-label="delete" onClick={focusOnMode}>
          <ZoomInIcon />
        </IconButton>
        {isOwner && <IconButton {...tagAttributes} color="primary" onClick={setTextMode}>
          <TitleIcon />
        </IconButton>
        }
        <IconButton
          {...tagAttributes}
          color={isMarked ? "warning" : "primary"}
          onClick={() => openContentMarkWindow()}
        >
          {isMarked ? (
            <StarRoundedIcon sx={{ color: "yellow" }} />
          ) : (
            <StarRoundedIcon />
          )}
        </IconButton>
        {(canvasData.protectLevel < system_operations.coordinator.getUserRoleInWall().power || canvasData.creatorID === system_operations.getUserID()) &&
          <IconButton {...tagAttributes} color="secondary" aria-label="delete" onClick={deleteWarning}>
            <DeleteIcon />
          </IconButton>}
        <IconButton {...tagAttributes} color="primary" onClick={() => setExtensionConsoleOn(!extensionConsoleOn)}>
          <MoreHorizIcon />
        </IconButton>
        {!isOwner && <DialogTitle id="fence-content-title" sx={{ color: "white", fontSize: '1rem', padding: 0, marginTop: 0 }}>
          &nbsp;
          <UserShortCardToolTip user={creator}>
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '1.25rem',
                color: "white"
              }}
            >
              {creator.username}
            </span>
          </UserShortCardToolTip>
        </DialogTitle>}
      </div>
    )
  }

  return (
    <div >
      {extensionConsoleOn &&
        <div style={{ display: 'inline-flex' }}>
          {canvasData.creatorID === system_operations.getUserID() && <FloatingCanvasStyleEditingBar canvasRect={canvasRect} tagAttributes={tagAttributes} />}
          <ImageUltilityBar canvasRect={canvasRect} tagAttributes={tagAttributes} />
        </div>
      }
      {mainConsole}
    </div>
  );
}

export const FloatingCanvasEditingBar = ({ undo, redo, submit, submissionStatus, giveup }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const theme = useTheme();

  return (
    <div style={{
      display: 'inline-flex', alignItems: 'center', paddingRight: 5,
      backgroundColor: theme.backgroundColor.wallPageUIBase, borderRadius: theme.borderRadius.wallPageUI,
    }}>
      <IconButton color="primary" aria-label="undo" onClick={undo}>
        <UndoIcon />
      </IconButton>
      <IconButton color="primary" aria-label="redo" onClick={redo}>
        <RedoIcon />
      </IconButton>
      {submissionStatus === 0 ?
        <IconButton style={{ color: theme.color.approval }} aria-label="submit" onClick={submit}>
          <CheckIcon />
        </IconButton>
        :
        <CircularProgress />}
      <IconButton style={{ color: theme.color.disapproval }} aria-label="giveup" onClick={giveup}>
        <ClearIcon />
      </IconButton>
    </div>
  );
}


export const PostInstanceReactionBar = ({ postInstanceRect, editingMode, tagAttributes }) => {
  const postInstanceData = postInstanceRect.getData();
  const { system_operations } = useContext(SystemOperationsContext);
  const [creator, setCreator] = useState({ username: postInstanceData.creatorID, avatar: "none" });
  const [liked, setLiked] = useState(false);
  const [isMarked, setIsMarked] = useState(postInstanceData.marked);
  const [interactable, setInteractable] = useState(postInstanceRect.interactable);
  const theme = useTheme();

  // console.log("the reaction bar is on");

  useEffect(() => {
    system_operations.coordinator.likeSystem.checkLikedForPost(postInstanceData).then((likedByUser) => {
      if (likedByUser !== liked) { setLiked(likedByUser); }
    });
  }, [])

  const deletionWarningButtons = useRef({
    save: {
      name: 'Yes',
      color: "white",
      backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
      checkDisable: () => { return false },
      onClick: async () => {
        system_operations.set_loading(true);
        await system_operations.coordinator.pendingPostInstancesSystem.deleteAPendingItem(postInstanceRect);
        system_operations.set_loading(false);
        system_operations.setContentWindow(null)
      },
    },
    dump: {
      name: "No",
      color: "white",
      backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
      checkDisable: () => { return false },
      onClick: async () => { system_operations.setContentWindow(null) },
    }
  });

  useEffect(() => {
    system_operations.usersData.getUserDataByID(postInstanceData.creatorID).then((userData) => {
      setCreator(userData);
    })
  }, []);

  const deleteWarning = () => {
    system_operations.setContentWindow((
      <div className='float-container'>
        Delete The Sticker Canvas?
        <ButtonGroups buttons={deletionWarningButtons.current} />
      </div>
    ));
  }

  const likeButtonClicked = () => {
    if (!liked) {
      setLiked(true);
      system_operations.coordinator.likeSystem.likeAPost(postInstanceData);
    } else {
      setLiked(false);
      system_operations.coordinator.likeSystem.dislikeAPost(postInstanceData);
    }
  }

  const moveLayerUpOrDown = (up) => {
    postInstanceRect.moveLayerUpOrDown(up);
    if (postInstanceRect.func?.styleRefreshPostInstanceCore) { postInstanceRect.func.styleRefreshPostInstanceCore(); }
    if (postInstanceRect.func?.rectRefresh) { postInstanceRect.func.rectRefresh(); }
  }

  const openContentMarkWindow = async () => {
    const contentMarker = system_operations.coordinator.sectionData.getItemFromContentMark(`post_${postInstanceRect.postInstanceID}`);
    if (!contentMarker) {
      system_operations.setContentWindow(<ContentMarkPrompt rectRef={postInstanceRect} baseType="pi" />);
    } else {
      system_operations.set_loading(true);
      await system_operations.firestoreActions.removeDocumentAt(`boards/${system_operations.coordinator.getWallID()}/marks/post_${postInstanceData.postInstanceID}`);
      // system_operations.coordinator.sectionData.deleteItemFromContentMarkLoader(contentMarker);
      setIsMarked(false);
      system_operations.set_loading(false);
    }
  }

  const changeInteractive = () => {
    const willBeInteractable = !interactable;
    setInteractable(willBeInteractable);
    postInstanceRect.func.setInteractable(willBeInteractable);
  }

  let displayItems = null;
  const isOwner = postInstanceData.creatorID === system_operations.getUserID()
  if (editingMode) {
    displayItems = (
      <div style={{
        display: 'inline-flex', alignItems: 'center', paddingRight: 5,
        backgroundColor: theme.backgroundColor.wallPageUIBase, borderRadius: theme.borderRadius.wallPageUI,
      }}>
        <div style={{
          display: 'inline-flex'
        }}>
          {isOwner &&
            <>
              <IconButton {...tagAttributes} color="primary" onClick={() => system_operations.coordinator.pendingPostInstancesSystem.startPlacementMode(postInstanceRect)}>
                <PanToolRoundedIcon />
              </IconButton>
              <IconButton {...tagAttributes} color="primary" onClick={() => moveLayerUpOrDown(true)}>
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton {...tagAttributes} color="primary" onClick={() => moveLayerUpOrDown(false)}>
                <ArrowDownwardIcon />
              </IconButton>
              <IconButton
                {...tagAttributes}
                color={isMarked ? "warning" : "primary"}
                onClick={() => openContentMarkWindow()}
              >
                {isMarked ? (
                  <StarRoundedIcon sx={{ color: "yellow" }} />
                ) : (
                  <StarRoundedIcon />
                )}
              </IconButton>
            </>
          }
          {!isOwner && <DialogTitle id="fence-content-title" sx={{ color: "white", fontSize: '1rem', padding: 0, marginTop: 0 }}>
            &nbsp;
            <UserShortCardToolTip user={creator}>
              <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '1.25rem',
                  color: "white"
                }}
              >
                {creator.username}
              </span>
            </UserShortCardToolTip>
          </DialogTitle>}
          {(postInstanceData.protectLevel < system_operations.coordinator.getUserRoleInWall().power || isOwner) &&
            <IconButton {...tagAttributes} color="secondary" aria-label="delete" onClick={deleteWarning}>
              <DeleteIcon />
            </IconButton>}
        </div>
      </div>
    );
  } else if (!editingMode) {
    displayItems = (
      <div style={{
        display: 'inline-flex', alignItems: 'center', paddingRight: 5,
        backgroundColor: theme.backgroundColor.wallPageUIBase, borderRadius: theme.borderRadius.wallPageUI,
      }}>
        <IconButton {...tagAttributes} color={liked ? "secondary" : "primary"} onClick={likeButtonClicked}>
          <ThumbUpIcon />
        </IconButton>
        <Badge badgeContent={0} color="secondary" overlap="circular">
          <div />
        </Badge>
        <IconButton {...tagAttributes} color="primary" aria-label="delete" onClick={changeInteractive} sx={{ color: interactable ? "" : "red" }}>
          <BrowserNotSupportedRoundedIcon />
        </IconButton>
        {!isOwner ? <DialogTitle id="fence-content-title" sx={{ color: "white", fontSize: '1rem', padding: 0, marginTop: 0 }}>
          &nbsp;
          <UserShortCardToolTip user={creator}>
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '1.25rem',
                color: "white"
              }}
            >
              {creator.username}
            </span>
          </UserShortCardToolTip>
        </DialogTitle> : <IconButton
          {...tagAttributes}
          color={isMarked ? "warning" : "primary"}
          onClick={() => openContentMarkWindow()}
        >
          {isMarked ? (
            <StarRoundedIcon sx={{ color: "yellow" }} />
          ) : (
            <StarRoundedIcon />
          )}
        </IconButton>}
        {(postInstanceData.protectLevel < system_operations.coordinator.getUserRoleInWall().power || isOwner) &&
          <IconButton {...tagAttributes} color="secondary" aria-label="delete" onClick={deleteWarning}>
            <DeleteIcon />
          </IconButton>}
      </div>
    );
  }

  return (
    <div>
      {displayItems}
    </div>
  );
}


export const ActionButtonReactionBar = ({ buttonRect, editingMode, tagAttributes }) => {
  const buttonData = buttonRect.getData();
  const { system_operations } = useContext(SystemOperationsContext);
  // const [creator, setCreator] = useState({ username: buttonData.creatorID, avatar: "none" });
  const [interactable, setInteractable] = useState(buttonRect.interactable);

  const theme = useTheme();

  const containerRef = useRef(null);

  const [showBKPicker, setShowBKPicker] = useState(false);
  const [hasBKColorChange, setHasBKColorChange] = useState(buttonRect.styleChange._backgroundColor);
  const [pendingBKColor, setPendingBKColor] = useState("rgba(255, 255, 255, 1)");

  const [showBorderPicker, setShowBorderPicker] = useState(false);
  const [hasBorderColorChange, setHasBorderColorChange] = useState(buttonRect.styleChange._borderColor);
  const [pendingBorderColor, setPendingBorderColor] = useState("rgba(255, 255, 255, 1)");

  const deletionWarningButtons = useRef({
    save: {
      name: 'Yes',
      color: "white",
      backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
      checkDisable: () => { return false },
      onClick: async () => {
        system_operations.set_loading(true);
        await system_operations.coordinator.pendingButtonsSystem.deleteAPendingItem(buttonRect);
        system_operations.set_loading(false);
        system_operations.setContentWindow(null)
      },
    },
    dump: {
      name: "No",
      color: "white",
      backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
      checkDisable: () => { return false },
      onClick: async () => { system_operations.setContentWindow(null) },
    }
  });

  const deleteWarning = () => {
    system_operations.setContentWindow((
      <div className='float-container'>
        Delete The Button?
        <ButtonGroups buttons={deletionWarningButtons.current} />
      </div>
    ));
  }

  const startEditing = () => {
    system_operations.setContentWindow((
      <ActionButtonControlPanel existingButtonRect={buttonRect} />
    ));
  }

  const togglePicker = (type) => {
    if (type === 0) {
      setShowBKPicker(!showBKPicker);
    } else {
      setShowBorderPicker(!showBorderPicker);
    }
  };

  const handleColorChange = (type, color) => {
    const colorStyle = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;

    if (type === 0) {
      setHasBKColorChange(true);
      buttonRect.styleChange._backgroundColor = colorStyle;
      setPendingBKColor(colorStyle);
    } else {
      setHasBorderColorChange(true);
      buttonRect.styleChange._borderColor = colorStyle;
      setPendingBorderColor(colorStyle);
    }

    if (buttonRect.func?.styleRefreshCanvasCore) {
      buttonRect.func.styleRefreshCanvasCore();
    }
  };

  const saveColorChange = async (type) => {
    system_operations.set_loading(true);
    if (type === 0) {
      setHasBKColorChange(false);
      const style = { backgroundColor: pendingBKColor };
      if (buttonRect.isNotUploaded) {
        buttonData.style.backgroundColor = style.backgroundColor; // directly update to the data.
      } else {
        delete buttonRect.styleChange["_backgroundColor"];
        buttonData.style.backgroundColor = style.backgroundColor;
        const dataJson = await RequestDataMaker.dataToUpdateActionButton(system_operations, buttonData.buttonID, { style: style })
        await system_operations.restServerConnector.requestToUpdateButtonData(dataJson);
      }
      buttonRect.clearStyleChanges();
    } else {
      setHasBorderColorChange(false);
      const style = { borderColor: pendingBorderColor };
      if (buttonRect.isNotUploaded) {
        buttonData.style.borderColor = style.borderColor; // directly update to the data.
      } else {
        delete buttonRect.styleChange["_borderColor"];
        buttonData.style.borderColor = style.borderColor;
        const dataJson = await RequestDataMaker.dataToUpdateActionButton(system_operations, buttonData.buttonID, { style: style })
        await system_operations.restServerConnector.requestToUpdateButtonData(dataJson);
      }
      buttonRect.clearStyleChanges();
    }

    system_operations.set_loading(false);
  }

  const changeInteractive = () => {
    const willBeInteractable = !interactable;
    setInteractable(willBeInteractable);
    buttonRect.func.setInteractable(willBeInteractable);
  }

  let displayItems = null;
  const isOwner = buttonData.creatorID === system_operations.getUserID()
  if (isOwner && editingMode) {
    displayItems = (
      <div style={{
        display: 'inline-flex', alignItems: 'center', paddingRight: 5,
        backgroundColor: theme.backgroundColor.wallPageUIBase, borderRadius: theme.borderRadius.wallPageUI,
      }}>
        <div style={{
          display: 'inline-flex'
        }}>
          <div ref={containerRef} style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: '40px', left: '0', display: showBKPicker ? 'block' : 'none' }}>
              <SketchPicker color={pendingBKColor} onChangeComplete={(e) => { handleColorChange(0, e) }} />
            </div>
            <div style={{ position: 'absolute', top: '40px', left: '0', display: showBorderPicker ? 'block' : 'none' }}>
              <SketchPicker color={pendingBorderColor} onChangeComplete={(e) => { handleColorChange(1, e) }} />
            </div>
          </div>
          {!hasBKColorChange ?
            <IconButton {...tagAttributes} color="primary" aria-label="change-color"
              sx={{ color: buttonRect.getActiveColor() }} onClick={() => { togglePicker(0) }}>
              <PaletteIcon />
            </IconButton>
            :
            <IconButton {...tagAttributes} sx={{ color: "rgb(0, 255, 0)" }} onClick={() => saveColorChange(0)}>
              <CheckIcon />
            </IconButton>}
          {!hasBorderColorChange ?
            <IconButton {...tagAttributes} color="primary" aria-label="change-color"
              sx={{ color: buttonRect.getActiveStyle("borderColor") }} onClick={() => { togglePicker(1) }}>
              <PaletteIcon />
            </IconButton>
            :
            <IconButton {...tagAttributes} sx={{ color: "rgb(0, 255, 0)" }} onClick={() => saveColorChange(1)}>
              <CheckIcon />
            </IconButton>}
          {
            buttonData.creatorID === system_operations.getUserID() &&
            <IconButton {...tagAttributes} color="primary" onClick={() => system_operations.coordinator.pendingButtonsSystem.startPlacementMode(buttonRect)}>
              <PanToolRoundedIcon />
            </IconButton>
          }
          {(buttonData.protectLevel < system_operations.coordinator.getUserRoleInWall().power || buttonData.creatorID === system_operations.getUserID()) &&
            <IconButton {...tagAttributes} color="secondary" aria-label="delete" onClick={deleteWarning}>
              <DeleteIcon />
            </IconButton>
          }
        </div>
      </div>
    );
  } else if (!editingMode) {
    displayItems = ((buttonData.protectLevel < system_operations.coordinator.getUserRoleInWall().power || buttonData.creatorID === system_operations.getUserID()) ?
      <div style={{
        display: 'inline-flex', alignItems: 'center', paddingRight: 5,
        backgroundColor: theme.backgroundColor.wallPageUIBase, borderRadius: theme.borderRadius.wallPageUI,
      }}>
        <IconButton {...tagAttributes} color="primary" aria-label="delete" onClick={changeInteractive} sx={{ color: interactable ? "" : "red" }}>
          <BrowserNotSupportedRoundedIcon />
        </IconButton>
        <IconButton {...tagAttributes} color="secondary" aria-label="delete" onClick={startEditing}>
          <SettingsIcon />
        </IconButton>
        <IconButton {...tagAttributes} color="secondary" aria-label="delete" onClick={deleteWarning}>
          <DeleteIcon />
        </IconButton>
      </div> : (null)
    )
  }
  return (
    <div>
      {displayItems}
    </div>
  );
}


const FloatingCanvasStyleEditingBar = ({ canvasRect, tagAttributes = {} }) => {
  const canvasData = canvasRect.getData();
  const { system_operations } = useContext(SystemOperationsContext);
  const [showPicker, setShowPicker] = useState(false);

  const [hasChange, setHasChange] = useState(canvasRect.styleChange._backgroundColor !== undefined || canvasRect.styleChange._borderRadius !== undefined || canvasRect.styleChange._boxShadow !== undefined);
  const [fcColor, setFcColor] = useState(canvasRect.getActiveStyle("backgroundColor") || undefined);
  const [borderRadius, setBorderRadius] = useState(canvasRect.getActiveStyle("borderRadius") || undefined);
  const [boxShadow, setBoxShadow] = useState(canvasRect.getActiveStyle("boxShadow") || undefined);

  const colorPickerContainerRef = useRef(null);
  const theme = useTheme();

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleColorChange = (color) => {
    setHasChange(true);
    const colorStyle = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    canvasRect.styleChange._backgroundColor = colorStyle;
    if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
    setFcColor(colorStyle);
  };

  const handleBorderRadiusChange = () => {
    setHasChange(true);
    let currentTier = theme.flexRects.borderRadiusTiers.indexOf(borderRadius);
    if (currentTier === -1) { currentTier = theme.flexRects.borderRadiusTiers.length - 1; } // set to max index so next index will be 0
    const nextTier = (currentTier + 1) % theme.flexRects.borderRadiusTiers.length;
    // console.log("tier is ", currentTier, "next tier is ", nextTier);
    const newBorderRadius = theme.flexRects.borderRadiusTiers[nextTier];
    canvasRect.styleChange._borderRadius = newBorderRadius; // set the value to the next tier...
    if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
    setBorderRadius(newBorderRadius);
  };

  const handleBoxShadowChange = () => {
    setHasChange(true);
    let currentTier = theme.flexRects.boxShadowTiers.indexOf(boxShadow);
    if (currentTier === -1) { currentTier = theme.flexRects.boxShadowTiers.length - 1; } // set to max index so next index will be 0
    const nextTier = (currentTier + 1) % theme.flexRects.boxShadowTiers.length;
    // console.log("tier is ", currentTier, "next tier is ", nextTier);
    const newBoxShadow = theme.flexRects.boxShadowTiers[nextTier];
    canvasRect.styleChange._boxShadow = newBoxShadow; // set the value to the next tier...
    if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
    setBoxShadow(newBoxShadow);
  };

  const saveChange = async () => {
    system_operations.set_loading(true);
    setHasChange(false);
    const style = {};
    if (fcColor !== undefined) style.backgroundColor = fcColor;
    if (borderRadius !== undefined) style.borderRadius = borderRadius;
    if (boxShadow !== undefined) style.boxShadow = boxShadow;

    if (canvasRect.isNotUploaded) {
      for (let key in style) {
        canvasData.style[key] = style[key];
      }
    } else {
      delete canvasRect.styleChange["_backgroundColor"];
      delete canvasRect.styleChange["_borderRadius"];
      delete canvasRect.styleChange["_boxShadow"];

      for (let key in style) {
        canvasData.style[key] = style[key];
      }
      const dataJson = await RequestDataMaker.dataToUpdateFloatingCanvas(system_operations, canvasData.canvasID, { style: style })
      console.log(dataJson);
      await system_operations.restServerConnector.requestToUpdateFloatingCanvas(dataJson);
    }
    system_operations.set_loading(false);
  }

  return (
    <div style={{
      display: 'inline-flex', alignItems: 'center', paddingRight: 5,
      backgroundColor: theme.backgroundColor.wallPageUIBase, borderRadius: theme.borderRadius.wallPageUI,
    }}>
      <div style={{
        display: 'inline-flex'
      }}>
        <div ref={colorPickerContainerRef} style={{ position: 'relative' }}>
          <div style={{
            position: 'absolute',
            top: '40px',
            left: '0',
            display: showPicker ? 'block' : 'none',
            zIndex: "100"
          }}>
            <SketchPicker color={fcColor} onChangeComplete={handleColorChange} />
          </div>
        </div>
        <IconButton
          {...tagAttributes}
          color="primary"
          aria-label="change-color"
          sx={{ color: fcColor }}
          onClick={togglePicker}
        >
          <PaletteIcon />
        </IconButton>
        <IconButton {...tagAttributes} color="primary" aria-label="change-boxShadow" onClick={handleBorderRadiusChange} >
          <FullscreenRoundedIcon />
        </IconButton>
        <IconButton {...tagAttributes} color="primary" aria-label="change-boxShadow" onClick={handleBoxShadowChange} >
          <FlipToFrontRoundedIcon />
        </IconButton>
        {hasChange &&
          <IconButton
            {...tagAttributes}
            sx={{ color: "rgb(0, 255, 0)" }} onClick={() => saveChange()}>
            <CheckIcon />
          </IconButton>}
      </div>
    </div>
  )
}

const ImageUltilityBar = ({ canvasRect, tagAttributes = {} }) => {
  // const canvasData = canvasRect.getData();
  const { system_operations } = useContext(SystemOperationsContext);
  const theme = useTheme();

  const composeThisCanvasImage = async () => {
    if (canvasRect) {
      const canvas = await canvasRect.getComposedCanvasSelf();
      displayCanvasData(canvas);
    }
  }
  const composeAllCanvasLayers = async () => {
    if (canvasRect) {
      const canvas = await canvasRect.getComposedCanvasesAll();
      displayCanvasData(canvas);
    }
  }
  const displayCanvasData = (canvas) => {
    system_operations.setContentWindow(
      <img
        src={canvas.toDataURL()}
        alt="Canvas content"
      />
    )
  }

  return (
    <div style={{
      display: 'inline-flex', alignItems: 'center', paddingRight: 5,
      backgroundColor: theme.backgroundColor.wallPageUIBase, borderRadius: theme.borderRadius.wallPageUI,
    }}>
      <div style={{
        display: 'inline-flex'
      }}>
        <IconButton {...tagAttributes} color="primary" aria-label="change-boxShadow" onClick={composeThisCanvasImage} >
          <PhotoFilterRoundedIcon />
        </IconButton>
        <IconButton {...tagAttributes} color="primary" aria-label="change-boxShadow" onClick={composeAllCanvasLayers} >
          <AutoAwesomeMotionRoundedIcon />
        </IconButton>
      </div>
    </div>
  )
}