import React, { useMemo, useRef, useEffect, useContext, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { SystemOperationsContext } from "../../context/SystemRunnerContext";
import { PaperCover } from "../../system/toolpack/Paper.js";


export const FCMedia = ({ paperRect }) => {
  const mediaData = paperRect?.getData().extension.data || null; // fine to get it every refresh.
  let mediaContent = null;
  if (mediaData) {
    switch (mediaData.type) {
      case "images":
        mediaContent = (<FcCarousel paperRect={paperRect} mediaData={mediaData} images={mediaData.urls} />);
        break;
      case "audio":
        mediaContent = (<FcCarousel paperRect={paperRect} mediaData={mediaData} images={mediaData.urls} />);
        break;
      case "video":
        mediaContent = (<FCVideoPlayer paperRect={paperRect} mediaData={mediaData} videoUrl={mediaData.urls[0]} />);
        break;
      default:
        mediaContent = null;
    }
  }

  return (
    <div>
      {mediaContent}
    </div>
  )
}

export const FcCarousel = ({ paperRect, mediaData, images }) => {
  const mediaProp = mediaData.options || {};

  const containerStyle = {
    position: "absolute",
    left: "0px", top: "0px",
    width: "100%", height: "100%",
    overflow: 'hidden',
  };

  const imageStyle = {
    width: `${paperRect.getActiveWidth()}px`,
    height: `${paperRect.getActiveHeight()}px`,
    objectFit: 'cover',
  };

  return (
    <div style={containerStyle}>
      <Carousel
        showThumbs={false}
        swipeable={true}
        showStatus={false}
        showIndicators={true}
        dynamicHeight={false}
        emulateTouch={true}
        infiniteLoop={true}
        autoPlay={Boolean(mediaProp.autoPlay)}
        dotListStyle={{ bottom: '10px', margin: '0' }} // Adjusting dots position
      >
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`slide-${index}`} style={imageStyle} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export const FCVideoPlayer = ({ paperRect, videoUrl }) => {
  const iframeRef = useRef(null);
  const containerStyle = {
    position: "absolute",
    left: "0px", top: "0px",
    width: "100%", height: "100%",
    overflow: 'hidden',
  };

  const videoBoxStyle = {
    width: `${paperRect.getActiveWidth()}px`,
    height: `${paperRect.getActiveHeight()}px`,
    objectFit: 'cover',
  };

  
  return (
    <div style={containerStyle}>
      <iframe ref={iframeRef} width={paperRect.getActiveWidth()} height={paperRect.getActiveHeight()} src={videoUrl} frameBorder="0" allowfullscreen></iframe>
    </div>
  );
};



