/*
File name: CollisionEngine.js
Author: Weiyan Tao
Date last modified: 08/07/2023
Description: Collision Engine for the frontend
*/

import {Magnetizer} from "./Magnetizer.js"

export class CollidableRect {
  constructor(x, y, width, height, id, type="uncategorized", dataRef = null, maxWidth = null, maxHeight = null, minWidth = 1, minHeight = 1) {
    this.collisionSystems = {};
    this.id = id;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.type = type;
    this.dataRef = dataRef; // to hold a reference to the data it representing 
    // this.collisionSystem.addRect(this);

    this.dragStartEvent = null;
    this.dragMoveEvent = null;
    this.dragStopEvent = null;

    this.maxWidth = maxWidth;
    this.maxHeight = maxHeight;
    this.minWidth = minWidth;
    this.minHeight = minHeight;
  }

  addACollisionCollection(collisionSystem, byPassRule = null, useDefaultByPassRule = false) {
    if (useDefaultByPassRule) { byPassRule = this.byPass_checkSelf.bind(this) }
    this.collisionSystems[collisionSystem.id] = { collisionSystem: collisionSystem, byPassRule: byPassRule };
  }

  clearCollisionSystemDeep() {
    for (const key in this.collisionSystems) {
      this.collisionSystems[key].collisionSystem.removeRect(this) // remove this from the collision system
    }
    this.collisionSystems = {};
  }

  getDimension() {
    return { x: this.x, y: this.y, width: this.width, height: this.height };
  }

  getDimensionArray() {
    return [this.x, this.y, this.width, this.height];
  }

  resize(width, height) {
    this.width = width;
    this.height = height;
  }

  reposition(x, y) {
    this.x = x;
    this.y = y;
  }

  remake(x, y, width, height) {
    this.reposition(x, y)
    this.resize(width, height)
  }

  moveStart(behavior = null) {
    if (behavior) { behavior() }
    if (this.dragStartEvent) {
      this.dragStartEvent();
    }
  }

  // no collision check
  forcefulTransform(x, y, width, height) {
    this.reposition(x, y)
    this.resize(width, height)
  }

  move(newX, newY, magnetsDict=null, displayX = null, displayY = null, behavior=null) {
    if (magnetsDict) {
      const xPoints = [newX, newX + this.width/2, newX + this.width];
      const yPoints = [newY, newY + this.height/2, newY + this.height];
      const fixMove = Magnetizer.rectangleMagnetMultiPointsMove(magnetsDict, xPoints, yPoints);
      if (fixMove.moved[0]) {
        newX += fixMove.move[0];
        if (displayX) displayX(fixMove.magnets[0]);
      } else {
        if (displayX) displayX(-1);
      }
      if (fixMove.moved[1]) {
        newY += fixMove.move[1];
        if (displayY) displayY(fixMove.magnets[1]);
      } else {
        if (displayY) displayY(-1);
      }
    }

    let finalX = newX, finalY = newY;
    let totalFixMove = [0, 0];
    let valid = true;
    let fixMoved = false;

    for (const key in this.collisionSystems) {
      let collisionData = this.collisionSystems[key].collisionSystem.checkCollision(newX, newY, this.width, this.height, this, this.collisionSystems[key].byPassRule);
      collisionData.forEach(collision => {
        const fixMove = collision.fixMove;
        for (let i = 0; i < 2; i++) {
          if (totalFixMove[i] === 0 || Math.sign(fixMove[i]) === Math.sign(totalFixMove[i])) {
            if (Math.abs(fixMove[i]) > Math.abs(totalFixMove[i])) {
              fixMoved = true;
              totalFixMove[i] = fixMove[i];
            } // else don't change anything
          } else {
            valid = false;
          }
        }
      });
    }

    if (fixMoved && valid) {
      finalX += totalFixMove[0]; finalY += totalFixMove[1];
      // check if after the movement, the collision still happens or not.
      for (const key in this.collisionSystems) {
        const intered = this.collisionSystems[key].collisionSystem.checkCollisionQuick(finalX, finalY, this.width, this.height, this, this.collisionSystems[key].byPassRule)
        if (intered) {
          valid = false;
        }
      }
    }
    if (valid) {
      this.reposition(finalX, finalY);
      if (behavior) { behavior(finalX, finalY); }
      return true;
    }
    return false;
  }

  byPass_checkSelf(checkRect, collisionRect) {
    if (checkRect && collisionRect && checkRect.id === collisionRect.id) {
      return true;
    }
    return false;
  }

  
  dragEdge(edge, newCoord, magnetsDict=null, display=null) {
    let newX = this.x, newY = this.y, newWidth = this.width, newHeight = this.height;
    if (magnetsDict) {
      const fix = Magnetizer.pointMagnetMove(magnetsDict, newCoord);
      if (fix.moved) {
        newCoord += fix.move;
        if (display) display(fix.magnet);
      } else {
        if (display) display(-1);
      }
    }
    let exceed = 0;
    let short = 0;
    switch (edge) {
      case 'x1':
        newWidth += this.x - newCoord;
        newX = newCoord;
        exceed = newWidth - (this.maxWidth? this.maxWidth : 0);
        short = this.minWidth - newWidth;
        if (this.maxWidth && exceed > 0) {
          newX += exceed; // move to left to reduce the width
          newWidth -= exceed;
        } else if (short > 0) {
          newX -= short;
          newWidth += short;
        }
        break;
      case 'x2':
        newWidth = newCoord - this.x;
        exceed = newWidth - (this.maxWidth? this.maxWidth : 0);
        short = this.minWidth - newWidth;
        if (this.maxWidth && exceed > 0) {
          newWidth -= exceed;
        } else if (short > 0) {
          newWidth += short;
        }
        break;
      case 'y1':
        newHeight += this.y - newCoord;
        newY = newCoord;
        exceed = newHeight - (this.maxHeight? this.maxHeight : 0);
        short = this.minHeight - newHeight;
        if (this.maxHeight && exceed > 0) {
          newY += exceed; // move to down to reduce the width
          newHeight -= exceed;
        } else if (short > 0) {
          newY -= short;
          newHeight += short;
        }
        break;
      case 'y2':
        newHeight = newCoord - this.y;
        exceed = newHeight - (this.maxHeight? this.maxHeight : 0);
        short = this.minHeight - newHeight;
        if (this.maxHeight && exceed > 0) {
          newHeight -= exceed;
        } else if (short > 0) {
          newHeight += short;
        }
        break;
      default:
        throw new Error(`Invalid edge "${edge}". Must be one of "x1", "x2", "y1", "y2".`);
    }


    for (const key in this.collisionSystems) {
      let collisionData = this.collisionSystems[key].collisionSystem.checkCollision(newX, newY, newWidth, newHeight, this, this.collisionSystems[key].byPassRule);
      for (let collision of collisionData) {
        switch (edge) {
          case 'x1':
            newX = Math.max(newX, collision.x + collision.width);
            newWidth = this.x + this.width - newX;
            break;
          case 'x2':
            newWidth = Math.min(newWidth, collision.x - this.x);
            break;
          case 'y1':
            newY = Math.max(newY, collision.y + collision.height);
            newHeight = this.y + this.height - newY;
            break;
          case 'y2':
            newHeight = Math.min(newHeight, collision.y - this.y);
            break;
        }
      }
    }
    this.remake(newX, newY, newWidth, newHeight);
    return { x: newX, y: newY, width: newWidth, height: newHeight };
  }

  moveStopped(behavior = null) {
    if (behavior) { behavior() }
  }
}

export class RectCollisionSystem {
  constructor(id = "default") {
    this.rects = {};
    this.boundary = null;
    this.id = id;
  }

  setBoundary(x1, x2, y1, y2) {
    this.boundary = { xMin: x1, xMax: x2, yMin: y1, yMax: y2 };
  }

  addRect(rect) {
    if (rect) {
      this.rects[rect.id] = rect;
    }
  }

  removeRect(rect) {
    if (rect) {
      delete this.rects[rect.id];
    }
  }

  checkBorderCollision(x, y, width, height) {
    if (this.boundary) {
      const fixMove = [0, 0];
      let [collisionX, collisionY, collisionWidth, collisionHeight, hasCollision] = [0, 0, 0, 0, false]
      if (x < this.boundary.xMin) {
        hasCollision = true;
        collisionX = x; collisionY = y;
        collisionWidth = this.boundary.xMin - x;
        collisionHeight = height;
        fixMove[0] = this.boundary.xMin - x;
      } else if (x + width > this.boundary.xMax) {
        hasCollision = true;
        collisionX = this.boundary.xMax; collisionY = y;
        collisionWidth = (x + width) - this.boundary.xMax;
        collisionHeight = height;
        fixMove[0] = this.boundary.xMax - (x + width);
      }

      if (y < this.boundary.yMin) {
        hasCollision = true;
        collisionX = x; collisionY = y;
        collisionWidth = width;
        collisionHeight = this.boundary.yMin - y;
        fixMove[1] = this.boundary.yMin - y;
      } else if (y + height > this.boundary.yMax) {
        hasCollision = true;
        collisionX = x; collisionY = this.boundary.yMax;
        collisionWidth = width;
        collisionHeight = (y + height) - this.boundary.yMax;
        fixMove[1] = this.boundary.yMax - (y + height);
      }
      if (hasCollision) {
        return { rect: null, x: collisionX, y: collisionY, width: collisionWidth, height: collisionHeight, fixMove: fixMove };
      }
    }
    return false;
  }

  checkContainPoint(x, y, contianBorder = false, byPassRule = null) {
    if (contianBorder) {
      if (x > this.boundary.xMax || x < this.boundary.xMin) { return null }
      if (y > this.boundary.yMax || y < this.boundary.yMin) { return null }
    }
    for (const rect of Object.values(this.rects)) {
      if (!byPassRule || !byPassRule(null, rect)) {
        if (x > rect.x && x < rect.x + rect.width && y > rect.y && y < rect.y + rect.height) {
          return rect;
        }
      }
    }
    return false;
  }

  checkCollisionQuick(x, y, width, height, rectRef = null, byPassRule = null) {
    let collisions = [];
    const borderCollision = this.checkBorderCollision(x, y, width, height);
    if (borderCollision) {
      return true;
    }

    for (const rect of Object.values(this.rects)) {
      if (!byPassRule || !byPassRule(rectRef, rect)) {
        if (this.isIntersecting(x, y, width, height, [rect.x, rect.y, rect.width, rect.height])) {
          return true;
        }
      }
    }
    return false;
  }

  checkCollision(x, y, width, height, rectRef = null, byPassRule = null) {
    let collisions = [];
    const borderCollision = this.checkBorderCollision(x, y, width, height);
    if (borderCollision) {
      collisions.push(borderCollision)
    }

    Object.keys(this.rects).forEach(id => {
      if (!byPassRule || !byPassRule(rectRef, this.rects[id])) {
        const rectB = this.rects[id];
        const intersectingData = this.getIntersectingData(x, y, width, height, [rectB.x, rectB.y, rectB.width, rectB.height]);

        if (intersectingData.intersecting) {
          const { collisionX, collisionY, collisionWidth, collisionHeight, overlapType } = intersectingData;

          const fixMove = [0, 0];

          // Determine the direction for the shortest "fixMove"
          if (overlapType === "partial") {
            if (collisionWidth < collisionHeight) {
              // Move horizontally (left or right)
              fixMove[0] = (x < rectB.x) ? -collisionWidth : collisionWidth;
            } else {
              // Move vertically (up or down)
              fixMove[1] = (y < rectB.y) ? -collisionHeight : collisionHeight;
            }
          } else { // Full overlap
            const distLeft = Math.abs(x - (rectB.x + width));
            const distRight = Math.abs(x - (rectB.x + rectB.width));
            const distUp = Math.abs(y - (rectB.y + height));
            const distDown = Math.abs(y - (rectB.y + rectB.height));

            const minDist = Math.min(distLeft, distRight, distUp, distDown);

            // Move to the direction with the minimum distance
            switch (minDist) {
              case distLeft:
                fixMove[0] = -distLeft; // Move to the left
                break;
              case distRight:
                fixMove[0] = distRight; // Move to the right
                break;
              case distUp:
                fixMove[1] = -distUp; // Move upwards
                break;
              case distDown:
                fixMove[1] = distDown; // Move downwards
                break;
            }
          }
          // Add this collision to the list of collisions

          const collision = { rect: rectB, x: collisionX, y: collisionY, width: collisionWidth, height: collisionHeight, fixMove: fixMove };
          collisions.push(collision);
        }
      }
    });
    return collisions;
  }

  isIntersecting(x1, y1, width1, height1, [x2, y2, width2, height2]) {
    x1 = Math.round(x1);
    y1 = Math.round(y1);
    width1 = Math.round(width1);
    height1 = Math.round(height1);
    x2 = Math.round(x2);
    y2 = Math.round(y2);
    width2 = Math.round(width2);
    height2 = Math.round(height2);
    return x1 < x2 + width2 && x1 + width1 > x2 && y1 < y2 + height2 && y1 + height1 > y2;
  }

  getIntersectingData(x1, y1, width1, height1, [x2, y2, width2, height2]) {
    x1 = Math.round(x1);
    y1 = Math.round(y1);
    width1 = Math.round(width1);
    height1 = Math.round(height1);
    x2 = Math.round(x2);
    y2 = Math.round(y2);
    width2 = Math.round(width2);
    height2 = Math.round(height2);
    const intersecting = x1 < x2 + width2 && x1 + width1 > x2 && y1 < y2 + height2 && y1 + height1 > y2;

    if (!intersecting) {
      return { intersecting };
    }

    let collisionX, collisionY, collisionWidth, collisionHeight;
    let overlapType = "partial"; // default case

    // Check if A contains B
    if (x1 <= x2 && y1 <= y2 && (x1 + width1) >= (x2 + width2) && (y1 + height1) >= (y2 + height2)) {
      collisionX = x2;
      collisionY = y2;
      collisionWidth = width2;
      collisionHeight = height2;
      overlapType = "A_contains_B";
    }
    // Check if B contains A
    else if (x2 <= x1 && y2 <= y1 && (x2 + width2) >= (x1 + width1) && (y2 + height2) >= (y1 + height1)) {
      collisionX = x1;
      collisionY = y1;
      collisionWidth = width1;
      collisionHeight = height1;
      overlapType = "B_contains_A";
    }
    // Normal intersecting case
    else {
      collisionX = Math.max(x1, x2);
      collisionY = Math.max(y1, y2);
      collisionWidth = Math.min(x1 + width1, x2 + width2) - collisionX;
      collisionHeight = Math.min(y1 + height1, y2 + height2) - collisionY;
    }
    return { intersecting, collisionX, collisionY, collisionWidth, collisionHeight, overlapType };
  }
}