import React, { useState, useContext } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import StickerViewer from './StickerViewer';
import StickerCreation from './StickerCreation';

const FloatPage = () => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [floatPageIndex, setFloatPageIndex] = useState(0);
  system_operations.floatPageController.assignSetFloatPageHook(setFloatPageIndex);


  if (floatPageIndex === 1) {
    return <StickerViewer />;
  } else if (floatPageIndex === 2) {
    return <StickerCreation />;
  } else {
    return null;
  }
};

export default FloatPage;