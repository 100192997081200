export class FenceMaker {
  constructor() {
		this.setFenceModeHook = null
	}

  setFenceMode(on) {
    if (this.setFenceModeHook) {
      this.setFenceModeHook(on);
    }
  }

  assignSetFenceModeHook(setFenceMode) {
    this.setFenceModeHook = setFenceMode;
  }
}