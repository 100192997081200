import { ImageProcessor } from "../utility/ImageProcessor.js"

export function fenceByPassRuleFC(fenceCollisionRect) {
  const fence = fenceCollisionRect?.dataRef;
  if (!fence) {
    return false;
  }
  const allowAddingCanvas = this.system.fenceCoder.isFlagSetByName(fence.rules, "addingCanvasOther");
  if (fence.creatorID === this.system.getUserID() || allowAddingCanvas) {
    return true;
  } else {
    return false;
  }
}

export function fenceByPassRulePI(fenceCollisionRect) {
  const fence = fenceCollisionRect?.dataRef;
  if (!fence) {
    return false;
  }
  const allowPosting = this.system.fenceCoder.isFlagSetByName(fence.rules, "postingOther");
  if (fence.creatorID === this.system.getUserID() || allowPosting) {
    return true;
  } else {
    return false;
  }
}

export function fenceByPassRuleAB(fenceCollisionRect) {
  const fence = fenceCollisionRect?.dataRef;
  if (!fence) {
    return false;
  }
  const allowAddingButton = this.system.fenceCoder.isFlagSetByName(fence.rules, "addingButtonOther");
  if (fence.creatorID === this.system.getUserID() || allowAddingButton) {
    return true;
  } else {
    return false;
  }
}


export class PaperRects {
  constructor(data, idName, typeName, updateSectionData, pendingSystem, system, isBasicPaper = true) {
    this.system = system;
    this.coordinator = this.system.coordinator;
    this.updateSectionData = updateSectionData;
    this.pendingSystem = pendingSystem;

    this.isHorizontal = this.coordinator.isHorizontal;

    this.collider = null;
    this.interactable = true; // when being displayed, if this paper is clickable, and block the event pointer.
    this.disabled = false;    // when the rect is disabled, it will not be displayed. Used for temp delete or move around.

    this.data = data; // path to the original data
    this.idName = idName;
    this.id = data[this.idName];
    this.typeName = typeName;

    if (typeName === "floatingCanvases") {
      this.fenceByPassRule = fenceByPassRuleFC;
    } else if (typeName === "posts") {
      this.fenceByPassRule = fenceByPassRulePI;
    } else {
      this.fenceByPassRule = fenceByPassRuleAB;
    }


    this.tempData = {}; // any transform changes
    this.styleChange = {}; // any style changes.
    this.utilities = {};
    this.func = {};     // all functions might needs to be added.
    this.hoverOn = false;
    this.hoverEffects = {};
    this.onDisableStateChange = {};
    this.onLayerChange = {};
    this.onResetChanges = {};

    this.getCollectionSet = (layer) => {
      return this.coordinator.getPaperCollectionSet(layer, this.fenceByPassRule.bind(this), this.typeName);
    }

    this.isNotUploaded = false;
  }

  setIsNotUploaded(pending) {
    this.isNotUploaded = pending;
  }

  getID() {
    return this.id;
  }

  getData() {
    return this.data;
  }

  setDisabled(disabled) {
    this.disabled = disabled;
    for (const key in this.onDisableStateChange) {this.onDisableStateChange[key](disabled);}
  }

  giveUpTempData() {
    this.tempData = {};
    this.styleChange = {};
  }

  getCoord() {
    if (this.isHorizontal) {
      return this.tempData._x !== undefined ? this.tempData._x : this.data.x;
    } else {
      return this.tempData._y !== undefined ? this.tempData._y : this.data.y;
    }
  }

  getActiveLength() {
    if (this.isHorizontal) {
      return this.tempData._width !== undefined ? this.tempData._width : this.data.width;
    } else {
      return this.tempData._height !== undefined ? this.tempData._height : this.data.height;
    }
  }

  getActiveCoord() {
    if (this.isHorizontal) {
      return this.getActiveX();
    } else {
      return this.getActiveY();
    }
  }

  getActiveX() { return this.tempData._x !== undefined ? this.tempData._x : this.data.x; }

  getActiveY() { return this.tempData._y !== undefined ? this.tempData._y : this.data.y; }

  getActiveWidth() {
    // console.log("width: ", this.tempData._width ? this.tempData._width : this.data.width)
    return this.tempData._width !== undefined ? this.tempData._width : this.data.width;
  }

  getActiveHeight() {
    // console.trace("height: ", this.tempData._height ? this.tempData._height : this.data.height)
    return this.tempData._height !== undefined ? this.tempData._height : this.data.height;
  }

  getActiveLayer() { return this.tempData._layer !== undefined ? this.tempData._layer : this.data.layer; }

  getActiveColor() { return this.styleChange._backgroundColor ? this.styleChange._backgroundColor : (this.data.style.backgroundColor || "rgb(255, 255, 255)"); }

  getBaseX() { return this.data.x; }

  getBaseY() { return this.data.y; }

  getBaseWidth() { return this.data.width; }

  getBaseHeight() { return this.data.height; }

  getBaseLayer() { return this.data.layer; }

  triggerHoverEffect(on) {
    if (on !== this.hoverOn) {
      this.hoverOn = on;
      for (const key in this.hoverEffects) {
        this.hoverEffects[key](on);
      }
    }
  }

  getActiveField(fieldName) {
    const tempFieldName = `_${fieldName}`
    return this.tempData[tempFieldName] !== undefined ? this.tempData[tempFieldName] : this.data[fieldName];
  }


  addToStyleChange(key, value) {
    if (!("_style" in this.tempData)) {
      this.tempData._style = {}
    }
    this.tempData._style[key] = value
  }

  clearStyleChanges() {
    if (this.tempData.style) {
      this.tempData.style = {};
    }
    this.styleChange = {};
  }

  // optional param styleFieldName: when is given, it will only return the value of this field. when it's missing, it will return the whole style object.
  getActiveStyle(styleFieldName = null) {
    if (!styleFieldName) {
      return this.tempData._style !== undefined ? this.tempData._style : this.data.style;
    } else {
      const styleChangeName = `_${styleFieldName}`;
      if (styleChangeName in this.styleChange) {
        return this.styleChange[styleChangeName] ? this.styleChange[styleChangeName] : this.data.style[styleFieldName]
      } else {
        return this.data.style[styleFieldName]
      }
    }
  }

  addTheFieldIfTempDataContains(updateData, fieldName) {
    const tempFieldName = `_${fieldName}`
    if (this.tempData[tempFieldName]) {
      updateData[fieldName] = this.tempData[tempFieldName]
    }
  }

  setTransform(x, y, width, height) {
    if (this.func.setTransform) { this.func.setTransform(x, y, width, height) }
  }

  resetChanges() {
    delete this.tempData['_x'];
    delete this.tempData['_y'];
    delete this.tempData['_width'];
    delete this.tempData['_height'];
    delete this.tempData['_layer'];
    this.tempData.transformChanged = false;

    for (const key in this.onResetChanges) { this.onResetChanges[key]() }
    delete this.pendingSystem.pendingList.update[this.id]; // remove from the pendingCanvases
    if (this.func.reset) { this.func.reset(); }
    if (this.func.rectSetCollision) { this.func.rectSetCollision(this.getCollectionSet(this.data.layer)); }
  }

  // This function is responsiable to update any transform change of the function. It need a rect target, and a new State which contains the new position and size.
  // new position and the size will be updated to a tempData of the target rect. So the unsaved change is recognizable.
  // in other functions, the save tempData functionality will be implemented.
  updateTransform(newState) {
    if (newState) {
      this.tempData.transformChanged = true;
      this.tempData._x = newState.x;
      this.tempData._y = newState.y;
      this.tempData._width = newState.width;
      this.tempData._height = newState.height;
    }
    this.pendingSystem.addToPendingList(this.id, this, "update");
    this.updateSectionData(this);
  }

  // this function is responsiable to update the layer of a given rect target.
  // update to tempData... saved in other functionalities.
  updateLayer(newLayer) {
    if (newLayer >= 0 && newLayer < this.system.coordinator.totalFloatingLayers) {
      this.tempData._layer = newLayer;
      this.pendingSystem.addToPendingList(this.id, this, "update");
      for (const key in this.onLayerChange) {
        this.onLayerChange[key](newLayer); // upload the layer
      }
      if (this.func.rectSetCollision) { this.func.rectSetCollision(this.getCollectionSet(newLayer)); }
    }
  }

  // the function that checks if the change to a specific layer is allowed. 
  checkCanChangeLevel(changeToIndex) {
    if (changeToIndex < 0 || changeToIndex > this.coordinator.totalFloatingLayers - 1) {
      return false;
    } else {
      const checkAginst = this.getCollectionSet(changeToIndex); // choose the right set to check against
      let hasCollision = false;
      for (const collectionObj of checkAginst) {
        if (!collectionObj.noneCheck) {
          const x = (this.tempData._x !== undefined) ? this.tempData._x : this.data.x;
          const y = (this.tempData._y !== undefined) ? this.tempData._y : this.data.y;
          const width = (this.tempData._width !== undefined) ? this.tempData._width : this.data.width;
          const height = (this.tempData._height !== undefined) ? this.tempData._height : this.data.height;
          if (collectionObj.collection.checkCollisionQuick(x, y, width, height, null, collectionObj.byPassRule)) { hasCollision = true; }
        }
      }
      return !hasCollision;
    }
  }

  // give a direction of layer change. it will find the cloest avaliable layer to move to. or no layer is allowed to move (-1)
  moveLayerUpOrDown(up) {
    if (up) {
      for (let layerIndex = this.getActiveLayer() + 1; layerIndex < this.coordinator.totalFloatingLayers; layerIndex++) {
        if (this.checkCanChangeLevel(layerIndex)) {
          this.updateLayer(layerIndex);
          return layerIndex;
        }
      }
    } else {
      for (let layerIndex = this.getActiveLayer() - 1; layerIndex >= 0; layerIndex--) {
        if (this.checkCanChangeLevel(layerIndex)) {
          this.updateLayer(layerIndex);
          return layerIndex;
        }
      }
    }
    return -1;
  }
}

export class FloatingCanvasRect extends PaperRects {
  constructor(data, idName, typeName, updateSectionData, pendingSystem, system) {
    super(data, idName, typeName, updateSectionData, pendingSystem, system);
    // this.utilities.fcDraw = new FloatingCanvas(system);
    this.version = 0;
  }

  /**
  * Draws a background image onto a canvas context with "cover" style scaling.
  *
  * This function scales the given image to fill the canvas while maintaining the image's aspect ratio,
  * ensuring that both dimensions (width and height) of the image fit within the dimensions of the canvas.
  * The scaled image will cover the entire canvas. If the aspect ratio of the image does not match
  * the aspect ratio of the canvas, then the image will be clipped either vertically or horizontally
  * such that no empty space remains in the canvas.
  *
  * @param {CanvasRenderingContext2D} ctx - The 2D rendering context of the canvas where the image will be drawn.
  * @param {HTMLImageElement} img - The image element containing the image to be drawn.
  * @param {number} canvasWidth - The width of the canvas.
  * @param {number} canvasHeight - The height of the canvas.
  */
  drawBackgroundImage(ctx, img, canvasWidth, canvasHeight) {
    const imgAspectRatio = img.width / img.height;
    const canvasAspectRatio = canvasWidth / canvasHeight;
    let drawWidth, drawHeight, offsetX, offsetY;

    if (imgAspectRatio > canvasAspectRatio) {
      // Image is wider than the canvas, scale by height
      drawHeight = canvasHeight;
      drawWidth = img.width * (drawHeight / img.height);
      offsetX = (canvasWidth - drawWidth) / 2;
      offsetY = 0;
    } else {
      // Image is narrower or equal to the canvas, scale by width
      drawWidth = canvasWidth;
      drawHeight = img.height * (drawWidth / img.width);
      offsetX = 0;
      offsetY = (canvasHeight - drawHeight) / 2;
    }

    ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
  }

  // return the background + front canvas...
  async getComposedCanvasSelf() {
    const canvas = document.createElement("canvas"); // create new canvas
    const ctx = canvas.getContext("2d");
    canvas.width = this.getActiveWidth();
    canvas.height = this.getActiveHeight();

    const backgroundColor = this.getActiveStyle("backgroundColor");
    if (backgroundColor) {
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    } else {
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    // Draw background image if exists
    const backgroundUrl = this.data.style?.backgroundImage;
    if (backgroundUrl) {
      await new Promise((resolve, reject) => {
        const backgroundImage = new Image();
        backgroundImage.crossOrigin = "anonymous";
        backgroundImage.onload = () => {
          this.drawBackgroundImage(ctx, backgroundImage, canvas.width, canvas.height);
          resolve();
        };
        backgroundImage.onerror = reject;
        backgroundImage.src = backgroundUrl;
      });
    }

    // Draw another canvas if exists
    if (this.utilities.canvas) {
      ctx.drawImage(this.utilities.canvas, 0, 0);
    }
    return canvas;
  }

  // return the composedimage self result for all collided fc from layer low to high.
  async getComposedCanvasesAll() {
    const canvas = document.createElement("canvas"); // create new canvas
    const ctx = canvas.getContext("2d");
    canvas.width = this.getActiveWidth();
    canvas.height = this.getActiveHeight();
    for (let layer = 0; layer < this.system.coordinator.totalFloatingLayers; layer++) {
      const checkAginst = this.getCollectionSet(layer); // choose the right set to check against
      for (const collectionObj of checkAginst) {
        if (!collectionObj.noneCheck) {
          const x = this.getActiveX();
          const y = this.getActiveY();
          const width = this.getActiveWidth();
          const height = this.getActiveHeight();

          const collisions = collectionObj.collection.checkCollision(x, y, width, height, null, null); // should not ignore any rect.
          for (const col of collisions) {
            if (col.rect.dataRef?.typeName === "floatingCanvases" && col.rect.dataRef.getComposedCanvasSelf) {
              const addonCanvas = await col.rect.dataRef.getComposedCanvasSelf();
              if (addonCanvas) {
                const [xOffset, yOffset] = [col.rect.x - x, col.rect.y - y];
                // const [copyWidth, copyHeight] = [col.rect.width - width, col.rect.height - height];
                ctx.drawImage(addonCanvas, xOffset, yOffset)
              }
            }
          }
        }
      }
    }
    return canvas;
  }

  reloadCanvasData() {
    if (this.utilities?.canvas && this.system.coordinator?.kDraw?.canvasDataTracker?.[this.canvasRect.getID()]) { // exist and no changes.
      const ctx = this.utilities.canvas.getContext("2d");
      ctx.clearRect(0, 0, this.utilities.canvas.width, this.utilities.canvas.height);
      ImageProcessor.static_loadImageUrlToCanvas(this.canvasData.imageUrl, ctx, true)
    } else if (this.system.coordinator?.kDraw?.canvasDataTracker?.[this.canvasRect.getID()]) {
      console.log("changed while drawing.");
    }
  }

  updateDataUrl() {
    this.version = new Date().getTime();
    this.data.imageUrl = `${this.data.imageUrl}?version=${this.version}`
  }
}

export class PostInstanceRect extends PaperRects {
  constructor(data, idName, typeName, updateSectionData, pendingSystem, system) {
    super(data, idName, typeName, updateSectionData, pendingSystem, system);
  }

  // mergeRule is called for the newly inserted rect. the one already in the list' mergeRule will not be called.
  // return true means replace the current one.
  // return false means don't replace the current one.
  mergeRule(existingRect) {
    const insertingRect = this;
    if (insertingRect.isNotUploaded && !existingRect.isNotUploaded) {
      // the rect is already in. The current in one is not a pending one.

      // copy the original value of the existing one. Don't case it's tempData becuase this is essentially replacing it.
      // all the transform of this one will be loaded in the temp data.
      // remove "this.isNotUploaded" flag so even if revoke, this is not deleted.

      insertingRect.tempData._x = insertingRect.getActiveX();
      insertingRect.tempData._y = insertingRect.getActiveY();
      insertingRect.tempData._width = insertingRect.getActiveWidth();
      insertingRect.tempData._height = insertingRect.getActiveHeight();
      insertingRect.tempData._layer = insertingRect.getActiveLayer();

      const insertingData = insertingRect.getData();
      const existingData = existingRect.getData();
      insertingData.x = existingData.x; // ignore the tempdata of the existing one. cause replacing it is kind of override all it's tempData.
      insertingData.y = existingData.y; // ignore the tempdata of the existing one. cause replacing it is kind of override all it's tempData.
      insertingData.width = existingData.width; // ignore the tempdata of the existing one. cause replacing it is kind of override all it's tempData.
      insertingData.height = existingData.height; // ignore the tempdata of the existing one. cause replacing it is kind of override all it's tempData.
      insertingData.layer = existingData.layer; // ignore the tempdata of the existing one. cause replacing it is kind of override all it's tempData.

      this.pendingSystem.removeFromPendingList(existingRect.getID()) // incase the existing one is already in the update list. we need to remove it.

      existingRect.setTransform(insertingRect.getActiveX(), insertingRect.getActiveY(), insertingRect.getActiveWidth(), insertingRect.getActiveHeight());

      insertingRect.isNotUploaded = false;
      return true; // replace the existing one
    } else if (!insertingRect.isNotUploaded && existingRect.isNotUploaded) {
      // there is a pending one in the list. The current inserting one is newly fetched.

      // make every transform data of the pending one to the tempData.
      // copy this' data to the pending one' original data.
      // remove "existingRect.isNotUploaded" flag so even if revoke, this is not deleted.

      existingRect.tempData._x = existingRect.getActiveX();
      existingRect.tempData._y = existingRect.getActiveY();
      existingRect.tempData._width = existingRect.getActiveWidth();
      existingRect.tempData._height = existingRect.getActiveHeight();
      existingRect.tempData._layer = existingRect.getActiveLayer();

      const insertingData = insertingRect.getData();
      const existingData = existingRect.getData();

      // the newly inserting one should not have tempData.
      existingData.x = insertingData.x;
      existingData.y = insertingData.y;
      existingData.width = insertingData.width;
      existingData.height = insertingData.height;
      existingData.layer = insertingData.layer;

      existingRect.setTransform();

      existingRect.isNotUploaded = false;
      return false; // don't replace the existing one (pending one)
    } else if (insertingRect.isNotUploaded && existingRect.isNotUploaded) {
      insertingRect.tempData._x = insertingRect.getActiveX();
      insertingRect.tempData._y = insertingRect.getActiveY();
      insertingRect.tempData._width = insertingRect.getActiveWidth();
      insertingRect.tempData._height = insertingRect.getActiveHeight();
      insertingRect.tempData._layer = insertingRect.getActiveLayer();
      existingRect.setTransform(insertingRect.getActiveX(), insertingRect.getActiveY(), insertingRect.getActiveWidth(), insertingRect.getActiveHeight);
      return true;
    }
    return true;
  }
}