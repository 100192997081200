import React, { forwardRef, useState, useEffect, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';

import { SystemOperationsContext } from "../../context/SystemRunnerContext";
import { StickyDraggable } from "../../cross_page_components/StickyDraggable.jsx";
import { MarkList } from './TeleportUI.jsx';
import { BoardContentPanel } from './BoardContent.jsx';
import { BoardSettingPanel } from '../BoardSettingPanel.jsx';
import { Link, useNavigate } from 'react-router-dom';

import dragger from '../../icons/drag-move-2-fill.svg';
import exit from '../../icons/exit-sign.svg';
import heart_pink from '../../icons/heart-pink.svg'
import heart_white from '../../icons/heart-white.svg'

import arrowLeft from '../../icons/arrow-left-circle-fill.svg';
import arrowRight from '../../icons/arrow-right-circle-fill.svg';
import arrowUp from '../../icons/arrow-up-circle-fill.svg';
import arrowDown from '../../icons/arrow-down-circle-fill.svg';
import eye_on from '../../icons/eye-white.svg';
import eye_off from '../../icons/eye-off-white.svg';

import file_list from '../../icons/file-list-fill.svg';
import hand from '../../icons/hand-raised-white.svg';
import hand_no_interaction from '../../icons/hand-raised-slash-white.svg';
import pen from '../../icons/edit-fill.svg';
import auxiliaryLine from '../../icons/auxiliaryLine.svg';
import text from '../../icons/text.svg';
import paintBrush from '../../icons/paint-brush-fill-red.svg';
import bookMark from '../../icons/book-mark-fill.svg';
import floatingCanvas from '../../icons/sticky-note-fill.svg';
import actionButton from '../../icons/code-box-fill.svg';
import fenceMaker from '../../icons/fence.svg';

import jump from '../../icons/jump-forward.svg';
import mapMark from '../../icons/map-pin-2-fill.svg';
import heavyDutyTools from '../../icons/tools-line.svg';

import { useTheme } from '@mui/system';
import { Tooltip } from '@mui/material';

import Joyride, { STATUS } from 'react-joyride';
import { FenceMaker } from '../../system/FenceMaker';

const standardItemSize = 50;
const panelSize = 160;
const itemPadding = 5;

const getPanelPlacedStyle = (vertical = true, placementSide = 0, isThinWidth = false, isShortHeight = false, zIndex = 0) => {
  // placementSide -> 0: left/top; 1 => right/bottom
  const style = {
    position: "fixed",
    boxSizing: 'border-box',
    zIndex: zIndex,
    // transition: "width 0.3s ease",
  }
  if (vertical) {
    style.height = "100%";
    if (isThinWidth) {
      style.width = panelSize;
    } else {
      style.width = panelSize;
    }
    if (placementSide === 0) {
      // left
      style.left = "0px";
    } else {
      // right
      style.right = "0px";
    }
  } else {
    style.width = "100%";
    if (isThinWidth) {
      style.height = panelSize;
    } else {
      style.height = panelSize;
    }
    if (placementSide === 0) {
      // top
      style.top = "0px";
    } else {
      // bottom
      style.bottom = "0px";
    }
  }
  return style;
};

export const baseItemStyle = {
  boxSizing: 'border-box',
  borderRadius: "5px",
  backgroundColor: "rgba(0, 0, 0, 0)",
  color: "#e0e0e0",
  border: '2px solid #e0e0e0',
  cursor: "pointer",
  fontSize: "14px",
  textAlign: "center",
};

export const baseItemHoverStyle = {
  boxSizing: 'border-box',
  borderRadius: "5px",
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  color: "#ffffff",
  border: '2px solid #ffffff',
  cursor: "pointer",
  fontSize: "14px",
  textAlign: "center",
};

export const baseItemSelectedStyle = {
  boxSizing: 'border-box',
  borderRadius: "5px",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  color: "#ffffff",
  border: '2px solid #ffffff',
  cursor: "pointer",
  fontSize: "14px",
  textAlign: "center",
};

export const UltimateControlPanel = ({ }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [isThinWidth, setIsThinWidth] = useState(window.innerWidth < 500);
  const [isShortHeight, setIsShortHeight] = useState(window.innerHeight < 600);
  const [isHiden, setIsHiden] = useState(false); // always not hiden first, but in later part it might get hiden after meassured the size and position
  const [contentExtended, setContentExtended] = useState(false);
  const [contentWidth, setContentWidth] = useState(0);
  const [isPlaced, setIsPlaced] = useState(true);
  const [isVertical, setIsVertical] = useState(true);
  const [placementSide, setPlacementSide] = useState(0);
  const [saved, setSaved] = useState(false);

  const [subtructiveMode, setSubtructiveMode] = useState(system_operations.coordinator.toolpack.globalEntity === "subtraction");
  const [currentPreemptiveTool, setCurrentPreemptiveTool] = useState("hand");

  const [draggerTransform, setDraggerTransform] = useState({ size: { width: 0, height: 0 }, pos: { x: 0, y: 0 } });
  const dragStartPosition = useRef({ x: 0, y: 0 });
  const initialHidenSet = useRef(false);
  const tableContentElementRef = useRef(null);
  const theme = useTheme();

  const panelPlacedStyle = getPanelPlacedStyle(isVertical, placementSide, isThinWidth, isShortHeight, 120);
  panelPlacedStyle.backgroundColor = "#555555";

  const projectionAndPlacementCallback = (pos) => {
    if (pos === "top") {
      setIsVertical(false);
      setPlacementSide(0);
    } else if (pos === "bottom") {
      setIsVertical(false);
      setPlacementSide(1);
    } else if (pos === "left") {
      setIsVertical(true);
      setPlacementSide(0);
    } else {
      setIsVertical(true);
      setPlacementSide(1);
    }
  }

  useEffect(() => {
    system_operations.boardSaveSystem.checkIfABoardIsSaved(system_operations.getUserID(), system_operations.coordinator.getWallID()).then((saved) => {
      setSaved(saved);
    })

    const holdingDone = () => {
      setIsPlaced(true);
    }
    const changeToolCall = (newTool) => {
      if (newTool.name !== "hand") {
        setCurrentPreemptiveTool(newTool.name);
      } else {
        if (newTool.handMode === 0) {
          setCurrentPreemptiveTool("hand");
        } else {
          setCurrentPreemptiveTool("hand_block");
        }
      }
    }

    const onHandModeChange = (newMode) => {
      if (system_operations.coordinator.toolpack.get_current_tool().name === "hand") {
        if (newMode === 0) {
          setCurrentPreemptiveTool("hand");
        } else {
          setCurrentPreemptiveTool("hand_block");
        }
      }
    }

    system_operations.coordinator.toolpack.onToolChange["_UCP"] = changeToolCall;
    system_operations.coordinator.toolpack.hand.onHandModeChange["_UCP"] = onHandModeChange;
    window.addEventListener("pointerup", holdingDone);
    return () => {
      delete system_operations.coordinator.toolpack.onToolChange["_UCP"];
      delete system_operations.coordinator.toolpack.hand.onHandModeChange["_UCP"];
      window.removeEventListener("pointerup", holdingDone);
    }
  }, []);

  useEffect(() => {
    if (isHiden && tableContentElementRef.current) {
      setContentWidth(tableContentElementRef.current.getBoundingClientRect().width);
    }
  }, [isHiden]);

  const saveOrUnsaveTheBoard = async () => {
    if (system_operations.loggedIn) {
      // system_operations.set_loading(true);
      if (!saved) {
        system_operations.boardSaveSystem.addToSaved(system_operations.coordinator.getWallID()).then(() => {
        });
        system_operations.coordinator.getWallData().likes += 1;
        setSaved(true);
      } else {
        await system_operations.boardSaveSystem.removeFromSaved(system_operations.coordinator.getWallID()).then(() => {
        });
        system_operations.coordinator.getWallData().likes -= 1;
        setSaved(false);
      }
      // system_operations.set_loading(false);
    } else {
      system_operations.pleaseLogIn("Login now and never get lost!!");
    }
  }

  const setIsHolding = (pointerEvent, isHolding) => {
    dragStartPosition.current = { x: pointerEvent.clientX, y: pointerEvent.clientY };
    setIsPlaced(!isHolding);
  }

  const setSubtructiveModeFull = (subtractiveMode) => {
    setSubtructiveMode(subtractiveMode);
    system_operations.coordinator.toolpack.setSubtractiveMode(subtractiveMode);
  }

  const setDraggerTransformFull = (transform) => {
    setDraggerTransform(transform);
    if (!initialHidenSet.current) {
      initialHidenSet.current = true;
      setIsHiden(Boolean(system_operations.coordinator.getBoardData().hideToolbarByDefault));
    }
  }

  const dragBeaconStyle = { ...draggerTransform.pos, }
  if (isHiden) {
    dragBeaconStyle.backgroundColor = panelPlacedStyle.backgroundColor;
    dragBeaconStyle.borderRadius = "5px";
    dragBeaconStyle.padding = "5px";
  }

  const keyForSize = isVertical ? "width" : "height";
  const keyForMatch = isVertical ? "height" : "width";

  return (
    <div style={{ userSelect: "none", zIndex: theme.zIndex.toolBar }}>
      <StickyDraggable cancel=".inputs" zIndex={150} scale={1} initialPos={draggerTransform.pos} megActive={isHiden} allowTop={false} allowBottom={false}
        projectionCallBack={projectionAndPlacementCallback} placementCallBack={projectionAndPlacementCallback}>
        <div style={dragBeaconStyle}>
          <ToolItem
            icon={dragger} onPointerDown={(pointerEvent) => { setIsHolding(pointerEvent, true) }} className=""
            baseStyle={{ ...baseItemStyle, ...draggerTransform.size }}
            hoverStyle={{ ...baseItemHoverStyle, ...draggerTransform.size }}
            src={dragger} alt={"drag"} draggable="true" />
          {isHiden &&
            <>
              <ToolItem
                icon={eye_on} onPointerDown={() => { setIsHiden(false) }} tip="Unhide" className="input"
                baseStyle={{ ...baseItemStyle, ...draggerTransform.size }}
                hoverStyle={{ ...baseItemHoverStyle, ...draggerTransform.size }}
              />
              <div>
                <ToolItem ref={tableContentElementRef} icon={file_list} tip="Table Content" onClick={() => { setContentExtended(!contentExtended) }} selected={contentExtended}
                  baseStyle={{ ...baseItemStyle, [keyForSize]: "100%", [keyForMatch]: `${0.33 * panelSize}px` }}
                  hoverStyle={{ ...baseItemHoverStyle, [keyForSize]: "100%", [keyForMatch]: `${0.33 * panelSize}px` }}
                  selectedStyle={{ ...baseItemSelectedStyle, [keyForSize]: "100%", [keyForMatch]: `${0.33 * panelSize}px` }}
                  alt={"content"} />
              </div>
              {
                contentExtended &&
                <BoardContentPanel maxWidth={contentWidth} />
              }
            </>
          }
        </div>
      </StickyDraggable>
      { // not hiden, then check the placement status.
        !isHiden && (isPlaced ?
          <div style={panelPlacedStyle}>
            <ToolsContainer
              currentPreemptiveTool={currentPreemptiveTool} setCurrentPreemptiveTool={setCurrentPreemptiveTool}
              subtructiveMode={subtructiveMode} setSubtructiveMode={setSubtructiveModeFull}
              setIsHolding={setIsHolding}
              saved={saved} saveOrUnsaveTheBoard={saveOrUnsaveTheBoard}
              setDraggerTransform={setDraggerTransformFull}
              vertical={isVertical} placementSide={placementSide}
              isThinWidth={isThinWidth} isShortHeight={isShortHeight}
              contentExtended={contentExtended} setContentExtended={setContentExtended}
              setIsHiden={setIsHiden}
            >
            </ToolsContainer>
          </div> :
          <Projection style={panelPlacedStyle} />)
      }
    </div>
  );
}

const ToolsContainer = ({
  children, setIsHolding, vertical = true, placementSide = 0, isThinWidth = false, isShortHeight = false,
  saved, saveOrUnsaveTheBoard, setDraggerTransform, currentPreemptiveTool, setCurrentPreemptiveTool,
  subtructiveMode, setSubtructiveMode, contentExtended, setContentExtended, setIsHiden }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [teleportSelection, setTeleportSelection] = useState(0);

  const navigate = useNavigate();

  const style = {
    display: "flex",
    flexDirection: vertical ? "column" : "row",
    boxSizing: 'border-box',
    padding: itemPadding,
    [vertical ? "height" : "width"]: '100%',
  }

  if (vertical) {
    if (isThinWidth) {
      style.width = panelSize;
    } else {
      style.width = panelSize;
    }
    if (placementSide === 0) {
      // left
      style.left = "0px";
    } else {
      // right
      style.right = "0px";
    }
  } else {
    style.width = "100%";
    if (isShortHeight) {
      style.height = panelSize;
    } else {
      style.height = panelSize;
    }
    if (placementSide === 0) {
      // top
      style.top = "0px";
    } else {
      // bottom
      style.bottom = "0px";
    }
  }

  const divider = (
    <div style={{
      borderRadius: "1px", backgroundColor: "#e0e0e0",
      [vertical ? "height" : "width"]: "3px", [vertical ? "width" : "height"]: "100%",
      [vertical ? "marginBottom" : "marginRight"]: itemPadding, [vertical ? "marginTop" : "marginLeft"]: itemPadding,
    }} />
  );

  let teleportPanel = null;
  if (teleportSelection === 1) {
    teleportPanel = (<TeleportInputer />);
  } else if (teleportSelection === 2) {
    teleportPanel = (<MarkList />);
  }
  const teleportClick = (changeTo) => {
    if (changeTo === teleportSelection) {
      setTeleportSelection(0);
    } else {
      setTeleportSelection(changeTo);
    }
  }

  const handleLeaving = async () => {
    system_operations.set_loading(true);
    navigate("/");
    await system_operations.exitTheWall();
    system_operations.set_loading(false);
  }

  const subMenuStyle = {
    [vertical ? "width" : "height"]: "100%",
    [vertical ? "marginTop" : "marginBottom"]: "3px",
    backgroundColor: "rgba(50,50,50,1)",
    borderRadius: "3px",
    padding: "3px",
    boxSizing: 'border-box',
  }

  let baseToolPanel = null;
  if (currentPreemptiveTool === "hand" || currentPreemptiveTool === "hand_block") {
    baseToolPanel = (
      <div style={subMenuStyle}>
        {system_operations.coordinator.toolpack.get_current_tool().renderToolControlPanel(vertical)}
      </div>
    );
  } else if (currentPreemptiveTool === "brush" || currentPreemptiveTool === "text" || currentPreemptiveTool === "eraser") {
    baseToolPanel = (
      <div style={subMenuStyle}>
        {system_operations.coordinator.toolpack.get_current_tool().renderToolControlPanel(vertical)}
      </div>
    );
  }

  const keyForSize = vertical ? "width" : "height";
  const keyForMatch = vertical ? "height" : "width";
  const baseItemStyleFit = { ...baseItemStyle, [keyForSize]: "33%" };
  const baseItemHoverStyleFit = { ...baseItemHoverStyle, [keyForSize]: "33%" };
  const baseItemSelectedStyleFit = { ...baseItemSelectedStyle, [keyForSize]: "33%" };

  const selectPreemptiveTool = (name, mode = 0) => {
    system_operations.coordinator.toolpack.changeToolByName(name);
    if (name === "hand") {
      system_operations.coordinator.toolpack.hand.changeHandMode(mode);
    }
    // setCurrentPreemptiveTool(name);
    // no need to set hook. the onToolChange will handle the hook.
  }

  const setBoardOnScreenRatioInput = (event) => {
    const newValue = event.target.value;
    system_operations.coordinator.boardSettings.setBoardOnScreenRatio(newValue);
  }

  return (
    <div style={style}>
      <div style={{ border: '2px solid #bbbbbb', borderRadius: "5px", padding: "2px", [vertical ? "marginBottom" : "marginRight"]: '5px' }}>
        <GridContainer isVertical={vertical}>
          <ItemPlaceholder className="" setTransform={setDraggerTransform} setIsHiden={setIsHiden} isVertical={vertical} style={{ ...baseItemStyleFit, [keyForSize]: "50%" }} />
          <ToolItem icon={eye_off} tip="Hide" alt={"hide"} onClick={() => setIsHiden(true)} selected={false}
            baseStyle={{ ...baseItemStyleFit, [keyForSize]: "50%" }} hoverStyle={{ ...baseItemHoverStyleFit, [keyForSize]: "50%" }} selectedStyle={{ ...baseItemSelectedStyleFit, [keyForSize]: "50%" }} />
          <ToolItem icon={file_list} tip="Table Content" onClick={() => { setContentExtended(!contentExtended) }} selected={contentExtended}
            baseStyle={{ ...baseItemStyleFit, [keyForSize]: "100%", [keyForMatch]: `${0.33 * panelSize}px` }}
            hoverStyle={{ ...baseItemHoverStyleFit, [keyForSize]: "100%", [keyForMatch]: `${0.33 * panelSize}px` }}
            selectedStyle={{ ...baseItemSelectedStyleFit, [keyForSize]: "100%", [keyForMatch]: `${0.33 * panelSize}px` }}
            alt={"content"} />
        </GridContainer>
        {
          contentExtended &&
          <BoardContentPanel />
        }
      </div>
      <div style={{ flexGrow: 1, [vertical ? "overflowY" : "overflowX"]: "auto", border: '2px solid #bbbbbb', borderRadius: "5px", padding: "2px", [vertical ? "marginBottom" : "marginRight"]: '5px' }}>
        <GridContainer isVertical={vertical} title={"Basic Tools"}>
          <ToolItem icon={hand} tip="Hand" onClick={() => { selectPreemptiveTool("hand", 0) }} selected={currentPreemptiveTool === "hand"} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"hand"} />
          {/* <ToolItem icon={hand_no_interaction} onClick={() => { selectPreemptiveTool("hand", 1) }} selected={currentPreemptiveTool === "hand_block"} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"hand"} /> */}
          <ToolItem icon={pen} tip="Brushes" onClick={() => { selectPreemptiveTool("brush") }} selected={currentPreemptiveTool === "brush"} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"brush"} />
          <ToolItem icon={text} tip="Text" onClick={() => { selectPreemptiveTool("text") }} selected={currentPreemptiveTool === "text"} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"text"} />
          <ToolItem icon={paintBrush} tip="Removal Mode"
            onClick={() => { setSubtructiveMode(!subtructiveMode) }} selected={subtructiveMode === true}
            baseStyle={{ ...baseItemStyleFit, color: "#e06060", border: '2px solid #e04040' }}
            hoverStyle={{ ...baseItemHoverStyleFit, color: "#ff0000", border: '2px solid #ff0000', backgroundColor: "rgba(255, 0, 0, 0.2)" }}
            selectedStyle={{ ...baseItemSelectedStyleFit, border: '2px solid #ff0000', backgroundColor: "rgba(255, 0, 0, 0.5)" }}
            alt={"sub"} />
          {baseToolPanel}
        </GridContainer>
        {divider}
        <GridContainer isVertical={vertical} title='Paper'>
          <ToolItem icon={floatingCanvas} tip="Edit Papers" onClick={() => { selectPreemptiveTool("fc") }} selected={currentPreemptiveTool === "fc"} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"sticker"} />
          <ToolItem icon={auxiliaryLine} tip="Auxiliary Lines" onClick={() => { selectPreemptiveTool("auxiliaryLine") }} selected={currentPreemptiveTool === "auxiliaryLine"} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"text"} />
          {(currentPreemptiveTool === "fc" || currentPreemptiveTool === "auxiliaryLine") &&
            <div style={subMenuStyle}>
              {system_operations.coordinator.toolpack.get_current_tool().renderToolControlPanel(vertical)}
            </div>
          }
        </GridContainer>
        {divider}
        <GridContainer isVertical={vertical} title='Management'>
          <ToolItem icon={fenceMaker} tip="Create Fence Rules" onClick={() => { selectPreemptiveTool("fence") }} selected={currentPreemptiveTool === "fence"} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"settings"} />
          {(currentPreemptiveTool === "fence") &&
            <div style={subMenuStyle}>
              {system_operations.coordinator.toolpack.get_current_tool().renderToolControlPanel(vertical)}
            </div>
          }
        </GridContainer>
        {divider}
        <GridContainer isVertical={vertical} title='Teleporting'>
          <ToolItem icon={jump} tip="Jump To" onClick={() => { teleportClick(1) }} selected={teleportSelection === 1} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"jump"} />
          <ToolItem icon={mapMark} tip="Search For Marks" onClick={() => { teleportClick(2) }} selected={teleportSelection === 2} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"map"} />
          {teleportPanel}
        </GridContainer>
        {divider}
        <GridContainer isVertical={vertical} title='Settings'>
          <ToolItem icon={heavyDutyTools} tip="Setting" onClick={() => { setCurrentPreemptiveTool("settings") }} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"settings"} />
        </GridContainer>
        {(currentPreemptiveTool === "settings") &&
          <div style={subMenuStyle}>
            <BoardSettingPanel />
          </div>
        }
        {/* (currentPreemptiveTool === "auxiliaryLine") &&
          <div style={subMenuStyle}>
            {system_operations.coordinator.toolpack.get_current_tool().renderToolControlPanel(vertical)}
          </div>
        */}
        {divider}
      </div>
      <div style={{ marginTop: 'auto', border: '2px solid #bbbbbb', borderRadius: "5px", padding: "2px" }}>
        <GridContainer isVertical={vertical} title='Board'>
          <ToolItem icon={saved ? heart_pink : heart_white} onClick={saveOrUnsaveTheBoard} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"board"} />
          <ToolItem icon={exit} onClick={handleLeaving} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"board"} />
          {/* <ToolItem icon={eye_off} onClick={() => setIsHiden(true)} baseStyle={baseItemStyleFit} hoverStyle={baseItemHoverStyleFit} selectedStyle={baseItemSelectedStyleFit} alt={"board"} /> */}
        </GridContainer>
        <div style={{ marginBottom: "5px" }}>
          <div style={{ color: "#eeeeee", fontSize: `10px` }}>Zoom</div>
          <input type="range" disabled={false} style={{ width: "100%", zIndex: 1 }} min={0.3} max={5.0} step={0.01} defaultValue={system_operations.coordinator.boardSettings.boardOnScreenRatio} onChange={setBoardOnScreenRatioInput} />
        </div>
      </div>
    </div>
  )
}

const TeleportInputer = () => {
  const { system_operations } = useContext(SystemOperationsContext)
  const inputRef = useRef(null);

  const onCoordinateSubmit = () => {
    const value = Number(inputRef.current.value);
    if (value !== system_operations.coordinator.getGlobalOffset()) {
      system_operations.coordinator.setGlobalOffsetTheo(value, true);
    }
  };

  return (
    <div style={{ display: 'flex', width: '100%', marginTop: "5px" }}>
      <input
        type="number"
        ref={inputRef}
        style={{ flexGrow: 1, width: "calc(100% - 50px)", marginRight: '5px' }}
        placeholder="Enter a number"
      />
      <button onClick={onCoordinateSubmit}>GO</button>
    </div>
  );
}

const ItemPlaceholder = ({ className = ".input", style, setTransform, setIsHiden, isVertical = true }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const ref = useRef(null);

  useEffect(() => {
    const rect = ref.current.getBoundingClientRect();
    const nativeEdgeLength = isVertical ? rect.width : rect.height;
    setTransform({
      // size: { width: 67, height: 67 },  // todo
      size: { width: nativeEdgeLength, height: nativeEdgeLength },
      pos: { x: rect.left, y: rect.top },
    });
  }, [])

  return (
    <div
      className={className} ref={ref} style={style}
    />
  );
}

export const ToolItem = forwardRef(({
  icon,
  selected = false,
  disabled = false,
  baseStyle,
  hoverStyle,
  selectedStyle,
  disabledStyle = {},
  tip,
  className = ".input",
  alt = "",
  onPointerDown = () => { },
  onPointerUp = () => { },
  onClick = () => { }
}, ref) => {
  const [isPointerDown, setIsPointerDown] = useState(false);
  const [hover, setHover] = useState(false);
  useEffect(() => {
    const pointerUpOffBound = (event) => {
      if (isPointerDown) {
        onPointerUp(event);
        setIsPointerDown(false);
      }
    }
    window.addEventListener("pointerup", pointerUpOffBound);
    return () => {
      window.removeEventListener("pointerup", pointerUpOffBound);
    }
  }, [])

  if (tip) {
    return (
      <Tooltip
        enterTouchDelay={0}
        leaveTouchDelay={1500}
        title={tip} placement="bottom"
        PopperProps={{
          modifiers: [{
            options: {
              offset: [0, -10],
            }, name: 'offset',
          }],
          style: { pointerEvents: 'none' },
        }}>
        <img
          ref={ref}
          onMouseEnter={() => { if (!disabled) { setHover(true); } }}
          onMouseLeave={() => { if (!disabled) { setHover(false); } }}
          className={className}
          src={icon} alt={alt}
          style={disabled ? disabledStyle : (selected ? selectedStyle : (hover ? hoverStyle : baseStyle))}
          draggable={false}
          onPointerDown={(event) => { if (!disabled) { onPointerDown(event); setIsPointerDown(true); } }}
          onClick={(event) => { if (!disabled) { onClick(event) } }}
        />
      </Tooltip>
    );
  }

  return (
    <img
      ref={ref}
      onMouseEnter={() => { if (!disabled) { setHover(true); } }}
      onMouseLeave={() => { if (!disabled) { setHover(false); } }}
      className={className}
      src={icon} alt={alt}
      style={disabled ? disabledStyle : (selected ? selectedStyle : (hover ? hoverStyle : baseStyle))}
      draggable={false}
      onPointerDown={(event) => { if (!disabled) { onPointerDown(event); setIsPointerDown(true); } }}
      onClick={(event) => { if (!disabled) { onClick(event) } }}
    />
  );
});


export const ToolItemICONChildren = forwardRef(({
  children, // supposed to be icon
  selected = false,
  baseStyle,
  hoverStyle,
  selectedStyle,
  className = ".input",
  tip,
  alt = "",
  onPointerDown = () => { },
  onPointerUp = () => { },
  onClick = () => { }
}, ref) => {
  const [isPointerDown, setIsPointerDown] = useState(false);
  const [hover, setHover] = useState(false);
  useEffect(() => {
    const pointerUpOffBound = (event) => {
      if (isPointerDown) {
        onPointerUp(event);
        setIsPointerDown(false);
      }
    }
    window.addEventListener("pointerup", pointerUpOffBound);
    return () => {
      window.removeEventListener("pointerup", pointerUpOffBound);
    }
  }, [])

  if (tip) {
    return (
      <div
        ref={ref}
        onMouseEnter={() => { setHover(true) }}
        onMouseLeave={() => { setHover(false) }}
        className={className}
        style={selected ? selectedStyle : (hover ? hoverStyle : baseStyle)}
        draggable={false}
        onPointerDown={(event) => { onPointerDown(event); setIsPointerDown(true); }}
        onClick={onClick}>
        {children}
      </div>
    );
  }

  return (
    <div
      ref={ref}
      onMouseEnter={() => { setHover(true) }}
      onMouseLeave={() => { setHover(false) }}
      className={className}
      style={selected ? selectedStyle : (hover ? hoverStyle : baseStyle)}
      draggable={false}
      onPointerDown={(event) => { onPointerDown(event); setIsPointerDown(true); }}
      onClick={onClick}>
      {children}
    </div>
  );
});




// pos -> 0: left/top; 1 => right/bottom
// const Projection = ({vertical=true, pos=0, isThinWidth=false, isShortHeight=false, zIndex=0}) => {
const Projection = ({ style }) => {
  const activeStyle = { ...style };
  // activeStyle.border = '10px solid rgba(160,160,160, 0.9)';
  activeStyle.backgroundColor = "rgba(180, 180, 180, 0.7)";

  return (
    <div style={activeStyle}>
    </div>
  );
}


export const GridContainer = ({ children, isVertical = true, title = "" }) => {
  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: isVertical ? 'row' : 'column',
    [isVertical ? "justifyContent" : "alignItems"]: 'normal'
  };
  if (!isVertical) {
    return (
      <div style={containerStyle}>
        {children}
      </div>
    )
  } else {
    return (<div>
      <div style={{ color: "white" }}> {title} </div>
      <div style={containerStyle}>{children}</div>
    </div>);
  }
};


/* export const GridContainer = ({ children, isVertical = true }) => {
  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: isVertical ? 'row' : 'column',
    [isVertical ? "justifyContent" : "alignItems"]: 'space-between'
  };
  return <div style={containerStyle}>{children}</div>;
};*/

