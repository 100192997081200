import Home from "./pages/Home";
import BoardPage from "./pages/BoardPage";
import Login from "./pages/Login";
import TroubleLogin from "./pages/TroubleLogin";
import Register from "./pages/Register";
import LoadingScene from "./cross_page_components/LoadingScene";
import ContentWindow from './cross_page_components/ContentWindow'
import FloatPage from './cross_page_components/FloatPage'
import SystemDialog from './cross_page_components/SystemDialog'
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { SystemOperationsContext } from "./context/SystemRunnerContext";
// import PopUpWindow from './components/PopUpWindow'
import "./main.scss"

import { useSnackbar } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#424242', // This is a grey color
      contrastText: '#fff', // This is white
    },
    secondary: {
      main: '#f44336', // This is a red color
    },
  },

  shadows: Array(25).fill('0px 5px 5px 0px rgba(0, 0, 0, 0.2)'),

  zIndex: {
    background: 0,
    backgroundWhiteCover: 1,
    blurBackground: 2,

    imageColumn: 3,
    boardPanel: 5,
    baseImages: 6,
    drawingCanvas: 10,
    boardFrame: 15,

    textPost: [50, 51, 52, 53, 54, 55, 56, 100, 101, 102], // 50 - 59, 100 - 104
    mediaPosts: [60, 61, 62, 63, 64, 65, 66, 105, 106, 107], // 60 - 69, 105 - 109

    paper: [82, 85, 88, 91, 94], // 95 - 98\
    paperUnderText: [80, 83, 86, 89, 92], // 95 - 98\
    paperBorder: [95, 96, 97, 98, 99],
    paperSelection: 100,

    paper_dz: {
      dp_0: 0, // draggable panel inactive
      de_0: 1, // draggable edge active

      m_0: 2, // middle layers
      m_1: 3, // middle layers
      m_2: 4, // middle layers
      m_3: 5, // middle layers
      m_4: 6, // middle layers
      m_5: 7, // middle layers
      m_6: 8, // middle layers
      m_7: 9, // middle layers
      m_8: 10, // middle layers
      m_9: 11, // middle layers
      m_10: 12, // middle layers

      dp_1: 13, // draggable panel inactive
      de_1: 14, // draggable edge active
    },

    button: [101],
    buttonBorder: [102],

    placementGuideline: 120,

    warningZone: 200,
    boardBlock: 250,
    cursor: 260,

    customizationPanel: 300,
    toolBar: 305,
    headBar: 310,
  },

  shadowBox: {
    selectionBox: 'rgba(228, 228, 180, 0.5)',
    allowedColor: ['rgba(0, 255, 0, 0.3)', 'rgba(150, 255, 0, 0.3)', 'rgba(255, 255, 0, 0.3)'],
    disAllowedColor: ['rgba(255, 0, 0, 0.3)',],
  },

  flexRects: {
    flexRectBoarderColorsAllow: ["rgb(0, 140, 0)", "rgb(100, 235, 60)", "rgb(190, 250, 120)"],
    flexRectBoarderColorsDisallow: ["rgb(255, 0, 0)", "rgb(255, 0, 0)", "rgb(255, 0, 0)"],
    flexButtonRectBoarderColorsAllow: ["rgb(100, 255, 255)"],
    flexButtonRectBoarderColorsDisallow: ["rgb(255, 200, 100)"],

    borderRadiusTiers: [0, 5, 10, 15, 20, 25],
    // borderRadiusTiers: ['0px', '5px', '10px', '15px', '20px', '25px'],
    boxShadowTiers: ['0px 0px 0px rgba(0, 0, 0, 0)', '0px 1px 3px rgba(0, 0, 0, 0.1)', '0px 2px 6px rgba(0, 0, 0, 0.2)', '0px 3px 9px rgba(0, 0, 0, 0.3)'],
  },

  color: {
    powerOrange: "#FF7E33",
    basementGrey: "rgb(180,180,180)",
    level2Grey: "rgb(220,220,220)",

    approval: 'rgba(0, 255, 0, 1)',
    disapproval: 'rgba(255, 0, 0, 1)',
  },

  backgroundColor: {
    homePageUIBase: 'rgba(105, 105, 105, 0.8)',
    homePageUIDarker: 'rgba(96, 96, 96, 1)',
    homePageUIDarker2: 'rgba(76, 76, 76, 1)',
    homePageUIDarker3: 'rgba(64, 64, 64, 1)',
    homePageUIDarker4: 'rgba(56, 56, 56, 1)',
    homePageUIDarker5: 'rgba(48, 48, 48, 1)',
    homePageUIDarkerHighlight: 'rgba(100, 100, 100, 1)',

    wallPageToolBarBase: "rgba(128, 128, 128, 0.5)",
    wallPageUIBase: 'rgba(106, 106, 106, 0.95)',
    wallPageUIDarker: 'rgba(90, 90, 90, 0.9)',
    wallPageUILighter: 'rgba(160, 160, 160, 0.9)',
  },

  frontColor: {
    homePageTextPrimary: 'rgba(235, 235, 235, 1)',
    homePageTextSecondary: 'rgba(190, 190, 190, 1)',

    wallPageTextPrimary: 'rgba(256, 256, 256, 1)',
    wallPageTextSecondary: 'rgba(225, 225, 225, 1)',
  },

  borderRadius: {
    homePageUI: "15px",
    homePageFrontUI: "8px",
    wallPageUI: "10px",
  }
});


const greetingLog = "Hi! Thank you for visiting Drawall!\n\n"
  + "Drawall is a platform where you can communicate with others through the art of drawing. As Drawall is still in the prototype phase, they are some unfinished features and you will likely encounter some glitches, which I am diligently working to fix. Thank you for your patience!\n\n"
  + "Also, drawing mechanics are currently disabled on mobile devices. I am still experimenting around with mobile user' experience.\n\n"
  + "Your experience on Drawall matters to us. If you have any feedback or suggestions, please don't hesitate to contact us at weiyanyixin@gmail.com. Your insights are much appreciated!\n"

function App() {
  const { system_operations } = useContext(SystemOperationsContext);
  const [logGreeting, setLogGreeting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!system_operations.loggedIn) { // not logged in.
      setLogGreeting(true);
    }
    system_operations.enqueueSnackbarHook = enqueueSnackbar;
    return () => {
      system_operations.enqueueSnackbarHook = () => { };
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <SystemDialog open={logGreeting} handleClose={() => { setLogGreeting(false); }} title={
            <>
              <h2>
                Hi! Thank you for visiting Drawall!
              </h2>
              <p>
                Drawall is a platform where you can communicate with others through the art of drawing. As Drawall is still in the prototype phase, they are some unfinished features and you will likely encounter some glitches, which I am diligently working to fix. Thank you for your patience!
              </p>
              <p>
                Also, drawing mechanics are currently disabled on mobile devices. I am still experimenting around with mobile user' experience.
              </p>
              <h3>
                Your opinions matter a lot to us! If you have any feedback or suggestions, please don't hesitate to contact us at weiyanyixin@gmail.com. Your insights are much appreciated!
              </h3>
            </>
          } />
          <LoadingScene />
          <ContentWindow fieldName={"setSystemWideWindow"} />
          <ContentWindow fieldName={"setContentWindow2"} />
          <ContentWindow fieldName={"setContentWindow"} />
          <FloatPage />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="troubleLogin" element={<TroubleLogin />} />
            <Route path="register" element={<Register />} />
            <Route path="board/:location" element={<BoardPage domainType={"name"} />} />
            <Route path="board/id/:location" element={<BoardPage domainType={"id"} />} />
            <Route path="/.well-known/acme-challenge/" render={() => <p>Let's Encrypt Challenge</p>} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
