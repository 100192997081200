import React, { useState, useRef, useEffect } from 'react';
import ReactDraggable from 'react-draggable';



export const StickyDraggable = ({
  children, cancel, handle, zIndex = 0, scale = 1, initialPos = { x: 0, y: 0 }, megActive = true, projectionCallBack = () => { }, placementCallBack = () => { },
  allowTop = true, allowBottom = true, allowLeft = true, allowRight = true, givenWidth = null, givenHeight = null
}) => {
  const [position, setPosition] = useState(initialPos);
  const nodeRef = useRef(null);
  const lastData = useRef(null);

  const recordData = (data) => { lastData.current = data; }
  useEffect(() => { setPosition(initialPos); }, [initialPos]);

  /* const setPositionTo = (posWords) => {
    const documentElement = document.documentElement;
    const wrapperHeight = window.innerHeight || documentElement.clientHeight;
    const wrapperWidth = window.innerWidth || documentElement.clientWidth;

    const boundingClient = nodeRef.current.getBoundingClientRect();
    const draggableContainerWidth = boundingClient.width * scale;
    const draggableContainerHeight = boundingClient.height * scale;

    let pos = { x: 0, y: 0 }
    // switch posWords...
    // pos = { x: (wrapperWidth - draggableContainerWidth) / 2, y: (wrapperHeight - draggableContainerHeight) };
    pos = { x: 0, y: (wrapperHeight - draggableContainerHeight) / 2 };
    setPosition(pos);
  } */

  const fixDraggablePosition = (event, data, placeAfterDone = false) => {
    recordData(data);
    const documentElement = document.documentElement;
    const wrapperHeight = window.innerHeight || documentElement.clientHeight;
    const wrapperWidth = window.innerWidth || documentElement.clientWidth;

    const draggableContainerWidth = givenWidth || data.node.clientWidth * scale;
    const draggableContainerHeight = givenHeight || data.node.clientHeight * scale;

    const center = {
      x: data.x + (draggableContainerWidth / 2),
      y: data.y + (draggableContainerHeight / 2)
    };

    // console.log(nodeRef.current.getBoundingClientRect().height, data.node.clientHeight);

    const margin = {
      top: allowTop ? center.y - 0 : 99999999,
      left: allowLeft ? center.x - 0 : 99999999,
      bottom: allowBottom ? wrapperHeight - center.y : 99999999,
      right: allowRight ? wrapperWidth - center.x : 99999999,
    };

    const newPosition = {
      top: { y: 0, x: Math.max(data.x, 0) },
      left: { y: Math.max(data.y, 0), x: 0 },
      bottom: { y: (wrapperHeight - draggableContainerHeight), x: Math.max(data.x, 0) },
      right: { y: Math.max(data.y, 0), x: (wrapperWidth - draggableContainerWidth) }
    };

    const sorted = Object.keys(margin).sort((a, b) => margin[a] - margin[b]);
    const nearestSide = sorted[0];

    if (placeAfterDone && megActive) {
      placementCallBack(sorted[0]);
      setPosition(newPosition[nearestSide]);
    } else {
      projectionCallBack(sorted[0]);
    }
  }

  /* useEffect(() => {
    const replacement = () => {
      if (lastData.current) { fixDraggablePosition(null, lastData.current); }
    }
    setPositionTo(initialPos);
    window.addEventListener('resize', replacement);
    return () => {
      window.removeEventListener('resize', replacement);
    }
  }, [])

  useEffect(() => {
    if (lastData.current) { fixDraggablePosition(null, lastData.current); }
  }, [scale]) */

  return (
    <ReactDraggable
      position={position} nodeRef={nodeRef} cancel={cancel} handle={handle}
      onDrag={(event, data) => fixDraggablePosition(event, data, false)}
      onStop={(event, data) => fixDraggablePosition(event, data, true)}
    >
      <div ref={nodeRef} style={{ position: 'fixed', zIndex: zIndex }} className="handle">
        {children}
      </div>
    </ReactDraggable>
  );
};

export default StickyDraggable;