export class Positioner {
  constructor(container = null, theoWidth = 0, theoHeight = 0) {
    this.container = container;
    this.theoWidth = theoWidth;
    this.theoHeight = theoHeight;

    this.theoOffsetX = 0;
    this.theoOffsetY = 0;
    this.screenOffsetX = 0;
    this.screenOffsetY = 0;
    this.onValuesChange = {};
    this.initialized = false; 
    this.onInitializedCallback = {};
  }

  setContianer(container) {
    this.container = container;
    this.initialized = true;
    for (const key in this.onInitializedCallback) {
      this.onInitializedCallback[key]();
    }
  }

  initializeDimension(theoWidth, theoHeight) {
    this.theoWidth = theoWidth;       // how wide the element actually is
    this.theoHeight = theoHeight;     // how heigh the element actually is
  }

  // get the position of a screen pos on the element, after scale to the theoretical position.
  getTheoPositionFromScreenPosition(scrX, scrY, clamp = false) {
    if (this.container) {
      const rect = this.container.getBoundingClientRect();
      const scaleX = this.theoWidth / rect.width;
      const scaleY = this.theoHeight / rect.height;
      const coordX = (scrX - rect.left) * scaleX;
      const coordY = (scrY - rect.top) * scaleY;
      if (clamp) {
        return [Math.min(this.theoWidth, Math.max(0, coordX)), Math.min(this.theoHeight, Math.max(0, coordY))];  // the offset is clamped in range of 0 to max theo value
      } else {
        return [coordX, coordY];
      }
    } else {
      return [0, 0];
    }
  }

  getScrPositionFromScreenPosition(scrX, scrY, clamp = false) {
    if (this.container) {
      const rect = this.container.getBoundingClientRect();
      const coordX = (scrX - rect.left);
      const coordY = (scrY - rect.top);

      return [coordX, coordY];
    } else {
      return [0, 0];
    }
  }

  setPositionTheo(left, top) {
    if (this.container) {
      this.updateScale();
      if (left !== null) this.container.style.left = left;
      if (top !== null) this.container.style.top = top;
      this.updateScreenOffset();
    }
  }

  setPositionScr(left, top) {
    if (this.container) {
      if (left !== null) this.container.style.left = left;
      if (top !== null) this.container.style.top = top;
    }
  }

  updateScreenOffsetAndScale(clamp = true) {
    if (this.container) {
      const rect = this.container.getBoundingClientRect();
      this.toTheoScaleX = this.theoWidth / rect.width;
      this.toTheoScaleY = this.theoHeight / rect.height;

      this.leftScrOffset = -rect.left;
      this.topScrOffset = -rect.top;
      this.leftTheoOffset = (-rect.left) * this.toTheoScaleX;
      this.topTheoOffset = (-rect.top) * this.toTheoScaleY;

      for (const key in this.onValuesChange) {
        this.onValuesChange[key]();
      }
    }
  }

  updateScale() {
    const rect = this.container.getBoundingClientRect();
    this.toTheoScaleX = this.theoWidth / rect.width;
    this.toTheoScaleY = this.theoHeight / rect.height;
  }

  updateScreenOffset() {
    const rect = this.container.getBoundingClientRect();
    this.leftScrOffset = -rect.left;
    this.topScrOffset = -rect.top;
    this.leftTheoOffset = (-rect.left) * this.toTheoScaleX;
    this.topTheoOffset = (-rect.top) * this.toTheoScaleY;
  }

  scaleToTheo(value, refresh = false, useWidth = true) {
    if (refresh) { this.updateScale() }
    return value * this.toTheoScaleX;
  }

  scaleToScr(value, refresh = false, useWidth = true) {
    if (refresh) { this.updateScale() }
    return value / this.toTheoScaleX;
  }

  getTheoToScrScale(refresh = false, useWidth = true) {
    if (refresh) { this.updateScale() }
    return 1 / this.toTheoScaleX;
  }

  getScrToTheoScale(refresh = false, useWidth = true) {
    if (refresh) { this.updateScale() }
    return this.toTheoScaleX;
  }


  // the curent view screen' left edge position on this element
  getCurrentScreenLeftOffset(recalculate = false, clamp = false, theo = true) {
    if (recalculate) {
      this.updateScreenOffsetAndScale();
    }
    if (clamp) {
      const theoLeft = Math.min(this.theoWidth, Math.max(0, this.leftTheoOffset));
      return theo ? theoLeft : theoLeft / this.toTheoScaleX;         // return clamped theo or coresponding scr space.
    } else {
      return theo ? this.leftTheoOffset : this.leftScrOffset;
    }
  }

  // the curent view screen' top edge position on this element
  getCurrentScreenTopOffset(recalculate = false, clamp = false, theo = true) {
    if (recalculate) {
      this.updateScreenOffsetAndScale();
    }
    if (clamp) {
      const theoTop = Math.min(this.theoHeight, Math.max(0, this.topTheoOffset))
      return theo ? theoTop : theoTop / this.toTheoScaleY;
    } else {
      return theo ? this.topTheoOffset : this.topScrOffset;
    }
  }
}

export class DrawingCanvas {
  constructor(positioner) {
    this.positioner = positioner;
    this.positioner.onValuesChange["_"] = this.resetCanvasPosition.bind(this);
    this.theoMaxWidth = 0;
    this.theoMaxHeight = 0;
    this.canvas = null;

    this.canvasTheoOffset = [0, 0];
    this.canvasScrOffset = [0, 0];
  }

  setCanvas(canvas) {
    this.canvas = canvas;
  }

  initializeDimension(theoMaxWidth, theoMaxHeight, isHorizontal = true) {
    this.isHorizontal = isHorizontal;
    this.theoMaxWidth = theoMaxWidth;
    this.theoMaxHeight = theoMaxHeight;
  }

  assignHooks(setCanvasTransform) {
    this.setCanvasTransform = setCanvasTransform;
  }

  getEventOnCanvasTheoPosition(eventX, eventY) {
    const canvasRect = this.canvas.getBoundingClientRect();
    const coordX = (eventX - canvasRect.left) * (this.canvas.width / canvasRect.width);
    const coordY = (eventY - canvasRect.top) * (this.canvas.height / canvasRect.height);
    return [coordX, coordY];
  }

  getEventOnCanvasScrPosition(eventX, eventY) {
    const canvasRect = this.canvas.getBoundingClientRect();
    const coordX = (eventX - canvasRect.left);
    const coordY = (eventY - canvasRect.top);
    return [coordX, coordY];
  }

  // called by the boards' positoner.
  resetCanvasPosition() {
    const toSrcScaleX = 1 / this.positioner.toTheoScaleX;
    const toSrcScaleY = 1 / this.positioner.toTheoScaleY;

    const screenTheoLeft = this.positioner.getCurrentScreenLeftOffset(false, true);     // the theo position of screen left. clamped to be greater than 0
    const screenTheoTop = this.positioner.getCurrentScreenTopOffset(false, true);       // the theo position of the screen top. clampped to be greater than 0

    // this.canvasTheoWidth = Math.min(this.positioner.theoWidth - this.canvasTheoOffset[0], this.theoMaxWidth);
    // this.canvasTheoHeight = Math.min(this.positioner.theoHeight - this.canvasTheoOffset[1], this.theoMaxHeight);
    this.canvasTheoWidth = this.theoMaxWidth;
    this.canvasTheoHeight = this.theoMaxHeight;
    this.canvasScreenSpaceWidth = this.canvasTheoWidth * toSrcScaleX
    this.canvasScreenSpaceHeight = this.canvasTheoHeight * toSrcScaleY

    const remainingSpaceForWidth = this.positioner.theoWidth - screenTheoLeft;
    const remainingSpaceForHeight = this.positioner.theoHeight - screenTheoTop;
    const shiftLeft = Math.max(0, this.canvasTheoWidth - remainingSpaceForWidth);
    const shiftUp = Math.max(0, this.canvasTheoHeight - remainingSpaceForHeight);

    this.canvasTheoOffset = [screenTheoLeft - shiftLeft, screenTheoTop - shiftUp];          // the result is clamped
    this.canvasScrOffset = [this.canvasTheoOffset[0] * toSrcScaleX, this.canvasTheoOffset[1] * toSrcScaleY];

    const transform = {
      displayW: this.canvasScreenSpaceWidth, displayH: this.canvasScreenSpaceHeight,
      theoW: this.canvasTheoWidth, theoH: this.canvasTheoHeight,
      displayX: this.canvasScrOffset[0], displayY: this.canvasScrOffset[1],
      theoX: this.canvasTheoOffset[0], theoY: this.canvasTheoOffset[1],
    };
    if (this.setCanvasTransform) {
      this.setCanvasTransform(transform);
    }
    return transform;
  }

  getCanvasTheoOffset() {
    return this.canvasTheoOffset;
  }

  getCanvasScrOffset() {
    return this.canvasScrOffset;
  }
}



/*
const screenSpaceOffset = this.positioner.getScreenSpaceOffset();
    // will be placed stick to the screen' left, but if exceed the container' left, then it will be placed stick to the container' left edge.
    this.screenOffsetX = Math.max(0, screenSpaceOffset[0]); // where the canvas will be placed horizontally in the container
    this.screenOffsetY = Math.max(0, screenSpaceOffset[1]);  // where the canvas will be placed vertically in the container

    const [scaleX, scaleY] = this.positioner.getScreenSpaceToTheoretialSpaceScale();
    this.theoOffsetX = this.screenOffsetX * scaleX;
    this.theoOffsetY = this.screenOffsetY * scaleY;
    this.canvasScreenSpaceWidth = this.theoMaxWidth / scaleX;
    this.canvasScreenSpaceHeight = this.theoMaxHeight / scaleY;
    
  resetCanvasPosition() {
    const [theoOffsetX, theoOffsetY] = this.positioner.getTheoSpaceOffset();  // screen' left and top edge to the container' left and top in theoretical space (before scaling)
    const [screenOffsetX, screenOffsetY] = this.positioner.getScreenSpaceOffset();  // screen' left and top edge to the container' left and top in theoretical space (before scaling)
    const [toTheoScaleX, toTheoScaleY] = this.positioner.getScreenSpaceToTheoretialSpaceScale();

    this.canvasTheoWidth = Math.min(this.positioner.theoWidth - theoOffsetX, this.theoMaxWidth);
    this.canvasTheoHeight = Math.min(this.positioner.theoHeight - theoOffsetY, this.theoMaxHeight);
    this.canvasScreenSpaceWidth = this.canvasTheoWidth / toTheoScaleX;
    this.canvasScreenSpaceHeight = this.canvasTheoHeight / toTheoScaleY;

    if (this.setCanvasTransform) {
      this.setCanvasTransform({
        displayW: this.canvasScreenSpaceWidth, displayH: this.canvasScreenSpaceHeight,
        theoW: this.canvasTheoWidth, theoH: this.canvasTheoHeight,
        displayX: screenOffsetX, displayY: screenOffsetY,
        theoX:toTheoScaleX, theoY: toTheoScaleY,
      });
    }
  }
*/