import { IHistoryBroadcast, IHistoryEntry, klHistory } from './klecks/history/kl-history';
import { TBrush } from './klecks/brushes/brushes';
import { KL } from './klecks/kl';
import { CanvasOperations } from './KlecksOnlyDrawSupport';


/*
setCurrentCtx:  mostly for the ui to know what canvas is currently working on. but also should track the current ctx for other undo and redo.
getCurrentCtx:  get the current focus ctx.
getCtxByID:     take an ID, then return the correct ctx from the ID
*/
export class RedoAndUndoCatch {
    private brushUiMap: any;
    private getCurrentCtx: () => CanvasRenderingContext2D;
    private setCurrentCtx: (id: string) => boolean;
    private getCtxByID: (id: string) => CanvasRenderingContext2D;
    private getCurrentBrush: () => any
    private getInitState: () => any
    private utilites: any = {};
    private canvasOperations: CanvasOperations;

    private test: any = null;

    private skipDrawActions: boolean = false;

    constructor(p: {
        getCurrentCtx: () => CanvasRenderingContext2D,
        setCurrentCtx: (id: string) => boolean,
        getCtxByID: (id: string) => CanvasRenderingContext2D,
        getCurrentBrush: () => any,
        getInitState: () => any,
        canvasOperations: CanvasOperations,
        brushes: any,

        otherUtilities: {},
    }) {
        this.getCurrentCtx = p.getCurrentCtx;
        this.setCurrentCtx = p.setCurrentCtx;
        this.getCtxByID = p.getCtxByID;
        this.getCurrentBrush = p.getCurrentBrush;
        this.getInitState = p.getInitState;
        this.utilites = p.otherUtilities;
        this.canvasOperations = p.canvasOperations;
        this.brushUiMap = p.brushes;
    }


    redo(): boolean {
        if (!klHistory.canRedo()) { return false; }
        const initState = this.getInitState();
        this.skipDrawActions = false;
        const entry = klHistory.redo();
        if (!entry) {
            setTimeout(() => {
                throw new Error('redo failed. redo entry undefined');
            });
            return false;
        }
        klHistory.pause(true);
        const brushes: any = {};
        Object.entries(KL.brushes).forEach(([b, brush]) => {
            brushes[b] = new brush();
            brushes[b].setContext(this.getCurrentCtx());
        });
        brushes.SketchyBrush.setSeed(this.brushUiMap.sketchyBrush.getSeed());
        this.execHistoryEntry(
            entry,
            "main", // doesn't matter, because result not used
            brushes,
            this.setCurrentCtx.bind(this),
            this.getCurrentCtx.bind(this),
            this.getCtxByID.bind(this),
        );
        this.brushUiMap.sketchyBrush.setSeed(brushes.SketchyBrush.getSeed());
        this.getCurrentBrush().setContext(this.getCurrentCtx());
        klHistory.pause(false);
        this.skipDrawActions = false;
        return true;
    }

    // needs initState, which tells which canvas we were working on.
    // might need to load the original state of the ctx.
    undo(): boolean {
        if (!klHistory.canUndo()) { return false; }
        const initState = this.getInitState();
        this.skipDrawActions = false;
        const entries: IHistoryEntry[] = klHistory.undo();
        klHistory.pause(true);
        let canvasID = initState.focus;
        const allLoadedCanvases = new Set();                   // track if a canvas has loaded the backup stage.
        const success = this.setCurrentCtx(canvasID);   // change the current ctx to the original state' one.
        if (!success) { this.skipDrawActions = true; }
        allLoadedCanvases.add(canvasID);
        const backupCtx = initState.backTrackData[canvasID];
        if (backupCtx && success) { // draw the backup ctx to the actual ctx.
            const currentCtx = this.getCurrentCtx();
            currentCtx.clearRect(0, 0, currentCtx.canvas.width, currentCtx.canvas.height);
            // console.log(currentCtx.canvas.toDataURL());
            currentCtx.drawImage(backupCtx.canvas, 0, 0); // draw the backup ctx ontop of the current ctx. current ctx will be loaded by execHistoryEntry
        }
        const brushes: any = {};
        Object.entries(KL.brushes).forEach(([b, brush]) => {
            brushes[b] = new brush();
            brushes[b].setContext(this.getCurrentCtx());    // set all the tools' canvas to the init state' canvas
        });
        brushes.SketchyBrush.setSeed(initState.brushes.SketchyBrush.getSeed());
        entries.forEach(entry => {
            canvasID = this.execHistoryEntry(
                entry,
                canvasID,
                brushes,
                this.setCurrentCtx.bind(this),
                this.getCurrentCtx.bind(this),
                this.getCtxByID.bind(this),
            );
            // console.log("after the execution, this is the canvasID", canvasID);
            if (!allLoadedCanvases.has(canvasID)) {
                allLoadedCanvases.add(canvasID);
                const backupCtx = initState.backTrackData[canvasID];
                const actualCtx = this.getCtxByID(canvasID);
                if (backupCtx && actualCtx) { // better check the existance of the current ctx...
                    actualCtx.clearRect(0, 0, actualCtx.canvas.width, actualCtx.canvas.height);
                    actualCtx.drawImage(backupCtx.canvas, 0, 0); // draw the backup ctx ontop of the current ctx. current ctx will be loaded by execHistoryEntry
                }
            }
        });
        this.brushUiMap.sketchyBrush.setSeed(brushes.SketchyBrush.getSeed());
        this.getCurrentBrush().setContext(this.getCurrentCtx());
        klHistory.pause(false);
        this.skipDrawActions = false;
        return true;
    }

    /* readInitStateCtx(initState: any, id: string) {
        if (!initState.backTrackData[id]) {
            // complain
        } else {
            if (initState.backTrackData[id].isCtx) {
                return initState.backTrackData[id].back;
            } else {
                // create a new canvas.
                const backupCanvas = document.createElement("canvas");
                backupCanvas.width = initState.backTrackData[id].width;
                backupCanvas.height = initState.backTrackData[id].height;
                const backupCtx = backupCanvas.getContext("2d");
                initState.backTrackData[id].back = backupCtx;
                initState.backTrackData[id].isCtx = true;
                this.test = backupCtx;
                return backupCtx;
            }
        }
        return this.getCtxByID(id);
    } */

    resizeInitStateCtx(localCurrentCtx: CanvasRenderingContext2D, currentCtxOfTheFocus: CanvasRenderingContext2D) {
        // handle resize. say if the older ctx' canvas is smaller than the current, then we need to stretch back ctx to match the size of width and height of the current ctx.
        let enlargeW = false;
        let enlargeH = false;
        if (!localCurrentCtx) console.trace(localCurrentCtx)
        if (localCurrentCtx.canvas.width < currentCtxOfTheFocus.canvas.width) {
            enlargeW = true; // enlarge the localCurrentCtx.
        }
        if (localCurrentCtx.canvas.height < currentCtxOfTheFocus.canvas.height) {
            enlargeH = true; // enlarge the localCurrentCtx.
        }
        if (enlargeW || enlargeH) {
            const imageDataOri = localCurrentCtx.getImageData(0, 0, localCurrentCtx.canvas.width, localCurrentCtx.canvas.height);
            if (enlargeW) { localCurrentCtx.canvas.width = currentCtxOfTheFocus.canvas.width; }
            if (enlargeH) { localCurrentCtx.canvas.height = currentCtxOfTheFocus.canvas.height; }
            localCurrentCtx.putImageData(imageDataOri, 0, 0); // after enlarging, put back the image data.
        }
    }

    getFocusCtxFromInitState(initState: any) {
        // const localCurrentCtx = this.readInitStateCtx(initState, initState.focus);
        let localCurrentCtx = initState.backTrackData[initState.focus];
        if (!localCurrentCtx) { // do not exist yet, create one for it.
            // can only happen if allow change focus without drawing anything
            localCurrentCtx = this.utilites.createInitStateCtx(initState.focus); // create one for the state.
        }
        const currentCtxOfTheFocus = this.getCtxByID(initState.focus);
        this.resizeInitStateCtx(localCurrentCtx, currentCtxOfTheFocus);
        return localCurrentCtx;
    }

    getCtxFromInitStateByID(initState: any, id: string) {
        // const initStateCtx = this.readInitStateCtx(initState, id);
        let initStateCtx = initState.backTrackData[id];
        if (!initStateCtx) {    // do not exist yet, create one for it.
            // can only happen if allow change focus without drawing anything
            initStateCtx = this.utilites.createInitStateCtx(initState.focus); // create one for the state.
        }
        const currentCtxOfTheFocus = this.getCtxByID(id);
        this.resizeInitStateCtx(initStateCtx, currentCtxOfTheFocus);
        return initStateCtx;
    }

    // this will trace the old marks that is removed from the history.
    catchup(logParam: IHistoryBroadcast | null): void {
        if (!logParam || !logParam.bufferUpdate) { return; }
        const initState = this.getInitState();
        let localCurrentCtx = this.getFocusCtxFromInitState(initState);

        // let localCurrentCtx = this.getCtxByID(initState.focus); // should not get the current CTX, should get a backup ctx which contain max history entry steps away from current stage. 
        // in execHistoryEntry, it might set the ctx as well. however, as in this loop, ctx will only be the focus one, so it's always ok to preset it.
        // Object.entries(initState.brushes).forEach(([, brush] : any) => brush.setContext(localCurrentCtx));
        initState.focus = this.execHistoryEntry(
            logParam.bufferUpdate,
            initState.focus,
            initState.brushes,
            (id: string) => {
                const switchToCtx = this.getCtxFromInitStateByID(initState, id);
                if (switchToCtx) {
                    localCurrentCtx = switchToCtx;
                    return true;
                }
                return false;
            }, // get the ctx, then set to the localCurrent
            () => { return localCurrentCtx },
            (id: string) => { return this.getCtxFromInitStateByID(initState, id); },
        );
        // console.log('catchup', performance.now() - start);
    }

    // take a canvasID, then return a canvasID.
    // the reason it wants a canvasID is to keep track with things. Not to read it.
    // the get/set functions to ctx may not to the current ones. can be given to any set of ctx.
    execHistoryEntry(
        historyEntry: IHistoryEntry,
        canvasID: string,
        brushes: any[], // should be an array of Brush, not the UI list...
        setCurrentCtx: (canvasID: string) => boolean,                                       // mostly for the ui to know what canvas is currently working on. but also should track the current ctx for other undo and redo.
        getCurrentCtx: () => CanvasRenderingContext2D,                                      // get the current focus ctx.
        getCtxByID: (canvasID: string) => CanvasRenderingContext2D,                         // take an ID, then return the correct ctx from the ID
    ): string {
        // do not use "canvasID", cause in redo, it won't be right. it's used only for tracking purposes.
        // console.log(historyEntry);
        // console.log("this is just a ctach up. see how many time it happens...");
        if (historyEntry.tool[0] === 'brush' && !this.skipDrawActions) {
            // console.log(canvasID, "in draw, htis is the layer.");
            const b = brushes[historyEntry.tool[1] as any];
            historyEntry.actions!.forEach(action => {
                (b[action.action as keyof TBrush] as any)(...action.params);
            });
        } else if (historyEntry.tool[0] === 'misc' && historyEntry.action === 'focusLayer') {
            canvasID = historyEntry.params![0];
            const ctx = getCtxByID(canvasID);
            if (!ctx) {
                this.skipDrawActions = true;
            } else {
                this.skipDrawActions = false;
            }
            setCurrentCtx(canvasID);
            Object.entries(brushes).forEach(([, brush]) => brush.setContext(ctx));
            // console.log("change the ctx: ", canvasID);

        } else if (historyEntry.tool[0] === 'text') {
            const p = historyEntry.params!;
            // console.log(p[1], "text on this layer");
            const ctx = getCtxByID(p[1]);
            if (!ctx) {
                this.skipDrawActions = true;
            } else {
                console.trace("before: ", historyEntry.action, p, "==========================================================================");
                this.canvasOperations.text(ctx.canvas, {...p[0]}); // get the first.
                this.skipDrawActions = false;
            }
            // const id = (klCanvas[historyEntry.action as keyof KlCanvas] as any)(...p);
            /* if (typeof id === 'number') {
                layerIndex = id;
                const ctx = throwIfNull(klCanvas.getLayerContext(layerIndex)) as KlCanvasContext;
                setCurrentLayerCtx(ctx);
                Object.entries(brushes).forEach(([, brush]) => brush.setContext(ctx));
            } */
        } /*  else if (historyEntry.tool[0] === 'misc' && historyEntry.action === 'importImage') {
        const id = klCanvas.addLayer();
        if (typeof id === 'number') {
            layerIndex = id;
            if (historyEntry.params![1]) {
                klCanvas.renameLayer(layerIndex, historyEntry.params![1]);
            }
            const ctx = throwIfNull(klCanvas.getLayerContext(layerIndex)) as KlCanvasContext;
            setCurrentCtx(ctx);
            Object.entries(brushes).forEach(([, brush]) => brush.setContext(ctx));
        }
        getCurrentCtx().drawImage(historyEntry.params![0], 0, 0);
    }*/
        return canvasID;
    }
}