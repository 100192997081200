import { getAuth, createUserWithEmailAndPassword, signInWithCustomToken, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFirestore, doc, addDoc, setDoc, getDoc, updateDoc, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

import { PostSystem } from "./PostSystem"
import { WallSystem } from "./WallSystem"
import { FirestoreActions } from "./FirestoreActions"

import { RequestDataMaker } from "../system/RestServerConnector.js"

export class AuthSystem {
  constructor(app) {
    this.auth = getAuth(app);
    this.firestore = getFirestore(app);
    this.storage = getStorage(app);

    this.wallSystem = new WallSystem(app);
  }

  async signIn(email, password) {
    try {
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
      return userCredential;
    } catch (err) {
      // return err.message;
      return "Usarneme end passward du not macth, chack misspalling";
    }
  }

  async signUp(system, email, password, username) {
    try {
      system.preventNextUserDefaultBehavior = 1; // source: xxdjhfhdfunfnsafjsdajfosad
      const userCreationData = await RequestDataMaker.getDataToCreateAUserWithEmail(email, password, {username});
      const result = await system.restServerConnector.createNewUserWithEmail(userCreationData); // create essencial data on firestore.

      if (result && result.success && result.customToken) {
        const userCredential = await signInWithCustomToken(this.auth, result.customToken);
        return userCredential;
      }
      return result?.message || "unknown error...";
    } catch (err) {
      return err.message;
    }
    return "unknown error...";
  }

  async signOut() {
    signOut(this.auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
        console.error('Error signing out', error);
      });
  }

  async storage_uploadAvatar(userID, file) {
    const path = `users/${userID}/profile/avatar`;
    const storageRef = ref(this.storage, path);
    if (file) {
      const acceptedImageTypes = ['image/png', 'image/jpeg', 'image/gif'];
      if (acceptedImageTypes.includes(file.type)) {
        await uploadBytes(storageRef, file);
      } else {
        throw new Error('Invalid image file format. Accepted formats are PNG, JPEG, and GIF.');
      }
    }
  }

  async FollowToAUser(userID, followToID) {
    const followingDoc = doc(this.firestore, `users/${userID}/following/${followToID}`);
    const followerDoc = doc(this.firestore, `users/${followToID}/followers/${userID}`);
    await setDoc(followingDoc, {
      userID: followToID,
    });
    await setDoc(followerDoc, {
      userID: userID,
    });
  }

  async AddToVisitHistory(userID, wallID) {
    const wallDoc = doc(this.firestore, `users/${userID}/recentVisits/${wallID}`);
    await setDoc(wallDoc, {
      date: Date.now()
    });
  }

  async firestore_addToUserTemplete(userID, email, username, firstBoardID) {
    const userDocRef = doc(this.firestore, "users", userID);
    const avatarURL = 'none';
    await setDoc(userDocRef, {
      email,
      username,
      avatar: avatarURL,
    });

    const ownBoardData = { "favorite": true }
    await FirestoreActions.static_createDocumentAt(`users/${userID}/boards`, ownBoardData, "boardID", firstBoardID);
  }

  async tryFetchAFieldFromUserFirestore(userID, field, subcollectionName = "", documentID = "default") {
    try {
      if (subcollectionName) {
        const documentRef = doc(this.firestore, 'users', userID, subcollectionName, documentID);
        const documentSnapshot = await getDoc(documentRef);

        if (documentSnapshot.exists()) {
          const documentData = documentSnapshot.data();
          if (documentData.hasOwnProperty(field)) {
            return documentData[field];
          }
        }
      } else {
        const userRef = doc(this.firestore, 'users', userID);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          if (userData.hasOwnProperty(field)) {
            return userData[field];
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    return null;
  }

  async tryWriteAFieldToUserFirestore(userID, field, value, subcollectionName = "", documentID = "default") {
    try {
      if (subcollectionName) {
        const documentRef = doc(this.firestore, 'users', userID, subcollectionName, documentID);
        await updateDoc(documentRef, { [field]: value });
      } else {
        const userRef = doc(this.firestore, 'users', userID);
        await updateDoc(userRef, { [field]: value });
      }
      return true;
    } catch (error) {
      console.error('Error writing data:', error);
      return false;
    }
  }
}
