import React, { useState, useRef, useEffect } from 'react';
const Input_Height = 50;

export const PaperChatBox = ({ paperRect }) => {
  const [messages, setMessages] = useState([{ text: "The demo for chat box feature. COMING SOON.", id: "demo" }]);
  const [inputValue, setInputValue] = useState('');

  const sendMessage = (e) => {
    e.preventDefault();
    const newMessages = [...messages, { text: inputValue, id: messages.length }];
    setMessages(newMessages);
    setInputValue('');
  };

  return (
    <div style={{
      width: `${paperRect.getActiveWidth()}px`,
      height: `${paperRect.getActiveHeight()}px`,
      boxSizing: 'border-box',
      backgroundColor: '#f7f7f7',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      zIndex: "-1000"
    }}>
      <MessageArea messages={messages} areaMaxHeight={paperRect.getActiveHeight() - Input_Height}/>
      <MessageInput
        inputValue={inputValue}
        setInputValue={setInputValue}
        sendMessage={sendMessage}
        height={Input_Height}
      />
    </div>
  );
};


const MessageArea = ({ messages, areaMaxHeight }) => {
  const containerRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(null);
  const stopScrolling = () => setIsScrolling(null);

  const scrollUp = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ top: -100, behavior: 'smooth' });
    }
  };

  // Function to handle scrolling down
  const scrollDown = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ top: 100, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    let intervalId;
    if (isScrolling === 'up') {
      intervalId = setInterval(() => {
        if (containerRef.current) {
          containerRef.current.scrollBy({ top: -100, behavior: 'smooth' });
        }
      }, 100);
    } else if (isScrolling === 'down') {
      intervalId = setInterval(() => {
        if (containerRef.current) {
          containerRef.current.scrollBy({ top: 100, behavior: 'smooth' });
        }
      }, 100);
    }
    return () => clearInterval(intervalId);
  }, [isScrolling]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  const scrollButtonHeight = 25;
  return (
    <div>
      <button
        onMouseDown={scrollUp}
        // onPointerDown={() => setIsScrolling('up')}
        style={{
          cursor: 'pointer',
          top: 0,
          width: "100%", height: scrollButtonHeight,
          padding: '5px',
          border: 'none',
          background: 'transparent',
          fontSize: '16px',
          boxSizing: 'border-box',
          fontSize: "9px",
        }}
        onMouseOver={(e) => (e.currentTarget.style.background = '#e0e0e0')}
        onMouseOut={(e) => (e.currentTarget.style.background = 'transparent')}
      >UP</button>
      <div
        ref={containerRef}
        style={{
          height: `${areaMaxHeight - scrollButtonHeight*2 - 7}px`,
          overflowY: 'hidden',
          padding: '10px',
          display: 'flex', // This makes the container a flex container
          flexDirection: 'column', // This sets the flex direction to column
          boxSizing: 'border-box',
        }}>
        {messages.map((message, index) => (
          // Assuming "message" object has an "isOwner" boolean to indicate if the message is from the owner
          <Message key={index} text={message.text} isOwner={message.id !== "demo"} />
        ))}
        <div />
      </div>
      <button
        onMouseDown={scrollDown}
        // onPointerDown={() => setIsScrolling('down')}
        style={{
          cursor: 'pointer',
          bottom: 0,
          width: "100%", height: scrollButtonHeight,
          padding: '5px',
          border: 'none',
          background: 'transparent',
          fontSize: '16px',
          boxSizing: 'border-box',
          fontSize: "9px",
        }}
        onMouseOver={(e) => (e.currentTarget.style.background = '#e0e0e0')}
        onMouseOut={(e) => (e.currentTarget.style.background = 'transparent')}
      >
        DOWN
      </button>
    </div >
  );
};


const Message = ({ text, isOwner }) => {
  const messageStyle = {
    backgroundColor: isOwner ? '#ADD8E6' : '#e0e0e0',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    borderRadius: '20px',
    padding: '10px 20px',
    maxWidth: '80%',
    alignSelf: isOwner ? 'flex-end' : 'flex-start',
    marginBottom: '10px',
    overflowWrap: 'break-word',
  };

  return (
    <div style={messageStyle}>
      {text}
    </div>
  );
};

const MessageInput = ({ inputValue, setInputValue, sendMessage, inputHeight }) => {
  const sendMessageFull = (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      sendMessage(e);
    }
  }

  return (
    <form onSubmit={sendMessageFull} style={{
      display: 'flex',
      borderTop: '1px solid #e0e0e0',
      padding: '10px',
      boxSizing: 'border-box',
      height: `${inputHeight}px`,
    }}>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Type a message..."
        style={{
          flexGrow: 1,
          border: 'none',
          padding: '10px',
          fontSize: '16px',
          marginRight: '10px',
          borderRadius: '4px',
        }}
      />
      <button type="submit" style={{
        padding: '10px 20px',
        border: 'none',
        backgroundColor: '#009688',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '4px',
      }}>
        Send
      </button>
    </form>
  );
};