export class PopUpController{
    constructor() {
        this.set_pop_up_window_hook = null;
        this.pop_up_window_open = false;
    }

    assign_pop_up_window_hooks(set_has_pop_up, set_pop_up_window) {
        this.set_pop_up_window_hook = set_pop_up_window;
        this.set_has_pop_up_hook = set_has_pop_up;
    }

    set_pop_up_window(type, message, onConfirm, onCancel, onInputChange = (nothing) => {}, default_input_value = "") {
        const new_pop_up_props = {
            "type": type,
            "message": message,
            "onInputChange": onInputChange,
            "default_input_value": default_input_value,
            "onConfirm": () => {
                onConfirm();
                this.pop_up_window_open = false;
                this.set_has_pop_up_hook(false);
            },
            "onCancel": () => {
                onCancel();
                this.pop_up_window_open = false;
                this.set_has_pop_up_hook(false);
            }
        }
        
        this.set_pop_up_window_hook(new_pop_up_props);
    }
}