/**
 * by bitbof (bitbof.com)
 */

import './polyfills/polyfills';
import { KL } from './klecks/kl';
import { BB } from './bb/bb';
import { IHistoryEntry, DecoyKlHistory, KlHistoryInterface, klHistory } from './klecks/history/kl-history';
import { KlApp } from './app/kl-app';
import { IKlProject, IRGB, IRGBA, ISliderConfig } from './klecks/kl-types';
import { PressureNormalizer } from './bb/input/pressure-normalizer';
import { eventUsesHighResTimeStamp, hasPointerEvents, isFirefox } from './bb/base/browser';
import { IPointerEvent, IWheelEvent, TPointerButton, TPointerEventType, TPointerType } from './bb/input/event.types';
import { IBounds, IPressureInput, IVector2D } from './bb/bb-types';
import { EventChain } from './bb/input/event-chain/event-chain';
import { ICoalescedPointerEvent } from './bb/input/event-chain/coalesced-exploder';
import { KeyListener, TOnKeyDown, TOnKeyUp } from './bb/input/key-listener';
import { LinetoolProcessor } from './klecks/events/linetool-processor';
import { TVec4 } from './bb/math/matrix';
import { LineSmoothing } from './klecks/events/line-smoothing';
import { LineSanitizer } from './klecks/events/line-sanitizer';
import { translateSmoothing } from './klecks/utils/translate-smoothing';
import { textToolDialog } from './klecks/ui/modals/TextToolDialogBone';
import { RedoAndUndoCatch } from './onlyDrawRedoAndUndo';
import { renderText } from './klecks/image-operations/render-text';
import {IBrushUi} from './klecks/kl-types';
import '../script/theme/theme';

// draw functionalities,
// set properties
// undo/redo
// set tool

export class KDrawAPI {
  private brushUiMap: {   // this is the api access to the brushes. sometime we don't like to directly access brushes.
    [key: string]: any;
  } = {};
  private brushMap: {     // this the actual brush map.
    [key: string]: any;
  } = {};
  private getCurrentCanvasID: () => string;
  private getCurrentCtx: () => CanvasRenderingContext2D;
  private setCurrentCtx: (id: string) => boolean;
  private getCtxByID: (id: string) => CanvasRenderingContext2D;
  private globalPositionToLocalPosition: (x: number, y: number) => [x: number, y: number];
  private textPromptOn: (p: any) => void;
  private textPromptOff: () => void;
  private allowComboKeys: () => boolean;
  private updateCurrentCanvasValidBoundaryByBrush: (x: number, y: number, size: number)=>any;
  private updateCurrentCanvasValidBoundaryByText: (pivotGlobalX:number, pivotGlobalY:number, pivotRatioX:number, pivotRatioY:number, angleRad:number, width:number, height:number)=>any;
  private pointerEventProcessors: any;                           // an object of {pointerDownProcessor, pointerMoveProcessor, pointerUpProcessor}
  // private brushSettingService: any; // actually: BrushSettingService;
  private currentBrushUi: any;
  private redoAndUndoCatch: RedoAndUndoCatch;
  private eventProcessor: EventProcessor;
  private canvasOperations: CanvasOperations;
  private currentColor: IRGB; // should be a KRGB
  private globalMode: TMode;

  private currentBrushId: string = "";
  private lastNonEraserBrushId: string = "";

  private currentCanvasID: string = "";
  private currentLayerCtx: CanvasRenderingContext2D;

  private backTrackData: any = {};                                 // track the ctx history up to max undo allowance.
  private initState: {
    focus: string,
    brushes: any,
    backTrackData: any,           // id -> ctx.
  };

  constructor(p: {
    getCurrentCanvasID: () => string,
    getCurrentCtx: () => CanvasRenderingContext2D,
    setCurrentCtx: (id: string) => boolean,
    getCtxByID: (id: string) => CanvasRenderingContext2D,
    globalPositionToLocalPosition: (x: number, y: number) => [x: number, y: number],
    updateCurrentCanvasValidBoundaryByBrush: (x: number, y: number, size: number)=>any,
    updateCurrentCanvasValidBoundaryByText: (pivotGlobalX:number, pivotGlobalY:number, pivotRatioX:number, pivotRatioY:number, angleRad:number, width:number, height:number)=>any,
    textPromptOn: (p: any) => void,
    textPromptOff: () => void,
    allowComboKeys: () => boolean,
    pointerEventProcessors: any,                          // an object of {pointerDownProcessor, pointerMoveProcessor, pointerUpProcessor}
  }) {
    this.getCurrentCanvasID = p.getCurrentCanvasID.bind(p);
    this.getCurrentCtx = p.getCurrentCtx.bind(p);
    this.setCurrentCtx = p.setCurrentCtx.bind(p);
    this.getCtxByID = p.getCtxByID.bind(p);
    this.globalPositionToLocalPosition = p.globalPositionToLocalPosition.bind(p);
    this.updateCurrentCanvasValidBoundaryByBrush = p.updateCurrentCanvasValidBoundaryByBrush.bind(p);
    this.updateCurrentCanvasValidBoundaryByText = p.updateCurrentCanvasValidBoundaryByText.bind(p);
    this.textPromptOn = p.textPromptOn.bind(p);
    this.textPromptOff = p.textPromptOff.bind(p);
    this.allowComboKeys = p.allowComboKeys.bind(p);
    this.pointerEventProcessors = p.pointerEventProcessors;

    this.canvasOperations = new CanvasOperations({
      getCtxByID: this.getCtxByID.bind(this),
      getCurrentCanvasID: this.getCurrentCanvasID.bind(this),
      getCurrentCtx: this.getCurrentCtx.bind(this),
      textPromptOn: this.textPromptOn.bind(this),
      textPromptOff: this.textPromptOff.bind(this),
    });
    this.redoAndUndoCatch = new RedoAndUndoCatch({
      getCurrentCtx: this.getCurrentCtx.bind(this),
      setCurrentCtx: this.setCurrentCtx.bind(this),
      getCtxByID: this.getCtxByID.bind(this),
      getCurrentBrush: () => { return this.brushUiMap[this.currentBrushId]; },
      getInitState: () => { return this.initState; },
      brushes: this.brushUiMap,
      canvasOperations: this.canvasOperations,
      otherUtilities: {
        createInitStateCtx: (id: string) => { return this.createInitStateCtx(id); }
      }
    })
    klHistory.addListener((p) => {
      // added to the history for case marks exceed the maxmium
      this.redoAndUndoCatch.catchup(p);
    });

    this.globalMode = TMode.Draw;
    this.currentColor = new BB.RGB(0, 0, 0);
    this.currentCanvasID = this.getCurrentCanvasID();
    this.currentLayerCtx = p.getCurrentCtx();

    this.initState = {
      focus: this.currentCanvasID,
      brushes: {},
      backTrackData: this.backTrackData,
    };
    this.createInitStateCtx("main"); // no need user' input, just create the main canvas' initstate cause it's must be blank
    Object.entries(KL.brushes).forEach(([b, Brush]) => {
      this.initState.brushes[b] = new Brush();
      this.initState.brushes[b].setContext(this.backTrackData["main"]);
    });

    Object.entries(KL.brushesUI).forEach(([b, brushUi]) => {
      // console.log(b);
      const ui = new (brushUi.Ui as any)({
        onSizeChange: ()=>{},
        onOpacityChange: (opacity: number) => {},
        onConfigChange: () => {},
      });
      this.brushUiMap[b] = ui;
      this.brushMap[b] = ui.brush;
      ui.getElement().style.padding = 10 + 'px';
      if (!this.currentBrushId) {
        this.setCurrentBrush(b);
        this.setSize(5);
      }
    });

    this.eventProcessor = new EventProcessor({
      pointerEventProcessors: this.pointerEventProcessors,
      eventReader: this.mouseEvent.bind(this),
      getGlobalMode: () => {return this.globalMode;},
      redoAndUndoCatch: this.redoAndUndoCatch,
      canvasOperations: this.canvasOperations,
      utilities: {
        getCurrentCanvasID: this.getCurrentCanvasID.bind(this),
        getCurrentCtx: this.getCurrentCtx.bind(this),
        getCurrentColor: () => { return this.currentColor; },
        globalPositionToLocalPosition: this.globalPositionToLocalPosition.bind(this),
        updateCurrentCanvasValidBoundaryByText: this.updateCurrentCanvasValidBoundaryByText.bind(this),
        allowComboKeys: this.allowComboKeys.bind(this),
      }
    });
  }

  sizeWatcher = (val: number) => {
    // this.brushSettingService.emitSize(val);
  };

  nullMouseEvent = () => { }

  mouseEvent = (event: any) => {
    if (event.type === 'down') {
      this.currentBrushUi.startLine(event.x, event.y, event.pressure);
    }
    if (event.type === 'move') {
      this.currentBrushUi.goLine(event.x, event.y, event.pressure, false, event.isCoalesced);
    }
    if (event.type === 'up') {
      this.currentBrushUi.endLine();
    }
    this.updateCurrentCanvasValidBoundaryByBrush(event.x, event.y, this.currentBrushUi.getSize());
    if (event.type === 'line') {
      this.currentBrushUi.getBrush().drawLineSegment(event.x0, event.y0, event.x1, event.y1);
    }
  }

  setCurrentBrush = (brushId: string) => {
    if (brushId in this.brushUiMap) {
      this.globalMode = TMode.Draw;
      if (brushId !== 'eraserBrush') {
        this.lastNonEraserBrushId = brushId;
      }
      this.currentBrushId = brushId;
      this.currentBrushUi = this.brushUiMap[brushId];
      this.currentBrushUi.setContext(this.getCurrentCtx());
      this.currentBrushUi.setColor(this.currentColor);
    } else if (brushId === "text") {
      this.globalMode = TMode.Text;
    }
  };


  // {r, g, b}
  setColor(newColor: IRGB) {
    this.currentBrushUi.setColor(newColor);
  }
  
  setTip(tip: number) {
    if (this.currentBrushUi.getBrush().setAlpha) {
      this.currentBrushUi.getBrush().setAlpha(tip);
    }
  }

  getTip() {
    if (this.currentBrushUi.brush.getAlpha) {
      return this.currentBrushUi.brush.getAlpha();
    }
    return 0;
  }

  setSize(newSize: number) {
    this.currentBrushUi.setSize(newSize);
  }

  setOpacity(newOpacity: number) {
    newOpacity = Math.min(Math.max(newOpacity, 0), 1);
    this.currentBrushUi.setOpacity(newOpacity);
  }

  // for line, there is a property called "useMaget"
  setOtherProperties(propertyName: string, value: any) {
    this.currentBrushUi.setProperties(propertyName, value);
  }

  getSize() {
    return this.currentBrushUi.getSize();
  }

  getOpacity() {
    return this.currentBrushUi.getSize();
  }

  undo() { this.redoAndUndoCatch.undo(); }
  redo() { this.redoAndUndoCatch.redo(); }

  createInitStateCtx = (canvasID: string) => {
    const currentCtx = this.getCtxByID(canvasID);
    const backupCanvas = document.createElement("canvas");
    backupCanvas.width = currentCtx.canvas.width;
    backupCanvas.height = currentCtx.canvas.height;
    const backupCtx = backupCanvas.getContext("2d");
    backupCtx?.drawImage(currentCtx.canvas, 0, 0); // draw the current canvas on to the backup when first tip is added.
    this.backTrackData[canvasID] = backupCtx;
    return backupCtx;
  }

  // when tip down, this will happen before the draw event.
  setCurrentCanvas = (canvasID: string) => {
    if (!(canvasID in this.backTrackData)) {
      this.createInitStateCtx(canvasID); // create a new ctx of the initial stage
    }
    if (this.currentCanvasID !== canvasID) {
      const ctx = this.getCtxByID(canvasID);
      this.setCurrentCtx(canvasID);
      if (ctx) {
        this.currentCanvasID = canvasID;
        this.currentLayerCtx = ctx;
        this.currentBrushUi.setContext(ctx);  // this set the current context to the current current brush.
        klHistory.push({
          tool: ['misc'],
          action: 'focusLayer',
          params: [canvasID],
        });
      }
    }
  }

  // called when drawing is done and submited or remove all marks.
  removeAllMarks() {
    this.backTrackData = {}; // initialize the backTrackData.
    this.initState = {
      focus: this.currentCanvasID,
      brushes: {},
      backTrackData: this.backTrackData,
    };
    this.createInitStateCtx("main"); // no need user' input, just create the main canvas' initstate cause it's must be blank
    Object.entries(KL.brushes).forEach(([b, Brush]) => {
      this.initState.brushes[b] = new Brush();
      this.initState.brushes[b].setContext(this.backTrackData["main"]);
    });
    // this.redoAndUndoCatch.
    klHistory.cleanUpHistory();        // clean up the history, so now the history doesn't have any entries.
  }

  cleanUp() {
    klHistory.cleanUpFull();
    this.eventProcessor.cleanUp();
  }
}

interface IPointer {
  pointerId: number;
  lastPageX: number | null;
  lastPageY: number | null;
}

interface IDragObj {
  pointerId: number; // long
  pointerType?: TPointerType;
  downPageX: number; //where was pointer when down-event occurred
  downPageY: number;
  buttons: number; // long
  lastPageX: number; //pageX in previous event - only for touch events, because they don't have movementX/Y
  lastPageY: number;
  lastTimeStamp?: number;
}

interface ICoalescedPointerFullEvent {
  pageX: number;
  pageY: number;
  clientX: number;
  clientY: number;
  movementX: number;
  movementY: number;
  timeStamp: number;
  pressure: number;
}

interface ICorrectedPointerEvent {
  pointerId: number;
  pointerType: string;
  pageX: number;
  pageY: number;
  clientX: number;
  clientY: number;
  movementX: number;
  movementY: number;
  timeStamp: number;
  pressure: number; // normalized
  buttons: number;
  button: number;
  coalescedArr: ICoalescedPointerFullEvent[];
  eventPreventDefault: () => void;
  eventStopPropagation: () => void;
}

const pointerArr: IPointer[] = [];

function addPointer(event: ICorrectedPointerEvent): IPointer {
  const pointerObj: IPointer = {
    pointerId: event.pointerId,
    lastPageX: null,
    lastPageY: null,
  };
  pointerArr.push(pointerObj);

  if (pointerArr.length > 15) {
    pointerArr.shift();
  }

  return pointerObj;
}

function getPointer(event: ICorrectedPointerEvent): IPointer | null {
  for (let i = pointerArr.length - 1; i >= 0; i--) {
    if (event.pointerId === pointerArr[i].pointerId) {
      return pointerArr[i];
    }
  }
  return null;
}

function getButtonStr(buttons: number): TPointerButton | undefined {
  switch (buttons) {
    case 1:
      return 'left';
    case 2:
      return 'right';
    case 4:
      return 'middle';
    default:
      return undefined;
  }
}

interface TExtendedDOMPointerEvent extends PointerEvent {
  corrected: ICorrectedPointerEvent;
}

const pressureNormalizer = new PressureNormalizer();
const timeStampOffset = eventUsesHighResTimeStamp() ? 0 : -performance.timing.navigationStart;

const pointerDownEvt = (hasPointerEvents ? 'pointerdown' : 'mousedown') as 'pointerdown';
const pointerMoveEvt = (hasPointerEvents ? 'pointermove' : 'mousemove') as 'pointermove';
const pointerUpEvt = (hasPointerEvents ? 'pointerup' : 'mouseup') as 'pointerup';
const pointerCancelEvt = (hasPointerEvents ? 'pointercancel' : 'mousecancel') as 'pointercancel';
const pointerLeaveEvt = (hasPointerEvents ? 'pointerleave' : 'mouseleave') as 'pointerleave';

function correctPointerEvent(event: PointerEvent | TExtendedDOMPointerEvent): ICorrectedPointerEvent {
  if ('corrected' in event) {
    return event.corrected;
  }

  function determineButtons(): number {
    if (event.buttons !== undefined) {
      return event.buttons;
    }
    /*
            button -> buttons
    none:	undefined -> 0
    left:	0 -> 1
    middle:	1 -> 4
    right:	2 -> 2
    fourth:	3 -> 8
    fifth:	4 -> 16
     */
    if (event.button !== undefined) { // old safari on mac has no buttons. remove eventually.
      return [1, 4, 2, 8, 16][event.button];
    }
    return 0;
  }

  const correctedObj: ICorrectedPointerEvent = {
    pointerId: event.pointerId,
    pointerType: event.pointerType,
    pageX: event.pageX,
    pageY: event.pageY,
    clientX: event.clientX,
    clientY: event.clientY,
    movementX: event.movementX,
    movementY: event.movementY,
    timeStamp: event.timeStamp + timeStampOffset,
    pressure: pressureNormalizer.normalize(event.pressure),
    buttons: determineButtons(),
    button: event.button,
    coalescedArr: [],
    eventPreventDefault: () => event.preventDefault(),
    eventStopPropagation: () => event.stopPropagation(),
  };
  (event as TExtendedDOMPointerEvent).corrected = correctedObj;

  let customPressure = null;
  if ('pointerId' in event) {
    if ('pressure' in event && event.buttons !== 0 && (['mouse'].includes(event.pointerType) || (event.pointerType === 'touch' && event.pressure === 0))) {
      correctedObj.pressure = 1;
      customPressure = 1;
    }
  } else {
    correctedObj.pointerId = 0;
    correctedObj.pointerType = 'mouse';
    correctedObj.pressure = (event as PointerEvent).buttons !== 0 ? 1 : 0;
    customPressure = correctedObj.pressure;
  }

  if (isFirefox && event.pointerType != 'mouse' && event.type === 'pointermove' && event.buttons === 0) { // once again firefox
    correctedObj.buttons = 1; // todo wrong if no buttons actually pressed
  }

  let coalescedEventArr: PointerEvent[] = [];
  if ('getCoalescedEvents' in event) {
    coalescedEventArr = event.getCoalescedEvents();
  }

  // chrome somehow movementX not same scale as pageX. todo: only chrome?
  // so make my own

  const pointerObj: IPointer = getPointer(correctedObj) || addPointer(correctedObj);

  const totalLastX = pointerObj.lastPageX;
  const totalLastY = pointerObj.lastPageY;

  for (let i = 0; i < coalescedEventArr.length; i++) {
    const eventItem = coalescedEventArr[i];

    correctedObj.coalescedArr.push({
      pageX: eventItem.pageX,
      pageY: eventItem.pageY,
      clientX: eventItem.clientX,
      clientY: eventItem.clientY,
      movementX: pointerObj.lastPageX === null ? 0 : eventItem.pageX - pointerObj.lastPageX,
      movementY: pointerObj.lastPageY === null ? 0 : eventItem.pageY - pointerObj.lastPageY,
      timeStamp: eventItem.timeStamp === 0 ? correctedObj.timeStamp : (eventItem.timeStamp + timeStampOffset), // 0 in firefox
      pressure: customPressure === null ? pressureNormalizer.normalize(eventItem.pressure) : customPressure,
    });

    pointerObj.lastPageX = eventItem.pageX;
    pointerObj.lastPageY = eventItem.pageY;
  }

  pointerObj.lastPageX = correctedObj.pageX;
  pointerObj.lastPageY = correctedObj.pageY;
  correctedObj.movementX = totalLastX === null ? 0 : pointerObj.lastPageX - totalLastX;
  correctedObj.movementY = totalLastY === null ? 0 : pointerObj.lastPageY - totalLastY;

  return correctedObj;
}

type TInputProcessorKeys = | 'draw' | 'fill' | 'gradient' | 'text' | 'shape' | 'hand' | 'spaceHand' | 'zoom' | 'picker' | 'altPicker' | 'rotate' | "redo" | "undo";
enum TMode {
  Draw, Hand, HandGrabbing, Pick, Zoom,
  Rotate, Rotating, Fill, Gradient, Text, Shape,
}

type TInputProcessorItem = {
  onPointer: (val: ICoalescedPointerEvent) => void;
  onKeyDown: TOnKeyDown;
  onKeyUp: TOnKeyUp;
  type: string;
}

class EventProcessor {
  // ts has problems with (HTMLElement|SVGElement) when adding event listeners
  // https://github.com/microsoft/TypeScript/issues/46819
  // private readonly targetElement: HTMLElement;
  private pointerEventProcessors: any;                        // an object of {pointerDownProcessor, pointerMoveProcessor, pointerUpProcessor}
  private readonly onPointerCallback: undefined | ((pointerEvent: IPointerEvent) => void);
  private utilities: {
    getCurrentColor: () => IRGB,
    getCurrentCanvasID: () => string,
    getCurrentCtx: () => CanvasRenderingContext2D,
    globalPositionToLocalPosition: (x: number, y: number) => [x: number, y: number],
    updateCurrentCanvasValidBoundaryByText: (pivotGlobalX:number, pivotGlobalY:number, pivotRatioX:number, pivotRatioY:number, angleRad:number, width:number, height:number)=>any,
    allowComboKeys: () => boolean,
  };
  private cursorPos: IVector2D;
  private pointer: IVector2D | null = null; // position of cursor
  private lastDrawEvent: IPressureInput | null; // previous drawing input
  private readonly maxPointers: number = 1;
  private pointerEventChain: EventChain;                      // first layer chain for getting input and filtering
  private drawEventChain: EventChain;                         // secondary chain for drawing specifically.
  private redoAndUndoCatch: RedoAndUndoCatch;
  private currentInputProcessor: TInputProcessorItem | null;
  private linetoolProcessor: LinetoolProcessor;
  private inputProcessorObj: { [key in TInputProcessorKeys]: TInputProcessorItem; };
  private canvasOperations: CanvasOperations;
  private lineSmoothing: LineSmoothing;
  private lineSanitizer: LineSanitizer;
  private history: KlHistoryInterface;
  private keyListener: KeyListener;
  private getGlobalMode: ()=>TMode;
  private isDrawing: boolean;

  // pointers that are pressing a button
  private dragObjArr: IDragObj[] = [];
  private dragPointerIdArr: number[] = [];

  // chrome input glitch workaround
  private lastPointerType: TPointerType | null = null;
  private didSkip: boolean = false;
  private addedFakePointerEvents: boolean = false;

  private readonly onPointerMove: ((event: PointerEvent) => void) | undefined;
  private readonly onPointerDown: ((event: PointerEvent, skipGlobal?: boolean) => void) | undefined;
  private readonly windowOnPointerMove: ((event: PointerEvent) => void) | undefined;
  private readonly windowOnPointerUp: ((event: PointerEvent) => void) | undefined;
  private readonly windowOnPointerLeave: ((event: PointerEvent) => void) | undefined;
  // fallback pre pointer events (iOS < 13, as of 2023-02, still 4.4% of iOS users)
  private readonly onTouchStart: ((e: TouchEvent) => void) | undefined;
  private readonly onTouchMove: ((e: TouchEvent) => void) | undefined;
  private readonly onTouchEnd: ((e: TouchEvent) => void) | undefined;
  private readonly onTouchCancel: ((e: TouchEvent) => void) | undefined;

  private resetInputProcessor(): void {
    this.currentInputProcessor = null;
  }

  private onDrawChain(e: any) {
    this.drawEventChain.chainIn(e as any)
  }

  constructor(p: {
    pointerEventProcessors: any,            // an object of {pointerDownProcessor, pointerMoveProcessor, pointerUpProcessor}
    eventReader: (event: any) => any,
    getGlobalMode: ()=>TMode,
    redoAndUndoCatch: RedoAndUndoCatch,
    canvasOperations: CanvasOperations,
    utilities: {
      getCurrentColor: () => IRGB,
      getCurrentCanvasID: () => string,
      getCurrentCtx: () => CanvasRenderingContext2D,
      globalPositionToLocalPosition: (x: number, y: number) => [x: number, y: number],
      updateCurrentCanvasValidBoundaryByText: (pivotGlobalX:number, pivotGlobalY:number, pivotRatioX:number, pivotRatioY:number, angleRad:number, width:number, height:number)=>any,
      allowComboKeys: () => boolean,
    },
  }) {
    this.cursorPos = {
      x: 0,
      y: 0,
    };
    this.pointerEventChain = new BB.EventChain({
      chainArr: [],
    });
    this.currentInputProcessor = null;
    this.getGlobalMode = p.getGlobalMode.bind(p);
    this.isDrawing = false;
    this.lastDrawEvent = null;
    this.redoAndUndoCatch = p.redoAndUndoCatch;
    this.lineSmoothing = new LineSmoothing({
      smoothing: translateSmoothing(1),
    });
    this.lineSanitizer = new LineSanitizer();
    this.drawEventChain = new BB.EventChain({
      chainArr: [
        this.lineSanitizer as any,
        this.lineSmoothing as any,
      ],
    });
    this.canvasOperations = p.canvasOperations;
    this.utilities = p.utilities;
    this.history = new DecoyKlHistory();

    this.pointerEventProcessors = p.pointerEventProcessors;
    this.drawEventChain.setChainOut(p.eventReader.bind(p) as any);

    const textToolSettings = {
      size: 20,
      align: 'left' as ('left' | 'center' | 'right'),
      isBold: false,
      isItalic: false,
      font: 'sans-serif' as ('serif' | 'monospace' | 'sans-serif' | 'cursive' | 'fantasy'),
      opacity: 1,
    };
    this.keyListener = new BB.KeyListener({
      onDown: (keyStr, event, comboStr, isRepeat) => {
        if (!this.utilities.allowComboKeys() || KL.dialogCounter.get() > 0 || BB.isInputFocused(true)) {
          return;
        }
        if (keyStr === 'alt') {
          event.preventDefault();
        }
        if (isRepeat) {
          return;
        }
        if (this.currentInputProcessor) {
          this.currentInputProcessor.onKeyDown(keyStr, event, comboStr, isRepeat);
        } else {
          this.shiftInputProcessorByKey(keyStr, event, comboStr, isRepeat);
        }
      },
      onUp: (keyStr, event, oldComboStr) => {
        // prevent menu bar in Firefox
        if (keyStr === 'alt') {
          event.preventDefault();
        }
        if (this.currentInputProcessor) {
          this.currentInputProcessor.onKeyUp(keyStr, event, oldComboStr);
        }
      },
    });
    this.linetoolProcessor = new LinetoolProcessor({
      onDraw: (event) => {
        const getMatrix = () => {
          let matrix = BB.Matrix.getIdentity();
          matrix = BB.Matrix.multiplyMatrices(matrix, BB.Matrix.createScaleMatrix(1 / 1));
          matrix = BB.Matrix.multiplyMatrices(matrix, BB.Matrix.createRotationMatrix(-0));
          matrix = BB.Matrix.multiplyMatrices(matrix, BB.Matrix.createTranslationMatrix(-0, -0));
          return matrix;
        };

        if (event.type === 'line' && !this.lastDrawEvent) {
          const matrix = getMatrix();
          let coords: TVec4 = [event.x1, event.y1, 0, 1];
          coords = BB.Matrix.multiplyMatrixAndPoint(matrix, coords);
          this.lastDrawEvent = {
            x: coords[0],
            y: coords[1],
            pressure: event.pressure1,
          };
          return;
        }

        if ('x' in event || 'x0' in event) {
          const matrix = getMatrix();
          if ('x' in event) { //down or move
            let coords: TVec4 = [event.x, event.y, 0, 1];
            coords = BB.Matrix.multiplyMatrixAndPoint(matrix, coords);
            event.x = coords[0];
            event.y = coords[1];
          }
          if ('x0' in event) { //line
            event.x0 = this.lastDrawEvent!.x;
            event.y0 = this.lastDrawEvent!.y;
            event.pressure0 = this.lastDrawEvent!.pressure;
            let coords: TVec4 = [event.x1, event.y1, 0, 1];
            coords = BB.Matrix.multiplyMatrixAndPoint(matrix, coords);
            event.x1 = coords[0];
            event.y1 = coords[1];

            this.lastDrawEvent = {
              x: event.x1,
              y: event.y1,
              pressure: event.pressure1,
            };
          }
        }

        if (event.type === 'down' || event.type === 'move') {
          this.lastDrawEvent = {
            x: event.x,
            y: event.y,
            pressure: event.pressure,
          };
        }
        this.onDrawChain(event);
      },
    });

    this.inputProcessorObj = {
      draw: {
        type: "draw",
        onPointer: (val) => {
          const comboStr = this.keyListener.getComboStr();
          const event: any = {};
          event.shiftIsPressed = comboStr === 'shift';
          event.pressure = val.pressure;
          event.isCoalesced = !!val.isCoalesced;

          if (val.type === 'pointerdown') {
            this.isDrawing = true;
            event.type = 'down';
          } else if (val.button) {
            event.type = 'move';
          } else if (val.type === 'pointerup') {
            this.isDrawing = false;
            event.type = 'up';
            this.linetoolProcessor.process(event);
            this.resetInputProcessor();
            return;
          } else {
            return;
          }

          const [localX, localY] = this.utilities.globalPositionToLocalPosition(val.clientX, val.clientY);
          event.x = localX;
          event.y = localY;

          this.linetoolProcessor.process(event);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => {

        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => {

        },
      },
      fill: {
        type: "fill",
        onPointer: (event) => {
          if (event.type === 'pointerup') {
            this.resetInputProcessor();
            return;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => { },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => { },
      },
      gradient: {
        type: "gradient",
        onPointer: (event) => {
          if (event.type === 'pointerup') {
            this.resetInputProcessor();
            return;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => { },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => { },
      },
      text: {
        type: "text",
        onPointer: (event) => {
          if (event.type === 'pointerdown') {
            if (KL.dialogCounter.get() > 0) {
              return;
            }
            const [canvasX, canvasY] = this.utilities.globalPositionToLocalPosition(event.clientX, event.clientY);
            // textToolDialog(same prompts)
            this.canvasOperations.openTextPrompt({
            // textToolDialog({
              baseCanvas: this.utilities.getCurrentCtx().canvas,
              x: canvasX,
              y: canvasY,
              angleRad: 0, // start from 0
              color: this.utilities.getCurrentColor(),
              secondaryColor: this.utilities.getCurrentColor(),
              size: textToolSettings.size,
              align: textToolSettings.align,
              isBold: textToolSettings.isBold,
              isItalic: textToolSettings.isItalic,
              font: textToolSettings.font,
              opacity: textToolSettings.opacity,
              updateCanvasBound: this.utilities.updateCurrentCanvasValidBoundaryByText.bind(this.utilities),
              onConfirm: (val: any) => {
                const colorRGBA = val.color as IRGBA;
                colorRGBA.a = val.opacity;

                textToolSettings.size = val.size;
                textToolSettings.align = val.align;
                textToolSettings.isBold = val.isBold;
                textToolSettings.isItalic = val.isItalic;
                textToolSettings.font = val.font;
                textToolSettings.opacity = val.opacity;

                const p = {
                  textStr: val.textStr,
                  x: val.x,
                  y: val.y,
                  size: val.size,
                  font: val.font,
                  align: val.align,
                  isBold: val.isBold,
                  isItalic: val.isItalic,
                  angleRad: val.angleRad,
                  color: BB.ColorConverter.toRgbaStr(colorRGBA),
                };
                this.canvasOperations.text(this.utilities.getCurrentCtx().canvas, p);
              },
              // onCancel: () => {},
            });
          } else if (event.type === 'pointerup') {
            this.resetInputProcessor();
            return;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => { },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => { },
      },
      shape: {
        type: "shape",
        onPointer: (event) => {
          if (event.type === 'pointerup') {
            this.resetInputProcessor();
            return;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => { },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => { },
      },
      hand: {
        type: "hand",
        onPointer: (event) => {
          if (event.type === 'pointerup') {
            this.resetInputProcessor();
            return;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => { },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => { },
      },
      spaceHand: {
        type: "spaceHand",
        onPointer: (event) => {
          if (event.type === 'pointerup') {
            this.resetInputProcessor();
            return;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => { },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => { },
      },
      zoom: {
        type: "zoom",
        onPointer: (event) => {
          if (event.type === 'pointerup') {
            this.resetInputProcessor();
            return;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => { },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => { },
      },
      picker: {
        type: "picker",
        onPointer: (event) => {
          if (event.type === 'pointerup') {
            this.resetInputProcessor();
            return;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => { },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => { },
      },
      altPicker: {
        type: "altPicker",
        onPointer: (event) => {
          if (event.type === 'pointerup') {
            this.resetInputProcessor();
            return;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => { },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => { },
      },
      rotate: {
        type: "rotate",
        onPointer: (event) => {
          if (event.type === 'pointerup') {
            this.resetInputProcessor();
            return;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => { },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => { },
      },
      redo: {
        type: "redo",
        onPointer: (event) => {  },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => {
          this.redoAndUndoCatch.redo();
          this.resetInputProcessor();
          return;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => { },
      },
      undo: {
        type: "undo",
        onPointer: (event) => {  },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyDown: () => {
          this.redoAndUndoCatch.undo();
          this.resetInputProcessor();
          return;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onKeyUp: () => {  },
      }
    };

    this.pointerEventChain.setChainOut((event: ICoalescedPointerEvent) => { // after round 2, this is what out function looks like, it will send the event to inputProssorObj. // event process round 2
      this.cursorPos.x = event.clientX;
      this.cursorPos.y = event.clientY;
      // if (event.type === "pointerdown") console.trace(event.type);
      if (event.type === 'pointerup' && event.pointerType === 'touch') {
        this.pointer = null;
        // this.lastRenderedState = -1;
        // this.reqFrame();
      } else {
        if (!this.pointer) {
          this.pointer = { x: 0, y: 0 };
        }
        this.pointer.x = event.clientX;
        this.pointer.y = event.clientY;
      }

      if (this.currentInputProcessor) {
        this.currentInputProcessor.onPointer(event);
      } else {
        const comboStr = this.keyListener.getComboStr();
        if (this.getGlobalMode() === TMode.Draw) {
          if (['', 'shift', 'ctrl'].includes(comboStr) && event.type === 'pointerdown' && event.button === 'left') {
            this.currentInputProcessor = this.inputProcessorObj.draw;
            this.currentInputProcessor.onPointer(event);
          } else if ([''].includes(comboStr) && event.type === 'pointerdown' && event.button === 'right') {
            this.currentInputProcessor = this.inputProcessorObj.picker;
            this.currentInputProcessor.onPointer(event);
          } else if ([''].includes(comboStr) && event.type === 'pointerdown' && event.button === 'middle') {
            this.currentInputProcessor = this.inputProcessorObj.hand;
            this.currentInputProcessor.onPointer(event);
          }
        } else if (this.getGlobalMode() === TMode.Text) {
          if (event.type === 'pointerdown' && event.button === 'left') {
            this.currentInputProcessor = this.inputProcessorObj.text;
            this.currentInputProcessor.onPointer(event);
          } else if ([''].includes(comboStr) && event.type === 'pointerdown' && event.button === 'right') {
            this.currentInputProcessor = this.inputProcessorObj.picker;
            this.currentInputProcessor.onPointer(event);
          } else if ([''].includes(comboStr) && event.type === 'pointerdown' && event.button === 'middle') {
            this.currentInputProcessor = this.inputProcessorObj.hand;
            this.currentInputProcessor.onPointer(event);
          }
        }
      }
    });

    // this.onPointerCallback = eventPorcessor; // directly pass in the callback function.
    this.onPointerCallback = (e) => { this.pointerEventChain.chainIn(e); }  // after round 1, pass the first round event result into chain. after read and process, it will feed back to inputProcessorObj. // event process round 2
    if (this.onPointerCallback) { // this is where the initial event come in. these functions will process the event and customize it. // event process round 1
      this.onPointerMove = (event: PointerEvent) => {}; // this is called no matter if the mouse is down. 
      this.onPointerDown = (event: PointerEvent, onSkipGlobal?: boolean) => {
        if (this.pointerEventProcessors.eventDownProcessor) {
          const pass = this.pointerEventProcessors.eventDownProcessor(event);
          if (!pass) { return }
        }
        const correctedEvent = correctPointerEvent(event);
        if (this.dragPointerIdArr.includes(correctedEvent.pointerId) || this.dragPointerIdArr.length === this.maxPointers || ![1, 2, 4].includes(correctedEvent.buttons)) {
          return;
        }

        //set up global listeners
        // console.log("condition for adding: ", this.dragObjArr.length, onSkipGlobal);
        if (this.dragObjArr.length === 0 && !onSkipGlobal) {
          this.setupDocumentListeners();
        }
        const dragObj: IDragObj = {
          pointerId: correctedEvent.pointerId,
          pointerType: correctedEvent.pointerType as TPointerType,
          downPageX: correctedEvent.pageX,
          downPageY: correctedEvent.pageY,
          buttons: correctedEvent.buttons,
          lastPageX: correctedEvent.pageX,
          lastPageY: correctedEvent.pageY,
          lastTimeStamp: correctedEvent.timeStamp,
        };
        this.dragObjArr.push(dragObj);
        this.dragPointerIdArr.push(correctedEvent.pointerId);

        const outEvent: IPointerEvent = this.createPointerOutEvent('pointerdown', correctedEvent, {
          downPageX: correctedEvent.pageX,
          downPageY: correctedEvent.pageY,
          button: getButtonStr(correctedEvent.buttons),
          pressure: correctedEvent.pressure,
        });

        this.onPointerCallback && this.onPointerCallback(outEvent);
      };

      // this is only called when mouse is already down.
      this.windowOnPointerMove = (event: PointerEvent) => {
        if (this.pointerEventProcessors.eventMoveProcessor) {
          const pass = this.pointerEventProcessors.eventMoveProcessor(event);
          if (!pass) { return }
        }
        const correctedEvent = correctPointerEvent(event);
        ////console.log('debug: ' + event.pointerId + ' GLOBALpointermove');
        if (!(this.dragPointerIdArr.includes(correctedEvent.pointerId))) {
          return;
        }

        const dragObj = this.getDragObj(correctedEvent.pointerId);

        if (!dragObj) {
          // todo need to handle this!
          return;
        }

        //if pointer changes button its pressing -> turn into pointerup
        if (correctedEvent.buttons !== dragObj.buttons) {
          //pointer up

          //remove listener
          if (this.dragObjArr.length === 1) {
            this.destroyDocumentListeners();
          }
          this.removeDragObj(correctedEvent.pointerId);

          const outEvent = this.createPointerOutEvent('pointerup', correctedEvent, {
            downPageX: dragObj.downPageX,
            downPageY: dragObj.downPageY,
          });
          this.onPointerCallback && this.onPointerCallback(outEvent);
          return;
        }

        // ipad likes to do this
        if (
          correctedEvent.pointerType === 'pen' &&
          correctedEvent.pageX === dragObj.lastPageX &&
          correctedEvent.pageY === dragObj.lastPageY &&
          correctedEvent.timeStamp === dragObj.lastTimeStamp
        ) {
          //ignore
          return;
        }

        const outEvent = this.createPointerOutEvent('pointermove', correctedEvent, {
          downPageX: dragObj.downPageX,
          downPageY: dragObj.downPageY,
          button: getButtonStr(correctedEvent.buttons),
          pressure: correctedEvent.pressure,
        });

        dragObj.lastPageX = correctedEvent.pageX;
        dragObj.lastPageY = correctedEvent.pageY;
        dragObj.lastTimeStamp = correctedEvent.timeStamp;

        this.onPointerCallback && this.onPointerCallback(outEvent);
      };

      this.windowOnPointerUp = (event: PointerEvent) => {
        if (this.pointerEventProcessors.eventUpProcessor) {
          const pass = this.pointerEventProcessors.eventUpProcessor(event);
          if (!pass) { return }
        }
        const correctedEvent = correctPointerEvent(event);
        ////console.log('debug: ' + event.pointerId + ' GLOBALpointerup');
        if (!(this.dragPointerIdArr.includes(correctedEvent.pointerId))) {
          return;
        }

        //remove listener
        if (this.dragObjArr.length === 1) {
          this.destroyDocumentListeners();
        }
        const dragObj = this.removeDragObj(correctedEvent.pointerId);
        if (!dragObj) {
          // todo need to handle this!
          return;
        }

        const outEvent = this.createPointerOutEvent('pointerup', correctedEvent, {
          downPageX: dragObj.downPageX,
          downPageY: dragObj.downPageY,
        });
        this.onPointerCallback && this.onPointerCallback(outEvent);

      };

      this.windowOnPointerLeave = (event: PointerEvent) => {
        if (this.pointerEventProcessors.eventLeaveProcessor) {
          const pass = this.pointerEventProcessors.eventLeaveProcessor(event);
          if (!pass) { return }
        }
        //BB.throwOut('pointerleave ' + event.pointerId);
        const correctedEvent = correctPointerEvent(event);
        ////console.log('debug: ' + event.pointerId + ' onGlobalPointerLeave', event);
        if (!(this.dragPointerIdArr.includes(correctedEvent.pointerId))) { //} || event.target !== document) {
          return;
        }

        //remove listener
        if (this.dragObjArr.length === 1) {
          this.destroyDocumentListeners();
        }
        const dragObj = this.removeDragObj(correctedEvent.pointerId);
        if (!dragObj) {
          // todo need to handle this!
          return;
        }

        const outEvent = this.createPointerOutEvent('pointerup', correctedEvent, {
          downPageX: dragObj.downPageX,
          downPageY: dragObj.downPageY,
        });
        this.onPointerCallback && this.onPointerCallback(outEvent);
      };

      // main event listener...
      window.addEventListener(pointerMoveEvt, this.onPointerMove);
      window.addEventListener(pointerDownEvt, this.onPointerDown);

      // console.log("has pointer events: ", hasPointerEvents);
      if (!hasPointerEvents) {
        const touchToFakePointer = (touch: Touch, touchEvent: TouchEvent, isDown: boolean) => {
          return {
            pointerId: touch.identifier,
            pointerType: 'touch',
            pageX: touch.pageX,
            pageY: touch.pageY,
            clientX: touch.clientX,
            clientY: touch.clientY,
            button: isDown ? 0 : undefined,
            buttons: isDown ? 1 : 0,
            timeStamp: touchEvent.timeStamp,
            target: touchEvent.target,
            pressure: isDown ? 1 : 0,
            preventDefault: () => touchEvent.preventDefault(),
            stopPropagation: () => touchEvent.stopPropagation(),
          };
        };

        const handleTouch = (e: TouchEvent, type: 'start' | 'move' | 'end' | 'cancel'): void => {
          console.log("this is ever called +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
          for (let i = 0; i < e.changedTouches.length; i++) {
            const touch = e.changedTouches[i];
            const fakePointer = touchToFakePointer(touch, e, ['start', 'move'].includes(type));
            if (type === 'start') {
              this.onPointerDown!(fakePointer as PointerEvent, false);
            } else if (type === 'move') {
              this.windowOnPointerMove!(fakePointer as PointerEvent);
            } else if (type === 'end') {
              this.windowOnPointerUp!(fakePointer as PointerEvent);
            } else {
              this.windowOnPointerLeave!(fakePointer as PointerEvent);
            }
          }
        };

        this.onTouchStart = (e: TouchEvent): void => {
          e.preventDefault();
          handleTouch(e, 'start');
        };
        this.onTouchMove = (e: TouchEvent): void => {
          handleTouch(e, 'move');
        };
        this.onTouchEnd = (e: TouchEvent): void => {
          handleTouch(e, 'end');
        };
        this.onTouchCancel = (e: TouchEvent): void => {
          handleTouch(e, 'cancel');
        };

        this.addedFakePointerEvents = true;
        window.addEventListener('touchstart', this.onTouchStart);
        window.addEventListener('touchmove', this.onTouchMove);
        window.addEventListener('touchend', this.onTouchEnd);
        window.addEventListener('touchcancel', this.onTouchCancel);
      }
    }
  }

  // this function will be used to change the input type, for example draw to text.
  changeInputType(type: TInputProcessorKeys) {
    this.currentInputProcessor = this.inputProcessorObj[type];
  }

  private shiftInputProcessorByKey(keyStr: string, event: any, comboStr: string, isRepeat: any) {
    if ([TMode.Draw, TMode.Pick, TMode.Fill, TMode.Gradient, TMode.Text, TMode.Shape].includes(this.getGlobalMode()) && comboStr === 'space') {
      this.currentInputProcessor = this.inputProcessorObj.spaceHand;
      this.currentInputProcessor.onKeyDown(keyStr, event, comboStr, isRepeat);
      return;
    } else if ('ctrl+z' === comboStr) {
      this.currentInputProcessor = this.inputProcessorObj.undo;
      this.currentInputProcessor.onKeyDown(keyStr, event, comboStr, isRepeat);
      this.currentInputProcessor = null;
      return;
    } else if ('ctrl+y' === comboStr) {
      this.currentInputProcessor = this.inputProcessorObj.redo;
      this.currentInputProcessor.onKeyDown(keyStr, event, comboStr, isRepeat);
      this.currentInputProcessor = null;
      return;
    }
  }

  // called when hold down.
  private setupDocumentListeners() {
    this.windowOnPointerMove && document.addEventListener(pointerMoveEvt, this.windowOnPointerMove);
    this.windowOnPointerUp && document.addEventListener(pointerUpEvt, this.windowOnPointerUp);
    this.windowOnPointerLeave && document.addEventListener(pointerCancelEvt, this.windowOnPointerLeave);
    this.windowOnPointerLeave && document.addEventListener(pointerLeaveEvt, this.windowOnPointerLeave);
  }

  // called when hold up.
  private destroyDocumentListeners() {
    this.windowOnPointerMove && document.removeEventListener(pointerMoveEvt, this.windowOnPointerMove);
    this.windowOnPointerUp && document.removeEventListener(pointerUpEvt, this.windowOnPointerUp);
    this.windowOnPointerLeave && document.removeEventListener(pointerCancelEvt, this.windowOnPointerLeave);
    this.windowOnPointerLeave && document.removeEventListener(pointerLeaveEvt, this.windowOnPointerLeave);
  }

  private getDragObj(pointerId: number): IDragObj | null {
    for (let i = 0; i < this.dragObjArr.length; i++) {
      if (pointerId === this.dragObjArr[i].pointerId) {
        return this.dragObjArr[i];
      }
    }
    return null;
  }

  private removeDragObj(pointerId: number): IDragObj | null {
    let removedDragObj: IDragObj | null = null;
    for (let i = 0; i < this.dragPointerIdArr.length; i++) {
      if (this.dragPointerIdArr[i] === pointerId) {
        removedDragObj = this.dragObjArr[i];
        this.dragObjArr.splice(i, 1);
        this.dragPointerIdArr.splice(i, 1);
        i--;
      }
    }
    return removedDragObj;
  }

  private createPointerOutEvent(
    typeStr: TPointerEventType,
    correctedEvent: ICorrectedPointerEvent,
    custom?: Partial<IPointerEvent>,
  ): IPointerEvent {

    // const bounds: DOMRect = this.targetElement.getBoundingClientRect();
    const result: IPointerEvent = {
      type: typeStr,
      pointerId: correctedEvent.pointerId,
      pointerType: correctedEvent.pointerType as TPointerType,
      pageX: correctedEvent.pageX,
      pageY: correctedEvent.pageY,
      clientX: correctedEvent.clientX,
      clientY: correctedEvent.clientY,
      relX: correctedEvent.clientX, // - bounds.left + this.targetElement.scrollLeft,
      relY: correctedEvent.clientY, // - bounds.top + this.targetElement.scrollTop,
      dX: correctedEvent.movementX,
      dY: correctedEvent.movementY,
      time: correctedEvent.timeStamp,
      eventPreventDefault: correctedEvent.eventPreventDefault,
      eventStopPropagation: correctedEvent.eventStopPropagation,
      ...custom,
    };

    if (typeStr === 'pointermove') {
      result.coalescedArr = [];
      if (correctedEvent.coalescedArr.length > 1) {
        let coalescedItem;
        for (let i = 0; i < correctedEvent.coalescedArr.length; i++) {
          coalescedItem = correctedEvent.coalescedArr[i];
          result.coalescedArr.push({
            pageX: coalescedItem.pageX,
            pageY: coalescedItem.pageY,
            clientX: coalescedItem.clientX,
            clientY: coalescedItem.clientY,
            relX: coalescedItem.clientX, // - bounds.left + this.targetElement.scrollLeft,
            relY: coalescedItem.clientY, // - bounds.top + this.targetElement.scrollTop,
            dX: coalescedItem.movementX,
            dY: coalescedItem.movementY,
            time: coalescedItem.timeStamp,
          });
        }
      }
    }
    return result;
  }
  cleanUp() {
    this.onPointerMove && window.removeEventListener(pointerMoveEvt, this.onPointerMove);
    this.onPointerDown && window.removeEventListener(pointerDownEvt, this.onPointerDown);

    // mouse hold listeners
    this.windowOnPointerMove && document.removeEventListener(pointerMoveEvt, this.windowOnPointerMove);
    this.windowOnPointerUp && document.removeEventListener(pointerUpEvt, this.windowOnPointerUp);
    this.windowOnPointerLeave && document.removeEventListener(pointerCancelEvt, this.windowOnPointerLeave);
    this.windowOnPointerLeave && document.removeEventListener(pointerLeaveEvt, this.windowOnPointerLeave);

    this.keyListener.destroy(); // remove any key listeners.

    if (this.addedFakePointerEvents) {
      this.onTouchStart && window.removeEventListener('touchstart', this.onTouchStart);
      this.onTouchMove && window.removeEventListener('touchmove', this.onTouchMove);
      this.onTouchEnd && window.removeEventListener('touchend', this.onTouchEnd);
      this.onTouchCancel && window.removeEventListener('touchcancel', this.onTouchCancel);
    }
  }
}
export class CanvasOperations {
  private utilities: {
    getCurrentCanvasID: () => string,
    getCurrentCtx: () => CanvasRenderingContext2D,
    getCtxByID: (id: string) => CanvasRenderingContext2D;
    textPromptOn: (p: any) => void,
    textPromptOff: () => void,
  }
  private history: KlHistoryInterface;

  constructor(utilities: {
    getCurrentCanvasID: () => string,
    getCurrentCtx: () => CanvasRenderingContext2D,
    getCtxByID: (id: string) => CanvasRenderingContext2D;
    textPromptOn: (p: any) => void,
    textPromptOff: () => void,
  }) {
    this.utilities = utilities;
    this.history = new DecoyKlHistory();
  }

  openTextPrompt(p: any) {
    this.utilities.textPromptOn(p);
  }

  turnOffTextPrompt() {
    this.utilities.textPromptOff();
  }

  text(canvas: HTMLCanvasElement, p: any) {
    renderText(canvas, BB.copyObj(p));
    klHistory.push({
      tool: ['text'],           // modified, used to be "canvas"
      action: 'text',
      params: [BB.copyObj(p), this.utilities.getCurrentCanvasID()],
    });
    this.turnOffTextPrompt();
  }
}