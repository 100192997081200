import React, { useMemo, useRef, useEffect, useContext, useState } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { RectCollisionSystem } from '../system/collisionSystem/CollisionEngine';
import { CanvasManipulator } from "../system/CanvasManipulator.js";
import { FlexRect } from '../cross_page_components/FlexRectangles';
import { ImageProcessor } from '../utility/ImageProcessor.js';
import { PostInstanceReactionBar } from '../cross_page_components/TooltipBars.jsx';
import { FloatingCanvasEditingBar } from '../cross_page_components/TooltipBars.jsx';
import { UploadPostPanel } from '../cross_page_components/PostMedia.jsx';
import { PostDisplayAndUtility } from "../system/PostDisplayAndUtility.js";
import { PostMainPanel } from "../cross_page_components/PostMedia.jsx";
import NoPostImage from "../img/Pencel_The_Post_is_Gone.svg"
import more_icon from "../icons/more-horizontal-circle-white.svg"
import file_list from '../icons/file-list-fill.svg';
import hand from '../icons/left_hand_icon.svg';
import pen from '../icons/edit-fill.svg';

import { styled } from '@mui/system';
import { tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/system';
import { Card, Paper, CardMedia, CardContent, Typography } from '@mui/material';


import BookRoundedIcon from '@mui/icons-material/BookRounded';
import PhotoAlbumRoundedIcon from '@mui/icons-material/PhotoAlbumRounded';
import PlayLessonRoundedIcon from '@mui/icons-material/PlayLessonRounded';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    maxWidth: 220,
  },
}));

const PostCanvases = ({ }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [displayScale, setDisplayScale] = useState(system_operations.coordinator.getTheoreticalSpaceToScreenSpaceRatio());
  const [rectsEditMode, setRectsEditMode] = useState(false);
  const theme = useTheme();

  const fenceByPassRule = useMemo(() => {
    return (fenceRect) => {
      const fence = fenceRect?.dataRef;
      if (!fence) {
        return false;
      }
      const allowPosting = system_operations.fenceCoder.isFlagSetByName(fence.rules, "postingOther");
      if (fence.creatorID === system_operations.getUserID() || allowPosting) {
        return true;
      } else {
        return false;
      }
    }
  }, []);

  const [allPostInstances, setAllPostInstances] = useState(system_operations.coordinator.currentDisplayingPosts)

  useEffect(() => {
    system_operations.coordinator.onPostsChange["fromPostsCanvases"] = setAllPostInstances;
    system_operations.coordinator.onTheoreticalSpaceToScreenSpaceScaleChange["fromPostsCanvases"] = setDisplayScale;
    system_operations.coordinator.toolpack.onToolChange["fromPostsCanvases"] = (newTool) => {
      if (newTool.name === "pi") {
        newTool.setMode(0);
        setRectsEditMode(true);
      } else if (newTool.name === "fc") {
        setRectsEditMode(true);
      } else {
        system_operations.coordinator.toolpack.getToolByName("pi").setMode(0);
        setRectsEditMode(false);
      }
    };

    return () => {
      delete system_operations.coordinator.onPostsChange["fromPostsCanvases"];
      delete system_operations.coordinator.onTheoreticalSpaceToScreenSpaceScaleChange["fromPostsCanvases"];
      delete system_operations.coordinator.toolpack.onToolChange["fromPostsCanvases"];
    }
  }, []);

  useEffect(() => {
    const postSticker = system_operations.coordinator.toolpack.getToolByName("pi");
    postSticker.startCreationHook = startCreationMode;
    postSticker.startEditingHook = startEditingMode;
    // postSticker.submitHook = onSubmit;
    // postSticker.cancelHook = onCancel;
  }, [allPostInstances])


  // ======================================================================================================================================================
  // ==============================Create New Float Canvases===============================================================================================
  const startCreationMode = () => {
    // don't immediate start the guidebox. because we need user to write the post first
    system_operations.setContentWindow(<UploadPostPanel />);
  }

  const startEditingMode = () => {
    system_operations.coordinator.guideBox.setActive(false);
    setRectsEditMode(true);
  }
  // ==============================Create New Float Canvases===============================================================================================
  // ======================================================================================================================================================

  return (
    <div style={{ zIndex: theme.zIndex.paper }}>
      {allPostInstances.map((postInstanceRect, index) => {
        return (
          <PostInstanceCoreWrapper postInstanceRect={postInstanceRect} rectsEditMode={rectsEditMode} fenceByPassRule={fenceByPassRule} displayScale={displayScale} index={index} theme={theme} key={postInstanceRect.getID()} />
        )
      })}
    </div >
  );
}

const PostInstanceCoreWrapper = ({ postInstanceRect, rectsEditMode, fenceByPassRule, displayScale, index, theme }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [interactable, setInteractable] = useState(postInstanceRect.interactable);
  const rectSelectorRef = useRef(null);
  const containerRef = useRef(null);
  const [layer, setLayer] = useState(postInstanceRect.getActiveLayer());

  const refreshCounter = useRef(0);
  const [refresh, setRefresh] = useState(0);

  postInstanceRect.onLayerChange["piBase"] = (layer) => {
    layer = Math.max(Math.min(layer, system_operations.coordinator.totalFloatingLayers - 1), 0);
    setLayer(layer);
  }

  const rectEditable = (postInstance) => {
    if (rectsEditMode && system_operations.getUserID() === postInstance.creatorID) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    postInstanceRect.func.styleRefreshPaperFullComponent = () => {
      refreshCounter.current += 1;
      setRefresh(refreshCounter.current);
    }

    const pointerUp = () => {
      const currentTool = system_operations.coordinator.toolpack.get_current_tool();
      if (currentTool.name == "hand" || currentTool.isType1PaperEditor) {
        system_operations.coordinator.toolpack.setCurrentSelectedRect(postInstanceRect);
      }
    }
    if (containerRef.current) {
      containerRef.current.addEventListener("pointerup", pointerUp);
    }
    return () => {
      delete postInstanceRect.func.styleRefreshPaperFullComponent;
      if (containerRef.current) {
        containerRef.current.addEventListener("pointerup", pointerUp);
      }
    }
  }, [postInstanceRect])

  postInstanceRect.func.setInteractable = (allow = true) => {
    postInstanceRect.interactable = allow;
    setInteractable(allow);
  }
  const editAble = rectEditable(postInstanceRect.getData());
  return (
    <div ref={containerRef}>
      <FlexRect
        dataRect={postInstanceRect}
        lookStyle={null}
        defaultCollisions={system_operations.coordinator.getPaperCollectionSet(layer, fenceByPassRule, "floatingCanvases")}
        scale={displayScale}
        zIndex={theme.zIndex.paper[layer]} activeDz={theme.zIndex.paper_dz.dp_1} inactiveDz={theme.zIndex.paper_dz.dp_0}
        flexBoxID={`postInstance_${postInstanceRect.getID()}`}
        editable={editAble}
        interactable={interactable}
        maxWidth={300}
        maxHeight={300}
        type={"postInstance"}
        getAllMagnetRects={() => { return system_operations.coordinator.getAllCurrentBasicPapers() }}
        key={postInstanceRect.getID()}>
        <div>
          <PostInstanceCore editAble={editAble} editMode={rectsEditMode} postInstanceRect={postInstanceRect} layer={layer} />
          <img ref={rectSelectorRef} src={more_icon} style={{ position: "absolute", width: "20px", height: "20px", left: "10px", top: "10px", zIndex: theme.zIndex.paper_dz.m_5 }}></img>
        </div>
      </FlexRect>
    </div>
  );
}

const PostInstanceCore = ({ postInstanceRect, editAble, editMode, layer }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const refreshCounter = useRef(0);
  const [refresh, setRefresh] = useState(0);
  const postInstanceData = postInstanceRect.getData();

  const theme = useTheme();

  postInstanceRect.func.styleRefreshPostInstanceCore = () => {
    refreshCounter.current += 1;
    setRefresh(refreshCounter.current);
  }

  const scale = system_operations.coordinator.boardPositioner.scaleToScr.bind(system_operations.coordinator.boardPositioner);
  return (
    <div style={{ position: "absolute", width: `100%`, height: `100%`, zIndex: theme.zIndex.paper_dz.m_0 }}>
      {editMode &&
        (editAble ? (<div style={{
          position: "absolute", width: `100%`, height: `100%`, userSelect: "none", zIndex: theme.zIndex.paper_dz.m_1,
          border: `${5}px dashed ${theme.flexRects.flexRectBoarderColorsAllow[layer]}`, borderRadius: `${5}px`, boxSizing: 'border-box'
        }} />) : (<div style={{
          position: "absolute", width: `100%`, height: `100%`, userSelect: "none", zIndex: theme.zIndex.paper_dz.m_1,
          border: `${5}px dashed ${theme.flexRects.flexRectBoarderColorsDisallow[layer]}`, borderRadius: `${5}px`, boxSizing: 'border-box'
        }} />))
      }
      <ShadedImagePostCard postObject={postInstanceRect.getData()} paperRect={postInstanceRect} />
    </div>
  )
}

export const RectPostCard = ({paperRect, interactWithMedia}) => {
  const canvasData = paperRect.getData(); // paper Rect has to exist to be here.
  return (
    <div style={{cursor: interactWithMedia ? "pointer" : ""}}>
      <ShadedImagePostCard postObject={canvasData.extension.data} paperRect={paperRect}/>
    </div>
  )
} 

// postObject can be either original post or post instance. as they all contains previewUrls, mediaType, text, postID(original post' postID will be its doc id).
// paperRect param more like a indicator if the post is on board of displayed as options.
export const ShadedImagePostCard = ({ postObject, paperRect = null }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [shadeText, setShadeText] = React.useState('');
  const [currentPreviewImage, setCurrentPreviewImage] = React.useState(postObject?.previewUrls?.[0] || null);
  const postContainerRef = useRef(null);

  // Convert Quill Delta to plain text on component mount
  useEffect(() => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(postObject.text, 'text/html');
    setShadeText(htmlDoc.body.textContent || '');
  }, [postObject]);

  const handleError = () => {
    setCurrentPreviewImage(NoPostImage);
  };

  const openPost = async () => {
    if (paperRect) {
      // it's a post instance
      const originalPost = await system_operations.postLoader.getPostFromInstance(postObject); // should just use a fetch instead to save ram...
      system_operations.setContentWindow(<PostMainPanel isCreating={false} existingPost={originalPost} postInstanceRectRef={paperRect} style={{ borderRadius: "10px" }} />)
    } else {
      // it's a post
      system_operations.setContentWindow(<PostMainPanel isCreating={false} existingPost={postObject} postInstanceRectRef={null} style={{ borderRadius: "10px" }} />)
    }
  }

  const height = paperRect?.getActiveHeight() || 128; // if postRect is provided, then use the rect' 
  const shadeStartHeight = system_operations.coordinator.theoreticalSpaceToScreenSpace(height * 0.65);
  const shadeDelta = system_operations.coordinator.theoreticalSpaceToScreenSpace(30);
  const textPadding = system_operations.coordinator.theoreticalSpaceToScreenSpace(5);
  const iconStyle = { position: "absolute", right: "5px", bottom: "5px", color: "rgba(70, 70, 70, 0.8)" };
  let iconIndicator = null;
  switch (postObject.mediaType) {
    case "image":
      iconIndicator = (<PhotoAlbumRoundedIcon sx={iconStyle}></PhotoAlbumRoundedIcon>);
      break;

    case "video":
      iconIndicator = (<PlayLessonRoundedIcon sx={iconStyle}></PlayLessonRoundedIcon>);
      break;

    case "audio":
      iconIndicator = (<PlayLessonRoundedIcon sx={iconStyle}></PlayLessonRoundedIcon>);
      break;

    default:
      iconIndicator = (<BookRoundedIcon sx={iconStyle}></BookRoundedIcon>);
      break;
  }

  const scale = system_operations.coordinator.boardPositioner.scaleToScr.bind(system_operations.coordinator.boardPositioner);
  return (
    <Card ref={postContainerRef} onClick={openPost} sx={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%", userSelect: 'none', overflow: 'hidden' }}>
      <CardMedia
        component="img"
        alt="no image..."
        image={currentPreviewImage}
        onError={handleError}
        sx={{ height: '100%', width: '100%', objectFit: 'cover', }} />
      <CardContent
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: "100%",
          background: `linear-gradient(to top, rgba(0, 0, 0, 0.93), rgba(0, 0, 0, 0.93) ${shadeStartHeight}px, transparent ${shadeStartHeight + shadeDelta}px)`,
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          padding: 0,
          opacity: 0,
          transition: 'opacity 0.3s',
          ':hover': {
            opacity: 1,
          }
        }}>
        <Typography
          variant="secondary"
          color="white"
          sx={{
            position: "absolute",
            left: textPadding, right: textPadding, bottom: 0,
            height: shadeStartHeight, width: "100%",
            whiteSpace: 'normal', overflowWrap: 'break-word',
            color: '#eeeeee',
            fontSize: '1rem',
            fontWeight: 400,
            alignSelf: 'flex-start', // try to override parent's centering
          }}>
          {shadeText}
        </Typography>
      </CardContent>
      {iconIndicator}
    </Card>
  );
}

export default PostCanvases;