import React, { useMemo, useEffect, useState, useContext, useRef } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { RequestDataMaker } from "../system/RestServerConnector.js"

import { TextField, Button, styled } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: "#777777",
  borderRadius: "10px",
  padding: "10px",
});

const StyledTextField = styled(TextField)({
  width: '700px',
  maxWidth: '1500px',
  '@media (max-width: 500px)': {
    width: '90%',
  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const ContentMarkPrompt = ({ rectRef = null, baseType = "none", existMarkID = "" }) => {
  // markID is type_typeID
  const { system_operations } = useContext(SystemOperationsContext);
  const [markExist, setMarkExist] = React.useState(rectRef?.data.marked || existMarkID);
  const [title, setTitle] = React.useState('');

  const getIDNameType = () => {
    let type = "";
    if (baseType === "fc") {
      type = "canvas";
    } else if (baseType === "pi") {
      type = "postInstance";
    }
    return type;
  }
  useEffect(() => {
    if (existMarkID) {
      system_operations.firestoreActions.fetchFromFirestore(`boards/${system_operations.coordinator.getWallID()}/marks/${existMarkID}`, "markID").then((mark) => {
        if (mark) {
          setTitle(mark.title);
          setMarkExist(true);
        }
      })
    } else if (rectRef) {
      if (rectRef.data.marked) {
        const type = getIDNameType();
        system_operations.firestoreActions.fetchFromFirestore(`boards/${system_operations.coordinator.getWallID()}/marks/${type}_${rectRef.getID()}`, "markID").then((mark) => {
          if (mark) {
            setTitle(mark.title);
            setMarkExist(true);
          }
        })
      }
    }
  }, [])

  const handleTextChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSubmit = async () => {
    system_operations.set_loading(true);
    if (title) {
      // create new one..
      const markCreationData = await RequestDataMaker.dataToCreateAMark(system_operations, title, baseType, rectRef ? rectRef.getID() : "", rectRef ? rectRef.getActiveCoord() : "");
      const result = await system_operations.restServerConnector.createAMark(markCreationData);
      if (result && result.success) { console.log(result) }
    } else {
      // delete old one..
      if (markExist) {
        let markID = existMarkID;
        if (!markID && rectRef) {
          const type = getIDNameType();
          markID = `${type}_${rectRef.getID()}` // compose a markID
        }
        const markdeletionData = await RequestDataMaker.dataToDeleteAMark(system_operations, markID);
        const result = await system_operations.restServerConnector.deleteAMark(markdeletionData);
        if (result && result.success) { console.log(result) }
      }
    }
    system_operations.set_loading(false);
  };

  return (
    <Container>
      <StyledTextField
        variant="outlined"
        value={title}
        placeholder='Give it a title...'
        onChange={handleTextChange}
      />
      <StyledButton
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        startIcon={<StarIcon />}
      >
        MARK
      </StyledButton>
    </Container>
  );
};

export default ContentMarkPrompt;