import { KDrawAPI } from "../Klecks/app/script/KlecksOnlyDrawSupport.ts"
import { TextPrompt } from "../Klecks/app/script/klecks/ui/modals/TextPrompt"
import { CanvasManipulator } from "./CanvasManipulator.js";

import { measureText, boxRotation } from "../utility/TextSizeCalculator.js";
import { RequestDataMaker } from "./RestServerConnector.js";


export class KDraw {
  constructor(system) {
    this.system = system;
    this.coordinator = this.system.coordinator;
    this.toolpack = this.system.coordinator.toolpack;

    // assign the section loader for canvas to be the container
    this.fcRectsContainer = this.coordinator.sectionData.sectionLoaderForCanvases;

    this.allowComboKeys = true;
    this.initialized = false;
    this.boundMargin = 50;

    this.currentCanvasID = "main";
    this.currentCanvas = null;
    this.currentCtx = null;
    this.mainCanvas = null;
    this.mainCanvasCtx = null;

    this.canvasDataTracker = {};
    this.currentTextPrompt = {};
    // this.textPromptSwitch = (on) => { };
    this.isChangesEmpty = true;
    this.onIsChangesEmptyChange = {};
  }

  initialize(mainCanvas) {
    if (!this.initialized) {
      this.initialized = true;
      this.mainCanvas = mainCanvas;
      this.mainCanvasCtx = this.mainCanvas.getContext("2d");
      // if (this.currentCanvasID === "main") {
      this.currentCanvas = this.mainCanvas;
      this.currentCtx = this.mainCanvasCtx;
      // }

      // create the draw api only after the main canvas is set.
      this.kApi = new KDrawAPI({
        getCurrentCanvasID: this.getCurrentCanvasID.bind(this),
        getCurrentCtx: this.getCurrentCtx.bind(this),
        setCurrentCtx: this.setCurrentCtx.bind(this),
        getCtxByID: this.getCtxByID.bind(this),
        globalPositionToLocalPosition: this.globalPositionToLocalPosition.bind(this),
        updateCurrentCanvasValidBoundaryByBrush: this.updateCurrentCanvasValidBoundaryByBrush.bind(this),
        updateCurrentCanvasValidBoundaryByText: this.updateCurrentCanvasValidBoundaryByText.bind(this),
        pointerEventProcessors: { eventDownProcessor: this.eventDownProcessor.bind(this), eventMoveProcessor: null, eventUpProcessor: null },
        textPromptOn: (p) => { this.currentTextPrompt = p; this.textPromptSwitch(true); },
        textPromptOff: () => { this.currentTextPrompt = {}; this.textPromptSwitch(false); },
        allowComboKeys: () => { return this.allowComboKeys; }
      });
    }
  }

  /* setTextPromptSwitch(textPromptHook) {
    this.textPromptSwitch = textPromptHook;
  }*/

  setAllowShotcupKeys(allow) {
    this.allowComboKeys = allow;
  }

  textPromptSwitch(on) {
    // this will handle the setting of allowComboKeys...
    if (on) {
      this.system.setContentWindow(<TextPrompt params={this.currentTextPrompt} />)
    } else {
      this.system.setContentWindow(null);
    }
  }

  getCtxByID(id) {
    if (id === "main") {
      return this.mainCanvasCtx;
    } else {
      const canvasRect = this.fcRectsContainer.getFromSavedData(id);
      if (canvasRect && canvasRect.utilities.canvas) {
        return canvasRect.utilities.canvas.getContext("2d");
      }
    }
    return null;
  }

  getCurrentCanvasID() {
    return this.currentCanvasID;
  }
  getCurrentCtx() {
    if (this.currentCtx) {
      return this.currentCtx;
    } else if (this.currentCanvasID) {
      const ctx = this.getCtxByID(this.currentCanvasID);
      if (ctx) {
        this.currentCanvas = ctx.canvas;
        this.currentCtx = ctx;
        return ctx;
      }
    }
    return null;
  }

  setCurrentCtx = (id) => {
    // this function should handle case of failure
    const ctx = this.getCtxByID(id);
    if (ctx) {
      this.currentCanvasID = id;
      this.currentCanvas = ctx.canvas;
      this.currentCtx = ctx;
      return true;
    }
    return false;
  }

  // this should come after the pointer event processor
  globalPositionToLocalPosition(clienX, clientY) {
    if (this.currentCanvas) {
      const rect = this.currentCanvas.getBoundingClientRect();
      const scaleX = this.currentCanvas.width / rect.width;
      const scaleY = this.currentCanvas.height / rect.height;
      return [(clienX - rect.left) * scaleX, (clientY - rect.top) * scaleY];
    }
  }

  eventDownProcessor(event) {
    const targetElement = event.target;
    if (targetElement.hasAttribute('data-canvas-id')) {
      const canvasID = targetElement.getAttribute('data-canvas-id');
      if (!this.canDraw(canvasID)) {
        return false;
      }
      this.kApi.setCurrentCanvas(canvasID); // this set the current ctx in kDraw.
      if (canvasID !== "main") {
        this.fcRectsContainer.addToCheekyItems(this.fcRectsContainer.getFromSavedData(canvasID));
        if (!(canvasID in this.canvasDataTracker)) {
          // not in.
          this.canvasDataTracker[canvasID] = {
            ctx: this.currentCtx,
            imageData: this.currentCtx.getImageData(0, 0, this.currentCtx.canvas.width, this.currentCtx.canvas.height),
            boundary: {
              left: 999999,
              right: 0,
              top: 999999,
              bottom: 0,
            }
          }
          this.isChangesEmpty = false;
          for (const key in this.onIsChangesEmptyChange) { this.onIsChangesEmptyChange[key](this.isChangesEmpty); }
        }
      } else if (!("main" in this.canvasDataTracker)) {
        // is main
        this.canvasDataTracker["main"] = { // do not store ctx and imageData. like a special case.
          boundary: {
            left: 999999,
            right: 0,
            top: 999999,
            bottom: 0,
          }
        }
        this.isChangesEmpty = false;
        for (const key in this.onIsChangesEmptyChange) { this.onIsChangesEmptyChange[key](this.isChangesEmpty); }
      }
      return canvasID;
    }
    return false;
  }

  updateCurrentCanvasValidBoundaryByBrush(coordX, coordY, size) {
    const radius = Math.ceil((size + 2) / 2); // exceed at max 2.
    const left = coordX - radius - this.boundMargin;
    const right = coordX + radius + this.boundMargin;
    const top = coordY - radius - this.boundMargin;
    const bottom = coordY + radius + this.boundMargin;
    if (this.canvasDataTracker[this.currentCanvasID] && this.currentCanvas) {
      const bound = this.canvasDataTracker[this.currentCanvasID].boundary;
      if (bound.left > left) { bound.left = Math.max(left, 0); }
      if (bound.right < right) { bound.right = Math.min(right, this.currentCanvas.width); }
      if (bound.top > top) { bound.top = Math.max(top, 0); }
      if (bound.bottom < bottom) { bound.bottom = Math.min(bottom, this.currentCanvas.height); }
    }
  }

  updateCurrentCanvasValidBoundaryByText(pivotGlobalX, pivotGlobalY, pivotRatioX, pivotRatioY, angleRad, width, height) {
    // const finalBound = boxRotation(pivotX, pivotY, p.angleRad, {x: pivotXRatio, y: pivotYRatio});
    // console.log(finalBound);
    // this.updateCurrentCanvasValidBoundaryByBox(p.x, p.y, finalBound.width, finalBound.height);
    const result = boxRotation(pivotGlobalX, pivotGlobalY, pivotRatioX, pivotRatioY, angleRad, width, height);
    this.updateCurrentCanvasValidBoundaryByBox(result.x, result.y, result.width, result.height);
  }

  updateCurrentCanvasValidBoundaryByBox(boxX, boxY, boxWidth, boxHeight) {
    const left = boxX - this.boundMargin;
    const right = boxX + boxWidth + this.boundMargin;
    const top = boxY - this.boundMargin;
    const bottom = boxY + boxHeight + this.boundMargin;
    if (this.canvasDataTracker[this.currentCanvasID] && this.currentCanvas) {
      const bound = this.canvasDataTracker[this.currentCanvasID].boundary;
      if (bound.left > left) { bound.left = Math.max(left, 0); }
      if (bound.right < right) { bound.right = Math.min(right, this.currentCanvas.width); }
      if (bound.top > top) { bound.top = Math.max(top, 0); }
      if (bound.bottom < bottom) { bound.bottom = Math.min(bottom, this.currentCanvas.height); }
    }
  }

  canDraw(canvasID) {
    if (this.toolpack.current_tool.allowKDrawBehaviors) {
      if (canvasID === "main") {
        return true;
      } else {
        const canvasRect = this.fcRectsContainer.getFromSavedData(canvasID);
        if (canvasRect && canvasRect.getData().creatorID === this.system.getUserID() && canvasRect.utilities?.canvas) {
          return true; // allowed.
        }
      }
    }
    return false;
  }

  // reset canvas to the original canvas state. doesn't effect the history
  restoreOriginalState() {
    for (const key in this.canvasDataTracker) {
      if (key === "main") {
        this.mainCanvasCtx.clearRect(0, 0, this.mainCanvas.width, this.mainCanvas.height);
      } else if (this.canvasDataTracker[key].ctx && this.canvasDataTracker[key].imageData) {
        this.canvasDataTracker[key].ctx.putImageData(this.canvasDataTracker[key].imageData, 0, 0);
      }
    }
  }

  saveAllChanges() {
    const dataTracker = this.canvasDataTracker; // the canvasDataTracker might get reset right after. though it should not matter. but in case want to access in "then()", we make an other ref.
    for (const key in dataTracker) {
      const offset = this.system.coordinator.drawingCanvas.getCanvasTheoOffset();
      if (key === "main" && this.mainCanvasCtx) {
        const drawSection = {
          canvasWidth: this.mainCanvasCtx.canvas.width,
          canvasHeight: this.mainCanvasCtx.canvas.height,
          xOffset: offset[0],
          yOffset: offset[1],
          onCanvasXMin: dataTracker[key].boundary.left,
          onCanvasXMax: dataTracker[key].boundary.right,
          onCanvasYMin: dataTracker[key].boundary.top,
          onCanvasYMax: dataTracker[key].boundary.bottom,
        }
        const clampedData = this.updateChanges(this.mainCanvasCtx, drawSection, true);
        this.mainCanvasCtx.clearRect(0, 0, this.mainCanvas.width, this.mainCanvas.height);
        RequestDataMaker.dataToUpdateMainCanvasImages(this.system, clampedData.dataUrl, this.system.coordinator.toolpack.globalEntity, clampedData.minX, clampedData.minY).then((dataJson) => {
          this.system.restServerConnector.updateMainCanvasImages(dataJson); 
        });
      } else if (dataTracker[key].ctx) {
        RequestDataMaker.dataToUpdateFloatingCanvasImage(this.system, key, dataTracker[key].ctx.canvas.toDataURL()).then((dataJson) => {
          this.system.restServerConnector.updateFloatingCanvasImage(dataJson).then((result) => {
            if (result.imageUrl) {
              const canvasRect = this.fcRectsContainer.getFromSavedData(key);
              if (canvasRect) {
                canvasRect.getData().imageUrl = result.imageUrl; // no need to react to the change. as we upload the image, the image should be already loaded.
              }
            }
          });
        });
        const paperRect = this.fcRectsContainer.getFromSavedData(key)
        if (paperRect) { // update the version
          paperRect.imageUrl = `${paperRect.imageUrl}?version=${new Date().getTime()}`;
        }
      }
    }
  }

  // clean up all history data. however doesn't do things to current canvas state.
  reset() {
    this.kApi?.removeAllMarks();
    this.canvasDataTracker = {};
    this.isChangesEmpty = true;
    this.fcRectsContainer.emptyCheekyItems();
    for (const key in this.onIsChangesEmptyChange) { this.onIsChangesEmptyChange[key](this.isChangesEmpty); }
  }

  // destroy anything in the draw component.
  cleanUp() { this.kApi?.cleanUp(); }

  // pass main canvas. 
  updateChanges(ctx, drawSection, onMainCanvas = true) {
    const canvasWidth = drawSection["canvasWidth"]
    const canvasHeight = drawSection["canvasHeight"]
    const globalEntity = this.system.coordinator.toolpack.globalEntity; // add this field to the toolpack.
    // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
    const xOffset = drawSection["xOffset"];
    const yOffset = drawSection["yOffset"];
    drawSection.xOffset = xOffset;
    drawSection.yOffset = yOffset;
    const onCanvasXMin = drawSection["onCanvasXMin"];
    const onCanvasXMax = drawSection["onCanvasXMax"];
    const onCanvasYMin = drawSection["onCanvasYMin"];
    const onCanvasYMax = drawSection["onCanvasYMax"];
    // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

    // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
    // clamp out x and y (depending on is horizontal or not)
    const clampResultX = this.clampCanvasCoordWithGlobalCoord(onCanvasXMin, onCanvasXMax);
    const clampResultY = this.clampCanvasCoordWithGlobalCoord(onCanvasYMin, onCanvasYMax);
    // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

    const checkClampResult = this.system.coordinator.isHorizontal ? clampResultX : clampResultY;

    if (checkClampResult.screenCoordMin < checkClampResult.screenCoordMax && checkClampResult.screenCoordMin >= 0 && checkClampResult.screenCoordMax <= this.system.coordinator.isHorizontal ? canvasWidth : canvasHeight) {
      // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
      const xIndexBoundary = this.system.coordinator.totalColumns - 1;
      const yIndexBoundary = this.system.coordinator.totalRows - 1;

      const leftXIndex = Math.max(Math.floor((xOffset + clampResultX.screenCoordMin) / this.system.coordinator.getGridWidth()), 0);
      const rightXIndex = Math.min(Math.floor((xOffset + clampResultX.screenCoordMax) / this.system.coordinator.getGridWidth()), xIndexBoundary);
      const leftXOffset = (xOffset + clampResultX.screenCoordMin) % this.system.coordinator.getGridWidth();
      const rightXOffset = (xOffset + clampResultX.screenCoordMax) % this.system.coordinator.getGridWidth();

      const leftYIndex = Math.max(Math.floor((yOffset + clampResultY.screenCoordMin) / this.system.coordinator.getGridHeight()), 0);
      const rightYIndex = Math.min(Math.floor((yOffset + clampResultY.screenCoordMax) / this.system.coordinator.getGridHeight()), yIndexBoundary);
      const leftYOffset = (yOffset + clampResultY.screenCoordMin) % this.system.coordinator.getGridHeight();
      const rightYOffset = (yOffset + clampResultY.screenCoordMax) % this.system.coordinator.getGridHeight();
      // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

      const canvasOperationDoneCallback = () => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      }
      if (ctx) {
        const clipped_ctx = CanvasManipulator.clip_canvas_context(ctx, clampResultX.screenCoordMin, clampResultX.screenCoordMax, clampResultY.screenCoordMin, clampResultY.screenCoordMax);
        this.system.coordinator.canvasManipulator.loadCanvasOnToManyColumns(clipped_ctx, globalEntity,
          this.system.coordinator.gridsSections.getGridGloballyAt.bind(this.system.coordinator.gridsSections),
          this.system.coordinator.gridsSections.updateGridGloballyAt.bind(this.system.coordinator.gridsSections),
          this.system.coordinator.gridWidth, this.system.coordinator.gridHeight,
          [leftXIndex, rightXIndex], [leftXOffset, rightXOffset],
          [leftYIndex, rightYIndex], [leftYOffset, rightYOffset],
          onMainCanvas ? canvasOperationDoneCallback : null);
        return {
          minX: xOffset + clampResultX.screenCoordMin,
          minY: yOffset + clampResultY.screenCoordMin,
          dataUrl: clipped_ctx.canvas.toDataURL(),
        }
      }
    } else {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }
    return false;
  };

  clampCanvasCoordWithGlobalCoord = (min, max) => {
    return {
      screenCoordMin: min,
      screenCoordMax: max,
    }
  }
}