// generated from src/languages. "npm run lang:build" to update

import dataEn from './en.json';

export const english = dataEn;
export const languages: {code: string; name: string}[] = [
    {
        code: 'en',
        name: 'English',
    },
    {
        code: 'de',
        name: 'Deutsch',
    },
    {
        code: 'fr',
        name: 'Français',
    },
    {
        code: 'ja',
        name: '日本語',
    },
    {
        code: 'zh-CN',
        name: '简体中文',
    },
    {
        code: 'zh-TW',
        name: '繁體中文',
    },
];
export const loadLanguage = async (code: string): Promise<Record<TTranslationCode, string>> => {
    if (code === 'en') {
        return english;
    } else if (code === 'de') {
        return await import('./de.json');
    } else if (code === 'fr') {
        return await import('./fr.json');
    } else if (code === 'ja') {
        return await import('./ja.json');
    } else if (code === 'zh-CN') {
        return await import('./zh-CN.json');
    } else if (code === 'zh-TW') {
        return await import('./zh-TW.json');
    }
    throw new Error('unknown language code');
};

export type TTranslationCode = 'switch-ui-left-right' | // Switch left/right UI
    'toggle-show-tools' | // Show/Hide Tools
    'scroll' | // Scroll
    'donate' | // Donate
    'home' | // Home
    'modal-new-tab' | // Open in new tab
    'tab-edit' | // Edit
    'tab-file' | // File
    'tool-brush' | // Brush
    'tool-paint-bucket' | // Paint Bucket
    'tool-gradient' | // Gradient
    'tool-shape' | // Shape
    'tool-text' | // Text
    'tool-hand' | // Hand Tool
    'tool-zoom' | // Zoom
    'undo' | // Undo
    'redo' | // Redo
    'brush-pen' | // Pen
    'brush-blend' | // Blend
    'brush-sketchy' | // Sketchy
    'brush-pixel' | // Pixel
    'brush-chemy' | // Chemy
    'brush-smudge' | // Smudge
    'brush-size' | // Size
    'brush-blending' | // Blending
    'brush-toggle-pressure' | // Toggle Pressure Sensitivity
    'brush-pen-circle' | // Circle
    'brush-pen-chalk' | // Chalk
    'brush-pen-calligraphy' | // Calligraphy
    'brush-pen-square' | // Square
    'brush-sketchy-scale' | // Scale
    'brush-pixel-dither' | // Dither
    'brush-chemy-fill' | // Fill
    'brush-chemy-stroke' | // Stroke
    'brush-chemy-mirror-x' | // Horizontal Symmetry
    'brush-chemy-mirror-y' | // Vertical Symmetry
    'brush-chemy-gradient' | // Gradient
    'brush-eraser-transparent-bg' | // Transparent Background
    'stabilizer' | // Stabilizer
    'stabilizer-title' | // Stroke Stabilizer
    'eyedropper' | // Eyedropper
    'secondary-color' | // Secondary Color
    'manual-color-input' | // Manual Color Input
    'mci-hex' | // Hex
    'mci-copy' | // Copy
    'modal-ok' | // Ok
    'modal-cancel' | // Cancel
    'modal-close' | // Close
    'layers-active-layer' | // Active Layer
    'layers-layer' | // Layer
    'layers-copy' | // copy
    'layers-blending' | // Blending
    'layers-new' | // New Layer
    'layers-remove' | // Remove Layer
    'layers-duplicate' | // Duplicate Layer
    'layers-merge' | // Merge with layer below
    'layers-rename' | // Rename
    'layers-active-layer-visible' | // Active layer is visible
    'layers-active-layer-hidden' | // Active layer is hidden
    'layers-visibility-toggle' | // Layer Visibility
    'layers-blend-normal' | // normal
    'layers-blend-darken' | // darken
    'layers-blend-multiply' | // multiply
    'layers-blend-color-burn' | // color burn
    'layers-blend-lighten' | // lighten
    'layers-blend-screen' | // screen
    'layers-blend-color-dodge' | // color dodge
    'layers-blend-overlay' | // overlay
    'layers-blend-soft-light' | // soft light
    'layers-blend-hard-light' | // hard light
    'layers-blend-difference' | // difference
    'layers-blend-exclusion' | // exclusion
    'layers-blend-hue' | // hue
    'layers-blend-saturation' | // saturation
    'layers-blend-color' | // color
    'layers-blend-luminosity' | // luminosity
    'layers-rename-title' | // Rename Layer
    'layers-rename-name' | // Name
    'layers-rename-clear' | // Clear Name
    'layers-rename-sketch' | // Sketch
    'layers-rename-colors' | // Colors
    'layers-rename-shading' | // Shading
    'layers-rename-lines' | // Lines
    'layers-rename-effects' | // Effects
    'layers-rename-foreground' | // Foreground
    'layers-merge-modal-title' | // Merge/Mix Layers
    'layers-merge-description' | // Merges the selected layer with the one underneath. Select the mix mode:
    'file-no-autosave' | // No autosave, no cloud storage
    'file-new' | // New
    'file-import' | // Import
    'file-save' | // Save
    'file-format' | // File Format
    'file-copy' | // Copy
    'file-copy-title' | // Copy To Clipboard
    'file-share' | // Share
    'file-storage' | // Browser Storage
    'file-storage-thumb-title' | // Restores when reopening page
    'file-storage-about' | // About Browser Storage
    'file-storage-cant-access' | // Can't access
    'file-storage-empty' | // Empty
    'file-storage-store' | // Store
    'file-storage-clear' | // Clear
    'file-storage-storing' | // Storing
    'file-storage-overwrite' | // Overwrite
    'file-storage-min-ago' | // {x}min ago
    'file-storage-hours-ago' | // {x}h ago
    'file-storage-days-ago' | // {x}d ago
    'file-storage-month-ago' | // > 1month ago
    'file-storage-restored' | // Restored from Browser Storage
    'file-storage-stored' | // Stored to Browser Storage
    'file-storage-failed' | // Failed to store to Browser Storage
    'file-storage-failed-1' | // Failed to store. Possible causes:
    'file-storage-failed-2' | // Out of disk space
    'file-storage-failed-3' | // Storage disabled in incognito tab
    'file-storage-failed-4' | // Browser doesn't support storage
    'file-storage-failed-clear' | // Failed to clear.
    'file-upload' | // Upload
    'cleared-layer' | // Cleared layer
    'filled' | // Filled
    'new-title' | // New Image
    'new-current' | // Current
    'new-fit' | // Fit
    'new-oversize' | // Oversize
    'new-square' | // Square
    'new-landscape' | // Landscape
    'new-portrait' | // Portrait
    'new-screen' | // Screen
    'new-video' | // Video
    'new-din-paper' | // DIN Paper
    'new-px' | // px
    'new-ratio' | // Ratio
    'upload-title' | // Upload to Imgur
    'upload-link-notice' | // Anyone with the link to your uploaded image will be able to view it.
    'upload-name' | // Title
    'upload-title-untitled' | // Untitled
    'upload-caption' | // Caption
    'upload-submit' | // Upload
    'upload-uploading' | // Uploading...
    'upload-success' | // Upload Successful
    'upload-failed' | // Upload failed.
    'upload-delete' | // To delete your image from Imgur visit:
    'cropcopy-title-copy' | // Copy To Clipboard
    'cropcopy-title-crop' | // Crop
    'cropcopy-click-hold' | // Right-click or press hold to copy.
    'cropcopy-btn-copy' | // To Clipboard
    'cropcopy-copied' | // Copied.
    'cropcopy-btn-crop' | // Apply Crop
    'crop-drag-to-crop' | // Drag to crop
    'filter-crop-extend' | // Crop/Extend
    'filter-flip' | // Flip
    'filter-perspective' | // Perspective
    'filter-resize' | // Resize
    'filter-rotate' | // Rotate
    'filter-transform' | // Transform
    'filter-bright-contrast' | // Bright/Contrast
    'filter-curves' | // Curves
    'filter-hue-sat' | // Hue/Saturation
    'filter-invert' | // Invert
    'filter-tilt-shift' | // Tilt Shift
    'filter-to-alpha' | // To Alpha
    'filter-triangle-blur' | // Triangle Blur
    'filter-unsharp-mask' | // Unsharp Mask
    'filter-crop-title' | // Crop / Extend
    'filter-crop-description' | // Crop or extend the image.
    'filter-crop-left' | // Left
    'filter-crop-right' | // Right
    'filter-crop-top' | // Top
    'filter-crop-bottom' | // Bottom
    'filter-crop-rule-thirds' | // Rule of Thirds
    'filter-crop-fill' | // Fill
    'filter-flip-title' | // Flip
    'filter-flip-description' | // Flips layer or whole image.
    'filter-flip-horizontal' | // Horizontal
    'filter-flip-vertical' | // Vertical
    'filter-flip-image' | // Flip Image
    'filter-flip-layer' | // Flip Layer
    'filter-perspective-title' | // Perspective
    'filter-perspective-description' | // Transforms the selected layer.
    'filter-resize-title' | // Resize
    'filter-resize-description' | // Resizes the image.
    'filter-rotate-title' | // Rotate
    'filter-rotate-description' | // Rotates the image.
    'filter-transform-empty' | // Layer is empty.
    'filter-transform-title' | // Transform
    'filter-transform-description' | // Transforms selected layer. Hold Shift for additional behavior.
    'filter-transform-rotation' | // Rotation
    'filter-transform-flip' | // Flip
    'filter-transform-center' | // Center
    'filter-transform-constrain' | // Constrain
    'filter-transform-snap' | // Snap
    'filter-transform-snap-title' | // Snap Rotation And Position
    'filter-bright-contrast-title' | // Brightness / Contrast
    'filter-bright-contrast-description' | // Change brightness and contrast for the selected layer.
    'filter-bright-contrast-brightness' | // Brightness
    'filter-bright-contrast-contrast' | // Contrast
    'filter-curves-title' | // Curves
    'filter-curves-description' | // Apply curves on the selected layer.
    'filter-curves-all' | // All
    'filter-hue-sat-title' | // Hue / Saturation
    'filter-hue-sat-description' | // Change hue and saturation for the selected layer.
    'filter-hue-sat-hue' | // Hue
    'filter-hue-sat-saturation' | // Saturation
    'filter-applied' | // applied
    'filter-tilt-shift-title' | // Tilt Shift
    'filter-tilt-shift-description' | // Applies tilt shift on the selected layer.
    'filter-tilt-shift-blur' | // Blur Radius
    'filter-tilt-shift-gradient' | // Gradient Radius
    'filter-to-alpha-title' | // To Alpha
    'filter-to-alpha-description' | // Generates alpha channel for selected layer from:
    'filter-to-alpha-inverted-lum' | // Inverted Luminance
    'filter-to-alpha-lum' | // Luminance
    'filter-to-alpha-replace' | // Replace RGB
    'filter-triangle-blur-title' | // Triangle Blur
    'filter-triangle-blur-description' | // Applies triangle blur on the selected layer.
    'filter-unsharp-mask-title' | // Unsharp Mask
    'filter-unsharp-mask-description' | // Sharpens the selected layer by scaling pixels away from the average of their neighbors.
    'filter-unsharp-mask-strength' | // Strength
    'filter-grid' | // Grid
    'filter-grid-description' | // Draws grid on selected layer.
    'filter-noise' | // Noise
    'filter-noise-description' | // Adds noise to selected layer.
    'filter-noise-scale' | // Scale
    'filter-noise-alpha' | // Alpha
    'filter-pattern' | // Pattern
    'filter-pattern-description' | // Generates pattern on selected layer. Drag the preview for further controls.
    'filter-distort' | // Distort
    'filter-distort-description' | // Distorts the selected layer.
    'filter-distort-phase' | // Phase
    'filter-distort-stepsize' | // Step Size
    'filter-distort-sync-xy' | // Sync XY
    'filter-vanish-point' | // Vanish Point
    'filter-vanish-point-title' | // Vanishing Point
    'filter-vanish-point-description' | // Adds vanishing point to selected layer. Drag preview to move.
    'filter-vanish-point-lines' | // Lines
    'import-opening' | // Opening file...
    'import-title' | // Import Image
    'import-too-large' | // Image too large, will be downscaled.
    'import-btn-as-layer' | // As Layer
    'import-btn-as-image' | // As Image
    'import-as-layer-title' | // Import Image as New Layer
    'import-as-layer-description' | // Adjust the position of the imported image.
    'import-as-layer-limit-reached' | // Layer limit reached. Image will be placed on existing layer.
    'import-as-layer-fit' | // Fit
    'import-flatten' | // Flatten image
    'import-unsupported-file' | // Unsupported file type. See Help for supported types.
    'import-broken-file' | // Couldn't load image. File might be corrupted.
    'import-psd-unsupported' | // Unsupported features. PSD had to be flattened.
    'import-psd-limited-support' | // PSD support is limited. Flattened will more likely look correct.
    'import-psd-too-large' | // Image exceeds maximum dimensions of {x} x {x} pixels. Unable to import.
    'import-psd-size' | // Image size
    'hand-reset' | // Reset
    'hand-fit' | // Fit
    'bucket-tolerance' | // Tolerance
    'bucket-sample' | // Sample
    'bucket-sample-title' | // Which layers to sample color from
    'bucket-sample-all' | // All
    'bucket-sample-active' | // Active
    'bucket-sample-above' | // Above
    'bucket-grow' | // Grow
    'bucket-grow-title' | // Grow filled area (in pixels)
    'bucket-contiguous' | // Contiguous
    'bucket-contiguous-title' | // Only fill connected areas
    'gradient-linear' | // Linear
    'gradient-linear-mirror' | // Linear-Mirror
    'gradient-radial' | // Radial
    'shape-stroke' | // Stroke
    'shape-fill' | // Fill
    'shape-rect' | // Rectangle
    'shape-ellipse' | // Ellipse
    'shape-line' | // Line
    'shape-line-width' | // Line Width
    'shape-outwards' | // Outwards
    'shape-fixed' | // Fixed 1:1
    'text-instruction' | // Click canvas to place text
    'text-title' | // Add Text
    'text-placeholder' | // Your text (multiline Shift+Enter)
    'text-color' | // Color
    'text-size' | // Size
    'text-left' | // Left
    'text-center' | // Center
    'text-right' | // Right
    'text-italic' | // Italic
    'text-bold' | // Bold
    'save-reminder-title' | // Unsaved Work
    'save-reminder-text' | // Image was not saved in {a} minutes{b}. Save now to prevent eventual loss.
    'save-reminder-save-psd' | // Save As PSD
    'save-reminder-psd-layers' | // PSD will remember all layers.
    'backup-drawing' | // You can backup your drawing.
    'submit' | // Submit
    'submit-title' | // Submit Drawing
    'submit-prompt' | // Submit drawing?
    'submit-submitting' | // Submitting
    'embed-init-loading' | // Loading app
    'embed-init-waiting' | // Waiting for image
    'unsaved' | // Unsaved
    'help' | // Help
    'tab-settings' | // Settings
    'settings-language' | // Language
    'settings-language-reload' | // Will update after reloading.
    'settings-theme' | // Theme
    'theme-dark' | // Dark
    'theme-light' | // Light
    'terms-of-service' | // Terms of Service
    'licenses' | // Licenses
    'source-code' | // Source Code
    'auto' | // auto
    'zoom-in' | // Zoom In
    'zoom-out' | // Zoom Out
    'radius' | // Radius
    'constrain-proportions' | // Constrain Proportions
    'width' | // Width
    'height' | // Height
    'opacity' | // Opacity
    'red' | // Red
    'green' | // Green
    'blue' | // Blue
    'eraser' | // Eraser
    'center' | // Center
    'layers' | // Layers
    'background' | // Background
    'scaling-algorithm' | // Scaling Algorithm
    'algorithm-smooth' | // Smooth
    'algorithm-pixelated' | // Pixelated
    'preview' | // Preview
    'angle-snap' | // Snap
    'angle-snap-title' | // 45° Angle Snapping
    'lock-alpha' | // Lock Alpha
    'lock-alpha-title' | // Locks layer's alpha channel
    'reverse' | // Reverse
    'compare-before' | // Before
    'compare-after'; // After
