import React from 'react';
import { useContext, useState, useEffect } from "react";
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import './styles/LoadingScene.scss';
import LoadingSvg from '../img/Loading.svg';

const LoadingScene = () => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [is_loading, set_is_loading] = useState(false);
  useEffect(() => {
    system_operations.assign_set_loading_hook(set_is_loading);
  })

  return (
    <div>
      {is_loading > 0 &&
        <div className="loading-scene">
          <img src={LoadingSvg} alt="Loading..." />
        </div>}
    </div>
  );
};

export default LoadingScene;