import React, { useState, useEffect, useContext } from 'react';

import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { algoliaSearchApi } from "../AlgoliaAPI.js";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, connectHits, Configure } from 'react-instantsearch-dom';
import InputBase from '@mui/material/InputBase';

const algoliaSearchClient = algoliasearch('H72HT0SUBL', algoliaSearchApi);
const boardSearchIndex = algoliaSearchClient.initIndex('boards');

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: 0,
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: "38px",
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export const searchInMarks = async (boardID, query="") => {
  const searchIndex = algoliaSearchClient.initIndex(`board_${boardID}`);
  const hits = await searchIndex.search(query);
  return hits.hits;
}

export const BoardSearcher = () => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (searchQuery) {
      boardSearchIndex.search(query).then((hits) => {
        const nameList = [];
        for (const board of hits.hits) {
          nameList.push(board.objectID);
        }
        system_operations.homePageManager.tryDisplayCustomizedList(nameList, "searching");
      })
    }
  }, [searchQuery])

  const handleEnterPress = (e, value) => {
    if (e.key === 'Enter') {
      system_operations.homePageManager.setDisplayType("searching");
      setSearchQuery(value);
    }
  };

  const handleSearchBoxChange = (e) => {
    setQuery(e.currentTarget.value);
  };

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearchBoxChange}
        onKeyDown={(e) => handleEnterPress(e, query)}
      />
    </Search>
  );
}


