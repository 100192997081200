import React, { useState, useContext, useRef } from 'react';
import { SystemOperationsContext } from "../../context/SystemRunnerContext.js";
import { RequestDataMaker } from "../../system/RestServerConnector.js";
import { FaTimes } from 'react-icons/fa';



export function FCMediaControlPanel({ canvasRect, onChangeCallback }) {
  const { system_operations } = useContext(SystemOperationsContext);
  const [selection, setSelection] = useState(canvasRect.mediaData ? canvasRect.mediaData.type : "none");

  const handleSelectionChange = (e) => {
    setSelection(e.target.value);
  };

  return (
    <div style={{ borderRadius: "10px", overflow: "hidden" }}>
      <div style={{ height: "30px", width: "100%", backgroundColor: "#505050", color: "#f0f0f0", fontSize: "20px", alignItems: "center" }}> &nbsp;&nbsp; Media Attachment </div>
      <div style={{ backgroundColor: "rgba(160,160,160,1)", padding: "5px" }}>
        <select value={selection} onChange={handleSelectionChange}>
          <option value="none">No media</option>
          <option value="video">Video</option>
          <option value="images">Image Sequence</option>
        </select>

        {selection === "none" && <NoMediaPanel canvasRect={canvasRect} onChangeCallback={onChangeCallback} />}
        {selection === "video" && <VideoUpload canvasRect={canvasRect} onChangeCallback={onChangeCallback} />}
        {/* selection === "audio" && <AudioUpload canvasRect={canvasRect} onChangeCallback={onChangeCallback} /> */}
        {selection === "images" && <ImageSequenceUpload canvasRect={canvasRect} onChangeCallback={onChangeCallback} />}
      </div>
      <div style={{ height: "30px", width: "100%", backgroundColor: "#505050" }}></div>
    </div>
  );
}

function NoMediaPanel({ canvasRect, onChangeCallback }) {
  const { system_operations } = useContext(SystemOperationsContext);

  const handleSubmit = () => {
    system_operations.set_loading(true);
    RequestDataMaker.dataToDeletePaperExtension(system_operations, canvasRect.getID()).then((requestJson) => {
      system_operations.restServerConnector.requestToUpdatePaperExtension(requestJson, []).then((result) => {
        if (onChangeCallback) { onChangeCallback(false); }
        system_operations.set_loading(false);
      })
    })
  };

  return (
    <div>
      <button onClick={handleSubmit}>Submit</button>
    </div>
  )
}

function ImageSequenceUpload({ canvasRect, onChangeCallback }) {
  const { system_operations } = useContext(SystemOperationsContext);
  const [data, setData] = useState([]);
  const [urlInput, setUrlInput] = useState("");
  const [links, setLinks] = useState([]);
  const [autoPlay, setAutoPlay] = useState(false);
  const [autoPlayInterval, setAutoPlayInterval] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const MAX_SIZE = 10000000; // 10MB
  const MAX_IMAGES = 10;
  const fileInputRef = React.useRef(null);

  const totalSize = data.reduce((sum, item) => item.file ? sum + item.file.size : sum, 0);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files).map(file => ({ url: false, file }));
    const newTotalSize = totalSize + newFiles.reduce((sum, item) => sum + item.file.size, 0);

    if (newTotalSize <= MAX_SIZE && data.length + newFiles.length <= MAX_IMAGES) {
      setData(prevData => [...prevData, ...newFiles]);
      setLinks(prevLinks => [...prevLinks, ""]);
    } else {
      if (newTotalSize > MAX_SIZE) {
        alert("Total file size should be less than 10MB!");
      }
      if (data.length + newFiles.length > MAX_IMAGES) {
        alert("Image count should be less than 10!");
      }
    }

    // Clear the file input for future uploads
    fileInputRef.current.value = "";
  };

  const handleUrlAdd = () => {
    if (urlInput && data.length < MAX_IMAGES) {
      setData(prevData => [...prevData, { url: urlInput, file: false }]);
      setLinks(prevLinks => [...prevLinks, ""]);
      setUrlInput("");
    } else {
      if (data.length >= MAX_IMAGES) {
        alert("Image count should be less than 10!");
      }
    }
  };

  const handleImageSelect = (index) => {
    setSelectedImageIndex(index);
  };

  const handleLinkChange = (e) => {
    const updatedLinks = [...links];
    updatedLinks[selectedImageIndex] = e.target.value;
    setLinks(updatedLinks);
  };

  const removeImage = (index) => {
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);

    // Remove the link at the same index
    const updatedLinks = [...links];
    updatedLinks.splice(index, 1);
    setLinks(updatedLinks);

    // Reset the selected index if the currently selected image is removed
    if (index === selectedImageIndex) {
      setSelectedImageIndex(null);
    }
  };

  const handleSubmit = async () => {
    system_operations.set_loading(true);
    const files = [];
    // Add the files to the formData
    data.forEach((item, index) => {
      if (item.file) {
        files.push(item.file);
      }
      // maybe data url as well...
    });
    // Add the rest of the data as a JSON string
    const dataArray = data.map(item => ({ url: item.url })); // a bunch of {url: validData} and {url: false}
    RequestDataMaker.dataToUpdatePaperExtension_media(system_operations, canvasRect.getID(), dataArray, "images").then((requestJson) => {
      system_operations.restServerConnector.requestToUpdatePaperExtension(requestJson, files).then((result) => {
        if (onChangeCallback) { onChangeCallback(true); }
        system_operations.set_loading(false);
      })
    })
  };

  return (
    <div>
      <div>
        <label>
          AutoPlay
          <input
            type="checkbox"
            checked={autoPlay}
            onChange={(e) => setAutoPlay(e.target.checked)}
          />
        </label>
        <input
          type="number"
          placeholder="Interval in seconds"
          value={autoPlayInterval}
          onChange={(e) => setAutoPlayInterval(e.target.value)}
          disabled={!autoPlay}
        />
      </div>
      <div>
        <input ref={fileInputRef} type="file" multiple onChange={handleFileChange} />
        <input
          type="text"
          placeholder="Or Enter a URL"
          value={urlInput}
          onChange={(e) => setUrlInput(e.target.value)}
        />
        <button onClick={handleUrlAdd}>Add</button>
      </div>

      <div style={{ overflowX: 'scroll', display: 'flex', gap: '10px', marginTop: '10px', padding: '5px', borderTop: '1px solid gray' }}>
        {data.map((item, index) => (
          <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
            <img
              src={item.url || URL.createObjectURL(item.file)}
              alt="Uploaded Content"
              style={{
                maxHeight: '50px',
                maxWidth: '50px',
                border: index === selectedImageIndex ? '2px solid lightgrey' : 'none', // Apply border if selected
                padding: index === selectedImageIndex ? '2px' : '4px', // Adjust padding to keep the image size consistent
                boxSizing: 'border-box',
                cursor: 'pointer' // Indicate the image is selectable
              }}
              onClick={() => handleImageSelect(index)}
            />
            <button style={{ background: 'none', border: 'none', position: 'absolute', top: 0, right: 0, color: 'red', cursor: 'pointer' }} onClick={() => removeImage(index)}>
              <FaTimes />
            </button>
          </div>
        ))}
      </div>

      <label>
        Insert A Link: 
        <input
          type="text"
          placeholder="Enter a link"
          value={selectedImageIndex !== null ? links[selectedImageIndex] || "" : ""}
          onChange={handleLinkChange}
          disabled={selectedImageIndex === null}
        />
      </label>

      <div>
        Total size uploaded: <span style={{ color: totalSize > MAX_SIZE ? 'red' : 'black' }}>{(totalSize / 1000000).toFixed(2)}MB</span> / 10MB
      </div>
      <div>
        Total images: <span style={{ color: data.length >= MAX_IMAGES ? 'red' : 'black' }}>{data.length}</span> / 10
      </div>

      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
}

function AudioUpload({ canvasRect, onChangeCallback }) {
  const { system_operations } = useContext(SystemOperationsContext);
  const [url, setUrl] = useState("");
  const [file, setFile] = useState(null);
  const urlInputRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setUrl("");
      urlInputRef.current.value = "";
      setFile(uploadedFile);
    }
  };

  const handleUrlUpdate = (e) => {
    const newUrl = e.target.value;
    setFile(null);
    fileInputRef.current.value = "";
    setUrl(newUrl);
  }

  const handleSubmit = () => {
    if (!url && !file) {
      alert("Please upload an audio file or provide a URL!");
      return;
    } else {
      system_operations.set_loading(true);
      const files = [];
      const dataArray = [];
      if (file) {
        files.push(file);
        dataArray.push({ url: false });
      } else {
        dataArray.push({ url: url });
      }
      RequestDataMaker.dataToUpdatePaperExtension_media(system_operations, canvasRect.getID(), dataArray, "audio").then((requestJson) => {
        system_operations.restServerConnector.requestToUpdatePaperExtension(requestJson, files).then((result) => {
          if (onChangeCallback) { onChangeCallback(true); }
          system_operations.set_loading(false);
        })
      })
    }
  };

  return (
    <div>
      <input ref={fileInputRef} type="file" onChange={handleFileChange} />
      <input ref={urlInputRef}
        type="text"
        placeholder="Or Enter a URL"
        value={url}
        onChange={handleUrlUpdate}
      />
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
}

function VideoUpload({ canvasRect, onChangeCallback }) {
  const { system_operations } = useContext(SystemOperationsContext);
  const [url, setUrl] = useState("");

  const validateURL = (url) => {
    const regex = /^https:\/\/www\.youtube\.com\/.+/;
    return regex.test(url);
  };

  const handleSubmit = () => {
    //if (!validateURL(url)) {
    if (!url) {
      alert("Please enter a valid YouTube URL!");
      return;
    }

    system_operations.set_loading(true);
    RequestDataMaker.dataToUpdatePaperExtension_media(system_operations, canvasRect.getID(), [{ url: url }], "video").then((requestJson) => {
      system_operations.restServerConnector.requestToUpdatePaperExtension(requestJson, []).then((result) => {
        if (onChangeCallback) { onChangeCallback(true); }
        system_operations.set_loading(false);
      })
    })
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Enter YouTube URL"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
}


