export class ByteFlagsCoder {
  constructor(names) {
    this.initialState = 0;
    this.nameDict = {};
    
    // Initialize the name to position mapping
    names.forEach((name, index) => {
      this.nameDict[name] = index;
    });
  }

  // Set a flag by position
  setFlagByPosition(flags, position) {
    flags |= (1 << position);
    return flags;
  }

  // Set a flag by name
  setFlagByName(flags, name) {
    return this.setFlagByPosition(flags, this.nameDict[name]); // Added return here
  }

  // Check if a flag is set by position
  isFlagSetByPosition(flags, position) {
    return (flags & (1 << position)) !== 0;
  }

  // Check if a flag is set by name
  isFlagSetByName(flags, name) {
    return this.isFlagSetByPosition(flags, this.nameDict[name]);
  }

  // Clear a flag by position
  clearFlagByPosition(flags, position) {
    flags &= ~(1 << position);
    return flags;
  }

  // Clear a flag by name
  clearFlagByName(flags, name) {
    return this.clearFlagByPosition(flags, this.nameDict[name]); // Added return here
  }
}