import React, { useState, useRef, useContext, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { RatioSizer } from "../utility/Sizer.js";
import ButtonGroups from "./ButtonGroups.jsx";
import MediaPlayer from './MediaPlayer';
import { UserShortCardToolTip } from "../components/UserDisplay";

import { useTheme } from '@mui/system';
import { DialogTitle } from '@mui/material';


const StickerViewer = ({ inWall = false }) => {
  const { system_operations } = useContext(SystemOperationsContext);

  const [postType, setPostType] = useState(null);
  const [perfectWindowSize, setPerfectWindowSize] = useState([0, 0]);
  const [mediaDisplaySize, setMediaDisplaySize] = useState([0, 0]);
  const insideTopDownMargin = 70, outsideTopDownMargin = 150;
  const [contentSize, setContentSize] = useState([512, 512]);
  const [image, setImage] = useState(null);
  const [postOwener, setPostOwener] = useState({ username: system_operations.postSystem.currentViewingPost.userID, avatar: "none" });

  const contentUrl = useRef("");

  const currentPost = useRef(null);
  const currentPreviewPost = useRef(null);
  const canRemove = useRef(false);
  const containerRef = useRef(null);
  const containerSizeCalculator = useRef(new RatioSizer());

  const theme = useTheme();

  const buttons = {
    close: {
      name: 'Close',
      color: "white",
      backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
      checkDisable: () => { return false },
      onClick: () => { system_operations.setContentWindow(null) },
    },
    removeOrDelete: {
      name: inWall ? "Remove" : "Delete",
      color: "white",
      backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
      checkDisable: () => { return (!canRemove.current) },
      onClick: inWall ? () => { removePost() } : () => { deletePost() },
    }
  }

  useEffect(() => {
    containerSizeCalculator.current.setWidthRatio(0.8, 800, 0.9);
    containerSizeCalculator.current.setHeightRatio(1, 0, 0);
    containerSizeCalculator.current.setWidthRange(300, 1000);
    containerSizeCalculator.current.setHeightRange(300, 1440);
  }, [image]);

  useEffect(() => {
    // really just temp solution.
    const loadImage = async () => {
      if (system_operations.postSystem.currentViewingPost) {
        currentPreviewPost.current = system_operations.postSystem.currentViewingPost
        system_operations.usersData.getUserDataByID(currentPreviewPost.current.creatorID).then((userData) => {
          setPostOwener(userData);
        })
        const fullPostOnUser = await system_operations.postSystem.getAPost(currentPreviewPost.current.creatorID, currentPreviewPost.current.postID);
        const userRole = system_operations.coordinator.getUserRoleInWall();
        if (fullPostOnUser.cretorID !== system_operations.getUserID() && fullPostOnUser.protectLevel >= userRole.power) {
          canRemove.current = false;
        } else {
          canRemove.current = true;
        }
        currentPost.current = fullPostOnUser;
        if (!fullPostOnUser.type || fullPostOnUser.type === "image") {
          setPostType("image")
          setContentSize([fullPostOnUser.imageWidth, fullPostOnUser.imageHeight]);
          setImage(fullPostOnUser.imageUrl);
        } else if (fullPostOnUser.type === "audio") {
          contentUrl.current = fullPostOnUser.audioUrl;
          setPostType("audio")
          setContentSize([0, 0]);
          setImage(fullPostOnUser.imageUrl);
        } else if (fullPostOnUser.type === "video") {
          contentUrl.current = fullPostOnUser.videoUrl;
          setPostType("video")
          setContentSize([0, 0]);
          setImage(fullPostOnUser.imageUrl);
        }
      }
    }
    loadImage();
  }, [])


  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      system_operations.floatPageController.setFloatPage(0);
    }
  };


  useEffect(() => {
    const calculateWindowSize = () => {
      const W = window.innerWidth;
      const H = window.innerHeight;
      const size = containerSizeCalculator.current.findSize(W, (H - outsideTopDownMargin));
      setPerfectWindowSize(size);
    };
    calculateWindowSize();
    window.addEventListener('resize', calculateWindowSize);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      window.removeEventListener('resize', calculateWindowSize);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const calcualateAndSetMediaDisplaySize = (imageW, imageH) => {
    const newImageActualDisplaySize = system_operations.imageProcessor.fitImageSize(imageW, imageH, perfectWindowSize[0], perfectWindowSize[1] - insideTopDownMargin, 0, false);
    setMediaDisplaySize(newImageActualDisplaySize);
  }

  useEffect(() => {
    calcualateAndSetMediaDisplaySize(contentSize[0], contentSize[1]);
  }, [perfectWindowSize, contentSize]);

  const removePost = async () => {
    system_operations.set_loading(true);
    await system_operations.postSystem.removeAPost(system_operations.wallData.wallID, currentPost.current.postID, system_operations.coordinator.getUserRoleInWall())
    system_operations.coordinator.sectionData.deleteItemFromPostLoader(currentPreviewPost.current);
    system_operations.setContentWindow(null);
    system_operations.coordinator.refreshDrawingCanvas();
    system_operations.set_loading(false);
  }

  const deletePost = async () => {
    system_operations.set_loading(true);
    await system_operations.postSystem.deleteAPost(system_operations.wallData.wallID, currentPost.current, system_operations.coordinator.getUserRoleInWall())
    system_operations.setContentWindow(null);
    system_operations.coordinator.refreshDrawingCanvas();
    system_operations.set_loading(false);
  }

  let viewPost;
  switch (postType) {
    case 'image':
      viewPost = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={image} style={{ "width": `${mediaDisplaySize[0]}px`, "height": `${mediaDisplaySize[1]}px` }} />
        </div>
      );
      break;
    case 'audio':
      viewPost = (
        <div style={{ width: perfectWindowSize[0] }}>
          <MediaPlayer mediaType="audio" mediaUrl={contentUrl.current} />
        </div>
      );
      break;
    case 'video':
      viewPost = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <MediaPlayer mediaType="video" mediaUrl={contentUrl.current} maxWidth={perfectWindowSize[0]} maxHeight={perfectWindowSize[1]} />
        </div>
      );
      break;
    default:
      viewPost = <div style={{ width: perfectWindowSize[0], height: perfectWindowSize[1] }} />;
      break;
  }

  return (
    <div style={{ userSelect: 'none' }}>
      <div ref={containerRef} className='float-container'>
        <DialogTitle id="fence-content-title" sx={{ color: "white", fontSize: '0.7rem', padding: 0, marginTop: 0 }}>
          Created By:&nbsp;
          <UserShortCardToolTip user={postOwener}>
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '0.7rem',
                color: "rgba(64, 64, 225, 1)"
              }}
            >
              {postOwener.username}
            </span>
          </UserShortCardToolTip>
        </DialogTitle>
        {viewPost}
        <ButtonGroups buttons={buttons} />
      </div>
    </div>
  );
};

export default StickerViewer;