import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Default_Avatar from "../img/Default_Avatar.png";
import { SystemOperationsContext } from "../context/SystemRunnerContext";

function Register() {
  const { system_operations } = useContext(SystemOperationsContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (system_operations.loggedIn) {
      navigate("/")
    }
  }, [])

  const handleImageUpload = () => {
    // Implement your submit logic here
  };

  const handleSubmit = async (event) => {
    system_operations.set_loading(true);

    event.preventDefault();
    const displayName = event.target[0].value;
    const email = event.target[1].value;
    const password = event.target[2].value;
    // let file = event.target[3].files[0];

    /*if (!file) {
      file = Default_Avatar
    }*/

    const userCredential = await system_operations.authSystem.signUp(system_operations, email, password, displayName, Default_Avatar);

    if (userCredential && typeof userCredential !== "string") {
      await system_operations.set_user(userCredential.user);
      // await system_operations.enterAWall(userCredential.user.uid); // temp here
      navigate("/");
    } else {
      // console.log("this is wrong", userCredential);
    }
    system_operations.set_loading(false);
  };

  return (
    <div>
      <div className="background-image"></div>
      <div className="form-container form-container--log">
        <div className="largest-text">
          <h2>Register</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <input type="text" placeholder="Username" required />
          <input type="email" placeholder="Email" required />
          <input type="password" placeholder="Password" required />
          <div className="image-upload" onClick={handleImageUpload}>
            {/* Replace with a suitable icon or style as needed */}
            <span>+</span>
          </div>
          <button type="submit">Submit</button>
        </form>
        <div className='small-text'>
          <p>Already have an account? <Link to="/login">Login</Link></p>
        </div>
      </div>
    </div>
  );
}

export default Register;