import React, { useState, useContext, useRef, useEffect, useMemo } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { RequestDataMaker } from "../system/RestServerConnector.js"

const FenceDefinition = ({ fences }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const fenceTool = system_operations.coordinator.toolpack.getToolByName("fence");

  // fenceStart: the edge where fence left / top edge indexed
  // fenceLength: how long the fence is. fenceStart + fenceLength = end index of the fence.
  // as fenceStart indicate edge, then two fence with same edge index doesn't count as intersect.
  const fenceGlobalStartIndex = useRef(0);
  const fenceIndexLength = useRef(1);
  const boardData = system_operations.coordinator.getWallData();

  const isDraggingLeft = useRef(false);
  const isDraggingRight = useRef(false);
  const previewing = useRef(false);
  const immediateEditingFlagRef = useRef(false);

  const boundMin = useRef(0);
  const boundMax = useRef(boardData.isHorizontal ? boardData.totalColumns : boardData.totalRows);
  const prevFence = useRef(null);
  const nextFence = useRef(null);

  const toSrc = system_operations.coordinator.boardPositioner.scaleToScr.bind(system_operations.coordinator.boardPositioner);
  const gridSize = system_operations.coordinator.getGridParallelEdge(); // the edge that stack up to the length of the board
  const [fenceStart, setFenceStart] = useState(fenceGlobalStartIndex.current * gridSize);
  const [fenceLength, setFenceLength] = useState(fenceIndexLength.current * gridSize);
  const [editing, setEditing] = useState(false);

  const containerRef = useRef(null);
  const leftBoarderRef = useRef(null);
  const rightBoarderRef = useRef(null);

  const getFenceGlobalStartIndex = (fence) => {
    return (fence.sectionIndex * boardData.sectionSize) + fence.start
  }

  const getFenceGlobalEndIndex = (fence) => {
    return (fence.sectionIndex * boardData.sectionSize) + fence.start + fence.length
  }

  const checkIfHasFencesOverlay = () => {
    for (const fence of fences) {
      // edge index can overlay.
      if (getFenceGlobalStartIndex(fence) <= fenceGlobalStartIndex.current && getFenceGlobalEndIndex(fence) >= fenceGlobalStartIndex.current + fenceIndexLength.current) {
        return fence;
      }
    }
    return false;
  }

  const clamp = (value, min, max) => {
    return Math.max(Math.min(value, max), min);
  }

  const getActiveOnBoardCoord = (mouseX, mouseY) => {
    const eventOnBoardCoord = system_operations.coordinator.boardPositioner.getTheoPositionFromScreenPosition(mouseX, mouseY, true);
    const activeOnBoardCoord = boardData.isHorizontal ? eventOnBoardCoord[0] : eventOnBoardCoord[1];
    return activeOnBoardCoord;
  }

  useEffect(() => {
    const handleScreenSizeChange = () => {
      setFenceStart(fenceGlobalStartIndex.current * gridSize);
      setFenceLength(fenceIndexLength.current * gridSize);
    }

    const submitBoarder = async () => {
      system_operations.set_loading(true);
      const verify = !checkIfHasFencesOverlay();
      if (verify) {
        const updateData = await RequestDataMaker.getDataToCreateAFence(system_operations, fenceGlobalStartIndex.current, fenceIndexLength.current);
        const updateResult = await system_operations.restServerConnector.createNewFence(updateData);
        if (updateResult && updateResult.success && updateResult.fence) {
          system_operations.socket_connector.emit_to_socket("updateFence", {
            operation: "upload", userData: system_operations.getUserData(), newFence: updateResult.fence
          })
          system_operations.coordinator.fenceLoader.insertNewItemToFenceLoader(updateResult.fence)
          fenceTool.fenceSetEditing(false);
          previewing.current = false;
          system_operations.coordinator.toolpack.changeToPreviousCommonTool();
        }
      }
      system_operations.set_loading(false);
    }

    fenceTool.fenceSetEditingHook = setEditing;
    fenceTool.submitFenceHook = submitBoarder;

    window.addEventListener('resize', handleScreenSizeChange);
    return () => {
      window.removeEventListener('resize', handleScreenSizeChange);
    }
  }, [])


  const placeFenceForEditing = () => {
    if (!editing && !checkIfHasFencesOverlay()) {
      // no overlapping and is inside the area, so can place
      for (const fence of fences) {
        if (getFenceGlobalEndIndex(fence) <= fenceGlobalStartIndex.current && getFenceGlobalEndIndex(fence) >= boundMin.current) {
          boundMin.current = getFenceGlobalEndIndex(fence);
          prevFence.current = fence;
        } else if (getFenceGlobalStartIndex(fence) >= fenceGlobalStartIndex.current + fenceIndexLength.current && getFenceGlobalStartIndex(fence) <= boundMax.current) {
          boundMax.current = getFenceGlobalStartIndex(fence);
          // boundMax.current = Math.min(getFenceGlobalStartIndex(fence), fenceGlobalStartIndex.current + 25);
          nextFence.current = fence;
        }
      }
      return true;
    }
    return false;
  }

  useEffect(() => {
    const preEditingPreviewBoarders = (mouseX, mouseY) => {
      const activeOnBoardCoord = getActiveOnBoardCoord(mouseX, mouseY);
      fenceGlobalStartIndex.current = Math.floor(activeOnBoardCoord / gridSize); // start index is always on the left side of the event

      setFenceStart(fenceGlobalStartIndex.current * gridSize);
      setFenceLength(fenceIndexLength.current * gridSize);
    }

    const dragFenceEdge = (mouseX, mouseY, isRightBoarder = true) => {
      const activeOnBoardCoord = getActiveOnBoardCoord(mouseX, mouseY);
      let dragToIndex = Math.round(activeOnBoardCoord / gridSize);

      if (isRightBoarder) {
        const maxDragTo = fenceGlobalStartIndex.current + boardData.sectionSize;
        dragToIndex = clamp(dragToIndex, fenceGlobalStartIndex.current + 1, Math.min(boundMax.current, maxDragTo));
        fenceIndexLength.current = dragToIndex - fenceGlobalStartIndex.current;
        setFenceLength(fenceIndexLength.current * gridSize);
      } else {
        const minDragTo = fenceGlobalStartIndex.current + fenceIndexLength.current - boardData.sectionSize;
        const currentEndBoarderIndex = fenceGlobalStartIndex.current + fenceIndexLength.current;
        dragToIndex = clamp(dragToIndex, Math.max(minDragTo, boundMin.current), currentEndBoarderIndex - 1);
        const lengthChange = fenceGlobalStartIndex.current - dragToIndex;
        fenceIndexLength.current += lengthChange;
        fenceGlobalStartIndex.current = dragToIndex;
        setFenceStart(fenceGlobalStartIndex.current * gridSize);
        setFenceLength(fenceIndexLength.current * gridSize);
      }
    }

    const onTouchMove = (e) => {
      eventMove(e.touches[0].clientX, e.touches[0].clientY);
    }

    const onMouseMove = (e) => {
      eventMove(e.clientX, e.clientY);
    }

    const eventMove = (eventX, eventY) => {
      if (!editing && !immediateEditingFlagRef.current) {
        preEditingPreviewBoarders(eventX, eventY); // as not editing, we just need to preview the fence position.
      } else {
        if (isDraggingLeft.current) {
          dragFenceEdge(eventX, eventY, false);
        } else if (isDraggingRight.current) {
          dragFenceEdge(eventX, eventY, true);
        }
        // console.log(toSrc(fenceGlobalStartIndex.current * gridSize), toSrc(fenceIndexLength.current * gridSize));
      }
    }

    const boarderDragStart = (isRightBoarder) => {
      if (system_operations.coordinator.toolpack.current_tool.name === "fence" && editing) {
        isDraggingLeft.current = !isRightBoarder;
        isDraggingRight.current = isRightBoarder;
      }
    }

    const leftEventStart = (e) => {
      e.preventDefault();
      boarderDragStart(false);
    }
    const rightEventStart = (e) => {
      e.preventDefault();
      boarderDragStart(true);
    }

    leftBoarderRef.current.addEventListener('mousedown', leftEventStart, { passive: false });
    leftBoarderRef.current.addEventListener('touchstart', leftEventStart, { passive: false });
    rightBoarderRef.current.addEventListener('mousedown', rightEventStart, { passive: false });
    rightBoarderRef.current.addEventListener('touchstart', rightEventStart, { passive: false });

    window.addEventListener('touchstart', onTouchMove);

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('touchup', onMouseUp);

    return () => {
      if (leftBoarderRef.current) {
        leftBoarderRef.current.removeEventListener('mousedown', leftEventStart, { passive: false });
        leftBoarderRef.current.removeEventListener('touchstart', leftEventStart, { passive: false });
      }
      if (rightBoarderRef.current) {
        rightBoarderRef.current.removeEventListener('mousedown', rightEventStart, { passive: false });
        rightBoarderRef.current.removeEventListener('touchstart', rightEventStart, { passive: false });
      }

      window.removeEventListener('touchstart', onTouchMove);

      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('touchup', onMouseUp);
    }
  }, [editing, isDraggingLeft, isDraggingRight]); // Empty array ensures that effect is only run on mount and unmount

  const lineThickness = 3;

  const getFieldNameForLength = () => { return boardData.isHorizontal ? "width" : "height" }
  const getFieldNameForThickness = () => { return boardData.isHorizontal ? "height" : "width" }
  const getFieldNameForCoordinate = () => { return boardData.isHorizontal ? "left" : "top" }

  const onMouseUp = () => {
    if (editing) {
      previewing.current = false;
      isDraggingLeft.current = false;
      isDraggingRight.current = false;
    }
  }

  const onTouchEnd = () => {
    onMouseUp();
    onClick();
  }

  const onClick = () => {
    if (placeFenceForEditing()) {
      fenceTool.fenceSetEditing(true);

      immediateEditingFlagRef.current = true;
      setFenceStart(fenceGlobalStartIndex.current * gridSize);
      setFenceLength(fenceIndexLength.current * gridSize);
    }
  }

  return (
    <div className="unselectable" ref={containerRef} onClick={onClick} onTouchEnd={onTouchEnd} style={{
      position: "absolute", [getFieldNameForCoordinate()]: `${fenceStart}px`, [getFieldNameForLength()]: `${fenceLength}px`, [getFieldNameForThickness()]: "100%", zIndex: "100"
    }}>
      <div style={{ position: 'absolute', [getFieldNameForLength()]: "100%", [getFieldNameForThickness()]: "100%", backgroundColor: "green", opacity: '0.5' }} />
      <div ref={leftBoarderRef} style={{
        position: 'absolute', [getFieldNameForCoordinate()]: `${-8}px`, [getFieldNameForLength()]: '16px', [getFieldNameForThickness()]: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: system_operations.coordinator.isHorizontal? 'ew-resize':'ns-resize', zIndex: "105"
      }}>
        <div style={{ [getFieldNameForLength()]: lineThickness, [getFieldNameForThickness()]: '100%', backgroundColor: 'green', opacity: '0.7' }} />
      </div>
      <div ref={rightBoarderRef} style={{
        position: 'absolute', [getFieldNameForCoordinate()]: `${fenceLength - 8}px`, [getFieldNameForLength()]: '16px', [getFieldNameForThickness()]: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: system_operations.coordinator.isHorizontal? 'ew-resize':'ns-resize', zIndex: "105"
      }}>
        <div style={{ [getFieldNameForLength()]: lineThickness, [getFieldNameForThickness()]: '100%', backgroundColor: 'green', opacity: '0.7' }} />
      </div>
    </div>
  )
}

export default FenceDefinition

/*
above is a component for a boarder defining process.
write code for:
if mouse position is inside of the containerRef, the previewing should be set to true. and the fenceMin and fenceMax should be equal to mouse position +- fenceMinWidth/2
if mouse position is outside of the containerRef, then check editing. if editing is true, then previewing is still true. otherwise, turn previewing to false.

when previewing(mouse is inside of the container) is true and editing is false. when user clicks, call a function called "checkFenceBoarder(fenceMin, fenceMax)"
"checkFenceBoarder" should verify if the current fence is overlapping with other fences. (the param fences is a array of fences, each fences is in form of: {creatorID, start, end, createdDate, protectLevel}, start and end is the boarder of it)
if "checkFenceBoarder" returns true, means there is no overlapping, editing is set to "true", also lock the previewing to true as well.

when previewing is true, in the return, render two green verticle dash lines that one at the fenceMin, one at the fenceMax. add a green transparent box to fill between the two dash lines.
The two dash lines should be dragable. when user drag one. change the value fenceMin or fenceMax depending on which line user is dragging. two line cannot come closer than fenceMinWidth to each other.
so define a helper function fix the value of fenceMin and fenceMax. It should be called "VerifyFenceBoarder(value, verifyMin = true)". when verifyMin is true, check if fenceMin surpass the max or not. if it's false, then check if fenceMax get below fenceMin or not. it should return a value after fixing. make sure min and max is at least "fenceMinWidth" away from eachother

at the bottom point of the fenceMax dash line, there should be a button, called "Set". when click, the previewing should be set to false so do the editing. but call a function "submit()" I will fill up the submit by myself. You just create a placeholder.
*/