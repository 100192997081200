import { getFirestore, doc, addDoc, setDoc, getDoc, collection, runTransaction, query, getDocs, deleteDoc, updateDoc, where, limit, orderBy, startAfter } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import app from "../firebase.js";

import { Base64Utility } from "../utility/Base64Utility.js"

export class FirestoreActions {
  static firestore = getFirestore(app);

  constructor() { }

  fetchFromFirestore(path, idName, queries = [], fileters = null) {
    return FirestoreActions.static_fetchFromFirestore(path, idName, queries, fileters);
  }

  /**
  * static_fetchFromFirestore - Asynchronously fetch data from Firestore based on the provided path, with query filtering support.
  *
  * @param {string} path - Path to the Firestore document or collection.
  * @param {string|null} [idName=null] - The identifier name to be used in the returned data dictionary. If null, no id will be included.
  * @param {Array<Object>} [queries=[]] - An array of query objects. Each query object should contain a 'field', and a 'query' array that includes 'sign' and 'value' attributes. The 'sign' should be a valid Firestore comparison operator, like "==", "<", ">", "<=", ">=", "array-contains", etc.
  * 
  * For example, a valid queries input could look like this:
  * Note that, only the amount in the first query filed will count.
  * queries = [
  *  {field: 'age', query: [{sign: '>=', value: 21}], (*optional*)amount: x },
  *  {field: 'name', query: [{sign: '==', value: 'John Doe'}]}
  * ]
  *
  * Filters is used to control the result selection. For example amount of results are returned. Start from Nth of the result...
  * filters = {
  *  amount: n,
  *  startFrom: m,
  *  orderByField,
  *  orderDirection
  * }
  * @returns {Promise<Array|Object|null>} Returns a promise that resolves to an array of data dictionaries if the path is a collection, or a single data dictionary if the path is a document. Returns null if an error occurred while fetching a document.
  */
  static async static_fetchFromFirestore(path, idName = null, queries = [], filters = null) {
    const pathSegments = path.split('/');
    // If the number of segments in the path is even, it's a document path.
    if (pathSegments.length % 2 === 0) {
      const docRef = doc(FirestoreActions.firestore, ...pathSegments);
      try {
        const docSnapshot = await getDoc(docRef);
        return FirestoreActions.static_constructDataDict(idName, docSnapshot.id, docSnapshot.data());
      } catch (error) {
        // console.error(error);
        return null;
      }
    } else {
      // If the number of segments in the path is odd, it's a collection path.
      const parentDocRef = doc(FirestoreActions.firestore, ...pathSegments.slice(0, -1));
      let collectionRef = collection(parentDocRef, pathSegments[pathSegments.length - 1]);

      // Loop through the query array and add a where clause for each condition in each field.
      for (let fieldQuery of queries) {
        for (let condition of fieldQuery.query) {
          collectionRef = query(collectionRef, where(fieldQuery.field, condition.sign, condition.value));
        }
      }
      if (filters) {
        if (filters.orderByField) {
          collectionRef = query(collectionRef, orderBy(filters.orderByField, filters.orderDirection || "desc"));
          if (filters.startFrom) {
            // Here, you can directly use collectionRef since orderBy is already added.
            collectionRef = query(collectionRef, startAfter(filters.startFrom));
          }
        } else if (filters.startFrom) {
          // In case orderByField is not provided in filters but startFrom is there, ordering by __name__.
          collectionRef = query(collectionRef, orderBy('__name__'), startAfter(filters.startFrom));
        }
        if (filters.amount) {
          collectionRef = query(collectionRef, limit(filters.amount));
        }
      }
      try {
        const querySnapshot = await getDocs(collectionRef);
        const collectionData = querySnapshot.docs.map((doc) => {
          return FirestoreActions.static_constructDataDict(idName, doc.id, doc.data());
        });
        return collectionData;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
  }

  static static_constructDataDict(idName, id, data) {
    if (data === undefined) {
      return null;
    }
    if (!idName) {
      idName = "id";
    }
    const result = {
      [idName]: id,
      ...data
    }
    return result
  }

  async createDocumentAt(path, data, idName = "", id = "") {
    return FirestoreActions.static_createDocumentAt(path, data, idName, id);
  }

  static async static_createDocumentAt(path, data, idName = "", id = "") {
    const pathSegments = path.split('/');

    // Check if the path is a collection path (number of segments is even).
    if (pathSegments.length % 2 === 1) {
      const collectionRef = collection(FirestoreActions.firestore, path);
      try {
        if (id === "") {
          // If id is an empty string, let Firestore generate a random ID.
          const docRef = await addDoc(collectionRef, data);
          data[idName ? idName : ""] = docRef.id;
          return data;
        } else {
          // If id is not an empty string, use the provided ID.
          const docRef = doc(FirestoreActions.firestore, path, id);
          await setDoc(docRef, data);
          data[idName ? idName : ""] = id;
          return data;
        }
      } catch (error) {
        console.error("Error creating document:", error);
        return null;
      }
    } else {
      console.error("Invalid path for creating a document. Path should point to a collection.");
      return null;
    }
  }

  async updateDocumentAt(path, data, idName = "") {
    return FirestoreActions.static_updateDocumentAt(path, data, idName);
  }

  static async static_updateDocumentAt(path, data, idName = "") {
    const pathSegments = path.split('/');
    // Check if the path is a document path (number of segments is even).
    if (pathSegments.length % 2 === 0) {
      const docRef = doc(FirestoreActions.firestore, path);
      try {
        await updateDoc(docRef, data);
        data[idName ? idName : "id"] = docRef.id;
        return data;
      } catch (error) {
        console.error("Error updating document:", error);
        return null;
      }
    } else {
      console.error("Invalid path for updating a document. Path should point to a document.");
      return null;
    }
  }

  async removeDocumentAt(pathToDocument) {
    return FirestoreActions.static_removeDocumentAt(pathToDocument);
  }

  static async static_removeDocumentAt(path) {
    const pathSegments = path.split('/');

    // Check if the path is a document path (number of segments is even).
    if (pathSegments.length % 2 === 0) {
      const docRef = doc(FirestoreActions.firestore, path);
      try {
        await deleteDoc(docRef);
        return { status: "Success", message: "Document successfully deleted" };
      } catch (error) {
        console.error("Error removing document:", error);
        return { status: "Failure", message: "Error removing document" };
      }
    } else {
      console.error("Invalid path for removing a document. Path should point to a document.");
      return { status: "Failure", message: "Invalid path for removing a document. Path should point to a document" };
    }
  }
}

export class StorageTalker {
  static storage = getStorage(app);

  static async readFromStorage(path) {
    try {
      // Create a reference to the file we want to download
      const storageRef = ref(this.storage, path);

      // Get the download URL
      const url = await getDownloadURL(storageRef);

      return url;
    } catch (error) {
      console.error("Error reading from storage: ", error);
      throw error;
    }
  }


  static async writeBase64ToStorage(path, base64, fileType, returnUrl = true) {
    const byteData = Base64Utility.base64DataToByteData(base64)

    try {
      // Create a reference to the file we want to upload
      const storageRef = ref(this.storage, path);

      // Upload the file
      const snapshot = await uploadBytes(storageRef, byteData, { contentType: fileType });

      if (returnUrl) {
        // Get the download URL
        const url = await getDownloadURL(storageRef);
        return url;
      }
    } catch (error) {
      console.error("Error writing to storage: ", error);
      throw error;
    }
  }
}

export class DataFetcher {
  static async getFromUrl(url) {

  }
}