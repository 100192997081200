export class CanvasManipulator {
  static create_transparent_image(width, height) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.clearRect(0, 0, width, height);
    return canvas.toDataURL();
  }

  static create_red_image(width, height) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.fillStyle = 'red'; // Set the fill color to red
    ctx.fillRect(0, 0, width, height); // Fill the entire canvas with the red color
    return canvas.toDataURL();
  }

  static clip_canvas_context(ctx, x_start, x_end, y_start, y_end) {
    const originalCanvas = ctx.canvas;
    const clippedWidth = x_end - x_start;
    const clippedHeight = y_end - y_start;

    const clippedCanvas = document.createElement('canvas');
    const clippedCtx = clippedCanvas.getContext('2d');
    clippedCanvas.width = clippedWidth;
    clippedCanvas.height = clippedHeight;

    // Draw the original canvas onto the new canvas with the specified start and end points
    clippedCtx.drawImage(originalCanvas, x_start, y_start, clippedWidth, clippedHeight, 0, 0, clippedWidth, clippedHeight);

    return clippedCtx;
  }

  constructor(coordinator) {
    this.system = coordinator.system;
    this.coordinator = coordinator;
    this.fenceLoader = this.coordinator.fenceLoader;
    this.columnUpdateData = {};
    this.isExecuting = {};
  }

  // xxxdCanvasOnToManyColumns(clipped_ctx, functionality, getGridAt, thisGridDoneCallback, column_width, column_height, left_index, right_index, left_offset, right_offset, allColumnsCallback = null) { }
  loadCanvasOnToManyColumns(clipped_ctx, globalEntity, getGridAt, thisGridDoneCallback, gridWidth, gridHeight, xIndexes, xOffsets, yIndexes, yOffsets, allColumnsCallback = null) {
    let index_row = 0;
    let taskCounter = 0;
    let allGridsStarted = false;

    const firstRowImageHeight = gridHeight - yOffsets[0];
    const lastRowImageHeight = yOffsets[1] === 0 ? gridHeight : yOffsets[1]; // last row' height can fully devide by the grid height, that means the whole thing is kept
    const firstColumnImageWidth = gridWidth - xOffsets[0];
    const lastColumnImageWidth = xOffsets[1] === 0 ? gridWidth : xOffsets[1];  // last column' width can fully devide by the grid width, that means the whole thing is kept

    for (let row = yIndexes[0]; row <= yIndexes[1]; row++) {
      const s_yOffset = Math.max((gridHeight * (index_row - 1)) + firstRowImageHeight, 0);
      let index_column = 0;
      for (let column = xIndexes[0]; column <= xIndexes[1]; column++) {
        taskCounter += 1

        const s_xOffset = Math.max((gridWidth * (index_column - 1)) + firstColumnImageWidth, 0); // be 0, or number of column - 1 times standard width + offset. -1 because the first column width might be smaller. 
        let d_xOffset = 0;
        let d_yOffset = 0;

        let s_width = gridWidth;
        let s_height = gridHeight;

        if (row === yIndexes[0]) {
          d_yOffset = yOffsets[0]
          s_height = firstRowImageHeight;
        }
        if (row === yIndexes[1]) {
          s_height = lastRowImageHeight;
        }

        if (column === xIndexes[0]) {
          d_xOffset = xOffsets[0]
          s_width = firstColumnImageWidth;
        }
        if (column === xIndexes[1]) {
          s_width = lastColumnImageWidth;
        }

        if (row === yIndexes[1] && column === xIndexes[1]) { allGridsStarted = true; }
        index_column += 1;
        // for absolute safty gurantee, you can add all the tasks in an array first and then after the for loop, push them in to the queue together. this way, the taskCounter is guranteed to be fully calculated.
        const doneColumnCallback = (dataUrl) => {
          if (dataUrl) {
            thisGridDoneCallback(row, column, dataUrl) // update the column
          }
          taskCounter -= 1;
          if (taskCounter <= 0 && allGridsStarted) {
            if (allColumnsCallback) {
              allColumnsCallback(); // if all is done, and there is a final callback, then call it.
            }
          }
        }
        this.queueOneColumn(row, column, clipped_ctx, globalEntity, getGridAt, doneColumnCallback, s_xOffset, s_yOffset, s_width, s_height, d_xOffset, d_yOffset, s_width, s_height, gridWidth, gridHeight);
      }
      index_row += 1;
    }
  }

  assignElementIfListIndexIsFalsy(list, row, column, element) {
    if (!list[row]) {
      list[row] = {};
    }
    if (!list[row][column]) {
      list[row][column] = element;
    }
  }

  queueOneColumn(row, column, clippedCtx, globalEntity, getGridAt, updateCallback, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight, bWidth, bHeight) {
    const updateData = {
      row, column,
      clippedCtx,
      globalEntity,
      getGridAt,
      updateCallback,
      sx, sy, sWidth, sHeight,
      dx, dy, dWidth, dHeight,
      bWidth, bHeight,
    }
    this.assignElementIfListIndexIsFalsy(this.columnUpdateData, row, column, []);
    this.assignElementIfListIndexIsFalsy(this.isExecuting, row, column, false);
    this.columnUpdateData[row][column].push(updateData);
    this.chainExecuting_noneAsyncProcess(row, column);
  }

  // this function will start executing the list
  async chainExecuting_noneAsyncProcess(row, column) {
    if (!this.columnUpdateData[row][column] || this.isExecuting[row][column]) { return; }
    this.isExecuting[row][column] = true;
    this.continueNoneAsyncChainCall(row, column);
  }

  continueNoneAsyncChainCall = (row, column) => {
    if (this.columnUpdateData[row][column].length > 0) {
      const nextUpdateData = this.columnUpdateData[row][column].shift(); // not executed, just definition of operation
      this.processOneColumn_asyncProcess(nextUpdateData, () => { this.continueNoneAsyncChainCall(row, column) });
    } else {
      // no more pending data in the queue, so just return now.
      this.isExecuting[row][column] = false;
    }
  }

  /* processOneColumn_noneAsyncProcess(data, continuingCall) {
    // finish time is not guranteed, and will not wait, so call the next one as a callback function
    data.getGridAt(data.row, data.column)
      .then(async (imageData) => {
        // this.load_canvas_on_to_image(ctx, image_url, callback, display_width, display_height, i_offset + left_offset, left_offset);
        const doneCallback = async (imageData) => {
          await data.updateCallback(imageData)
          if (continuingCall) { continuingCall(); } // check and call the next if there are more.
        }
        if (data.globalEntity === "subtraction") {
          CanvasManipulator.eraseCanvasFromImage(data.clippedCtx, imageData.dataUrl, doneCallback, data.sx, data.sy, data.sWidth, data.sHeight, data.dx, data.dy, data.dWidth, data.dHeight, data.bWidth, data.bHeight);
        } else {
          CanvasManipulator.loadCanvasOnToImage(data.clippedCtx, imageData.dataUrl, doneCallback, data.sx, data.sy, data.sWidth, data.sHeight, data.dx, data.dy, data.dWidth, data.dHeight, data.bWidth, data.bHeight);
        }
      });
  } */

  async processOneColumn_asyncProcess(data, continuingCall) {
    // finish time is not guranteed, and will not wait, so call the next one as a callback function
    if (data) {
      const [imageData, fence] = await Promise.all([data.getGridAt(data.row, data.column), this.fenceLoader.getFenceAt(data.row, data.column)]);
      // console.log(fence);
      if (!fence || fence.creatorID === this.system.getUserID() || this.system.fenceCoder.isFlagSetByName(fence.rules, "drawingOther")) {
        // this.load_canvas_on_to_image(ctx, image_url, callback, display_width, display_height, i_offset + left_offset, left_offset);
        const doneCallback = async (imageData) => {
          await data.updateCallback(imageData)
          if (continuingCall) { continuingCall(); } // check and call the next if there are more.
        }
        const dataUrl = await imageData.getDataUrl();
        if (data.globalEntity === "subtraction") {
          CanvasManipulator.eraseCanvasFromImage(data.clippedCtx, dataUrl, doneCallback, data.sx, data.sy, data.sWidth, data.sHeight, data.dx, data.dy, data.dWidth, data.dHeight, data.bWidth, data.bHeight);
        } else {
          CanvasManipulator.loadCanvasOnToImage(data.clippedCtx, dataUrl, doneCallback, data.sx, data.sy, data.sWidth, data.sHeight, data.dx, data.dy, data.dWidth, data.dHeight, data.bWidth, data.bHeight);
        }
      } else {
        console.log("disallowed to pass...")
        await data.updateCallback();
        if (continuingCall) { continuingCall(); }
      }
    }
  }

  static loadCanvasOnToImage(drawCtx, image_url, callback, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight, bWidth, bHeight) {
    const baseCanvas = document.createElement('canvas');
    const baseCtx = baseCanvas.getContext('2d');

    let base_image_url = image_url;
    if (!base_image_url) {
      base_image_url = CanvasManipulator.create_transparent_image(dWidth, dHeight);
    }
    const baseImage = new Image();
    baseImage.onload = () => {
      baseCanvas.width = bWidth;
      baseCanvas.height = bHeight;
      baseCtx.drawImage(baseImage, 0, 0);
      // console.log(baseCanvas.width, baseCanvas.height, baseImage.width, baseImage.height, base_image_url);

      baseCtx.drawImage(
        drawCtx.canvas,
        sx, sy, sWidth, sHeight,
        dx, dy, dWidth, dHeight
      );
      const combinedImageDataUrl = baseCanvas.toDataURL();
      // console.log(combinedImageDataUrl);
      callback(combinedImageDataUrl);
    };
    baseImage.src = base_image_url;
  }

  static eraseCanvasFromImage(eraseMarkCtx, image_url, callback, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight, bWidth, bHeight) {
    const baseCanvas = document.createElement('canvas');
    const baseCtx = baseCanvas.getContext('2d');

    let base_image_url = image_url;
    if (!base_image_url) {
      base_image_url = CanvasManipulator.create_transparent_image(dWidth, dHeight);
    }
    const baseImage = new Image();
    baseImage.onload = () => {
      baseCanvas.width = bWidth;
      baseCanvas.height = bHeight;
      baseCtx.drawImage(baseImage, 0, 0);

      baseCtx.globalCompositeOperation = 'destination-out';
      baseCtx.drawImage(
        eraseMarkCtx.canvas,
        sx, sy, sWidth, sHeight,
        dx, dy, dWidth, dHeight
      );
      const combinedImageDataUrl = baseCanvas.toDataURL();
      callback(combinedImageDataUrl);
    };
    baseImage.src = base_image_url;
  }

  static rgbaToHex(r, g, b, a) {
    const alpha = Math.round(a * 255);
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}${alpha.toString(16).padStart(2, '0')}`;
  }

  static async getColorFromBase64ImageAtCoordinate(base64Image, x, y) {
    const color = new Promise((resolve, reject) => {
      const image = new Image();
      image.src = base64Image;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;

        context.drawImage(image, 0, 0, image.width, image.height);
        const imageData = context.getImageData(x, y, 1, 1).data;
        // const colorString = `rgba(${imageData[0]}, ${imageData[1]}, ${imageData[2]}, ${1})`;
        const color = CanvasManipulator.rgbaToHex(imageData[0], imageData[1], imageData[2], 1)
        resolve(color);
      };
      image.onerror = () => {
        reject('Error loading base64 image.');
      };
    });
    return await color;
  }
}