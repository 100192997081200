import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
// import { GridContainer, ToolItem, ToolItemICONChildren } from '../../components/toolBar/UltimateControlPanel';
import { SystemOperationsContext } from "../../context/SystemRunnerContext";
import {FCFullFunctionalPanel} from './FcControlPanel'
import {PIFullFunctionalPanel} from './PostControlPanel'


export const baseSubItemStyle = {
  boxSizing: 'border-box',
  borderRadius: "5px",
  backgroundColor: "rgba(0, 0, 0, 0)",
  color: "#e0e0e0",
  border: '2px solid #e0e0e0',
  cursor: "pointer",
  fontSize: "14px",
  textAlign: "center",
}

export const HandControlPanel = ({ toolpack, isVertical }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [rect, setRect] = useState(toolpack.paperSelectionArray[0]); // can be undefined...
  const [type, setType] = useState(toolpack.paperSelectionArray[0] ? toolpack.paperSelectionArray[0].typeName : "");

  useEffect(() => {
    const setNewRect = (selections, selectionArray) => {
      const rect = selectionArray[0]; // might be undefined
      setRect(rect);
      setType(rect ? rect.typeName : "");
    }

    toolpack.onPaperSelectionsChange["_HCP"] = setNewRect;
    return () => {
      delete toolpack.onPaperSelectionsChange["_HCP"];
    }
  }, []);

  const containerStyle = {
    boxSizing: 'border-box',
    [isVertical ? "width" : "height"]: "100%",
    zIndex: "100000000000000000"
  }

  const subItemBaseStyle = { ...baseSubItemStyle, [isVertical ? "width" : "height"]: "25%" };
  const subItemHoverStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.2)" };
  const subItemSelectedStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.5)" };

  const divider = (
    <div style={{
      borderRadius: "1px", backgroundColor: "#e0e0e0",
      [isVertical ? "height" : "width"]: "1px", [isVertical ? "width" : "height"]: "100%",
      [isVertical ? "marginBottom" : "marginRight"]: 3, [isVertical ? "marginTop" : "marginLeft"]: 3,
    }} />
  );

  if (!rect) {
    return (
      <div style={{ ...containerStyle, color: "white", fontSize: "10px" }}>
        No target selected
      </div>
    );
  }

  let rectDisplay = null;
  if (type === "floatingCanvases") {
    rectDisplay = (<FCFullFunctionalPanel canvasRect={rect} isVertical={isVertical} />)
  } else if (type === "posts") {
    rectDisplay = (<PIFullFunctionalPanel postInstanceRect={rect} isVertical={isVertical} />)
  }

  return (
    <div style={containerStyle}>
      <RectDataDisplay rect={rect} divider={divider} />
      {rectDisplay}
    </div>
  )
}

export const RectDataDisplay = ({ rect, divider }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [userData, setUserData] = useState({
    username: rect ? (rect.getData ? rect.getData().creatorID : "") : "",
    avatar: ""
  });

  useEffect(() => {
    const loadUserData = (rect) => {
      if (rect?.getData && rect?.getData().creatorID) {
        system_operations.usersData.getUserDataByID(rect.getData().creatorID).then((user) => {
          setUserData(user);
        })
      } else {
        setUserData("");
      }
    }

    loadUserData(rect);
  }, [rect]);



  if (rect) {
    return (
      <div>
        <div style={{ color: "white", fontSize: "14px" }}> {"@" + userData.username} </div>
        {rect?.getData()?.date &&
          <div style={{ color: "white", fontSize: "14px" }}>
            {unixTimestampToDate(rect.getData().date)}
          </div>
        }
        {divider}
        {rect &&
          <div
            style={{ color: "#808080", fontSize: "10px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {rect.getID()}
          </div>
        }
        {divider}
      </div>
    )
  } else {
    return null;
  }
}

function unixTimestampToDate(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convert seconds to milliseconds
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');  // +1 because months are 0-indexed
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

