import React from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/system';

function ButtonGroups({ buttons }) {
  const theme = useTheme();

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {Object.keys(buttons).map((buttonKey) => {
        const button = buttons[buttonKey];
        return (
          <Button
            key={buttonKey}
            style={{
              flex: 1,
              height: "26px", 
              backgroundColor: button.backgroundColor, 
              color: button.checkDisable()? "rgb(80, 80, 80)" : button.color,
              padding: "2px", 
              borderRadius: theme.borderRadius.wallPageUI, 
              whiteSpace: 'nowrap',
              marginRight: buttonKey !== Object.keys(buttons).slice(-1)[0] ? '1em' : 0  // add right margin except for the last button
            }}
            disabled={button.checkDisable()}
            onClick={button.onClick}
          >
            {button.name}
          </Button>
        );
      })}
    </div>
  );
}

export default ButtonGroups;