import React, { useState, useContext, useRef, useEffect } from 'react';

import { Card, Paper, CardMedia, CardContent, Typography } from '@mui/material';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { PostMainPanel } from "../cross_page_components/PostMedia.jsx";
import NoPostImage from "../img/Pencel_The_Post_is_Gone.svg"


export class PostDisplayAndUtility {

  static createDefaultPost(userID) {
    const newPost = {
      text: "",
      contentUrls: [],
      mediaType: "",
      numberOfContent: 0,

      creatorID: userID ? userID : "",
      protectLevel: 0,
      createdDate: Date.now(),
      // instancesLocation: [],

      views: 0,
      likes: 0,
    }
    return newPost;
  }

  static createPostInstanceFromAPost(post, protectLevel = 0,) {
    const postInstance = {
      creatorID: post.creatorID,
      postID: post.postID,
      mediaType: post.mediaType,
      displayStyle: "",
      x: post.x,
      y: post.y,
      width: 128,
      height: 128,
      previewUrls: post.previewUrls,
      text: post.text,
      numberOfContent: post.numberOfContent,
      layer: 0,

      protectLevel: protectLevel,
      createdDate: post.createdDate,
      style: {},
    }
    return postInstance;
  }

  static shapeAPostInstance(postInstance) {
    const newPost = {
      creatorID: postInstance.creatorID,
      postID: postInstance.postID,
      mediaType: postInstance.mediaType,
      displayStyle: postInstance.displayStyle,
      x: postInstance.x,
      y: postInstance.y,
      width: postInstance.width,
      height: postInstance.height,
      previewUrls: postInstance.previewUrls,
      text: postInstance.text,
      numberOfContent: postInstance.numberOfContent,
      layer: postInstance.layer,

      protectLevel: postInstance.protectLevel,
      createdDate: postInstance.createdDate,
      style: postInstance.style,
    };
    return newPost;
  }

  // used for both post and post instance
  static getPostDisplay(post, fetchPost, setContentWindow, postRectRef = null, options = {},) {
    const mediaType = post.mediaType ? post.mediaType : "text";
    const textContent = post.text ? post.text : "<p><br></p>";
    let numberOfContent = post.numberOfContent ? post.numberOfContent : (post.contentUrls ? post.contentUrls.length : 0);
    const width = post.width ? post.width : 128;
    const height = post.height ? post.height : 128;
    let previewUrls = post.previewUrls && post.previewUrls.length > 0 ? post.previewUrls : [];

    const openPost = async () => {
      if (post.postInstanceID) {
        // it's a post instance
        if (fetchPost) {
          const originalPost = await fetchPost(post);
          if (setContentWindow) { setContentWindow(<PostMainPanel isCreating={false} existingPost={originalPost} postInstanceRectRef={postRectRef} style={{ borderRadius: "10px" }} />) }
        }
      } else {
        // it's a post
        if (setContentWindow) { setContentWindow(<PostMainPanel isCreating={false} existingPost={post} postInstanceRectRef={null} style={{ borderRadius: "10px" }} />) }
      }
    }

    if (previewUrls.length > 0) {
      // if (displayStyle !== "textFirst") {
        // display a big file icon.
      return (<ShadedImagePostCard textContent={textContent} previewUrls={previewUrls} height={height} openPost={openPost} options={options}/>);
      // }
    }
    return (<TextPriorityPostCard textContent={textContent} previewUrls={previewUrls} openPost={openPost} options={options}/>);
  }
}

const TextPriorityPostCard = ({ previewUrls, textContent, openPost }) => {
  return (
    <Paper sx={{ width: "100%", height: "100%", borderRadius: "15px", overflow: 'hidden', boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)', }} onClick={openPost}>
      <QuillTextDisplayer textContent={textContent} color={""} />
    </Paper>
  );
}

// it will match the size of the parent
const ShadedImagePostCard = ({ previewUrls, textContent, height, openPost, options}) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [shadeText, setShadeText] = React.useState('');
  const [currentImage, setCurrentImage] = React.useState(previewUrls[0] ? previewUrls[0] : "");
  // Convert Quill Delta to plain text on component mount
  useEffect(() => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(textContent, 'text/html');
    setShadeText(htmlDoc.body.textContent || '');
  }, [textContent]);

  const handleError = () => {
    setCurrentImage(NoPostImage);
  };

  const shadeStartHeight = system_operations.coordinator.theoreticalSpaceToScreenSpace(height * 0.75);
  const shadeDelta = system_operations.coordinator.theoreticalSpaceToScreenSpace(30);
  const textPadding = system_operations.coordinator.theoreticalSpaceToScreenSpace(10);

  const scale = system_operations.coordinator.boardPositioner.scaleToScr.bind(system_operations.coordinator.boardPositioner);
  return (
    <Card onClick={openPost} sx={{ position: "absolute", left: 0, top: 0, borderRadius: `${scale(15)}px`, width: "100%", height: "100%", userSelect: 'none', overflow: 'hidden' }}>
      <CardMedia
        component="img"
        alt= "no image..."
        image={currentImage}
        onError={handleError}
        sx={{ height: '100%', width: '100%', objectFit: 'cover', }} />
      {!options.ignoreDarkCover && <CardContent
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background: `linear-gradient(to top, rgba(0, 0, 0, 0.93), rgba(0, 0, 0, 0.93) ${shadeStartHeight}px, transparent ${shadeStartHeight + shadeDelta}px)`,
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          padding: 0,
          opacity: 0,
          transition: 'opacity 0.3s',
          ':hover': {
            opacity: 1,
          }
        }}>
        <Typography
          variant="secondary"
          color="white"
          sx={{
            position: "absolute", bottom: "0px",
            left: textPadding, right: textPadding,
            height: shadeStartHeight,
            color: '#eeeeee',
            fontSize: '1rem',
            fontWeight: 400,
            alignSelf: 'flex-start', // try to override parent's centering
          }}>
          {shadeText}
        </Typography>
      </CardContent>}
    </Card>
  );
}

const uniformTextColor = (textContent, color) => {
  textContent.ops.forEach(op => {
    if (op.attributes && op.attributes.color) {
      op.attributes.color = color;
    }
  });
  return textContent;
}

const QuillTextDisplayer = ({ textContent, color = "" }) => {
  return (
    <ReactQuill
      theme="snow"
      value={textContent}
      readOnly={true}
      modules={{
        toolbar: false, // Disable toolbar
      }}
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: 'transparent',
        color: color // customize text color
      }} // Set the size of the editor
    />
  );
}

export default PostDisplayAndUtility;