import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import Default_Avatar from '../img/Default_Avatar.png';
import './styles/home_components.scss';

const FriendList = () => {
  const { system_operations } = useContext(SystemOperationsContext);

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [friendList, setFriendList] = useState([]);
  const [displayedFriends, setDisplayedFriends] = useState(friendList);

  // login refresh mechanism ##################################################################################
  const defaultUser = useRef({
    userID: "",
    email: "none",
    username: "sign in",
    avatar: "none",
    recentVisit: []
  })
  const [userData, setUserData] = useState(system_operations.getUserData() ? system_operations.getUserData() : defaultUser.current);
  const OnUserChange = (uData) => {
    setUserData(uData ? uData : defaultUser.current)
  }
  system_operations.addToOnUserChange("fromFriendList", OnUserChange);
  // login refresh mechanism ##################################################################################

  useEffect(() => {
    system_operations.addFunctionToDict(system_operations.onFollowingDoneLoading, "fromFriendList", setFriendList);
    if (system_operations.following.length !== 0) {
      setFriendList(system_operations.following);
    }
  }, [])

  const handleClick = async (wallData) => {
    await system_operations.enterAWall(wallData["wallID"]);
    navigate("/board");
  }

  const filtering = () => {
    return friendList.filter(friendList =>
      friendList.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchButtonClick = () => {
    setDisplayedFriends(filtering());
  };

  return (
    <div className="home-list-container home-list-container--items">
      <div style={{position: "relative"}}>
        <div style={{position: "absolute", fontSize: "50px", color: "#cccccc"}}>
          Coming Soon......
        </div>
      </div>
      {/*<p>-Friends-</p>
      <hr className='home-page-horizontal-line' />
      {userData.userID &&
        <div>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
            <button className="search-button" onClick={handleSearchButtonClick}>
              {">"}
            </button>
          </div>
          <ul>
            {friendList.map((content, index) => (
              <li
                onClick={() => handleClick(content)}
                key={index}
              >
                <img
                  className="avatar-icon"
                  src={content.avatarUrl === "none" ? Default_Avatar : content.avatarUrl}
                  alt={`${content.displayName}'s avatar`}
                />
                {content.displayName}
              </li>
            ))}
          </ul>
            </div>}*/}
    </div>
  );
};

export default FriendList;

