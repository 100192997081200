
export class MouseData {
  constructor(coordinator, sizer) {
    this.isHorizontal = true;

    this.mouseOnScreenPosition = [0, 0];
    this.mouseOnScreenTheoPosition = [0, 0];
    this.mouseOnCanvasTheoPosition = [0, 0];
    this.coordinator = coordinator;
    this.sizer = sizer;
    this.lastDownEvent = null;

    this.onMousePositionChange = {}
  }

  setCurrentEventTarget(event) {
    this.lastDownEvent = event;
  }

  setMouseOnScreenPosition(onScreenPosition) {
    if (this.mouseOnScreenPosition[0] !== onScreenPosition[0] || this.mouseOnScreenPosition[1] !== onScreenPosition[1]) {
      this.lastMousePosition = this.mouseOnScreenPosition;
      this.mouseOnScreenPosition = onScreenPosition;
      this.mouseOnScreenTheoPosition = [
        this.coordinator.screenSpaceToTheoreticalSpace(this.mouseOnScreenPosition[0]),
        this.coordinator.screenSpaceToTheoreticalSpace(this.mouseOnScreenPosition[1])
      ];
      const deltaPosition = [this.mouseOnScreenPosition[0] - this.lastMousePosition[0], this.mouseOnScreenPosition[1] - this.lastMousePosition[1]];
      for (const key in this.onMousePositionChange) {
        this.onMousePositionChange[key](this.mouseOnScreenPosition, deltaPosition);
      }
    }
  }

  getMousePositionScreenSpace() {
    return this.mouseOnScreenPosition;
  }

  getMouseOnCanvasLocalPositionScreenSpace(onScreenPosition = null) {
    return [
      this.mouseOnScreenPosition[0] - this.sizer.getCanvasStartPx(false),
      this.mouseOnScreenPosition[1] - this.sizer.getCanvasStartPx(true)
    ]
  }

  getMouseOnCanvasGlobalPositionScreenSpace(onScreenPosition = null) {
    return [
      this.mouseOnScreenPosition[0] - this.sizer.getCanvasStartPx(false) + this.coordinator.getCanvasOffset_screenSpace(),
      this.mouseOnScreenPosition[1] - this.sizer.getCanvasStartPx(true)
    ]
  }

  getMousePositionTheoreticalSpace(onScreenPosition = null) {
    if (!onScreenPosition) {
      return this.mouseOnScreenTheoPosition;
    } else {
      return [
        this.coordinator.screenSpaceToTheoreticalSpace(onScreenPosition[0]),
        this.coordinator.screenSpaceToTheoreticalSpace(onScreenPosition[1])
      ];
    }
  }

  getMouseOnCanvasLocalPositionTheoreticalSpace(onScreenPosition = null) {
    if (!onScreenPosition) {
      return [
        this.coordinator.screenSpaceToTheoreticalSpace(this.mouseOnScreenPosition[0] - this.sizer.getCanvasStartPx(false)),
        this.coordinator.screenSpaceToTheoreticalSpace(this.mouseOnScreenPosition[1] - this.sizer.getCanvasStartPx(true))
      ]
    } else {
      return [
        this.coordinator.screenSpaceToTheoreticalSpace(onScreenPosition[0] - this.sizer.getCanvasStartPx(false)),
        this.coordinator.screenSpaceToTheoreticalSpace(onScreenPosition[1] - this.sizer.getCanvasStartPx(true))
      ];
    }
  }

  getMouseOnCanvasGlobalPositionTheoreticalSpace(onScreenPosition = null) {
    if (!onScreenPosition) {
      return [
        this.mouseOnScreenTheoPosition[0] - this.coordinator.screenSpaceToTheoreticalSpace(this.sizer.getCanvasStartPx(false)) + this.coordinator.getCanvasOffset_theoreticalSpace(),
        this.mouseOnScreenTheoPosition[1] - this.coordinator.screenSpaceToTheoreticalSpace(this.sizer.getCanvasStartPx(true)),
      ]
    } else {
      return [
        this.coordinator.screenSpaceToTheoreticalSpace(onScreenPosition[0] - this.sizer.getCanvasStartPx(false)) + this.coordinator.getCanvasOffset_theoreticalSpace(),
        this.coordinator.screenSpaceToTheoreticalSpace(onScreenPosition[1] - this.sizer.getCanvasStartPx(true))
      ];
    }
  }

  getTheoMousePosPivotFromAScreenPoint(pivotX, pivotY, mouseCoord = null) {
    if (!mouseCoord) { mouseCoord = this.mouseOnScreenTheoPosition }
    const theoX = this.coordinator.screenSpaceToTheoreticalSpace(pivotX);
    const theoY = this.coordinator.screenSpaceToTheoreticalSpace(pivotY);
    return [mouseCoord[0] - theoX, mouseCoord[1] - theoY];
  }

  getTheoMousePosPivotFromATheoPoint(pivotX, pivotY, mouseCoord = null) {
    if (!mouseCoord) { mouseCoord = this.mouseOnScreenTheoPosition }
    return [mouseCoord[0] - pivotX, mouseCoord[1] - pivotY];
  }
}