export function measureText(text, bold, isItalic, font, size) {
    var div = document.createElement('DIV');
    div.innerHTML = text;
    div.style.position = 'absolute';
    div.style.top = '0px';
    div.style.left = '0px';
    div.style.visibility = 'hidden'; // Hide the element without affecting its layout
    div.style.fontFamily = font;
    div.style.fontWeight = bold ? 'bold' : 'normal';
    div.style.fontSize = size + 'pt';
    div.style.fontStyle = isItalic ? 'italic' : 'normal'; // Set fontStyle based on isItalic
    div.style.whiteSpace = 'nowrap'; // to prevent wrapping, remove if not needed
    document.body.appendChild(div);
    
    var textSize = [div.offsetWidth, div.offsetHeight];
    document.body.removeChild(div);
    
    return textSize;
}

export function boxRotation(pivotGlobalX, pivotGlobalY, pivotRatioX, pivotRatioY, angleRad, width, height) {
    // Calculate the pivot point in relation to the rectangle
    const pivotX = pivotGlobalX - (pivotRatioX * width);
    const pivotY = pivotGlobalY - (pivotRatioY * height);

    // Calculate the four corners of the rectangle
    const corners = [
        { x: pivotX, y: pivotY }, // top-left
        { x: pivotX + width, y: pivotY }, // top-right
        { x: pivotX + width, y: pivotY + height }, // bottom-right
        { x: pivotX, y: pivotY + height } // bottom-left
    ];

    // Rotate each corner
    const rotatedCorners = corners.map(corner => {
        const dx = corner.x - pivotGlobalX;
        const dy = corner.y - pivotGlobalY;
        const hoho = {
            x: pivotGlobalX + (dx * Math.cos(angleRad) - dy * Math.sin(angleRad)),
            y: pivotGlobalY + (dx * Math.sin(angleRad) + dy * Math.cos(angleRad))
        };
        return hoho;
    });

    // Find the bounding box of the rotated rectangle
    const minX = Math.min(...rotatedCorners.map(corner => corner.x));
    const minY = Math.min(...rotatedCorners.map(corner => corner.y));
    const maxX = Math.max(...rotatedCorners.map(corner => corner.x));
    const maxY = Math.max(...rotatedCorners.map(corner => corner.y));

    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY
    };
}