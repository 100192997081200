export class LoadingSceneController {
  constructor() {
    this.loading_count = 0;
    this.loading_scene_on = false;
    this.set_loading_hook = null;
    this.on_loading_scene_change = {};
  }

  assign_set_loading_hook(set_loading) {
    this.set_loading_hook = set_loading;
  }
  // loading scene control is like a stack. when not forceful, it will just +/- 1 to the count, if forceful, then will just set the value to 1 or 0
  set_loading_scene(turn_on, forceful = false) {
    if (turn_on) {
      if (forceful) {
        this.loading_count = 1;
      } else {
        this.loading_count += 1;
      }
    } else {
      if (forceful) {
        this.loading_count = 0;
      } else {
        this.loading_count -= 1;
      }
    }

    this.loading_count = Math.max(this.loading_count, 0); // cannot go below 0

    if (this.loading_count > 0 && !this.loading_scene_on) {
      this.loading_scene_on = true;
      this.set_loading_hook(true);
      for (const fun in this.on_loading_scene_change) {
        this.on_loading_scene_change[fun](true);
      }
    } else if (this.loading_count === 0 && this.loading_scene_on) {
      this.loading_scene_on = false;
      this.set_loading_hook(false);
      for (const fun in this.on_loading_scene_change) {
        this.on_loading_scene_change[fun](false);
      }
    }
  }
}