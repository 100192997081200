import React, { useState, useRef, useEffect, useContext } from 'react';
import { SystemOperationsContext } from "../../context/SystemRunnerContext.js";
const Input_Height = 50;

export const PaperJumpButton = ({ paperRect }) => {
  const { system_operations } = useContext(SystemOperationsContext);

  const onClick = () => {
    const buttonData = paperRect.getData().extension.data;
    system_operations.coordinator.setGlobalOffsetTheo(buttonData.coordinate, true);
  }

  return (
    <div
      onClick={onClick}
      style={{
        width: `${paperRect.getActiveWidth()}px`,
        height: `${paperRect.getActiveHeight()}px`,
        cursor: "pointer",
      }}> </div>
  );
};
