import React, { useState, useRef, useContext, useEffect } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import StickerCreationToolBar from "./StickerCreationToolBar.jsx";
import { RatioSizer } from "../utility/Sizer.js";
import ButtonGroups from "./ButtonGroups.jsx";

import { useTheme } from '@mui/system';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';

const MyIconButton = styled(Button)(({ theme }) => ({
  position: 'absolute !important',
  top: '50% !important',
  left: '50% !important',
  transform: 'translate(-50%, -50%) !important',
  width: '250px !important',
  height: '250px !important',
  borderRadius: '50% !important',
  backgroundColor: 'rgba(128, 128, 128, 0.6) !important',
  '&:hover': {
    backgroundColor: 'rgba(128, 128, 128, 0.8) !important',
    boxShadow: `0 0 10px ${theme.palette.grey[700]} !important`,
    transform: 'translate(-50%, -50%) scale(1.05) !important',
  },
  transition: '0.3s !important',
}));

const StyledAddIcon = styled(AddIcon)({
  width: "100%", height: "100%",
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    color: 'rgba(255, 255, 255, 0.77)'
  }
});

const ImagePostCreation = ({ perfectWindowSize, uplaod, submit, contentFile }) => {
  const { system_operations } = useContext(SystemOperationsContext);

  const [uploadedImage, setUploadedImage] = useState(null);

  const [showDescriptionInput, setShowDescriptionInput] = useState(false);
  const [displayedCanvasSize, setDisplayedCanvasSize] = useState([0, 0]);
  const insideTopDownMargin = 200, outsideTopDownMargin = 140;
  const [canvasWidth, setCanvasWidth] = useState(768);
  const [canvasHeight, setCanvasHeight] = useState(512);
  const [emptyCanvas, setEmptyCanvas] = useState(true);

  const containerSizeCalculator = useRef(new RatioSizer());
  const canvasRef = useRef(null);
  const textareaRef = useRef("");

  const theme = useTheme();

  const onTextChange = (event) => {
    textareaRef.current = event.target.value;
  }

  const onCleanCanvas = async () => {
    setUploadedImage(null);
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setEmptyCanvas(true);
  };

  const setCanvasDisplaySize = (canvasW, canvasH) => {
    const newCanvasDisplaySize = system_operations.imageProcessor.fitImageSize(canvasW, canvasH, perfectWindowSize[0], perfectWindowSize[1] - insideTopDownMargin);
    setDisplayedCanvasSize(newCanvasDisplaySize);
  }

  useEffect(() => {
    if (contentFile) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const img = new Image();
        img.onload = async () => {
          const ctx = canvasRef.current.getContext('2d');
          ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
          setCanvasWidth(img.width);
          setCanvasHeight(img.height);
          setUploadedImage(img);
          setEmptyCanvas(false);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(contentFile);
    }
  }, [contentFile])

  useEffect(() => {
    setCanvasDisplaySize(canvasWidth, canvasHeight);
  }, [perfectWindowSize, canvasWidth, canvasHeight]);

  useEffect(() => {
    if (uploadedImage && canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      ctx.drawImage(uploadedImage, 0, 0, uploadedImage.width, uploadedImage.height, 0, 0, uploadedImage.width, uploadedImage.height);
    }
  }, [canvasWidth, canvasHeight, uploadedImage]);

  useEffect(() => {
    containerSizeCalculator.current.setWidthRatio(0.5, 800, 0.9);
    containerSizeCalculator.current.setHeightRatio(0.8, 0, 0);
    containerSizeCalculator.current.setWidthRange(400, 1000);
    containerSizeCalculator.current.setHeightRange(300, 1440);
  }, []);

  const submitFunction = () => {
    submit("image", canvasRef.current.toDataURL("image/png"), true,
      canvasRef.current.width, canvasRef.current.height,
      null, "image/png", textareaRef.current, "");
  }

  return (
    <div>
      <div style={{ paddingTop: "5px", "overflow": "hidden", width: `${displayedCanvasSize[0]}px`, height: `${displayedCanvasSize[1]}px` }}>
        {showDescriptionInput && <div style={{ position: "absolute", bottom: "0px", width: '100%', height: '60px' }}>
          <textarea
            className='list-container list-container--white-text list-container--no-padding'
            onChange={onTextChange}
            defaultValue={textareaRef.current}
            style={{
              position: "absolute", left: "5px", right: "5px", bottom: "5px", backgroundColor: "white", border: "none",
              color: "black", borderRadius: "5px", resize: "none"
            }}
          />
        </div>}
        {/*canvas size might different from the display size. the outside div control the actual display size.*/}
        <div style={{ width: "100%", height: "100%"}}>
          {contentFile ? (<canvas ref={canvasRef} style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
            width={canvasWidth} height={canvasHeight} onClick={uplaod}
          />) : (
            <MyIconButton
              variant="contained"
              startIcon={
                <StyledAddIcon />
              }
              size="large"
              onClick={uplaod}
            >
            </MyIconButton>)}
        </div>
      </div>
      {/* <StickerCreationToolBar setDescribeBox={setShowDescriptionInput} selectTool={test} />*/}
      <div style={{ marginTop: "5px" }}>
        <ButtonGroups buttons={{
          submitButton: {
            name: 'Looks Good',
            color: "white",
            backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
            checkDisable: () => { return false },
            onClick: () => { submitFunction() },
          }
        }} />
      </div>
    </div>
  );
}

export default ImagePostCreation;