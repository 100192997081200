const trendingUrl = 'https://trendingsystem-snlplwplkq-uc.a.run.app';

// const mainCanvasDrawUrl = "http://localhost:8081";
const mainCanvasDrawUrl = "https://main-canvas-draw-snlplwplkq-uc.a.run.app";

// const fcDrawUrl = 'http://localhost:8082';
const fcDrawUrl = 'https://fcdraw-snlplwplkq-wl.a.run.app';

const likeSystemUrl = 'https://likesystem-snlplwplkq-uc.a.run.app'

// const paperCollisionUrl = 'http://localhost:8083';
const paperCollisionUrl = 'https://papersystem-snlplwplkq-uc.a.run.app';

// const paperOtherDataUpdateUpdaterUrl = 'http://localhost:8085';
const paperOtherDataUpdateUpdaterUrl = 'https://paper-data-upater-snlplwplkq-uc.a.run.app';

// const userAndBoardControlUrl = 'http://localhost:8080';
const userAndBoardControlUrl = 'https://users-and-boards-controller-snlplwplkq-uc.a.run.app';

// const fenceManagerUrl = 'http://localhost:8084';
const fenceManagerUrl = 'https://fence-manager-snlplwplkq-uc.a.run.app';

// const postManagerUrl = "http://localhost:8086"
const postManagerUrl = "https://postmanager-snlplwplkq-uc.a.run.app";

// const markManagerUrl = "http://localhost:8087";
const markManagerUrl = "https://markmanager-snlplwplkq-uc.a.run.app";

export class RestServerConnector {
  constructor(system) {
    this.trendingUrl = trendingUrl;
    this.likeSystemUrl = likeSystemUrl;
    this.system = system;
  }

  async getTrending(counter = 0, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.getJsonGeneral(`${trendingUrl}/trending/${counter}`, defaultErrorRasing, raiseErrorFunc);
  }

  async likeOrDislikeAnItem(dataJson, delta, defaultErrorRasing = true, raiseErrorFunc = null) {
    if (delta > 0) { dataJson.delta = 1; } else if (delta < 0) { dataJson.delta = -1; }
    return this.postJsonGeneral(`${likeSystemUrl}/likes`, dataJson, defaultErrorRasing, raiseErrorFunc);
  }

  updateMainCanvasImages(updateData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${mainCanvasDrawUrl}/update`, updateData, defaultErrorRasing, raiseErrorFunc);
  }

  updateFloatingCanvasImage(updateData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${fcDrawUrl}/update`, updateData, defaultErrorRasing, raiseErrorFunc);
  }

  removeFloatingCanvasImage(updateData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${paperOtherDataUpdateUpdaterUrl}/removefcimage`, updateData, defaultErrorRasing, raiseErrorFunc);
  }

  // old and dumped
  /* drawOnMainCanvas(drawData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${mainCanvasDrawUrl}/draw`, drawData, defaultErrorRasing, raiseErrorFunc);
  }
  drawOnAFloatingCanvas(fcDrawData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${fcDrawUrl}/fcdraw`, fcDrawData, defaultErrorRasing, raiseErrorFunc);
  } */

  requestToUpdatePapersTransform(fcRequestData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${paperCollisionUrl}/papertransforms`, fcRequestData, defaultErrorRasing, raiseErrorFunc);
  }

  requestToUpdateButtonsTransform(abRequestData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${paperCollisionUrl}/actionbuttontransforms`, abRequestData, defaultErrorRasing, raiseErrorFunc);
  }

  requestToDeleteAPapers(deletionData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${paperCollisionUrl}/deletepaper`, deletionData, defaultErrorRasing, raiseErrorFunc);
  }

  requestToDeleteAnActionButton(deletionData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${paperCollisionUrl}/deleteactionbutton`, deletionData, defaultErrorRasing, raiseErrorFunc);
  }

  requestToUpdatePaperExtension(updateData, files, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonWithFiles(`${paperOtherDataUpdateUpdaterUrl}/updateextension`, updateData, files, defaultErrorRasing, raiseErrorFunc);
  }

  requestToUpdateFloatingcanvasMedia(updateData, files, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonWithFiles(`${paperOtherDataUpdateUpdaterUrl}/updatefcmedia`, updateData, files, defaultErrorRasing, raiseErrorFunc);
  }

  requestToUpdateFloatingCanvasProperties(updateData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${paperOtherDataUpdateUpdaterUrl}/updatefcproperties`, updateData, defaultErrorRasing, raiseErrorFunc);
  }

  requestToUpdateFloatingCanvasStyles(updateData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${paperOtherDataUpdateUpdaterUrl}/updatefcstyles`, updateData, defaultErrorRasing, raiseErrorFunc);
  }

  requestToUpdatePostInstance(updateData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${paperOtherDataUpdateUpdaterUrl}/updatepi`, updateData, defaultErrorRasing, raiseErrorFunc);
  }

  requestToUpdateBasePost(updateData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${paperOtherDataUpdateUpdaterUrl}/updatepost`, updateData, defaultErrorRasing, raiseErrorFunc);
  }

  requestToUpdateButtonData(updateData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${paperOtherDataUpdateUpdaterUrl}/updateab`, updateData, defaultErrorRasing, raiseErrorFunc);
  }

  createNewUserWithEmail(userCreationData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${userAndBoardControlUrl}/createuseremail`, userCreationData, defaultErrorRasing, raiseErrorFunc);
  }

  createABoard(boardCreationData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${userAndBoardControlUrl}/createaboard`, boardCreationData, defaultErrorRasing, raiseErrorFunc);
  }

  updateBoard(updateData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${userAndBoardControlUrl}/updateboard`, updateData, defaultErrorRasing, raiseErrorFunc);
  }

  updateBoardContent(updateData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${userAndBoardControlUrl}/updateboardcontent`, updateData, defaultErrorRasing, raiseErrorFunc);
  }

  updateBoardDisplaySetting(updateData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${userAndBoardControlUrl}/updateboardimage`, updateData, defaultErrorRasing, raiseErrorFunc);
  }

  updateBoardDomain(updateData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${userAndBoardControlUrl}/updateboarddomain`, updateData, defaultErrorRasing, raiseErrorFunc);
  }

  createNewFence(fenceData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${fenceManagerUrl}/createfence`, fenceData, defaultErrorRasing, raiseErrorFunc);
  }

  deleteAFence(fenceData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${fenceManagerUrl}/removefence`, fenceData, defaultErrorRasing, raiseErrorFunc);
  }

  createAPost(postData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${postManagerUrl}/createapost`, postData, defaultErrorRasing, raiseErrorFunc);
  }

  deleteAPost(postData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${postManagerUrl}/deleteapost`, postData, defaultErrorRasing, raiseErrorFunc);
  }

  createAComment(commentData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${postManagerUrl}/addacomment`, commentData, defaultErrorRasing, raiseErrorFunc);
  }

  deleteAComment(commentData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${postManagerUrl}/deleteacomment`, commentData, defaultErrorRasing, raiseErrorFunc);
  }

  createAMark(markData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${markManagerUrl}/createamark`, markData, defaultErrorRasing, raiseErrorFunc);
  }

  deleteAMark(markData, defaultErrorRasing = true, raiseErrorFunc = null) {
    return this.postJsonGeneral(`${markManagerUrl}/deleteamark`, markData, defaultErrorRasing, raiseErrorFunc);
  }

  async getJsonGeneral(url, defaultErrorRasing = true, raiseErrorFunc = null) {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      if (defaultErrorRasing && this.system) { this.system.raiseLoadingError(error) }
      if (raiseErrorFunc) { raiseErrorFunc(error) }
      return null;
    }
  }

  async postJsonGeneral(url, jsonData, defaultErrorRasing = true, raiseErrorFunc = null) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(jsonData)
      });

      if (!response.ok) {
        // Try to read the error message from the server
        let errorMsg = "";
        try {
          const errorData = await response.json();
          errorMsg = errorData.message || `HTTP error! status: ${response.status}`;
        } catch (parseError) {
          // If parsing fails, use the status code
          errorMsg = `HTTP error! status: ${response.status}`;
        }
        if (defaultErrorRasing && this.system) { this.system.raiseLoadingErrorMessage(errorMsg) }
        return null;
      }

      const data = await response.json();
      return data;
    } catch (error) {
      if (defaultErrorRasing && this.system) { this.system.raiseLoadingError(error) }
      if (raiseErrorFunc) { raiseErrorFunc(error) }
      return null;
    }
  }

  async postJsonWithFiles(url, jsonData, filesArray = [], defaultErrorRasing = true, raiseErrorFunc = null) {
    // Create FormData object
    const formData = new FormData();
    // Append each file from the filesArray to the FormData
    filesArray.forEach(file => {
        formData.append('files', file);
    });
    // Append the JSON data to the FormData
    formData.append('jsonData', JSON.stringify(jsonData));

    console.log("formData: ", formData);

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: formData // No need to set 'Content-Type', the browser will set it to 'multipart/form-data'
        });
        if (!response.ok) {
            // Try to read the error message from the server
            let errorMsg = "";
            try {
                const errorData = await response.json();
                errorMsg = errorData.message || `HTTP error! status: ${response.status}`;
            } catch (parseError) {
                // If parsing fails, use the status code
                errorMsg = `HTTP error! status: ${response.status}`;
            }
            if (defaultErrorRasing && this.system) { this.system.raiseLoadingErrorMessage(errorMsg) }
            if (raiseErrorFunc) { raiseErrorFunc(new Error(errorMsg)) }
            return null;
        }
        const data = await response.json();
        return data;
    } catch (error) {
        if (defaultErrorRasing && this.system) { this.system.raiseLoadingError(error) }
        if (raiseErrorFunc) { raiseErrorFunc(error) }
        return null;
    }
}
}

export class RequestDataMaker {
  constructor() { }

  static async getBaseData(system) {
    return {
      userID: system.getUserID(),
      idToken: await system.obtainIDToken(),
    }
  }

  static async getBaseBoardData(system) {
    return {
      boardID: system.coordinator.getWallID(),
      userID: system.getUserID(),
      socketID: system.socket_connector.socketID || system.getUserID(),
      idToken: await system.obtainIDToken(),
    }
  }

  static async dataToUpdateMainCanvasImages(system, dataUrl, globalEntity, xOffset, yOffset) {
    return {
      ...(await this.getBaseBoardData(system)),
      loggedIn: system.loggedIn,
      dataUrl, globalEntity, xOffset, yOffset,
    }
  }

  static async dataToUpdateFloatingCanvasImage(system, canvasID, dataUrl) {
    return {
      ...(await this.getBaseBoardData(system)),
      canvasID: canvasID, dataUrl,
    }
  }

  static async dataToRemoveFloatingCanvasImage(system, canvasID) {  // remove the imageUrl
    return {
      ...(await this.getBaseBoardData(system)),
      canvasID: canvasID,
    }
  }

  static async papersTransformUpdateData(system, updateData) {
    const request = {
      ...(await this.getBaseBoardData(system)),
      ...updateData,
    }
    return request;
  }

  static async dataToCreateAPost(system, content, previewImages, text) {
    const request = {
      ...(await this.getBaseData(system)),
      content, previewImages, text,
    }
    return request;
  }

  static async dataToDeleteAPost(system, postID) {
    const request = {
      ...(await this.getBaseData(system)), postID,
    }
    return request;
  }

  static async dataToCreateAMark(system, title, baseType, baseID = "", coord = "") {
    const request = {
      ...(await this.getBaseBoardData(system)),
      title, baseType, baseID, coord,
    }
    return request;
  }

  static async dataToDeleteAMark(system, markID) {
    const request = {
      ...(await this.getBaseBoardData(system)),
      markID,
    }
    return request;
  }

  static async dataToCreateAComment(system, postID, content) {
    const request = {
      ...(await this.getBaseData(system)),
      postID, content,
    }
    return request;
  }

  static async dataToDeleteAComment(system, postID, commentID) {
    const request = {
      ...(await this.getBaseBoardData(system)),
      postID, commentID,
    }
    return request;
  }

  static async dataToUpdateFloatingCanvas(system, canvasID, updateFields) {
    return {
      canvasID: canvasID,
      ...(await this.getBaseBoardData(system)),
      ...updateFields,
    }
  }

  static async dataToUpdateFloatingCanvasMedia(system, canvasID, dataList, mediaType, remove = false) { // data files are handled elsewhere.
    return {
      canvasID: canvasID,
      ...(await this.getBaseBoardData(system)),
      dataList, mediaType, remove,
    }
  }

  static async dataToDeletePaperExtension(system, canvasID) { // data files are handled elsewhere.
    return {
      canvasID: canvasID,
      ...(await this.getBaseBoardData(system)),
      extensionType: "remove", extensionData: {remove: true},
    }
  }

  static async dataToUpdatePaperExtension_media(system, canvasID, dataList, mediaType, remove = false) { // data files are handled elsewhere.
    return {
      canvasID: canvasID,
      ...(await this.getBaseBoardData(system)),
      extensionType: "media", extensionData: {dataList, mediaType},
    }
  }

  static async dataToUpdatePaperExtension_post(system, canvasID, postID, text, previewUrls, numberOfContent, remove = false) { // data files are handled elsewhere.
    const extensionData = {
      postID, text, remove
    }
    if (previewUrls !== undefined) { extensionData.previewUrls = previewUrls; }
    if (numberOfContent !== undefined) { extensionData.numberOfContent = numberOfContent; }
    return {
      canvasID: canvasID,
      ...(await this.getBaseBoardData(system)),
      extensionType: "post", extensionData: extensionData,
    }
  }

  static async dataToUpdatePaperExtension_jump(system, canvasID, coordinate, remove = false) { // data files are handled elsewhere.
    return {
      canvasID: canvasID,
      ...(await this.getBaseBoardData(system)),
      extensionType: "jump", extensionData: {coordinate, remove},
    }
  }

  static async dataToUpdatePaperExtension_link(system, canvasID, link, remove = false) { // data files are handled elsewhere.
    return {
      canvasID: canvasID,
      ...(await this.getBaseBoardData(system)),
      extensionType: "link", extensionData: {link, remove},
    }
  }

  static async dataToUpdatePostInstance(system, postInstanceID, updateFields) {
    return {
      postInstanceID: postInstanceID,
      ...(await this.getBaseBoardData(system)),
      ...updateFields,
    }
  }

  static async dataToUpdateBasePost(system, postID, text) {
    return {
      postID: postID,
      userID: system.getUserID(),
      text: text,
      idToken: await system.obtainIDToken(),
    }
  }

  static async dataToUpdateActionButton(system, buttonID, updateData) {
    return {
      buttonID: buttonID,
      ...(await this.getBaseBoardData(system)),
      ...updateData,
    }
  }

  static async getDataToCreateAUserWithEmail(email, password, additionalData) {
    return { email, password, additionalData, }
  }

  static async getDataToCreateABoard(system, userID, displayName, description, isHorizontal) {
    return {
      idToken: await system.obtainIDToken(),
      userID,
      displayName, description, isHorizontal,
    }
  }

  static async getDataToCreateAFence(system, globalStart, fenceLength, fenceRules = 15) {
    return {
      ...(await this.getBaseBoardData(system)),
      globalStart, fenceLength, fenceRules,
    }
  }

  static async getDataToDeleteAFence(system, fenceID) {
    return {
      ...(await this.getBaseBoardData(system)),
      fenceID,
    }
  }

  static async getDataToUpdateboard(system, boardID, data) {
    return {
      boardID: boardID,
      ...await RequestDataMaker.getBaseData(system),
      ...data,
    }
  }

  static async getDataToUpdateboardContent(system, boardID, newContent) {
    return {
      boardID: boardID,
      ...await RequestDataMaker.getBaseData(system),
      content: newContent,
    }
  }
}