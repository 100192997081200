import { SignInMethod } from "firebase/auth";

export class RatioSizer {
  constructor() {
    this.widthAboveRatio = 0.5;
    this.widthBelowRatio = 0.9;
    this.widthRatioSwitchPoint = 800;
    this.heightAboveRatio = 0.8;
    this.heightBelowRatio = 1;
    this.heightRatioSwitchPoint = 0;
    this.minWidth = 0;
    this.maxWidth = 9999;
    this.minHeight = 0;
    this.maxHeight = 9999;
    this.hasMinWidth = false;
    this.hasMaxWidth = false;
    this.hasMinHeight = false;
    this.hasMaxHeight = false;
  }

  setWidthRatio(ratio, switchPoint = 0, switchedRatio = 0) {
    this.widthAboveRatio = ratio;
    this.widthBelowRatio = switchedRatio;
    this.widthRatioSwitchPoint = switchPoint;
  }

  setHeightRatio(ratio, switchPoint = 0, switchedRatio = 0) {
    this.heightAboveRatio = ratio;
    this.heightBelowRatio = switchedRatio;
    this.heightRatioSwitchPoint = switchPoint;
  }

  setWidthRange(min, max) {
    this.minWidth = min;
    this.maxWidth = max;
    if (this.minWidth > 0) { this.hasMinWidth = true; } else { this.hasMinWidth = false; }
    if (this.maxWidth > 0) { this.hasMaxWidth = true; } else { this.hasMaxWidth = false; }
  }

  setHeightRange(min, max) {
    this.minHeight = min;
    this.maxHeight = max;
    if (this.minHeight > 0) { this.hasMinHeight = true; } else { this.hasMinHeight = false; }
    if (this.minHeight > 0) { this.hasMaxHeight = true; } else { this.hasMaxHeight = false; }
  }

  findSize(parentWidth, parentHeight) {
    const W = parentWidth;
    const H = parentHeight;
    let x, y;
    if (W > this.widthRatioSwitchPoint) {
      x = W * this.widthAboveRatio;
    } else {
      x = W * this.widthBelowRatio;
    }
    if (this.hasMinWidth) { x = Math.max(x, this.minWidth); }
    if (this.hasMaxWidth) { x = Math.min(x, this.maxWidth); }


    if (H > this.heightRatioSwitchPoint) {
      y = H * this.heightAboveRatio;
    } else {
      y = H * this.heightBelowRatio;
    }
    if (this.hasMinHeight) { y = Math.max(y, this.minHeight); }
    if (this.hasMaxHeight) { y = Math.min(y, this.maxHeight); }

    return [x, y];
  }
}

/*
the top bar will take 50px at most
the bottom bar will take 60px at most
means the remaining regin will be (screenHeight - 110 px)

the wall foot should be at the bottom (bottom: 0px), and its height should be 60px + 2vh(for avatar). should calculate for "vh"
the wall top should be starting from top at: 50px + Xvh(X is for a nice looking gap), it's height should be a nice number that define a small area. better to be a vh value.

the drawing area

find a value for both
*/
export class WallPageSizer {
  constructor() {
    this.isHorizontal = true;

    this.gap1 = new SizeCalculator(); // the space on the top or on the left of the edge1
    this.gap2 = new SizeCalculator(); // the space on the bottom or on the right of the edge2

    this.edge1 = new SizeCalculator();
    this.edge2 = new SizeCalculator();

    this.shortEdge1 = new SizeCalculator();
    this.shortEdge2 = new SizeCalculator();


    this.topMenuBar = new SizeCalculator();
    this.bottomMenuBar = new SizeCalculator();
    this.marginTop = new SizeCalculator();
    this.marginBottom = new SizeCalculator()

    this.wallTopBar = new SizeCalculator();
    this.wallBottomBar = new SizeCalculator();
  }

  initializeDefault(isHorizontal = true) {
    this.isHorizontal = isHorizontal;

    this.gap1.px = 60;
    this.gap2.px = 60;

    this.edge1.vh = 2;
    this.edge2.vh = 2;
    this.shortEdge1.vh = 2;
    this.shortEdge2.vh = 2;
  }

  getSizes(parentMeasure) {
    this.gap1Size = this.gap1.getSize(parentMeasure);
    this.gap2Size = this.gap2.getSize(parentMeasure);
    this.edge1Size = this.edge1.getSize(parentMeasure);
    this.edge2Size = this.edge2.getSize(parentMeasure);
    this.shortEdge1Size = this.shortEdge1.getSize(parentMeasure);
    this.shortEdge2Size = this.shortEdge2.getSize(parentMeasure);

    this.corners1Size = {
      width: Math.ceil(this.isHorizontal? this.shortEdge1Size : this.edge1Size),
      height: Math.ceil(this.isHorizontal? this.edge1Size : this.shortEdge1Size),
    }

    this.corners2Size = {
      width: Math.ceil(this.isHorizontal? this.shortEdge2Size : this.edge2Size),
      height: Math.ceil(this.isHorizontal? this.edge2Size : this.shortEdge2Size),
    }

    this.edge1StartPx = this.gap1Size; // from top/left
    this.edge2EndPx = this.gap2Size; // from bottom/right
    if (this.isHorizontal) {
      this.canvasStartPx_x = Math.ceil(this.gap1Size + this.edge1Size);
      this.canvasStartPx_y = 0
    }
    this.canvasStartPx = Math.ceil(this.gap1Size + this.edge1Size); // from top/left
    this.drawingAreaSize = Math.ceil(parentMeasure - (this.gap1Size + this.gap2Size + this.edge1Size + this.edge2Size));

    const result = {
      gap1Size: this.gap1Size,
      gap2Size: this.gap2Size,

      edge1Size: this.edge1Size,
      edge2Size: this.edge2Size,

      edge1StartPx: this.edge1StartPx,
      edge2EndPx: this.edge2EndPx,

      shortEdge1Size: this.shortEdge1Size,
      shortEdge2Size: this.shortEdge2Size,
      corners1Size: this.corners1Size,
      corners2Size: this.corners2Size,

      canvasStartPx: this.canvasStartPx,
      canvasSize: this.drawingAreaSize,
    }

    return result;
  }

  getCurrentDrawingAreaStartPX() {
    return this.canvasStartPx;
  }
  getCanvasStartPx(gettingX = true) {
    if (gettingX) {
      return this.canvasStartPx_x;
    } else {
      return this.canvasStartPx_y;
    }
  }
}

export class SizeCalculator {
  constructor(px = 0, vh = 0) {
    this.px = 0;
    this.vh = 0;
  }

  getSize(parentMeasure) {
    return Math.ceil((this.vh / 100) * parentMeasure + this.px);
  }
}