import React, { useMemo, useRef, useEffect, useContext, useState } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { RectCollisionSystem } from '../system/collisionSystem/CollisionEngine';
import { CanvasManipulator } from "../system/CanvasManipulator.js";
import { FlexRect } from '../cross_page_components/FlexRectangles';
import { ImageProcessor } from '../utility/ImageProcessor.js';
import { FloatingCanvasReactionBar } from '../cross_page_components/paper/FCReactionBar.jsx';
import { IndieDrawingCanvas } from '../cross_page_components/IndieDrawingCanvas.jsx';
import { IndieDisplayCanvas } from '../cross_page_components/IndieDrawingCanvas.jsx';
import { FloatingCanvasText } from '../components/FloatingCanvasText.jsx';
import { FloatingCanvasRect } from '../system/PaperRects.js';
import { RequestDataMaker } from '../system/RestServerConnector.js';
import { FCMedia } from './papers/FcMediaDisplay.jsx';
import { RectPostCard } from './PostsCanvas.jsx';
import { PaperCover } from '../system/toolpack/Paper.js';
import { PaperChatBox } from './papers/PaperChatBox.jsx';
import { PaperLinker } from './papers/PaperLinker.jsx';
import { PaperJumpButton } from './papers/PaperJumpButton.jsx';

import Paper from "@mui/material/Paper";
import { styled } from '@mui/system';
import { tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/system';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import zIndex from '@mui/material/styles/zIndex';

const extensions = {
  'link': PaperLinker,
  'media': FCMedia,
  'post': RectPostCard,
  'jump': PaperJumpButton,
  'chat': PaperChatBox,
  'email': <div></div>,
};


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    maxWidth: 220,
  },
}));

const FloatCanvases = ({ fullPowerMode }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [rectsEditMode, setRectsEditMode] = useState(false);
  const [rectsSelectMode, setRectsSelectMode] = useState(system_operations.coordinator.toolpack.rectsSelectMode);

  const eventListeners = useRef({
    pointerDown: {},
    pointerMove: {},
    pointerUp: {},
    pointerEnter: {},
    pointerLeave: {},
    click: {},
  });

  // const [paperSelection, setPaperSelection] = useState(toolpack.paperSelectionArray); // get a set.
  const placed = useRef(false);
  const theme = useTheme();


  const [allCanvasesRects, setAllCanvasesRects] = useState(system_operations.coordinator.currentDisplayingCanvases)
  const newCanvasStyle = useRef({ x: 0, y: 0, width: 128, height: 128, borderRadius: 5 });
  const fenceByPassRule = useMemo(() => {
    return (fenceCollisionRect) => {
      const fence = fenceCollisionRect?.dataRef;
      if (!fence) {
        return false;
      }
      const allowAddingCanvas = system_operations.fenceCoder.isFlagSetByName(fence.rules, "addingCanvasOther");
      if (fence.creatorID === system_operations.getUserID() || allowAddingCanvas) {
        return true;
      } else {
        return false;
      }
    }
  }, []);

  useEffect(() => {
    const placeNewFloatCanvas = (extra) => {
      placed.current = true;
      let newCanvas = {
        x: newCanvasStyle.current.x, y: newCanvasStyle.current.y, width: newCanvasStyle.current.width, height: newCanvasStyle.current.height, style: {},
        layer: (extra.layer !== undefined && extra.layer >= 0) ? extra.layer : 0, creatorID: system_operations.getUserID(),
        canvasID: system_operations.coordinator.pendingCanvasSystem.getTempID(), text: "", showText: false, isAi: false,
        protectLevel: system_operations.coordinator.getUserRoleInWall().power, likes: 0,
      }
      const canvasRect = new FloatingCanvasRect(
        newCanvas, "canvasID", "floatingCanvases",
        system_operations.coordinator.sectionData.updateCanvasLoaderItemPosition.bind(system_operations.coordinator.sectionData),
        system_operations.coordinator.pendingCanvasSystem, system_operations
      );
      canvasRect.isNotUploaded = true;
      system_operations.coordinator.sectionData.insertNewItemToCanvasLoader(canvasRect);
      system_operations.coordinator.pendingCanvasSystem.addToPendingList(newCanvas.canvasID, canvasRect, "upload");
      system_operations.coordinator.toolpack.getToolByName("fc").setMode(0);
    }

    const startCreationMode = () => {
      placed.current = false;
      newCanvasStyle.current = { x: 0, y: 0, width: 128, height: 128, borderRadius: 5 };
      system_operations.coordinator.guideBox.setActive(newCanvasStyle.current, (x, y) => {
        if (!placed.current) { newCanvasStyle.current.x = x; newCanvasStyle.current.y = y }
      }, placeNewFloatCanvas,
        system_operations.coordinator.floatingCollisionSystems,
        system_operations.coordinator.fenceLoader.fenceCollisionSystem, fenceByPassRule);
    }

    const startEditingMode = () => {
      system_operations.coordinator.guideBox.setActive(false);
      setRectsEditMode(true);
    }

    const fcEditor = system_operations.coordinator.toolpack.getToolByName("fc");
    fcEditor.startCreationHook = startCreationMode;
    fcEditor.startEditingHook = startEditingMode;

    const checkEditable = () => {
      const newTool = system_operations.coordinator.toolpack.get_current_tool();
      // system_operations.coordinator.toolpack.rectsSelectMode
      if (newTool.name === "fc") {
        newTool.setMode(0);
        setRectsEditMode(true);
      } else {
        setRectsEditMode(false);
      }
    }
    system_operations.coordinator.onCanvasesChange["fromFloatCanvases"] = setAllCanvasesRects;
    system_operations.coordinator.toolpack.onToolChange["fromFloatCanvases"] = checkEditable;
    system_operations.coordinator.toolpack.onRectsSelectModeChange["fromFloatCanvases"] = setRectsSelectMode;


    const onPointerDown = (event) => {
      const element = event.target;
      if (element.hasAttribute('data-pp-id')) {
        const paperPart = element.getAttribute('data-pp-id');
        if (paperPart in eventListeners.current.pointerDown) {
          eventListeners.current.pointerDown[paperPart](event);
        }
      }
    }

    const selectPaper = (paperID) => {
      const canvasRect = system_operations.coordinator.getPaperRectFromID(paperID);
      if (canvasRect) {
        const currentTool = system_operations.coordinator.toolpack.get_current_tool();
        if (currentTool.normalHandBehavior) {
          system_operations.coordinator.toolpack.setPaperSelection({ [paperID]: canvasRect });
        } else if (currentTool.isType1PaperEditor) {
          if (system_operations.coordinator.toolpack.multiSelection) {
            system_operations.coordinator.toolpack.addToPaperSelection(canvasRect);
          } else {
            system_operations.coordinator.toolpack.setPaperSelection({ [paperID]: canvasRect });
          }
        }
      }
    }
    const onClick = (event) => {
      let element = event.target;
      if (element.hasAttribute('data-pp-id')) {
        const paperPart = element.getAttribute('data-pp-id');
        if (paperPart in eventListeners.current.click) {
          eventListeners.current.click[paperPart](event);
        }
      }

      let pauseCount = 10;
      while (element && !element.hasAttribute('data-paper-id') && pauseCount > 0) {
        element = element.parentElement;
        pauseCount -= 1;
      }
      // console.log("found at ", 10 - pauseCount);

      if (element && element.hasAttribute('data-paper-id')) {
        const paperID = element.getAttribute('data-paper-id');
        selectPaper(paperID);
      }
    }

    window.addEventListener('pointerdown', onPointerDown);
    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('pointerdown', onPointerDown);
      window.removeEventListener('click', onClick);

      delete system_operations.coordinator.onCanvasesChange["fromFloatCanvases"];
      delete system_operations.coordinator.onTheoreticalSpaceToScreenSpaceScaleChange["fromFloatCanvases"];
      delete system_operations.coordinator.toolpack.onToolChange["fromFloatCanvases"];
      delete system_operations.coordinator.toolpack.onRectsSelectModeChange["fromFloatCanvases"];
    }
  }, []);

  return (
    <div style={{ zIndex: theme.zIndex.paper }}>
      {allCanvasesRects.map((canvasRect, index) => {
        return (
          <FloatCanvasCoreWrapper
            canvasRect={canvasRect}
            fullPowerMode={fullPowerMode}
            rectsEditMode={rectsEditMode} rectsSelectMode={rectsSelectMode}
            fenceByPassRule={fenceByPassRule}
            eventListeners={eventListeners.current}
            index={index} theme={theme}
            key={canvasRect.getID()}
          />
        )
      })}
      {rectsSelectMode &&
        <SelectionBox />
      }
    </div >
  );
}


const nullFunc = () => { }
const FloatCanvasCoreWrapper = ({ canvasRect, fullPowerMode, rectsEditMode, rectsSelectMode, fenceByPassRule, eventListeners, index, theme }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const paperID = canvasRect.getID();

  const [interactable, setInteractable] = useState(canvasRect.interactable); // is this rect going to block the click and other pointer events.
  const idTagName = useMemo(() => { return `data-fc-${index}` }, [index]);

  const [toolbarPresistData, setToolbarPresistData] = useState({});
  const [layer, setLayer] = useState(canvasRect.getActiveLayer());
  const [disabled, setDisabled] = useState(canvasRect.disabled);
  const tipbarRef = useRef(null);
  const containerRef = useRef(null);
  const backgroundImageUploadRef = useRef(null);
  const colliderCollections = useRef(system_operations.coordinator.getPaperCollectionSet(layer, fenceByPassRule, "floatingCanvases"));

  const refreshCounter = useRef(0);
  const [refresh, setRefresh] = useState(0);
  const [selected, setSelected] = useState(paperID in system_operations.coordinator.toolpack.paperSelections);

  useEffect(() => {
    const updateSelection = (selections, selectionArray) => {
      setSelected(paperID in selections); // selected if in the selections.
    }

    system_operations.coordinator.toolpack.onPaperSelectionsChange[paperID] = updateSelection; // update the current selections.
    return () => {
      delete system_operations.coordinator.toolpack.onPaperSelectionsChange[paperID];
    }
  }, []);

  const rectEditable = (canvas) => {
    if (rectsEditMode && system_operations.getUserID() === canvas.creatorID) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    canvasRect.onDisableStateChange["self"] = (isDisabled) => {
      setDisabled(isDisabled); // set...
    }
    canvasRect.func.styleRefreshPaperFullComponent = () => {
      refreshCounter.current += 1;
      setRefresh(refreshCounter.current);
    }

    canvasRect.onLayerChange["fcBase"] = (layer) => {
      layer = Math.max(Math.min(layer, system_operations.coordinator.totalFloatingLayers - 1), 0);
      setLayer(layer);
    }

    // if (containerRef.current) {
    //   containerRef.current.addEventListener("pointerup", pointerUp);
    // }
    return () => {
      delete canvasRect.func.styleRefreshPaperFullComponent;
      delete canvasRect.onLayerChange["fcBase"];
      delete eventListeners.click;
      // if (containerRef.current) {
      //   containerRef.current.addEventListener("pointerup", pointerUp);
      // }
    }
  }, [canvasRect])

  const focusOnCanvas = () => {
    if (canvasRect.func.focusOnCanvas) {
      canvasRect.func.focusOnCanvas();
    }
  }

  canvasRect.func.setInteractable = (allow = true) => {
    canvasRect.interactable = allow;
    setInteractable(allow);
  }

  // UPLOADBACKGROUNDIMAGE
  const [uploadBackgroundImage, clearBackgroundImage] = useMemo(() => {
    const canvasData = canvasRect.getData();
    const uploadBackgroundImage = (e) => {
      system_operations.set_loading(true);
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = function () {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        RequestDataMaker.dataToUpdateFloatingCanvas(system_operations, canvasData.canvasID, {
          backgroundImage: base64String,
          backgroundSize: "cover",
        }).then((dataJson) => {
          const jsonStr = JSON.stringify(dataJson);
          // const sizeInCharacters = jsonStr.length;
          system_operations.restServerConnector.requestToUpdateFloatingCanvasBackgroundImage(dataJson).then(
            (result) => {
              if (result && result.success) {
                if (result.backgroundImage) {
                  canvasData.style.backgroundImage = result.backgroundImage;
                };
                canvasData.style.backgroundSize = "cover";
                if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
              }
              system_operations.set_loading(false);
            })
        })
      };
      // Read the file as a Base64-encoded string
      reader.readAsDataURL(file);
    }
    const clearBackgroundImage = async () => {
      system_operations.set_loading(true);
      const dataJson = await RequestDataMaker.dataToUpdateFloatingCanvas(system_operations, canvasData.canvasID, {
        backgroundImage: "",
        backgroundSize: "cover",
      });
      const result = await system_operations.restServerConnector.requestToUpdateFloatingCanvasBackgroundImage(dataJson);
      if (result && result.success) {
        canvasData.style.backgroundImage = "";
        canvasData.style.backgroundSize = "cover"
        if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
      }
      system_operations.set_loading(false);
    }
    return [uploadBackgroundImage, clearBackgroundImage];
  }, []);

  useEffect(() => {
    setToolbarPresistData({
      backgroundImageUploadRef: backgroundImageUploadRef,
      clearBackgroundImage: clearBackgroundImage,
    })
  }, [])
  // if is in edit mode, and if user has the permission to edit
  const editAble = rectEditable(canvasRect.getData());
  // const textInteractable = !editAble && system_operations.coordinator.toolpack.get_current_tool().normalHandBehavior;
  // const collisions = system_operations.coordinator.getPaperCollectionSet(layer, fenceByPassRule, "floatingCanvases");

  // do not render anything if the rect is disabled. This will makes FlexRect disappear so it will remove itself from the collider collections as well.
  return (
    <div data-paper-id={paperID} ref={containerRef}>
      {!disabled &&
        <>
          <FlexRect
            dataRect={canvasRect}
            lookStyle={null}
            defaultCollisions={colliderCollections.current}
            colliderTrackerCollections={[system_operations.coordinator.allPaperColliderCollection]}
            zIndex={theme.zIndex.paper[layer]} activeDz={theme.zIndex.paper_dz.dp_1} inactiveDz={theme.zIndex.paper_dz.dp_0}
            eventListeners={eventListeners}
            flexBoxID={`r_${paperID}`}
            editable={editAble && !rectsSelectMode}
            interactable={interactable}
            maxWidth={system_operations.coordinator.isHorizontal ? 3072 : system_operations.coordinator.theoreticalBoardWidth}
            maxHeight={system_operations.coordinator.isHorizontal ? system_operations.coordinator.theoreticalBoardHeight : 3072}
            type={"canvas"}
            getAllMagnetRects={() => { return system_operations.coordinator.getAllCurrentBasicPapers() }}
            key={paperID}>
            <div>
              {/*<div style={{position: "absolute", width: `100%`, height: `100%`, backgroundColor: "red"}}></div>*/}
              <FloatCanvasCore canvasRect={canvasRect} fullPowerMode={fullPowerMode} editAble={editAble} editMode={rectsEditMode} layer={layer} selected={selected} version={canvasRect.version} />
              <input type="file" accept="image/*" id="uploadBackground" ref={backgroundImageUploadRef} onChange={(e) => { uploadBackgroundImage(e) }} style={{ display: "none" }} />
            </div>
          </FlexRect>
          {canvasRect.getData().showUnderText &&
            <ReactQuill
              className="quill-under-text"
              theme="snow"
              value={canvasRect.getData().underText}
              readOnly={true}
              modules={{
                toolbar: false, // Disable toolbar
              }}
              style={{
                position: "absolute", left: canvasRect.getActiveX(), top: `${canvasRect.getActiveY() + canvasRect.getActiveHeight()}px`, width: canvasRect.getActiveWidth(),
                zIndex: theme.zIndex.paperUnderText[layer], border: "none", padding: "0px",
                // userSelect: textInteractable? "auto" : "none", pointerEvents: textInteractable? "auto" : "none",
                userSelect: "none", pointerEvents: "none",
              }} // Set the size of the editor
            />}
        </>
      }
    </div>
  );
}

const FloatCanvasCore = ({ canvasRect, fullPowerMode, editAble, editMode, layer, selected = false, version = 0 }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [drawFlag, setDrawFlag] = useState(false);
  const canvasLoaded = useRef(false);
  const [baseSize, setBaseSize] = useState([canvasRect.getActiveWidth(), canvasRect.getActiveHeight()]); // before resize, how large is this. this should not be changed as far as I concern. It's for image display size.
  const [blocked, setBlocked] = useState(false);
  const [interactWithMedia, setInteractWithMedia] = useState(
    (system_operations.coordinator.toolpack.get_current_tool().normalHandBehavior && system_operations.coordinator.toolpack.get_current_tool().handMode === 0) ? true : false
  );

  const refreshCounter = useRef(0);
  const [refresh, setRefresh] = useState(0);
  const [isWarning, setIsWarning] = useState(false);

  const fcDrawInitialized = useRef(false);
  // const fcDraw = useRef(new FloatingCanvas(system_operations));
  const isDrawing = useRef(false);
  const canvasRef = useRef(null);

  const theme = useTheme();
  const canvasData = canvasRect.getData();

  const triggerWarning = () => {
    setIsWarning(true);
    setTimeout(() => setIsWarning(false), 500); // Duration of the flash in milliseconds
  };

  useEffect(() => {
    if (isWarning) {
      setTimeout(() => setIsWarning(false), 500); // Duration of the flash staying red in milliseconds
    }
  }, [isWarning]);

  canvasRect.func.triggerWarning = triggerWarning;

  useEffect(() => {
    console.log(fullPowerMode);
    if (fullPowerMode) {
      if (!canvasLoaded.current && canvasRef.current && canvasData.imageUrl) {
        system_operations.imageProcessor.loadImageUrlToCanvas(canvasData.imageUrl, canvasRef.current.getContext("2d"), true).then(() => {
          canvasLoaded.current = true;
        })
      } else {
        canvasLoaded.current = true;
      }
    }
  }, [fullPowerMode])

  useEffect(() => {
    const canvasID = canvasRect.getID();
    if (!fcDrawInitialized.current) {
      canvasRect.utilities.canvas = canvasRef.current;
      fcDrawInitialized.current = true;
    }

    canvasRect.utilities.canvas = canvasRef.current;

    canvasRect.func.resizeCanvas = (width, height) => {
      ImageProcessor.static_resizeCanvas(canvasRef.current, width, height);
    }
    canvasRect.func.focusOnCanvas = () => {
      setBlocked(true);
      if (system_operations.getUserID() === canvasRect.getData().creatorID) {
        system_operations.setContentWindow((<IndieDrawingCanvas canvasRect={canvasRect} editAble={editAble} editMode={editMode} originalCanvas={canvasRef.current} setBlockTheOriginalFC={setBlocked} />));
      } else {
        system_operations.setContentWindow((<IndieDisplayCanvas canvasRect={canvasRect} originalCanvas={canvasRef.current} />));
      }
    }
    canvasRect.func.styleRefreshCanvasCore = () => {
      refreshCounter.current += 1;
      setRefresh(refreshCounter.current);
    }
    canvasRect.func.getEventCoords = (x, y) => {
      if (!canvasRef.current) return [0, 0];
      const rect = canvasRef.current.getBoundingClientRect();
      const scaleX = canvasRef.current.width / rect.width;
      const scaleY = canvasRef.current.height / rect.height;
      const coordX = (x - rect.left) * scaleX;
      const coordY = (y - rect.top) * scaleY;
      return [coordX, coordY];
    }

    const checkInteractingWithMedia = (tool) => {
      if (tool.normalHandBehavior && tool.handMode === 0) {
        // if using hand, then we are interacting with media.
        setInteractWithMedia(true);
      } else {
        setInteractWithMedia(false);
      }
    }

    system_operations.coordinator.toolpack.hand.onHandModeChange[canvasID] = (newMode) => {
      checkInteractingWithMedia(system_operations.coordinator.toolpack.get_current_tool());
    }
    system_operations.coordinator.toolpack.onToolChange[canvasID] = checkInteractingWithMedia;

    return () => {
      delete canvasRect.func.resizeCanvas;
      delete canvasRect.func.focusOnCanvas;
      delete canvasRect.func.styleRefreshCanvasCore;
      delete canvasRect.func.getEventCoords;
      delete system_operations.coordinator?.toolpack?.onToolChange[canvasID];
      delete system_operations.coordinator?.toolpack?.hand.onHandModeChange[canvasID];
    }
  }, [canvasRect])


  useEffect(() => {
    return () => { };
  }, [drawFlag, blocked])


  const paperStyle = {
    position: "absolute", width: `100%`, height: `100%`, userSelect: "none", zIndex: theme.zIndex.paper_dz.m_1,
    boxShadow: canvasRect.getActiveStyle("boxShadow") || theme.flexRects.boxShadowTiers[0],
    borderRadius: `${canvasRect.getActiveStyle("borderRadius") || theme.flexRects.borderRadiusTiers[1]}px`,
    backgroundColor: canvasRect.getActiveStyle("backgroundColor") || "white", overflow: "hidden",
  }
  const bk = canvasRect.getActiveStyle("backgroundImage");
  if (bk) {
    paperStyle.backgroundImage = `url(${bk})`;
    paperStyle.backgroundSize = 'cover';
    paperStyle.backgroundPosition = 'center';
    paperStyle.backgroundRepeat = 'no-repeat';
  }

  const paperCoverOn = interactWithMedia && Boolean(canvasData.extension);// no cover if in things like draw mode

  let Extension = canvasData.extension ? (extensions[canvasData.extension.type] || null) : null;

  return (
    <div style={{ zIndex: theme.zIndex.paper_dz.m_0 }}>
      {editMode && // in editmode, this thing showup and blocks the rest of content.
        (editAble ? (<div style={{
          position: "absolute", width: `100%`, height: `100%`, userSelect: "none", zIndex: theme.zIndex.paper_dz.m_4,
          border: `${selected ? 7 : 5}px ${selected ? "solid" : "dashed"} ${theme.flexRects.flexRectBoarderColorsAllow[layer]}`, borderRadius: paperStyle.borderRadius, boxSizing: 'border-box'
        }} />) : (<div style={{
          position: "absolute", width: `100%`, height: `100%`, userSelect: "none", zIndex: theme.zIndex.paper_dz.m_4,
          border: `${selected ? 7 : 5}px ${selected ? "solid" : "dashed"} ${theme.flexRects.flexRectBoarderColorsDisallow[layer]}`, borderRadius: paperStyle.borderRadius, boxSizing: 'border-box'
        }} />))
      }
      <Paper sx={paperStyle}>
        <>
          {Extension && <Extension {...{ paperRect: canvasRect, interactWithMedia: interactWithMedia }} />}
          <div style={{ pointerEvents: interactWithMedia ? "none" : "auto" }}>
            { !fullPowerMode && <img src={canvasData.imageUrl} style={{ position: "absolute", left: "0px", top: "0px", }} /> }
            <canvas ref={canvasRef} width={canvasRect.getActiveWidth()} height={canvasRect.getActiveHeight()} style={{ position: "absolute", left: "0px", top: "0px", width: `100%`, height: `100%` }} />
            {canvasData.showText &&
              <ReactQuill
                theme="snow"
                value={canvasData.text}
                readOnly={true}
                modules={{
                  toolbar: false, // Disable toolbar
                }}
                style={{ position: "absolute", left: 0, top: 0, height: "100%", width: "100%", userSelect: "none", zIndex: theme.zIndex.paper_dz.m_2 }} // Set the size of the editor
              />
            }
            <div data-canvas-id={canvasData.canvasID}
              style={{
                position: "absolute", left: 0, top: 0, width: "100%", height: "100%",
                backgroundColor: isWarning ? "rgba(255,0,0,0.4)" : "transparent",
                transition: 'background-color 0.5s ease-in-out',
                zIndex: theme.zIndex.paper_dz.m_3,
              }} />
          </div>
        </>
        {
          paperCoverOn &&
          <PaperCover rect={canvasRect} selected={selected} />
        }
      </Paper>
    </div>
  )
}

const SelectionBox = () => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [startPos, setStartPos] = useState([0, 0]);
  const [endPos, setEndPos] = useState([0, 0]);
  const isPointerDown = useRef(false);
  const addingSelectionFlag = useRef(false);
  const [renderBox, setRenderBox] = useState(false);

  const theme = useTheme();

  const handlePointerDown = (event) => {
    event.target.setPointerCapture(event.pointerId);
    const pos = system_operations.coordinator.boardPositioner.getTheoPositionFromScreenPosition(event.clientX, event.clientY, true);
    setStartPos(pos);
    setEndPos(pos);
    isPointerDown.current = true;
    setRenderBox(true);
  };

  const handlePointerMove = (event) => {
    if (isPointerDown.current) {
      const pos = system_operations.coordinator.boardPositioner.getTheoPositionFromScreenPosition(event.clientX, event.clientY, true);
      setEndPos(pos);
    }
  };

  const handlePointerUp = (event) => {
    event.target.releasePointerCapture(event.pointerId);
    isPointerDown.current = false;
    addingSelectionFlag.current = true;
    setRenderBox(false);
  };

  useEffect(() => {
    window.addEventListener('pointerdown', handlePointerDown);
    window.addEventListener('pointermove', handlePointerMove);
    window.addEventListener('pointerup', handlePointerUp);

    return () => {
      window.removeEventListener('pointerdown', handlePointerDown);
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    };
  }, []);

  const left = Math.min(startPos[0], endPos[0]);
  const top = Math.min(startPos[1], endPos[1]);
  const width = Math.abs(startPos[0] - endPos[0]);
  const height = Math.abs(startPos[1] - endPos[1]);

  if (addingSelectionFlag.current) {
    addingSelectionFlag.current = false;
    const coveredPaper = system_operations.coordinator.allPaperColliderCollection.checkCollision(left, top, width, height);
    system_operations.coordinator.toolpack.addToPaperSelection_collisionArray(coveredPaper); // add all covered to selection.
  }

  if (!renderBox) {
    return null;
  }

  return (
    <div style={{
      position: "absolute", left: left, top: top, width: width, height: height,
      backgroundColor: theme.shadowBox.selectionBox, zIndex: theme.zIndex.paperSelection,
    }}>
    </div>
  );
};

export default FloatCanvases;