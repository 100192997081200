import React, { useRef, useState, useEffect, useMemo } from "react";
import { BB } from '../../../bb/bb';
import { renderText } from '../../image-operations/render-text';
import { ColorOptions } from '../components/color-options';
import { Select } from '../components/select';
import { ImageRadioList } from '../components/image-radio-list';
import { ImageToggle } from '../components/image-toggle';
import { KlSlider } from '../components/kl-slider';
import { showModal } from './base/showModal';
import alignLeftImg from '../../../../img/ui/align-left.svg';
import alignCenterImg from '../../../../img/ui/align-center.svg';
import alignRightImg from '../../../../img/ui/align-right.svg';
import typoItalicImg from '../../../../img/ui/typo-italic.svg';
import typoBoldImg from '../../../../img/ui/typo-bold.svg';
import toolZoomInImg from '../../../../img/ui/tool-zoom-in.svg';
import toolZoomOutImg from '../../../../img/ui/tool-zoom-out.svg';
import { IRGB } from '../../kl-types';
import { KlCanvas } from '../../canvas/kl-canvas';
import { LANG } from '../../../language/language';
import { throwIfNull } from '../../../bb/base/base';
// import { theme } from '../../../theme/theme';

// LINKED COMPONENT WITH TextToolDialogBone.ts

/*
params: {
        baseCanvas: HTMLCanvasElement,
        x: number;
        y: number;
        angleRad: number;
        color: IRGB;
        secondaryColor: IRGB;
        size: number; // px
        align: TTextFormat; // default 'left'
        isBold: boolean; // default false
        isItalic: boolean; // default false
        font: TTextFont; // default sans-serif
        opacity: number; // [0, 1] default 1
        updateCanvasBound: () => void,
        onConfirm: (confirmP: TTextToolResult) => void;
        onCancel: () => void;
    }
*/
// this is used in the contextWindow.
export const TextPrompt = ({ params }) => {
  const getCanvasData = useMemo(() => {
    return () => {
      const isSmallWidth = window.innerWidth < 550;
      const isSmallHeight = window.innerHeight < 630;
      const width = isSmallWidth ? 340 : Math.min(window.innerWidth - 200, 1024);
      const height = isSmallWidth ? (isSmallHeight ? 210 : 260) : (isSmallHeight ? 230 : Math.max(Math.min(window.innerHeight / 2, 768), 360));

      // const layerArr = p.klCanvas.getLayersFast();
      const textCanvas = BB.canvas(params.baseCanvas.width, params.baseCanvas.height); // this is to create a normal canvas.
      const textCtx = BB.ctx(textCanvas);                                          // this is to get the normal ctx.
      const targetCanvas = BB.canvas(width, height);
      const targetCtx = BB.ctx(targetCanvas);
      const layersCanvas = BB.canvas(width, height);
      const layersCtx = BB.ctx(layersCanvas);

      const unitCanvas = document.createElement("canvas");
      unitCanvas.width = 1;
      unitCanvas.height = 1;
      const unitCtx = unitCanvas.getContext("2d");
      unitCtx.fillStyle = "white";
      unitCtx.fillRect(0, 0, 1, 1)
      // const previewCanvas = BB.canvas(width, height); // the one that is visible
      // const previewCtx = BB.ctx(previewCanvas);
      return {
        textCanvas, textCtx, targetCanvas, targetCtx, layersCanvas, layersCtx, unitCanvas, unitCtx, width, height, scale: 1,
      }
    }
  }, [])
  const canvasData = useRef(getCanvasData());
  const previewCanvas = useRef(null);
  const textBoxRef = useRef(null);
  const avaliableTextFont = useRef([
    ['sans-serif', 'Sans-serif'],
    ['serif', 'Serif'],
    ['monospace', 'Monospace'],
    ['cursive', 'Cursive'],
    ['fantasy', 'Fantasy'],
  ]);
  const zoomFac = useRef(1);

  const [x, setX] = useState(params.x);
  const [y, setY] = useState(params.y);
  const [color, setColor] = useState(params.color);
  const [angleRad, setAngleRad] = useState(params.angleRad);
  const [size, setSize] = useState(params.size);
  const [align, setAlign] = useState(params.align);
  const [isBold, setIsBold] = useState(params.isBold);
  const [isItalic, setIsItalic] = useState(params.isItalic);
  const [font, setFont] = useState(params.font);
  const [opacity, setOpacity] = useState(params.opacity);
  const [text, setText] = useState("");

  // after set the hook, also update settings. this will be used when render the text.
  const settings = useRef({ ...params, text, size, });
  const boxBoundingParams = useRef({
    pivotGlobalX: 0,
    pivotGlobalY: 0,
    pivotRatioX: 0,
    pivotRatioY: 0,
    angleRad: 0,
    width: 0,
    height: 0,
  });


  const updatePreview = useMemo(() => {
    /* p: {
      textCanvas, textCtx,
      targetCanvas, targetCtx,
      layersCanvas, layersCtx,
      previewCanvas, previewCtx,
      scale,
    }*/
    const updatePreview = (p) => {
      let scale = canvasData.current.scale;
      const width = canvasData.current.width;
      const height = canvasData.current.height;

      const textCanvas = canvasData.current.textCanvas;
      const textCtx = canvasData.current.textCtx;
      const targetCanvas = canvasData.current.targetCanvas;
      const targetCtx = canvasData.current.targetCtx;
      const layersCanvas = canvasData.current.layersCanvas;
      const layersCtx = canvasData.current.layersCtx;
      const unitCanvas = canvasData.current.unitCanvas;
      const unitCtx = canvasData.current.unitCtx;
      const previewCanvas = canvasData.current.previewCanvas;
      const previewCtx = canvasData.current.previewCtx;

      const angleRad = settings.current.angleRad;

      // --- draw text ---
      textCtx.clearRect(0, 0, textCanvas.width, textCanvas.height);
      const colorRGBA = {
        ...settings.current.color,
        a: settings.current.opacity,
      };
      // console.log(settings.current.size, "this is size.");
      const bounds = renderText(textCanvas, {
        x: settings.current.x,
        y: settings.current.y,
        textStr: settings.current.text,
        align: settings.current.align,
        isItalic: settings.current.isItalic,
        isBold: settings.current.isBold,
        size: parseFloat(settings.current.size),
        font: settings.current.font,
        color: BB.ColorConverter.toRgbaStr(colorRGBA),
        angleRad: settings.current.angleRad,
      });
      // bounds.x and bound.y is where the text pivot is.

      // --- determine transformation of viewport ---
      // text should always be visible
      bounds.width = Math.max(bounds.width, 1);
      bounds.height = Math.max(bounds.height, 1);
      const rotatedXY = BB.rotate(bounds.x, bounds.y, -angleRad / Math.PI * 180);
      const rotatedWH = BB.rotate(bounds.width, bounds.height, -angleRad / Math.PI * 180);
      const centerX = settings.current.x + rotatedXY.x + rotatedWH.x / 2;
      const centerY = settings.current.y + rotatedXY.y + rotatedWH.y / 2;

      const cornorX = settings.current.x + bounds.x;
      const cornorY = settings.current.y + bounds.y;
      const pivotRatioX = -bounds.x / bounds.width;
      const pivotRatioY = -bounds.y / bounds.height;
      // console.log(pivotGlobalX, pivotGlobalY, pivotRatioX, pivotRatioY);

      boxBoundingParams.current = {
        pivotGlobalX: settings.current.x,
        pivotGlobalY: settings.current.y,
        pivotRatioX: pivotRatioX,
        pivotRatioY: pivotRatioY,
        angleRad: settings.current.angleRad,
        width: bounds.width,
        height: bounds.height,
      }
      // work when degree = 0;
      // const pivotPxFromTop = (settings.current.y - centerY)+bounds.height/2
      // const pivotPxPercentage = pivotPxFromTop / bounds.height;

      const padding = 100;
      // console.log(scale);
      const fitBounds = BB.fitInto(bounds.width, bounds.height, width - padding, height - padding);
      // console.log(scale);
      scale = Math.min(1, fitBounds.width / bounds.width);
      scale = Math.min(4, scale * Math.pow(2, zoomFac.current));
      // console.log(bounds, fitBounds, zoomFac.current, scale);
      // console.log(scale)
      // --- compose text and target layer ---
      targetCtx.save();

      if (scale >= 4) {
        targetCtx.imageSmoothingEnabled = false;
      } else {
        targetCtx.imageSmoothingEnabled = true;
        targetCtx.imageSmoothingQuality = scale >= 1 ? 'low' : 'medium';
      }

      targetCtx.clearRect(0, 0, previewCanvas.width, previewCanvas.height);
      targetCtx.translate(width / 2, height / 2);
      targetCtx.scale(scale, scale);
      targetCtx.rotate(angleRad);
      targetCtx.drawImage(settings.current.baseCanvas, -centerX, -centerY);
      targetCtx.drawImage(textCanvas, -centerX, -centerY);
      targetCtx.restore();
      // console.log(targetCtx.canvas.toDataURL(), " target canvas");

      const isDark = true; // theme.isDark();
      let checkerPattern = previewCtx.createPattern(BB.createCheckerCanvas(8, isDark), 'repeat');
      const emptyCanvas = BB.canvas(1, 1);
      const emptyCanvasLight = BB.canvas(1, 1);

      // --- layers ---
      layersCtx.save();
      layersCtx.fillStyle = isDark ? 'rgb(33,33,33)' : 'rgb(158,158,158)';
      layersCtx.fillRect(0, 0, width, height);

      { // bg
        layersCtx.save();

        layersCtx.translate(width / 2, height / 2);
        layersCtx.scale(scale, scale);
        layersCtx.rotate(angleRad);

        layersCtx.imageSmoothingEnabled = false;

        //outline
        const borderSize = 1 / scale;
        layersCtx.globalAlpha = isDark ? 0.25 : 0.2;
        layersCtx.drawImage(
          isDark ? emptyCanvasLight : emptyCanvas,
          -centerX - borderSize,
          -centerY - borderSize,
          textCanvas.width + borderSize * 2,
          textCanvas.height + borderSize * 2,
        );
        layersCtx.globalAlpha = 1;

        //erase
        layersCtx.globalCompositeOperation = 'destination-out';
        // layersCtx.drawImage(emptyCanvas, -centerX, -centerY, textCanvas.width, textCanvas.height);
        layersCtx.drawImage(unitCanvas, -centerX, -centerY, textCanvas.width, textCanvas.height);
        layersCtx.restore();
      }

      { // individual layers
        if (scale >= 4) {
          layersCtx.imageSmoothingEnabled = false;
        } else {
          layersCtx.imageSmoothingEnabled = true;
          layersCtx.imageSmoothingQuality = scale >= 1 ? 'low' : 'medium';
        }

        // originally, deal with multilayers, however, as for draw api, just handle one layer which is the target canvas, then we don't need to stack all canvas layers.
        // target layer
        layersCtx.drawImage(targetCanvas, 0, 0);
      }

      // --- final composite ---
      previewCtx.save();
      previewCtx.fillStyle = checkerPattern;
      previewCtx.fillRect(0, 0, previewCanvas.width, previewCanvas.height);
      // console.log(previewCanvas.toDataURL());
      previewCtx.drawImage(layersCanvas, 0, 0);
      // previewCtx.drawImage(targetCanvas, 0, 0);
      previewCtx.restore();

      // bounds
      previewCtx.save();
      previewCtx.globalCompositeOperation = 'difference';
      previewCtx.strokeStyle = '#fff';
      previewCtx.lineWidth = 1;
      // centerX = p.x + bounds.x + bounds.width / 2;
      // centerY = p.y + bounds.y + bounds.height / 2;
      previewCtx.strokeRect(
        Math.round(width / 2 - (bounds.width / 2) * scale),
        Math.round(height / 2 - (bounds.height / 2) * scale),
        Math.round(bounds.width * scale),
        Math.round(bounds.height * scale)
      );
      previewCtx.restore();
    }
    return updatePreview;
  }, [])



  /* 
  const [secondaryColor, setSecondaryColor] = useState(params.secondaryColor);
  const [onConfirm, setOnConfirm] = useState(params.onConfirm); */


  useEffect(() => {
    let scale = 1;
    canvasData.current.scale = scale;
    canvasData.current.previewCanvas = previewCanvas.current;
    canvasData.current.previewCtx = previewCanvas.current.getContext("2d"); // if move to ts, then we might need to initialize this here, or put updatePreview function inside the body.

    function move(x, y) {
      const rotated = BB.rotate(x, y, -angleRad / Math.PI * 180);
      settings.current.x += rotated.x / scale;
      settings.current.y += rotated.y / scale;
      updatePreview(settings.current);
    }

    const previewPointerListener = new BB.PointerListener({
      target: previewCanvas.current,
      onPointer: (e) => {
        if (e.type === 'pointermove' && e.button) {
          e.eventPreventDefault();
          move(-e.dX, -e.dY);
        }
      },
      onWheel: (e) => {
        changeZoomFac(-e.deltaY);
      },
    });

    /* const sizePointerListener = new BB.PointerListener({
      target: sizeInput,
      onWheel: (e) => {
        sizeInput.value = '' + Math.max(1, Math.min(1000, parseInt(sizeInput.value) - e.deltaY));
        updatePreview(settings.current);
      },
    }); */

    /* const fontPointerListener = new BB.PointerListener({
      target: fontSelect.getElement(),
      onWheel: (e) => fontSelect.setDeltaValue(e.deltaY),
    }); */

    const wheelPrevent = (event) => event.preventDefault();
    previewCanvas.current.addEventListener('wheel', wheelPrevent);


    function changeZoomFac(d) {
      zoomFac.current = Math.min(2, Math.max(-2, zoomFac.current + d));
      updatePreview(settings.current);
      // zoomInBtn.disabled = !canZoom(1);    todo
      // zoomOutBtn.disabled = !canZoom(-1);    todo
    }
    function canZoom(d) {
      return zoomFac.current !== Math.min(2, Math.max(-2, zoomFac.current + d));
    }

    function onScroll() {
      window.scrollTo(0, 0);
    }
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      previewPointerListener?.destroy();
      previewCanvas?.current?.removeEventListener('wheel', wheelPrevent);
      // sizePointerListener.destroy();
      // fontPointerListener.destroy();
      // theme.removeIsDarkListener(updatePreview);
      // textInput.removeEventListener('input', updatePreview);
      // BB.destroyEl(textInput);
      /* BB.destroyEl(previewWrapper);
      rotationSlider.destroy();
      BB.destroyEl(zoomInBtn);
      BB.destroyEl(zoomOutBtn);
      BB.destroyEl(sizeInput);
      colorOptions.destroy();
      fontSelect.destroy();
      keyListener.destroy();
      alignRadioList.destroy();
      italicToggle.destroy();
      boldToggle.destroy();
      opacitySlider.destroy();
      // theme.removeIsDarkListener(updateCheckerboard); */
      // if (val === 'Ok') {
      // p.onConfirm(result);
      // }
    }

  }, [params])

  useEffect(() => {
    console.log("rerender....");
    updatePreview(settings.current);
  }, [x, y, text, color, angleRad, size, align, isBold, isItalic, font, opacity])


  const updateText = (e) => {
    const textValue = e.target.value;
    settings.current.text = textValue;
    setText(textValue);
  }

  const onOpacityChange = (value) => {
    settings.current.opacity = value / 100;
    setOpacity(value / 100);
  }

  const onAngleRadChange = (value) => {
    settings.current.angleRad = value * (Math.PI / 180);
    setAngleRad(value * (Math.PI / 180));
  }

  const onTextSizeChange = (e) => {
    const size = e.target.value;
    setSize(size);
    settings.current.size = size;
  }

  const onFontChange = (e) => {
    const font = e.target.value;
    setFont(font);
    settings.current.font = font;
  }
  const onAlignChange = (e) => {
    const align = e.target.value;
    setAlign(align);
    settings.current.align = align;
  }
  const onIsBoldChange = (isBold) => {
    setIsBold(isBold);
    settings.current.isBold = isBold;
  }
  const onIsItalicChange = (isItalic) => {
    setIsItalic(isItalic);
    settings.current.isItalic = isItalic;
  }

  const onConfirm = () => {
    const result = {
      x: settings.current.x,
      y: settings.current.y,
      textStr: settings.current.text,
      align: settings.current.align,
      isItalic: settings.current.isItalic,
      isBold: settings.current.isBold,
      color: settings.current.color,
      size: Number(settings.current.size),
      font: settings.current.font,
      opacity: settings.current.opacity,
      angleRad: settings.current.angleRad,
    };
    settings.current.onConfirm(result);
    settings.current.updateCanvasBound(
      boxBoundingParams.current.pivotGlobalX, boxBoundingParams.current.pivotGlobalY,
      boxBoundingParams.current.pivotRatioX, boxBoundingParams.current.pivotRatioY,
      boxBoundingParams.current.angleRad, boxBoundingParams.current.width, boxBoundingParams.current.height);
  }

  const onCancel = () => {
    settings.current.onCancel();
  }

  return (
    <div style={{ borderRadius: "10px", overflow: "hidden" }}>
      <div style={{ height: "30px", width: "100%", backgroundColor: "#505050", color: "#f0f0f0", fontSize: "20px", alignItems: "center" }}> &nbsp;&nbsp;TEXT </div>
      <canvas ref={previewCanvas} width={canvasData.current.width} height={canvasData.current.height}>
      </canvas>
      <div style={{ backgroundColor: "rgba(160,160,160,1)", padding: "5px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ marginRight: '10px' }}> {/* Adding some margin for spacing between inputs */}
            <CoolSlider style={{ width: "200px" }} min={0} max={100} title={"opacity"} defalutValue={100} onChange={onOpacityChange} />
          </div>
          <div>
            <CoolSlider style={{ width: "200px" }} min={-180} max={180} title={"rotation"} defalutValue={0} onChange={onAngleRadChange} />
          </div>
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <input type="number" style={{ width: "100px", marginRight: "5px" }} defaultValue={size} placeholder="Font Size" onChange={onTextSizeChange} />
          <select value={settings.current.font} onChange={onFontChange} style={{ marginRight: "5px" }}>
            {avaliableTextFont.current.map((font, index) => (
              <option key={index} value={font[0]}>
                {font[1]}
              </option>
            ))}
          </select>
          <div style={{ marginRight: "5px" }}>
            <IconButton text={"B"} onClick={onIsBoldChange} width={30} height={30} style={{ marginRight: "5px" }} />
            <IconButton text={"L"} onClick={onIsItalicChange} width={30} height={30} />
          </div>

        </div>
        <textarea
          ref={textBoxRef}
          onChange={updateText}
          value={text}
          style={{
            display: 'block',
            width: 'calc(100% - 5px)',  // Will be overridden by useEffect to match canvas width
            height: '5em',  // Assuming a typical line height of 1em
            whiteSpace: 'nowrap',
            overflowX: 'scroll',
            resize: 'vertical',  // Prevent user from resizing the textarea
            marginTop: "5px",
          }}
        ></textarea>
      </div>
      <div style={{ height: "30px", width: "100%", backgroundColor: "#909090", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <button style={{ width: "100px", marginRight: "10px" }} onClick={onConfirm}>OK!</button>
        <button style={{ width: "100px", marginRight: "10px" }} onClick={onCancel}>Nuh..</button>
      </div>
      <div style={{ height: "30px", width: "100%", backgroundColor: "#505050" }}></div>
    </div>
  );
}

const CoolSlider = ({ style, textStyle = {}, inputBoxStyle = {}, min = 0, max = 10, title, defalutValue = 0, onChange = (val) => { } }) => {
  const [value, setValue] = useState(defalutValue);

  const updateValue = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue);
  }
  return (
    <div style={style}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ textStyle }}> {title} </div>
        <input style={inputBoxStyle} type="number" value={value} min={min} max={max} onChange={updateValue} />
      </div>

      <input type="range" style={{ width: "100%" }} value={value} min={min} max={max} onChange={updateValue} />
    </div>
  );
}

const IconButton = ({ text, onClick, width, height, style = {} }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  let buttonStyle = { cursor: 'pointer', background: 'none', border: 'none', outline: 'none', border: '2px solid black', boxSizing: 'border-box', width, height, backgroundColor: "rgba(160,160,160,1)", ...style };
  // let iconStyle = { opacity: 0.5, border: '2px solid black', boxSizing: 'border-box' }; // Default to unselected

  if (isHovered && !isSelected) {
    // iconStyle = { ...iconStyle, opacity: 0.7, backgroundColor: "#aaaaaa" };  // hoverButNotSelected
    buttonStyle = { ...buttonStyle, backgroundColor: "#aaaaaa" }
  } else if (!isHovered && isSelected) {
    // iconStyle = { ...iconStyle, opacity: 1, border: '2px solid blue' };    // selected
    buttonStyle = { ...buttonStyle, border: '2px solid blue', backgroundColor: "#cccccc" }
  } else if (isHovered && isSelected) {
    // iconStyle = { ...iconStyle, opacity: 0.85, border: '2px solid blue' }; // hoverAndSelected
    buttonStyle = { ...buttonStyle, border: '2px solid blue', backgroundColor: "#dddddd" }
  }

  const onButtonClick = () => {
    if (isSelected) {
      setIsSelected(false)
      onClick(false);
    } else {
      setIsSelected(true)
      onClick(true);
    }
  }

  console.log("hovered: ", buttonStyle.backgroundColor);
  return (
    <button
      onMouseEnter={() => { setIsHovered(true); console.log("hovered"); }}
      onMouseLeave={() => { setIsHovered(false); console.log("hovered off"); }}
      onClick={onButtonClick}
      style={buttonStyle}
    >
      {text}
    </button>
  );
};

