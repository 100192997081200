import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SystemOperationsContext } from "../context/SystemRunnerContext";

function TroubleLogin() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const { system_operations } = useContext(SystemOperationsContext);

  useEffect(() => {
    if (system_operations.loggedIn) {
      navigate("/")
    }
  }, [])

  const handleEmailSubmit = async (event) => {
    system_operations.set_loading(true);
    event.preventDefault();
    // Validate email and send verification code
    setStep(2);
    system_operations.set_loading(false);
  };

  const handleVerificationCodeSubmit = async (event) => {
    system_operations.set_loading(true);
    event.preventDefault();
    // Validate verification code
    setStep(3);
    system_operations.set_loading(false);
  };

  const handlePasswordSubmit = async (event) => {
    system_operations.set_loading(true);
    event.preventDefault();
    // Validate and update password
    if (password === confirmPassword) {
      // Update user's password
      navigate('/login');
    } else {
    }
    system_operations.set_loading(false);
  };

  return (
    <div>
      <div class="background-image"></div>
      <div className="form-container form-container--log">
        <div className="large-text">
          <h2>Trouble Logging In?</h2>
        </div>
        {step === 1 && (
          <form onSubmit={handleEmailSubmit}>
            <input
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <button type="submit">Next</button>
          </form>
        )}
        {step === 2 && (
          <form onSubmit={handleVerificationCodeSubmit}>
            <input
              type="text"
              placeholder="Verification Code"
              required
              value={verificationCode}
              onChange={(event) => setVerificationCode(event.target.value)}
            />
            <button type="submit">Next</button>
          </form>
        )}
        {step === 3 && (
          <form onSubmit={handlePasswordSubmit}>
            <input
              type="password"
              placeholder="New Password"
              required
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              required
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
            <button type="submit">Submit</button>
          </form>
        )}
        <div className='small-text'>
          <p>Remembered your password? <Link to="/login">Login</Link></p>
        </div>
      </div>
    </div>
  );
}

export default TroubleLogin;