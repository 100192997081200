import React, { useState, useContext, useEffect } from 'react';
import { Button, Card, CardContent, Typography, Avatar, Box, Tooltip } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { tooltipClasses } from '@mui/material/Tooltip';
import { SystemOperationsContext } from "../context/SystemRunnerContext";

const baseColor = 'rgba(170, 170, 170, 0.7)';
const baseTextColor = "rgba(250, 250, 250, 1)";
const secondaryTextColor = "rgba(225, 225, 225, 1)";
const borderRadius = '10px';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: baseColor,
  borderRadius,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(10px)',
}))

export const UserShortCard = ({ user, onNavigate }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [isFollowing, setIsFollowing] = useState(false);
  useEffect(() => {
    const userID = system_operations.getUserID()
    if (userID) {
      system_operations.firestoreActions.fetchFromFirestore(`users/${userID}/following/${user["userID"]}`, "userID").then((result) => {
        if (result) {
          setIsFollowing(true);
        } else {
          setIsFollowing(false);
        }
      })
    }
  }, []);

  const navigate = useNavigate();

  const handleVisit = async () => {
    if (user["userID"]) {
      await system_operations.enterAWall(`${user["userID"]}_baseBoard`);
      onNavigate();
      navigate("/board/" + user["userID"]);
    }
  }

  const handleFollow = async () => {
    // await system_operations.authSystem.FollowToAUser(system_operations.getUserID(), user["userID"])
  }

  return (
    <div>
      {user && <StyledCard sx={{ width: 295, height: 100, display: 'flex', flexDirection: 'row' }}>
        <Avatar alt="user avatar" src={user.avatar} sx={{ width: 50, height: 50, margin: 'auto', marginLeft: "5px", marginRight: "5px" }} />
        <Divider orientation="vertical" flexItem />
        <CardContent>
          <div style={{ height: 45, width: 200 }}>
            <Typography variant="body1" color={baseTextColor} sx={{ whiteSpace: 'nowrap', flex: '0 0 auto' }}>
              {user.username}
            </Typography>
            <Typography variant="body2" color={secondaryTextColor} sx={{ whiteSpace: 'nowrap', flex: '0 0 auto' }}>
              following system coming soon
            </Typography>
          </div>
          <Divider />
          <div style={{ height: 45, width: 200 }}>
            <Box display="flex" justifyContent="space-between" flexWrap="nowrap" sx={{ marginLeft: "5px" }}>
              <Button size="small" onClick={handleVisit}>Visit</Button>
              <Button size="small" onClick={handleFollow} disabled={isFollowing}>{isFollowing ? "Followed" : "Follow"}</Button>
            </Box>
          </div>
        </CardContent>
      </StyledCard>}
    </div>
  );
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    maxWidth: 220,
  },
}));

export const UserShortCardToolTip = ({ user, onNavigate, children }) => {
  return (
    <HtmlTooltip
      title={
        <UserShortCard user={user} onNavigate={onNavigate} />
      }
    >
      {children}
    </HtmlTooltip>
  )
}