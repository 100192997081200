import { getAuth } from 'firebase/auth';
import { getFirestore, addDoc, collection, deleteDoc, doc, getDoc, getDocs, setDoc, query, updateDoc, where } from 'firebase/firestore';
import { getStorage, deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';

import { ImageProcessor } from "../utility/ImageProcessor.js"
import { Base64Utility } from "../utility/Base64Utility.js"

import { FirestoreActions } from "./FirestoreActions"
import WallSystem from "./WallSystem"
import AuthSystem from "./AuthSystem"


export class PostSystem {

  static defaultAudioCover = "https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2FAudio_Player_160x160.png?alt=media&token=01dd6d5c-78cb-418f-9d03-4dccbca6c396";
  static defaultVideoCover = "https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fvideo_player_160x160.png?alt=media&token=2386cb4f-ba97-426f-a50e-29312aa96e69";
  static defaultPreviewGeneral = "https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2FSticker_Book160.png?alt=media&token=21380632-3b0b-4172-9c73-d0c0d6180bc5"

  constructor(app) {
    this.auth = getAuth(app);
    this.firestore = getFirestore(app);
    this.storage = getStorage(app);

    this.defaultAudioCover = "https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2FAudio_Player_160x160.png?alt=media&token=01dd6d5c-78cb-418f-9d03-4dccbca6c396";
    this.defaultVideoCover = "https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fvideo_player_160x160.png?alt=media&token=2386cb4f-ba97-426f-a50e-29312aa96e69";
    this.defaultPreviewGeneral = "https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2FSticker_Book160.png?alt=media&token=21380632-3b0b-4172-9c73-d0c0d6180bc5"


    this.currentViewingPost = null;
    this.changed = false;
    this.storedUser = ""
    this.storedPosts = [];
  }

  async getAPost(userID, postID) {
    // this is a temp solution
    const fullPost = await FirestoreActions.static_fetchFromFirestore(`users/${userID}/posts/${postID}`, "postID");
    fullPost.creatorID = userID;
    return fullPost;
  }

  async getAllPosts(userID) {
    if (this.changed || this.storedUser != userID) {
      this.storedPosts = await FirestoreActions.static_fetchFromFirestore(`users/${userID}/posts`, "postID");
      this.storedUser = userID;
      this.changed = false;
    }
    return this.storedPosts
  }

  async createAPost(post, userID, convertToPreviews = null, postContent = []) {
    // this.changed = true;
    const newPost = this.private_createAPost(post, userID, convertToPreviews, postContent);
    this.storedPosts.push(newPost); // push to the back, so we don't need to reload the whole post list
    return newPost;
  }

  async private_createAPost(post, userID, convertToPreviews = null, postContent = []) {
    const postDocRef = doc(collection(this.firestore, `users/${userID}/posts`));
    const postID = postDocRef.id;

    let previewsUploadPromises = [];
    const allFilesURLs = [];
    if (convertToPreviews) {
      // this is async
      previewsUploadPromises = this.private_previewsUpload_base64(convertToPreviews, userID, postID);
    }
    for (let i = 0; i < postContent.length; i++) {
      const storageFileName = `${postID}_${i}`;
      const storageFilePath = `users/${userID}/files/${storageFileName}`;

      const uploadFile = async () => {
        const fileRef = ref(this.storage, storageFilePath);
        await uploadBytes(fileRef, Base64Utility.base64DataToByteData(postContent[i].data), { contentType: postContent[i].type })
        const url = await getDownloadURL(fileRef);
        return url;
      }
      allFilesURLs.push(uploadFile());
    }

    const allAsyncTasks = await Promise.all([previewsUploadPromises, Promise.all(allFilesURLs)]);
    const previewUrls = allAsyncTasks[0]; // an array
    const downloadableURLs = allAsyncTasks[1];

    let contentUrls = [];
    for (let i = 0; i < postContent.length; i++) {
      if (postContent[i] && downloadableURLs[i]) {
        contentUrls[i] = { data: downloadableURLs[i], type: postContent[i].type }
      }
    }

    const newPost = {
      ...post,
      postID: postID,
      contentUrls: contentUrls,
      previewUrls: previewUrls,
    };

    await setDoc(postDocRef, newPost);
    return newPost;
  }

  async private_previewsUpload_base64(images, userID, postID) {
    const previewUrlsPromises = []
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const previewUrlPromise = this.uploadOnePreview(image, i, userID, postID);
      previewUrlsPromises.push(previewUrlPromise);
    }
    return await Promise.all(previewUrlsPromises); // return an array of preview results
  }

  async uploadOnePreview(image, index, userID, postID) {
    const [previewWidth, previewHeight] = await ImageProcessor.static_returnDimensionFromImageUrl(image);
    const [desiredW, desiredH] = ImageProcessor.static_fitImageSize(previewWidth, previewHeight, 160, 160, 30, false);
    const base64PreviewImageData = await ImageProcessor.static_resizeBase64Image(image, desiredW, desiredH);
    const previewRef = ref(this.storage, `users/${userID}/previews/${`${postID}_preview_${index}`}`);
    await uploadBytes(previewRef, Base64Utility.base64DataToByteData(base64PreviewImageData.resizedBase64), { contentType: 'image/png' })
    const previewUrl = await getDownloadURL(previewRef)
    return previewUrl;
  }

  async getAllPostsOfUser(userID) {
    const postsQuery = query(collection(this.firestore, `users/${userID}/posts`));
    const querySnapshot = await getDocs(postsQuery);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }

  async stickAPostInstance(boardID, userID, post) {
    const postInstanceID = `${userID}_${post.postID}`;
    const wallPostDocRef = doc(this.firestore, `boards/${boardID}/posts/${postInstanceID}`);
    await setDoc(wallPostDocRef, post);

    const originalPostRef = doc(this.firestore, `users/${userID}/posts/${post.postID}/instanceLocations/${boardID}`);
    await setDoc(originalPostRef, { instanceID: "postInstanceID" });
    // const originalPostRef = doc(this.firestore, `users/${userID}/posts/${post.postID}`);
    // await updateDoc(originalPostRef, { instancesLocation: this.firestore.firestore.instancesLocation.arrayUnion(boardID) });
    post.postInstanceID = postInstanceID;
    return post;
  }

  async removeAPostInstance(boardID, userID, postID) {
    const wallPostDocRef = doc(this.firestore, `boards/${boardID}/posts/${postID}`);
    await deleteDoc(wallPostDocRef);
    const originalPostRef = doc(this.firestore, `users/${userID}/posts/${postID}/instanceLocations/${boardID}`);
    await deleteDoc(originalPostRef);
    // const originalPostRef = doc(this.firestore, `users/${userID}/posts/${postID}`);
    // await updateDoc(originalPostRef, { instancesLocation: this.firestore.firestore.instancesLocation.arrayRemove(boardID) });
  }

  async deleteAPost(userID, post) {
    const allPromises = [];
    allPromises.push(await FirestoreActions.static_removeDocumentAt(`users/${userID}/posts/${post.postID}`));
    for (let i = 0; i < post.contentUrls.length; i++) {
      const contentStorageRef = ref(this.storage, `users/${userID}/files/${post.postID}_${i}`);
      allPromises.push(deleteObject(contentStorageRef));
    }
    for (let i = 0; i < post.previewUrls.length; i++) {
      const contentStorageRef = ref(this.storage, `users/${userID}/previews/${post.postID}_preview_${i}`);
      allPromises.push(deleteObject(contentStorageRef));
    }
    // remove all the posts instances
    const allInstances = FirestoreActions.static_fetchFromFirestore(`users/${userID}/posts/${post.postID}/instanceLocations`, "boardID")
    for (let i = 0; i < allInstances.length; i++) {
      const originalPostRef = doc(this.firestore, `boards/${allInstances[i].boardID}/posts/${userID}_${post.postID}/`);
      await deleteDoc(originalPostRef);
    }

    await Promise.all(allPromises);
  }
}

async function dataUrlToBlob(dataUrl) {
  const response = await fetch(dataUrl);
  return await response.blob();
}
