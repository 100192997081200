

export const MyToggle = ({ handleChange = () => { }, checked = false, trackWidth = 100, trackHeight = 40, switchSize = 36, style = {}, switchSX = {}, trackSX = {}, children }) => {
  const { handleChildren, trackChildren } = children;

  const swtchRadius = switchSize / 2;
  const switchOffsetBase = (trackHeight - switchSize) / 2
  const handleStyle = {
    position: "absolute", overflow: "hidden",
    width: switchSize, height: switchSize, borderRadius: swtchRadius,
    left: checked ? trackWidth - (switchSize + switchOffsetBase) : switchOffsetBase, top: switchOffsetBase, transition: 'all 0.5s ease',
    ...switchSX, zIndex: 2,
  }

  const trackStyle = {
    position: "relative", overflow: "hidden", display: 'flex', alignItems: 'center',
    width: trackWidth, height: trackHeight, borderRadius: trackHeight / 2, backgroundColor: "red", transition: 'all 0.5s ease',
    ...trackSX, zIndex: 1,
  }

  return (
    <div style={style} onClick={handleChange}>
      <div style={trackStyle}>
        {trackChildren}
        <div style={handleStyle}>
          {handleChildren}
        </div>
      </div>
    </div>
  )
}