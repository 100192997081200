import React, { useState, useRef, useContext, useEffect } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { RatioSizer } from "../utility/Sizer.js";
import ButtonGroups from "../cross_page_components/ButtonGroups.jsx";
import { UserShortCardToolTip } from "../components/UserDisplay";
import { RequestDataMaker } from '../system/RestServerConnector.js';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles

import { useTheme } from '@mui/system';
import { Paper, styled, DialogTitle } from '@mui/material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: 3,
  borderRadius: '2px',
}));

const MIN_HEIGHT = 20;
const MAX_HEIGHT = 500;
const MIN_WIDTH = 50;
const MAX_WIDTH = 600;

const isEmptyText = (text) => {
  return text === "<p><br></p>" || text === "";
}

// onChangeCallback(showText, text)
export const FloatingCanvasText = ({ canvasRect = null, setUnderText = false, onChangeCallback = null }) => {
  const canvasData = canvasRect.getData();
  const { system_operations } = useContext(SystemOperationsContext);
  const [text, setText] = useState('');

  const [perfectWindowSize, setPerfectWindowSize] = useState([0, 0]);
  const [backgroundColor, setBackgroundColor] = useState("rgb(255,255,255)");
  const [postWidth, setPostWidth] = useState(200);
  const [postHeight, setPostHeight] = useState("");
  const insideTopDownMargin = 200, outsideTopDownMargin = 140;

  const containerSizeCalculator = useRef(new RatioSizer());
  const containerRef = useRef(null);
  const changed = useRef(false);

  const [postOwenerForViewing, setPostOwenerForViewing] = useState({ username: canvasData ? canvasData.creatorID : "", avatar: "none" });
  const action = (canvasData.creatorID === system_operations.getUserID()) ? "editing" : "viewing"

  const theme = useTheme();

  useEffect(() => {
    if (canvasData) {
      setBackgroundColor(setUnderText ? "white" : (canvasData.style.backgroundColor ? canvasData.style.backgroundColor : "white"));
      setPostWidth(canvasData.width);
      setPostHeight(setUnderText ? "" : canvasData.height);
      setText(setUnderText ? canvasData.underText : canvasData.text);

      system_operations.usersData.getUserDataByID(canvasData.creatorID).then((userData) => {
        setPostOwenerForViewing(userData);
      })
    }
  }, [canvasRect]);

  useEffect(() => {
    containerSizeCalculator.current.setWidthRatio(0.5, 800, 0.9);
    containerSizeCalculator.current.setHeightRatio(0.8, 0, 0);
    containerSizeCalculator.current.setWidthRange(400, 1000);
    containerSizeCalculator.current.setHeightRange(300, 1440);
  }, []);

  const doneEditing = async () => {
    let change = false;
    let textOn = false;
    if (text !== canvasData.text) { // not the same
      if (text && !isEmptyText(text)) {
        change = true;
        textOn = true;
      } else {
        change = true;
        textOn = false;
      }
    } else if (text && !canvasData.showText) { // has text but the canvas says no text.
      change = true;
      textOn = true;
    }

    if (change) {
      if (textOn) {
        system_operations.set_loading(true);
        if (!setUnderText) {
          canvasData.showText = true;
          canvasData.text = text;
          const dataJson = await RequestDataMaker.dataToUpdateFloatingCanvas(system_operations, canvasData.canvasID, { text: text, showText: true });
          await system_operations.restServerConnector.requestToUpdateFloatingCanvasProperties(dataJson);
          if (onChangeCallback) {
            onChangeCallback(true, text);
          }
        } else {
          canvasData.showUnderText = true;
          canvasData.underText = text;
          const dataJson = await RequestDataMaker.dataToUpdateFloatingCanvas(system_operations, canvasData.canvasID, { underText: text, showUnderText: true });
          await system_operations.restServerConnector.requestToUpdateFloatingCanvasProperties(dataJson);
          if (onChangeCallback) {
            onChangeCallback(true, text);
          }
        }
        if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
        system_operations.set_loading(false);
      } else {
        system_operations.set_loading(true);
        if (!setUnderText) {
          canvasData.showText = false;
          canvasData.text = "";
          const dataJson = await RequestDataMaker.dataToUpdateFloatingCanvas(system_operations, canvasData.canvasID, { text: "", showText: false });
          console.log(dataJson);
          await system_operations.restServerConnector.requestToUpdateFloatingCanvasProperties(dataJson);
          if (onChangeCallback) {
            onChangeCallback(false, "");
          }
        } else {
          canvasData.showUnderText = false;
          canvasData.underText = "";
          const dataJson = await RequestDataMaker.dataToUpdateFloatingCanvas(system_operations, canvasData.canvasID, { underText: "", showUnderText: false });
          await system_operations.restServerConnector.requestToUpdateFloatingCanvasProperties(dataJson);
          if (onChangeCallback) {
            onChangeCallback(false, "");
          }
        }
        if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
        system_operations.set_loading(false);
      }
    }
    changed.current = false;
    system_operations.setContentWindow(null);
  }

  const changeText = (newContent) => {
    changed.current = true;
    setText(newContent);
  }

  const handleColorChange = (event) => {
    changed.current = true;
    setBackgroundColor(event.target.value);
  };

  useEffect(() => {
    const calculateWindowSize = () => {
      const W = window.innerWidth;
      const H = window.innerHeight;
      const size = containerSizeCalculator.current.findSize(W, (H - outsideTopDownMargin));
      setPerfectWindowSize(size);
    };
    calculateWindowSize();

    window.addEventListener('resize', calculateWindowSize);
    return () => {
      window.removeEventListener('resize', calculateWindowSize);
    };
  }, []);

  const powerCheck = () => {
    return (canvasData.creatorID === system_operations.getUserID());
  }

  let buttons = {}
  if (action === "editing") {
    buttons = {
      submitButton: {
        name: 'Looks Good',
        color: "white",
        backgroundColor: "#999999",
        checkDisable: () => { return !powerCheck() },
        onClick: () => { doneEditing() },
      },
    }
  } else {
    buttons = {}
  }

  let quillComponent = (null);
  if (action === "editing") {
    quillComponent = (
      <div className='float-container' style={{ "overflow": "hidden", margin: "5px", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <div id='toolbar' style={{ minWidth: "500px", width: "100%" }}>
          {/* <input type="color" value={backgroundColor} onChange={handleColorChange} style={{ height: "100%", width: "20px", padding: "0" }} /> */}
          <select className="ql-font">
            <option selected>Sans Serif</option>
            <option value="serif">Serif</option>
            <option value="monospace">Monospace</option>
          </select>
          <select className="ql-size">
            <option value="small">Small</option>
            <option value="large" selected>Normal</option>
            <option value="huge">Large</option>
          </select>
          <button className="ql-bold">Bold</button>
          <button className="ql-italic">Italic</button>
          <button className="ql-underline">Underline</button>
          <button className="ql-strike">Strike</button>
          <select className="ql-color"></select>
          <select className="ql-background"></select>
          <button className="ql-script" value="sub">Sub</button>
          <button className="ql-script" value="super">Super</button>
          <button className="ql-header" value="1">Header 1</button>
          <button className="ql-header" value="2">Header 2</button>
          <button className="ql-blockquote">Blockquote</button>
          <button className="ql-code-block">Code Block</button>
          <button className="ql-link">Link</button>
          <button className="ql-align" value="center"></button>
          <button className="ql-align" value="right"></button>
          <button className="ql-align" value="justify"></button>
          <button className="ql-list" value="ordered">Ordered List</button>
          <button className="ql-list" value="bullet">Unordered List</button>
          <button className="ql-indent" value="-1">Indent Decrease</button>
          <button className="ql-indent" value="+1">Indent Increase</button>
          <button className="ql-direction" value="rtl">RTL</button>
          <button className="ql-align"></button>
          <button className="ql-clean">Clean</button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: "5px" }}>
          <StyledPaper style={{ width: postWidth, height: postHeight, border: 0, backgroundColor: backgroundColor, borderColor: false ? 'black' : 'transparent', }}>
            <ReactQuill
              className="myQuill"
              theme="snow"
              value={text}
              onChange={changeText}
              modules={{
                toolbar: {
                  container: '#toolbar', // Selector for toolbar container
                }
              }}
              style={{
                height: '100%',
                userSelect: "auto",
              }}
            >
            </ReactQuill>
          </StyledPaper>
        </div>
      </div>);
  } else {
    quillComponent = (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <StyledPaper style={{ width: canvasData.width, height: canvasData.height, border: 0, backgroundColor: backgroundColor, borderColor: false ? 'black' : 'transparent', }}>
          <ReactQuill
            theme="snow"
            value={canvasData.text}
            readOnly={true}
            modules={{
              toolbar: false, // Disable toolbar
            }}
            style={{ height: "100%", width: "100%" }} // Set the size of the editor
          />
        </StyledPaper>
      </div>
    );
  }

  return (
    <div style={{ userSelect: 'none' }}>
      <div ref={containerRef} style={{

        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#777777",
        borderRadius: "10px",
        padding: "10px",
      }}>
        {action === "viewing" && <DialogTitle id="fence-content-title" sx={{ color: "white", fontSize: '0.7rem', padding: 0, marginTop: 0 }}>
          Created By:&nbsp;
          <UserShortCardToolTip user={postOwenerForViewing}>
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '0.7rem',
                color: "rgba(64, 64, 225, 1)"
              }}
            >
              {postOwenerForViewing.username}
            </span>
          </UserShortCardToolTip>
        </DialogTitle>}
        {quillComponent}
        <ButtonGroups buttons={buttons} />
      </div>
    </div>
  );
};

export default FloatingCanvasText;