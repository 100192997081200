import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDnjhfGSNGX6T8_q0CoUc7mbPNkvmi-3XI",
    authDomain: "wallswithworld.firebaseapp.com",
    databaseURL: "https://wallswithworld-default-rtdb.firebaseio.com",
    projectId: "wallswithworld",
    storageBucket: "wallswithworld.appspot.com",
    messagingSenderId: "902517960195",
    appId: "1:902517960195:web:1c7c06da49b32915d12836",
    measurementId: "G-4GLD6BC2DM"
  };

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const analytics = getAnalytics(app);

export default app;