import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { GridContainer, ToolItem, ToolItemICONChildren } from '../../components/toolBar/UltimateControlPanel';
import { SystemOperationsContext } from "../../context/SystemRunnerContext";

import { SketchPicker } from 'react-color';
import { FloatingCanvasText } from '../../components/FloatingCanvasText.jsx';
import { ContentMarkPrompt } from '../../components/ContentMarkPrompt.jsx';
import { RequestDataMaker } from '../../system/RestServerConnector.js';
import { useTheme } from '@mui/system';

import eye_off_red from '../../icons/eye-off-red.svg'
import eye_off_white from '../../icons/eye-off-white.svg'
import picture_green from '../../icons/picture-green.svg'
import picture_white from '../../icons/picture-white.svg'
import text_blue from '../../icons/text-blue.svg'
import text_white from '../../icons/text-white.svg'
import star_white from '../../icons/star-white.svg'
import star_yellow from '../../icons/star-yellow.svg'
import heart_pink from '../../icons/heart-pink.svg'
import heart_white from '../../icons/heart-white.svg'
import palette_white from '../../icons/palette-white.svg'
import radius_white from '../../icons/radius-white.svg'
import coin_shadow_white from '../../icons/coin-shadow-white.svg'
import check_green from '../../icons/check-green.svg'
import check_696969 from '../../icons/check-696969.svg'

import {baseSubItemStyle} from './Hand.js'


export const PIFullFunctionalPanel = ({ postInstanceRect, isVertical }) => {
  const subItemBaseStyle = { ...baseSubItemStyle, [isVertical ? "width" : "height"]: "20%" };
  const subItemHoverStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.2)" };
  const subItemSelectedStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.5)" };

  return (
    <div>
      <PIReactionPanel postInstanceRect={postInstanceRect} isVertical={isVertical} subItemBaseStyle={subItemBaseStyle} subItemHoverStyle={subItemHoverStyle} subItemSelectedStyle={subItemSelectedStyle} />
    </div>
  );
}

export const PIReactionPanel = ({ postInstanceRect, isVertical, subItemBaseStyle, subItemHoverStyle, subItemSelectedStyle }) => {
  const postInstanceData = postInstanceRect.getData();
  const { system_operations } = useContext(SystemOperationsContext);
  const [liked, setLiked] = useState(false);
  const [isMarked, setIsMarked] = useState(postInstanceData.marked);
  const [textModeOn, setTextModeOn] = useState(postInstanceData.showText);
  const [interactable, setInteractable] = useState(postInstanceRect.interactable);

  const theme = useTheme();
  const isOwner = postInstanceData.creatorID === system_operations.getUserID();

  useEffect(() => {
    system_operations.coordinator.likeSystem.checkLikedForPost(postInstanceData).then((likedByUser) => {
      if (likedByUser !== liked) {
        setLiked(likedByUser);
      }
    });

    setIsMarked(postInstanceData.marked);
    setTextModeOn(postInstanceData.showText);
    setInteractable(postInstanceRect.interactable);
  }, [postInstanceRect])

  const likeButtonClicked = () => {
    if (!liked) {
      setLiked(true);
      system_operations.coordinator.likeSystem.likeAPost(postInstanceData);
    } else {
      setLiked(false);
      system_operations.coordinator.likeSystem.dislikeAPost(postInstanceData);
    }
  }

  const openContentMarkWindow = async () => {
    const contentMarker = system_operations.coordinator.sectionData.getItemFromContentMark(`post_${postInstanceRect.postInstanceID}`);
    if (!contentMarker) {
      system_operations.setContentWindow(<ContentMarkPrompt rectRef={postInstanceRect} baseType="pi" />);
    } else {
      system_operations.set_loading(true);
      await system_operations.firestoreActions.removeDocumentAt(`boards/${system_operations.coordinator.getWallID()}/marks/post_${postInstanceData.postInstanceID}`);
      // system_operations.coordinator.sectionData.deleteItemFromContentMarkLoader(contentMarker);
      setIsMarked(false);
      system_operations.set_loading(false);
    }
  }

  const changeInteractive = () => {
    const willBeInteractable = !interactable;
    setInteractable(willBeInteractable);
    postInstanceRect.func.setInteractable(willBeInteractable);
  }


  return (
    <div>
      <GridContainer isVertical={isVertical} title='Interaction'>
        <ToolItem icon={liked ? heart_pink : heart_white} onClick={likeButtonClicked} selected={liked}
          baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
        <ToolItem icon={!interactable ? eye_off_red : eye_off_white} onClick={changeInteractive} selected={!interactable}
          baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
        {
          isOwner &&
          <>
            <ToolItem icon={isMarked ? star_yellow : star_white} onClick={openContentMarkWindow} selected={isMarked}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
          </>
        }
      </GridContainer>
    </div>
  );
}
