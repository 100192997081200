import { getFirestore, collection, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import app from "../firebase.js";



export class ContentMarkers {
  constructor() {
    this.app = app;
    this.firestore = getFirestore(app);
    this.storage = getStorage(app);

    this.startAddingContentMarkerFunc = null;
  }

  getContentMarksAt(coordinate) {

  }

  startAddingContentMarkerProcess() {
    if (this.startAddingContentMarkerFunc) {
      this.startAddingContentMarkerFunc();
    }
  }

  addContentMark(coordinate, content) {

  }
}