import React, { useState, useContext, useEffect, useRef } from 'react';
import { UserShortCardToolTip } from "../components/UserDisplay";

import { styled, useTheme } from '@mui/system';
import { Typography, Button, Divider, Grid, Link, IconButton } from '@mui/material';
import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import { Tabs, Tab, Box, TextField, Checkbox, FormControlLabel } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisitIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import PublicOffRoundedIcon from '@mui/icons-material/PublicOffRounded';

import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { DialogTitle } from '@mui/material';

import horizontalIcon from '../icons/split-cells-horizontal.svg';
import verticalIcon from '../icons/split-cells-vertical.svg';

import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { RequestDataMaker } from "../system/RestServerConnector.js"
import { useNavigate } from 'react-router-dom';

import { FirestoreActions } from "../auth/FirestoreActions.js"
import { ImageProcessor } from "../utility/ImageProcessor.js"
import { Base64Utility } from "../utility/Base64Utility.js"


const MAX_PREVIEW_SIZE = 360, MAX_PREVIEW_OVERSIZE = 30;


const baseColor = 'rgba(128, 128, 128, 0.7)';
const secondaryTextColor = "rgba(225, 225, 225, 1)";
const borderRadius = '10px';

const hoverDiv = {
  transition: 'all 0.3s ease', // for a smooth transitions
  borderRadius: "5px",
  '&:hover': {
    boxShadow: '0px 5px 15px rgba(0,0,0,0.12), 0px 1px 10px rgba(0,0,0,0.14)', // This is similar to Material UI's Paper shadow
    backgroundImage: 'linear-gradient(180deg, rgba(255,255,255,0.05) 0%, transparent 70%)', // inner highlight at the top, sharper and more subtle
  }
}

let saveIP = false;
const BoardCard = ({ wall, refresh, shortenDisplay = false }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [saved, setSaved] = useState(wall.wallID in system_operations.homePageManager.savedBoards);
  const [likes, setLikes] = useState(wall.likes);
  const [owned, setOwned] = useState(wall.owner === system_operations.getUserID());
  // const [hoverSaveButton, setHoverSaveButton] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    system_operations.boardSaveSystem.checkIfABoardIsSaved(system_operations.getUserID(), wall.wallID).then((saved) => {
      setSaved(saved);
    })
    // setSaved(wall.wallID in system_operations.homePageManager.savedBoards);
  }, [refresh])

  const enterTheBoard = async () => {
    system_operations.set_loading(true);
    navigate("/board/id/" + wall["wallID"]);
    system_operations.set_loading(false);
  }

  const saveTheBoard = async () => {
    if (system_operations.loggedIn) {
      // system_operations.set_loading(true);
      if (!saved) {
        saveIP = true;
        system_operations.boardSaveSystem.addToSaved(wall.wallID).then(() => {
          saveIP = false;
        });
        wall.likes += 1;
        setLikes(wall.likes);
        setSaved(true);
      } else {
        saveIP = true;
        await system_operations.removeFromSaved(wall.wallID).then(() => {
          saveIP = false;
        });
        wall.likes -= 1;
        setLikes(wall.likes);
        setSaved(false);
      }
      // system_operations.set_loading(false);
    } else {
      system_operations.pleaseLogIn("Login now and never get lost!!");
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {
        shortenDisplay ? (
          <Tooltip
            title={<CardButtons saved={saved} saveTheBoard={saveTheBoard} enterTheBoard={enterTheBoard} />} placement="right"
            PopperProps={{
              popperOptions: {
                modifiers: [{
                  name: 'offset',
                  options: {
                    offset: [0, -180],
                  },
                },],
              },
            }}
            enterTouchDelay={0}
            leaveTouchDelay={1800}
          >
            <Card sx={{
              display: 'flex',
              marginBottom: theme.spacing(2),
              borderRadius: theme.borderRadius.homePageFrontUI,
              backgroundColor: theme.backgroundColor.homePageUIDarker,
              color: theme.frontColor.homePageTextPrimary,
              maxWidth: "100%",
              maxHeight: 140,
              height: 140,
              '&:hover': {
                backgroundColor: theme.backgroundColor.homePageUILighter,
                boxShadow: '0px 4px 20px rgba(0,0,0,0.5)',
              },
            }}>
              <CardMediaArea wall={wall} owned={owned} size={140} />
              <Divider orientation="vertical" flexItem />
              <BoardCardTextArea wall={wall} owned={owned} likes={likes} />
              <Divider orientation="vertical" flexItem />
            </Card>
          </Tooltip>
        ) : (
          <Card sx={{
            display: 'flex',
            marginBottom: theme.spacing(2),
            borderRadius: theme.borderRadius.homePageFrontUI,
            backgroundColor: theme.backgroundColor.homePageUIDarker,
            color: theme.frontColor.homePageTextPrimary,
            maxWidth: "100%",
            maxHeight: 140,
            height: 140,
            '&:hover': {
              backgroundColor: theme.backgroundColor.homePageUILighter,
              boxShadow: '0px 4px 20px rgba(0,0,0,0.5)',
            },
          }}>
            <CardMediaArea wall={wall} owned={owned} size={140} />
            <Divider orientation="vertical" flexItem />
            <BoardCardTextArea wall={wall} owned={owned} likes={likes} />
            <Divider orientation="vertical" flexItem />
            <CardButtons saved={saved} saveTheBoard={saveTheBoard} enterTheBoard={enterTheBoard} />
          </Card>
        )
      }
    </div>
  );
};

const CardButtons = ({ saved, saveTheBoard, enterTheBoard }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 140, minWidth: 140 }}>
      <Button
        variant={saved ? "contained" : "outlined"}
        color="secondary"
        startIcon={<FavoriteIcon />}
        sx={{ marginBottom: theme.spacing(1), width: 100, height: theme.spacing(5) }}
        onClick={saveTheBoard}
      >
        {saved ? "Saved" : "Save"}
      </Button>
      <Button
        variant="outlined"
        startIcon={<VisitIcon />}
        sx={{
          borderColor: theme.frontColor.homePageTextPrimary, // change border color
          width: 100, height: theme.spacing(5),
          color: theme.frontColor.homePageTextPrimary, // change text color
          '&:hover': { // change hover color
            borderColor: '#fff',
            backgroundColor: '#fff',
            color: '#000',
          },
        }}
        onClick={enterTheBoard}>
        Visit
      </Button>
    </Box>
  );
}

const BoardCardTextArea = ({ wall, owned, likes }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [editing, setEditing] = useState("");
  const [textValue, setTextValue] = useState("");
  const [isPublic, setIsPublic] = useState(wall.isPublic);
  const [ownerData, setOwnerData] = useState({ username: wall.owner, avatar: "none" })
  const theme = useTheme();

  useEffect(() => {
    system_operations.usersData.getUserDataByID(wall.owner).then((userData) => {
      setOwnerData(userData);
    })
  }, [])

  const startEditing = (mode, defaultValue) => {
    if (editing !== mode) {
      setEditing(mode);
      setTextValue(defaultValue);
    }
  }

  const handleInputChange = (event) => {
    setTextValue(event.target.value);
  };

  const submitChanges = () => {
    system_operations.set_loading(true);
    const path = `boards/${wall.wallID}`;
    if (editing === "name") {
      wall.displayName = textValue;
      RequestDataMaker.getDataToUpdateboard(system_operations, wall.wallID, { displayName: textValue }).then((updateData) => {
        system_operations.restServerConnector.updateBoard(updateData);
      })
    } else if (editing === "description") {
      wall.description = textValue;
      RequestDataMaker.getDataToUpdateboard(system_operations, wall.wallID, { description: textValue }).then((updateData) => {
        system_operations.restServerConnector.updateBoard(updateData);
      })
    }
    system_operations.set_loading(false);
    setEditing("");
  };

  const changePrivacyData = () => {
    wall.isPublic = !wall.isPublic;
    RequestDataMaker.getDataToUpdateboard(system_operations, wall.wallID, { isPublic: wall.isPublic }).then((updateData) => {
      system_operations.restServerConnector.updateBoard(updateData);
    })
    setIsPublic(wall.isPublic);
  }

  const clickAway = (from) => {
    if (from === editing) {
      setEditing("");
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      submitChanges();
    }
  };

  const boardName = wall.displayName ? wall.displayName : wall.wallName;
  const boardDescription = wall.description;

  return (
    <CardContent sx={{ padding: "10px", flexGrow: 1, flexBasis: '0', flexShrink: 1 }}> {/*You can adjust minWidth as per your requirement*/}
      <div style={{ width: "calc(100% - 20px)", height: "100%", overflow: "hidden" }}>
        <ClickAwayListener onClickAway={() => { clickAway("name") }}>
          {owned ?
            (<div style={{ display: "flex" }}>
              <Box sx={editing === "name" ? {} : hoverDiv} onClick={() => { startEditing("name", boardName) }}>
                {editing === "name" ? <TextField value={textValue} onChange={handleInputChange} onKeyDown={handleKeyDown} size="small" autoFocus style={{ width: "100%" }}
                  InputProps={{
                    style: { fontSize: "15px", padding: '0px', color: "white" }, endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={submitChanges}>
                          <CheckIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }} /> :
                  <Typography style={{ fontSize: "20px" }}>{boardName}</Typography>}
              </Box>
              <Tooltip title={isPublic ? "Allow people to discover and visit your board" : "Disallow people to discover and visit your board"} arrow>
                {isPublic ?
                  <PublicRoundedIcon onClick={changePrivacyData} sx={{ marginLeft: "5px", cursor: "pointer" }} />
                  :
                  <PublicOffRoundedIcon onClick={changePrivacyData} sx={{ color: "red", marginLeft: "5px", cursor: "pointer" }} />
                }
              </Tooltip>
            </div>
            ) : <Typography style={{ fontSize: "20px" }}>{boardName}</Typography>
          }
        </ClickAwayListener>
        <div style={{ width: "calc(100% - 20px)", height: "17px", display: 'flex' }}>
          <div style={{ height: "100%", display: 'flex' }}>
            <FavoriteIcon style={{ width: 17, height: 17, color: theme.frontColor.homePageTextSecondary }} />
            <Typography sx={{ fontSize: "12px", color: theme.frontColor.homePageTextSecondary, minWidth: "20px", marginLeft: "0px" }}>
              {`${likes}`}
            </Typography>
            <VisibilityIcon style={{ width: 17, height: 17, color: theme.frontColor.homePageTextSecondary, marginLeft: "3px" }} />
            <Typography sx={{ fontSize: "12px", color: theme.frontColor.homePageTextSecondary, minWidth: "20px", marginLeft: "1px" }}>
              {`${wall.views}`}
            </Typography>
            <Tooltip title={`The board is ${wall.isHorizontal ? "horizontally" : "vertically"} orientated`} arrow>
              <img src={wall.isHorizontal ? horizontalIcon : verticalIcon} style={{ width: 17, height: 17, marginLeft: "10px" }} />
            </Tooltip>
            {owned ?
              <Tooltip title={`Your are the owner`} arrow>
                <StarIcon color="secondary" style={{ width: 17, height: 17, marginLeft: "3px" }} />
              </Tooltip> :
              (wall.realm === "public" ? null :
                <DialogTitle id="fence-content-title" sx={{ color: "white", fontSize: '0.9rem', padding: 0, marginTop: 0 }}>
                  <UserShortCardToolTip user={ownerData}>
                    <PersonIcon style={{ width: 17, height: 17, marginLeft: "3px", color: theme.frontColor.homePageTextSecondary }} />
                  </UserShortCardToolTip>
                </DialogTitle>)
            }
          </div>
        </div>
        <Divider />
        <ClickAwayListener onClickAway={() => { clickAway("description") }}>
          {owned ?
            (<Box sx={editing === "description" ? {} : hoverDiv} onClick={() => { startEditing("description", boardDescription) }}>
              {editing === "description" ? <TextField value={textValue} onChange={handleInputChange} onKeyDown={handleKeyDown} size="small" autoFocus style={{ width: "100%" }}
                InputProps={{
                  style: { fontSize: "12px", padding: '0px', color: "white" }, endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={submitChanges}>
                        <CheckIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }} /> :
                <Typography sx={{ fontSize: "14px", color: theme.frontColor.homePageTextSecondary, }}>{boardDescription}</Typography>}
            </Box>) : (<Typography sx={{ fontSize: "14px", color: theme.frontColor.homePageTextSecondary, }}>{boardDescription}</Typography>)
          }
        </ClickAwayListener>
      </div>
    </CardContent>
  );
}


const CardMediaArea = ({ wall, owned, size }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [previewImage, setPreviewImage] = useState(wall.previewImage);
  const uploadingInputRef = useRef(null);
  const theme = useTheme();

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = async () => {
          system_operations.set_loading(true);
          const tempCanvas = document.createElement('canvas');
          tempCanvas.width = img.width;
          tempCanvas.height = img.height;
          const ctx = tempCanvas.getContext('2d');
          ctx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
          ctx.drawImage(img, 0, 0);
          const base64 = tempCanvas.toDataURL();

          const [desiredW, desiredH] = ImageProcessor.static_fitImageSize(img.width, img.height, MAX_PREVIEW_SIZE, MAX_PREVIEW_SIZE, MAX_PREVIEW_OVERSIZE, false);
          const base64PreviewImageData = await ImageProcessor.static_resizeBase64Image(base64, desiredW, desiredH);

          const updateData = await RequestDataMaker.getDataToUpdateboard(system_operations, wall.wallID, { usePlace: "preview", imageData: base64PreviewImageData.resizedBase64 })
          const updateResult = await system_operations.restServerConnector.updateBoardDisplaySetting(updateData);

          if (updateResult && updateResult.success && updateResult.url) {
            wall.previewImage = updateResult.url;
            setPreviewImage(updateResult.url);
          }
          system_operations.set_loading(false);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  const triggerFileUpload = () => {
    if (owned && uploadingInputRef.current) {
      uploadingInputRef.current.click();
    }
  }

  return (
    <>
      <CardMedia
        component="img"
        image={previewImage}
        alt={wall.description}
        sx={{
          width: size, // Adjust this value for your needs
          objectFit: 'cover', // This will make the image fit into the square
          height: size, // Making width and height equal will result in a square
          cursor: owned ? 'pointer' : 'default',
          ':hover': owned ? {
            boxShadow: '0 5px 15px rgba(0,0,0,0.32)', // state of the box-shadow on hover
            transform: 'scale(1.025)',
            transition: 'all 0.3s ease', // for a smooth transitions
          } : {}
        }}
        onClick={owned ? triggerFileUpload : undefined}
      />
      {owned && <input
        type="file"
        ref={uploadingInputRef}
        style={{ display: 'none' }}
        onChange={onImageChange}
        accept="image/*" />
      }
    </>
  );
}


const MainPanelDisplayWalls = ({ shortenDisplay }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [currentTab, setCurrentTab] = useState(0);
  const [walls, setWalls] = useState(system_operations.homePageManager.mainDisplayWalls);
  const refreshCountRef = useRef(0);
  const [refreshCount, setRefreshCount] = useState(refreshCountRef.current);
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState("BOARDS");

  const theme = useTheme();

  useEffect(() => {
    system_operations.addToOnUserChange("fromMainPanelDisplay", () => { refreshCountRef.current += 1; setRefreshCount(refreshCountRef.current); });
    system_operations.addFunctionToDict(system_operations.homePageManager.onMainDisplayWallsChange, "homeDisplayedWalls", setWalls);
    system_operations.homePageManager.setLoadingHook = setLoading;
    system_operations.homePageManager.setLabelHook = setLabel;
    system_operations.homePageManager.showTrending();
  }, [])

  const newBoardCreationStart = () => {
    system_operations.setContentWindow(<CustomizeNewBoard />)
  }

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        borderRadius: theme.borderRadius.homePageUI,
        padding: 2,
        width: "100%",
        height: '100%',
        backgroundColor: theme.backgroundColor.homePageUIBase,
        color: theme.frontColor.homePageTextPrimary,
        overflowY: 'auto', // make it scrollable
      }}
    >
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        sx={{ justifyContent: "flex-start", marginBottom: 2 }}
      >
        <Tab label={label || "BOARDS"} sx={{
          color: theme.frontColor.homePageTextPrimary,
          fontSize: '1.5rem', // Change this to the size you want
        }} />
      </Tabs>
      {walls.length > 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {
            walls.map((wall, index) => {
              return (
                <BoardCard wall={wall} refresh={refreshCount} shortenDisplay={shortenDisplay} key={wall.wallID} />
              )
            })
          }
          {(system_operations.homePageManager.displayingType === "ownBoard" && system_operations.getUserData()?.maxNumberOfBoards > walls.length)?
            <IconButton onClick={newBoardCreationStart} sx={{ width: "100px", height: "100px" }}>
              <ControlPointRoundedIcon sx={{ width: "100%", height: "100%" }} />
            </IconButton> : <div style={{color: "#bbbbbb", userSelect: "none"}}>Cannot have more boards</div>
          }
        </div>
      ) : (
        loading ?
          <Typography align="center">loading...</Typography> :
          <Typography align="center">No board to display</Typography>
      )}
    </Box>
  );
};

const CustomizeNewBoard = () => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [displayName, setDisplayName] = React.useState('Board of Magic!');
  const [description, setDescription] = React.useState('...');
  const [isHorizontal, setIsHorizontal] = React.useState(true);

  const updateDisplayName = (e) => {
    setDisplayName(e.target.value);
  };

  const updateDescription = (e) => {
    setDescription(e.target.value);
  };

  const updateIsHorizontal = () => {
    setIsHorizontal(!isHorizontal);
  }

  const uploadBoard = async () => {
    system_operations.set_loading(true);
    const boardCreationData = await RequestDataMaker.getDataToCreateABoard(system_operations, system_operations.getUserID(), displayName, description, isHorizontal);
    const result = await system_operations.restServerConnector.createABoard(boardCreationData);
    if (result && result.success && result.board) {
      const newBoard = result.board;
      newBoard.wallID = result.boardID;
      system_operations.homePageManager.ownBoards[newBoard.boardID] = newBoard;
      system_operations.wallsData.insertABoardData(newBoard);
      system_operations.homePageManager.showOwnBoards(system_operations.getUserID(), true);
    }
    system_operations.setContentWindow(null)
    system_operations.set_loading(false);
  }

  return (
    <div style={{
      backgroundColor: "rgba(120,120,120, 0.9)", borderRadius: 8, width: "500px"
    }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        p={2}
        sx={{ boxSizing: 'border-box' }}
      >
        <Typography variant="h4" component="h2" gutterBottom>
          New Board
        </Typography>
        <TextField
          label="Display Name"
          variant="outlined"
          value={displayName}
          onChange={updateDisplayName}
          fullWidth
          sx={{ mb: 2, color: "white" }}
        />
        <TextField
          label="Description"
          variant="outlined"
          value={description}
          onChange={updateDescription}
          fullWidth
          sx={{ mb: 2, color: "white" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isHorizontal}
              onChange={updateIsHorizontal}
              color="primary"
            />
          }
          label="Horizontal Orientated"
        />
        <Button variant="contained" color="primary" onClick={uploadBoard}>
          CREATE BOARD!
        </Button>
      </Box>
    </div>
  );
}

export default MainPanelDisplayWalls;