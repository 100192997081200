import io from 'socket.io-client';
import { FloatingCanvasRect, PostInstanceRect } from '../system/PaperRects.js';

// const socket_address = 'ws://localhost:8080';
// const socket_address = 'https://34.125.159.65:8433';
const socket_address = 'https://server.drawall.world:8433';

export class SocketConnector {
  constructor(system) {
    this.system = system;
    this.fullyInitialized = false;
    this.serverIsReady = false;
    this.userData = null;
    this.socketID = "";
    this.userID = "";
    this.idToken = "";
    this.wallID = "";
    this.wallData = null;
    this.socket = null;
    this.on_receive = {};
    this.on_emit = {};
    this.onServerStageChange = {};

    this.badConnection = false;
    this.properlyDisconnected = false;
    this.waitingForReconnection = false;
  }

  async connect(userData, wallData) {
    if (!this.serverIsReady || this.userID !== userData?.userID || this.idToken !== userData?.idToken || this.wallID !== wallData?.wallID || this.badConnection) {
      if (!userData) {
        userData = { userID: "guest" }
      }
      this.userData = userData;
      this.userID = userData.userID;
      this.idToken = await this.system.obtainIDToken(false);
      this.wallID = wallData.wallID;
      this.wallData = wallData;
      this.waitingForReconnection = false;
      this.socket = io(socket_address);
      this.socket.on('connect', () => {
        if (this.waitingForReconnection) {
          this.waitingForReconnection = false;
          alert("Good news! Server is reconnected!!!");
        }
        this.properlyDisconnected = false;
        const data = {
          userData: this.userData,
          boardID: this.wallID,
        }
        this.socket.emit('initializationData', data);
      });

      this.socket.on('serverReady', (socketID) => {
        this.setServerStage(true);
        this.socketID = socketID;
      });

      this.socket.on('addView', (signal) => {
        this.wallData.views += 1;
      });

      /*{
        socketID: ...
        type: reproduce(rp) || update(up)
        reprocude -> drawData: {
          drawMarks,
          drawSection
        }
        updateGrids: []
      }*/
      this.socket.on('um', (data) => {
        console.log(data);
        if (data.socketID !== this.socketID) {
          const coordinator = this.system.coordinator;
          if (data.type === "up") {
            const updateGrids = data.updateGrids;
            for (const grid of updateGrids) {
              // for every single grids, update the url of it...
              coordinator.gridsSections.updateGridUrlVersionGloballyAt(grid[0], grid[1]); // if doesn't exist yet, then will do nothing.
            }
          }
        }
      });

      /*{
        type: reproduce(rp) || update(up)
        socketID: ...
        canvasID: ...
        reprocude -> drawMarks: ...
      }*/
      this.socket.on('uf', (data) => {
        console.log("other' fc draw data received.");
        if (data.socketID !== this.socketID) {
          const savedData = this.system.coordinator.sectionData.sectionLoaderForCanvases.getFromSavedData(data.canvasID);
          if (savedData) {
            savedData.reloadCanvasData();
          }
        }
      });

      /*{
        socketID,
        updateArray: [
          {type...},
          {type...},
          {type...},
        ]
      }*/
      this.socket.on("updatepaper", (updateData) => {
        console.log(updateData)
        if (updateData.socketID !== this.socketID) {
          for (const item of updateData.updateArray) {
            if (item.type === "fc") {
              this.updateFC(item);
            } else if (item.type === "pi") {
              this.updatePI(item);
            } else {
              // this.updateAB(item);
            }
          }
        } else {
          console.log("blocked...");
        }
      });

      /*{
        socketID: ...
        operation: "add", "remove"
        updateData: {
          ?fenceID
          ?newFence,
        }
      }*/
      this.socket.on('updatefence', (data) => {
        const operation = data.operation;
        if (operation === "add") {
          this.system.coordinator.fenceLoader.updateFence(operation, data.updateData.newFence);
        } else if (operation === "remove") {
          this.system.coordinator.fenceLoader.updateFence(operation, data.updateData.newFence);
        }
      });

      this.socket.on('disconnect', () => {
        if (!this.properlyDisconnected) {
          this.waitingForReconnection = true;
          alert("Oh no... We are sorry, but the server is disconnected... Server is working on its best to get itself back...");
        }
        this.fullyInitialized = false;
        this.setServerStage(false);
      });
    }
  }

  // floating data changed
  /*{
    socketID: ...
    operation: "remove", "update", "add",
    updateData: {
    ?canvasID...
    ?canvas...
    ?changes...
    }
  }*/
  updateFC(data) {
    if (data.userID !== this.userID) {
      if (data.operation === "remove") {
        // delete the fc canvas from the data.
        this.system.coordinator.sectionData.deleteItemFromCanvasLoader(this.system.coordinator.sectionData.sectionLoaderForCanvases.getFromSavedData(data.canvasID));
      } else if (data.operation === "update") {
        const savedRect = this.system.coordinator.sectionData.sectionLoaderForCanvases.getFromSavedData(data.canvasID);
        if (savedRect && data.changes) {
          const savedData = savedRect.getData();
          savedData.x = (data.changes.x !== undefined) ? data.changes.x : savedData.x;
          savedData.y = (data.changes.y !== undefined) ? data.changes.y : savedData.y;
          savedData.width = (data.changes.width !== undefined) ? data.changes.width : savedData.width;
          savedData.height = (data.changes.height !== undefined) ? data.changes.height : savedData.height;
          savedData.layer = (data.changes.layer !== undefined) ? data.changes.layer : savedData.layer;
          savedRect.resetChanges()
          this.system.coordinator.sectionData.updateCanvasLoaderItemPosition(savedRect)
        }
      } else if (data.operation === "add") {
        const savedRect = this.system.coordinator.sectionData.sectionLoaderForCanvases.getFromSavedData(data.canvas.canvasID);
        if (!savedRect) {
          const newRect = new FloatingCanvasRect(
            data.canvas, "canvasID", "floatingCanvases",
            this.system.coordinator.sectionData.updateCanvasLoaderItemPosition.bind(this.system.coordinator.sectionData),
            this.system.coordinator.pendingCanvasSystem, this.system
          );
          this.system.coordinator.sectionData.insertNewItemToCanvasLoader(newRect)
        }
      }
    }
  }

  // floating data changed
  /*{
    socketID: ...
    operation: "remove", "update", "add",
    ?postInstanceID...
    ?postInstance...
    ?changes...
  }*/
  updatePI(data) {
    if (data.userID !== this.userID) {
      if (data.operation === "remove") {
        this.system.coordinator.sectionData.deleteItemFromPostLoader(this.system.coordinator.sectionData.sectionLoaderForPosts.getFromSavedData(data.postInstanceID));
      } else if (data.operation === "update") {
        const savedRect = this.system.coordinator.sectionData.sectionLoaderForPosts.getFromSavedData(data.postInstanceID);
        if (savedRect && data.changes) {
          const savedData = savedRect.getData();
          savedData.x = (data.changes.x !== undefined) ? data.changes.x : savedData.x;
          savedData.y = (data.changes.y !== undefined) ? data.changes.y : savedData.y;
          savedData.width = (data.changes.width !== undefined) ? data.changes.width : savedData.width;
          savedData.height = (data.changes.height !== undefined) ? data.changes.height : savedData.height;
          savedData.layer = (data.changes.layer !== undefined) ? data.changes.layer : savedData.layer;
          savedRect.resetChanges()
          this.system.coordinator.sectionData.updatePostLoaderItemPosition(savedRect)
        }
      } else if (data.operation === "add") {
        const savedRect = this.system.coordinator.sectionData.sectionLoaderForPosts.getFromSavedData(data.postInstance.postInstanceID);
        if (!savedRect) {
          const newRect = new PostInstanceRect(
            data.postInstance, "postInstanceID", "posts",
            this.system.coordinator.sectionData.updatePostLoaderItemPosition.bind(this.system.coordinator.sectionData),
            this.system.coordinator.pendingPostInstancesSystem, this.system
          );
          this.system.coordinator.sectionData.insertNewItemToPostLoader(newRect);
        }
      }
    }
  }

  disconnect() {
    if (this.socket) {
      this.properlyDisconnected = true;
      this.socket.disconnect();
      this.socketID = ""
      this.setServerStage(false);
      this.wallData = null;
      this.fullyInitialized = false;
    }
  }

  setServerStage(stage) {
    this.serverIsReady = stage;
    for (const key in this.onServerStageChange) {
      this.onServerStageChange[key](this.serverIsReady);
    }
  }

  drawDataToEmitData(drawData, wallID, userRole) {
    drawData.userID = this.userID;
    drawData.loggedIn = this.system.userData.loggedIn;
    drawData.wallID = wallID;
    drawData.userRole = userRole;
    return drawData;
  }

  async fcDataToEmitData(drawMarks, canvasID, boardID, userRole) {
    const fcData = {};
    fcData.drawMarks = drawMarks;
    fcData.userID = this.userID;
    fcData.loggedIn = this.system.userData.loggedIn;
    fcData.canvasID = canvasID;
    fcData.boardID = boardID;
    fcData.userRole = userRole;
    fcData.idToken = await this.system.obtainIDToken();
    return fcData;
  }

  PaperUpdateDataToEmitData(operation, updateData) {
    const emitData = { ...updateData };
    emitData.userID = this.userID;
    emitData.loggedIn = this.system.userData.loggedIn;
    emitData.operation = operation;
    return emitData;
  }


  // data is a json, contains all needed data.
  emit_to_socket(type, data) {
    this.system.obtainIDToken(false);
    this.socket.emit(type, data);
    for (const key in this.on_emit) {
      this.on_emit[key](type, data);
    }
  }

  add_to_on_receive(key, func) {
    this.on_receive[key] = func;
  }

  add_to_on_emit(key, func) {
    this.on_emit[key] = func;
  }

  addToOnServerStageChange(key, func) {
    this.onServerStageChange[key] = func;
  }

  remove_from_on_receive(key) {
    delete this.on_receive[key];
  }

  remove_from_on_emit(key) {
    delete this.on_emit[key];
  }

  removeFromOnServerStageChange(key) {
    delete this.onServerStageChange[key];
  }
}