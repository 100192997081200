import React, { useMemo, useEffect, useState, useContext, useRef } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { RectCollisionSystem } from '../system/collisionSystem/CollisionEngine';
import { CollidableRect } from '../system/collisionSystem/CollisionEngine';


// all the value pass in should be theorectical. No need to pre scale.
// when calculate collisions, use theo space. use screenspace only when in style for displaying.
// When position changed, do not use tempData for transform changes. Use the result from collision system, and then assign the result back to the tempData (Using functions)
export const FlexRect = ({
  dataRect, lookStyle, defaultCollisions, colliderTrackerCollections, children, zIndex, activeDz, inactiveDz, eventListeners,
  flexBoxID, editable, interactable = true, type = "uncategorized", resizable = true, maxWidth = null, maxHeight = null, minWidth = 128, minHeight = 128, getAllMagnetRects = () => { return [] }
}) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const stateRef = useRef({
    x: dataRect.getActiveX(),
    y: dataRect.getActiveY(),
    width: dataRect.getActiveWidth(),
    height: dataRect.getActiveHeight(),
  });
  const [collisionCollections, setCollisionCollections] = useState(defaultCollisions);
  const draggingFlag = useRef(false);
  // const magnetsDicts = useRef(false);
  const usingMagnet = useRef(false);
  const magnetsDicts = useRef([[], []]);


  const containerRef = useRef(null);
  const dragMoveRef = useRef(null);
  const dataRef = useRef(dataRect.getData());
  // const collidableRect = useRef(new CollidableRect(dataRef.current.x, dataRef.current.y, dataRef.current.width, dataRef.current.height, flexBoxID, type, dataRect.getData(), maxWidth, maxHeight));
  const draggingTheoOffset = useRef([0, 0]);
  const specialDragType = useRef("");
  if (!dataRect.collider) {
    dataRect.collider = new CollidableRect(dataRef.current.x, dataRef.current.y, dataRef.current.width, dataRef.current.height, flexBoxID, type, dataRect, maxWidth, maxHeight, minWidth, minHeight);
  }

  const [displayStyle, setDisplayStyle] = useState({
    ...lookStyle,
    zIndex: zIndex,
    position: "absolute",
    left: dataRect.getActiveX(),
    top: dataRect.getActiveY(),
    width: dataRect.getActiveWidth(),
    height: dataRect.getActiveHeight(),
    pointerEvents: interactable ? 'auto' : 'none',
  });
  const refreshCounter = useRef(0);
  const [refresh, setRefresh] = useState(0);


  useEffect(() => {
    // reset back to the unchanged state
    dataRect.func.reset = () => {
      dataRect.collider.forcefulTransform(dataRef.current.x, dataRef.current.y, dataRef.current.width, dataRef.current.height);
      updateChangeHelper();
    }
    dataRect.func.setTransform = (left, top, width, height) => {
      dataRect.collider.forcefulTransform(left, top, width, height);
      updateChangeHelper();
    }

    // for outside component which is trying to change level
    dataRect.func.rectSetCollision = (newCollisionCollections) => {
      setCollisionCollections(newCollisionCollections);
    }
    dataRect.func.rectRefresh = () => {
      refreshCounter.current += 1;
      setRefresh(refreshCounter);
    }
  }, [dataRect])

  useEffect(() => {
    const newStyle = {
      ...lookStyle, zIndex: zIndex, position: "absolute",
      left: stateRef.current.x,
      top: stateRef.current.y,
      width: stateRef.current.width,
      height: stateRef.current.height,
      pointerEvents: interactable ? 'auto' : 'none',
    }
    setDisplayStyle(newStyle);
  }, [lookStyle, zIndex, interactable])

  useEffect(() => {
    dataRect.collider.clearCollisionSystemDeep(); // this will remove all current collision collections also remove this rect from those collections.
    for (const collectionObj of collisionCollections) {
      if (collectionObj.collection) {
        if (collectionObj.addToCollection) {
          collectionObj.collection.addRect(dataRect.collider);
        }
        // none check means when the rect moves, the given collection is not check againsted.
        if (!collectionObj.noneCheck) {
          if (collectionObj.byPassRule) {
            dataRect.collider.addACollisionCollection(collectionObj.collection, collectionObj.byPassRule);
          } else {
            dataRect.collider.addACollisionCollection(collectionObj.collection, null, true);
          }
        }
      }
      // }
    }
    // these collider collision are not checked for collision, just for tracking the colliders.
    for (const trackerCollection of colliderTrackerCollections) {
      if (trackerCollection) {
        trackerCollection.addRect(dataRect.collider);
      }
    }

    return () => {
      dataRect.collider.clearCollisionSystemDeep();
      for (const trackerCollection of colliderTrackerCollections) {
        if (trackerCollection) {
          trackerCollection.removeRect(dataRect.collider);
        }
      }
    }
  }, [collisionCollections])

  useEffect(() => {
    const onPointerDown = (event) => {
      if (editable) {
        const mousePos = system_operations.coordinator.boardPositioner.getTheoPositionFromScreenPosition(event.clientX, event.clientY);
        // calculate the drag offset in theoretical space.
        draggingTheoOffset.current[0] = stateRef.current.x - mousePos[0];
        draggingTheoOffset.current[1] = stateRef.current.y - mousePos[1];
        draggingFlag.current = true;
        const auxiliaryLineTool = system_operations.coordinator.toolpack.auxiliaryLine;
        usingMagnet.current = system_operations.coordinator.toolpack.paperEditor.usingMagnet;
        if (usingMagnet.current) {
          magnetsDicts.current = system_operations.coordinator.magnetsMaker.getMagnets(getAllMagnetRects(), { [dataRect.getID()]: true }, auxiliaryLineTool.xAuxiliary, auxiliaryLineTool.yAuxiliary);
        }
        event.target.setPointerCapture(event.pointerId);
        document.addEventListener('pointermove', handleMove);
        document.addEventListener('pointerup', onPointerUp);
      }
    }

    const onPointerUp = (event) => {
      draggingFlag.current = false;
      system_operations.coordinator.setXPlacementGuideLine(-1);
      system_operations.coordinator.setYPlacementGuideLine(-1);
      event.target.releasePointerCapture(event.pointerId);
      document.removeEventListener('pointermove', handleMove);
      document.removeEventListener('pointerup', onPointerUp);
      changeDragType();
    }

    const handleMove = (pointerEvent) => {
      if (editable && draggingFlag.current) {
        const draggingTo = system_operations.coordinator.boardPositioner.getTheoPositionFromScreenPosition(pointerEvent.clientX, pointerEvent.clientY);

        // apply drag offset in theoretical space because that's where the coords are in theo space. And collision is calculated in theo space
        draggingTo[0] += draggingTheoOffset.current[0];
        draggingTo[1] += draggingTheoOffset.current[1];
        const newX = draggingTo[0];
        const newY = draggingTo[1];
        let success = false;

        if (usingMagnet.current) {
          success = dataRect.collider.move(newX, newY, magnetsDicts.current, system_operations.coordinator.setXPlacementGuideLine.bind(system_operations.coordinator), system_operations.coordinator.setYPlacementGuideLine.bind(system_operations.coordinator));
        } else {
          success = dataRect.collider.move(newX, newY);
        }
        if (success) { updateChangeHelper() }
      }
    };

    eventListeners.pointerDown[flexBoxID] = onPointerDown;
    // if (dragMoveRef.current) {
    //   dragMoveRef.current.addEventListener('pointerdown', onPointerDown);
    // }

    return () => {
      delete eventListeners.pointerDown[flexBoxID];
      // if (dragMoveRef && dragMoveRef.current) {
      //   dragMoveRef.current.removeEventListener('pointerdown', onPointerDown);
      // }
      document.removeEventListener('pointermove', handleMove);
      document.removeEventListener('pointerup', onPointerUp);
    };
  }, [editable, zIndex, interactable])

const changeDragType = (type = "") => {
  specialDragType.current = type;
}

const updateChangeHelper = () => {
  const newState = {
    x: dataRect.collider.x,
    y: dataRect.collider.y,
    width: dataRect.collider.width,
    height: dataRect.collider.height,
  }

  const newStyle = {
    ...displayStyle,
    left: newState.x,
    top: newState.y,
    width: newState.width,
    height: newState.height,
    zIndex: zIndex,
    pointerEvents: interactable ? 'auto' : 'none',
  }

  stateRef.current = newState;
  setDisplayStyle(newStyle);
  dataRect.updateTransform(newState);
}

return (
  <div ref={containerRef} style={displayStyle}>
    {children}
    <div data-pp-id={flexBoxID} ref={dragMoveRef} style={{ position: "absolute", left: 0, top: 0, width: displayStyle.width, height: displayStyle.height, zIndex: editable ? activeDz : inactiveDz }} />
    {resizable &&
      <>
        <DraggableEdge isHorizontal={true} thickness={10} dataRect={dataRect} zIndex={(editable ? activeDz : inactiveDz) + 1} edgeID="x1" boxID={flexBoxID} eventListeners={eventListeners} changeDragging={changeDragType} editable={editable} getAllMagnetRects={getAllMagnetRects} updateChangeHelper={updateChangeHelper} />
        <DraggableEdge isHorizontal={true} thickness={10} dataRect={dataRect} zIndex={(editable ? activeDz : inactiveDz) + 1} edgeID="x2" boxID={flexBoxID} eventListeners={eventListeners} changeDragging={changeDragType} editable={editable} getAllMagnetRects={getAllMagnetRects} updateChangeHelper={updateChangeHelper} />
        <DraggableEdge isHorizontal={false} thickness={10} dataRect={dataRect} zIndex={(editable ? activeDz : inactiveDz) + 1} edgeID="y1" boxID={flexBoxID} eventListeners={eventListeners} changeDragging={changeDragType} editable={editable} getAllMagnetRects={getAllMagnetRects} updateChangeHelper={updateChangeHelper} />
        <DraggableEdge isHorizontal={false} thickness={10} dataRect={dataRect} zIndex={(editable ? activeDz : inactiveDz) + 1} edgeID="y2" boxID={flexBoxID} eventListeners={eventListeners} changeDragging={changeDragType} editable={editable} getAllMagnetRects={getAllMagnetRects} updateChangeHelper={updateChangeHelper} />
      </>
    }
  </div>
);
}


const DraggableEdge = React.memo(({ isHorizontal, thickness, dataRect, edgeID, boxID, eventListeners, zIndex, changeDragging, editable, getAllMagnetRects, updateChangeHelper }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [cursor, setCursor] = useState('auto');
  const edgeRef = useRef(null);
  const isDragging = useRef(false);

  const usingMagnet = useRef(false);
  const magnetsDicts = useRef([[], []]);

  const ppID = `${boxID}_${edgeID}`;

  useEffect(() => {
    setCursor(isHorizontal ? 'ew-resize' : 'ns-resize');
  }, [isHorizontal]);

  useEffect(() => {
    const onPointerDown = (event) => {
      event.target.setPointerCapture(event.pointerId);
      window.addEventListener('pointermove', handleDragEdge);
      window.addEventListener('pointerup', onPointerUp);
      isDragging.current = true;
      const auxiliaryLineTool = system_operations.coordinator.toolpack.auxiliaryLine;
      usingMagnet.current = system_operations.coordinator.toolpack.paperEditor.usingMagnet;
      if (usingMagnet.current) {
        magnetsDicts.current = system_operations.coordinator.magnetsMaker.getMagnets(getAllMagnetRects(), { [dataRect.getID()]: true }, auxiliaryLineTool.xAuxiliary, auxiliaryLineTool.yAuxiliary);
      }
    }

    const onPointerUp = (event) => {
      isDragging.current = false;
      event.target.releasePointerCapture(event.pointerId);
      window.removeEventListener('pointermove', handleDragEdge);
      window.removeEventListener('pointerup', onPointerUp);
      system_operations.coordinator.setXPlacementGuideLine(-1);
      system_operations.coordinator.setYPlacementGuideLine(-1);
    }

    const handleDragEdge = (pointerEvent) => {
      const [x, y] = [pointerEvent.clientX, pointerEvent.clientY];
      if (editable && isDragging.current) {
        const draggingTo = system_operations.coordinator.boardPositioner.getTheoPositionFromScreenPosition(x, y);

        let newCoord = 0;
        let magnetsDict = magnetsDicts.current[0];
        let displayFunc = system_operations.coordinator.setXPlacementGuideLine.bind(system_operations.coordinator);
        switch (edgeID) {
          case "x1":
            newCoord = draggingTo[0];
            magnetsDict = magnetsDicts.current[0];
            // let displayFunc = system_operations.coordinator.setXPlacementGuideLine.bind(system_operations.coordinator);
            break;
          case "x2":
            newCoord = draggingTo[0];
            magnetsDict = magnetsDicts.current[0];
            // let displayFunc = system_operations.coordinator.setXPlacementGuideLine.bind(system_operations.coordinator);
            break;
          case "y1":
            newCoord = draggingTo[1];
            magnetsDict = magnetsDicts.current[1];
            displayFunc = system_operations.coordinator.setYPlacementGuideLine.bind(system_operations.coordinator);
            break;
          case "y2":
            newCoord = draggingTo[1];
            magnetsDict = magnetsDicts.current[1];
            displayFunc = system_operations.coordinator.setYPlacementGuideLine.bind(system_operations.coordinator);
            break;
        }
        if (usingMagnet.current && magnetsDicts.current) {

          dataRect.collider.dragEdge(edgeID, newCoord, magnetsDict, displayFunc);
        } else {
          dataRect.collider.dragEdge(edgeID, newCoord);
        }

        updateChangeHelper();
      }
    };

    eventListeners.pointerDown[ppID] = onPointerDown;
    // if (edgeRef.current) { edgeRef.current.addEventListener('pointerdown', onPointerDown, { passive: false }); }

    return () => {
      delete eventListeners.pointerDown[ppID];
      // if (edgeRef && edgeRef.current) { edgeRef.current.removeEventListener('pointerdown', onPointerDown, { passive: false }); }
      window.removeEventListener('pointermove', handleDragEdge);
      window.removeEventListener('pointerup', onPointerUp);
    };
  }, [editable, dataRect])

  const style = {
    pointerEvents: editable ? "auto" : "none",
    position: "absolute",
    // backgroundColor: "green",
    cursor: cursor,
    zIndex: zIndex, // needs to be greater than the parent.
  }

  const halfDisplayThickness = Math.floor(thickness / 2);

  switch (edgeID) {
    case "x1":
      style.left = -halfDisplayThickness;
      style.width = thickness;
      style.top = halfDisplayThickness
      style.bottom = halfDisplayThickness;
      break;

    case "x2":
      style.right = -halfDisplayThickness;
      style.width = thickness;
      style.top = halfDisplayThickness
      style.bottom = halfDisplayThickness;
      break;

    case "y1":
      style.top = -halfDisplayThickness;
      style.height = thickness;
      style.left = halfDisplayThickness;
      style.right = halfDisplayThickness;
      break;

    case "y2":
      style.bottom = -halfDisplayThickness;
      style.height = thickness;
      style.left = halfDisplayThickness;
      style.right = halfDisplayThickness;
      break;

    default:
      style.left = -halfDisplayThickness;
      style.width = thickness;
      style.top = halfDisplayThickness
      style.bottom = halfDisplayThickness;
      break;

  }

  return (
    <>
      <div
        ref={edgeRef}
        data-pp-id={ppID}
        style={style}
        onMouseEnter={() => setCursor(isHorizontal ? 'ew-resize' : 'ns-resize')}
        onMouseLeave={() => setCursor('auto')}
      ></div>
    </>
  );
});


