import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import Default_Avatar from '../img/Default_Avatar.png';
import SamplePost from '../img/Elsa_Cute.jpg';
import { PostMainPanel } from "../cross_page_components/PostMedia.jsx";
import { ShadedImagePostCard } from "../components/PostsCanvas.jsx";

import PendingIcon from '@mui/icons-material/Pending';
import Button from '@mui/material/Button';

// Material UI components
import { Typography, Avatar, ListItem, ListItemAvatar, List, Paper } from '@mui/material';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';

const ImagePostContainer = styled('div')({
  position: "relative",
  width: '160px',
  height: '160px',
  maxWidth: '256px',
  maxHeight: '256px',
  objectFit: 'cover',
  transition: 'filter 0.3s ease',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '12px',
  '&:hover': {
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-2px)',
    filter: 'brightness(1.1)',
  },
});

const PostList = () => {
  const { system_operations } = useContext(SystemOperationsContext);
  const defaultUser = useRef({
    userID: "",
    email: "none",
    username: "sign in",
    avatar: "none",
    recentVisit: []
  });
  const [userData, setUserData] = useState(system_operations.getUserData() || defaultUser.current);
  const [refreshCount, setRefreshCount] = useState(0);
  const [hasMorePosts, setHasMorePosts] = useState(true);

  const refreshCountRef = useRef(0);

  const loadAmount = 16;
  const isLoading = useRef(false);
  const cursorValue = useRef(9999999999999);
  const numberOfPostFetched = useRef(0);
  const postList = useRef([]);

  const rerender = () => {
    // refreshCountRef.current = + 1;
    setRefreshCount(refreshCount + 1);
  }

  const OnUserChange = (uData) => {
    setUserData(uData || defaultUser.current);
  };
  system_operations.addToOnUserChange("fromPostList", OnUserChange);

  useEffect(() => {
    const loadRecentVisit = async () => {
      if (userData.userID) {
        await loadMorePosts();
      }
    };
    loadRecentVisit();
  }, [userData]);

  const handlePostClick = async (post) => {
    system_operations.postSystem.currentViewingPost = post;
    system_operations.setContentWindow(<PostMainPanel isCreating={false} existingPost={post} postInstanceRef={null} style={{ borderRadius: "10px" }} />);
  }

  const loadMorePosts = async (buttonClicked = false) => {
    if (!isLoading.current) {
      isLoading.current = true;
      const filters = {
        amount: loadAmount,
        startFrom: cursorValue.current, // starting from variable called "currentIndex"
        orderByField: 'date', // configure to load result basing on "date" param of the document
        orderDirection: 'desc' // ordering by date in ascending order
      };
      const results = await system_operations.firestoreActions.fetchFromFirestore(`users/${system_operations.getUserID()}/posts`, "postID", [], filters);
      if (results) {
        for (let i = 0; i < results.length; i++) {
          postList.current[numberOfPostFetched.current + i] = results[i]; // append into the array...
          cursorValue.current = results[i].date;
        }
        numberOfPostFetched.current += results.length;
        if (results.length < loadAmount && buttonClicked) {
          setHasMorePosts(false);
        }
        rerender();
      } else if (buttonClicked) {
        setHasMorePosts(false);
      }
      isLoading.current = false;
    }
  }
  return (
    <div className="home-post-container home-post-container--unselectable">
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: 1 }}>
          <p>-Showcase-</p>
          <hr className='home-page-horizontal-line' />
        </div>
        <div style={{ flex: 15, overflow: 'auto' }}>
          <div className='scroll-area' style={{ height: "100%" }}>
            <ul>
              {postList.current.map((post, index) => {
                return (<li
                  onClick={() => handlePostClick(post)}
                  key={index}
                >
                  <ImagePostContainer>
                    <ShadedImagePostCard postObject={post} postInstanceRect={null} />
                  </ImagePostContainer>
                </li>
              )})}
              {hasMorePosts && <Button sx={{
                position: "relative",
                width: '160px',
                height: '160px',
                maxWidth: '256px',
                maxHeight: '256px',
                borderRadius: '12px',
              }} onClick={() => loadMorePosts(true)}>
                <PendingIcon sx={{ width: '100%', height: '100%', color: "#f0f0f0" }} />
              </Button>}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostList;