/*
File name: WallDependentSystems.js
Author: Weiyan Tao
Date last modified: 08/03/2023
Description: Includes multiple classes that are designed to work within a specific board.
*/


import { SectionLoaderSimple } from "../utility/SectionLoader.js";
import { StorageTalker } from '../auth/FirestoreActions.js'
import { PaperRects, FloatingCanvasRect, PostInstanceRect } from '../system/PaperRects.js'
import { RequestDataMaker } from "../system/RestServerConnector.js"

import { fenceByPassRuleFC as paperFenceByPassRule } from "./PaperRects.js"

export class WallSectionalDataSet {
  constructor(coordinator) {
    this.coordinator = coordinator;

    this.sectionLoaderForCanvases = null;
    // this.sectionLoaderForPosts = null;
    // this.sectionLoaderForButtons = null;
    // this.sectionLoaderForContentMarks = null;
  }

  // have data dependency from the parent "coordinator". Only call this when all the data is ready in coordinator.
  initializeData() {
    // this.createPostsLoader();
    // this.createContentMarksLoader();
    this.createCanvasesLoader();
    // this.createButtonsLoader();
  }

  createCanvasesLoader() {
    const fetchFunctionForCanvases = async (sectionIndex, coord, lookBackDistance, lookFrontDistance) => {
      // fetch all the posts which' x is within the area
      const canvases = await this.coordinator.system.firestoreActions.fetchFromFirestore(`boards/${this.coordinator.wallData.wallID}/canvases`, "canvasID",
        [{ field: "x", query: [{ sign: '>=', value: coord }, { sign: '<=', value: coord + lookFrontDistance }] }]);
      const canvasRects = [];
      for (const canvas of canvases) {
        const canvasRect = new FloatingCanvasRect(canvas, "canvasID", "floatingCanvases", this.updateCanvasLoaderItemPosition.bind(this), this.coordinator.pendingCanvasSystem, this.coordinator.system);
        canvasRects.push(canvasRect);
      }
      return canvasRects;
    }
    const readFunctionForCanvases = (canvasRect) => {
      const start = canvasRect.getCoord();
      const length = canvasRect.getActiveLength();
      return { id: canvasRect.getID(), start: start, end: start + length }
    }

    this.sectionLoaderForCanvases = new SectionLoaderSimple(fetchFunctionForCanvases, 4096, readFunctionForCanvases);

    const onChangeFunction = (coord) => {
      // console.log("read at coord: ", coord);
      this.sectionLoaderForCanvases.discover_cache_async(coord).then((resultArray) => {
        this.coordinator.updateCanvases(resultArray);
      });
    }
    this.coordinator.onCoordinateChange["self_canvases_loader"] = onChangeFunction;
  }

  insertNewItemToCanvasLoader(item) {
    this.sectionLoaderForCanvases.insertToLoadedZone(item);
    this.sectionLoaderForCanvases.discover_cache_async(this.coordinator.getTheoreticalOffset()).then((resultArray) => {
      this.coordinator.updateCanvases(resultArray);
    });
  }

  updateCanvasLoaderItemPosition(item) {
    this.sectionLoaderForCanvases.updatePositionInLoadedZone(item);
    this.sectionLoaderForCanvases.discover_cache_async(this.coordinator.getTheoreticalOffset()).then((resultArray) => {
      this.coordinator.updateCanvases(resultArray);
    });
  }

  deleteItemFromCanvasLoader(item) {
    this.sectionLoaderForCanvases.deleteFromLoadedZone(item);
    this.sectionLoaderForCanvases.discover_cache_async(this.coordinator.getTheoreticalOffset()).then((resultArray) => {
      this.coordinator.updateCanvases(resultArray);
    });
  }

  getItemFromContentMark(itemID) {
    return null;
  }
}

export class GuideBox {
  constructor(coordinator) {
    this.active = false;
    this.activationHook = null;
    this.absoluteDisallowedCSLayers = []; // can't collide anytime. 
    this.permissionOnlyCS = null; // for things like fence, needs the permission to collide/fully collided
    this.guideBoxStyle = {};
    this.updatePosition = null;
    this.checkAuthLevel = null;
    this.submit = null;
    this.data = null;
    this.coordinator = coordinator;
  }

  setActive(guideBoxStyle, updatePosition, submit, absoluteDisallowedCSLayers, permissionOnlyCS, checkAuthLevel, data = null) {
    if (guideBoxStyle && updatePosition && submit) {
      this.guideBoxStyle = guideBoxStyle;
      this.updatePosition = updatePosition;
      this.submit = submit;
      this.absoluteDisallowedCSLayers = Array.isArray(absoluteDisallowedCSLayers) ? absoluteDisallowedCSLayers : [absoluteDisallowedCSLayers]; // in case a single collision collection is passed.
      this.permissionOnlyCS = permissionOnlyCS;
      this.checkAuthLevel = checkAuthLevel;
      this.data = data;
      this.active = true;
      this.activationHook(true);
    } else {
      this.data = null;
      this.active = false;
      this.activationHook(false);
    }
  }

  cancelGuideBoxPlacement() {
    this.data = null;
    this.active = false;
    this.activationHook(false);
  }

  // will fix the mouse position to make sure the given rect is not exceeding the bundary of the board.
  // x and y are theoretical event position
  fixAndSetPosition(x, y) {
    if (this.updatePosition) {
      this.bundaryX = [0, this.coordinator.theoreticalBoardWidth];
      this.bundaryY = [0, this.coordinator.theoreticalBoardHeight]
      const fixMove = [0, 0];
      let [collisionX, collisionY] = [0, 0]
      if (x < this.bundaryX[0]) {
        collisionX = x; collisionY = y;
        fixMove[0] = this.bundaryX[0] - x;
      } else if (x + this.guideBoxStyle.width > this.bundaryX[1]) {
        collisionX = this.bundaryX[1]; collisionY = y;
        fixMove[0] = this.bundaryX[1] - (x + this.guideBoxStyle.width);
      }

      if (y < this.bundaryY[0]) {
        collisionX = x; collisionY = y;
        fixMove[1] = this.bundaryY[0] - y;
      } else if (y + this.guideBoxStyle.height > this.bundaryY[1]) {
        collisionX = x; collisionY = this.bundaryY[1];
        fixMove[1] = this.bundaryY[1] - (y + this.guideBoxStyle.height);
      }

      this.guideBoxStyle.x = Math.round(x + fixMove[0]);
      this.guideBoxStyle.y = Math.round(y + fixMove[1]);
      this.updatePosition(this.guideBoxStyle.x, this.guideBoxStyle.y);
    }
  }

  setPosition(x, y) {
    if (this.updatePosition) {
      this.guideBoxStyle.x = x;
      this.guideBoxStyle.y = y;
      this.updatePosition(x, y);
    }
  }

  getTheoPosition() {
    return [this.guideBoxStyle.x, this.guideBoxStyle.y];
  }

  async submitChange(extra) {
    if (this.submit) {
      await this.submit(extra);
    }
  }

  absoluteDisallowedCollision() {
    let layer = 0;
    for (const collisionCollection of this.absoluteDisallowedCSLayers) {
      const result = collisionCollection.checkCollision(this.guideBoxStyle.x, this.guideBoxStyle.y, this.guideBoxStyle.width, this.guideBoxStyle.height)
      if (result.length === 0) {
        return layer;
      }
      layer += 1;
    }
    if (layer === 0) { return layer; } // this means no collision collection was in/checked.
    return -1; // went through all layers, but can't find a fit.
  }

  // this doesn't allow for the second layer check
  permissionOnlyCollision() {
    if (this.permissionOnlyCS) {
      const result = this.permissionOnlyCS.checkCollision(this.guideBoxStyle.x, this.guideBoxStyle.y, this.guideBoxStyle.width, this.guideBoxStyle.height)
      if (result.length === 0) {
        return true;
      } else {
        let permitted = true;
        for (const fenceCol of result) {
          if (fenceCol.rect && !this.checkAuthLevel(fenceCol.rect)) { // basically check if in a fence area that is owned by the user.
            permitted = false;
          }
        }
        return permitted;
      }
    }
    return true;
  }

  allowanceCheck() {
    const permission = this.permissionOnlyCollision();
    if (permission) {
      const layer = this.absoluteDisallowedCollision();
      return { allow: (layer !== -1 && permission), layer: layer };
    }
    return { allow: false, layer: -1 };
  }
}

class PendingList {
  constructor(system, coordinator) {
    this.system = system;
    this.coordinator = coordinator;

    // pending items are rects rather than plain data.
    this.pendingList = { upload: {}, update: {}, delete: {} };
    this.onPendingListChange = {};

    this.onSubmit = {};
    this.onCancel = {};
  }

  // no need to override
  addToPendingList(itemID, rect, operation) {
    this.pendingList[operation][itemID] = rect;
    this.onPendingListModified(false);
  }

  // no need to override
  removeFromPendingList(canvasID) {
    delete this.pendingList["upload"][canvasID];
    delete this.pendingList["update"][canvasID];
    delete this.pendingList["delete"][canvasID];

    const empty = (Object.keys(this.pendingList["upload"]).length === 0 && Object.keys(this.pendingList["update"]).length === 0 && Object.keys(this.pendingList["delete"]).length === 0)
    this.onPendingListModified(empty);
  }

  // no need to override
  cleanUpPendingList() {
    this.pendingList = { upload: {}, update: {}, delete: {} };
    this.onPendingListModified(true);
  }

  // no need to override
  onPendingListModified(empty) {
    // console.log(this.pendingList);
    for (const key in this.onPendingListChange) {
      this.onPendingListChange[key](this.pendingList, empty);
    }
  }

  processANewUpdate(key) {
    const rect = this.pendingList.update[key]; // for every modified canvases.
    const data = rect.getData();
    data.x = (rect.tempData._x !== undefined) ? rect.tempData._x : data.x;
    data.y = (rect.tempData._y !== undefined) ? rect.tempData._y : data.y;
    data.width = (rect.tempData._width !== undefined) ? rect.tempData._width : data.width;
    data.height = (rect.tempData._height !== undefined) ? rect.tempData._height : data.height;
    data.layer = (rect.tempData._layer !== undefined) ? rect.tempData._layer : data.layer;
    if (rect.tempData.transformChanged) { rect.tempData.transformChanged = false; }
    this.saveAUpdatePendingItem_effects(rect)
  }

  addADeletePendingItem_effects(rect) { } // should only mark it for deletion rather really delete it.

  saveAUpdatePendingItem_effects(rect) { }

  saveADeletePendingItem_effects(rect) { } // Now is the time to really delete it.

  // override
  processANewUpload(key, uploaded) { }

  // override
  revokeAUpload(key, uploaded) { }

  // override
  revokeAUpdate(key, uploaded) { }

  // override
  cleanUpUploadData(key) {
    return this.pendingList.upload[key];
  }
  // override
  cleanUpUpdateData(key) {
    return this.pendingList.update[key];
  }
  // override
  cleanUpDeleteData(key) {
    return this.pendingList.delete[key];
  }

  // no need to override
  processPendingList() {
    const allUpload = {};
    for (const key of Object.keys(this.pendingList.upload)) {
      allUpload[key] = this.cleanUpUploadData(key);
    }
    const allUpdate = {};
    for (const key of Object.keys(this.pendingList.update)) {
      allUpdate[key] = this.cleanUpUpdateData(key);
    }
    const allDelete = {};
    for (const key of Object.keys(this.pendingList.delete)) {
      allDelete[key] = this.cleanUpDeleteData(key);
    }
    return { upload: allUpload, update: allUpdate, delete: allDelete };
  }

  // no need to override
  onSubmitEvents() {
    for (const key in this.onSubmit) { this.onSubmit[key]() }
  }

  // no need to override
  finishSubmitPendingList(changeResult) {
    for (const key in this.onSubmit) { this.onSubmit[key]() }
    if (changeResult) {
      for (const key in changeResult.upload) {
        this.processANewUpload(key, changeResult.upload[key]);
      }
      // for pending list
      for (const key in this.pendingList.update) {
        this.processANewUpdate(key, changeResult.update[key]);
      }
    }
    this.cleanUpPendingList();
  }

  // no need to override
  giveUpPendingList() {
    for (const key in this.onCancel) { this.onCancel[key]() }
    this.cancelAll();
  }

  cancelAUploadPendingItem_effects(rect) { console.log("base one called?"); }

  cancelAUpdatePendingItem_effects(rect) { }

  cancelADeletePendingItem_effects(rect) { }

  cancelAll() {
    // console.log(this.pendingList.upload);
    for (const key in this.pendingList.upload) {
      const rect = this.pendingList.upload[key];
      console.log("I am about to call the effect : ", rect);
      this.cancelAUploadPendingItem_effects(rect);
      console.log("Effect called");
    }

    for (const key in this.pendingList.update) {
      const rect = this.pendingList.update[key];
      const data = rect.getData();
      if (rect.tempData.transformChanged) {
        rect.tempData._x = data.x;
        rect.tempData._y = data.y;
        rect.tempData._width = data.width;
        rect.tempData._height = data.height;
        rect.tempData.transformChanged = false;
        if (rect.func.reset) { rect.func.reset(); } // call the refresh function
        this.cancelAUpdatePendingItem_effects(rect)
      }
    }

    for (const key in this.pendingList.delete) {
      const rect = this.pendingList.delete[key];
      this.cancelADeletePendingItem_effects(rect);
    }
    this.cleanUpPendingList();
  }

  // override
  async deleteAPendingItem(canvasData) { }

  startPlacementMode(bound, placementEffect, absCollisionCollection, fenceCollisionCollection, fenceByPassRule) {
    this.placingRectData = { x: bound.x0, y: bound.y0, width: bound.x1 - bound.x0, height: bound.y1 - bound.y0, borderRadius: 5 }
    this.system.coordinator.guideBox.setActive(this.placingRectData, (x, y) => {
      if (this.placingRectData) { this.placingRectData.x = x; this.placingRectData.y = y }
    }, placementEffect,
      absCollisionCollection,
      fenceCollisionCollection, fenceByPassRule);
  }
}

export class PendingFloatingCanvases extends PendingList {
  constructor(system, coordinator) {
    super(system, coordinator);
    this.tempID = 0;
  }

  getTempID() {
    this.tempID += 1;
    return `tempCanvas_${this.tempID}`;
  }

  async createNewUrlForFloatingCanvas(canvasID) {
    const base64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";
    const canvasPath = `${this.coordinator.getBoardStoragePath()}/canvases/${canvasID}`
    return await StorageTalker.writeBase64ToStorage(canvasPath, base64, 'image/png');
  }

  // this doesn't upload layer
  socket_UpdateFloatingCanvasTransform(rect) {
    const canvas = rect.getData();
    const changes = { x: canvas.x, y: canvas.y, width: canvas.width, height: canvas.height, layer: canvas.layer };
    this.system.socket_connector.emit_to_socket("updateFC", this.system.socket_connector.PaperUpdateDataToEmitData("transform", { canvasID: canvas.canvasID, changes: changes }));
  }

  socket_UploadNewFloatingCanvas(canvas) {
    this.system.socket_connector.emit_to_socket("updateFC", this.system.socket_connector.PaperUpdateDataToEmitData("add", { canvasID: canvas.canvasID, canvas: canvas }));
  }

  socket_DeleteFloatingCanvas(canvas) {
    this.system.socket_connector.emit_to_socket("updateFC", this.system.socket_connector.PaperUpdateDataToEmitData("remove", { canvasID: canvas.canvasID }));
  }

  processANewUpload(key, uploadedCanvas) {
    // section loader will read rect rather than data.
    this.coordinator.sectionData.deleteItemFromCanvasLoader(this.pendingList["upload"][key]);  // this one has temp ID
    const localSavedCanvas = this.pendingList["upload"][key].getData(); // the data core
    Object.assign(localSavedCanvas, uploadedCanvas); // assign canvasID, x, y, width, height, and other transform data.
    this.pendingList["upload"][key].id = uploadedCanvas.canvasID;  // reinsert with the correct id name
    this.pendingList["upload"][key].isNotUploaded = false;
    this.coordinator.sectionData.insertNewItemToCanvasLoader(this.pendingList["upload"][key]); // now upload the new canvas

    this.socket_UploadNewFloatingCanvas(localSavedCanvas)
  }

  revokeAUpload(key, uploadedCanvas) {
    this.coordinator.sectionData.deleteItemFromCanvasLoader(this.pendingList["upload"][key]);  // remove this canvas because it's failed
  }

  cleanUpUploadData(key) {
    const canvasRect = this.pendingList["upload"][key];
    const canvas = this.pendingList["upload"][key].getData();
    const newCanvasData = {
      canvasID: canvas.canvasID, // will be a temp one, but we need it. we will delete the tempID on the server.
      x: (canvasRect.tempData._x !== undefined) ? canvasRect.tempData._x : canvas.x,
      y: (canvasRect.tempData._y !== undefined) ? canvasRect.tempData._y : canvas.y,
      width: (canvasRect.tempData._width !== undefined) ? canvasRect.tempData._width : canvas.width,
      height: (canvasRect.tempData._height !== undefined) ? canvasRect.tempData._height : canvas.height,
      layer: (canvasRect.tempData._layer !== undefined) ? canvasRect.tempData._layer : canvas.layer,
      style: (canvasRect.tempData._style !== undefined) ? canvasRect.tempData._style : canvas.style,
    };
    // will remove same id in update and copy its values
    if (this.pendingList["update"][key]) {          // this is also in the update.
      delete this.pendingList["update"][key];       // remove it from update.
    }
    return newCanvasData;
  }

  revokeAUpdate(key, uploadedCanvas) {
    this.coordinator.sectionData.deleteItemFromCanvasLoader(this.pendingList["update"][key]);  // this one has temp ID
    this.pendingList["update"][key].giveUpTempData(); // the temp data is now removed
    this.coordinator.sectionData.insertNewItemToCanvasLoader(this.pendingList["update"][key]); // now upload the new canvas
  }

  cleanUpUpdateData(key) {
    const canvasRect = this.pendingList["update"][key];
    const canvas = this.pendingList["update"][key].getData();
    const updateData = {
      canvasID: canvas.canvasID,
      x: (canvasRect.tempData._x !== undefined) ? canvasRect.tempData._x : canvas.x,
      y: (canvasRect.tempData._y !== undefined) ? canvasRect.tempData._y : canvas.y,
      width: (canvasRect.tempData._width !== undefined) ? canvasRect.tempData._width : canvas.width,
      height: (canvasRect.tempData._height !== undefined) ? canvasRect.tempData._height : canvas.height,
      layer: (canvasRect.tempData._layer !== undefined) ? canvasRect.tempData._layer : canvas.layer,
      style: (canvasRect.tempData._style !== undefined) ? canvasRect.tempData._style : canvas.style,
      oriWidth: canvas.width, oriHeight: canvas.height, // record the old size for resize detection
      imageUrl: canvas.imageUrl ? canvas.imageUrl : "",
    }
    return updateData;
  }

  cleanUpDeleteData(key) {
    const canvasRect = this.pendingList["delete"][key];
    const canvas = this.pendingList["delete"][key].getData();
    const deleteData = { canvasID: canvas.canvasID, }
    return deleteData;
  }

  saveAUpdatePendingItem_effects(rect) {
    if (rect.func.resizeCanvas) { rect.func.resizeCanvas(rect.getActiveWidth(), rect.getActiveHeight()) } // for canvas
    this.socket_UpdateFloatingCanvasTransform(rect);
  }

  cancelAUploadPendingItem_effects(rect) {
    this.coordinator.sectionData.deleteItemFromCanvasLoader(rect);
  }

  cancelAUpdatePendingItem_effects(rect) {
    this.coordinator.sectionData.updateCanvasLoaderItemPosition(rect);
  }

  cancelADeletePendingItem_effects(rect) {
    rect.pendingDeletion = false;
    // this.coordinator.sectionData.insertNewItemToCanvasLoader(rect);
  }

  // this is actually delete a item, not a pending one.
  async deleteAPendingItem(canvasRect) {
    if (canvasRect.getID() in this.pendingList.upload) {
      this.removeFromPendingList(canvasRect.getID());
      return;
    }

    if (this.pendingList.upload[canvasRect.id]) {
      delete this.pendingList.upload[canvasRect.id];
    }
    const deleteData = await RequestDataMaker.papersTransformUpdateData(this.system, { removeID: canvasRect.getID(), type: "canvas" })
    const removal = await this.system.restServerConnector.requestToDeleteAPapers(deleteData);
    if (removal && removal.success) {
      if (this.pendingList.update[canvasRect.id]) {
        // only remove from update if successed
        delete this.pendingList.update[canvasRect.id];
      }
      this.socket_DeleteFloatingCanvas({ canvasID: canvasRect.getID() }); // tell the socket the canvas is deleted
      this.coordinator.sectionData.deleteItemFromCanvasLoader(canvasRect);
    }
  }

  // takes an array of rects
  startPlacementMode(rects, bound, dynamicLayering = true) {
    this.relativePositions = {};
    for (const rect of rects) {
      this.relativePositions[rect.getID()] = {
        x: rect.getActiveX() - bound.x0,
        y: rect.getActiveY() - bound.y0,
      }
    }
    const placementEffect = (extra) => {
      for (const rect of rects) {
        const relativePos = this.relativePositions[rect.getID()];
        
        rect.tempData._x = this.placingRectData.x + relativePos.x;
        rect.tempData._y = this.placingRectData.y + relativePos.y;
        // rect.tempData._width = this.placingRectData.width;
        // rect.tempData._height = this.placingRectData.height;
        rect.tempData._layer = dynamicLayering ? (extra.layer || rect.getActiveLayer()) : rect.getActiveLayer();
        this.addToPendingList(rect.getID(), rect, "update");
        rect.setDisabled(false);
        if (rect.func.setTransform) { rect.func.setTransform(rect.getActiveX(), rect.getActiveY(), rect.getActiveWidth(), rect.getActiveHeight()) }
      }
      // this.system.coordinator.sectionData.updateCanvasLoaderItemPosition(rect); // update the position...
      this.placingRectData = {};
    }
    // if the array is just 1 element, then it will only be calculated as layer "1"
    let colliderCollections = this.system.coordinator.floatingCollisionSystems;
    if (!dynamicLayering) {
      colliderCollections = [this.system.coordinator.allPaperColliderCollection];
    }
    if (rects.length > 0) {
      super.startPlacementMode(bound, placementEffect, this.system.coordinator.floatingCollisionSystems, this.system.coordinator.fenceLoader.fenceCollisionSystem, rects[0].fenceByPassRule.bind(rects[0]))
    }
  }
}