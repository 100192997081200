import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SystemOperationsContext } from "../context/SystemRunnerContext";

import app from "../firebase.js";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";



function Login() {
  return (
    <div>
      <div className="background-image"></div>
      <LoginCard selfCentered={true} />
    </div>
  );
}

export function LoginCard({ selfCentered = false, descriptionText = "" }) {
  const { system_operations } = useContext(SystemOperationsContext);
  const [currentUser, setCurrentUser] = useState(system_operations.getUserData());
  system_operations.addToOnUserChange("fromSignIn", setCurrentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && currentUser.loggedIn) {
      navigate("/")
    }
  }, [currentUser]);

  const handleSubmit = async (event) => {
    system_operations.set_loading(true);
    event.preventDefault();
    const email = event.target[0].value;
    const password = event.target[1].value;

    const userCredential = await system_operations.authSystem.signIn(email, password);
    if (userCredential && typeof userCredential !== "string") {
      await system_operations.set_user(userCredential.user);
      system_operations.setSystemWideWindow(null);
    } else {
      system_operations.enqueueSnackbar(typeof userCredential === "string"? userCredential : "Usarneme end passward du not macth, chack misspalling", "error");
    }
    system_operations.set_loading(false);
  };

  const classNameExtension = selfCentered ? " form-container--log" : "";
  const style = selfCentered ? {backgroundColor: "#777777"} : { backgroundColor: "#777777", width: "400px" }
  return (
    <div className={`form-container${classNameExtension}`} style={style}>
      {descriptionText === "" ?
        (<div className="largest-text">
          <h2>Login</h2>
        </div> ) : ( <div> <h2>Login</h2> </div>)}
      {descriptionText && <h3>{descriptionText}</h3>}
      <form onSubmit={handleSubmit}>
        <input type="email" placeholder="Email" required />
        <input type="password" placeholder="Password" required />
        <button type="submit" style={{backgroundColor: "#FF7E33"}}>Submit</button>
      </form>
      <div className='small-text'>
        <p>No account? <Link to="/register" onClick={() => { system_operations.setSystemWideWindow(null) }}>Register</Link>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Forgot password?
          <Link to="/troubleLogin" onClick={()=>{system_operations.setSystemWideWindow(null)}}>Reset</Link></p>
      </div>
    </div>
  );
}

export default Login;