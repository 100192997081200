import React, { useRef, useEffect, useContext, useState, useMemo } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
// import TextInputer from "./TextInputer.jsx";

import { styled, useTheme } from '@mui/system';

export const BoardAxisGuideline = React.memo(({ }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const containerRef = useRef(null);

  const [xAuxiliary, setXAuxiliary] = useState(system_operations.coordinator.toolpack.auxiliaryLine.xAuxiliary);
  const [yAuxiliary, setYAuxiliary] = useState(system_operations.coordinator.toolpack.auxiliaryLine.yAuxiliary);
  const refresh_counter = useRef(0);
  const [refresh, setRefresh] = useState(0);

  const [xMaget, setXMaget] = useState(-1);
  const [yMaget, setYMaget] = useState(-1);

  const [cursorOnMainCanvas, setCursorOnMainCanvas] = useState(true);

  const theme = useTheme();

  useEffect(() => {
    const updateXAuxiliary = (newXAuxiliary) => {
      refresh_counter.current += 1;
      setRefresh(refresh_counter.current);
      setXAuxiliary(newXAuxiliary);
    }

    const updateYAuxiliary = (newYAuxiliary) => {
      refresh_counter.current += 1;
      setRefresh(refresh_counter.current);
      setYAuxiliary(newYAuxiliary);
    }

    system_operations.coordinator.setXPlacementGuideLine = (value) => { setXMaget(value); };
    system_operations.coordinator.setYPlacementGuideLine = (value) => { setYMaget(value); };

    system_operations.coordinator.toolpack.auxiliaryLine.onXAuxiliarylineChange["_BAGLX"] = updateXAuxiliary;
    system_operations.coordinator.toolpack.auxiliaryLine.onYAuxiliarylineChange["_BAGLY"] = updateYAuxiliary;

    return () => {
      system_operations.coordinator.setXPlacementGuideLine = () => { };
      system_operations.coordinator.setYPlacementGuideLine = () => { };

      delete system_operations.coordinator.toolpack.auxiliaryLine.onXAuxiliarylineChange["_BAGLX"];
      delete system_operations.coordinator.toolpack.auxiliaryLine.onYAuxiliarylineChange["_BAGLY"];
    }
  }, [])

  return (
    <div ref={containerRef}
      style={{pointerEvents: "none"}}>
      {xMaget >= 0 && <div style={{ position: "absolute", left: `${xMaget}px`, top: "0px", width: "2px", height: "100%", borderLeft: "2px dashed #777777", zIndex: theme.zIndex.placementGuideline }}></div>}
      {yMaget >= 0 && <div style={{ position: "absolute", left: "0px", top: `${yMaget}px`, width: "100%", height: "2px", borderTop: "2px dashed #777777", zIndex: theme.zIndex.placementGuideline }}></div>}

      {xAuxiliary.map((xCoord, index) => (
        <div style={{ position: "absolute", left: `${xCoord}px`, top: "0px", width: "2px", height: "100%", borderLeft: "2px dashed black", zIndex: theme.zIndex.placementGuideline }} key={index}></div>
      ))}
      {yAuxiliary.map((yCoord, index) => (
        <div style={{ position: "absolute", left: "0px", top: `${yCoord}px`, width: "100%", height: "2px", borderTop: "2px dashed black", zIndex: theme.zIndex.placementGuideline }} key={index}></div>
      ))}
    </div >
  );
});
