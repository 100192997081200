import React, { useState, useRef, useContext, useEffect, useMemo } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";


// const indicatorInterval = 150;
export const CoordinateAxis = React.memo(({ displayOffset }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const containerRef = useRef(null);
  const [indicatorCoords, setIndicatorCoords] = useState([]);
  const [axisColor, setAxisColor] = useState("#707070");

  const resetCoords = async (coord) => {
    const toTheoScale = system_operations.coordinator.boardPositioner.scaleToTheo.bind(system_operations.coordinator.boardPositioner);
    const displaySize = toTheoScale(system_operations.coordinator.isHorizontal ? window.innerWidth : window.innerHeight); // screen size is scr size, we need the theo one of it cause it will be scaled to scr later
    const indicatorInterval = system_operations.coordinator.getGridParallelEdge();
    const start = (indicatorInterval - coord % indicatorInterval) % indicatorInterval;
    const icArray = [];
    for (let cd = start; cd < displaySize; cd += indicatorInterval) {
      if (coord + cd <= system_operations.coordinator.pixelBoundary[1]) icArray.push(coord + cd);
    }
    setIndicatorCoords(icArray);
  }

  useEffect(() => {
    system_operations.coordinator.onCoordinateChange["CX"] = resetCoords;
  }, []);

  const hoverOn = () => { setAxisColor("#303030") }
  const hoverOff = () => { setAxisColor("#707070") }

  return (
    <div ref={containerRef} onMouseEnter={hoverOn} onMouseLeave={hoverOff}
      style={{
        position: "absolute", userSelect: "none", cursor: "pointer",
        top: system_operations.coordinator.isHorizontal ? `${displayOffset}px` : 0,
        left: system_operations.coordinator.isHorizontal ? 0 : `${displayOffset}px`,
        height: system_operations.coordinator.isHorizontal ? indicatorHeight + 3 : system_operations.coordinator.pixelBoundary[1],
        width: system_operations.coordinator.isHorizontal ? system_operations.coordinator.pixelBoundary[1] : indicatorHeight + 3,
        zIndex: "100",
      }}>
      <div style={{
        position: "absolute",
        [system_operations.coordinator.isHorizontal ? "left" : "top"]: 0,
        [system_operations.coordinator.isHorizontal ? "width" : "height"]: system_operations.coordinator.isHorizontal ? system_operations.coordinator.theoreticalBoardWidth : system_operations.coordinator.theoreticalBoardHeight,
        [system_operations.coordinator.isHorizontal ? "top" : "left"]: indicatorHeight,
        [system_operations.coordinator.isHorizontal ? "height" : "width"]: "2px",
        backgroundColor: axisColor,
      }} />
      {indicatorCoords.map((coord) => {
        return (<CoordIndicator coordinate={coord} axisColor={axisColor} isHorizontal={system_operations.coordinator.isHorizontal} key={coord} />)
      })}
    </div>
  );
});

const indicatorWidth = 2;
const indicatorHeight = 8;
const CoordIndicator = ({ coordinate, axisColor, isHorizontal }) => {

  const containerStyle = {
    position: "absolute",
    [isHorizontal ? "left" : "top"]: coordinate,
    [isHorizontal ? "top" : "left"]: 0,
  }
  if (!isHorizontal) {
    containerStyle.transform = `rotate(-90deg)  translateX(${-indicatorWidth}px)`;
    containerStyle.transformOrigin = 'top left';
  }

  return (
    <div style={containerStyle}>
      <div style={{
        width: indicatorWidth,
        height: indicatorHeight,
        backgroundColor: axisColor,
      }} />
      <div style={{
        position: "absolute",
        bottom: -1,
        left: indicatorWidth + 2,
        fontSize: 11,
        color: axisColor,
      }}> {coordinate} </div>
    </div>
  );
}