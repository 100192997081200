import React, { useState, useRef, useEffect, useContext } from 'react';
import { SystemOperationsContext } from "../../context/SystemRunnerContext";


export const PaperLinker = ({ paperRect, interactWithMedia }) => {
  // const { system_operations } = useContext(SystemOperationsContext);
  const tryGoGlobalLink = () => {
    const canvasData = paperRect?.getData() || null;
    if (interactWithMedia && canvasData?.extension?.data.link) {
      if (!canvasData.extension.data.link.startsWith('http://') && !canvasData.extension.data.link.startsWith('https://')) {
        canvasData.extension.data.link = 'http://' + canvasData.extension.data.link;
      }
      window.open(canvasData.extension.data.link, '_blank');
    }
  }

  return (
    <div onClick={tryGoGlobalLink} style={{
      cursor: interactWithMedia ? "pointer" : "",
      position: "absolute",
      width: `${paperRect.getActiveWidth()}px`,
      height: `${paperRect.getActiveWidth()}px`,
    }} />
  );
};
