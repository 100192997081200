import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import HeaderBar from "../components/HeaderBar.jsx";
import ToolBar from "../components/ToolBar.jsx";
import { UltimateControlPanel } from "../components/toolBar/UltimateControlPanel.jsx";
import Board from "../components/Board.jsx";

import Joyride, { STATUS } from 'react-joyride';


const BoardPage = ({ domainType = "id" }) => {
  const { location } = useParams();
  const { system_operations } = useContext(SystemOperationsContext);
  // const [wallInitialized, setWallInitialized] = useState(system_operations.coordinator.wallInitialized && system_operations.coordinator.getWallID() === location);
  const [wallInitialized, setWallInitialized] = useState(false);
  const [forceShowHeaderBar, setForceShowHeaderBar] = useState(false);

  const [guideIsRunning, setGuideIsRunning] = useState(!system_operations.generalGuideFinsihed);
  const navigate = useNavigate();


  const boardPageRef = useRef(null);

  const holdingFlag = useRef(false);
  const holdStartPos = useRef([0, 0]);

  const maxHoldTorlerance = useRef(1);
  const holdTimeout = useRef(null);

  useEffect(() => {
    if (wallInitialized && !system_operations.coordinator?.kDrawApi) {

    }
  }, [wallInitialized])

  useEffect(() => {
    const fullProcessOfEnteringABoard = async () => {
      let boardID = location;
      if (domainType === "id") {
        await system_operations.enterAWall(boardID, setWallInitialized);
        const initialized = system_operations.coordinator.wallInitialized && system_operations.coordinator.getWallID() === boardID;
        setWallInitialized(initialized);
        return;
      } else if (domainType === "name") {
        const domainObject = await system_operations.firestoreActions.fetchFromFirestore(`boardDomains/${location}`, "domainName"); // fetch the domain object...
        if (domainObject && domainObject.boardID) {
          boardID = domainObject.boardID;
          await system_operations.enterAWall(boardID, setWallInitialized);
          const initialized = system_operations.coordinator.wallInitialized && system_operations.coordinator.getWallID() === boardID;
          setWallInitialized(initialized);
          return;
        }
      }
      // failed...
      system_operations.enqueueSnackbar("Board does not exist...", "error")
    }
    fullProcessOfEnteringABoard();

    const onMouseDown = (e) => {
      onEventDown(e.clientX, e.clientY);
    }

    const onTouchDown = (e) => {
      onEventDown(e.touches[0].clientX, e.touches[0].clientY);
    }

    const onMouseMove = (e) => {
      onEventMove(e.clientX, e.clientY);
    }

    const onTouchMove = (e) => {
      onEventMove(e.touches[0].clientX, e.touches[0].clientY);
    }

    const onEventDown = (x, y) => {
      holdingFlag.current = true;
      holdStartPos.current = [x, y];

      holdTimeout.current = setTimeout(() => {
        system_operations.mouseHoldingTrigger(x, y); // holding and trigger
      }, 1500);
    }

    const onEventMove = (x, y) => {
      if (holdingFlag.current) {
        const dx = x - holdStartPos.current[0];
        const dy = y - holdStartPos.current[1];
        const displacement = Math.sqrt((dx * dx) + (dy * dy));
        if (displacement > maxHoldTorlerance.current) {
          clearTimeout(holdTimeout.current);
        }
      }
    }

    const onEventUp = () => {
      holdingFlag.current = false;
      clearTimeout(holdTimeout.current);
    }

    const escKeyPressed  = (e) => {
      if (e.key === 'Escape') {
        console.log("exit.");
        navigate("/");
      }
    };
    window.addEventListener('keydown', escKeyPressed);
    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('touchstart', onTouchDown);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('mouseup', onEventUp);
    window.addEventListener('touchend', onEventUp);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('touchstart', onTouchDown);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('mouseup', onEventUp);
      window.removeEventListener('touchend', onEventUp);
      window.removeEventListener('keydown', escKeyPressed);
      clearTimeout(holdTimeout.current);
    };
  }, []);

  // console.log(" The board is reloaded here. ", location, wallInitialized);
  return (
    <div ref={boardPageRef} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <div className="home_background-image"></div>
      {wallInitialized &&
        <div>
          <Board />
          <UltimateControlPanel />
        </div>
      }
    </div>
  )
}

export default BoardPage