import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { GridContainer, ToolItem, ToolItemICONChildren } from '../../components/toolBar/UltimateControlPanel';
import { SystemOperationsContext } from "../../context/SystemRunnerContext";
import ButtonGroups from "../../cross_page_components/ButtonGroups.jsx";
import { RectDataDisplay } from "./Hand.js";

import { useTheme } from '@mui/system';
import skip_up_line from '../../icons/skip-up-line.svg'
import skip_down_line from '../../icons/skip-down-line.svg'
import sdrag_move_2_line from '../../icons/drag-move-2-line.svg'
import check_green from '../../icons/check-green.svg'
import check_696969 from '../../icons/check-696969.svg'
import cross_red from '../../icons/cross-red.svg'
import cross_696969 from '../../icons/cross-696969.svg'
import add_box_white from '../../icons/add-box-white.svg'
import delete_bin_red from '../../icons/delete-bin-red.svg'
import play_white from '../../icons/play-white.svg'
import magnet_white from '../../icons/magnet-white.svg'
import magnet_blue from '../../icons/magnet-blue.svg'

import single_select from '../../icons/paper/rectangle-fill-single1.svg'
import multi_select from '../../icons/paper/rectangle-fill-multi2.svg'
import rect_select_white from '../../icons/select-rectangle-white.svg'
import rect_select_green from '../../icons/select-rectangle-green.svg'
import unselect from '../../icons/paper/rectangle-fill-cross.svg'


export const PaperRectTransformControl = ({ layerSet = 0, toolpack, paperTool, isVertical }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [paperSelections, setPaperSelection] = useState(toolpack.paperSelectionArray);

  const refreshCounter = useRef(0);
  const [refresh, setRefresh] = useState(0);

  const theme = useTheme();

  useEffect(() => {
    const updateSelections = (selections, selectionArray) => {
      setPaperSelection(selectionArray);
      setRefresh(refreshCounter.current + 1);
      refreshCounter.current += 1;
    }

    toolpack.onPaperSelectionsChange["_PTP"] = updateSelections;
    return () => {
      delete toolpack.onPaperSelectionsChange["_PTP"];
    }
  }, []);


  const subItemBaseStyle = {
    boxSizing: 'border-box',
    borderRadius: "5px",
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#e0e0e0",
    border: '2px solid #e0e0e0',
    cursor: "pointer",
    fontSize: "14px",
    textAlign: "center",
    [isVertical ? "width" : "height"]: "25%",
  };
  const subItemHoverStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.2)" };
  const subItemSelectedStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.5)" };
  const containerStyle = {
    boxSizing: 'border-box',
    backgroundColor: "rgba(50,50,50,1)",
    borderRadius: "3px",
    [isVertical ? "width" : "height"]: "100%",
    padding: "3px",
    zIndex: "100000000000000000"
  }

  const divider = (
    <div style={{
      borderRadius: "1px", backgroundColor: "#e0e0e0",
      [isVertical ? "height" : "width"]: "1px", [isVertical ? "width" : "height"]: "100%",
      [isVertical ? "marginBottom" : "marginRight"]: 3, [isVertical ? "marginTop" : "marginLeft"]: 3,
    }} />
  );

  if (paperSelections.length === 0) {
    return (
      <div style={{ ...containerStyle, color: "white", fontSize: "10px" }}>
        No target selected
        <PaperManagement paperSelections={paperSelections} paperTool={paperTool} isVertica={isVertical} subItemBaseStyle={subItemBaseStyle} subItemHoverStyle={subItemHoverStyle} subItemSelectedStyle={subItemSelectedStyle} />
      </div>
    );
  }


  const singular = paperSelections.length <= 1;
  return (
    <div style={containerStyle}>
      {
        singular && 
        <RectDataDisplay rect={paperSelections[0]} divider={divider} />
      }
      <PaperManagement paperSelections={paperSelections} paperTool={paperTool} isVertica={isVertical} subItemBaseStyle={subItemBaseStyle} subItemHoverStyle={subItemHoverStyle} subItemSelectedStyle={subItemSelectedStyle} />
      {
        singular ?
        <PaperRectEditTransformCore rect={paperSelections[0]} layerSet={layerSet} isVertica={isVertical} subItemBaseStyle={subItemBaseStyle} subItemHoverStyle={subItemHoverStyle} subItemSelectedStyle={subItemSelectedStyle} />
        :
        <PaperGroupEditTransformCore rectsSelections={paperSelections} layerSet={layerSet} isVertica={isVertical} subItemBaseStyle={subItemBaseStyle} subItemHoverStyle={subItemHoverStyle} subItemSelectedStyle={subItemSelectedStyle} />
      }
    </div>
  );
}

export const PaperManagement = ({ paperSelections, paperTool, layerSet = 0, isVertical, subItemBaseStyle, subItemHoverStyle, subItemSelectedStyle }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [mode, setMode] = useState(paperTool.actionMode); // 0: edit, 1: create new
  const [usingMagnet, setUsingMagnet] = useState(paperTool.usingMagnet); // might use paper tool to store this so we don't need to change it everytime.
  // const [multiSelection, setMultiSelection] = useState(system_operations.coordinator.toolpack.paperEditor.multiSelection); // might use paper tool to store this so we don't need to change it everytime.
  const [multiSelection, setMultiSelection] = useState(false); // when switch to this paper tool, the rect select mode is always turned off by default.
  // const [rectsSelectMode, setRectsSelectMode] = useState(system_operations.coordinator.toolpack.rectsSelectMode); // might use paper tool to store this so we don't need to change it everytime.
  const [rectsSelectMode, setRectsSelectMode] = useState(false); // default to be false. when switch to it, it should be false;
  const [hasChanges, setHasChanges] = useState(!system_operations.coordinator.allPendingPaperChangesEmpty);
  const [rulesSelections, setRulesSelections] = useState(system_operations.coordinator.toolpack.rulesSelections);
  const tryToSwitchToTool = useRef("hand");
  const selectionLength = paperSelections.length; // no thing selected or just selected one.
  const theme = useTheme();

  const deletionWarningButtons = {
    save: {
      name: 'Yes',
      color: "white",
      backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
      checkDisable: () => { return false },
      onClick: async () => {
        system_operations.set_loading(true);
        if (rulesSelections) {
          for (const paper of paperSelections) {
            // needs work to make it compatiable with multi papers.
            await system_operations.coordinator.pendingCanvasSystem.deleteAPendingItem(paper);
          }
        }

        system_operations.set_loading(false);
        system_operations.setContentWindow(null)
      },
    },
    dump: {
      name: "No",
      color: "white",
      backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
      checkDisable: () => { return false },
      onClick: async () => { system_operations.setContentWindow(null) },
    }
  };

  useEffect(() => {
    const checkForChange = (noChanges) => {
      if (noChanges) {
        setHasChanges(false);
      } else {
        setHasChanges(true);
      }
    }

    const setRectsSelectMode_callback = (selectOn) => {
      setRectsSelectMode(selectOn);
    }

    paperTool.setModeHook = setMode;
    paperTool.toolPack.system.coordinator.onPendingPapersEmptyChange["_FPTCP"] = checkForChange;
    system_operations.coordinator.toolpack.onRectsSelectModeChange["_FPTCP"] = setRectsSelectMode_callback;

    system_operations.coordinator.toolpack.setMultiSelectionOnOrOff(false);
    system_operations.coordinator.toolpack.setMultiSelectionOnOrOff(false);

    return (() => {
      delete paperTool.toolPack.system.coordinator.onPendingPapersEmptyChange["_FPTCP"];
    })
  }, [])

  useEffect(() => {
    const buttons = {
      save: {
        name: 'Yes',
        color: "white",
        backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
        checkDisable: () => { return false },
        onClick: async () => {
          await submitChanges();
          paperTool.toolPack.system.setContentWindow(null)
          delete paperTool.conditionsForToolChange["canChangeIfThereAreChanges"];
          paperTool.toolPack.changeToolByName(tryToSwitchToTool.current); // switch again
        },
      },
      dump: {
        name: "No",
        color: "white",
        backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
        checkDisable: () => { return false },
        onClick: async () => {
          await cancelChange();
          paperTool.toolPack.system.setContentWindow(null)
          delete paperTool.conditionsForToolChange["canChangeIfThereAreChanges"];
          paperTool.toolPack.changeToolByName(tryToSwitchToTool.current); // switch again
        },
      }
    }

    paperTool.conditionsForToolChange["canChangeIfThereAreChanges"] = (tool) => {
      if (tool.name !== paperTool.name && hasChanges) {
        tryToSwitchToTool.current = tool.name;
        // pop up the warning
        paperTool.toolPack.system.setContentWindow((
          <div className='float-container' style={{ borderRadius: 8, padding: 5, backgroundColor: "rgba(64, 64, 64, 0.85)" }}>
            Save All Changes?
            <ButtonGroups buttons={buttons} />
          </div>
        ));
        return false;
      } else {
        return true;
      }
    }
  }, [hasChanges]);

  const deleteWarning = () => {
    system_operations.setContentWindow((
      <div className='float-container'>
        Delete The Item?
        <ButtonGroups buttons={deletionWarningButtons} />
      </div>
    ));
  }

  const changeMode = (nextMode) => {
    setMode(nextMode);
    paperTool.setMode(nextMode);
    paperTool.toolPack.refreshToolControlPanel();
  }

  const setMagnetOnAndOff = (setOn) => {
    setUsingMagnet(setOn);
    paperTool.setMagnetOnOrOff(setOn); // tell if magnet is on.
  }

  const submitChanges = async () => {
    paperTool.toolPack.system.set_loading(true);
    await paperTool.toolPack.system.coordinator.saveAllPendingPapersChanges();
    paperTool.toolPack.refreshToolControlPanel();
    paperTool.toolPack.system.set_loading(false);
  }

  const cancelChange = async () => {
    paperTool.toolPack.system.set_loading(true);
    paperTool.toolPack.system.coordinator.discardAllPendingPapersChanges();
    paperTool.toolPack.system.set_loading(false);
  }

  const unselectFunc = () => {
    system_operations.coordinator.toolpack.setPaperSelection({});
  }

  const setSelectionType = () => {
    system_operations.coordinator.toolpack.setMultiSelectionOnOrOff(!system_operations.coordinator.toolpack.multiSelection);
    setMultiSelection(system_operations.coordinator.toolpack.multiSelection);
    if (!system_operations.coordinator.toolpack.multiSelection) {
      system_operations.coordinator.toolpack.setRectsSelectMode(false); // no longer select...
    }
  }

  const _setRectsSelectMode = () => {
    system_operations.coordinator.toolpack.setRectsSelectMode(!rectsSelectMode); // allow or disallow rects select mode.
    // setRectsSelectMode(!rectsSelectMode); // event will be called by callback..
  }

  const alwaysDisplayed = (
    <>
      <ToolItem icon={add_box_white} tip="Add A Paper" onClick={() => { mode === 0 ? changeMode(1) : changeMode(0) }} selected={mode === 1}
        baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
      <ToolItem icon={usingMagnet ? magnet_blue : magnet_white} tip="Magnetized Placement" onClick={() => { setMagnetOnAndOff(!usingMagnet) }} selected={usingMagnet}
        baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
      {
        selectionLength > 0 &&
        <ToolItem icon={unselect} tip="Unselect" onClick={unselectFunc} selected={false}
          baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
      }
      {
        selectionLength < 1 &&
        <ToolItem icon={multiSelection ? multi_select : single_select} tip="Allow Multi-Selection" onClick={setSelectionType} selected={multiSelection}
          baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
      }
      {multiSelection && 
        <ToolItem icon={rectsSelectMode ? rect_select_green : rect_select_white} tip="Area Selection" onClick={_setRectsSelectMode} selected={rectsSelectMode}
          baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
      }
    </>
  );

  return (
    <div>
      <GridContainer isVertical={isVertical} title='Manage Papers'>
        {alwaysDisplayed}
        {/*can only delete if rect exist... might change to a "mode" button*/}
        {rulesSelections && selectionLength > 0 &&
          <ToolItem icon={delete_bin_red} tip="Delete Selected Papers" onClick={deleteWarning} selected={false}
            baseStyle={{ ...subItemBaseStyle, color: "#e06060", border: '2px solid #e04040' }}
            hoverStyle={{ ...subItemHoverStyle, color: "#ff0000", border: '2px solid #ff0000', backgroundColor: "rgba(255, 0, 0, 0.2)" }}
            selectedStyle={{ ...subItemSelectedStyle, border: '2px solid #ff0000', backgroundColor: "rgba(255, 0, 0, 0.5)" }}
          />
        }
        <ToolItem icon={hasChanges ? check_green : check_696969} tip="Save" onClick={submitChanges} selected={false} disabled={!hasChanges}
          baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} disabledStyle={{ ...subItemBaseStyle, color: "rgb(70, 70, 70)", backgroundColor: "rgba(70,70,70, 0.5)", cursor: "default" }} />
        <ToolItem icon={hasChanges ? cross_red : cross_696969} tip="Discard" onClick={cancelChange} selected={false} disabled={!hasChanges}
          baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} disabledStyle={{ ...subItemBaseStyle, color: "rgb(70, 70, 70)", backgroundColor: "rgba(70,70,70, 0.5)", cursor: "default" }} />
      </GridContainer>
    </div>
  );
}


export const PaperRectEditTransformCore = ({ rect, layerSet = 0, isVertical, subItemBaseStyle, subItemHoverStyle, subItemSelectedStyle }) => {
  const rectData = rect.getData();
  const { system_operations } = useContext(SystemOperationsContext);
  const [layer, setLayer] = useState(rect.getActiveLayer());
  const [isNotUploaded, setIsNotUploaded] = useState(rect.isNotUploaded);

  const theme = useTheme();
  const hasPower = rectData.creatorID === system_operations.getUserID() ||
    system_operations.coordinator.getUserRoleInWall().power > rectData.protectLevel;

  useEffect(() => {
    setLayer(rect.getActiveLayer());
    setIsNotUploaded(rect.isNotUploaded);
  }, [rect])

  const moveLayerUpOrDown = (up) => {
    rect.moveLayerUpOrDown(up); // if success, it will handle the rest.
    if (rect.func?.styleRefreshPaperFullComponent) { rect.func.styleRefreshPaperFullComponent(); }
    if (rect.func?.rectRefresh) { rect.func.rectRefresh(); }
  }

  const onReplaceToolClick = () => {
    const currentSelectionBound = system_operations.coordinator.toolpack.getSelectionBound();
    system_operations.coordinator.toolpack.disableSelectedPapers(); // disable (hide) any selections.
    system_operations.coordinator.pendingCanvasSystem.startPlacementMode([rect], currentSelectionBound);
  }

  return (
    <div>
      <GridContainer isVertical={isVertical} title='Edit Papers'>
        {
          hasPower &&
          <>
            <ToolItem icon={skip_up_line} tip="Layer Move Up" onClick={() => { moveLayerUpOrDown(true) }} selected={false}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
            <ToolItem icon={skip_down_line} tip="Layer Move Down" onClick={() => { moveLayerUpOrDown(false) }} selected={false}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
            {
              !isNotUploaded &&
              <ToolItem icon={sdrag_move_2_line} tip="Re-Place the Paper" selected={false}
                onClick={onReplaceToolClick}
                baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
            }
          </>
        }
      </GridContainer>
    </div>
  );
}

export const PaperGroupEditTransformCore = ({ rectsSelections, isVertical, subItemBaseStyle, subItemHoverStyle, subItemSelectedStyle }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const toolpack = system_operations.coordinator.toolpack;
  const [hasPower, setHasPower] = useState(toolpack.rulesSelections)
  const theme = useTheme();

  useEffect(() => {
    const updateSelections = () => {
      setHasPower(toolpack.rulesSelections);
    }
    toolpack.onPaperSelectionsChange["_PGET"] = updateSelections;
  }, [rectsSelections])

  const onReplaceToolClick = () => {
    // do a size check...
    const currentSelectionBound = toolpack.getSelectionBound();
    system_operations.coordinator.toolpack.disableSelectedPapers(); // disable (hide) any selections.
    system_operations.coordinator.pendingCanvasSystem.startPlacementMode(rectsSelections, currentSelectionBound, false);
  }

  return (
    <div>
      <GridContainer isVertical={isVertical} title='Edit Papers'>
        {
          hasPower ?
          <>
            <ToolItem icon={sdrag_move_2_line} tip="Re-Place the Paper" selected={false}
              onClick={onReplaceToolClick}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
          </> :
          <div>
            No Permission...
          </div>
        }
      </GridContainer>
    </div>
  );
}


export const PaperCover = ({ rect, selected }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [isHover, setIsHover] = useState(false);

  if (selected) {
    return null;
  } else {
    return (
      <div
        onPointerEnter={() => { setIsHover(true) }} onPointerLeave={() => { setIsHover(false) }}
        style={{ position: "absolute", width: rect.getActiveWidth(), height: rect.getActiveHeight(), left: 0, top: 0, backgroundColor: isHover ? "rgba(255,255,255,0.3)" : "transparent" }}></div>
    );
  }
}