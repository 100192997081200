import React, { useState, useEffect, useContext, useRef } from 'react';
import { GridContainer, ToolItem, divider } from '../../components/toolBar/UltimateControlPanel';

import Button from '@mui/material/Button';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import brush_line from '../../icons/brush_icons/ruler-pencil-white.svg'
import brush_pen from '../../Klecks/app/img/ui/brush-pen.svg'
import brush_blend from '../../Klecks/app/img/ui/brush-blend.svg'
import brush_chemy from '../../Klecks/app/img/ui/brush-chemy.svg'
import brush_eraser from '../../Klecks/app/img/ui/brush-eraser.svg'
import brush_pixel from '../../Klecks/app/img/ui/brush-pixel.svg'
import brush_pressure from '../../Klecks/app/img/ui/brush-pressure.svg'
import brush_sketchy from '../../Klecks/app/img/ui/brush-sketchy.png'
import brush_smudge from '../../Klecks/app/img/ui/brush-smudge.svg'

import circle from '../../Klecks/app/img/ui/circle.png'
import chalk from '../../Klecks/app/img/ui/chalk.png'
import calligraphy from '../../Klecks/app/img/ui/calligraphy.png'
import square from '../../Klecks/app/img/ui/square.png'

export const allBrushTypes = [
  "penBrush",
  "blendBrush",
  "sketchyBrush",
  "pixelBrush",
  "chemyBrush",
  "smudgeBrush",
  "eraserBrush",
]

export const baseSubItemStyle = {
  boxSizing: 'border-box',
  borderRadius: "5px",
  backgroundColor: "rgba(0, 0, 0, 0)",
  color: "#e0e0e0",
  border: '2px solid #e0e0e0',
  cursor: "pointer",
  fontSize: "14px",
  textAlign: "center",
}


export const BrushControlPanel = ({ toolpack, isVertical }) => {
  const [brushType, setBrushType] = useState(toolpack.brush.type);

  const containerStyle = {
    boxSizing: 'border-box',
    [isVertical ? "width" : "height"]: "100%",
    zIndex: "100000000000000000"
  }

  const subItemBaseStyle = { ...baseSubItemStyle, [isVertical ? "width" : "height"]: "25%" };
  const subItemHoverStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.2)" };
  const subItemSelectedStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.5)" };

  const divider = (
    <div style={{
      borderRadius: "1px", backgroundColor: "#e0e0e0",
      [isVertical ? "height" : "width"]: "1px", [isVertical ? "width" : "height"]: "100%",
      [isVertical ? "marginBottom" : "marginRight"]: 3, [isVertical ? "marginTop" : "marginLeft"]: 3,
    }} />
  );

  const setBrushTypeGeneral = (type) => {
    toolpack.brush.setType(type);
    // toolpack.kDraw.setBrushType(type);
    setBrushType(type);
  }

  let settingField = null;
  switch (brushType) {
    case "penBrush":
      // console.log("is this runable???");
      settingField = (<PenBrushSetting toolpack={toolpack} isVertical={isVertical} />);
      break;
    case "lineBrush":
      settingField = (<LineBrushSetting toolpack={toolpack} isVertical={isVertical} />);
      break;
    case "blendBrush":
      settingField = (<BlendBrushSetting toolpack={toolpack} isVertical={isVertical} />);
      break;
    case "sketchyBrush":
      settingField = (<SketchyBrushSetting toolpack={toolpack} isVertical={isVertical} />);
      break;
    case "pixelBrush":
      settingField = (<PixelBrushSetting toolpack={toolpack} isVertical={isVertical} />);
      break;
    case "chemyBrush":
      settingField = (<ChemyBrushSetting toolpack={toolpack} isVertical={isVertical} />);
      break;
    case "smudgeBrush":
      settingField = (<SmudgeBrushSetting toolpack={toolpack} isVertical={isVertical} />);
      break;
    case "eraserBrush":
      settingField = (<EraserBrushSetting toolpack={toolpack} isVertical={isVertical} />);
      break;
    default:
      settingField = (<PenBrushSetting toolpack={toolpack} isVertical={isVertical} />);
  }

  return (
    <div style={containerStyle}>
      <GridContainer isVertical={isVertical} title='Brush Type'>
        <ToolItem icon={brush_pen} onClick={() => { setBrushTypeGeneral("penBrush") }} selected={brushType === "penBrush"} baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} alt={"brush"} />
        <ToolItem icon={brush_line} onClick={() => { setBrushTypeGeneral("lineBrush") }} selected={brushType === "lineBrush"} baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} alt={"brush"} />
        <ToolItem icon={brush_blend} onClick={() => { setBrushTypeGeneral("blendBrush") }} selected={brushType === "blendBrush"} baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} alt={"brush"} />
        <ToolItem icon={brush_chemy} onClick={() => { setBrushTypeGeneral("chemyBrush") }} selected={brushType === "chemyBrush"} baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} alt={"brush"} />
        <ToolItem icon={brush_eraser} onClick={() => { setBrushTypeGeneral("eraserBrush") }} selected={brushType === "eraserBrush"} baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} alt={"brush"} />
        <ToolItem icon={brush_pixel} onClick={() => { setBrushTypeGeneral("pixelBrush") }} selected={brushType === "pixelBrush"} baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} alt={"brush"} />
        <ToolItem icon={brush_sketchy} onClick={() => { setBrushTypeGeneral("sketchyBrush") }} selected={brushType === "sketchyBrush"} baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} alt={"brush"} />
        <ToolItem icon={brush_smudge} onClick={() => { setBrushTypeGeneral("smudgeBrush") }} selected={brushType === "smudgeBrush"} baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} alt={"brush"} />
      </GridContainer>
      {divider}
      {settingField}
    </div>
  )
}


export const TextControlPanel = ({ toolpack, isVertical }) => {
  const containerStyle = {
    boxSizing: 'border-box',
    backgroundColor: "rgba(50,50,50,1)",
    borderRadius: "3px",
    [isVertical ? "width" : "height"]: "100%",
    padding: "3px",
    zIndex: "100000000000000000"
  }

  return (
    <div style={containerStyle}>
      <CanvasDefaultButtons toolpack={toolpack} isVertical={isVertical} />
    </div>
  )
}


const PenBrushSetting = ({ toolpack, isVertical }) => {
  const [subBrushType, setSubBrushType] = useState("penBrush");

  const containerStyle = {
    boxSizing: 'border-box',
    backgroundColor: "rgba(50,50,50,1)",
    borderRadius: "3px",
    [isVertical ? "width" : "height"]: "100%",
    padding: "3px",
    zIndex: "100000000000000000"
  }

  const subItemBaseStyle = { ...baseSubItemStyle, [isVertical ? "width" : "height"]: "20%" };
  const subItemHoverStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.2)" };
  const subItemSelectedStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.5)" };

  const setBrushTypeGeneral = (tip) => {
    toolpack.brush.setTip(tip);
    // toolpack.kDraw.setBrushType(type);
    setSubBrushType(tip);
  }

  return (
    <div>
      <GridContainer isVertical={isVertical} title='Tip Type'>
        <ToolItem icon={circle} onClick={() => { setBrushTypeGeneral(0) }} selected={subBrushType === 0} baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} alt={"brush"} />
        <ToolItem icon={chalk} onClick={() => { setBrushTypeGeneral(1) }} selected={subBrushType === 1} baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} alt={"brush"} />
        <ToolItem icon={calligraphy} onClick={() => { setBrushTypeGeneral(2) }} selected={subBrushType === 2} baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} alt={"brush"} />
        <ToolItem icon={square} onClick={() => { setBrushTypeGeneral(3) }} selected={subBrushType === 3} baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} alt={"brush"} />
      </GridContainer>
      <BrushSettingBase toolpack={toolpack} canSetColor={true} canSetSize={true} canSetOpacity={true} />
    </div>
  )
}

const LineBrushSetting = ({ toolpack }) => {
  return (
    <BrushSettingBase toolpack={toolpack} canSetColor={true} canSetSize={true} canSetOpacity={true} />
  )
}

const BlendBrushSetting = ({ toolpack }) => {
  return (
    <BrushSettingBase toolpack={toolpack} canSetColor={true} canSetSize={true} canSetOpacity={true} />
  )
}

const SketchyBrushSetting = ({ toolpack }) => {
  return (
    <BrushSettingBase toolpack={toolpack} canSetColor={true} canSetSize={true} canSetOpacity={true} />
  )
}

const PixelBrushSetting = ({ toolpack }) => {
  return (
    <BrushSettingBase toolpack={toolpack} canSetColor={true} canSetSize={true} canSetOpacity={true} />
  )
}

const ChemyBrushSetting = ({ toolpack }) => {
  return (
    <BrushSettingBase toolpack={toolpack} canSetColor={true} canSetSize={true} canSetOpacity={true} />
  )
}

const SmudgeBrushSetting = ({ toolpack }) => {
  return (
    <BrushSettingBase toolpack={toolpack} canSetColor={true} canSetSize={true} canSetOpacity={true} />
  )
}

const EraserBrushSetting = ({ toolpack }) => {
  return (
    <BrushSettingBase toolpack={toolpack} canSetColor={true} canSetSize={true} canSetOpacity={true} />
  )
}

const BrushSettingBase = ({ toolpack, canSetColor = true, canSetSize = true, canSetOpacity = true, isVertical = true }) => {

  const changeColor = (event) => {
    toolpack.brush.setColor(event.target.value)
  }

  const changeSize = (event) => {
    const newValue = parseInt(event.target.value, 10)
    toolpack.brush.setSize(newValue);
  }

  const changeOpacity = (event) => {
    let newValue = parseInt(event.target.value, 10)
    newValue /= 100;
    toolpack.brush.setOpacity(newValue);
  }

  return (
    <div>
      {canSetColor && <input type="color" defaultValue={toolpack.brush.getToolColor()} onChange={changeColor} style={{ width: "100%", marginBottom: "3px" }} />}
      {canSetSize && <DragFreezingRangeBar min={1} max={100} defaultValue={toolpack.brush.getSize()} onChange={changeSize} isVertical={isVertical} label={"Size"}/>}
      {canSetOpacity && <DragFreezingRangeBar min={1} max={100} defaultValue={toolpack.brush.getOpacity() * 100} onChange={changeOpacity} isVertical={isVertical} label={"Opacity"}/>}
      <CanvasDefaultButtons toolpack={toolpack} isVertical={isVertical} />
    </div>
  )
}


const CanvasDefaultButtons = ({ toolpack, isVertical }) => {
  const [isEmpty, setIsEmpty] = useState(toolpack.system?.coordinator?.kDraw.isChangesEmpty);

  useEffect(() => {
    if (toolpack.system?.coordinator?.kDraw) {
      toolpack.system.coordinator.kDraw.onIsChangesEmptyChange["_CDB"] = setIsEmpty;
    }

    return () => {
      if (toolpack.system?.coordinator?.kDraw) {
        delete toolpack.system.coordinator.kDraw.onIsChangesEmptyChange["_CDB"];
      }
    }
  }, [toolpack])

  const undoAction = () => {
    toolpack.undo();
  }

  const redoAction = () => {
    toolpack.redo();
  }

  const handleSubmit = () => {
    toolpack.saveDrawing()
  }
  const handleDump = () => {
    toolpack.resetDrawing();
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* 2 MUI buttons in the same line */}
        <Button className='inputs' variant="contained" color="primary" onClick={undoAction} sx={{ height: 20, backgroundColor: "#777777" }}>
          <UndoIcon /> Undo
        </Button>
        <Button className='inputs' variant="contained" color="primary" onClick={redoAction} sx={{ height: 20, backgroundColor: "#777777" }}>
          <RedoIcon /> Redo
        </Button>
      </div>
      <Button
        className='inputs'
        variant="contained"
        style={{ width: '100%', marginTop: '5px', height: 22, backgroundColor: isEmpty ? "grey" : "green" }}
        onClick={handleSubmit}
        disabled={isEmpty}
      >
        <CheckIcon style={{ marginRight: '5px' }} /> Submit!
      </Button>
      <Button
        className='inputs'
        variant="contained"
        style={{ width: '100%', marginTop: '5px', height: 22, backgroundColor: isEmpty ? "grey" : "red" }}
        onClick={handleDump}
        disabled={isEmpty}
      >
        <ClearIcon style={{ marginRight: '5px' }} /> Dump...
      </Button>
    </div>
  );
}

const DragFreezingRangeBar = ({ min, max, defaultValue, onChange, isVertical = true, label }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    const mouseUp = () => { }
    window.addEventListener('mouseup', mouseUp);
    window.addEventListener('touchend', mouseUp);
    return () => {
      window.removeEventListener('mouseup', mouseUp);
      window.removeEventListener('touchend', mouseUp);
    };
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className='inputs' style={{ position: 'relative', width: [isVertical ? "100%" : "150px"], border: '2px solid white', borderRadius: "2px", marginBottom: "3px" }}>
      <div style={{ padding: "0px", fontSize:"14px", color: "white", lineHeight: '1', }}>
        {`${label}: ${value}`}
      </div>
      <input type="range" style={{ width: "100%", zIndex: 1 }} min={min} max={max} defaultValue={defaultValue} onChange={handleChange} />
    </div>
  )
}