import React, { useState, useContext, useEffect } from 'react';
import { Box, Grid, Hidden, Accordion, AccordionSummary, AccordionDetails, List, ListItem, Typography, IconButton, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Navbar from "../home_page_components/Navbar"
import SideBar from "../home_page_components/SideBar"
import PostList from "../home_page_components/PostList"
import FriendList from "../home_page_components/FriendList"
import MainPanelDisplayWalls from "../home_page_components/MainPanelDisplayWalls"
import RecentVisit from "../home_page_components/RecentVisit"

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { SystemOperationsContext } from "../context/SystemRunnerContext";


const sidebarWidth = 320;
const SideBarArea = ({ toggleDrawer }) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: `${sidebarWidth}px`, backgroundColor: theme.backgroundColor.homePageUIDarker2, color: 'white', height: '100%', overflow: "auto"}}>
      <SideBar toggleDrawer = {toggleDrawer} sidebarWidth={sidebarWidth} />
    </Box>
  );
};


const MobileSidebar = ({ isOpen, toggleDrawer }) => {
  const theme = useTheme();
  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={() => {toggleDrawer(false)}}
      PaperProps={{
        sx: { width: `${sidebarWidth}px`, backgroundColor: theme.backgroundColor.homePageUIDarker2, color: 'white' }
      }}
    >
      <SideBar toggleDrawer={toggleDrawer} sidebarWidth={sidebarWidth} />
    </Drawer>
  );
};

const MainAreaTop = ({shortenDisplay}) => {
  return (
    <div style={{ height: '80vh', margin: 6, marginLeft: 6 }}>
      <MainPanelDisplayWalls shortenDisplay={shortenDisplay}/>
    </div>
  );
};

//sytle={{width: "100%", height: "100%"}}
const MainAreaBottom = () => {
  return (
    <div style={{ display: 'flex', height: '60vh', margin: 6 }}>
      <div style={{ flex: 7 }}>
        <PostList />
      </div>
      <div style={{ flex: 4, marginLeft: 6 }}>
        <FriendList />
      </div>
    </div>
  );
};

const Home = () => {
  const { system_operations } = useContext(SystemOperationsContext);
  system_operations.wallsData.getWallDataByID(system_operations.getUserID());
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));

  useEffect(() => {
    system_operations.exitTheWall();
  }, [])

  const toggleDrawer = (open) => { // We create a closure here
    setDrawerOpen(open);
  };

  return (
    <div style={{ height: "100vh", width: "100%", backgroundColor: "rgb(210, 210, 210)" }}>
      <Navbar height={64} setSidebarVisibility={toggleDrawer} sidebarHidden={!isDrawerOpen && !isLargeScreen}/>
      <div style={{ display: 'flex', overflow: 'hidden', height: 'calc(100vh - 64px)' }}>
        {isLargeScreen ? (
          <div style={{ width: 320, height: '100%' }}>
            <SideBarArea toggleDrawer={toggleDrawer}/>
          </div>
        ) : (
          <MobileSidebar isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
        )}
        <div style={{ flexGrow: 1, height: '100%', overflowY: 'auto' }}>
          <MainAreaTop shortenDisplay={!isLargeScreen}/>
          <MainAreaBottom />
        </div>
      </div>
    </div>
  );
};

export default Home;