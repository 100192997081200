import React, { useMemo, useEffect, useState, useContext, useRef } from 'react';
import { SystemOperationsContext } from "../../context/SystemRunnerContext";
import { RequestDataMaker } from "../../system/RestServerConnector.js"

import { TextField, Button, styled } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: "#777777",
  borderRadius: "10px",
  padding: "10px",
});

const StyledTextField = styled(TextField)({
  width: '700px',
  maxWidth: '1500px',
  '@media (max-width: 500px)': {
    width: '90%',
  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const FCLinkPrompt = ({ rect, onChangeCallback = null }) => {
  const canvasData = rect.getData();
  // markID is type_typeID
  const { system_operations } = useContext(SystemOperationsContext);
  const [link, setLink] = React.useState(rect.getData().link || "");

  const handleTextChange = (e) => {
    setLink(e.target.value);
  };

  const handleSubmit = async () => {
    system_operations.set_loading(true);
    if (link) {
      // create new one..
      canvasData.extension = {type: "link", data: {link: link}};
      const dataJson = await RequestDataMaker.dataToUpdatePaperExtension_link(system_operations, canvasData.canvasID, link);
      await system_operations.restServerConnector.requestToUpdatePaperExtension(dataJson, []);
      if (onChangeCallback) { onChangeCallback(link); }
    } else {
      // delete old one
      delete canvasData.extension;
      const dataJson = await RequestDataMaker.dataToDeletePaperExtension(system_operations, canvasData.canvasID);
      await system_operations.restServerConnector.requestToUpdatePaperExtension(dataJson, []);
      if (onChangeCallback) { onChangeCallback(""); }
    }
    system_operations.set_loading(false);
  };

  return (
    <Container>
      <StyledTextField
        variant="outlined"
        value={link}
        placeholder='Enter a link...'
        onChange={handleTextChange}
      />
      <StyledButton
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        startIcon={<StarIcon />}
      >
        LINK
      </StyledButton>
    </Container>
  );
};
