import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/system';
import Typography from '@mui/material/Typography';

import { SystemOperationsContext } from "../context/SystemRunnerContext";
import StickerCreation from "./StickerCreation";

const baseColor = 'rgba(128, 128, 128, 0.7)';
const secondaryTextColor = "rgba(225, 225, 225, 1)";
const borderRadius = '10px';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: baseColor,
    padding: "10px",
    borderRadius: borderRadius,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(10px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 'none',  // Dialog will grow with content
    minWidth: '200px',  // Dialog will be at least 300px wide
  },
}));

function ContentWindow({ fieldName, styleOverride = {} }) {
  const { system_operations } = useContext(SystemOperationsContext);
  const [open, setOpen] = useState(false);

  const [content, setContent] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    system_operations[fieldName] = handleOpenOrClose;
  }, []);

  const handleOpenOrClose = (newContent) => {
    if (newContent) {
      system_operations.coordinator?.kDraw?.setAllowShotcupKeys(false);
      setContent(newContent);
      setOpen(true);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    system_operations.coordinator?.kDraw?.setAllowShotcupKeys(true);
    setOpen(false);
    // don't change content
  };

  const style = {
    backdropFilter: 'blur(3px)',
    ...styleOverride,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const paperProps = {
    borderRadius: "0px",
    padding: "0px",
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: 'auto',
    height: 'auto',
    maxWidth: '90vw',  // Maximum width is 90% of viewport width
    maxHeight: '90vh',  // Maximum height is 90% of viewport height
    overflow: 'auto',   // Makes content scrollable if it exceeds the size
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Dialog
      open={open}
      sx={style}
      PaperProps={{
        style: paperProps
      }}
      onClose={handleClose}
    >
      <div>
        {content}
      </div>
    </Dialog>
  );
}

export default ContentWindow;