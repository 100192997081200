import pica from 'pica';

export class ImageProcessor {
  static picaInstance = pica();
  static previewImageSize = 160;

  constructor() {
  }

  static static_loadImageUrlToCanvas(url, ctx, returnData = false) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Add this line
      // resolve will only trigger after onload.
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        if (returnData) {
          const dataUrl = ctx.canvas.toDataURL();
          resolve(dataUrl);
        } else {
          resolve(ctx);
        }
      };
      img.onerror = (err) => reject(err);
      img.src = url;
    });
  }

  static static_loadImageData(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Add this line
      // resolve will only trigger after onload.
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const dataUrl = canvas.toDataURL();
        resolve(dataUrl);
      };
      img.onerror = (err) => reject(err);
      img.src = url;
    });
  };

  loadImageData(url) {
    return ImageProcessor.static_loadImageData(url);
  };

  loadImageUrlToCanvas(url, ctx, returnData = false) {
    return ImageProcessor.static_loadImageUrlToCanvas(url, ctx, returnData);
  };

  static async static_fileToBase64(file) {
    const data = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
    return await data;
  }

  static static_base64ImageToByteData(base64ImageData, needsCleanUp = true) {
    let dataStringClean = base64ImageData;
    if (needsCleanUp) { dataStringClean = ImageProcessor.static_cleanImageDataString(base64ImageData); }
    const imageData = Uint8Array.from(atob(dataStringClean), c => c.charCodeAt(0));
    return imageData;
  }

  // in case on have a instance
  base64ImageToByteData(base64ImageData, needsCleanUp = true) {
    return ImageProcessor.static_base64ImageToByteData(base64ImageData, needsCleanUp);
  }

  static static_cleanImageDataString(inputStr) {
    if (typeof inputStr !== 'string') {
      throw new Error('Input must be a string.');
    }
    const regex = /,([^,]+)/;
    const match = inputStr.match(regex);

    if (match) {
      return match[1]; // Return the part after the first comma
    } else {
      return inputStr;
    }
  }

  cleanImageDataString(inputStr) {
    return ImageProcessor.static_cleanImageDataString(inputStr);
  }

  static async static_loadImage(imageSrc) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
    });
  }

  async loadImage(imageSrc) {
    return ImageProcessor.static_loadImage(imageSrc);
  }

  static async static_compressImage(imageSrc, maxX, maxY) {
    try {
      const image = await ImageProcessor.static_loadImage(imageSrc);
      const aspectRatio = image.width / image.height;

      let newWidth = Math.min(image.width, maxX);
      let newHeight = newWidth / aspectRatio;
      if (newHeight > maxY) {
        newHeight = maxY;
        newWidth = newHeight * aspectRatio;
      }
      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0, newWidth, newHeight);

      const compressedImageData = canvas.toDataURL('image/png');
      return compressedImageData;
    } catch (error) {
      throw error;
    }
  }

  async compressImage(imageSrc, maxX, maxY) {
    // will be awaited if put await infront the call. cause a new promise is returned.
    return ImageProcessor.static_compressImage(imageSrc, maxX, maxY);
  }

  static async static_resizeBase64Image(base64Data, width, height) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        try {
          const result = await ImageProcessor.picaInstance.resize(img, canvas);
          // const resizedBlob = await ImageProcessor.picaInstance.toBlob(result, 'image/jpeg', 0.8); // You can adjust the quality value based on your requirements
          resolve({ resizedBase64: result.toDataURL('image/png'), width, height });
        } catch (error) {
          reject(error);
        }
      };
      img.onerror = error => reject(error);
      img.src = base64Data;
    });
  }

  async resizeBase64Image(base64Data, maxWidth) {
    return ImageProcessor.static_resizeBase64Image(base64Data, maxWidth)
  }

  static async static_changeImageSizeTo(base64, targetX, targetY) {
    return await new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = targetX;
        canvas.height = targetY;
        ctx.drawImage(img, 0, 0, targetX, targetY);
        const resizedDataUrl = canvas.toDataURL();
        resolve(resizedDataUrl);
      };
      img.src = base64;
    });
  }

  async changeImageSizeTo(base64, targetX, targetY) {
    return ImageProcessor.static_changeImageSizeTo(base64, targetX, targetY);
  }

  static static_canvasToImage(canvas) {
    const img = new Image();
    img.src = canvas.toDataURL("image/png");
    return img;
  }

  canvasToImage(canvas) {
    return ImageProcessor.static_canvasToImage(canvas);
  }


  static async static_canvasToImageAsync(canvas) {
    return await new Promise((resolve, reject) => {
      const img = new Image();
      img.src = canvas.toDataURL("image/png");
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
    });
  }

  async canvasToImageAsync(canvas) {
    return ImageProcessor.static_canvasToImageAsync(canvas);
  }

  static async static_drawImageToCanvas(canvas, ctx, imageUrl) {
    return new Promise((resolve, reject) => {
      const backgroundImage = new Image();
      backgroundImage.src = imageUrl;
      backgroundImage.onload = () => {
        ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
        resolve();
      };
      backgroundImage.onerror = (error) => {
        reject(error);
      };
    });
  }

  static static_fitImageSize(currentW, currentH, maxW, maxH, maxMargin = 0, strech = true) {
    let ratio = maxH / currentH;
    // console.log("pre ratio", ratio, "and data are: ", currentW, currentH, maxW, maxH, maxMargin, strech);
    if (!strech && ratio > 1) {
      ratio = 1; // not strech to fit
    }
    // console.log("ratio", ratio);
    let scaledHeight = currentH * ratio;
    let scaledWidth = currentW * ratio;
    const newCanvasWidthRatio = scaledWidth / maxW;
    if (newCanvasWidthRatio > 1) {
      // make sure both w and h fit in the space.
      scaledWidth /= newCanvasWidthRatio;
      scaledHeight /= newCanvasWidthRatio;
    }
    if (maxMargin > 0) {
      [scaledWidth, scaledHeight] = ImageProcessor.private_addMargin(scaledWidth, scaledHeight, maxW, maxH, maxMargin);
    }
    // console.log("the final is ", scaledWidth, scaledHeight);
    return [scaledWidth, scaledHeight];
  }

  // maxMargin is the maxium amount the long side can exceed the maxium, but the actual marginal up depends on the shorter side.
  static private_addMargin(scaledWidth, scaledHeight, maxW, maxH, maxMargin) {
    const differW = maxW - scaledWidth;
    const differH = maxH - scaledHeight;

    if (differW >= differH) { // can exceed the size by only maxMargin amount, so always go with the side that is further from the max.
      const marginUp = Math.min(differW, maxMargin); // even though can exceed, never go larger than the orinal max for the shorter side.
      const newHeight = scaledHeight + marginUp; // the long side get the margin
      const ratioUp = newHeight / scaledHeight;
      const newWidth = scaledWidth * ratioUp;
      return [newWidth, newHeight]
    } else {
      const marginUp = Math.min(differH, maxMargin); // even though can exceed, never go larger than the orinal max for the shorter side.
      const newWidth = scaledWidth + marginUp; // the long side get the margin
      const ratioUp = newWidth / scaledWidth;
      const newHeight = scaledHeight * ratioUp;
      return [newWidth, newHeight]
    }
  }

  fitImageSize(currentW, currentH, maxW, maxH, maxMargin = 0, strech = true) {
    return ImageProcessor.static_fitImageSize(currentW, currentH, maxW, maxH, maxMargin, strech);
  }

  async drawImageToCanvas(canvas, ctx, imageUrl) {
    return ImageProcessor.static_drawImageToCanvas(canvas, ctx, imageUrl);
  }

  resizeCanvas(canvas, newWidth, newHeight) {
    ImageProcessor.static_resizeCanvas(canvas, newWidth, newHeight)
  }

  static static_resizeCanvas(canvas, newWidth, newHeight) {
    const context = canvas.getContext("2d");
    let imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    canvas.width = newWidth;
    canvas.height = newHeight;
    context.putImageData(imageData, 0, 0);
  }

  static static_returnDimensionFromImageUrl(imageUrl) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = function () {
        resolve([this.width, this.height]);
      };

      img.onerror = function () {
        reject(new Error("Failed to load image"));
      };

      img.src = imageUrl;
    });
  }
}
