import { v4 as uuidv4 } from 'uuid';

import React, { createContext, useState, useEffect } from "react";
import { LoginCard } from "../pages/Login.jsx"
import { LoadingSceneController } from "../system/LoadingSceneController.js"
import { PopUpController } from "../system/PopUpController.js"
import { WallPageManager } from "../system/WallPageManager.js"
import { Location } from "../system/Location.js"
import { Coordinator } from "../system/Coordinator.js"
import { PerformanceMeter } from "../system/PerformanceMeter.js"
import { FenceMaker } from "../system/FenceMaker.js"
import { ContentMarkers } from "../system/ContentMarkers.js"
import { SocketConnector } from "../system/SocketConnector.js"
import { RestServerConnector } from "../system/RestServerConnector.js"
import { FloatPageController } from "../system/FloatPageController.js"
import { ToolsPack } from "../system/toolpack/ToolsPack.js"

import { ImageProcessor } from "../utility/ImageProcessor.js"
import { ByteFlagsCoder } from "../utility/ByteFlags.js"

import { FirestoreActions } from "../auth/FirestoreActions";
import { AuthSystem } from "../auth/AuthSystem";
import { PostSystem } from "../auth/PostSystem";
import { WallSystem } from "../auth/WallSystem";

import app from "../firebase.js";
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";

export const SystemOperationsContext = createContext();


class SystemRunnerOperations {
  constructor() {
    this.createGuestUser();
    this.refresh_user_count = 0;
    this.initializeAllNoneTimeSensitiveUserData();
    this.createSignals();
    this.auth = getAuth(app);

    this.authSystem = new AuthSystem(app);
    this.postSystem = new PostSystem(app);
    this.wallSystem = new WallSystem(app);

    this.currentBoardID = "";
    this.shouldReEnterBoard = true;

    this.refresh_user_hook = null;
    this.loading_controller = new LoadingSceneController();
    this.pop_up_controller = new PopUpController();
    this.wallPageManager = new WallPageManager();
    this.socket_connector = new SocketConnector(this);
    this.restServerConnector = new RestServerConnector(this);
    this.imageProcessor = new ImageProcessor();
    this.firestoreActions = new FirestoreActions();
    this.fenceMaker = new FenceMaker();
    this.contentMarkers = new ContentMarkers();
    this.floatPageController = new FloatPageController();
    this.enqueueSnackbarHook = () => { };

    this.setContentWindow = (content) => { }; // not assigned yet
    this.setContentWindow2 = (content) => { }; // not assigned yet
    this.setSystemWideWindow = (content) => { }; // not assigned yet

    this.usersData = new UsersData();
    this.postLoader = new PostLoader(this);
    this.wallsData = new WallsData();
    this.fenceCoder = new ByteFlagsCoder(["drawingSelf", "addingCanvasSelf", "postingSelf", "addingButtonSelf", "", "", "", "", "drawingOther", "addingCanvasOther", "postingOther", "addingButtonOther"]);
    this.fenceCoder.initialState = 7; // allowing self doing things.
    this.generalGuideFinsihed = false;

    this.coordinator = new Coordinator(this, this.wallSystem);
    this.performanceMeter = new PerformanceMeter(this);
    // this.userSettings = new UserSettings(this);

    this.onMouseHolding = {};
    this.functionsDict = {}; // is used to store all kinds of functions, data.
    this.onUserChange = {};
    this.onMouseModeChange = {};

    // this.private_assignOnLocationChange();
    this.private_homePageDataInitialization();
    this.boardSaveSystem = new BoardSaveSystem(this);
  }

  createSignals() {
    this.wallInitialized = false;
    this.frontBusy = false;
    this.isMouseMode = true;
  }

  createGuestUser() {
    this.loggedIn = false;
    this.userID = `guest_${uuidv4()}`;
    this.user = null;
    this.userData = {
      loggedIn: false, userID: this.userID, avatar: "none", email: "", idToken: "", recentVisit: [], username: "wonderer"
    };
    this.idToken = "";
  }

  // this is called after signin or register
  async set_user(user) {
    if (!this.loggedIn || user.uid !== this.userID) {
      this.user = user;
      this.userID = user.uid;
      this.loggedIn = true;
      const userData = await this.usersData.getUserDataByID(this.userID);
      this.userData = userData;
      // this.userSettings.setUser(this.userData);
      this.userData.loggedIn = true;

      await this.obtainIDToken(false); // set the token for user
      if (!this.idToken) { return false } // block the login if token is null
      this.getAllNoneTimeSensitiveUserData();
      this.socket_connector.disconnect();
      if (this.coordinator.wallInitialized) {
        await this.socket_connector.connect(this.userData, this.coordinator.getWallData())
      }
      if (this.currentBoardID) { // has this field means it's loaded with the other data. we might need to override it.
        this.coordinator?.setUser(this.userID);
      }
      if (this.refresh_user_hook) { this.refresh_user_count += 1; this.refresh_user_hook(this.refresh_user_count); }
      for (const key in this.onUserChange) {
        this.onUserChange[key](this.userData, this.user);
      }
    }
  }

  async signOutUser() {
    this.user = null;
    this.userID = "";
    this.userData = null;
    this.initializeAllNoneTimeSensitiveUserData();
    this.coordinator.setUser(""); // no more users. will read default role...
    if (this.refresh_user_hook) { this.refresh_user_count += 1; this.refresh_user_hook(this.refresh_user_count); }
    for (const key in this.onUserChange) {
      this.onUserChange[key](this.userData, this.user);
    }
    this.loggedIn = false;
    for (const key in this.onUserChange) {
      this.onUserChange[key](this.userData, this.user);
    }
  }

  async obtainIDToken(refresh = false) {
    if (!this.loggedIn) {
      return "";
    }
    try {
      const idToken = await getIdToken(this.auth.currentUser, refresh);
      if (idToken !== this.idToken) {
        this.userData.idToken = idToken;
        this.idToken = idToken;
      }
      return idToken;
    } catch (error) {
      console.error(error);
      this.userData.idToken = "";
      this.idToken = "";
      return "";
    }
  }

  private_homePageDataInitialization() {
    this.homePageManager = new HomePageManager(this);
  }

  async enterAWall(boardID, initialzationCallback) {
    if (this.currentBoardID !== boardID) {
      this.currentBoardID = boardID;
      // this.shouldReEnterBoard = false;
      this.coordinator.restoreEveryData();
      let allAsync = [];
      if (this.loggedIn) { allAsync.push(this.addToRecentVisit(boardID)); }
      allAsync.push(this.coordinator.setWall(boardID, this.getUserID(), initialzationCallback));
      await Promise.all(allAsync);
      await this.socket_connector.connect(this.userData, this.coordinator.getWallData());
    }
  }

  // the problem with nevagate is exitTheWall is not called...
  exitTheWall() {
    // this.shouldReEnterBoard = true;
    if (this.currentBoardID) {
      this.currentBoardID = "";
      this.socket_connector.disconnect();
    }
  }

  assign_refresh_user_hook(refresh_hook) {
    this.refresh_user_hook = refresh_hook;
  }

  async getAllNoneTimeSensitiveUserData() {
    this.getAllFriends();
    this.getAllFollowers();
  }

  initializeAllNoneTimeSensitiveUserData() {
    this.onFollowingDoneLoading = {};
    this.onFollowersDoneLoading = {};
    this.following = [];
    this.follower = [];
  }

  async getAllFriends() {
    this.following = await this.firestoreActions.fetchFromFirestore(`users/${this.userID}/following`, "userID");
    for (const key in this.onFollowingDoneLoading) {
      this.onFollowingDoneLoading[key](this.following);
    }
  }

  async getAllFollowers() {
    this.followers = await this.firestoreActions.fetchFromFirestore(`users/${this.userID}/followers`, "userID");
    for (const key in this.onFollowersDoneLoading) {
      this.onFollowersDoneLoading[key](this.followers);
    }
  }

  getUserData() {
    return this.userData;
  }

  getUserID() {
    if (this.userData) {
      return this.userID;
    }
    return "";
  }

  getSocketID() {
    if (this.socket_connector) {
      return this.socket_connector.socketID;
    }
  }

  async addToRecentVisit(boardID) {
    const visitData = {
      time: Date.now(),
      boardID: boardID,
    }
    await this.firestoreActions.createDocumentAt(`users/${this.getUserID()}/recentVisits`, visitData, "boardID", boardID)
    this.homePageManager.recentVisitListReady = false;
  }

  async constructLikeJson(boardID, likedData = {}) {
    return {
      likeFieldName: "likes",
      targetType: "boards",
      targetRealm: "boards",
      boardID: boardID,
      targetID: boardID,
      reactionTargetID: boardID,     // to user' path
      reactionPathDirectory: boardID,
      userID: this.getUserID(),
      userRecord: likedData,
      idToken: await this.obtainIDToken(),
    }
  }

  mouseHoldingTrigger(x, y) {
    for (const key in this.onMouseHolding) {
      this.onMouseHolding[key]([x, y]);
    }
  }

  async addToSaved(boardID) {
    const newBoard = { boardID: boardID, time: Date.now() };
    const updateJson = await this.constructLikeJson(boardID, newBoard);
    this.restServerConnector.likeOrDislikeAnItem(updateJson, 1);
    this.homePageManager.savedBoards[boardID] = newBoard;
  }

  async removeFromSaved(boardID) {
    const updateJson = await this.constructLikeJson(boardID);
    this.restServerConnector.likeOrDislikeAnItem(updateJson, -1);
    delete this.homePageManager.savedBoards[boardID];
  }

  enqueueSnackbar(message, variant = "") {
    if (variant) {
      this.enqueueSnackbarHook(message, { variant });
    } else {
      this.enqueueSnackbarHook(message);
    }
  };

  addFunctionToDict(dict, key, func) {
    dict[key] = func;
  }

  removeFunctionFromDict(dict, key) {
    delete dict[key];
  }

  addToOnUserChange(key, func) {
    this.onUserChange[key] = func;
  }

  removeOnUserChange(key) {
    delete this.onUserChange[key];
  }

  check_is_loading() {
    return this.loading_controller.loading_scene_on;
  }
  assign_set_loading_hook(set_loading) {
    this.loading_controller.assign_set_loading_hook(set_loading);
  }
  set_loading(turn_on, forceful = false) {
    this.loading_controller.set_loading_scene(turn_on, forceful);
  }
  raiseLoadingError(error) {
    this.loading_controller.set_loading_scene(false, true);
    this.enqueueSnackbar(error.message, "error");
  }

  raiseLoadingErrorMessage(message) {
    this.loading_controller.set_loading_scene(false, true);
    this.enqueueSnackbar(message, "error");
  }

  assign_pop_up_window_hooks(set_has_pop_up, set_pop_up_window) {
    this.pop_up_controller.assign_pop_up_window_hooks(set_has_pop_up, set_pop_up_window)
  }
  set_pop_up_window(type, message, onConfirm, onCancel, onInputChange = (nothing) => { }, default_input_value = "") {
    this.pop_up_controller.set_pop_up_window(type, message, onConfirm, onCancel, onInputChange = (nothing) => { }, default_input_value = "");
  }

  setMouseMode(isMouseMode) {
    if (this.isMouseMode !== isMouseMode) {
      this.isMouseMode = isMouseMode;
      for (const key in this.onMouseModeChange) {
        this.onMouseModeChange[key](isMouseMode);
      }
    }
  }

  pleaseLogIn(description) {
    this.setSystemWideWindow(<LoginCard descriptionText={description} />)
  }
}

class BoardSaveSystem {
  constructor(system) {
    this.system = system;

    this.savedBoardsReady = false;
    this.savedBoards = {};
    this.notSavedBoards = new Set();
  }

  async addToSaved(boardID) {
    this.savedBoards[boardID] = {boardID, time: Date.now()}
    this.notSavedBoards.delete(boardID); // delete if not exist...

    const boardLikeData = { boardID: boardID, time: Date.now() };
    const updateJson = await this.system.constructLikeJson(boardID, boardLikeData);
    this.system.restServerConnector.likeOrDislikeAnItem(updateJson, 1);
  }

  async removeFromSaved(boardID) {
    delete this.savedBoards[boardID];
    this.notSavedBoards.add(boardID);

    const updateJson = await this.system.constructLikeJson(boardID);
    this.system.restServerConnector.likeOrDislikeAnItem(updateJson, -1);
  }

  async checkIfABoardIsSaved(userID, boardID) {
    if (boardID in this.savedBoards) {
      return true;
    } else if (this.notSavedBoards.has(boardID)) {
      return false;
    }
    let result = await this.system.firestoreActions.fetchFromFirestore(`users/${userID}/savedBoards/${boardID}`, "boardID");
    if (result) {
      this.savedBoards[boardID] = result
      return true;
    } else {
      this.notSavedBoards.add(boardID);
    }
    return false;
  }

  async getSavedBoards() {
    if (this.system.loggedIn) {
      if (this.savedBoardsReady) {
        return this.savedBoards;
      } else {
        const filters = {
          amount: null,
          startFrom: 0,
          orderByField: "time",
          orderDirection: "desc",
        }
        const fetchResult = await this.system.firestoreActions.fetchFromFirestore(`users/${this.system.getUserID()}/savedBoards`, "boardID", [], filters);
        // this.savedBoards = {}; // clean up first.
        for (const board of fetchResult) {
          this.savedBoards[board.boardID] = board;
        }
        return this.savedBoards;
      }
    }
    return [];
  }
}

class HomePageManager {
  constructor(system) {
    this.system = system;
    this.mainDisplayWalls = [];
    this.onMainDisplayWallsChange = {};

    this.savedListReady = false;
    this.ownBoardsListReady = false;
    this.recentVisitListReady = false;
    this.savedListCounter = 0;
    this.recentVisitListCounter = 0;

    this.displayingType = "trending";
    this.onDisplayTypeChange = {};
    this.isLoading = false;
    this.setLoadingHook = null;
    this.setLabelHook = () => { };

    this.recentVisitList = [];
    this.savedBoards = {};
    // this.notSavedBoards = new Set();
    this.ownBoards = {};
  }

  setLoading(loadingFlag) {
    this.isLoading = loadingFlag;
    if (this.setLoadingHook) {
      this.setLoadingHook(loadingFlag);
      this.mainDisplayWallsChanged([]);
    }
  }

  setDisplayType(type = "trending") {
    this.displayingType = type;
    for (const key in this.onDisplayTypeChange) {
      this.onDisplayTypeChange[key](type);
    }
  }

  // call setDisplayType outside to ensure something which i forgot.
  async tryDisplayCustomizedList(nameList, displayingType = "searching", label = "SEARCH RESULT") {
    if (displayingType === this.displayingType) {
      this.setLoading(true);
      this.setLabelHook(label);
      await this.fetchBoardDataFromIDList(nameList);
    }
  }

  async showDemos() {
    this.setLoading(true);
    const demoList = ["UCSC", "SantaCruz", "basicDemo",
      "artistDemo", "animeHubDemo",
      "restaurantDemo", "kidsDemo",
      "hearthstoneGamerDemo"];
    this.setLabelHook("DEMO BOARDS");
    await this.fetchBoardDataFromIDList(demoList);
  }

  async showTrending() {
    this.setLoading(true);
    const trendingIDList = (await this.system.restServerConnector.getTrending(0.5)).trendingList;
    this.setDisplayType("trending");
    this.setLabelHook("NOW TRENDING...");
    await this.fetchBoardDataFromIDList(trendingIDList);
  }

  async fetchBoardDataFromIDList(idList) {
    const supposedDisplayingType = this.displayingType;
    const loadingTasks = [];
    for (const boardID of idList) {
      loadingTasks.push(this.system.wallsData.getWallDataByID(boardID));
    }
    this.mainDisplayWalls = await Promise.all(loadingTasks);
    if (supposedDisplayingType === this.displayingType) {
      this.setLoading(false);
      this.mainDisplayWalls = this.mainDisplayWalls.filter(item => item !== null && item !== undefined);
      this.mainDisplayWallsChanged(this.mainDisplayWalls);
    }
  }

  async showOwnBoards(userID, reload = false) {
    if (this.system.loggedIn && !this.ownBoardsListReady) {
      this.setLoading(true);
      const fetchResult = await this.system.firestoreActions.fetchFromFirestore(`users/${userID}/boards`, "boardID", []);
      this.ownBoards = {}; // clean up first.
      for (const board of fetchResult) {
        this.ownBoards[board.boardID] = board;
      }

      this.ownBoardsListReady = true;
    }
    this.setDisplayType("ownBoard");
    this.setLabelHook("MY BOARDS");
    if (reload) {
      const idList = Object.keys(this.ownBoards);
      await this.fetchBoardDataFromIDList(idList);
    }
  }

  async showSaved(userID, reload = false) {
    if (this.system.loggedIn && !this.savedListReady) {
      this.savedBoards = await this.system.boardSaveSystem.getSavedBoards();
    }
    this.setDisplayType("saved");
    this.setLabelHook("YOUR FAVORITES");
    if (reload) {
      const idList = Object.keys(this.savedBoards);
      await this.fetchBoardDataFromIDList(idList);
    }
  }

  async showRecentVisit(reload = false) {
    if (this.system.loggedIn && !this.recentVisitListReady) {
      this.setLoading(true);
      const filters = {
        amount: 20,
        startFrom: 0,
        orderByField: "time",
        orderDirection: "desc",
      }
      this.recentVisitList = await this.system.firestoreActions.fetchFromFirestore(`users/${this.system.getUserID()}/recentVisits`, "boardID", [], filters);
      /* for (const board of recentVisitList) {
        this.savedRecentVisits[board.boardID] = board;
      }*/
      this.recentVisitListReady = true;
    }
    this.setDisplayType("recentVisit");
    this.setLabelHook("FOOTAGE");
    if (reload) {
      const idList = [];
      for (const board of this.recentVisitList) {
        idList.push(board.boardID);
      }
      await this.fetchBoardDataFromIDList(idList);
    }
  }

  async search() {
    this.mainDisplayWalls = [];
    this.mainDisplayWallsChanged(this.mainDisplayWalls);
  }

  mainDisplayWallsChanged(newMainDisplayWalls) {
    for (const key in this.onMainDisplayWallsChange) {
      this.onMainDisplayWallsChange[key](newMainDisplayWalls);
    }
  }
}

class UsersData {
  constructor() {
    this.userIDToData = {}
    this.followToListOfUser = {}
  }

  async getUserDataByID(userID) {
    if (userID in this.userIDToData) {
      return this.userIDToData[userID];
    } else {
      const userData = await FirestoreActions.static_fetchFromFirestore(`users/${userID}`, "userID");
      this.userIDToData[userID] = userData;
    }
    return this.userIDToData[userID];
  }

  async getUserFollowList(userID) {
    if (userID in this.followToListOfUser) {
      return this.followToListOfUser[userID];
    } else {
      const followToList = await FirestoreActions.static_fetchFromFirestore(`users/${userID}/following`, "userID");
      this.followToListOfUser[userID] = followToList;
    }
    return this.followToListOfUser[userID];
  }
}

class PostLoader {
  constructor(system) {
    this.system = system;
    this.instanceToPost = {}
    this.usersAllPosts = {}

    this.onUsersAllPostsChange = {}
  }

  updateLoadedPosts(userID, post, operation) {
    if (operation === "add") {
      if (this.usersAllPosts[userID]) {
        this.usersAllPosts[userID][post.postID] = post;
      }
    } else if (operation === "delete") {
      if (this.usersAllPosts[userID] && this.usersAllPosts[userID][post.postID]) {
        delete this.usersAllPosts[userID][post.postID];
      }
    }

    for (const key in this.onUsersAllPostsChange) {
      this.onUsersAllPostsChange[key](this.usersAllPosts);
    }
  }

  async getUsersAllPosts(userID) {
    if (userID in this.usersAllPosts) {
      return this.usersAllPosts[userID];
    } else {
      const allPosts = await this.system.firestoreActions.fetchFromFirestore(`users/${userID}/posts`, 'postID');
      const allPostDict = {};
      for (const post of allPosts) {
        allPostDict[post.postID] = post;
      }
      this.usersAllPosts[userID] = allPostDict;
    }
    return this.usersAllPosts[userID];
  }

  async getPostFromInstance(postInstance) {
    const postID = postInstance.postID;
    const userID = postInstance.creatorID;

    if (postID in this.instanceToPost) {
      return this.instanceToPost[postID];
    } else {
      const post = await FirestoreActions.static_fetchFromFirestore(`users/${userID}/posts/${postID}`, "postID");
      this.instanceToPost[postID] = post;
    }
    return this.instanceToPost[postID];
  }
}

class WallsData {
  constructor() {
    this.wallIDToData = {}
  }

  insertABoardData(board) {
    this.wallIDToData[board.wallID] = board;
  }

  async getWallDataByID(wallID) {
    if (wallID in this.wallIDToData) {
      return this.wallIDToData[wallID];
    } else {
      const wallData = await FirestoreActions.static_fetchFromFirestore(`boards/${wallID}`, "wallID");
      if (wallData) {
        this.wallIDToData[wallID] = wallData;
      } else {
        return null;
      }
    }
    return this.wallIDToData[wallID];
  }
}

export const SystemOperationsContextProvider = ({ children }) => {
  const [system_operations, setSystemOperations] = useState(new SystemRunnerOperations());
  const [refreshCounter, setRefreshCounter] = useState(0);

  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      if (system_operations.preventNextUserDefaultBehavior > 0) { // source: xxdjhfhdfunfnsafjsdajfosad
        system_operations.preventNextUserDefaultBehavior = 0;
      } else {
        await system_operations.set_user(user);
      }
    }
  });

  return (
    <SystemOperationsContext.Provider value={{ system_operations }}>
      {children}
    </SystemOperationsContext.Provider>
  );
};