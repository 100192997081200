import React, { useRef, useEffect, useContext, useState, useMemo } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { CanvasManipulator } from "../system/CanvasManipulator.js";
import StickerContainer from "./StickerContainer.jsx";
import { Magnetizer } from "../system/collisionSystem/Magnetizer.js"
// import TextInputer from "./TextInputer.jsx";

import { styled, useTheme } from '@mui/system';

const LocalDrawingCanvas = React.memo(({ }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);

  const [refresh_counter, set_refresh_counter] = useState(0);
  const [cursorOnMainCanvas, setCursorOnMainCanvas] = useState(true);

  const defaultTransform = useMemo(() => {
    return system_operations.coordinator.drawingCanvas.resetCanvasPosition();
  }, []);
  const [canvasTransform, setCanvasTransform] = useState(defaultTransform);
  /* const [canvasTransform, setCanvasTransform] = useState({
    displayW: 0, displayH: 0,
    theoW: 0, theoH: 0,
    displayX: 0, displayY: 0,
    theoX: 0, theoY: 0,
  }); */
  const canvasTransformRef = useRef(canvasTransform);
  const eventFloatingCanvasData = useRef(null);

  const theme = useTheme();
  const refreshData = () => {
    set_refresh_counter((prevCounter) => prevCounter + 1);
  };

  useEffect(() => {
    // console.log("at the begining: ", canvasRef.current.width, canvasRef.current.height);
    system_operations.coordinator.kDraw.initialize(canvasRef.current);
  }, [])

  useEffect(() => {
    const canvas = canvasRef.current;
    ctxRef.current = canvas.getContext('2d');

    system_operations.coordinator.assignRefreshDrawingCanvasHook(refreshData);
    system_operations.coordinator.onCoordinateChange["localDC"] = (coor) => {
      refreshData();
    };

    system_operations.coordinator.drawingCanvas.setCanvas(canvasRef.current);
    system_operations.coordinator.drawingCanvas.assignHooks((newTransform) => {
      setCanvasTransform(newTransform);
      canvasTransformRef.current = newTransform;
    });
    system_operations.coordinator.boardPositioner.updateScreenOffsetAndScale();
    system_operations.coordinator.setEventFloatingCanvasRect = (floatingCanvasRect) => {
      eventFloatingCanvasData.current = floatingCanvasRect;
      system_operations.coordinator.eventFloatingCanvasData = floatingCanvasRect;
    }

    const setIsCursorOnMainCanvasTrue = () => { setCursorOnMainCanvas(true); }
    const setIsCursorOnMainCanvasFalse = () => { setCursorOnMainCanvas(false); }

    const pointerUp = () => {
      // const currentTool = system_operations.coordinator.toolpack.get_current_tool();
      if (!system_operations.coordinator.toolpack.rectsSelectMode) {
        system_operations.coordinator.toolpack.setPaperSelection({});
      }
    }
    if (canvasRef.current) {
      canvasRef.current.addEventListener("pointerup", pointerUp);
    }

    if (containerRef.current) {
      containerRef.current.addEventListener("mouseenter", setIsCursorOnMainCanvasTrue);
      containerRef.current.addEventListener("mouseleave", setIsCursorOnMainCanvasFalse);
    }
    return () => {
      delete system_operations.coordinator.toolpack.onToolChange["localDC"];
      delete system_operations.coordinator.onCoordinateChange["localDC"];
      if (containerRef.current) {
        containerRef.current.removeEventListener("mouseenter", setIsCursorOnMainCanvasTrue)
        containerRef.current.removeEventListener("mouseleave", setIsCursorOnMainCanvasFalse)
      }
      if (canvasRef.current) {
        canvasRef.current.removeEventListener("pointerup", pointerUp);
      }
    }
  }, [])

  return (
    <div ref={containerRef} onDragStart={(e) => { e.preventDefault() }} onContextMenu={(e) => { e.preventDefault() }}
      style={{ height: "100%", width: "100%", overflow: "hidden" }}>
      {/* showTextInputPrompt && <TextInputer
        canvas={canvasRef.current}
  /> */}
      <GuideBox canvas={canvasRef.current} />
      <canvas className="pending-canvas"
        data-canvas-id="main"
        ref={canvasRef}
        width={canvasTransform.theoW} height={canvasTransform.theoH}
        style={{
          position: "absolute",
          left: canvasTransform.theoX,
          top: canvasTransform.theoY,
          width: canvasTransform.theoW,
          height: canvasTransform.theoH,
          zIndex: theme.zIndex.drawingCanvas
        }} />
    </div>
  );
});

const GuideBox = ({ canvas }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [active, setActive] = useState(false);
  const [theoreticalPosition, setTheoreticalPosition] = useState([0, 0]);
  const allow = useRef(true);
  const layer = useRef(0);
  const recordedMouseScreenPosition = useRef([0, 0]);
  const guideBoxRef = useRef(null);

  const theme = useTheme();

  useEffect(() => {
    system_operations.coordinator.guideBox.activationHook = setActive;
    if (active) {
      const insideCanvasCheck = () => {
        if (canvas) {
          const [x, y] = recordedMouseScreenPosition.current;
          const { left, top, right, bottom } = canvas.getBoundingClientRect();
          const isInside =
            x >= left &&
            x <= right &&
            y >= top &&
            y <= bottom;
          return isInside;
        }
        return false;
      }

      const recheck = (x, y) => {
        if (x === undefined || y === undefined) {
          x = recordedMouseScreenPosition.current[0];
          y = recordedMouseScreenPosition.current[1];
        } else {
          recordedMouseScreenPosition.current = [x, y];
        }

        const isInside = insideCanvasCheck();
        const mouseOnCanvasTheoPosition = system_operations.coordinator.boardPositioner.getTheoPositionFromScreenPosition(x, y);  // get current mouse coordinate on the canvas in theo space

        const auxiliaryLineTool = system_operations.coordinator.toolpack.auxiliaryLine;
        const magnetsDict = system_operations.coordinator.magnetsMaker.getMagnets_cache(
          system_operations.coordinator.getAllCurrentBasicPapers.bind(system_operations.coordinator),
          system_operations.coordinator.toolpack.paperSelections, auxiliaryLineTool.xAuxiliary, auxiliaryLineTool.yAuxiliary
        );
        if (system_operations.coordinator.toolpack.paperEditor.usingMagnet) { // should use magnet...
          const guideStyle = system_operations.coordinator.guideBox.guideBoxStyle;
          const xPoints = [mouseOnCanvasTheoPosition[0], mouseOnCanvasTheoPosition[0] + guideStyle.width / 2, mouseOnCanvasTheoPosition[0] + guideStyle.width];
          const yPoints = [mouseOnCanvasTheoPosition[1], mouseOnCanvasTheoPosition[1] + guideStyle.height / 2, mouseOnCanvasTheoPosition[1] + guideStyle.height];
          const fixMove = Magnetizer.rectangleMagnetMultiPointsMove(magnetsDict, xPoints, yPoints);
          if (fixMove.moved[0]) {
            mouseOnCanvasTheoPosition[0] += fixMove.move[0];
            system_operations.coordinator.setXPlacementGuideLine(fixMove.magnets[0]);
          } else {
            system_operations.coordinator.setXPlacementGuideLine(-1);
          }
          if (fixMove.moved[1]) {
            mouseOnCanvasTheoPosition[1] += fixMove.move[1];
            system_operations.coordinator.setYPlacementGuideLine(fixMove.magnets[1]);
          } else {
            system_operations.coordinator.setYPlacementGuideLine(-1);
          }
        }

        if (isInside) {
          system_operations.coordinator.guideBox.fixAndSetPosition(mouseOnCanvasTheoPosition[0], mouseOnCanvasTheoPosition[1]);
          const collisionCheck = system_operations.coordinator.guideBox.allowanceCheck();
          allow.current = collisionCheck.allow; // for the current position, check if the placement is allowed
          layer.current = collisionCheck.layer;
          setTheoreticalPosition(system_operations.coordinator.guideBox.getTheoPosition());
        } else {
          system_operations.coordinator.guideBox.setPosition(mouseOnCanvasTheoPosition[0], mouseOnCanvasTheoPosition[1]);           // calculate legitment in theo space.
          allow.current = false;
          layer.current = -1;
          setTheoreticalPosition(system_operations.coordinator.guideBox.getTheoPosition());                             // set the theo position. then the theo position will be converted to screen space when render.
        }
      }

      const trySubmit = async (event) => {
        const isInside = insideCanvasCheck();
        if (isInside) {
          if (active && allow.current) {
            system_operations.set_loading(true);
            await system_operations.coordinator.guideBox.submitChange({ layer: layer.current });
            setActive(false);
            system_operations.set_loading(false);
          }
        }
      }

      const mouseEvent = (event) => { recheck(event.clientX, event.clientY) };
      const touchEvent = (event) => { recheck(event.touches[0].clientX, event.touches[0].clientY) };
      window.addEventListener('mousemove', mouseEvent);
      window.addEventListener('touchmove', touchEvent);
      window.addEventListener('touchstart', touchEvent);
      window.addEventListener('mouseup', trySubmit);
      window.addEventListener('touchend', trySubmit);

      system_operations.addFunctionToDict(system_operations.coordinator.onGlobalOffsetChange, "fromGuideBox", recheck);
      return () => {
        window.removeEventListener('mousemove', mouseEvent);
        window.removeEventListener('touchmove', touchEvent);
        window.removeEventListener('touchstart', touchEvent);
        window.removeEventListener('mouseup', trySubmit);
        window.removeEventListener('touchend', trySubmit);

        delete system_operations.coordinator?.onGlobalOffsetChange["fromGuideBox"]
      }
    }
  }, [active, canvas])

  const guideStyle = system_operations.coordinator.guideBox.guideBoxStyle;

  let backgroundColor = allow.current ? theme.shadowBox.allowedColor[layer.current] : theme.shadowBox.disAllowedColor[0];

  return (
    <div>
      {active &&
        <div ref={guideBoxRef} style={{
          position: "absolute", backgroundColor: backgroundColor,
          width: guideStyle.width, height: guideStyle.height, borderRadius: guideStyle.borderRadius,
          left: theoreticalPosition[0], top: theoreticalPosition[1],
          zIndex: "251",
        }}>
        </div>
      }
    </div>
  )
}


export default LocalDrawingCanvas;