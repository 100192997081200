import React, { useState, useEffect, useContext, useRef } from 'react';
import './styles/ToolBar.scss';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { StickyDraggable } from "../cross_page_components/StickyDraggable.jsx";

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import { RequestDataMaker } from "../system/RestServerConnector.js"

import HttpsIcon from '@mui/icons-material/Https';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded';
import DoNotTouchRoundedIcon from '@mui/icons-material/DoNotTouchRounded';

const MIN_DOMAIN_LENGTH = 8, MAX_DOMAIN_LENGTH = 100;

// two vertical sections. first is usage setting. second is board setting. which will be blocked if not the owner.
/*
  in usage setting:
  user can change weather the board is locked (two buttons, lock and unlock.)
  weather enabling the handBlock mode. which ensure hand touch will not draw.
*/
/*
  in board setting:
  everything is blocked for now, all setting will be comming out in the future.
*/

const darkerTextColor = "#bfbfbf";
const lighterTextColor = "#eeeeee";

export const BoardSettingPanel = ({ }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [isAdmin, setIsAdmin] = useState(system_operations.coordinator.getUserRoleInWall().power >= 100);

  const [displayMode, setDisplayMode] = useState(system_operations.coordinator.boardSettings.displayMode);
  const [crossScrollOn, setCrossScrollOn] = useState(system_operations.coordinator.boardSettings.crossScrollOn);
  const [entrance, setEntrance] = useState(system_operations.coordinator.entrance);
  // const [allowHandsDraw, setAllowHandsDraw] = useState(system_operations.userSettings.allowHandsDraw);
  const [defaultDisplayRatio, setDefaultDisplayRatio] = useState(system_operations.coordinator.boardSettings.defaultDisplayRatio || system_operations.coordinator.boardSettings.boardOnScreenRatio);
  const [hideToolbarByDefault, setHideToolbarByDefault] = useState(Boolean(system_operations.coordinator.getBoardData().hideToolbarByDefault));
  const [inputDomain, setInputDomain] = useState(system_operations.coordinator.domains[0] || "");
  const [domainErrMessage, setDomainErrMessage] = useState("");

  const [refreshCounter, setRefreshCounter] = useState(-1);
  const toolbarControlPanelRef = useRef(null);

  const buttonUnselected = { height: 20, cursor: "pointer", color: "#ffffff", backgroundColor: "#909090", borderRadius: "5px", boxSizing: 'border-box', width: "49%" };
  const buttonSelected = { ...buttonUnselected, color: "#ffffff", backgroundColor: "#c0c0c0" };
  const fullSizeButton = { height: 20, cursor: "pointer", width: "100%", marginBottom: "5px" };
  const buttonIcon = { height: 18, width: 18 };

  useEffect(() => {
    system_operations.coordinator.boardSettings.onDisplayModeChanged["BSP"] = setDisplayMode;
    system_operations.coordinator.boardSettings.onCrossScrollOnChanged["BSP"] = setCrossScrollOn;
    // system_operations.userSettings.onAllowHandsDrawChanged["BSP"] = setAllowHandsDraw;

    return (() => {
      delete system_operations.coordinator.boardSettings.onDisplayModeChanged["BSP"];
      delete system_operations.coordinator.boardSettings.onCrossScrollOnChanged["BSP"];
      // delete system_operations.userSettings.onAllowHandsDrawChanged["BSP"];
    })
  }, []);

  const setDisplayModeClick = (mode) => {
    if (mode !== displayMode && mode === 0) {
      system_operations.coordinator.boardSettings.setBoardOnScreenRatio(system_operations.coordinator.boardSettings.initialBoardOnScreenRatio);
    }
    setDisplayMode(mode);
    system_operations.coordinator.boardSettings.settingDisplayMode(mode);
  }

  const setCrossScrollOnClick = (isOn) => {
    setCrossScrollOn(isOn);
    system_operations.coordinator.boardSettings.settingCrossScrolling(isOn);
  }

  const setBoardOnScreenRatioInput = (event) => {
    const newValue = event.target.value;
    system_operations.coordinator.boardSettings.setBoardOnScreenRatio(newValue);
  }

  const setCurrentPreferenceToDefault = () => {
    RequestDataMaker.getDataToUpdateboard(system_operations, system_operations.coordinator.getWallID(), { defaultFullScreenDisplay: displayMode === 2, allowCrossScrollingByDefault: crossScrollOn }).then((updateData) => {
      system_operations.restServerConnector.updateBoard(updateData);
    })
  }

  const setToolBarInOrOutByDefault = () => {
    if (system_operations.coordinator.getBoardData().hideToolbarByDefault) {
      system_operations.coordinator.getBoardData().hideToolbarByDefault = false;
    } else {
      system_operations.coordinator.getBoardData().hideToolbarByDefault = true;
    }
    setHideToolbarByDefault(system_operations.coordinator.getBoardData().hideToolbarByDefault);
    RequestDataMaker.getDataToUpdateboard(system_operations, system_operations.coordinator.getWallID(), { hideToolbarByDefault: system_operations.coordinator.getBoardData().hideToolbarByDefault }).then((updateData) => {
      system_operations.restServerConnector.updateBoard(updateData);
    })
  }

  const setCurrentScaleToDefaultScale = () => {
    setDefaultDisplayRatio(system_operations.coordinator.boardSettings.boardOnScreenRatio);
    RequestDataMaker.getDataToUpdateboard(system_operations, system_operations.coordinator.getWallID(), { defaultDisplayRatio: system_operations.coordinator.boardSettings.boardOnScreenRatio }).then((updateData) => {
      system_operations.restServerConnector.updateBoard(updateData);
    })
  }

  const onEntranceInputChange = (e) => {
    let coord = Number(e.target.value);
    coord = Math.min(Math.max(coord, 0), system_operations.coordinator.pixelBoundary[1])
    setEntrance(coord);
  }

  const saveEntrance = () => {
    // setEntrance(system_operations.coordinator.boardSettings.boardOnScreenRatio);
    system_operations.coordinator.getBoardData().entrance = entrance;
    system_operations.coordinator.entrance = entrance;
    RequestDataMaker.getDataToUpdateboard(system_operations, system_operations.coordinator.getWallID(), { entrance: entrance }).then((updateData) => {
      system_operations.restServerConnector.updateBoard(updateData);
    })
  }

  const saveBoardDomain = () => {
    const validUrlRegex = /^[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*$/;
    if (validUrlRegex.test(inputDomain) && inputDomain.length <= MAX_DOMAIN_LENGTH && inputDomain.length >= MIN_DOMAIN_LENGTH) {
      system_operations.coordinator.getBoardData().domains = [inputDomain]; // rewrite the domain
      system_operations.coordinator.domains = [inputDomain]; // rewrite the domain
      RequestDataMaker.getDataToUpdateboard(system_operations, system_operations.coordinator.getWallID(), { domain: inputDomain }).then((updateData) => {
        system_operations.restServerConnector.updateBoardDomain(updateData);
      })
      setRefreshCounter(refreshCounter + 1);
      setDomainErrMessage('');
    } else {
      setDomainErrMessage(`must in between ${MIN_DOMAIN_LENGTH} and ${MAX_DOMAIN_LENGTH} characters`);
    }
  }

  const inputDomainBlock = (e) => {
    const newValue = e.target.value;
    // Regular expression for valid URL characters
    const validUrlRegex = /^[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*$/;

    if (validUrlRegex.test(newValue) && newValue.length <= MAX_DOMAIN_LENGTH) {
      setInputDomain(newValue.toLowerCase());
      // setDomainErrMessage(''); // Clear error message if input is valid
    }
  }

  const barTextSize = 10;

  return (
    <div style={{ width: '100%', boxSizing: 'border-box', }}>
      <div className='inputs' style={{
        width: "100%", borderRadius: "5px", padding: "px", marginBottom: "3px",
        backgroundColor: `rgba(96, 96, 96, 0.6)`, boxSizing: 'border-box',
      }}>
        <div>
          <div style={{ color: darkerTextColor, fontSize: `${barTextSize}px` }}>-Preference-</div>
          <Divider sx={{ borderColor: darkerTextColor, marginBottom: "3px" }} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "5px" }}>
          <button className='inputs' variant="contained" color="primary" onClick={() => { setDisplayModeClick(0) }} style={{ ...(displayMode !== 0 ? buttonUnselected : buttonSelected), width: "33%" }}>
            Free
          </button>
          <button className='inputs' variant="contained" color="primary" onClick={() => { setDisplayModeClick(1) }} style={{ ...(displayMode !== 1 ? buttonUnselected : buttonSelected), width: "33%" }}>
            1:1
          </button>
          <button className='inputs' variant="contained" color="primary" onClick={() => { setDisplayModeClick(2) }} style={{ ...(displayMode !== 2 ? buttonUnselected : buttonSelected), width: "33%" }}>
            Full
          </button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "5px" }}>
          <button className='inputs' variant="contained" color="primary" onClick={() => { setCrossScrollOnClick(true) }} style={(!crossScrollOn ? buttonUnselected : buttonSelected)}>
            Unlock
          </button>
          <button className='inputs' variant="contained" color="primary" onClick={() => { setCrossScrollOnClick(false) }} style={(crossScrollOn ? buttonUnselected : buttonSelected)}>
            Lock
          </button>
        </div>
        {isAdmin &&
          <button className='inputs' variant="contained" color="primary" onClick={setCurrentPreferenceToDefault} style={fullSizeButton}>
            Set As Default
          </button>
        }
        {/* <div style={{ marginBottom: "5px" }}>
          <div style={{ color: lighterTextColor, fontSize: `${barTextSize}px` }}>Zoom</div>
          <input type="range" disabled={displayMode !== 0} style={{ width: "100%", zIndex: 1 }} min={0.1} max={5.0} step={0.01} defaultValue={system_operations.coordinator.boardOnScreenRatio} onChange={setBoardOnScreenRatioInput} />
        </div> */}
      </div>
      <div className='inputs' style={{
        position: "relative", width: "100%", borderRadius: "5px", padding: "2%",
        backgroundColor: `rgba(96, 96, 96, 0.6)`, userSelect: isAdmin ? "auto" : "none", boxSizing: 'border-box',
      }}>
        <div>
          <div style={{ color: darkerTextColor, fontSize: `${barTextSize}px` }}>-Board Setting-</div>
          <Divider sx={{ borderColor: darkerTextColor, marginBottom: "3px" }} />
        </div>

        <div style={{ color: lighterTextColor, fontSize: `${barTextSize}px` }}>Board Styling!</div>
        <BoardPartSetting />

        <div style={{ color: lighterTextColor, fontSize: `${barTextSize}px` }}>Custom Domain Block</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "5px" }}>
          <input value={inputDomain} placeholder='Enter a custom domain name...' style={{ width: "100%" }} onChange={inputDomainBlock} />
        </div>
        <button className='inputs' variant="contained" color="primary" style={fullSizeButton} onClick={saveBoardDomain} disabled={!inputDomain || system_operations.coordinator.domains[0] === inputDomain}>
          {"Save Domain"}
        </button>
        {
          domainErrMessage &&
          <div style={{ color: "red", fontSize: `${barTextSize}px` }}>{domainErrMessage}</div>
        }

        <div style={{ color: lighterTextColor, fontSize: `${barTextSize}px` }}>Entrance Coordinate</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "5px" }}>
          <input placeholder='The coord where users enter...' style={{ width: "100%" }} onChange={onEntranceInputChange} />
        </div>
        <button className='inputs' variant="contained" color="primary" style={fullSizeButton} onClick={saveEntrance} disabled={system_operations.coordinator.entrance === entrance}>
          {"Save Entrance"}
        </button>

        <div style={{ color: lighterTextColor, fontSize: `${barTextSize}px` }}>Hide Toolbar By Default</div>
        <button className='inputs' variant="contained" color="primary" style={fullSizeButton} onClick={setToolBarInOrOutByDefault}>
          {hideToolbarByDefault ? "Unhide" : "Hide"}
        </button>

        <div style={{ color: lighterTextColor, fontSize: `${barTextSize}px` }}>Set Default Display Ratio</div>
        <button className='inputs' variant="contained" color="primary" style={fullSizeButton} onClick={setCurrentScaleToDefaultScale}>
          Set_Ratio
        </button>

        {/*  <div style={{ color: lighterTextColor, fontSize: `${barTextSize}px` }}>Lock c-Scroll by Default</div>
        <button className='inputs' variant="contained" color="primary" style={fullSizeButton} onClick={setToolBarInOrOutByDefault}>
          {hideToolbarByDefault ? "Unhide" : "Hide"}
    </button> */}
        {!isAdmin &&
          <div style={{
            position: "absolute",
            top: 0, right: 0, bottom: 0, left: 0,
            backgroundColor: "rgba(160, 160, 160, 0.8)",
            borderRadius: "5px",
          }} />
        }
      </div>
    </div>
  );
};


const BoardPartSetting = () => {
  const { system_operations } = useContext(SystemOperationsContext);
  const buttonDarker = { height: 20, width: 105, backgroundColor: "#808080" };
  const buttonLighter = { height: 20, width: 105, backgroundColor: "#999999" };
  const fullSizeButton = { height: 20, width: "100%", backgroundColor: "#808080" };

  return (
    <div style={{ width: '100%', marginBottom: "5px", boxSizing: 'border-box', }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "1px" }}>
        <button className='inputs' variant="contained" color="primary" style={{ ...buttonDarker, width: "25%" }}>
          LT
        </button>
        <button className='inputs' variant="contained" color="primary" style={{ ...buttonLighter, width: "50%" }}>
          T
        </button>
        <button className='inputs' variant="contained" color="primary" style={{ ...buttonDarker, width: "25%" }}>
          RT
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "1px" }}>
        <button className='inputs' variant="contained" color="primary" style={{ ...buttonLighter, height: 32, width: "25%" }}>
          L
        </button>
        <button className='inputs' variant="contained" color="primary" style={{ ...buttonDarker, height: 32, width: "50%" }}>
          C
        </button>
        <button className='inputs' variant="contained" color="primary" style={{ ...buttonLighter, height: 32, width: "25%" }}>
          R
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "1px" }}>
        <button className='inputs' variant="contained" color="primary" style={{ ...buttonDarker, width: "25%" }}>
          LB
        </button>
        <button className='inputs' variant="contained" color="primary" style={{ ...buttonLighter, width: "50%" }}>
          B
        </button>
        <button className='inputs' variant="contained" color="primary" style={{ ...buttonDarker, width: "25%" }}>
          RB
        </button>
      </div>
      <button className='inputs' variant="contained" color="primary" style={{ ...fullSizeButton, width: '100%' }}>
        background
      </button>
    </div>
  )
}
