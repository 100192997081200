import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
// import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import GroupIcon from '@mui/icons-material/Group';
import MenuIcon from '@mui/icons-material/Menu';
import WallpaperIcon from '@mui/icons-material/Wallpaper';

import { Switch } from "@mui/material";

import { BoardSearcher } from './BoardSearch.jsx';

import React, { useState, useContext, useEffect, useRef } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { MyToggle } from "../cross_page_components/ToggleSwitch.jsx";
import { Link, useNavigate } from 'react-router-dom';


const defaultUser = {
  userID: "",
  email: "none",
  username: "sign in",
  avatar: "none",
  recentVisit: []
}


/*
login switch:
when signed out,
click to ask user to sign in. However, don't move the switch.

when logged in, first click, should trigger a confirmation. that last a little while.
if user clicked the switch again, log out the user.
*/
export default function Navbar({ height, sidebarHidden, setSidebarVisibility }) {
  const { system_operations } = useContext(SystemOperationsContext);
  const [userData, setUserData] = useState(system_operations.getUserData() ? system_operations.getUserData() : defaultUser);
  const OnUserChange = (uData) => {
    setUserData(uData ? uData : defaultUser)
  }
  system_operations.addToOnUserChange("fromNavbar", OnUserChange);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [numberOfNotifications, setNumberOfNotifications] = React.useState(45);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const renderValue = (selectedValue = "People") => {
    switch (selectedValue) {
      case 'People':
        return <><GroupIcon fontSize="small" /> People</>;
      case 'Wall':
        return <><WallpaperIcon fontSize="small" /> Wall</>;
      default:
        return "";
    }
  };

  const searchGeneral = async () => {
  }

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';

  return (
    <div>
      <AppBar position="fixed" sx={{ backgroundColor: theme.backgroundColor.homePageUIDarker5, width: '100%', height: height }}>
        <Toolbar>
          {sidebarHidden &&
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open sidebar"
              onClick={() => { setSidebarVisibility(true) }}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          }
          <Typography
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block', fontSize: "38px" } }}
          >
            DrawAll
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <BoardSearcher />
          <Divider orientation="vertical" flexItem sx={{ margin: 1 }} />
          {/*<IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <Typography variant="body1" color="white" noWrap sx={{ whiteSpace: 'nowrap', maxWidth: '120px', marginRight: "5px" }}>
              {userData.username}
            </Typography>
            <AccountCircle />
        </IconButton>*/}
          <LoginSwitch />
        </Toolbar>
      </AppBar>
      <div style={{ width: "100%", height: height }} />
    </div>
  );
}

const LoginSwitch = () => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [userData, setUserData] = useState(system_operations.getUserData() || defaultUser);
  const [isLoggedIn, setIsLoggedIn] = useState(userData !== defaultUser && userData?.loggedIn);
  const [isConfirmingLogout, setIsConfirmingLogout] = useState(false);

  const styles = useRef({
    handleSize: 34,
    trackHeight: 38,
    trackWidth: 110,
  })

  useEffect(() => {
    const updateUserData = (userData) => {
      if (userData && userData !== defaultUser && userData.loggedIn) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setUserData(userData);
    }
    system_operations.onUserChange["fromLoginSwitch"] = updateUserData;
    return () => {
      delete system_operations.onUserChange["fromLoginSwitch"]
    }
  }, [])

  const signOutUser = async () => {
    system_operations.set_loading(true);
    await system_operations.authSystem.signOut();
    system_operations.signOutUser();
    system_operations.set_loading(false);
  }

  const handleChange = () => {
    if (!isLoggedIn) {
      system_operations.pleaseLogIn("Finally! You are here!");
    } else {
      if (!isConfirmingLogout) {
        setIsConfirmingLogout(true);
        setTimeout(() => { // Automatically clear confirmation after a short period
          setIsConfirmingLogout(false)
        }, 3000);
      } else {
        signOutUser();
        setIsConfirmingLogout(false); // Reset the confirmation flag
      }
    }
  };

  // Reset isConfirmingLogout flag when login status changes
  useEffect(() => {
    setIsConfirmingLogout(false);
  }, [isLoggedIn]);

  let handleChildren = null;
  let trackChildren = null;
  let switchSX = {};
  let trackSX = {};
  let trackContainerStyle = {
    width: `${styles.current.trackWidth - styles.current.handleSize}px`, height: "100%", marginLeft: `${styles.current.handleSize}px`,
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  }
  if (!isLoggedIn) {
    switchSX.backgroundColor = "#707070"; trackSX.backgroundColor = "#999999";
    handleChildren = (<AccountCircleOutlinedIcon sx={{ width: "100%", height: "100%", color: "#aaaaaa" }} />);
    trackChildren = (
      <div style={trackContainerStyle}>
        <Typography sx={{ marginLeft: "-5px", fontSize: "24px", color: "#7A7A7A" }}> {"login"} </Typography>
      </div>
    );
  } else {
    trackContainerStyle.marginLeft = "0px";
    switchSX.backgroundColor = "#709070"; trackSX.backgroundColor = "#50b050";
    handleChildren = (<AccountCircleOutlinedIcon sx={{ width: "100%", height: "100%", color: "#f5f5f5" }} />);
    if (isConfirmingLogout) {
      trackChildren = (
        <div style={trackContainerStyle}>
          <Typography sx={{ marginLeft: "0px", fontSize: "17px", color: "#158015" }}> {"log out?"} </Typography>
        </div>
      );
    } else {
      trackChildren = (

        <div style={trackContainerStyle}>
          <Typography sx={{ marginLeft: "4px", fontSize: "17px", color: "#158015", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}> {userData.username} </Typography>
        </div>
      );
    }
  }

  return (
    <div>
      <MyToggle
        checked={isLoggedIn}
        handleChange={handleChange}
        style={{ cursor: 'pointer' }}
        trackWidth={styles.current.trackWidth}
        trackHeight={styles.current.trackHeight}
        switchSize={styles.current.handleSize}
        switchSX={switchSX}
        trackSX={trackSX}>
        {{
          handleChildren: handleChildren,
          trackChildren: trackChildren
        }}
      </MyToggle>
    </div>
  );
};
