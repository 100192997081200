import React, { useState, useContext, useRef, useEffect } from 'react';
import { SystemOperationsContext } from "../../context/SystemRunnerContext";

import { CanvasTextWrapper } from 'canvas-text-wrapper';
import { BoardSettingPanel } from '../../components/BoardSettingPanel.jsx';

import ButtonGroups from "../../cross_page_components/ButtonGroups.jsx";
import { useTheme } from '@mui/system';

import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import SaveIcon from '@mui/icons-material/Save';
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded';
import OpenWithRoundedIcon from '@mui/icons-material/OpenWithRounded';
import ZoomOutMapRoundedIcon from '@mui/icons-material/ZoomOutMapRounded';
import FilterCenterFocusRoundedIcon from '@mui/icons-material/FilterCenterFocusRounded';
import TransformRoundedIcon from '@mui/icons-material/TransformRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import Crop32Icon from '@mui/icons-material/Crop32';

import GridViewIcon from '@mui/icons-material/GridView';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SpellcheckRoundedIcon from '@mui/icons-material/SpellcheckRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { GridContainer, ToolItem } from '../../components/toolBar/UltimateControlPanel';
import { HandControlPanel } from './Hand.js';
import { BrushControlPanel, TextControlPanel } from './Brush.js';
import { PaperRectTransformControl } from './Paper.js';
import { AuxiliaryLineControlPanel } from './AuxiliaryLineControlPanel.js';


const guest_canvasMaxThickness = 8;
const user_MaxThickness = 150;

export class ToolsPack {
  constructor(system) {
    this.system = system;

    this.globalColor = "black";
    this.globalEntity = "addition";
    this.rectsSelectMode = false;
    this.multiSelection = false;
    this.selectionsBound = { x0: Infinity, x1: -Infinity, y0: Infinity, y1: -Infinity };
    this.drawSectionRef = {};

    this.set_tool_hook = null;
    this.onToolChange = {};
    this.on_color_change = {};
    this.onToolRadiusChange = {};
    this.onRectsSelectModeChange = {};
    this.onMultiSelectionChange = {};

    this.canvasSubmitActionCaller = null;
    this.refreshCounter = 0;
    this.refreshToolControlPanelHook = null;
    this.setCurrentPreemptiveToolHook = null;

    this.rectEditingMode = false;
    this.currentSelectedRect = undefined;
    this.paperSelections = {};            // the dict of selected papers.
    this.paperSelectionArray = [];
    this.rulesSelections = true;

    this.onPaperSelectionsChange = {};

    this.hand = new Hand(this);
    this.brush = new ToolPen(this);
    this.paperEditor = new PaperEditor(this);
    // this.ABEditor = new ABEditor(this);
    // this.PIEditor = new PIEditor(this);
    this.fence = new FenceMaker(this);
    this.text = new TextInput(this);
    this.auxiliaryLine = new AuxiliaryLine(this);

    this.all_tools = {
      "hand": this.hand,
      "brush": this.brush,
      "text": this.text,
      "auxiliaryLine": this.auxiliaryLine,
      "fc": this.paperEditor,
      // "pi": this.PIEditor,
      // "ab": this.ABEditor,
      "fence": this.fence,
    }
    this.current_tool = this.hand;
    this.latestCommonTool = this.hand;
  }

  refreshToolControlPanel() {
    if (this.refreshToolControlPanelHook) {
      this.refreshToolControlPanelHook(this.refreshCounter);
      this.refreshCounter += 1;
    }
  }

  get_current_tool() {
    return this.current_tool;
  }

  getLatestCommonTool() {
    return this.latestCommonTool;
  }

  getToolByName(name) {
    return this.all_tools[name];
  }

  setGlobalColor(color) {
    this.globalColor = color;
    for (const key in this.on_color_change) {
      // current tool might have its own way of calculate color. So just set the global color, but get color using the current tool
      this.on_color_change[key](this.current_tool.getToolColor());
    }
  }

  setSubtractiveMode(sub) {
    if (sub) {
      this.globalEntity = "subtraction";
    } else {
      this.globalEntity = "addition";
    }
  }

  radiusChanged(radius) {
    for (const key in this.onToolRadiusChange) {
      this.onToolRadiusChange[key](radius);
    }
  }

  getGlobalColor() {
    return this.globalColor;
  }

  checkHasPowerOverAPaper(paper) {
    const paperData = paper.getData();
    if (paperData.creatorID === this.system.getUserID() || this.system.coordinator.getUserRoleInWall().power > paperData.protectLevel) {
      return true;
    }
    return false;
  }

  setPaperSelection(newPaperSelections = {}) {
    // this.selectionsBound = { x0: Infinity, x1: -Infinity, y0: Infinity, y1: -Infinity };
    this.enableSelectedPapers(); // for whatever is selected, enable them.
    this.paperSelections = {};
    this.paperSelectionArray = [];
    let ownAll = true;
    for (const p of Object.values(newPaperSelections)) {
      // if (this.calculateAdditionalPaperBound(p) && !(p.getID() in this.paperSelections)) {
      if (!(p.getID() in this.paperSelections)) {
        // in bound.
        this.paperSelections[p.getID()] = p;
        this.paperSelectionArray.push(p);
        if (!this.checkHasPowerOverAPaper(p)) {
          ownAll = false;
        }
      }
    }
    this.rulesSelections = ownAll;
    this.currentSelectedRect = this.paperSelectionArray[0]; // can be undefined
    for (const key in this.onPaperSelectionsChange) { this.onPaperSelectionsChange[key](this.paperSelections, this.paperSelectionArray, this.rulesSelections); }
  }
  addToPaperSelection_collisionArray(collisionArray) {
    let changed = false;
    for (const collisionRect of collisionArray) {
      const paper = collisionRect.rect.dataRef;
      if (!(paper.getID() in this.paperSelections)) { // only add if not in already.
        // if (this.calculateAdditionalPaperBound(paper)) {
          this.paperSelections[paper.getID()] = paper;
          this.paperSelectionArray.push(paper);
          changed = true; // has changes.
          if (this.rulesSelections && !this.checkHasPowerOverAPaper(paper)) {
            this.rulesSelections = false;
          }
        // }
      }
    }
    this.currentSelectedRect = this.paperSelectionArray[0]; // can be undefined
    if (changed) {
      for (const key in this.onPaperSelectionsChange) { this.onPaperSelectionsChange[key](this.paperSelections, this.paperSelectionArray, this.rulesSelections); }
    }
  }
  addToPaperSelection_array(paperArray) {
    let changed = false;
    for (const paper of paperArray) {
      if (!(paper.getID() in this.paperSelections)) { // only add if not in already.
        // if (this.calculateAdditionalPaperBound(paper)) {
          this.paperSelections[paper.getID()] = paper;
          this.paperSelectionArray.push(paper);
          changed = true; // has changes.
          if (this.rulesSelections && !this.checkHasPowerOverAPaper(paper)) {
            this.rulesSelections = false;
          }
        // }
      }
    }
    this.currentSelectedRect = this.paperSelectionArray[0]; // can be undefined
    if (changed) {
      for (const key in this.onPaperSelectionsChange) { this.onPaperSelectionsChange[key](this.paperSelections, this.paperSelectionArray, this.rulesSelections); }
    }
  }
  addToPaperSelection(paper) {
    // if (!(paper.getID() in this.paperSelections) && this.calculateAdditionalPaperBound(paper)) { // only add if not in already.
    if (!(paper.getID() in this.paperSelections)) { // only add if not in already.
      this.paperSelections[paper.getID()] = paper;
      this.paperSelectionArray.push(paper);
      if (this.rulesSelections && !this.checkHasPowerOverAPaper(paper)) {
        this.rulesSelections = false;
      }
      this.currentSelectedRect = this.paperSelectionArray[0]; // can be undefined
      for (const key in this.onPaperSelectionsChange) { this.onPaperSelectionsChange[key](this.paperSelections, this.paperSelectionArray, this.rulesSelections); }
    }
  }
  removeFromPaperSelection(paper) {
    if (paper.getID() in this.paperSelections) {
      // this.selectionsBound = { x0: Infinity, x1: -Infinity, y0: Infinity, y1: -Infinity };
      paper.setDisabled(false);
      delete this.paperSelections[paper.getID()];
      this.paperSelectionArray = Object.values(this.paperSelections); // removal needs go through...
      let ownAll = true;
      for (const p of this.paperSelectionArray) {
        if (this.checkHasPowerOverAPaper(p)) {
          ownAll = false;
        }
        // this.calculateAdditionalPaperBound(p); // re run the bound method and reform the bound...
      }
      this.rulesSelections = ownAll;
      this.currentSelectedRect = this.paperSelectionArray[0]; // can be undefined
      for (const key in this.onPaperSelectionsChange) { this.onPaperSelectionsChange[key](this.paperSelections, this.paperSelectionArray, this.rulesSelections); }
    }
  }

  enableSelectedPapers() {
    for (const p of this.paperSelectionArray) {
      if (p) {
        p.setDisabled(false);
      }
    }
  }

  disableSelectedPapers() {
    for (const p of this.paperSelectionArray) {
      if (p) {
        p.setDisabled(true);
      }
    }
  }

  getSelectionBound() {
    const selectionsBound = { x0: Infinity, x1: -Infinity, y0: Infinity, y1: -Infinity };
    for (const p of this.paperSelectionArray) {
      this.calculateAdditionalPaperBound(p, selectionsBound); // re run the bound method and reform the bound...
    }
    return selectionsBound;
  }

  calculateAdditionalPaperBound(paper, selectionsBound) {
    const startX = paper.getActiveX();
    const startY = paper.getActiveY();
    const endX = startX + paper.getActiveWidth();
    const endY = startY + paper.getActiveHeight();

    /* if (Math.max(endX, selectionsBound.x1) - Math.min(startX, selectionsBound.x0) > this.maxPaperX ||
      Math.max(endY, selectionsBound.y1) - Math.min(startY, selectionsBound.y0) > this.maxPaperY) {
      return false; // out the bound...
    } */

    if (startX < selectionsBound.x0) {
      selectionsBound.x0 = startX;
    }
    if (endX > selectionsBound.x1) {
      selectionsBound.x1 = endX;
    }
    if (startY < selectionsBound.y0) {
      selectionsBound.y0 = startY;
    }
    if (endY > selectionsBound.y1) {
      selectionsBound.y1 = endY;
    }
    // return true;
  }

  setRectsSelectMode(setOn) {
    this.rectsSelectMode = setOn;
    for (const key in this.onRectsSelectModeChange) { this.onRectsSelectModeChange[key](this.rectsSelectMode); }
  }

  setMultiSelectionOnOrOff(setOn) {
    if (this.multiSelection && !setOn && this.paperSelectionArray.length > 1) {
      this.setPaperSelection({[this.paperSelectionArray[0].getID()]: this.paperSelectionArray[0]});
    }
    this.multiSelection = setOn;
    for (const key in this.onMultiSelectionChange) { this.onMultiSelectionChange[key](this.onMultiSelectionChange); }
  }

  /* setCurrentSelectedRect(rect) {
    this.currentSelectedRect = rect;
    for (const key in this.onCurrentSelectedRectChange) { this.onCurrentSelectedRectChange[key](this.currentSelectedRect); }
  } */

  addToOnToolRadiusChange(key, func) {
    this.onToolRadiusChange[key] = func;
  }

  assign_set_tool_hooks(set_tool) {
    this.set_tool_hook = set_tool;
  }

  add_to_on_color_change(key, func) {
    this.on_color_change[key] = func;
  }

  remove_from_on_color_change(key) {
    delete this.on_color_change[key];
  }

  addToolChangeCondition() {

  }

  changeToolByName(tool_name = "hand") {
    if (this.private_changeTool(this.all_tools[tool_name])) {
      if (this.setCurrentPreemptiveToolHook) { this.setCurrentPreemptiveToolHook(this.current_tool.name); } // this is called from tool bar, so in this case no need to call this. but this is not hurmful to call
    }
  }

  changeToPreviousCommonTool() {
    if (this.private_changeTool(this.latestCommonTool)) {
      if (this.setCurrentPreemptiveToolHook) { this.setCurrentPreemptiveToolHook(this.current_tool.name); }
    }
  }

  private_changeTool(tool) {
    let canChange = true;
    for (const key in this.current_tool.conditionsForToolChange) {
      canChange = this.current_tool.conditionsForToolChange[key](tool);
    }
    if (!tool.switchToConditionCheck()) {
      this.changeToPreviousCommonTool();
      canChange = false;
    }

    if (canChange) {
      this.current_tool.onSwitchOffEvent();
      if (!tool) {
        tool = this.hand;
      }
      this.current_tool = tool;
      this.current_tool.onSwitchToEvent();
      if (tool.isCommonTool) {
        this.latestCommonTool = tool;
      }

      if (this.set_tool_hook) { this.set_tool_hook(this.current_tool); }
      for (const key in this.onToolChange) {
        this.onToolChange[key](this.current_tool);
      }
      return true;
    }
    return false;
  }

  undo() {
    // console.log("undo", this.system.coordinator);
    this.system?.coordinator?.kDraw?.kApi?.undo();
  }

  redo() {
    // console.log("redo", this.system.coordinator);
    this.system?.coordinator?.kDraw?.kApi?.redo();
  }

  saveDrawing() {
    this.system?.coordinator?.kDraw?.saveAllChanges();
    this.system?.coordinator?.kDraw?.reset();
  }

  // when submit or revert all changes, this is called. this just clean up the history, but doesn't change anything to the current canvas state.
  resetDrawing() {
    this.system?.coordinator?.kDraw?.restoreOriginalState();
    this.system?.coordinator?.kDraw?.reset();
  }
}

class SelectableTool {
  constructor(name, toolPack) {
    this.toolPack = toolPack;

    this.allowKDrawBehaviors = false;
    this.isCommonTool = true; // Draw Tools. For example Brush. Will be used for switch back.
    this.normalHandBehavior = false;      // allow this tool to be used as hand or it's the hand.
    this.strictHandBehavior = false;      // if event happen on board, then we can drag it.
    this.name = name;
    this.cursor = "default";
    this.additionalCursor = "";

    this.overrideColor = "";
    this.conditionsForToolChange = {};

    this.current_global_left_boarder = 0;
    this.current_global_right_boarder = 0;
    this.minDrawDistance = 0.8;
    // this.coordinateOffset = 0;

    this.current_local_left_boarder = 0;
    this.current_local_right_boarder = 0;
    this.normalProcessAsIndieTool = true;
    this.submitActionAsIndieTool = false;
    this.requireLogin = false;
  }

  setOverrideColor(color = "") {
    this.overrideColor = color;
  }
  getToolColor() {
    if (this.overrideColor) {
      return this.overrideColor;
    }
    if (this.toolPack) {
      return this.toolPack.getGlobalColor();
    }
    return '#000000'
  }
  onSwitchToEvent() { }
  onSwitchOffEvent() { }
  switchToConditionCheck(data = null) {
    if (this.requireLogin && !this.toolPack.system.loggedIn) {
      this.toolPack.system.pleaseLogIn("Please login to use this tool T.T");
      return false;
    }
    return true;
  }

  renderToolControlPanel() { return (null); }
}

class Hand extends SelectableTool {
  constructor(toolPack) {
    super("hand", toolPack);
    this.name = "hand";

    this.allowKDrawBehaviors = false;
    this.isCommonTool = true;
    this.normalHandBehavior = true;
    this.handMode = 0;        // 0 has most of freedom, can do anything, 1 will block the media play.
    this.cursor = "grab";

    this.normalProcessAsIndieTool = false;
    this.onHandModeChange = {};
  }

  changeHandMode(newMode) {
    this.handMode = newMode;
    for (const key in this.onHandModeChange) { this.onHandModeChange[key](this.handMode); }
  }

  onSwitchOffEvent() {
    /* this.currentSelectedRect = null;
    for (const key in this.onCurrentSelectedRectChange) { this.onCurrentSelectedRectChange[key](this.currentSelectedRect); } */
  }

  renderToolControlPanel(isVertical = true) {
    return (
      // <DrawToolCommon tool={this} toolPack={this.toolPack} key="pen" />
      <HandControlPanel toolpack={this.toolPack} isVertical={isVertical} />
    )
  }
}

function hexToRgb(hex) {
  // Remove the hash at the start if it's there
  hex = hex.charAt(0) === '#' ? hex.slice(1) : hex;

  // Parse the r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return { r, g, b };
}

class ToolPen extends SelectableTool {
  constructor(toolPack) {
    super("brush", toolPack);
    this.name = "brush";
    this.allowKDrawBehaviors = true;
    this.cursor = "crosshair";
    this.additionalCursor = "circle";

    this.type = "penBrush";
    this.tip = 0;
    this.defaultSize = 5; // default size...
    this.opacity = 1;
    this.defaultIRGB = { r: 0, g: 0, b: 0 }; // might not be the color of the current brush, but its the default color if a brush' color hasn't been set.
    this.typeData = {}; // the data of each brush type. like opacity, like color, like everything else.

    this.oldSize = 5;
    this.onSizeChange = {};
    this.onTypeChange = {};
    this.onTipChange = {};
  }

  onSwitchToEvent() {
    // this.toolPack.system?.coordinator?.kDraw?.kApi?.setCurrentBrush(this.type);
    // console.log(this.type, " tool switching, this is the type.");
    this.setType(this.type);
  }

  checkAndCreateTypeConfiguation(type) {
    if (!(type in this.typeData)) {
      this.typeData[type] = {
        color: this.defaultIRGB,
        size: this.defaultSize,
      }
    }
  }

  setType(type) {
    this.type = type;
    this.checkAndCreateTypeConfiguation(type);
    // console.log("the current color we are talking about: ", this.defaultIRGB);
    this.toolPack.system?.coordinator?.kDraw?.kApi?.setCurrentBrush(type);
    this.toolPack.system?.coordinator?.kDraw?.kApi?.setColor(this.typeData[type].color);
    this.toolPack.system?.coordinator?.kDraw?.kApi?.setSize(this.typeData[type].size); // set the brush size.
    for (const key in this.onTypeChange) { this.onTypeChange[key](this.type); }
  }

  setSize(newSize) {
    this.checkAndCreateTypeConfiguation(this.type);
    this.defaultSize = newSize;
    this.typeData[this.type].size = newSize;
    // if (this.oldSize !== this.defaultSize) {
    //  this.oldSize = this.defaultSize;
      for (const key in this.onSizeChange) { this.onSizeChange[key](newSize); }
    //}
    this.toolPack.system?.coordinator?.kDraw?.kApi?.setSize(newSize);
  }

  setColor(hexColor) {
    this.checkAndCreateTypeConfiguation(this.type);
    this.toolPack.globalColor = hexColor;
    this.defaultIRGB = hexToRgb(hexColor);
    this.typeData[this.type].color = this.defaultIRGB;
    this.toolPack.system?.coordinator?.kDraw?.kApi?.setColor(this.defaultIRGB);
  }

  setTip(newTip) {
    this.tip = newTip;
    this.toolPack.system?.coordinator?.kDraw?.kApi?.setTip(newTip);
    for (const key in this.onTipChange) { this.onTipChange[key](this.defaultSize); }
  }

  setOpacity(newOpacity) {
    this.opacity = newOpacity;
    this.toolPack.system?.coordinator?.kDraw?.kApi?.setOpacity(newOpacity);
  }

  getSize() { return this.toolPack.system?.coordinator?.kDraw?.kApi?.getSize(); }
  getTip() { return this.toolPack.system?.coordinator?.kDraw?.kApi?.getTip(); }
  getOpacity() { return this.toolPack.system?.coordinator?.kDraw?.kApi?.getOpacity(); }

  // fieldName, setFieldFunctionName are sting
  // fieldValue is what ever...
  setField(fieldName, setFieldFunctionName, fieldValue) {
    this[fieldName] = fieldValue;
    this.toolPack.system?.coordinator?.kDraw?.kApi?.[setFieldFunctionName](fieldValue);
  }

  renderToolControlPanel(isVertical = true) {
    return (
      // <DrawToolCommon tool={this} toolPack={this.toolPack} key="pen" />
      <BrushControlPanel toolpack={this.toolPack} isVertical={isVertical} />
    )
  }
}

class TextInput extends SelectableTool {
  constructor(toolPack) {
    super("text", toolPack);
    this.name = "text";
    this.allowKDrawBehaviors = true;
    this.setFontSizeHook = null;
    this.cursor = "text";
    this.additionalCursor = "text";
  }

  onSwitchToEvent() {
    this.toolPack.system?.coordinator?.kDraw?.kApi?.setCurrentBrush("text");
  }

  renderToolControlPanel(isVertical = true) {
    return (
      <div>
        <TextControlPanel toolpack={this.toolPack} isVertical={isVertical} />
      </div>
    )
  }
}

class FenceMaker extends SelectableTool {
  constructor(toolPack) {
    super("fenceMaker", toolPack);
    this.name = "fence";
    this.allowKDrawBehaviors = false;
    this.isCommonTool = false;
    this.normalHandBehavior = false;
    this.strictHandBehavior = true;
    this.cursor = "grab";

    this.fenceSetEditingHook = null;
    this.submitFenceHook = null;
    this.controlPanelSetIsEditingHook = null;

    this.setFenceCreationHook = () => { };

    this.requireLogin = true;
  }

  onSwitchToEvent() {
    this.setFenceCreationHook(true);
  }

  onSwitchOffEvent() {
    this.setFenceCreationHook(false);
  }

  fenceSetEditing(editing) {
    if (this.fenceSetEditingHook) {
      this.fenceSetEditingHook(editing);
    }
    if (this.controlPanelSetIsEditingHook) {
      this.controlPanelSetIsEditingHook(editing)
    }
  }

  submitFence() {
    if (this.submitFenceHook) {
      this.submitFenceHook();
    }
  }

  renderToolControlPanel(isVertical = true) {
    return (
      <FenceDefiner theTool={this} submit={this.submitFence.bind(this)} cancel={() => { this.fenceSetEditing(false) }} />
    )
  }
}

const FenceDefiner = ({ theTool, submit, cancel }) => {
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    theTool.controlPanelSetIsEditingHook = setIsEditing;
  }, [])

  return (
    <div>
      {isEditing ?
        (<div>
          <StandardButton onClick={cancel} disabled={false} text={"Nuh uh.."} iconComponent={null} />
          <StandardButton onClick={submit} disabled={false} text={"Claim!"} iconComponent={null} />
        </div>)
        :
        (<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          placing fence...
        </div>)
      }
    </div>
  )
}

class PIEditor extends SelectableTool {
  constructor(toolPack) {
    super("pi", toolPack);
    this.name = "pi";
    this.isType1PaperEditor = true; // can edit both paper and fcanvas

    this.allowKDrawBehaviors = false;
    this.isCommonTool = false;
    // this.normalHandBehavior = true;
    this.cursor = "grab";

    this.startEditingHook = null;
    this.startCreationHook = null;
    this.setModeHook = null;
    this.actionMode = 0;

    this.requireLogin = true;
  }

  setMode(mode) {
    this.actionMode = mode;
    if (mode === 0) {
      if (this.startEditingHook) {
        this.startEditingHook();
      }
    } else { // pop up the creation guidebox
      if (this.startCreationHook) {
        this.startCreationHook();
      }
    }
    if (this.setModeHook) {
      this.setModeHook(mode);
      this.toolPack.refreshToolControlPanel();
    }
  }

  onSwitchToEvent(data = null) {
    this.toolPack.rectEditingMode = true;
  }

  onSwitchOffEvent(data = null) {
    this.toolPack.rectEditingMode = false;
  }

  renderToolControlPanel(isVertical = true) {
    return (
      <div>
        <PaperRectTransformControl layerSet={0} toolpack={this.toolPack} paperTool={this} isVertical={isVertical} />
      </div>
    )
  }
}

class PaperEditor extends SelectableTool {
  constructor(toolPack) {
    super("fc", toolPack);
    this.name = "fc";
    this.isType1PaperEditor = true; // can edit both paper and fcanvas

    this.allowKDrawBehaviors = false;
    this.isCommonTool = false;

    this.strictHandBehavior = true;
    toolPack.onRectsSelectModeChange["_PE"] = (rectsSelectMode) => { this.strictHandBehavior = !rectsSelectMode; }

    this.cursor = "grab";

    this.startEditingHook = null;
    this.startCreationHook = null;
    this.setModeHook = null;
    this.actionMode = 0;

    this.usingMagnet = true;

    this.onUsingMagnetChange = {};
    this.requireLogin = true;
  }

  setMode(mode) {
    this.actionMode = mode;
    if (mode === 0) {
      if (this.startEditingHook) {
        this.startEditingHook();
      }
    } else { // pop up the creation guidebox
      if (this.startCreationHook) {
        this.startCreationHook();
      }
    }
    if (this.setModeHook) {
      this.setModeHook(mode);
      this.toolPack.refreshToolControlPanel();
    }
  }

  setMagnetOnOrOff(setOn) {
    this.usingMagnet = setOn;
    for (const key in this.onUsingMagnetChange) {
      this.onUsingMagnetChange[key](this.onUsingMagnetChange);
    }
  }

  onSwitchToEvent(data = null) {
    this.toolPack.rectEditingMode = true;
  }

  onSwitchOffEvent(data = null) {
    this.toolPack.rectEditingMode = false;
  }

  renderToolControlPanel(isVertical = true) {
    return (
      <div>
        <PaperRectTransformControl layerSet={0} toolpack={this.toolPack} paperTool={this} isVertical={isVertical} />
      </div>
    )
  }
}

// action button editor
class AuxiliaryLine extends SelectableTool {
  constructor(toolPack) {
    super("auxiliaryLine", toolPack);
    this.name = "auxiliaryLine";

    this.allowKDrawBehaviors = false;
    this.isCommonTool = false;
    // this.normalHandBehavior = true;
    this.cursor = "grab";

    this.xAuxiliary = [];
    this.yAuxiliary = [];

    this.onXAuxiliarylineChange = {};
    this.onYAuxiliarylineChange = {};
  }

  sortArray(array) {
    array.sort((a, b) => { return a - b; });
  }

  removeXAuxiliary(index) {
    this.xAuxiliary.splice(index, 1);
    for (const key in this.onXAuxiliarylineChange) { this.onXAuxiliarylineChange[key](this.xAuxiliary) }
  }

  removeYAuxiliary(index) {
    this.yAuxiliary.splice(index, 1);
    for (const key in this.onYAuxiliarylineChange) { this.onYAuxiliarylineChange[key](this.yAuxiliary) }
  }

  updateXAuxiliary(index, newValue) {
    this.xAuxiliary[index] = newValue;
    this.xAuxiliary = [...new Set(this.xAuxiliary)];
    this.sortArray(this.xAuxiliary);
    for (const key in this.onXAuxiliarylineChange) { this.onXAuxiliarylineChange[key](this.xAuxiliary) }
  }

  updateYAuxiliary(index, newValue) {
    this.yAuxiliary[index] = newValue;
    this.yAuxiliary = [...new Set(this.yAuxiliary)];
    this.sortArray(this.yAuxiliary);
    for (const key in this.onYAuxiliarylineChange) { this.onYAuxiliarylineChange[key](this.yAuxiliary) }
  }

  addXAuxiliary(at) {
    if (!this.xAuxiliary.includes(at)) {
      this.xAuxiliary.push(at);
      this.sortArray(this.xAuxiliary);
      for (const key in this.onXAuxiliarylineChange) { this.onXAuxiliarylineChange[key](this.xAuxiliary) }
    }
  }

  addYAuxiliary(at) {
    if (!this.yAuxiliary.includes(at)) {
      this.yAuxiliary.push(at);
      this.sortArray(this.yAuxiliary);
      for (const key in this.onYAuxiliarylineChange) { this.onYAuxiliarylineChange[key](this.yAuxiliary) }
    }
  }

  renderToolControlPanel(isVertical = true) {
    return (
      <div>
        <AuxiliaryLineControlPanel toolpack={this.toolPack} theToolObject={this} isVertical={isVertical} />
      </div>
    )
  }
}

export const DragFreezingRangeBar = ({ min, max, allowedMax, defaultValue, onChange }) => {
  useEffect(() => {
    const mouseUp = () => { }
    window.addEventListener('mouseup', mouseUp);
    window.addEventListener('touchend', mouseUp);

    return () => {
      window.removeEventListener('mouseup', mouseUp);
      window.removeEventListener('touchend', mouseUp);
    };
  }, []);

  return (
    <div className='inputs' style={{ position: 'relative', width: '100%' }}>
      <input type="range" style={{ width: "100%", zIndex: 1 }} min={min} max={max} defaultValue={defaultValue} onChange={onChange} />
      {allowedMax !== max && <div style={{ position: "absolute", top: 6, left: `${(allowedMax / max) * 100}%`, height: 9, width: 2, backgroundColor: "#f04040", zIndex: 2 }} />}
    </div>
  )
}

const PaperControlPanel = ({ paperTool, buttonType }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [mode, setMode] = useState(paperTool.actionMode); // 0: edit, 1: create new
  const [hasChanges, setHasChanges] = useState(!system_operations.coordinator.allPendingPaperChangesEmpty);
  const tryToSwitchToTool = useRef("hand");

  const theme = useTheme();

  useEffect(() => {
    const checkForChange = (noChanges) => {
      if (noChanges) {
        setHasChanges(false);
      } else {
        setHasChanges(true);
      }
    }
    paperTool.setModeHook = setMode;
    paperTool.toolPack.system.addFunctionToDict(paperTool.toolPack.system.coordinator.onPendingPapersEmptyChange, "fromPaperToolCP", checkForChange);

    return (() => {
      paperTool.toolPack.system.removeFunctionFromDict(paperTool.toolPack.system.coordinator.onPendingPapersEmptyChange, "fromPaperToolCP");
    })
  }, [])

  useEffect(() => {
    const buttons = {
      save: {
        name: 'Yes',
        color: "white",
        backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
        checkDisable: () => { return false },
        onClick: async () => {
          await submitChanges();
          paperTool.toolPack.system.setContentWindow(null)
          delete paperTool.conditionsForToolChange["canChangeIfThereAreChanges"];
          paperTool.toolPack.changeToolByName(tryToSwitchToTool.current); // switch again
        },
      },
      dump: {
        name: "No",
        color: "white",
        backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
        checkDisable: () => { return false },
        onClick: async () => {
          await cancelChange();
          paperTool.toolPack.system.setContentWindow(null)
          delete paperTool.conditionsForToolChange["canChangeIfThereAreChanges"];
          paperTool.toolPack.changeToolByName(tryToSwitchToTool.current); // switch again
        },
      }
    }

    paperTool.conditionsForToolChange["canChangeIfThereAreChanges"] = (tool) => {
      if (tool.name !== paperTool.name && hasChanges) {
        tryToSwitchToTool.current = tool.name;
        // pop up the warning
        paperTool.toolPack.system.setContentWindow((
          <div className='float-container' style={{ borderRadius: 8, padding: 5, backgroundColor: "rgba(64, 64, 64, 0.85)" }}>
            Save All Changes?
            <ButtonGroups buttons={buttons} />
          </div>
        ));
        return false;
      } else {
        return true;
      }
    }
  }, [hasChanges]);

  const changeMode = (nextMode) => {
    setMode(nextMode);
    paperTool.setMode(nextMode);
    paperTool.toolPack.refreshToolControlPanel();
  }

  const submitChanges = async () => {
    paperTool.toolPack.system.set_loading(true);
    await paperTool.toolPack.system.coordinator.saveAllPendingPapersChanges();
    paperTool.toolPack.refreshToolControlPanel();
    paperTool.toolPack.system.set_loading(false);
  }

  const cancelChange = async () => {
    paperTool.toolPack.system.set_loading(true);
    paperTool.toolPack.system.coordinator.discardAllPendingPapersChanges();
    paperTool.toolPack.system.set_loading(false);
  }

  let mainButton = null;
  if (buttonType === "canvas") {
    mainButton = (
      <ButtonForCanvas mode={mode} onClick={mode === 0 ? () => { changeMode(1) } : () => { changeMode(0) }} />
    )
  } else {
    mainButton = (
      <ButtonForPost mode={mode} onClick={mode === 0 ? () => { changeMode(1) } : () => { changeMode(0) }} />
    )
  }

  return (
    <div>
      {mainButton}
      <StandardButton onClick={submitChanges} disabled={!hasChanges} text={"Save All"} iconComponent={<SaveIcon />} />
    </div>
  )
}

const ButtonControlPanel = ({ paperTool }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [mode, setMode] = useState(paperTool.actionMode); // 0: edit, 1: create new
  const [hasChanges, setHasChanges] = useState(!system_operations.coordinator.allPendingPaperChangesEmpty);
  const tryToSwitchToTool = useRef("hand");

  const theme = useTheme();

  useEffect(() => {
    const checkForChange = (noChanges) => {
      if (noChanges) {
        setHasChanges(false);
      } else {
        setHasChanges(true);
      }
    }
    paperTool.setModeHook = setMode;
    paperTool.toolPack.system.addFunctionToDict(paperTool.toolPack.system.coordinator.onPendingButtonsEmptyChange, "fromPaperToolCP", checkForChange);

    return (() => {
      paperTool.toolPack.system.removeFunctionFromDict(paperTool.toolPack.system.coordinator.onPendingButtonsEmptyChange, "fromPaperToolCP");
    })
  }, [])

  useEffect(() => {
    const buttons = {
      save: {
        name: 'Yes',
        color: "white",
        backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
        checkDisable: () => { return false },
        onClick: async () => {
          await submitChanges();
          paperTool.toolPack.system.setContentWindow(null)
          delete paperTool.conditionsForToolChange["canChangeIfThereAreChanges"];
          paperTool.toolPack.changeToolByName(tryToSwitchToTool.current); // switch again
        },
      },
      dump: {
        name: "No",
        color: "white",
        backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
        checkDisable: () => { return false },
        onClick: async () => {
          await cancelChange();
          paperTool.toolPack.system.setContentWindow(null)
          delete paperTool.conditionsForToolChange["canChangeIfThereAreChanges"];
          paperTool.toolPack.changeToolByName(tryToSwitchToTool.current); // switch again
        },
      }
    }

    paperTool.conditionsForToolChange["canChangeIfThereAreChanges"] = (tool) => {
      if (tool.name !== paperTool.name && hasChanges) {
        tryToSwitchToTool.current = tool.name;
        // pop up the warning
        paperTool.toolPack.system.setContentWindow((
          <div className='float-container' style={{ borderRadius: 8, padding: 5, backgroundColor: "rgba(64, 64, 64, 0.85)" }}>
            Save All Changes?
            <ButtonGroups buttons={buttons} />
          </div>
        ));
        return false;
      } else {
        return true;
      }
    }
  }, [hasChanges]);

  const changeMode = (nextMode) => {
    setMode(nextMode);
    paperTool.setMode(nextMode);
    paperTool.toolPack.refreshToolControlPanel();
  }

  const submitChanges = async () => {
    paperTool.toolPack.system.set_loading(true);
    await paperTool.toolPack.system.coordinator.saveAllPendingActionButtonChanges();
    paperTool.toolPack.refreshToolControlPanel();
    paperTool.toolPack.system.set_loading(false);
  }

  const cancelChange = async () => {
    paperTool.toolPack.system.set_loading(true);
    paperTool.toolPack.system.coordinator.discardAllPendingButtonChanges();
    paperTool.toolPack.system.set_loading(false);
  }

  const mainButton = (
    <ButtonForAB mode={mode} onClick={mode === 0 ? () => { changeMode(1) } : () => { changeMode(0) }} />
  )

  return (
    <div>
      {mainButton}
      <StandardButton onClick={submitChanges} disabled={!hasChanges} text={"Save All"} iconComponent={<SaveIcon />} />
    </div>
  )
}

const ButtonForAB = ({ mode, onClick }) => {
  const buttonText = mode === 0 ? 'Create New' : 'Edit Existing';

  return (
    <Box
      className='inputs'
      sx={{
        width: '100%', height: mode === 0 ? '35px' : '25px',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        transition: 'all 0.3s ease-in-out',
      }}
      onClick={onClick}
    >
      <Button
        variant="contained"
        startIcon={mode === 0 ? (<LibraryAddIcon />) : (<TransformRoundedIcon />)}
        sx={{
          height: '100%', width: '100%',
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          borderRadius: '10px',
          textTransform: 'none',
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );
}

const ButtonForCanvas = ({ mode, onClick }) => {
  const buttonText = mode === 0 ? 'Create New' : 'Edit Existing';

  return (
    <Box
      className='inputs'
      sx={{
        width: '100%', height: mode === 0 ? '35px' : '25px',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        transition: 'all 0.3s ease-in-out',
      }}
      onClick={onClick}
    >
      <Button
        variant="contained"
        startIcon={mode === 0 ? (<LibraryAddIcon />) : (<TransformRoundedIcon />)}
        sx={{
          height: '100%', width: '100%',
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          borderRadius: '10px',
          textTransform: 'none',
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );
}

const ButtonForPost = ({ mode, onClick }) => {
  const buttonText = mode === 0 ? 'Create New' : 'Edit Existing';

  return (
    <Box
      className='inputs'
      sx={{
        width: '100%', height: mode === 0 ? '35px' : '25px',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        transition: 'all 0.3s ease-in-out',
      }}
      onClick={onClick}
    >
      <Button
        variant="contained"
        startIcon={mode === 0 ? (<LibraryAddIcon />) : (<TransformRoundedIcon />)}
        sx={{
          height: '100%', width: '100%',
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          borderRadius: '10px',
          textTransform: 'none',
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );
}

const StandardButton = ({ onClick, disabled = false, text, iconComponent }) => {
  return (
    <Box
      className='inputs'
      sx={{
        width: '100%', height: '22px',
        marginTop: '5px',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
    >
      <Button
        variant="contained"
        startIcon={iconComponent}
        onClick={onClick}
        disabled={disabled}
        sx={{
          height: '100%', width: '100%',
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          borderRadius: '10px', textTransform: 'none',
        }}
      >
        {text}
      </Button>
    </Box>
  );
}

const BoardEditor = ({ }) => {
  const [checked, setChecked] = useState(true);
  const [boardMode, setBoardMode] = useState(true);              // 0: edit the board, 1: edit grids

  const [selectedGrids, setSelectedGrids] = useState([]); // [[0, 0], [0, 1], [0, 2]]

  const handleCheckboxChange = () => {
    setChecked(!checked);
  }

  const changeMode = () => {
    setBoardMode(!boardMode);
  }

  const nothing = () => { };

  return (
    <div>
      <label>
        <input
          className='inputs'
          type="checkbox"
          checked={checked}
          onChange={handleCheckboxChange}
        />
        Show Unapproved Marks
      </label>
      <StandardButton onClick={changeMode} text={boardMode ? "Select Grids" : "Edit The Board"} iconComponent={boardMode ? <GridViewIcon /> : <Crop32Icon />} />
      {
        !boardMode &&
        <div>
          <StandardButton onClick={nothing} disabled={selectedGrids.length <= 0} text={"Roll Back The Selected Grids"} iconComponent={<CloseRoundedIcon />} />
          <StandardButton onClick={nothing} disabled={selectedGrids.length <= 0} text={"Save Selected Grids"} iconComponent={<CheckRoundedIcon />} />
          <StandardButton onClick={nothing} disabled={true} text={"Save All Pendings"} iconComponent={<DoneAllRoundedIcon />} />
          <StandardButton onClick={nothing} disabled={true} text={"Give Up All Pendings"} iconComponent={<ErrorOutlineIcon />} />
        </div>
      }
    </div>
  );
}
