import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';

export class PerformanceMeter {
  static FULL_POWER = 5;
  static MOBILE_DEFAULT_POWER = 2;


  constructor(system) {
    this.system = system;

    this.isMobile = false;
    this.devicePerformanceLevel = PerformanceMeter.FULL_POWER;
    this.currentPerformanceLevel = this.FULL_POWER;

    this.onDeviceTypeChange = {};
    this.onDevicePerformanceLevelChange = {};
    this.onCurrentPerformanceLevelChange = {};

    this.autoSet();
  }

  getFullPowerMode() {
    console.log(this.currentPerformanceLevel);
    return this.currentPerformanceLevel >= PerformanceMeter.FULL_POWER;
  }

  setIsMobile(isMobile) {
    this.isMobile = isMobile;
    for (const key in this.onDeviceTypeChange) { this.onDeviceTypeChange[key](isMobile); }
  }
  setDevicePerformanceLevel(performance) {
    this.devicePerformanceLevel = performance;
    for (const key in this.onDevicePerformanceLevelChange) { this.onDevicePerformanceLevelChange[key](performance); }
  }
  setPerformanceLevel(performance) {
    this.currentPerformanceLevel = performance;
    for (const key in this.onCurrentPerformanceLevelChange) { this.onCurrentPerformanceLevelChange[key](performance); }
  }

  autoSet() {
    if (isMobile && !isTablet) {
      // strictly for phones, which is weaker than other devices.
      this.setIsMobile(true);
      this.setDevicePerformanceLevel(PerformanceMeter.MOBILE_DEFAULT_POWER);
      this.setPerformanceLevel(PerformanceMeter.MOBILE_DEFAULT_POWER);
    } else {
      this.setIsMobile(false);
      this.setDevicePerformanceLevel(PerformanceMeter.FULL_POWER);
      this.setPerformanceLevel(PerformanceMeter.FULL_POWER);
    }
  }
}