import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { GridContainer, ToolItem, ToolItemICONChildren } from '../../components/toolBar/UltimateControlPanel.jsx';
import { SystemOperationsContext } from "../../context/SystemRunnerContext.js";
import ButtonGroups from "../../cross_page_components/ButtonGroups.jsx";
import { RectDataDisplay } from "./Hand.js";

import { useTheme } from '@mui/system';
import check_green from '../../icons/check-green.svg'
import cross_red from '../../icons/cross-red.svg'


export const AuxiliaryLineControlPanel = ({ toolpack, theToolObject, isVertical }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [xAuxiliary, setXAuxiliary] = useState(theToolObject.xAuxiliary);
  const [yAuxiliary, setYAuxiliary] = useState(theToolObject.yAuxiliary);
  const [refresh, setRefresh] = useState(0);

  const newXAuxiliaryValue = useRef(0);
  const newYAuxiliaryValue = useRef(0);

  const theme = useTheme();

  useEffect(() => {
    const updateXAuxiliary = (newXAuxiliary) => {
      setRefresh(refresh + 1);
      setXAuxiliary(newXAuxiliary);
    }

    const updateYAuxiliary = (newYAuxiliary) => {
      setRefresh(refresh + 1);
      setYAuxiliary(newYAuxiliary);
    }

    theToolObject.onXAuxiliarylineChange["_ALCX"] = updateXAuxiliary;
    theToolObject.onYAuxiliarylineChange["_ALCY"] = updateYAuxiliary;
    return () => {
      delete theToolObject.onXAuxiliarylineChange["_ALCX"];
      delete theToolObject.onYAuxiliarylineChange["_ALCY"];
    }
  }, [refresh]);


  const subItemBaseStyle = {
    boxSizing: 'border-box',
    borderRadius: "5px",
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#e0e0e0",
    border: '2px solid #e0e0e0',
    cursor: "pointer",
    fontSize: "14px",
    textAlign: "center",
    [isVertical ? "width" : "height"]: "20%",
  };
  const subItemHoverStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.2)" };
  const subItemSelectedStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.5)" };
  const containerStyle = {
    boxSizing: 'border-box',
    backgroundColor: "rgba(50,50,50,1)",
    borderRadius: "3px",
    [isVertical ? "width" : "height"]: "100%",
    padding: "3px",
    zIndex: "100000000000000000"
  }

  const horizontalAuxiliaryInput = (e) => {
    const maxCoord = system_operations.coordinator.theoreticalBoardWidth;
    newXAuxiliaryValue.current = Math.max(Math.min(e.target.value, maxCoord), 0);
  }

  const verticalAuxiliaryInput = (e) => {
    const maxCoord = system_operations.coordinator.theoreticalBoardHeight;
    newYAuxiliaryValue.current = Math.max(Math.min(e.target.value, maxCoord), 0);
  }

  return (
    <div>
      <div style={{ maxWidth: '100%', maxHeight: '200px', overflowY: 'auto' }}>
        <div style={{ color: "white", fontSize: "14px" }}> Horizontal Guides </div>
        {yAuxiliary.map((yCoord, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
            <div style={{ marginRight: '5px', backgroundColor: "#777777", borderRadius: "3px", paddingLeft: "5px", color: "white", width: "80%", height: "100%" }}>{yCoord}</div>
            <ToolItem icon={cross_red} onClick={() => { theToolObject.removeYAuxiliary(index) }} selected={false}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
          </div>
        ))}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input type="number" defaultValue={0} onChange={verticalAuxiliaryInput} min={0} max={system_operations.coordinator.theoreticalBoardHeight} style={{ marginRight: '5px', width: "80%", height: "100%" }} />
          <ToolItem icon={check_green} onClick={() => { theToolObject.addYAuxiliary(Number(newYAuxiliaryValue.current)) }} selected={false}
            baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
        </div>
      </div>
      <div style={{ maxWidth: '100%', maxHeight: '200px', overflowY: 'auto' }}>
        <div style={{ color: "white", fontSize: "14px" }}> Vertical Guides </div>
        {xAuxiliary.map((xCoord, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
            <div style={{ marginRight: '5px', backgroundColor: "#777777", borderRadius: "3px", paddingLeft: "5px", color: "white", width: "80%", height: "100%" }}>{xCoord}</div>
            <ToolItem icon={cross_red} onClick={() => { theToolObject.removeXAuxiliary(index) }} selected={false}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
          </div>
        ))}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input type="number" defaultValue={0} onChange={horizontalAuxiliaryInput} min={0} max={system_operations.coordinator.theoreticalBoardWidth} style={{ marginRight: '5px', width: "80%", height: "100%" }} />
          <ToolItem icon={check_green} onClick={() => { theToolObject.addXAuxiliary(Number(newXAuxiliaryValue.current)) }} selected={false}
            baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
        </div>
      </div>
    </div>
  );
}
