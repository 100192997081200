export class MagnetsMaker {
  constructor(coordinator, staticXValues, staticYValues) {
    this.coordinator = coordinator;
    this.staticXValues = staticXValues;
    this.staticYValues = staticYValues;
  }

  setStaticValues(staticXValues, staticYValues) {
    this.staticXValues = staticXValues;
    this.staticYValues = staticYValues;
  }

  setStaticValuesByBoardData(boardData) {
    if (!boardData.isHorizontal) {
      const boardWidth = boardData.gridWidth * boardData.totalColumns;
      const board1_3Width = boardWidth/3;
      const board1_4Width = boardWidth/4;
      this.staticXValues = [ boardWidth / 2, board1_3Width, board1_3Width * 2, board1_4Width, board1_4Width * 3, ];
      this.staticYValues = [];
    } else {
      const boardHeight = boardData.gridHeight * boardData.totalRows;
      const board1_3Height = boardHeight/3;
      const board1_4Height = boardHeight/4;
      this.staticXValues = [];
      this.staticYValues = [ boardHeight / 2, board1_3Height, board1_3Height * 2, board1_4Height, board1_4Height * 3, ];
    }
  }

  getMagnets(rectArrays, rectsToExclude_IDDict, xAuxiliaries=[], yAuxiliaries=[]) {
    const xMagnets = {};
    const yMagnets = {};

    for (const value of this.staticXValues) {
      xMagnets[value] = true;
    }

    for (const value of this.staticYValues) {
      yMagnets[value] = true;
    }
    for (const xCoord of xAuxiliaries) {
      xMagnets[xCoord] = true;
    }
    for (const yCoord of yAuxiliaries) {
      yMagnets[yCoord] = true;
    }

    for (const rectArray of rectArrays) {
      for (const rect of rectArray) {
        if (!(rect.getID() in rectsToExclude_IDDict)) {
          const x0Edge = rect.getActiveX();
          const xMidEdge = x0Edge + rect.getActiveWidth()/2;
          const x1Edge = x0Edge + rect.getActiveWidth();
          const y0Edge = rect.getActiveY();
          const yMidEdge = y0Edge + rect.getActiveHeight()/2;
          const y1Edge = y0Edge + rect.getActiveHeight();
          xMagnets[x0Edge] = true; // not storing real useful data.
          xMagnets[xMidEdge] = true; // not storing real useful data.
          xMagnets[x1Edge] = true; // not storing real useful data.
          yMagnets[y0Edge] = true; // not storing real useful data.
          yMagnets[yMidEdge] = true; // not storing real useful data.
          yMagnets[y1Edge] = true; // not storing real useful data.
        }
      }
    }
    return [xMagnets, yMagnets];
  }

  // this will not update the changes promotly when there are rect changes in the same section.
  getMagnets_cache(getRectArray, rectsToExclude_IDDict, xAuxiliaries=[], yAuxiliaries=[]) {
    const currentSection = this.coordinator.getCurrentPaperSection();
    if (!this.cachedMagnets || this.cachedSection !== currentSection) {
      this.cachedMagnets = this.getMagnets(getRectArray(), rectsToExclude_IDDict, xAuxiliaries, yAuxiliaries);
      this.cachedSection = currentSection;
    }
    return this.cachedMagnets;
  }
}


// this function is used to prefix the input. meaning that use this before the collision check. let the collision system do the final touch for the final positioning.
export class Magnetizer {
  static pointMagnetMove(magnetsDict, eventPoint, margin = 10) {
    const moveResult = this.moveToTheClosestMagnet(magnetsDict, eventPoint);
    const magnetPoint = moveResult.magnet;
    if (moveResult.moved && Math.abs(moveResult.move) <= margin) {
      return { move: moveResult.move, moved: true, magnet: magnetPoint };
    }
    return { move: 0, moved: false, magnet: 0 };
  }

  static rectangleMagnetMultiPointsMove(magnetsDicts, xArray, yArray, margin = 10) {
    let xMinMove = Infinity;
    let yMinMove = Infinity;
    let xMagnetPoint = 0;
    let yMagnetPoint = 0;
    let xMoved = false;
    let yMoved = false;

    for (let x of xArray) {
      let xResult = this.moveToTheClosestMagnet(magnetsDicts[0], x);
      if (xResult.moved && Math.abs(xResult.move) < Math.abs(xMinMove)) {
        xMinMove = xResult.move;
        xMagnetPoint = xResult.magnet;
        xMoved = true;
      }
    }

    if (Math.abs(xMinMove) > margin) {
      xMinMove = 0;  // Reset if move is outside of margin
      xMagnetPoint = 0;
      xMoved = false;
    }

    for (let y of yArray) {
      let yResult = this.moveToTheClosestMagnet(magnetsDicts[1], y);
      if (yResult.moved && Math.abs(yResult.move) < Math.abs(yMinMove)) {
        yMinMove = yResult.move;
        yMagnetPoint = yResult.magnet;
        yMoved = true;
      }
    }

    if (Math.abs(yMinMove) > margin) {
      yMinMove = 0;  // Reset if move is outside of margin
      yMagnetPoint = 0;
      yMoved = false;
    }

    // bases are only valid if moved is true.
    return { move: [xMinMove, yMinMove], moved: [xMoved, yMoved], magnets: [xMagnetPoint, yMagnetPoint] };
  }

  // return a move data as [0, 0]
  static rectangleMagnetMove(magnetsDicts, x0, x1, y0, y1, margin = 10) {
    const x0ToMagMove = this.moveToTheClosestMagnet(magnetsDicts, x0);
    const x1ToMagMove = this.moveToTheClosestMagnet(magnetsDicts, x1);
    const y0ToMagMove = this.moveToTheClosestMagnet(magnetsDicts, y0);
    const y1ToMagMove = this.moveToTheClosestMagnet(magnetsDicts, y1);

    let move = [0, 0];
    let moved = [false, false];

    if (x0ToMagMove.moved && (Math.abs(x0ToMagMove.move) <= Math.abs(x1ToMagMove.move) || !x1ToMagMove.moved)) {
      if (Math.abs(x0ToMagMove.move) <= margin) {
        move[0] = x0ToMagMove.move;
        moved[0] = true;
      }
    } else if (x1ToMagMove.moved) {
      if (Math.abs(x1ToMagMove.move) <= margin) {
        move[0] = x1ToMagMove.move;
        moved[0] = true;
      }
    }

    if (y0ToMagMove.moved && (Math.abs(y0ToMagMove.move) <= Math.abs(y1ToMagMove.move) || !y1ToMagMove.moved)) {
      if (Math.abs(y0ToMagMove.move) <= margin) {
        move[1] = y0ToMagMove.move;
        moved[1] = true;
      }
    } else if (y1ToMagMove.moved) {
      if (Math.abs(y1ToMagMove.move) <= margin) {
        move[1] = y1ToMagMove.move;
        moved[1] = true;
      }
    }

    return { move, moved };
  }

  static moveToTheClosestMagnet(magnetsDict, eventPoint) {
    // if (Object.keys(magnetsDict).length === 0) {
    //   return Infinity;
    // }
    let minDistance = Infinity;
    let moveValue = 0;
    let magnetPoint = eventPoint;
    let moved = false;

    for (let key in magnetsDict) {
      let point = Number(key); // magnetsDict[key];
      let distance = Math.abs(point - eventPoint);
      if (distance < minDistance) {
        moved = true;
        minDistance = distance;
        moveValue = point - eventPoint;  // This will give the signed value to move to the magnet
        magnetPoint = point;
      }
    }
    return { move: moveValue, moved, magnet: magnetPoint };
  }
}
