import React, { useRef, useState, useContext, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SystemOperationsContext } from "../../context/SystemRunnerContext";
import { RequestDataMaker } from "../../system/RestServerConnector.js"
import { ToolItem } from "./UltimateControlPanel.jsx"

import gear_white from '../../icons/settings-white.svg';

const ItemType = "DRAGGABLE_ITEM";

// isOwner can later be changed to editing. check two values -> isOwner && editing
const DraggableItem = ({ title, coord, index, itemRef, moveItemHandler, isOwner, editMode, editButton }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [isEditing, setIsEditing] = useState(false);
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item, monitor) {
      if (!isOwner || !editMode) { // If isOwner is false, don't proceed
        return;
      }
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveItemHandler(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    canDrag: isOwner && editMode,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  if (isOwner && editMode) {
    drag(drop(ref));
  }

  const clicked = () => {
    system_operations.coordinator.setGlobalOffsetTheo(coord, true);
  }

  const itemStyle = {
    width: (isOwner && editMode) ? "calc(100% - 33px)" : "100%", height: "100%", backgroundColor: "transparent",
    border: "2px solid #e0e0e0", color: "#e0e0e0", borderRadius: "5px", display: "flex",
    alignItems: "center", cursor: (isOwner && editMode)? "move" : "pointer", boxSizing: 'border-box',
    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
  };
  const editButtonBaseStyle = {
    boxSizing: 'border-box', borderRadius: "5px",
    width: "30px", marginRight: "3px",
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#e0e0e0", border: '2px solid #e0e0e0',
    cursor: "pointer",
    textAlign: "center",
  };
  const itemStyleHover = {
    ...editButtonBaseStyle,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#ffffff",
    border: '2px solid #ffffff',
  }
  const itemStyleSelected = {
    ...itemStyleHover, backgroundColor: "rgba(255, 255, 255, 0.5)",
  };

  const setEditingButton = () => {
    setIsEditing(!isEditing);
    editButton(itemRef);
  }

  return (
    <div ref={ref} style={{ width: "100%", height: "30px", marginBottom: "2px", display: "flex", boxSizing: 'border-box' }}>
      {
        (isOwner && editMode) &&
        <ToolItem icon={gear_white} onClick={setEditingButton} selected={isEditing} baseStyle={editButtonBaseStyle} hoverStyle={itemStyleHover} selectedStyle={itemStyleSelected} />
      }
      <div
        onClick={clicked}
        style={itemStyle}>
        {title}
      </div>
    </div>
  );
};

const DragContainer = ({ items, setItems, editButton, deleteButton, isOwner, editMode }) => {
  const moveItemHandler = (dragIndex, hoverIndex) => {
    const dragItem = items[dragIndex];
    if (dragItem) {
      setItems((prevState) => {
        const copiedStateArray = [...prevState];
        const prevItem = copiedStateArray.splice(hoverIndex, 1, dragItem);
        copiedStateArray.splice(dragIndex, 1, prevItem[0]);
        return copiedStateArray;
      });
    }
  };

  return (
    <div style={{
      maxHeight: "500px",
      overflowY: "auto",
      width: "100%",
      padding: "3px",
      boxSizing: 'border-box',
    }}>
      {items.map((item, index) => (
        <DraggableItem
          key={item.id}
          title={item.title}
          coord={item.coord}
          itemRef={item}
          editButton={editButton}
          deleteButton={deleteButton}
          isOwner={isOwner}
          editMode={editMode}
          index={index}
          moveItemHandler={moveItemHandler}
        />
      ))}
    </div>
  );
};

function getItemsForDnD(boardData) {
  if (boardData.content) {
    const items = [];
    for (let i = 0; i < boardData.content.length; i++) {
      const item = { ...boardData.content[i] };
      item.id = i;
      items.push(item);
    }
    return items;
  }
  return [];
}

export const BoardContentPanel = ({ maxWidth }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [editMode, setEditMode] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [items, setItems] = useState(getItemsForDnD(system_operations.coordinator.getBoardData()));
  const [currentItemTitle, setCurrentItemTitle] = useState("");
  const [currentItemCoord, setCurrentItemCoord] = useState(0);
  const idTrack = useRef(items.length - 1);
  const [currentEditing, setCurrentEditing] = useState(null);
  const unchangedContentString = useRef(JSON.stringify(items));

  const saveCallbackRef = useRef(null);


  const isOwner = system_operations.coordinator.getUserRoleInWall().power > 10000;

  useEffect(() => {
    if (unchangedContentString.current !== JSON.stringify(items)) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [items, currentEditing]);

  const buttonStyle = {
    width: "50%", height: "20px", backgroundColor: "transparent", color: "#ffffff", border: "2px solid #ffffff",
    boxSizing: 'border-box', alignItems: "center", justifyContent: "center", borderRadius: "5px", cursor: "pointer"
  };

  const saveButtonStyle = { ...buttonStyle, backgroundColor: "green", }
  const deleteButtonStyle = { ...buttonStyle, backgroundColor: "red", }
  const disabledButton = { ...buttonStyle, backgroundColor: "grey", border: "2px solid #bbbbbb", cursor: "default" }
  const canAddMore = items.length < 10;

  const editButton = (item, saveCallback) => {
    if (currentEditing === item) {
      setCurrentEditing(null);
      setCurrentItemTitle("");
      setCurrentItemCoord(0);
      saveCallbackRef.current = saveCallback;
    } else {
      setCurrentEditing(item);
      setCurrentItemTitle(item.title);
      setCurrentItemCoord(item.coord);
      saveCallbackRef.current = null;
    }
  }

  const addNewButton = () => {
    const newItem = { title: "GO", coord: 0, id: idTrack.current };
    idTrack.current += 1; // increasing for one more.
    setItems(prevItems => [...prevItems, newItem]);
  }

  const saveAllChanges = () => {
    const newContent = [];
    for (const item of items) {
      newContent.push({ title: item.title, coord: item.coord });
    }
    system_operations.coordinator.getBoardData().content = newContent;
    RequestDataMaker.getDataToUpdateboardContent(system_operations, system_operations.coordinator.getWallID(), newContent).then((updateData) => {
      system_operations.restServerConnector.updateBoardContent(updateData); // update the board content...
    })
    setEditMode(false);
    setHasChanged(false);
  }

  const titleInput = (event) => {
    // currentEditing.title = event.target.value;
    setCurrentItemTitle(event.target.value);
  }

  const coordinateInput = (event) => {
    // currentEditing.coord = event.target.value;
    setCurrentItemCoord(Number(event.target.value));
  }

  // refresh the component. so it display the new value...
  const saveChangeToCurrentItem = (event) => {
    currentEditing.title = currentItemTitle;
    currentEditing.coord = currentItemCoord;
    setCurrentEditing(null); // no longer editing
  }

  const deleteCurrentItem = () => {
    setItems(prevItems => prevItems.filter(item => item !== currentEditing));
    setCurrentEditing(null); // no longer editing
  };

  return (
    <div style={{ width: (maxWidth ? maxWidth : "100%") }}>
      <DndProvider backend={HTML5Backend}>
        <DragContainer items={items} setItems={setItems} editButton={editButton} isOwner={isOwner} editMode={editMode} />
      </DndProvider>
      {
        currentEditing &&
        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <input
            type="text"
            maxLength="30"
            value={currentItemTitle}
            placeholder="Content Name..."
            onChange={titleInput}
          />
          <input
            type="number"
            placeholder="Coordinate..."
            value={currentItemCoord}
            onChange={coordinateInput}
          />
          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <button onClick={saveChangeToCurrentItem} style={buttonStyle}>
              {"OK!"}
            </button>
            <button onClick={deleteCurrentItem} style={deleteButtonStyle}>
              {"Remove"}
            </button>
          </div>
        </div>
      }
      {
        isOwner &&
        <>
          {
            editMode ?
              <div style={{ width: "100%", flexDirection: "row" }}>
                <button onClick={addNewButton} disabled={!canAddMore} style={canAddMore ? buttonStyle : disabledButton}>
                  {canAddMore ? "Add" : "---"}
                </button>
                <button onClick={saveAllChanges} disabled={!hasChanged} style={hasChanged ? saveButtonStyle : disabledButton}>
                  {hasChanged ? "Save" : "---"}
                </button>
                <div>
                  <button onClick={() => { setEditMode(false) }} style={{ ...buttonStyle, width: "100%" }}>
                    {" Cancel "}
                  </button>
                </div>
              </div> :
              <button onClick={() => { setEditMode(true) }} style={{ ...buttonStyle, width: "100%" }}>
                {" Edit Content "}
              </button>
          }
        </>
      }
    </div>
  );
};