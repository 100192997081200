import React, { useState, useRef, useContext, useEffect, useMemo } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { RatioSizer } from "../utility/Sizer.js";
import { PostDisplayAndUtility } from "../system/PostDisplayAndUtility.js";
import { PostInstanceRect } from '../system/PaperRects.js';
import { RequestDataMaker } from "../system/RestServerConnector.js"
import NoPostImage from "../img/Pencel_The_Post_is_Gone_Text.svg"

import { useDropzone } from 'react-dropzone';
import ButtonGroups from "../cross_page_components/ButtonGroups.jsx";
import { UserShortCardToolTip } from "../components/UserDisplay";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles

import { useTheme } from '@mui/system';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Paper, DialogTitle, Button, Avatar, Box, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';
import Divider from '@mui/material/Divider';
import { act } from 'react-dom/test-utils';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import LoopIcon from '@mui/icons-material/Loop';



export const UploadPostPanel = ({ paperRect, submitCallback }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [creatingMode, setCreatingMode] = useState(true);
  const containerRef = useRef(null);

  const theme = useTheme();

  const fenceByPassRule = useMemo(() => {
    return (fenceRect) => {
      const fence = fenceRect?.dataRef;
      if (!fence) {
        return false;
      }
      const allowPosting = system_operations.fenceCoder.isFlagSetByName(fence.rules, "postingOther");
      if (fence.creatorID === system_operations.getUserID() || allowPosting) {
        return true;
      } else {
        return false;
      }
    }
  }, []);

  const switchMode = () => {
    if (creatingMode) {
      setCreatingMode(false);
    } else {
      setCreatingMode(true);
    }
  }

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      system_operations.coordinator.toolpack.getToolByName("pi").setMode(0); // back to edit mode
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div ref={containerRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ width: "100%", backgroundColor: theme.color.basementGrey, height: "26px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={switchMode}
          style={{
            position: 'absolute',
            left: creatingMode ? "auto" : "20px",
            right: creatingMode ? "20px" : "auto",
            top: "4px",
            height: "18px",
            width: "116px",
          }}
          startIcon={<LoopIcon />}
        >
          {creatingMode ? "Select!" : "Create!"}
        </Button>
      </div>
      {creatingMode ?
        <PostMainPanel isCreating={true} existingPost={null} style={{ borderRadius: "0px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }} postInstanceRectRef={paperRect} fenceByPassRule={fenceByPassRule} submitCallback={submitCallback} /> :
        <PostsSelectionPanel style={{ borderRadius: "0px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }} paperRect={paperRect} submitCallback={submitCallback} />
      }
    </div>
  )
}

const closePageButton = (safe, disabled = false) => {
  return {
    width: "50px",
    height: "50px",
    marginTop: "10px",
    borderRadius: '50%',
    border: 'none',
    fontSize: '24px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    flex: '0 0 auto', // This line added to prevent resizing
    opacity: disabled ? 0.5 : 1,
    border: `1px solid ${safe ? "rgba(0,255,0,0.5)" : "rgba(255,0,0,0.5)"}`,
    backgroundColor: safe ? 'rgba(0,255,0,0.2)' : 'rgba(255,0,0,0.2)',
    color: safe ? 'green' : 'red',
    '&:hover': disabled ? {} : {
      backgroundColor: safe ? 'rgba(0,255,0,0.5)' : 'rgba(255,0,0,0.5)',
    },
  }
};

const PostsSelectionPanel = ({ style, paperRect, submitCallback }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [selection, setSelection] = useState(null);
  const [postList, setPostList] = useState([]);
  const [size, setSize] = useState({ width: '90vw', maxWidth: '512px' });
  const [sizes, setSizes] = useState({
    listingArea: {
      height: '70vh',
      maxHeight: '700px',
    },
  })
  const holdingPostInstanceRef = useRef(null);
  const placed = useRef(false);

  const theme = useTheme();

  const imagePostContainerBaseStyle = {
    width: '128px',
    height: '128px',
    maxWidth: '256px',
    maxHeight: '256px',
    objectFit: 'cover',
    transition: 'filter 0.3s ease',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '12px',
  };

  const liBaseStyle = {
    cursor: 'pointer',
    borderRadius: '10px',
    overflow: 'hidden',
  };

  const ulStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
    padding: '0',
    listStyle: 'none',
  };

  useEffect(() => {
    const fetchPosts = async () => {
      const posts = await system_operations.postSystem.getAllPosts(system_operations.getUserID());
      setPostList(posts);
    };
    fetchPosts();
  }, []);

  /* const placePostInstance = async (extra) => {
    placed.current = true;
    const newPostInstance = { ...holdingPostInstanceRef.current }
    newPostInstance.style = {};
    newPostInstance.protectLevel = system_operations.coordinator.getUserRoleInWall().power;
    newPostInstance.creatorID = system_operations.getUserID();
    newPostInstance.layer = (extra.layer !== undefined && extra.layer >= 0) ? extra.layer : 0;
    newPostInstance.postInstanceID = `${system_operations.getUserID()}_${newPostInstance.postID}`;

    const piRect = new PostInstanceRect(
      newPostInstance, "postInstanceID", "posts",
      system_operations.coordinator.sectionData.updatePostLoaderItemPosition.bind(system_operations.coordinator.sectionData),
      system_operations.coordinator.pendingPostInstancesSystem, system_operations
    );
    piRect.isNotUploaded = true;
    piRect.setIsNotUploaded(true);
    // delete newPostInstance["postInstanceID"]; // remove the ID from the data.

    // if the post already has a loaded instance, should change to update mode, which move the existing instance to the desired transform. (using temp data)
    // if the instance is inside the board, however, it's not loaded currently. if it's ever loaded. it will use mergeRule, add the new transform as the base transform.
    system_operations.coordinator.sectionData.insertNewItemToPostLoader(piRect); // use MergeRule to add.
    system_operations.coordinator.pendingPostInstancesSystem.addToPendingList(piRect.getID(), piRect, "upload");
    system_operations.coordinator.toolpack.getToolByName("pi").setMode(0);
  }

  const startPostInstancePlacement = async () => {
    placed.current = false;
    holdingPostInstanceRef.current = PostDisplayAndUtility.createPostInstanceFromAPost(selection);
    system_operations.coordinator.guideBox.setActive(holdingPostInstanceRef.current, (x, y) => {
      if (!placed.current) { holdingPostInstanceRef.current.x = x; holdingPostInstanceRef.current.y = y; }
    },
      placePostInstance, system_operations.coordinator.floatingCollisionSystems, system_operations.coordinator.fenceLoader.fenceCollisionSystem, fenceByPassRule);
    system_operations.setContentWindow(null);
  }*/

  const handleSelectPost = (selected) => {
    setSelection(selected);
  }

  const submitSelection = () => {
    if (selection) {
      system_operations.set_loading(true);
      RequestDataMaker.dataToUpdatePaperExtension_post(system_operations, paperRect.getID(), selection.postID, selection.text, selection.previewUrls, selection.numberOfContent, false).then((requestJson) => {
        system_operations.restServerConnector.requestToUpdatePaperExtension(requestJson, []).then((result) => {
          if (result.success) {
            if (submitCallback) { submitCallback(true); }
          }
          system_operations.set_loading(false);
        })
      })
    }
  }

  const getDefaultPreview = (post) => {
    if (post.type === "audio") {
      return system_operations.postSystem.defaultAudioCover;
    } else if (post.type === "video") {
      return system_operations.postSystem.defaultVideoCover;
    }
    return system_operations.postSystem.defaultPreviewGeneral;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{
        backgroundColor: theme.color.basementGrey, overflow: "hidden", overflowY: 'scroll',
        width: size.width, maxWidth: size.maxWidth, ...sizes.listingArea, ...style
      }}>
        <div style={{ paddingTop: "10px" }}>
          <ul style={ulStyle}>
            {postList.map((content, index) => (
              <li
                style={selection === content ? { ...liBaseStyle, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transform: 'translateY(-2px)', border: '4px solid rgba(0, 0, 0, 0.6)' } : liBaseStyle}
                onClick={() => handleSelectPost(content)}
                key={index}
              >
                <div style={imagePostContainerBaseStyle}>
                  <img
                    className="post-preview"
                    src={content.previewUrls && content.previewUrls[0] ? content.previewUrls[0] : getDefaultPreview(content)}
                    alt={`Post ${content.postID}`}
                    draggable="false"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <IconButton variant="outlined" sx={closePageButton(true, selection ? false : true)} disabled={selection === null} onClick={submitSelection}>
        <CheckIcon sx={{ color: '#00ff00' }} />
      </IconButton>
    </div>
  );
}

export const PostMainPanel = ({ isCreating, existingPost = null, postInstanceRectRef = null, style = {}, fenceByPassRule, submitCallback, deletionCallback }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [post, setPost] = useState(isCreating ? PostDisplayAndUtility.createDefaultPost(system_operations.getUserID()) : existingPost);
  const [confirmation, setConfirmationOn] = useState(false);
  const [postCreatorData, setPostCreatorData] = useState({ username: "", avatar: "none" });
  const [size, setSize] = useState({ width: '90vw', maxWidth: '512px', });
  const [mainAreaSize, setMainAreaSize] = useState([0, 0]);
  const postCreateDate = useRef("");

  const [sizes, setSizes] = useState({
    textArea: {
      height: '52vh',
      maxHeight: '500px',
    },
    mediaArea: {
      height: '18vh',
      maxHeight: '200px',
    },
  })
  const uploadingFilesUrlsRef = useRef([]);
  const contentFilesType = useRef("");
  const hasTextInput = useRef(false);
  const holdingPostInstanceRef = useRef(null);
  const mainAreaRef = useRef(null);
  const placed = useRef(false);

  const theme = useTheme();

  useEffect(() => {
    if (post) {
      const formattedDate = new Date(post.createdDate);
      postCreateDate.current = `${formattedDate.getMonth() + 1}/${formattedDate.getDate()}/${formattedDate.getFullYear()}`;
      system_operations.usersData.getUserDataByID(post.creatorID).then((userData) => {
        setPostCreatorData(userData);
      })
    }
  }, [existingPost])

  useEffect(() => {
    if (mainAreaRef.current) {
      const bounding = mainAreaRef.current.getBoundingClientRect();
      setMainAreaSize([bounding.width, bounding.height]);
    }
  }, [])

  const setTextContent = (textContent) => {
    if (textContent === '<p><br></p>' || textContent === "" || textContent === "<p></p>") {
      hasTextInput.current = true;
    } else {
      hasTextInput.current = false;
    }
    post.text = textContent;
  }

  const setContentFiles = (newUrlFiles) => {
    uploadingFilesUrlsRef.current = newUrlFiles
    post.numberOfContent = newUrlFiles.length; // exact how many files in
  }

  const setContentFilesType = (type) => {
    contentFilesType.current = type;
  }


  /* const placePostInstance = async (extra) => {
    placed.current = true;
    const newPostInstance = { ...holdingPostInstanceRef.current }
    newPostInstance.style = {};
    newPostInstance.protectLevel = system_operations.coordinator.getUserRoleInWall().power;
    newPostInstance.creatorID = system_operations.getUserID();
    newPostInstance.layer = (extra.layer !== undefined && extra.layer >= 0) ? extra.layer : 0;
    newPostInstance.postInstanceID = `${system_operations.getUserID()}_${newPostInstance.postID}`;

    const piRect = new PostInstanceRect(
      newPostInstance, "postInstanceID", "posts",
      system_operations.coordinator.sectionData.updatePostLoaderItemPosition.bind(system_operations.coordinator.sectionData),
      system_operations.coordinator.pendingPostInstancesSystem, system_operations
    );
    piRect.isNotUploaded = true;
    piRect.setIsNotUploaded(true);
    //  delete holdingPostInstanceRef.current["postInstanceID"]; // remove the ID from the data.

    // if the post already has a loaded instance, should change to update mode, which move the existing instance to the desired transform. (using temp data)
    // if the instance is inside the board, however, it's not loaded currently. if it's ever loaded. it will use mergeRule, add the new transform as the base transform.
    system_operations.coordinator.sectionData.insertNewItemToPostLoader(piRect); // use MergeRule to add.
    system_operations.coordinator.pendingPostInstancesSystem.addToPendingList(piRect.getID(), piRect, "upload");
    system_operations.coordinator.toolpack.getToolByName("pi").setMode(0);
  }

  const startPostInstancePlacement = async () => {
    placed.current = false;
    system_operations.set_loading(true);

    // const uploadedPost = await system_operations.postSystem.createAPost(PostDisplayAndUtility.shapeAPost(post), system_operations.getUserID(), previewImages, uploadingFilesUrlsRef.current);
    const postCreationData = await RequestDataMaker.dataToCreateAPost(system_operations, uploadingFilesUrlsRef.current, null, post.text);
    const result = await system_operations.restServerConnector.createAPost(postCreationData);
    if (result && result.success && result.post) {
      const newPost = result.post;
      system_operations.postLoader.updateLoadedPosts(system_operations.getUserID(), newPost, "add");
      holdingPostInstanceRef.current = PostDisplayAndUtility.createPostInstanceFromAPost(newPost);
      system_operations.coordinator.guideBox.setActive(holdingPostInstanceRef.current, (x, y) => {
        if (!placed.current) { holdingPostInstanceRef.current.x = x; holdingPostInstanceRef.current.y = y; }
      },
        placePostInstance,
        system_operations.coordinator.floatingCollisionSystems, system_operations.coordinator.fenceLoader.fenceCollisionSystem,
        fenceByPassRule);
      system_operations.setContentWindow(null);
    }
    system_operations.set_loading(false);
  } */

  // is used to remove a post stick if postInstanceRectRef is given, otherwise, this will remove the post base...
  const deleteAPost = async () => {
    if (!confirmation) {
      setConfirmationOn(true);
    } else {
      // remove the post
      system_operations.set_loading(true);
      if (postInstanceRectRef) {
        // await system_operations.coordinator.pendingPostInstancesSystem.deleteAPendingItem(postInstanceRectRef);
        system_operations.set_loading(true);
        RequestDataMaker.dataToDeletePaperExtension(system_operations, postInstanceRectRef.getID()).then((requestJson) => { // data to remove existing extension.
          system_operations.restServerConnector.requestToUpdatePaperExtension(requestJson, []).then((result) => {
            if (result.success) {
              delete postInstanceRectRef.getData().extension;
              if (deletionCallback) { deletionCallback(true); }
              if (postInstanceRectRef.func?.styleRefreshCanvasCore) { postInstanceRectRef.func.styleRefreshCanvasCore(); } // refresh the style
            }
            system_operations.set_loading(false);
          })
        })
      } else {
        const postDeletionData = await RequestDataMaker.dataToDeleteAPost(system_operations, post.postID);
        const result = await system_operations.restServerConnector.deleteAPost(postDeletionData);
        if (result && result.success) {
          system_operations.setContentWindow(null);
        }
      }
      system_operations.set_loading(false);
    }
  }

  const submitPostCreation = async () => {
    system_operations.set_loading(true);
    const postCreationData = await RequestDataMaker.dataToCreateAPost(system_operations, uploadingFilesUrlsRef.current, null, post.text);
    const result = await system_operations.restServerConnector.createAPost(postCreationData);
    if (result && result.success && result.post) {
      const newPost = result.post;
      system_operations.postLoader.updateLoadedPosts(system_operations.getUserID(), newPost, "add");
    
      if (postInstanceRectRef) {
        system_operations.set_loading(true);
        RequestDataMaker.dataToUpdatePaperExtension_post(system_operations, postInstanceRectRef.getID(), newPost.postID, newPost.text, newPost.previewUrls, newPost.numberOfContent, false).then((requestJson) => {
          system_operations.restServerConnector.requestToUpdatePaperExtension(requestJson, []).then((result) => {
            if (result.success) {
              if (submitCallback) { submitCallback(true); }
              system_operations.setContentWindow(null);
            }
            system_operations.set_loading(false);
          })
        })
      } else {
        system_operations.setContentWindow(null);
      }
    }
    system_operations.set_loading(false);
  }

  const recallStickRemoval = () => {
    setConfirmationOn(false);
  }

  const mainPanelStyle = {
    width: size.width, maxWidth: size.maxWidth, backgroundColor: theme.color.basementGrey, overflow: "hidden",
    display: 'flex', flexDirection: 'column', overflow: "hidden", borderRadius: "10px"
  }

  const userDisplay = (
    <DialogTitle id="fence-content-title" sx={{ color: "white" }}>
      Created By:&nbsp;
      <UserShortCardToolTip user={postCreatorData} onNavigate={() => { }}>
        <span
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '1rem',
            color: "rgba(64, 64, 225, 1)"
          }}
        >
          {postCreatorData.username}
        </span>
      </UserShortCardToolTip>
      &nbsp;&nbsp;on <span
        style={{
          fontSize: '1rem',
          color: "rgba(225, 225, 225, 1)"
        }}>{postCreateDate.current}</span>
    </DialogTitle>
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
      <div style={{ width: '100%', height: `${mainAreaSize[1]}px`, overflowX: 'hidden', overflowY: 'scroll', backgroundColor: theme.color.basementGrey, ...style }}>
        <div ref={mainAreaRef} style={mainPanelStyle}>
          <div style={{ width: '100%', overflow: 'auto', ...sizes.textArea }}>
            <TextInputZone allowEditing={isCreating} content={post ? post.text : ""} setContent={setTextContent} userDisplay={userDisplay} />
          </div>
          <div style={{ height: '2px', backgroundColor: '#505050', width: '100%' }} />
          <div style={{ width: '100%', overflowX: 'scroll', ...sizes.mediaArea }}>
            {isCreating ?
              (<MediaUploader allowChange={isCreating} post={post} setContentFiles={setContentFiles} setContentFilesType={setContentFilesType} />)
              :
              (<MediaDisplayer post={post} />)
            }
          </div>
          {!post &&
            <div style={{
              position: "absolute", left: "0", right: "0", width: "100%", height: `${mainAreaSize[1]}px`,
              display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: "rgba(128, 128, 128, 0.5)", zIndex: 1000000, ...style
            }}>
              <img src={NoPostImage} style={{ width: "100%", height: "100%" }} />
            </div>
          }
        </div>

        {post && !isCreating &&
          <div style={{ width: "100%" }}>
            <CommentArea post={post} />
          </div>
        }
      </div>
      {isCreating ?
        <IconButton variant="outlined" sx={closePageButton(true)} onClick={submitPostCreation}>
          <CheckIcon sx={{ color: '#00ff00' }} />
        </IconButton> :
        (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {confirmation &&
              <IconButton variant="outlined" sx={{ marginRight: "60px", ...closePageButton(true) }} onClick={recallStickRemoval}>
                <CloseIcon sx={{ color: '#00ff00' }} />
              </IconButton>}
            <IconButton variant="outlined" sx={closePageButton(false)} onClick={deleteAPost}>
              {
                confirmation ? <CheckIcon sx={{ color: '#ff0000' }} /> : <DeleteIcon sx={{ color: '#ff0000' }} />
              }
            </IconButton>
          </div>
        )
      }
    </div>
  );
}


const CommentArea = ({ post }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [allCommentsLoaded, setAllCommentsLoaded] = useState(false); // Default image size
  const [refreshCount, setRefreshCount] = useState(0); // Default image size

  const numberOfComments = useRef(post.commentsCount);

  const frontComments = useRef([]);
  const bottomComments = useRef([]);
  const frontNumberOfLoadedComments = useRef(0);
  const bottomNumberOfLoadedComments = useRef(0);
  const frontLastFetchValue = useRef(0);
  const backLastFetchValue = useRef(9999999999999);
  const isLoadingFront = useRef(false);
  const isLoadingBack = useRef(false);

  const containerRef = useRef();
  const amountOfCommentsPerLoad = 5;

  const theme = useTheme();

  const loadMoreCommentsForward = async () => {
    if (!isLoadingFront.current) {
      isLoadingFront.current = true;
      const loadAmount = Math.min(amountOfCommentsPerLoad, numberOfComments.current - (frontNumberOfLoadedComments.current + bottomNumberOfLoadedComments.current))
      const filters = {
        amount: loadAmount,
        startFrom: frontLastFetchValue.current, // starting from variable called "currentIndex"
        orderByField: 'date', // configure to load result basing on "date" param of the document
        orderDirection: 'asc' // ordering by date in ascending order
      };
      const results = await system_operations.firestoreActions.fetchFromFirestore(`users/${post.creatorID}/posts/${post.postID}/comments`, "commentID", [], filters);
      if (results) {
        for (let i = 0; i < results.length; i++) {
          frontComments.current[frontNumberOfLoadedComments.current + i] = results[i]; // append into the array...
          frontLastFetchValue.current = results[i].date;  // update the date...
        }
        frontNumberOfLoadedComments.current += results.length;
        if (frontNumberOfLoadedComments.current + bottomNumberOfLoadedComments.current >= numberOfComments.current) {
          mergeForwardAndBackward();
        }
        setRefreshCount(refreshCount + 1);
      }
      isLoadingFront.current = false;
    }
  }

  const loadMoreCommentsBackward = async () => {
    if (!isLoadingBack.current) {
      isLoadingBack.current = true;
      const loadAmount = Math.min(amountOfCommentsPerLoad, numberOfComments.current - (frontNumberOfLoadedComments.current + bottomNumberOfLoadedComments.current))
      const filters = {
        amount: loadAmount,
        startFrom: backLastFetchValue.current, // starting from variable called "currentIndex"
        orderByField: 'date', // configure to load result basing on "date" param of the document
        orderDirection: 'desc' // ordering by date in ascending order
      };
      const results = await system_operations.firestoreActions.fetchFromFirestore(`users/${post.creatorID}/posts/${post.postID}/comments`, "commentID", [], filters);
      if (results) {
        for (let i = 0; i < results.length; i++) {
          bottomComments.current[bottomNumberOfLoadedComments.current + i] = results[i]; // append into the array...
          backLastFetchValue.current = results[i].date;
        }
        bottomNumberOfLoadedComments.current += results.length;
        if (frontNumberOfLoadedComments.current + bottomNumberOfLoadedComments.current >= numberOfComments.current) {
          mergeForwardAndBackward();
        }
        setRefreshCount(refreshCount + 1);
      }
      isLoadingBack.current = false;
    }
  }

  const mergeForwardAndBackward = () => {
    let reversedBottomComments = bottomComments.current.slice().reverse();
    const extra = frontNumberOfLoadedComments.current + bottomNumberOfLoadedComments.current - numberOfComments.current;
    for (let i = 0; i < extra; i++) {
      reversedBottomComments.shift();
    }
    frontComments.current = frontComments.current.concat(reversedBottomComments);
    frontNumberOfLoadedComments.current = numberOfComments.current;
    bottomComments.current = [];
    bottomNumberOfLoadedComments.current = 0;
    setAllCommentsLoaded(true);
  }


  useEffect(() => {
    if (numberOfComments.current > amountOfCommentsPerLoad) {
      loadMoreCommentsForward();
      loadMoreCommentsBackward();
    } else {
      loadMoreCommentsForward();
    }
  }, [post]);

  const addCommentHook = (newComment) => {
    if (frontNumberOfLoadedComments.current === numberOfComments.current) {
      // everything is loaded, then add to the front array.
      frontComments.current.push(newComment);
      numberOfComments.current += 1;
      frontNumberOfLoadedComments.current += 1;
    } else {
      // add to the bottom array.
      bottomComments.current.unshift(newComment);
      numberOfComments.current += 1;
      bottomNumberOfLoadedComments.current += 1;
    }
    setRefreshCount(refreshCount + 1);
  }

  const removeCommentHook = (order, index) => {
    if (order === "front") {
      frontComments.current.splice(index, 1);
      frontNumberOfLoadedComments.current -= 0;
    } else {
      bottomComments.current.splice(index, 1);
      bottomNumberOfLoadedComments.current -= 0;
    }
    numberOfComments.current -= 1;
    setRefreshCount(refreshCount + 1);
  }

  const commentAreaStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'hidden',
    boxSizing: 'border-box',
    backgroundColor: theme.color.basementGrey,
    borderRadius: "10px",
  };

  const reversedBottomComments = bottomComments.current.slice().reverse();
  return (
    <div style={commentAreaStyle} ref={containerRef}>
      <CommentPrompt post={post} addCommentHook={addCommentHook} />
      {frontComments.current && frontComments.current.map((comment, index) => {
        return (<CommentBlock commentData={comment} post={post} order={"front"} removeCommentHook={removeCommentHook} index={index} key={index} />)
      })}
      {allCommentsLoaded ?
        <div style={{
          display: 'flex', alignItems: 'center',
          minWidth: "100%",
          height: "15px",
          fontSize: "12px"
        }}>
          No more comments...
        </div>
        :
        <div>
          <Button variant="contained"
            onClick={loadMoreCommentsForward}
            sx={{
              minWidth: "100%",
              height: "15px", // Adjusted to fit better with the textarea
              fontSize: "12px",
              borderRadius: '5px',
              backgroundColor: "#cccccc",
            }}> ...Load More Comments... </Button>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: "center",
          }}>
            ......
          </div>
          <Button variant="contained"
            onClick={loadMoreCommentsBackward}
            sx={{
              minWidth: "100%",
              height: "15px", // Adjusted to fit better with the textarea
              fontSize: "12px",
              borderRadius: '5px',
              backgroundColor: "#cccccc",
            }}> ...Load More Comments... </Button>
        </div>
      }
      {reversedBottomComments.map((comment, index) => {
        const oriIndex = reversedBottomComments.length - 1 - index;
        return (<CommentBlock commentData={comment} post={post} order={"bottom"} removeCommentHook={removeCommentHook} index={oriIndex} key={oriIndex} />)
      })}
    </div>
  );
}

const CommentPrompt = ({ post, addCommentHook }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [commentText, setCommentText] = useState(""); // Default image size
  const containerRef = useRef();

  const theme = useTheme();

  useEffect(() => {
  }, []);

  const maxLength = 300;
  const handleChange = (e) => {
    if (e.target.value.length <= maxLength) {
      setCommentText(e.target.value);
    }
  }

  const handleSubmit = async () => {
    if (commentText) {
      system_operations.set_loading(true);
      // const uploadedPost = await system_operations.postSystem.createAPost(PostDisplayAndUtility.shapeAPost(post), system_operations.getUserID(), previewImages, uploadingFilesUrlsRef.current);
      const commentCreationData = await RequestDataMaker.dataToCreateAComment(system_operations, post.postID, commentText);
      const result = await system_operations.restServerConnector.createAComment(commentCreationData);
      if (result && result.success && result.comment) {
        addCommentHook(result.comment);
        setCommentText("");
      }
      system_operations.set_loading(false);
    }
  }

  return (
    <div ref={containerRef} style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '5px',
      backgroundColor: theme.color.level2Grey, // Light grey background
      borderRadius: '8px',
    }}>
      <textarea
        value={commentText}
        onChange={handleChange}
        maxLength={maxLength}
        placeholder="What's in your mind..."
        style={{
          flex: 1, // Take up all available space
          fontSize: "18px",
          lineHeight: "1.5em",
          maxHeight: "4.5em",
          resize: "none",
          padding: "10px",
          backgroundColor: theme.color.level2Grey,
          borderRadius: "8px",
          border: "1px solid #bbbbbb", // Light grey border
          outline: "none", // Remove default outline
        }}
      />
      <div ref={containerRef} style={{
        width: "100px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2px',
        padding: '5px',
        borderRadius: '8px',
      }}>
        <Button
          variant="contained" onClick={handleSubmit}
          sx={{
            minWidth: "80px",
            height: "40px", // Adjusted to fit better with the textarea
            borderRadius: '5px',
            backgroundColor: theme.color.powerOrange,
          }}>
          SAY~
        </Button>
        <div style={{
          fontSize: '11px', // Smaller font-size for the counter
          color: '#555', // Dark grey color
        }}>
          {maxLength - commentText.length} remaining
        </div>
      </div>
    </div >
  );
}

const CommentBlock = ({ commentData, post, order, removeCommentHook, index }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [userData, setUserData] = useState({ username: commentData.creatorID, avatar: "" }); // Default image size
  const [permission, setPermission] = useState(commentData.creatorID === system_operations.getUserID() || post.creatorID === system_operations.getUserID());
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef();
  const theme = useTheme();

  useEffect(() => {
    if (post) {
      // system_operations.firestoreActions.fetchFromFirestore(`users/${commentData.creatorID}/posts/${post.postID}`, "").then((result) => {})
      system_operations.usersData.getUserDataByID(commentData.creatorID).then((user) => {
        setUserData(user);
      })
    }
  }, [post]);

  const handleDelete = async () => {
    if (permission) {
      system_operations.set_loading(true);
      // const uploadedPost = await system_operations.postSystem.createAPost(PostDisplayAndUtility.shapeAPost(post), system_operations.getUserID(), previewImages, uploadingFilesUrlsRef.current);
      const commentDeletionData = await RequestDataMaker.dataToDeleteAComment(system_operations, post.postID, commentData.commentID);
      const result = await system_operations.restServerConnector.deleteAComment(commentDeletionData);
      if (result && result.success) {
        // do something when success..
        removeCommentHook(order, index);
      }
      system_operations.set_loading(false);
    }
  }

  const handleExpandClick = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }


  const expandButton = (
    <IconButton sx={{ height: "18px", width: "18px" }} onClick={handleExpandClick}>
      {!expanded ?
        <MoreHorizIcon sx={{ height: "18px", width: "18px" }} /> : <NavigateNextRoundedIcon sx={{ height: "18px", width: "18px" }} />
      }
    </IconButton>
  )

  const expandedUI = (
    <>
      {/*<Button variant="outlined" color="primary" sx={{height: "14px", width: "80px",  fontSize: "12px"}}>Edit</Button>*/}
      {permission && (
        <Button variant="outlined" color="secondary" onClick={handleDelete} sx={{ height: "18px", width: "60px", fontSize: "12px", marginRight: "3px" }}>Delete</Button>
      )}
      {expandButton}
    </>
  )

  return (
    <div style={{ position: "relative", padding: "5px" }}>
      <Divider sx={{ marginTop: "0px", marginBottom: "3px" }} />
      <Box component="div" display="flex" alignItems="center" width="100%">
        <Avatar src={userData.avatar} alt={"user"} />
        <Box marginLeft={1} flex={1}>
          <Box sx={{ display: "flex", width: "100%", height: "12px", fontSize: "12px" }}>
            <div style={{ marginRight: "10px", fontWeight: 'bold' }}>
              {`@${userData.username}`}
            </div>
            <div style={{ fontSize: "12px", color: "#505050" }}>
              {timeSince(post.date)}
            </div>
          </Box>
          <Divider sx={{ marginTop: "5px", marginBottom: "0px" }} />
          <Box marginBottom={"3px"}>
            {commentData.content}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            {expanded ? expandedUI : expandButton}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = Math.floor(seconds / 31536000); // 1 year = 31536000 seconds

  if (interval > 1) {
    return interval + " years ago";
  }
  interval = Math.floor(seconds / 2592000); // 1 month = 2592000 seconds
  if (interval > 1) {
    return interval + " months ago";
  }
  interval = Math.floor(seconds / 604800); // 1 week = 604800 seconds
  if (interval > 1) {
    return interval + " weeks ago";
  }
  interval = Math.floor(seconds / 86400); // 1 day = 86400 seconds
  if (interval > 1) {
    return interval + " days ago";
  }
  interval = Math.floor(seconds / 3600); // 1 hour = 3600 seconds
  if (interval > 1) {
    return interval + " hours ago";
  }
  interval = Math.floor(seconds / 60); // 1 minute = 60 seconds
  if (interval > 1) {
    return interval + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}


const mediaContainerStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: "10px",
  boxSizing: 'border-box',
};

const imageIconStyle = {
  height: '100%',
  width: 'auto',
  marginRight: '10px',
  cursor: 'pointer',
};

const MediaDisplayer = ({ post }) => {
  const [imageSize, setImageSize] = useState(100); // Default image size
  const containerRef = useRef();
  useEffect(() => {
    setImageSize(containerRef.current.offsetHeight - 5);
    const handleResize = () => {
      setImageSize(containerRef.current.offsetHeight - 5);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={mediaContainerStyle} ref={containerRef}>
      {post && post.contentUrls.map(({ data, type }, index) => {
        return (
          <ImageCard
            key={index}
            src={data}
            isImage={type.startsWith('image') ? true : false}
            size={imageSize}
            allowChanges={false}
          />
        )
      })}
    </div>
  );
}

const MediaUploader = ({ setContentFiles, post, setContentFilesType }) => {
  const [files, setFiles] = useState([]);
  const [firstFileType, setFirstFileType] = useState(null);
  const [imageSize, setImageSize] = useState(100); // Default image size
  const containerRef = useRef();

  useEffect(() => {
    setImageSize(containerRef.current.offsetHeight - 5);
    const handleResize = () => {
      setImageSize(containerRef.current.offsetHeight - 5);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onDrop = acceptedFiles => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newFiles = (oldFiles => [...oldFiles, { data: reader.result, type: file.type }])(files);
        setFiles(newFiles);
        setContentFiles(newFiles);
      };
      reader.readAsDataURL(file);

      const currentFileType = file.type.split('/')[0];
      if (currentFileType === "image") {
        setContentFilesType("image");
      } else {
        setContentFilesType("files");
      }
      /* if (!firstFileType) {
        setFirstFileType(currentFileType);
        if (currentFileType === "image") {
          setContentFilesType("image");
        } else {
          setContentFilesType("files");
        }
      } else if (firstFileType === "image") { // already have previous upload
        setContentFilesType("imageList");
      }*/
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: firstFileType === 'image' ? 'image/*' : '',
    onDrop,
    disabled: firstFileType && firstFileType !== 'image'
  });

  const removeFile = index => {
    if (files.length > index) {
      if (files.length === 1) {
        setFirstFileType(null);
      }
      const newFiles = (oldFiles => oldFiles.filter((_, i) => i !== index))(files);
      setFiles(newFiles);
      setContentFiles(newFiles);
    }
  };

  const uploadButtonStyle = (disabled) => ({
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    border: 'none',
    fontSize: '24px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    flex: '0 0 auto', // This line added to prevent resizing
    opacity: disabled ? 0.6 : 1,
  });

  return (
    <div style={mediaContainerStyle} ref={containerRef}>
      {files.map(({ data, type }, index) => (
        <ImageCard
          key={index}
          src={data}
          isImage={type.startsWith('image') ? true : false}
          size={imageSize}
          allowChanges={true}
          onDelete={() => removeFile(index)}
        />
      ))}
      <div>
        <button style={uploadButtonStyle(firstFileType && firstFileType !== 'image')}
          {...getRootProps()}>
          <input {...getInputProps()} />
          +
        </button>
        <div style={{ flex: '0 0 10px', height: "100%" }} />
      </div>
    </div>
  );
}

const ImageCard = ({ src, allowChanges, onDelete, size, isImage }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);
  const deleteClicked = () => onDelete && onDelete();

  return (
    <Card sx={{ position: 'relative', width: `${size}px`, height: `${size}px`, minWidth: `${size}px`, marginRight: '10px' }}>
      {(isImage && src) ?
        <CardMedia
          component="img"
          image={src}
          onClick={handleOpen}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: allowChanges ? 'scale(1.05)' : 'none'
            }
          }}
        /> :
        <InsertDriveFileIcon onClick={handleOpen} style={imageIconStyle} />
      }
      {allowChanges && (
        <CardActions sx={{ position: 'absolute', top: 0, right: 0 }}>
          <IconButton
            variant="contained"
            sx={{
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                backgroundColor: 'transparent',
                color: 'red',
              },
            }}
            onClick={deleteClicked}
          >
            <CloseIcon />
          </IconButton>
        </CardActions>
      )}

      <Dialog open={openDialog} onClose={handleClose}>
        {isImage ?
          <DialogContent sx={{ padding: 0 }}>
            <img src={src} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
          </DialogContent>
          :
          <div>
            <DialogTitle sx={{ padding: 0 }}>
              Do you want to download the file?
            </DialogTitle>
            <DialogActions sx={{ padding: 0 }}>
              <Button onClick={handleClose}>No</Button>
              <Button component="a" href={src} download onClick={handleClose}>Yes</Button>
            </DialogActions>
          </div>
        }
      </Dialog>
    </Card>
  );
};

const textToolBarHeight = 44;
const TextInputZone = ({ allowEditing, content, setContent, style, userDisplay }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const currentContent = useRef(content);
  const containerSizeCalculator = useRef(new RatioSizer());

  const theme = useTheme();
  const [backgroundColor, setBackgroundColor] = useState(theme.color.level2Grey);

  const onContentChange = (newContent) => {
    setContent(newContent);
    currentContent.current = newContent
  }

  const textAreaStyle = {
    width: '100%',
    height: "100%",
    overflow: "hidden",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  }

  let quillComponent = (null);
  if (allowEditing) {
    quillComponent = (
      <div style={textAreaStyle}>
        <div id='toolbar' style={{ width: "100%", height: textToolBarHeight }}>
          {/* <input type="color" value={stickerColor} onChange={handleColorChange} style={{ height: "100%", width: "20px", padding: "0" }} /> */}
          <button className="ql-bold">Bold</button>
          <button className="ql-italic">Italic</button>
          <button className="ql-underline">Underline</button>
          <button className="ql-strike">Strike</button>
          <button className="ql-script" value="sub">Sub</button>
          <button className="ql-script" value="super">Super</button>
          <button className="ql-blockquote">Blockquote</button>
          <button className="ql-code-block">Code Block</button>
          <button className="ql-link">Link</button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: `calc(100% - ${textToolBarHeight}px)` }}>
          <Paper style={{ width: '100%', height: "100%", borderRadius: "0px", backgroundColor: backgroundColor, borderColor: 'transparent', padding: 5 }}>
            <ReactQuill
              className="myQuill"
              theme="snow"
              placeholder={"Let's say something..."}
              defaultValue={currentContent.current}
              onChange={onContentChange}
              modules={{
                toolbar: {
                  container: '#toolbar', // Selector for toolbar container
                },
              }}
              style={{
                height: '100%',
                userSelect: "auto",
              }}
            >
            </ReactQuill>
          </Paper>
        </div>
      </div>);
  } else {
    quillComponent = (
      <div style={textAreaStyle}>
        <div style={{ width: "100%", height: textToolBarHeight }}>
          {userDisplay}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: `calc(100% - ${textToolBarHeight}px)` }}>
          <Paper style={{ width: '100%', height: '100%', borderRadius: "0px", backgroundColor: backgroundColor, padding: 5 }}>
            <ReactQuill
              theme="snow"
              value={currentContent.current}
              readOnly={true}
              modules={{
                toolbar: false, // Disable toolbar
              }}
              style={{ height: "100%", width: "100%" }} // Set the size of the editor
            />
          </Paper>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {quillComponent}
    </div>
  );
};
