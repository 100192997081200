import React, { useMemo, useEffect, useState, useContext, useRef } from 'react';
import { SystemOperationsContext } from "../../context/SystemRunnerContext";
import { RequestDataMaker } from "../../system/RestServerConnector.js"

import { TextField, Button, styled } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: "#777777",
  borderRadius: "10px",
  padding: "10px",
});

const StyledTextField = styled(TextField)({
  width: '700px',
  maxWidth: '1500px',
  '@media (max-width: 500px)': {
    width: '90%',
  },
  '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const FCJumpPrompt = ({ rect, onChangeCallback = null }) => {
  const canvasData = rect.getData();
  // markID is type_typeID
  const { system_operations } = useContext(SystemOperationsContext);
  const [coord, setCoord] = React.useState(rect.getData().coord || undefined);

  const handleCoordChange = (e) => {
    const newCoord = Number(e.target.value); // might be better to clamp it.
    setCoord(newCoord);
  };

  const handleSubmit = async () => {
    system_operations.set_loading(true);
    if (coord > -1 && coord !== undefined) {
      // positie, create new one..
      canvasData.extension = { type: "jump", data: { coordinate: coord } };
      const dataJson = await RequestDataMaker.dataToUpdatePaperExtension_jump(system_operations, canvasData.canvasID, coord);
      await system_operations.restServerConnector.requestToUpdatePaperExtension(dataJson, []);
      if (onChangeCallback) { onChangeCallback(coord); }
    } else {
      // negative, delete old one
      delete canvasData.extension;
      const dataJson = await RequestDataMaker.dataToDeletePaperExtension(system_operations, canvasData.canvasID);
      await system_operations.restServerConnector.requestToUpdatePaperExtension(dataJson, []);
      if (onChangeCallback) { onChangeCallback(""); }
    }
    system_operations.set_loading(false);
  };

  return (
    <Container>
      <StyledTextField
        type="number"
        variant="outlined"
        value={coord}
        placeholder='Enter a coordinate...'
        onChange={handleCoordChange}
      />
      <StyledButton
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        startIcon={<StarIcon />}
      >
        JUMP!
      </StyledButton>
    </Container>
  );
};
