import React, { useState, useContext, useEffect } from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { ArrowRight } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

import HomeIcon from '@mui/icons-material/Home';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import PreviewIcon from '@mui/icons-material/Preview';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import HistoryIcon from '@mui/icons-material/History';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';

import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { MyToggle } from "../cross_page_components/ToggleSwitch.jsx";

const SideBarButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.backgroundColor.homePageUIDarker4,
  color: theme.frontColor.homePageTextPrimary,
  width: '100%',  // Set the width to 100% to fill the parent container
  height: '50px',  // Set a fixed height
  justifyContent: 'flex-start',  // Align the text and icon to the left
  '&:hover': {
    backgroundColor: theme.backgroundColor.homePageUIDarker3,  // Set the hover color to white
  },
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(1),  // Add some space between the icon and the text
  },
}));


const tabsNeedLogin = new Set();
tabsNeedLogin.add("ownBoard");
tabsNeedLogin.add("saved");
tabsNeedLogin.add("recentVisit");

const unselectedColor = { trackBK: "#333333", handleBK: "#222222", handleFront: "#505050", trackText: "#707070" }
const selectedHomeColor = { trackBK: "#60b060", handleBK: "#505050", handleFront: "#c0ffc0", trackText: "#505050" }
const selectedTrendingColor = { trackBK: "#b06015", handleBK: "#505050", handleFront: "#FF7E33", trackText: "#505050" }
const selectedSavedColor = { trackBK: "#B23737", handleBK: "#505050", handleFront: "#FF9595", trackText: "#505050" }
const selectedRecentVisitColor = { trackBK: "#6060b0", handleBK: "#505050", handleFront: "#c0c0ff", trackText: "#505050" }

const SideBar = ({ toggleDrawer, sidebarWidth }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [currentTab, setCurrentTab] = useState(system_operations.homePageManager.displayingType);

  useEffect(() => {
    system_operations.homePageManager.onDisplayTypeChange["sidebar"] = setCurrentTab;
    return () => {
      delete system_operations.homePageManager.onDisplayTypeChange["sidebar"];
    }
  }, [])

  const showTrending = async () => {
    // system_operations.set_loading(true);
    await system_operations.homePageManager.showTrending();
    // system_operations.set_loading(false);
  }

  const showSavedBoards = async () => {
    if (system_operations.loggedIn) {
      // system_operations.set_loading(true);
      await system_operations.homePageManager.showSaved(system_operations.getUserID(), true);
      // system_operations.set_loading(false);
    } else {
      system_operations.pleaseLogIn("Login now and never get lost!!");
    }
  }

  const showRecentVisitBoards = async () => {
    if (system_operations.loggedIn) {
      // system_operations.set_loading(true);
      system_operations.homePageManager.showRecentVisit(true);
      // system_operations.set_loading(false);
    } else {
      system_operations.pleaseLogIn("Login now and never get lost!!");
    }
  }

  const showUsersBoards = () => {
    if (system_operations.loggedIn) {
      system_operations.homePageManager.showOwnBoards(system_operations.getUserID(), true)
    } else {
      system_operations.pleaseLogIn("Login and get a board of your own!!");
    }
  }

  const handleClick = (tabName, currentTab, showFunction) => {
    if (tabsNeedLogin.has(tabName) && !system_operations.loggedIn) {
      system_operations.pleaseLogIn("Login now and never get lost!!");
    } else {
      if (currentTab === tabName) {
        // do nothing for now...
      } else {
        setCurrentTab(tabName);
      }
      showFunction();
      toggleDrawer(false);
    }
  }

  const theme = useTheme();
  return (
    <div style={{ padding: "10px" }}>
      <SideBarSwitch tabName={"ownBoard"} sidebarWidth={sidebarWidth} isSelected={currentTab === "ownBoard"}
        handleChange={() => { handleClick("ownBoard", currentTab, showUsersBoards) }}
        color={currentTab === "ownBoard" ? selectedHomeColor : unselectedColor}
        IconComponent={HomeOutlinedIcon} text={"My Boards"} />
      <SideBarSwitch tabName={"trending"} sidebarWidth={sidebarWidth} isSelected={currentTab === "trending"}
        handleChange={() => { handleClick("trending", currentTab, showTrending) }}
        color={currentTab === "trending" ? selectedTrendingColor : unselectedColor}
        IconComponent={LocalFireDepartmentOutlinedIcon} text={"Hot Boards"} />
      <SideBarSwitch tabName={"saved"} sidebarWidth={sidebarWidth} isSelected={currentTab === "saved"}
        handleChange={() => { handleClick("saved", currentTab, showSavedBoards) }}
        color={currentTab === "saved" ? selectedSavedColor : unselectedColor}
        IconComponent={FavoriteBorderOutlinedIcon} text={"Saved"} />
      <SideBarSwitch tabName={"recentVisit"} sidebarWidth={sidebarWidth} isSelected={currentTab === "recentVisit"}
        handleChange={() => { handleClick("recentVisit", currentTab, showRecentVisitBoards) }}
        color={currentTab === "recentVisit" ? selectedRecentVisitColor : unselectedColor}
        IconComponent={UpdateOutlinedIcon} text={"Visited"} />


      {/* <ButtonGroup
        orientation="vertical"
        variant="outlined"
        fullWidth
      >
        <SideBarButton
          startIcon={<HomeIcon sx={{ color: theme.frontColor.homePageTextPrimary }} />}
          onClick={() => { showUsersBoards(); toggleDrawer(false); }}
        >
          <Typography>My Boards</Typography>
          <ArrowRight sx={{ color: theme.frontColor.homePageTextPrimary, position: "absolute", right: 10 }} />
        </SideBarButton>
        <SideBarButton
          startIcon={<LocalFireDepartmentIcon sx={{ color: theme.frontColor.homePageTextPrimary }} />}
          onClick={() => { system_operations.homePageManager.showTrending(); toggleDrawer(false); }}
        >
          <Typography>Hot</Typography>
          <ArrowRight sx={{ color: theme.frontColor.homePageTextPrimary, position: "absolute", right: 10 }} />
        </SideBarButton>
        <SideBarButton
          startIcon={<PreviewIcon sx={{ color: theme.frontColor.homePageTextPrimary }} />}
          onClick={() => { system_operations.homePageManager.showDemos(); toggleDrawer(false); }}
        >
          <Typography>Demos</Typography>
          <ArrowRight sx={{ color: theme.frontColor.homePageTextPrimary, position: "absolute", right: 10 }} />
        </SideBarButton>
        <SideBarButton
          startIcon={<BookmarkIcon sx={{ color: theme.frontColor.homePageTextPrimary }} />}
          onClick={() => { showSavedBoards(); toggleDrawer(false); }}
        >
          <Typography>Saved</Typography>
          <ArrowRight sx={{ color: theme.frontColor.homePageTextPrimary, position: "absolute", right: 10 }} />
        </SideBarButton>
        <SideBarButton
          startIcon={<HistoryIcon sx={{ color: theme.frontColor.homePageTextPrimary }} />}
          onClick={() => { showRecentVisitBoards(); toggleDrawer(false); }}
        >
          <Typography>RecentVisited</Typography>
          <ArrowRight sx={{ color: theme.frontColor.homePageTextPrimary, position: "absolute", right: 10 }} />
        </SideBarButton>
      </ButtonGroup> */}
    </div>
  );
};


const SideBarSwitch = ({ sidebarWidth, handleChange, isSelected, IconComponent, text, color = { trackBK: "#999999", handleBK: "#707070", handleFront: "#aaaaaa", trackText: "#7A7A7A" } }) => {
  const { system_operations } = useContext(SystemOperationsContext);

  const trackWidth = sidebarWidth - 20;
  const handleSize = isSelected ? 88 : 54;
  const trackHeight = isSelected ? 94 : 60;

  let handleChildren = null;
  let trackChildren = null;
  let switchSX = {};
  let trackSX = {};
  let trackItemContainerStyle = {
    width: `${trackWidth - handleSize}px`, height: "100%", marginLeft: `${handleSize}px`,
    display: 'flex', alignItems: 'center', //justifyContent: 'center', 
  }
  if (!isSelected) {
    switchSX.backgroundColor = color.handleBK;
    trackSX.backgroundColor = color.trackBK;
    handleChildren = (<IconComponent sx={{ width: "100%", height: "100%", color: color.handleFront }} />);
    trackChildren = (
      <div style={trackItemContainerStyle}>
        <Typography sx={{ marginLeft: "10px", fontSize: "32px", color: color.trackText }}> {text} </Typography>
      </div>
    );
  } else {
    trackItemContainerStyle.marginLeft = "0px";
    switchSX.backgroundColor = color.handleBK;
    trackSX.backgroundColor = color.trackBK;
    handleChildren = (<IconComponent sx={{ width: "100%", height: "100%", color: color.handleFront }} />);
    trackChildren = (
      <div style={trackItemContainerStyle}>
        <Typography sx={{ marginLeft: "10px", fontSize: "32px", color: color.trackText }}> {text} </Typography>
      </div>
    );
  }


  return (
    <MyToggle
      checked={isSelected}
      handleChange={handleChange}
      style={{ marginTop: `${5}px`, cursor: 'pointer', userSelect: "none" }}
      trackWidth={trackWidth}
      trackHeight={trackHeight}
      switchSize={handleSize}
      switchSX={switchSX}
      trackSX={trackSX}>
      {{
        handleChildren: handleChildren,
        trackChildren: trackChildren
      }}
    </MyToggle>
  )
}

export default SideBar;
