import React, { useState, useEffect, useContext, useRef } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
// import { ImageProcessor } from '../utility/ImageProcessor.js';

import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded';
import CreateIcon from '@mui/icons-material/Create';
import TitleIcon from '@mui/icons-material/Title';
import EraserIcon from '@mui/icons-material/CallToAction';

import SettingsIcon from '@mui/icons-material/Settings';
import { FloatingCanvasEditingBar } from '../cross_page_components/TooltipBars.jsx';
import { IconButton } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/system';
import Paper from "@mui/material/Paper";
import { styled } from '@mui/system';


const HtmlTooltip = styled(React.forwardRef((props, ref) => (
  <Tooltip ref={ref} {...props} classes={{ popper: props.className }} />
)))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    maxWidth: 220,
  },
}));

export const IndieDisplayCanvas = ({ canvasRect, originalCanvas }) => {
  return (
    <div style={{ width: originalCanvas.width, height: originalCanvas.height, backgroundColor: "white" }}>
      <img src={originalCanvas.toDataURL()} style={{ width: "100%", height: "100%" }} />
    </div>
  )
}


export const IndieDrawingCanvas = ({ canvasRect, editAble, editMode, originalCanvas, setBlockTheOriginalFC }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [drawFlag, setDrawFlag] = useState(false);
  const [unsavedMarks, setUnsavedMarks] = useState(0);

  const canvasRef = useRef(null);
  const theme = useTheme();

  const canvasData = canvasRect.getData();

  useEffect(() => {
    if (canvasRef.current.width !== originalCanvas.width || canvasRef.current.height !== originalCanvas.height) {
      canvasRef.current.width = originalCanvas.width;
      canvasRef.current.height = originalCanvas.height;
    }
    return (() => {
    })
  }, [originalCanvas])


  useEffect(() => {
    const mouseStart = (event) => { drawEventMouse(event, 0) };
    const touchStart = (event) => { drawEventTouch(event, 0) };
    const mouseMove = (event) => { drawEventMouse(event, 1) };
    const touchMove = (event) => { drawEventTouch(event, 1) };
    const mouseEnd = (event) => { drawEventMouse(event, 2) };
    const touchEnd = (event) => { drawEventTouch(event, 2) };

    setBlockTheOriginalFC(true);
    canvasRef.current.addEventListener('mousedown', mouseStart);
    canvasRef.current.addEventListener('touchstart', touchStart);
    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('touchmove', touchMove);
    window.addEventListener('mouseup', mouseEnd);
    window.addEventListener('touchend', touchEnd);

    return () => {
      setBlockTheOriginalFC(false);
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('touchmove', touchMove);
      window.removeEventListener('mouseup', mouseEnd);
      window.removeEventListener('touchend', touchEnd);
      if (canvasRef.current) {
        canvasRef.current.removeEventListener('mousedown', mouseStart);
        canvasRef.current.removeEventListener('touchstart', touchStart);
      }
    };
  }, [drawFlag])

  useEffect(() => {
    canvasRect.utilities.fcDraw.addToOnDrawMarksCountChange("FC_focus", setUnsavedMarks);
    setUnsavedMarks(canvasRect.utilities.fcDraw.drawDataAll.length);

    return ()=>{
      canvasRect.utilities.fcDraw.removeFromOnDrawMarksCountChange("FC_focus", setUnsavedMarks);
    }
  }, [])

  const getCoords = (x, y) => {
    if (!canvasRef.current) return [0, 0];
    const rect = canvasRef.current.getBoundingClientRect();
    const scaleX = canvasRef.current.width / rect.width;
    const scaleY = canvasRef.current.height / rect.height;
    const coordX = (x - rect.left) * scaleX;
    const coordY = (y - rect.top) * scaleY;
    return [coordX, coordY];
  }

  const drawEventMouse = (event, step) => {
    if (step === 1) {
      canvasRect.utilities.fcDraw.drawContinue(event.clientX, event.clientY);
    } else if (step === 0) {
      canvasRect.utilities.fcDraw.drawStart(event.clientX, event.clientY);
    } else {
      canvasRect.utilities.fcDraw.drawEnd();
    }
  }
  const drawEventTouch = (event, step) => {
    if (step === 1) {
      canvasRect.utilities.fcDraw.drawContinue(event.touches[0].clientX, event.touches[0].clientY);
    } else if (step === 0) {
      canvasRect.utilities.fcDraw.drawStart(event.touches[0].clientX, event.touches[0].clientY);
    } else {
      canvasRect.utilities.fcDraw.drawEnd();
    }
  }

  const checkClick = () => {
    const toolName = system_operations.coordinator.toolpack.get_current_tool().name;
    if (toolName === "text") {
      system_operations.coordinator.setEventFloatingCanvasRect(canvasRect);
    }
  }

  const preventingDefault = useRef(false);
  const touchEventStart = (event) => {
    if (!system_operations.coordinator.toolpack.get_current_tool().normalHandBehavior) {
      event.preventDefault();
      preventingDefault.current = true;
    } else {
      preventingDefault.current = false;
    }
  }

  useEffect(() => {
    /* 
    window.addEventListener('mousemove', eventMove);
    window.addEventListener('mouseup', eventEnd);

    return (() => {
      window.removeEventListener('mousemove', eventMove);
      window.removeEventListener('mouseup', eventEnd);
      
    })*/
    const eventDragStart = (event) => {
      if (!system_operations.coordinator.toolpack.get_current_tool().normalHandBehavior) {
        event.preventDefault();
      }
    }

    const eventMove = (event) => {
      if (!preventingDefault.current) {
        event.preventDefault();
      }
    }
    const eventEnd = (event) => {
      preventingDefault.current = false;
    }
    
    window.addEventListener('dragstart', eventDragStart);
    canvasRef.current.addEventListener('touchstart', touchEventStart, { passive: false });
    window.addEventListener('touchmove', eventMove, { passive: false });
    window.addEventListener('touchend', eventEnd, { passive: false });

  return () => {
    if (canvasRef.current) {
      canvasRef.current.removeEventListener('touchstart', touchEventStart, { passive: false });
    }
    
    window.removeEventListener('dragstart', eventDragStart);
    window.removeEventListener('touchmove', eventMove, { passive: false });
    window.removeEventListener('touchend', eventEnd, { passive: false });
  }
  }, [])

  const layer = Math.max(Math.min(canvasData.layer, system_operations.coordinator.totalFloatingLayers - 1), 0);
  const scale = system_operations.coordinator.theoreticalSpaceToScreenSpace.bind(system_operations.coordinator);
  return (
    <HtmlTooltip
      open={true}
      title={
        <IndieToolBar />
      } placement="top-start" PopperProps={{
        modifiers: [{
          options: {
            offset: [-10, -15], // Moves Tooltip 10px along main axis
          }, name: 'offset',
        }]
      }}>
      <HtmlTooltip title={
        <FloatingCanvasEditingBar canvasRect={canvasRect} undo={canvasRect.utilities.fcDraw.undo.bind(canvasRect.utilities.fcDraw)} redo={canvasRect.utilities.fcDraw.redo.bind(canvasRect.utilities.fcDraw)}
          submit={canvasRect.utilities.fcDraw.updateImage.bind(canvasRect.utilities.fcDraw)} giveup={canvasRect.utilities.fcDraw.clear.bind(canvasRect.utilities.fcDraw)} />
      } placement="bottom-end"
        open={unsavedMarks !== 0}
        PopperProps={{
          modifiers: [{
            options: {
              offset: [10, - 15], // Moves Tooltip 10px along main axis
            }, name: 'offset',
          }]
        }}>
        <div style={{ width: `${originalCanvas.width}px`, height: `${originalCanvas.height}px` }}>
          <Paper
            sx={{ position: "absolute", width: `100%`, height: `100%`, userSelect: "none", overflow: 'hidden', ...canvasData.style }}
            onMouseUp={checkClick} onTouchEnd={checkClick}
          >
            <canvas ref={canvasRef} style={{ position: "relative", left: "0px", top: "0px", width: `100%`, height: `100%` }} width={originalCanvas.width} height={originalCanvas.height}/>
          </Paper>
        </div>
      </HtmlTooltip >
    </HtmlTooltip>
  )
}

const IndieToolBar = () => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [currentTool, setCurrentTool] = useState(system_operations.coordinator.toolpack.get_current_tool());
  const theme = useTheme();

  const selectTool = (toolName) => {
    system_operations.coordinator.toolpack.changeToolByName(toolName);
    setCurrentTool(system_operations.coordinator.toolpack.get_current_tool());
  }

  return (
    <div style={{
      display: 'inline-flex', alignItems: 'center', paddingRight: 5,
      backgroundColor: theme.backgroundColor.wallPageUIBase, borderRadius: theme.borderRadius.wallPageUI,
    }}>
      <IconButton color={currentTool.name === "brush" ? "primary" : "third"} onClick={() => { selectTool("hand") }}>
        <PanToolRoundedIcon />
      </IconButton>
      <IconButton color={currentTool.name === "brush" ? "primary" : "third"} onClick={() => { selectTool("brush") }}>
        <CreateIcon />
      </IconButton>
      <IconButton color={currentTool.name === "eraser" ? "primary" : "third"} onClick={() => { selectTool("eraser") }}>
        <EraserIcon />
      </IconButton>
      {/* <IconButton color={currentTool.name === "text" ? "primary" : "third"} onClick={() => { selectTool("text") }}>
        <TitleIcon />
  </IconButton> */}
      {currentTool.renderToolControlPanel()}
      {/*<HtmlTooltip title={
        <div style={{ paddingTop: 3, paddingBottom: 3, backgroundColor: theme.backgroundColor.wallPageUIBase, borderRadius: theme.borderRadius.wallPageUI }}>
          {currentTool.renderToolControlPanel()}
        </div>
      } placement="right-end" open={true}>
        <IconButton color={currentTool.name === "text" ? "primary" : "third"} onClick={() => { }}>
          <SettingsIcon />
        </IconButton>
    </HtmlTooltip>*/}
    </div>
  );
}