import { FirestoreActions } from "../auth/FirestoreActions";

export class GridsSections {
  constructor(system) {
    this.system = system;
    this.coordinator = null;
    this.imageProcessor = null;
    this.boardData = {};
    this.sectionNames = []

    this.emptyColumn = null;

    this.imagesData = {};
    this.urlSections = {};
    this.backupUrlSections = {};

    this.urlSectionsPromises = {}; // when fetch the url of a section, put it in urlSectionsPromise first.
    this.backupUrlSectionsPromises = {}; // when fetch the url of a section, put it in urlSectionsPromise first.
    this.imagesDataPromises = {};

    this.onUrlDoneLoading = {};

    this.rerenderHook = () => { };
  }

  async initialize(boardData) {
    this.coordinator = this.system.coordinator;
    this.imageProcessor = this.coordinator.imageProcessor;
    this.boardData = boardData;
    this.sectionNames = [] // might not initialized

    this.sectionNames = (await this.system.firestoreActions.fetchFromFirestore(`boardsSectionsData/${this.boardData.wallID}`, "boardID")).sectionNames;
  }

  getSectionDataFromGridIndex(rowGlobal, columnGlobal) {
    const sectionIndex = Math.floor((this.boardData.isHorizontal ? columnGlobal : rowGlobal) / this.boardData.sectionSize);
    const sectionID = this.sectionNames[sectionIndex];
    const row = this.boardData.isHorizontal ? rowGlobal : rowGlobal % this.boardData.sectionSize;
    const column = this.boardData.isHorizontal ? columnGlobal % this.boardData.sectionSize : columnGlobal;
    return { sectionID, row, column };
  }

  generateEmptyColumnData(width, height) {
    let canvas = document.createElement('canvas');
    canvas.width = width; canvas.height = height;
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL('image/png');
    return dataUrl;
  }

  getDefaultPNG() {
    if (!this.emptyColumn) {
      this.emptyColumn = this.generateEmptyColumnData(this.boardData.gridWidth, this.boardData.gridHeight);
    }
    return this.emptyColumn;
  }

  getGridGloballyAt(rowGlobal, columnGlobal) {
    const sectionData = this.getSectionDataFromGridIndex(rowGlobal, columnGlobal);
    return this.getGridAt(sectionData.sectionID, sectionData.row, sectionData.column, rowGlobal, columnGlobal);
  }

  getGridGloballyAt_quick(rowGlobal, columnGlobal, assignImageFunc) {
    const sectionData = this.getSectionDataFromGridIndex(rowGlobal, columnGlobal);
    return this.getGridAt_quick(sectionData.sectionID, sectionData.row, sectionData.column, rowGlobal, columnGlobal, assignImageFunc);
  }

  removeGridGloballyAt(rowGlobal, columnGlobal, reload = false) {
    const sectionData = this.getSectionDataFromGridIndex(rowGlobal, columnGlobal);
    this.removeGridAt(sectionData.sectionID, sectionData.row, sectionData.column, reload);
  }

  createImageObject(globalRow, globalColumn, url) {
    const self = this; // get the class object ref.
    const imageObject = {
      row: globalRow,
      column: globalColumn,
      url: url,
      ref: { current: null },
      changed: false, // weather the grid updated and url hasn't update the version.
      setUrl: null,
      getDataUrl: async() => {return await self.getDataUrlFromImageData(imageObject)}
    }
    return imageObject;
  }

  // assignImageFunc(data)

  // this return nothing. this uses callback to feed the value back.
  // this is usually quicker cause it can just get the url.
  getGridAt_quick(sectionID, row, column, rowGlobal, columnGlobal, assignImageFunc) {
    let imageObject = this.getCachedGrid(sectionID, row, column)
    if (imageObject) {
      if (assignImageFunc) assignImageFunc(imageObject);
    } else {
      const fullGridName = `${sectionID}_${row}_${column}`
      const imageID = `${row}_${column}`;
      if (this.urlSections[sectionID] && this.urlSections[sectionID][imageID]) {
        const hasUrl = this.urlSections[sectionID][imageID] !== "none" && this.urlSections[sectionID][imageID] !== undefined
        const newImageObject = this.createImageObject(rowGlobal, columnGlobal, this.urlSections[sectionID][imageID]);
        if (assignImageFunc) assignImageFunc(newImageObject); // give the url first.
        if (this.imagesDataPromises[fullGridName] instanceof Promise) {
          // after it's fully done, then we need a callback.
          this.imagesDataPromises[fullGridName].then(() => {
            if (assignImageFunc) assignImageFunc(this.imagesData[sectionID][row][column]);
          })
        } else {
          this.getGridAt(sectionID, row, column, rowGlobal, columnGlobal).then(() => {
            if (assignImageFunc) assignImageFunc(this.imagesData[sectionID][row][column]);
          })
        }
      } else {
        // has no url
        this.insertToOnUrlSectionLoaded(fullGridName, (urlDict) => {
          const hasUrl = this.urlSections[sectionID][imageID] !== "none" && this.urlSections[sectionID][imageID] !== undefined
          const newImageObject = this.createImageObject(rowGlobal, columnGlobal, this.urlSections[sectionID][imageID]);
          if (assignImageFunc) assignImageFunc(newImageObject);
        })
        this.getGridAt(sectionID, row, column, rowGlobal, columnGlobal).then(() => {
          if (assignImageFunc) assignImageFunc(this.imagesData[sectionID][row][column]);
        });
      }
    }
  }

  // this is directly called if you must need a result after the get function
  // first time running this on a grid, this will try load the urlSection and image data of that grid
  // afterward running this section, it will only await for the previous promise to finish.
  async getGridAt(sectionID, row, column, globalRow, globalColumn) {
    let imageObject = this.getCachedGrid(sectionID, row, column);
    if (!imageObject) {
      const fullGridName = `${sectionID}_${row}_${column}`
      if (!this.imagesDataPromises[fullGridName]) {
        const fetchProcess = async () => {
          let imagePieceUrl = await this.getImageUrl(sectionID, row, column);
          /* let dataUrl = "";
          if (imagePieceUrl !== "none") {
            dataUrl = await this.loadImageData(imagePieceUrl);
          } else {
            dataUrl = this.getDefaultPNG();
          } */
          imageObject = this.createImageObject(globalRow, globalColumn, imagePieceUrl);

          if (!this.imagesData[sectionID]) { this.imagesData[sectionID] = {} }
          if (!this.imagesData[sectionID][row]) { this.imagesData[sectionID][row] = {}; }
          this.imagesData[sectionID][row][column] = imageObject;
        }
        this.imagesDataPromises[fullGridName] = fetchProcess();
      } // else the promise already exist, so we should just wait for it...
      await this.imagesDataPromises[fullGridName];
    }
    return imageObject;
  }

  getCachedGridGloballyAt(rowGlobal, columnGlobal) {
    const sectionData = this.getSectionDataFromGridIndex(rowGlobal, columnGlobal);
    return this.getCachedGrid(sectionData.sectionID, sectionData.row, sectionData.column);
  }

  getCachedGrid(sectionID, row, column) {
    let imageData = null;
    // to change to Concatenated String Keys, need to change the getGridAt() That's the only writer to this.imagesData. also tryGetUrlOrImageData, cause it's also a reader.
    if (sectionID in this.imagesData) {
      if (row in this.imagesData[sectionID]) {
        if (column in this.imagesData[sectionID][row]) {
          // exist and not changed.
          imageData = this.imagesData[sectionID][row][column];
        }
      }
    }
    return imageData;
  }

  // first, try get from the cached section.
  // if can't find, then fetch from the firestore, and store the result in cached section.
  // return the image url. if there is no the corresponding entry, then means it doesn't exist. return "none"
  // note: first time running this on a grid, this will try load the urlSection and image data of that grid, afterward running this section, it will only await for the previous promise to finish.
  getImageUrl = (sectionID, row, column) => {
    const imageID = `${row}_${column}`
    if (this.urlSections[sectionID] && this.backupUrlSections[sectionID]) {
      // check if both section is fetched for the url.
      if (imageID in this.urlSections[sectionID]) {
        // this can return "none"
        return this.urlSections[sectionID][imageID];
      } else {
        return "none"
      }
    }
    // else, return the promise to fetch for the image.
    return new Promise(async (resolve, reject) => {
      // section will be a dict contains each "row_column" as a field.

      if (!this.urlSectionsPromises[sectionID]) { // no fetching promise yet.
        const fetchingSectionUrl = async () => {
          const sectionUrls = await this.system.firestoreActions.fetchFromFirestore(`boardsSectionsData/${this.boardData.wallID}/sections/${sectionID}`, "boardID");
          if (!sectionUrls) {
            this.urlSections[sectionID] = {}; // nothing is in this section. then do nothing.
          } else {
            this.urlSections[sectionID] = sectionUrls;
          }

          this.triggerOnUrlSectionLoaded(sectionID);

          return this.urlSections[sectionID];
        }
        const fetchSectionPromise = fetchingSectionUrl();
        this.urlSectionsPromises[sectionID] = fetchSectionPromise;
      }

      if (!this.backupUrlSectionsPromises[sectionID]) { // no backup fetching promise yet.
        const fetchingBackupSectionUrl = async () => {
          const backupSectionUrls = await this.system.firestoreActions.fetchFromFirestore(`boardsSectionsData/${this.boardData.wallID}/sections/${sectionID}_backup`, "boardID");
          if (!backupSectionUrls) {
            this.backupUrlSections[sectionID] = {};
          } else {
            this.backupUrlSections[sectionID] = backupSectionUrls;
          }
          return this.backupUrlSections[sectionID];
        }
        const fetchSectionBackupPromise = fetchingBackupSectionUrl();
        this.backupUrlSectionsPromises[sectionID] = fetchSectionBackupPromise;
      }

      // both url section is fetched before continue.
      await Promise.all([this.urlSectionsPromises[sectionID], this.backupUrlSectionsPromises[sectionID]]);

      if (imageID in this.urlSections[sectionID]) {
        resolve(this.urlSections[sectionID][imageID]);
      } else {
        resolve("none");
      }
    });
  };

  async getDataUrlFromImageData(imageData) {
    if (imageData.getDataUrlFromRef) { // if the dataUrl override exist, then we should just use it.
      const dataUrl = await imageData.getDataUrlFromRef();
      if (dataUrl) {
        return dataUrl
      }
    }
    if (imageData.url && imageData.url !== "none") {
      return await this.loadImageData(imageData.url);
    } else {
      return this.getDefaultPNG();
    }
  }

  async loadImageData(url) {
    try {
      return await this.imageProcessor.loadImageData(url);
    } catch (err) {
      return this.getDefaultPNG();
    }
  }

  // brutally update the dataUrl using urlOverride method.
  updateGridGloballyAt(rowGlobal, columnGlobal, newDataUrl) {
    const sectionData = this.getSectionDataFromGridIndex(rowGlobal, columnGlobal);
    this.updateGridAt(sectionData.sectionID, sectionData.row, sectionData.column, newDataUrl)
  }

  updateGridAt(sectionID, row, column, newDataUrl) {
    if (sectionID in this.imagesData) {
      if (row in this.imagesData[sectionID]) {
        if (column in this.imagesData[sectionID][row]) {
          // this.imagesData[sectionID][row][column].dataUrl = newDataUrl;
          if (this.imagesData[sectionID][row][column].setUrl) {
            this.imagesData[sectionID][row][column].changed = true; // the url should be refetched when next time load this image.
            this.imagesData[sectionID][row][column].setUrl(newDataUrl); // set imageData...
          }
          this.rerenderHook();
        }
      }
    }
  }

  // update the url version to force a refetch.
  updateGridUrlVersionGloballyAt(rowGlobal, columnGlobal) {
    const sectionData = this.getSectionDataFromGridIndex(rowGlobal, columnGlobal);
    this.updateGridUrlVersionAt(sectionData.sectionID, sectionData.row, sectionData.column)
  }

  updateGridUrlVersionAt(sectionID, row, column) {
    if (sectionID in this.imagesData) {
      if (row in this.imagesData[sectionID]) {
        if (column in this.imagesData[sectionID][row]) {
          this.imagesData[sectionID][row][column].url = `${this.imagesData[sectionID][row][column].url}?version=${new Date().getTime()}`; // set the Default url...
          if (this.imagesData[sectionID][row][column].setUrl) {
            this.imagesData[sectionID][row][column].changed = true;
            this.imagesData[sectionID][row][column].setUrl(null); // set imageData...
          }
          this.rerenderHook();
        }
      }
    }
  }

  removeGridAt(sectionID, row, column, reload = false) {
    const imageID = `${row}_${column}`;
    if (this.imagesData[sectionID] && this.imagesData[sectionID][imageID]) {
      delete this.imagesData[sectionID][imageID];
    }
  }

  insertToOnUrlSectionLoaded(sectionID, func) {
    if (!this.onUrlDoneLoading[sectionID]) { this.onUrlDoneLoading[sectionID] = [] }
    this.onUrlDoneLoading[sectionID].push(func);
  }

  triggerOnUrlSectionLoaded(sectionID) {
    if (this.onUrlDoneLoading[sectionID]) {
      for (const func of this.onUrlDoneLoading[sectionID]) {
        func(this.urlSections[sectionID]);
      }
      delete this.onUrlDoneLoading[sectionID];
    }
  }
}

// this class doesn't have the functionality to handle verticle board.
export class GridsDisplay {
  constructor(coordinator) {
    this.coordinator = coordinator;
    this.gridsSections = coordinator.gridsSections;
    this.displayingGrids = {} // ["row_column"]

    this.currentMinIndex = 0;
    this.currentMaxIndex = 0;

    this.rerenderHook = () => { };
  }

  getDisplayingAtCoordinate() {
    const newMinIndex = Math.max(this.coordinator.current_index - this.coordinator.preCoordViewImages, 0);
    const newMaxIndex = Math.min(this.coordinator.current_index + this.coordinator.afterCoordViewImages, this.coordinator.totalColumns - 1);

    /* const minMargin = newMinIndex - this.currentMinIndex; // greater than 0, then remove this much margins, less than 0, then means add this much margin
    const maxMargin = newMaxIndex - this.currentMaxIndex; // greater than 0, then add this much margins, less than 0, then means remove this much margin

    if (this.coordinator.isHorizontal) {
      if (minMargin > 0) {
        this.fixedRowColumnLoop(this.currentMinIndex, newMinIndex, this.removeGrids.bind(this));
      } else if (minMargin < 0) {
        this.fixedRowColumnLoop(newMinIndex, this.currentMinIndex, this.addGrids.bind(this));
      }

      if (maxMargin > 0) {
        this.fixedRowColumnLoop(this.currentMaxIndex, newMaxIndex, this.addGrids.bind(this));
      } else if (maxMargin < 0) {
        this.fixedRowColumnLoop(newMaxIndex, this.currentMaxIndex, this.removeGrids.bind(this));
      }
    } //else {}

    this.currentMinIndex = newMinIndex;
    this.currentMaxIndex = newMaxIndex;*/

    this.displayingGrids = {};
    this.fixedRowColumnLoop(newMinIndex, newMaxIndex, this.addGrids.bind(this));
    this.rerenderHook();
  }

  fixedRowColumnLoop(columnStartIndex, columnEndIndex, operation) {
    for (let row = 0; row < this.coordinator.totalRows; row++) {
      for (let column = columnStartIndex; column <= columnEndIndex; column++) {
        operation(row, column);
      }
    }
  }

  addGrids(row, column) {
    const imageID = `${row}_${column}`
    const alterTheGrid = (data) => {
      // only write back if the grid is still displaying...
      if (imageID in this.displayingGrids) {
        this.displayingGrids[imageID] = data;
      }
      this.rerenderHook();
    }
    this.displayingGrids[imageID] = this.createDefaultImageData(row, column, this.gridsSections.getDefaultPNG(), false);
    this.gridsSections.getGridGloballyAt_quick(row, column, alterTheGrid);
  }

  removeGrids(row, column) {
    const imageID = `${row}_${column}`
    if (imageID in this.displayingGrids) {
      delete this.displayingGrids[imageID];
    }
  }

  createDefaultImageData(row, column, data, isUrl) {
    // for now, just display "none"
    return this.gridsSections.createImageObject(row, column, this.gridsSections.getDefaultPNG());
  }
}


