export class WallPageManager {
  constructor() {
    this.currentUserCard = null;
    this.stickerCreationPageOn = false;

    this.setHasPopPageHook = null;
    this.setStickerCreationPageHook = null;
    this.setUserCardHook = null;
    this.onPopPageChange = {};
    this.onStickerCreationPageChange = {};
    this.onUserCardChange = {};
  }

  private_setPopPage(hasPopPage = false) {
    if (this.setHasPopPageHook) {
      this.setHasPopPageHook(hasPopPage);
      for (const key in this.onPopPageChange) {
        this.onPopPageChange[key](hasPopPage);
      }
    }
  }

  setDisplayStickerCreationPage(turnOn) {
    if (this.setStickerCreationPageHook) {
      this.setStickerCreationPageHook(turnOn);
      this.private_setPopPage(turnOn);
      this.stickerCreationPageOn = turnOn;
      for (const key in this.onStickerCreationPageChange) {
        this.onStickerCreationPageChange[key](turnOn);
      }
    }
  }

  setDisplayUserCard(user = null) {
    if (this.setUserCardHook) {
      this.setUserCardHook(user);
      this.private_setPopPage(user !== null);
      this.currentUserCard = user;
      for (const key in this.onUserCardChange) {
        this.onUserCardChange[key](user);
      }
    }
  }

  assignSetHasPopPageHook(setHasPopPageHook) {
    this.setHasPopPageHook = setHasPopPageHook;
  }

  addToOnPopPageChange(key, func) {
    this.onPopPageChange[key] = func;
  }


  assignSetStickerCreationPageHook(setStickerCreationPageHook) {
    this.setStickerCreationPageHook = setStickerCreationPageHook;
  }

  addToOnStickerCreationPageChange(key, func) {
    this.onStickerCreationPageChange[key] = func;
  }


  assignSetUserCardHook(setUserCardHook) {
    this.setUserCardHook = setUserCardHook;
  }

  addToOnUserCardChange(key, func) {
    this.onUserCardChange[key] = func;
  }
}