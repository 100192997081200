import React, { useMemo, useRef, useEffect, useContext, useState } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { FlexRect } from '../cross_page_components/FlexRectangles';
import { ActionButtonControlPanel } from '../components/ActionButtonControlPanel.jsx';
import { ActionButtonReactionBar } from '../cross_page_components/TooltipBars.jsx';
import { PaperRects } from '../system/PaperRects.js';

import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/system';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import zIndex from '@mui/material/styles/zIndex';

import Color from 'color';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    maxWidth: 220,
  },
}));

const ButtonCanvases = ({ }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [displayScale, setDisplayScale] = useState(system_operations.coordinator.getTheoreticalSpaceToScreenSpaceRatio());
  const [rectsEditMode, setRectsEditMode] = useState(false);
  const placed = useRef(false);

  const theme = useTheme();

  const [allButtonsRects, setAllButtonsRects] = useState(system_operations.coordinator.currentDisplayingCanvases)
  const newCanvasStyle = useRef({ x: 0, y: 0, width: 128, height: 128, borderRadius: 5 });
  const fenceByPassRule = useMemo(() => {
    return (fenceCollisionRect) => {
      const fence = fenceCollisionRect?.dataRef;
      if (!fence) {
        return false;
      }
      const allowAddingCanvas = system_operations.fenceCoder.isFlagSetByName(fence.rules, "addingButtonOther");
      if (fence.creatorID === system_operations.getUserID() || allowAddingCanvas) {
        return true;
      } else {
        return false;
      }
    }
  }, []);


  useEffect(() => {
    system_operations.coordinator.onButtonsChange["fromButtonsCanvas"] = setAllButtonsRects;
    system_operations.coordinator.onTheoreticalSpaceToScreenSpaceScaleChange["fromButtonsCanvas"] = setDisplayScale;
    system_operations.coordinator.toolpack.onToolChange["fromButtonsCanvas"] = (newTool) => {
      if (newTool.name === "ab") {
        newTool.setMode(0);
        setRectsEditMode(true);
      } else {
        system_operations.coordinator.toolpack.getToolByName("ab").setMode(0);
        setRectsEditMode(false);
      }
    };

    const openButtonCreationPanel = () => {
      system_operations.setContentWindow(<ActionButtonControlPanel fenceByPassRule={fenceByPassRule} />)
    }
    const startEditingMode = () => {
      system_operations.coordinator.guideBox.setActive(false);
      setRectsEditMode(true);
    }
    const ABEditor = system_operations.coordinator.toolpack.getToolByName("ab");
    ABEditor.startCreationHook = openButtonCreationPanel;
    ABEditor.startEditingHook = startEditingMode;

    return () => {
      delete system_operations.coordinator.onButtonsChange["fromButtonsCanvas"];
      delete system_operations.coordinator.onTheoreticalSpaceToScreenSpaceScaleChange["fromButtonsCanvas"];
      delete system_operations.coordinator.toolpack.onToolChange["fromButtonsCanvas"];
    }
  }, []);

  return (
    <div style={{ zIndex: theme.zIndex.paper }}>
      {allButtonsRects.map((buttonRect, index) => {
        return (
          <ButtonCanvasCoreWrapper buttonRect={buttonRect} rectsEditMode={rectsEditMode} fenceByPassRule={fenceByPassRule} displayScale={displayScale} index={index} theme={theme} key={buttonRect.getID()} />
        )
      })}
    </div >
  );
}


const nullFunc = () => { }
const ButtonCanvasCoreWrapper = ({ buttonRect, rectsEditMode, fenceByPassRule, displayScale, index, theme }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [interactable, setInteractable] = useState(buttonRect.interactable);
  const [openType, setOpenType] = useState(0); // 0, closed, 1: normal likes, 2: editing
  const idTagName = useMemo(() => { return `data-ab-${index}` }, [index]);
  const [layer, setLayer] = useState(buttonRect.getActiveLayer());

  const refreshCounter = useRef(0);
  const containerRef = useRef(null);
  const [refresh, setRefresh] = useState(0);

  buttonRect.onLayerChange["fcBase"] = (layer) => {
    layer = Math.max(Math.min(layer, system_operations.coordinator.totalFloatingLayers - 1), 0);
    setLayer(layer);
  }

  const [handleTooltipClose, handleTooltipOpen] = useMemo(() => {
    const handleTooltipClose = (event) => {
      if (!event || !event.target.getAttribute(idTagName)) {
        setOpenType(0);
      }
    };

    const handleTooltipOpen = () => {
      const currentToolName = system_operations.coordinator.toolpack.get_current_tool().name;
      if (currentToolName === "hand") { // Replace `someCondition` with your actual condition
        setOpenType(1);
      } else if (currentToolName === "ab") {
        setOpenType(2);
      }
    };

    buttonRect.hoverEffects["tooltipOpen"] = (on) => {
      if (on) {
        handleTooltipOpen();
      } else {
        handleTooltipClose();
      }
    };
    return [handleTooltipClose, handleTooltipOpen]
  }, [buttonRect])

  useEffect(() => {
    buttonRect.func.styleRefreshPaperFullComponent = () => {
      refreshCounter.current += 1;
      setRefresh(refreshCounter.current);
    }
    return () => {
      delete buttonRect.func.styleRefreshPaperFullComponent;
    }
  }, [buttonRect])

  const rectEditable = (canvas) => {
    if (rectsEditMode && system_operations.getUserID() === canvas.creatorID) {
      return true;
    }
    return false;
  }

  const focusOnCanvas = () => {
    if (buttonRect.func.focusOnCanvas) {
      buttonRect.func.focusOnCanvas();
    }
  }

  buttonRect.func.setInteractable = (allow = true) => {
    buttonRect.interactable = allow;
    setInteractable(allow);
  }

  const editAble = rectEditable(buttonRect.getData());
  return (
    <div ref={containerRef}>
      <FlexRect
        dataRect={buttonRect}
        lookStyle={null}
        defaultCollisions={system_operations.coordinator.getButtonCollectionSet(layer, fenceByPassRule)}
        scale={displayScale}
        zIndex={theme.zIndex.button[layer]} activeDz={theme.zIndex.paper_dz.dp_1} inactiveDz={theme.zIndex.paper_dz.dp_0}
        flexBoxID={`canvas_${buttonRect.getID()}`}
        editable={editAble}
        interactable={interactable}
        maxWidth={192} maxHeight={192} minWidth={64} minHeight={64}
        type={"canvas"}
        getAllMagnetRects={() => { return system_operations.coordinator.getAllCurrentUtilityPapers() }}
        key={buttonRect.getID()}>
        <HtmlTooltip
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
          open={(openType !== 0) || !interactable}
          enterTouchDelay={0}
          leaveTouchDelay={1500}
          title={
            <ActionButtonReactionBar buttonRect={buttonRect} editingMode={rectsEditMode} focusOnMode={focusOnCanvas} />
          } placement="top-start" PopperProps={{
            modifiers: [{
              options: {
                offset: [-10, -15], // Moves Tooltip 10px along main axis
              }, name: 'offset',
            }]
          }}>
          <div>
            <ActionButtonCore buttonRect={buttonRect} editAble={editAble} editMode={rectsEditMode} layer={layer} />
          </div>
        </HtmlTooltip>
      </FlexRect>
    </div>
  );
}

const ActionButtonCore = ({ buttonRect, editAble, editMode, layer }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const refreshCounter = useRef(0);
  const [refresh, setRefresh] = useState(0);

  const theme = useTheme();

  const buttonData = buttonRect.getData();



  buttonRect.func.styleRefreshCanvasCore = () => {
    refreshCounter.current += 1;
    setRefresh(refreshCounter.current);
  }

  const scale = system_operations.coordinator.boardPositioner.scaleToScr.bind(system_operations.coordinator.boardPositioner);

  const onClick = () => {
    if (!system_operations.coordinator.toolpack.rectEditingMode) {
      const buttonData = buttonRect.getData();
      if (buttonData.functionality === "jump") {
        system_operations.coordinator.setGlobalOffsetTheo(buttonData.functionData, true);
      } else if (buttonData.functionality === "link") {
        const url = buttonData.functionData;
        try {
          new URL(url);
          if (url) {
            window.open(url, '_blank');
          }
        } catch (_) {
          // broken url
        }
      }
    }
  }

  let normalBoarderColor = buttonRect.getActiveStyle("borderColor");
  if (!normalBoarderColor) {
    normalBoarderColor = "rgb(0, 0, 0)"
  }
  return (
    <div style={{ zIndex: theme.zIndex.paper_dz.m_0 }}>
      {editMode &&
        (editAble ? (<div style={{
          position: "absolute", width: `100%`, height: `100%`, userSelect: "none", zIndex: theme.zIndex.paper_dz.m_2,
          border: `${5}px dashed ${theme.flexRects.flexButtonRectBoarderColorsAllow[layer]}`, borderRadius: `${5}px`, boxSizing: 'border-box'
        }} />) : (<div style={{
          position: "absolute", width: `100%`, height: `100%`, userSelect: "none", zIndex: theme.zIndex.paper_dz.m_2,
          border: `${5}px dashed ${theme.flexRects.flexButtonRectBoarderColorsDisallow[layer]}`, borderRadius: `${5}px`, boxSizing: 'border-box'
        }} />))
      }
      <HtmlTooltip title={
        buttonData.description ?
          <div className="float-container">
            <Typography>
              {buttonData.description}
            </Typography>
          </div> : null
      }
        placement="bottom" >
        <Button
          style={{
            position: "absolute", width: `100%`, height: `100%`, userSelect: "none", boxSizing: 'border-box', border: `${scale(5)}px solid ${normalBoarderColor}`,
            zIndex: theme.zIndex.paper_dz.m_1, overflow: 'hidden', backgroundColor: buttonRect.getActiveColor()
          }}
          onClick={onClick}
        >
          <Typography>
            {buttonData.title}
          </Typography>
        </Button>
      </HtmlTooltip>
    </div>
  )
}

export default ButtonCanvases;