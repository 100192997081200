import React, { useState, useContext, useRef, useEffect, useMemo } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import { UserShortCardToolTip } from "../components/UserDisplay";
import { PostDisplayAndUtility } from "../system/PostDisplayAndUtility";
import { RequestDataMaker } from "../system/RestServerConnector.js"

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip, Avatar, Divider } from '@mui/material';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import './styles/FenceLines.scss';

import EditOffRoundedIcon from '@mui/icons-material/EditOffRounded';
import ContentPasteOffRoundedIcon from '@mui/icons-material/ContentPasteOffRounded';
import SubtitlesOffRoundedIcon from '@mui/icons-material/SubtitlesOffRounded';


const baseColor = 'rgba(128, 128, 128, 0.7)';
const secondaryTextColor = "rgba(225, 225, 225, 1)";
const borderRadius = '10px';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    backgroundColor: baseColor,
    padding: theme.spacing(2),
    borderRadius,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(10px)',
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    maxWidth: 220,
  },
}));

const FenceLines = React.memo(({ displayOffset, fences }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const nullFence = useRef({ creatorID: "none", start: 0, end: 0, createdDate: 0, protectLevel: 0 });
  const canRemove = useRef(false);
  const fenceCreateDate = useRef("9/12/2000");
  const [fenceCreatorUserData, setFenceCreatorUserData] = useState({ username: "", avatar: "none" });
  const [fenceOpened, setFenceOpened] = useState(false);
  const [currentFence, setCurrentFence] = useState(nullFence.current);

  const readFenceRule = useMemo(() => {
    return system_operations.fenceCoder;
  }, []);
  const [hasChanges, setHasChanges] = useState(false);
  const [allowOtherToDraw, setAllowOtherToDraw] = useState(false);
  const [allowOtherToAddCanvas, setAllowOtherToAddCanvas] = useState(false);
  const [allowOtherToPost, setAllowOtherToPost] = useState(false);

  useEffect(() => {
    setAllowOtherToDraw(readFenceRule.isFlagSetByName(currentFence.rules, "drawingOther"));
    setAllowOtherToAddCanvas(readFenceRule.isFlagSetByName(currentFence.rules, "addingCanvasOther"));
    setAllowOtherToPost(readFenceRule.isFlagSetByName(currentFence.rules, "postingOther"));
  }, [currentFence]);

  const CloseAFenceInfo = () => {
    system_operations.frontBuzy = false;
    setFenceOpened(false);
  }

  const handleToggleChange = (name) => {
    setHasChanges(true);
    switch (name) {
      case 'draw':
        setAllowOtherToDraw(!allowOtherToDraw);
        break;
      case 'addCanvas':
        setAllowOtherToAddCanvas(!allowOtherToAddCanvas);
        break;
      case 'post':
        setAllowOtherToPost(!allowOtherToPost);
        break;
      default:
        break;
    }
  }

  const OpenAFenceInfo = (fence) => {
    // {creatorID, start, end, createdDate, protectLevel}
    setCurrentFence(fence);
    system_operations.frontBuzy = true;
    const formattedDate = new Date(fence.createdDate);
    const userRole = system_operations.coordinator.getUserRoleInWall();
    if (system_operations.coordinator.getWallData().owner === system_operations.getUserID() ||
      fence.protectLevel < userRole.power ||
      fence.creatorID === system_operations.getUserData()) {
      canRemove.current = true;
    } else {
      canRemove.current = false;
    }
    fenceCreateDate.current = `${formattedDate.getMonth() + 1}/${formattedDate.getDate()}/${formattedDate.getFullYear()}`;
    system_operations.usersData.getUserDataByID(fence.creatorID).then((userData) => {
      setFenceCreatorUserData(userData);
    })
    setFenceOpened(true);
  }

  const saveChanges = async () => {
    system_operations.set_loading(true);
    if (currentFence.creatorID === system_operations.getUserID() && hasChanges) {
      let rules = currentFence.rules;
      rules = allowOtherToDraw ? readFenceRule.setFlagByName(rules, "drawingOther") : readFenceRule.clearFlagByName(rules, "drawingOther");
      rules = allowOtherToAddCanvas ? readFenceRule.setFlagByName(rules, "addingCanvasOther") : readFenceRule.clearFlagByName(rules, "addingCanvasOther");
      rules = allowOtherToPost ? readFenceRule.setFlagByName(rules, "postingOther") : readFenceRule.clearFlagByName(rules, "postingOther");
      currentFence.rules = rules;
      await system_operations.firestoreActions.updateDocumentAt(`boards/${system_operations.coordinator.getWallID()}/fences/${currentFence.fenceID}`, { rules: rules })
    }
    setHasChanges(false);
    system_operations.set_loading(false);
  }

  const removeFence = async () => {
    system_operations.set_loading(true);
    const updateData = await RequestDataMaker.getDataToDeleteAFence(system_operations, currentFence.fenceID);
    const updateResult = await system_operations.restServerConnector.deleteAFence(updateData);

    if (updateResult && updateResult.success) {
      system_operations.coordinator.fenceLoader.deleteItemFromFenceLoader(currentFence)
      system_operations.socket_connector.emit_to_socket("updateFence", {
        operation: "remove", userData: system_operations.getUserData(), fence: currentFence
      }) // go look the param passed to remove a fence.
    }
    system_operations.frontBuzy = false;
    setFenceOpened(false);
    system_operations.set_loading(false);
  }

  const isHorizontal = system_operations.coordinator.isHorizontal;
  const style = {
    position: "absolute",
    top: isHorizontal ? `${displayOffset}px` : 0,
    left: isHorizontal ? 0 : `${displayOffset}px`,
    zIndex: "100",
  }
  const removeInsteadOfSave = currentFence.creatorID !== system_operations.getUserID() || !hasChanges;

  return (
    <div style={{ overflow: "hidden" }}>
      <div style={style}>
        {fences.map((fence) => {
          return (<FenceLine fence={fence} readFenceRule={readFenceRule} OpenAFenceInfo={OpenAFenceInfo} key={fence.fenceID}/>)
        })}
      </div>
      <div>
        <StyledDialog open={fenceOpened}
          onClose={CloseAFenceInfo}
          aria-labelledby="fence-content-title"
          BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}>
          <DialogTitle id="fence-content-title" sx={{ color: "rgb(230,230,230)" }}>
            Created By:&nbsp;
            <UserShortCardToolTip user={fenceCreatorUserData} onNavigate={CloseAFenceInfo}>
              <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '1.2rem',
                  color: "rgba(64, 64, 225, 1)"
                }}
              >
                {fenceCreatorUserData.username}
              </span>
            </UserShortCardToolTip>
            &nbsp;&nbsp;on <span
              style={{
                fontSize: '1.2rem',
                color: secondaryTextColor
              }}>{fenceCreateDate.current}</span>
          </DialogTitle>
          <Divider orientation="horizontal" flexItem />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: "rgb(230,230,230)" }}>Permissions:</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowOtherToDraw}
                      onChange={() => handleToggleChange('draw')}
                      disabled={currentFence.creatorID !== system_operations.getUserID()}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  }
                  label="Allow others to draw"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowOtherToAddCanvas}
                      onChange={() => handleToggleChange('addCanvas')}
                      disabled={currentFence.creatorID !== system_operations.getUserID()}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  }
                  label="Allow others to add canvas"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowOtherToPost}
                      onChange={() => handleToggleChange('post')}
                      disabled={currentFence.creatorID !== system_operations.getUserID()}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  }
                  label="Allow others to post"
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={CloseAFenceInfo} sx={{ userSelect: 'none', color: "rgb(230,230,230)" }} color="inherit">
                Close
              </Button>
              <Button onClick={removeInsteadOfSave ? removeFence : saveChanges} color="error" sx={{ userSelect: 'none', color: removeInsteadOfSave ? "" : "#00ff00" }} disabled={!canRemove.current}>
                {removeInsteadOfSave ? 'Remove' : 'Save'}
              </Button>
            </DialogActions>
          </DialogContent>
        </StyledDialog>
      </div>
    </div>
  )
});


const FenceLine = ({ fence, readFenceRule, OpenAFenceInfo }) => {
  const { system_operations } = useContext(SystemOperationsContext);

  useEffect(() => {
    system_operations.coordinator.fenceLoader.createCollisionRectForFence(fence);
    return () => {
      system_operations.coordinator?.fenceLoader.removeFenceCollisionRect(fence);
    }
  }, []);

  const style={
    position: "absolute", zIndex: "10", borderRadius: "3px", cursor: "pointer", transition: "all 0.3s ease-in-out",
    [system_operations.coordinator.getBoardCoordinateField()]: `${system_operations.coordinator.fenceLoader.getFenceGlobalStartCoordinate(fence)}px`,
    [system_operations.coordinator.getBoardLengthMeasurementField()]: `${system_operations.coordinator.fenceLoader.getFenceGlobalLength(fence)}px`, 
    [system_operations.coordinator.getBoardWidthMeasurementField()]: `${10}px`, 
  }

  return (
    <Tooltip
      key={fence.fenceID}
      enterTouchDelay={0}
      leaveTouchDelay={2500}
      title={
        <div style={{ display: 'inline-flex' }}>
          {!readFenceRule.isFlagSetByName(fence.rules, "drawingOther") && <EditOffRoundedIcon />}
          {!readFenceRule.isFlagSetByName(fence.rules, "addingCanvasOther") && <ContentPasteOffRoundedIcon />}
          {!readFenceRule.isFlagSetByName(fence.rules, "postingOther") && <SubtitlesOffRoundedIcon />}
        </div>
      }
      placement="top-start"
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -12], // Moves Tooltip along main axis
              },
            },
          ],
        },
      }}
    >
      <div className={fence.creatorID === system_operations.getUserID() ?
        (system_operations.coordinator.isHorizontal? "green-lines-horizontal" : "green-lines-vertical") :
        (system_operations.coordinator.isHorizontal? "red-lines-horizontal" : "red-lines-vertical")
      }
        style={style}
        onClick={() => { OpenAFenceInfo(fence) }}
      />
    </Tooltip>
  )
}

export default FenceLines