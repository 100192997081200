import React, { useState, useRef, useContext, useEffect } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import StickerCreationToolBar from "./StickerCreationToolBar.jsx";
import { RatioSizer } from "../utility/Sizer.js";
import ButtonGroups from "./ButtonGroups.jsx";
import ImagePostCreation from "./ImagePostCreation.jsx";
import AudioPostCreation from "./AudioPostCreation.jsx";
import VideoPostCreation from "./VideoPostCreation.jsx";

import { useTheme } from '@mui/system';



const StickerCreation = () => {
  const { system_operations } = useContext(SystemOperationsContext);

  const [activeTab, setActiveTab] = useState('choose');
  const [selectedPost, setSelectedPost] = useState(null);
  const [perfectWindowSize, setPerfectWindowSize] = useState([0, 0]);
  const [postList, setPostList] = useState([]);
  const [uploadContentFile, setUploadContentFile] = useState(null);
  const insideTopDownMargin = 200, outsideTopDownMargin = 140;

  const containerSizeCalculator = useRef(new RatioSizer());
  const fileInputRef = useRef(null);
  const containerRef = useRef(null);

  const theme = useTheme();

  useEffect(() => {
    containerSizeCalculator.current.setWidthRatio(0.5, 800, 0.9);
    containerSizeCalculator.current.setHeightRatio(0.8, 0, 0);
    containerSizeCalculator.current.setWidthRange(400, 1000);
    containerSizeCalculator.current.setHeightRange(300, 1440);
  }, []);

  const handleSelectPost = (selected) => {
    setSelectedPost(selected);
  }

  const submit = async (type, image, isBaes64, imageW, imageH, content, fileType, text, link) => {
    system_operations.set_loading(true);
    const postContent = {
      "type": type,
      "content": content,
      "image": image,
      "fileType": fileType,
      "isBaes64": isBaes64,
      "text": text,
      "link": link,
      "imageWidth": imageW,
      "imageHeight": imageH
    }
    const post = await system_operations.postSystem.createPost(postContent, system_operations.getUserID());
    system_operations.coordinator.toolpack.getToolByName("pi").assignPost(post);

    putAPreviewPostToSelected(post);
    system_operations.setContentWindow(null);
    system_operations.set_loading(false);
  }

  const putAPreviewPostToSelected = (post) => {
    const shadowBox = {
      width: post.previewWidth,
      height: post.previewHeight,
      boarderRadius: 5,
    }
    system_operations.coordinator.setShadowBox(shadowBox);
    system_operations.coordinator.setPostOfAnyType(post);
    system_operations.coordinator.submitShadowBoxHook = stickAPostSubmission;
  }

  const stickAPostSubmission = (wallID, postRef) => {
    system_operations.postSystem.stickAPost(wallID, system_operations.getUserID(), system_operations.coordinator.getUserRoleInWall().power,
      postRef, [postRef.x, postRef.y], [1, 1])
      .then((stick) => {
        system_operations.coordinator.toolpack.changeToPreviousCommonTool();
        system_operations.coordinator.sectionData.insertNewItemToPostLoader(stick);
      })
  }

  const onLooksGoodClick = async () => {
    // Placeholder for converting canvas to base64 PNG and doing something with it later
    if (activeTab === "choose" && selectedPost) {
      putAPreviewPostToSelected(selectedPost);
      system_operations.setContentWindow(null);
    }
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      system_operations.coordinator.toolpack.changeToPreviousCommonTool();
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      const posts = await system_operations.postSystem.getAllPosts(system_operations.getUserID());
      setPostList(posts);
    };
    fetchPosts();

    const calculateWindowSize = () => {
      const W = window.innerWidth;
      const H = window.innerHeight;
      const size = containerSizeCalculator.current.findSize(W, (H - outsideTopDownMargin));
      setPerfectWindowSize(size);
    };
    calculateWindowSize();

    window.addEventListener('resize', calculateWindowSize);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      window.removeEventListener('resize', calculateWindowSize);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const getDefaultPreview = (post) => {
    if (post.type === "audio") {
      return system_operations.postSystem.defaultAudioCover;
    } else if (post.type === "video") {
      return system_operations.postSystem.defaultVideoCover;
    }
    return system_operations.postSystem.defaultPreviewGeneral;
  }

  const clickUploadFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onUploadFile = async (e) => {
    const file = e.target.files[0];
    setUploadContentFile(file);
  };


  let postCreationWindow = <ImagePostCreation perfectWindowSize={perfectWindowSize} uplaod={clickUploadFile} submit={submit} contentFile={uploadContentFile} />;

  return (
    <div style={{ userSelect: 'none' }}>
      <div ref={containerRef} className='float-container'>
        <input type="file" accept="image/*" id="uploadFile" ref={fileInputRef} onChange={onUploadFile} style={{ display: "none" }} />
        <div className='button-container'>
          <button onClick={() => setActiveTab('choose')} className={activeTab === 'choose' ? 'selected' : ""} style={{ "width": "128px", "fontSize": "12px", "padding": "4px" }}>Gallary</button>
          <button onClick={() => setActiveTab('draw')} className={activeTab === 'draw' ? 'selected' : ""} style={{ "width": "128px", "fontSize": "12px", "padding": "4px" }}>New Post</button>
        </div>
        {activeTab === 'draw' && (
          <div style={{ marginTop: "10px" }}>
            <div className='button-container' style={{ "display": "inline-block", "marginRight": "10px", "margin": "0px", "padding": "0x" }}>
              {/* <div>
                <button onClick={clickUploadFile} style={{ width: "100px", "marginRight": "10px", "flexGrow": 1, "fontSize": "12px", "padding": "2px" }}>
                  Upload
                </button>
                <button
                  onClick={() => { }}
                  style={{ width: "100px", flexGrow: 1, "fontSize": "12px", "padding": "2px" }}
                >
                  Clean
                </button>
        </div> */}
            </div>
            {
              postCreationWindow
            }
          </div>
        )}
        {activeTab === 'choose' && <div className="choose-tab"> {
          <div style={{ marginTop: "10px", "width": perfectWindowSize[0], "height": perfectWindowSize[1] }}>
            <div className='post-container' style={{ "height": `${perfectWindowSize[1] - 50}px` }}>
              <ul>
                {postList.map((content, index) => (
                  <li
                    className={selectedPost === content ? "selected" : ""}
                    onClick={() => handleSelectPost(content)}
                    key={index}
                  >
                    <div className='image-post-container'>
                      <img
                        className="post-preview"
                        src={content.previewUrl ? content.previewUrl : getDefaultPreview(content)}
                        alt={`Post ${content.postID}`}
                        draggable="false"
                      />
                      {content.type === "audio" || content.type === "video" ? <div className="play-icon-container" /> : null}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <ButtonGroups buttons={{
              submitButton: {
                name: 'Looks Good',
                color: "white",
                backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
                checkDisable: () => { return (selectedPost === null ? true : false) },
                onClick: () => { onLooksGoodClick() },
              }
            }} />
          </div>
        } </div>}
      </div>
    </div>
  );
};

export default StickerCreation;