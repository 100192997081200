import { WallPageSizer } from "../utility/Sizer.js";
import { SectionLoaderSimple } from "../utility/SectionLoader.js";
import { RectCollisionSystem } from "./collisionSystem/CollisionEngine.js";
import { CollidableRect } from "./collisionSystem/CollisionEngine.js";
import { MagnetsMaker } from "./collisionSystem/Magnetizer.js";
import { MouseData } from "./MouseData.js";
import { WallSectionalDataSet } from "./WallDependentSystems.js";
import { GuideBox } from "./WallDependentSystems.js";
import { PendingFloatingCanvases } from "./WallDependentSystems.js";
import { CanvasManipulator } from "../system/CanvasManipulator.js";
import { Positioner } from "../system/Positioner.js";
import { DrawingCanvas } from "../system/Positioner.js";
import { GridsSections } from "../system/GridsSections.js";
import { GridsDisplay } from "../system/GridsSections.js";
import { RequestDataMaker } from "../system/RestServerConnector.js"
import { ToolsPack } from "./toolpack/ToolsPack.js"

import { KDraw } from "./KDraw.js"

export class Coordinator {
  constructor(system, wallSystem) {
    this.system = system;
    this.wallSystem = wallSystem;

    this.restoreEveryData();
  }

  restoreEveryData() {
    this.isHorizontal = true;
    // this.boardWholePositioner = new Positioner(); // the whole board, including the boarder and any decorations.
    this.boardPositioner = new Positioner();  // just the board, where the coordinate start to account.
    this.drawingCanvas = new DrawingCanvas(this.boardPositioner);
    this.guideBox = new GuideBox(this);
    this.likeSystem = new LikeSystem(this.system, this);
    this.drawEventTracker = new DrawEventTracker(this.system);
    this.toolpack = new ToolsPack(this.system);

    // scale is the theo to actual displayed pixel ratio.
    // onScreenRatio is how much the board is to the screen dimension.

    this.edgeWidth = 10;
    this.lengthOverScroll = 300;
    // this.widthOverScroll = 300;
    this.maxCanvasLength = 4096;
    this.globalOffset = 0;
    this.theoreticalBoardWidth = 0;
    this.theoreticalBoardHeight = 0;
    this.emptyColumn = "";
    this.mouseScreenSpacePosition = { x: 0, y: 0 };
    this.mouseTheoreticalSpacePosition = { x: 0, y: 0 };
    this.current_index = 0;
    this.autoScrollSpeed = 0;
    this.mainCanvasRef = null;
    this.elementRefs = {};
    this.cacheData = {};
    this.refreshDrawingCanvasHook = null;
    this.setGlobalOffsetHook = null;
    this.setAutoScrollingHook = null;
    this.refresh_wall_hook = null;
    this.anyPostRef = null;
    this.submitShadowBoxHook = null;
    this.boardSettings = new BoardSettings();
    this.wallSizer = new WallPageSizer();
    this.wallSizer.initializeDefault();
    this.mouseData = new MouseData(this, this.wallSizer);
    this.sectionData = new WallSectionalDataSet(this);
    this.fenceLoader = new FenceLoader(this.system, this, this.wallSystem);
    this.canvasManipulator = new CanvasManipulator(this); // need fenceLoader
    this.gridsSections = new GridsSections(this.system);
    this.gridsDisplay = new GridsDisplay(this);
    this.magnetsMaker = new MagnetsMaker(this, [], []);

    this.allPaperColliderCollection = null; // this will be a collision system that stores every paper regard the layer.
    this.floatingCollisionSystems = []; // colliderCollection of different layers.
    this.totalFloatingLayers = 3;

    this.createSectionLoaders();

    this.setXPlacementGuideLine = () => { };
    this.setYPlacementGuideLine = () => { };

    this.onWallInfoChange = {};
    this.onLengthOverScrollChange = {};
    this.onTheoreticalSpaceToScreenSpaceScaleChange = {};
    this.onGlobalOffsetChange = {}; // feed with the whole container' scr space offset
    this.onCoordinateChange = {}; // feed with the board' theo coordinate
    this.onMouseScreenSpacePositionChange = {};
    this.onMouseTheoreticalSpacePositionChange = {};
    this.onShadowBoxChange = {};
    this.on_index_change = {};

    this.currentInRangeContentMarks = [];
    this.currentDisplayingCanvases = [];
    this.currentDisplayingButtons = [];
    this.currentDisplayingPosts = [];
    this.currentDisplayingFences = [];

    this.wallData = null;
    this.wallID = "";
    this.userRole = this.wallSystem.defaultRole;
    this.domains = []; // nothing in.
    this.entrance = 0;
    this.boardStyle = {
      colors: {
        M: "#D6D2C5", // main board...
        S: "rgba(0, 0, 0, 0.05)",

        T: "#D5D1C4",
        B: "#D5D1C4",
        L: "#D5D1C4",
        R: "#D5D1C4",

        TL: "#D5D1C4",
        TR: "#D5D1C4",
        BL: "#D5D1C4",
        BR: "#D5D1C4",
      },
      images: {
        M: "",

        T: "",
        B: "",
        L: "",
        R: "",

        TL: "",
        TR: "",
        BL: "",
        BR: "",

        BK: `url(https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fbasic_frame_set%2Fgrid-png.png?alt=media&token=a7419a5d-c7ab-4f9c-ade7-8a6ea6c1b1ef)`,
      },
    }
    this.setWallCustomization();
    this.boardStoragePath = "boards/404"

    this.loadedUserID = "";
    this.columnScale = 1;
    this.gridWidth = 64;
    this.gridHeight = 768;
    this.totalColumns = 100;
    this.pixelBoundary = [0, this.gridWidth * this.totalColumns];
    this.indexBoundary = [0, this.totalColumns - 1];
    this.preCoordViewImages = 7;
    this.afterCoordViewImages = 40;
    this.wallInitialized = false;

    this.pendingGridsImageData = {};
    this.storedGridsImageData = {};

    this.createDataTransactionFields();
    this.initializeKDraw();
  }

  initializeKDraw() {
    if (this.kDraw?.cleanUp) { this.kDraw.cleanUp(); }
    this.kDraw = null;
  }

  createDataTransactionFields() {
    this.setEventFloatingCanvasRect = () => { };
    this.eventFloatingCanvasData = null;
  }

  createSectionLoaders() {
    this.onCanvasesChange = {};
    this.onPostsChange = {};
    this.onButtonsChange = {};
    this.onContentMarksChange = {};
    this.onFencesChange = {};

    this.onPendingPapersEmptyChange = {};
    this.onPendingButtonsEmptyChange = {};
    this.pendingCanvasChangesEmpty = true;
    this.allPendingPaperChangesEmpty = true;

    const pendingCanvasesSetEmpty = (list, empty) => {
      this.pendingCanvasChangesEmpty = empty;
      this.checkPendingPaperEmpty();
    }

    this.pendingCanvasSystem = new PendingFloatingCanvases(this.system, this);
    this.pendingCanvasSystem.onPendingListChange["_fc"] = pendingCanvasesSetEmpty;
  }

  checkPendingPaperEmpty() {
    if (this.pendingCanvasChangesEmpty) { // now empty.
      if (!this.allPendingPaperChangesEmpty) {
        this.allPendingPaperChangesEmpty = true;
        for (const key in this.onPendingPapersEmptyChange) {
          this.onPendingPapersEmptyChange[key](true);
        }
      }
    } else { // no longer empty.
      if (this.allPendingPaperChangesEmpty) {
        this.allPendingPaperChangesEmpty = false;
        for (const key in this.onPendingPapersEmptyChange) {
          this.onPendingPapersEmptyChange[key](false);
        }
      }
    }
  }

  async createPaperSubmissionRequest() {
    const request = {
      allCanvasesRequests: this.pendingCanvasSystem.processPendingList(),
    }
    return await RequestDataMaker.papersTransformUpdateData(this.system, request);
  }

  async saveAllPendingPapersChanges() {
    const fcRequestData = await this.createPaperSubmissionRequest();
    this.pendingCanvasSystem.onSubmitEvents();

    const changeResult = await this.system.restServerConnector.requestToUpdatePapersTransform(fcRequestData);
    if (changeResult && changeResult.success) {
      const changeResultForCanvases = changeResult.canvasesResult;
      if (this.pendingCanvasSystem) {
        this.pendingCanvasSystem.finishSubmitPendingList(changeResultForCanvases);
      }
    } else {
      // should not remove anything. just go and invoke an error. do not remove changes.
      // this.discardAllPendingPapersChanges();
      this.system.enqueueSnackbar("Failed to update Papers. Please try again...", "error")
    }
  }

  // todo, pending new iteams is not removed.
  discardAllPendingPapersChanges() {
    if (this.pendingCanvasSystem) {
      this.pendingCanvasSystem.giveUpPendingList();
    }
  }

  updateCanvases(canvases) {
    this.currentDisplayingCanvases = canvases;
    for (const key in this.onCanvasesChange) {
      this.onCanvasesChange[key](canvases);
    }
  }

  updateContentMarks(marks) {
    this.currentInRangeContentMarks = marks;
    for (const key in this.onContentMarksChange) {
      this.onContentMarksChange[key](marks);
    }
  }

  updateFences(fences) {
    this.currentDisplayingFences = fences;
    for (const key in this.onFencesChange) {
      this.onFencesChange[key](fences);
    }
  }

  setWallCustomization(wallData) {
    this.cornerTLImage = (wallData && wallData.cornerTLImage) ? `url(${wallData.cornerTLImage})` : `url(https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fbasic_frame_set%2FCornerTL.png?alt=media&token=9c24e947-2213-4820-91e8-1bdd57448255)`;
    this.cornerTRImage = (wallData && wallData.cornerTRImage) ? `url(${wallData.cornerTRImage})` : `url(https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fbasic_frame_set%2FCornerTR.png?alt=media&token=06055d10-d3d9-431f-a159-1ce489b39bf3)`;
    this.cornerBLImage = (wallData && wallData.cornerBLImage) ? `url(${wallData.cornerBLImage})` : `url(https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fbasic_frame_set%2FCornerBL.png?alt=media&token=030cd601-e81e-47fe-90e4-79d272c71818)`;
    this.cornerBRImage = (wallData && wallData.cornerBRImage) ? `url(${wallData.cornerBRImage})` : `url(https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fbasic_frame_set%2FCornerBR.png?alt=media&token=936e6700-0e70-419e-a1d7-0c168a30a57b)`;

    this.boardLeftEdgeImage = (wallData && wallData.boardLeftEdgeImage) ? `url(${wallData.boardLeftEdgeImage})` : `url(https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fbasic_frame_set%2FVertical%20Wall%20Bars.png?alt=media&token=b3ba34b0-ec3f-4298-b52f-2dc45eab75af)`;
    this.boardRightEdgeImage = (wallData && wallData.boardRightEdgeImage) ? `url(${wallData.boardRightEdgeImage})` : `url(https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fbasic_frame_set%2FVertical%20Wall%20Bars.png?alt=media&token=b3ba34b0-ec3f-4298-b52f-2dc45eab75af)`;

    this.boardTopEdgeImage = (wallData && wallData.boardTopEdgeImage) ? `url(${wallData.boardTopEdgeImage})` : `url(https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fbasic_frame_set%2FHorizontal%20Wall%20Bars.png?alt=media&token=4d2d473e-6627-4c94-895c-f29dceeccba1)`;
    this.boardBottomEdgeImage = (wallData && wallData.boardBottomEdgeImage) ? `url(${wallData.boardBottomEdgeImage})` : `url(https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fbasic_frame_set%2FHorizontal%20Wall%20Bars.png?alt=media&token=4d2d473e-6627-4c94-895c-f29dceeccba1)`;
    this.backgroundImage = (wallData && wallData.backgroundImage) ? `url(${wallData.backgroundImage})` : `url(https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fbasic_frame_set%2Fgrid-png.png?alt=media&token=a7419a5d-c7ab-4f9c-ade7-8a6ea6c1b1ef)`
    this.boardPanelImage = (wallData && wallData.boardPanelImage) ? `url(${wallData.boardPanelImage})` : `url(https://firebasestorage.googleapis.com/v0/b/wallswithworld.appspot.com/o/system%2Fbasic_frame_set%2FMainPlane.png?alt=media&token=27081878-4556-4285-bde6-dbb703dd5c4c)`
    // this.wallFootUrl = (wallData && wallData.wallFootUrl) ? `url(${wallData.wallFootUrl})` : null;
    if (wallData && wallData.style && wallData.style.colors) {
      for (const key in wallData.style.colors) {
        this.boardStyle.colors[key] = wallData.style.colors[key];
      }
    }
    if (wallData && wallData.style && wallData.style.images) {
      for (const key in wallData.style.images) {
        this.boardStyle.images[key] = `url(${wallData.style.images[key]})`;
      }
    } 
  }

  async setUser(userID) {
    if (userID !== this.loadedUserID) {
      this.loadedUserID = userID;
      const role = await this.fetchUserRole(this.wallID, userID);
      this.userRole = role;
    }
  }

  // when this is called, the wall is refreshed.
  async setWall(boardID, userID = "", initialzationCallback = null) {
    this.wallID = boardID;
    this.wallData = await this.system.wallsData.getWallDataByID(boardID);   // obtain the boardData...
    this.boardData = this.wallData;
    this.wallID = this.wallData["wallID"];
    this.boardStoragePath = `boards/${this.wallID}`;
    this.setWallCustomization(this.wallData);
    this.boardSettings.setBoard(this.boardData);
    this.domains = this.boardData.domains || [];
    this.entrance = this.boardData.entrance || 0;

    this.magnetsMaker.setStaticValuesByBoardData(this.boardData);

    this.columnScale = 1;
    this.isHorizontal = this.wallData["isHorizontal"];

    this.gridWidth = this.wallData["gridWidth"];
    this.gridHeight = this.wallData["gridHeight"];

    this.totalColumns = this.wallData["totalColumns"];
    this.totalRows = this.wallData["totalRows"];

    this.theoreticalBoardWidth = this.gridWidth * this.totalColumns;
    this.theoreticalBoardHeight = this.gridHeight * this.totalRows;
    this.pixelBoundary = [0, this.isHorizontal ? this.theoreticalBoardWidth : this.theoreticalBoardHeight];
    this.indexBoundary = [0, this.isHorizontal ? this.totalColumns - 1 : this.totalRows - 1];
    this.pendingGridsImageData = {};
    await this.gridsSections.initialize(this.wallData); // after this is called, anything else accessing section names can be executed.
    await this.fenceLoader.enterAWall(this.wallData, this.system.firestoreActions);
    this.sectionData.initializeData();
    this.initializeCollisionCollectionsAndLogic(); // after the fence is loaded.

    this.boardPositioner.initializeDimension(this.theoreticalBoardWidth, this.theoreticalBoardHeight);
    if (this.isHorizontal) {
      this.drawingCanvas.initializeDimension(this.maxCanvasLength, this.theoreticalBoardHeight);
    } else {
      this.drawingCanvas.initializeDimension(this.theoreticalBoardWidth, this.maxCanvasLength);
    }

    // in case set user never happened.
    if (!this.loadedUserID) {
      this.loadedUserID = userID;
      const role = await this.fetchUserRole(this.wallID, userID);
      this.userRole = role;
    }

    for (const key in this.onWallInfoChange) {
      this.onWallInfoChange[key](this.wallData);
    }
    this.wallInitialized = true;
    this.kDraw = new KDraw(this.system); // create the kDraw component.
    if (initialzationCallback) initialzationCallback(true);
  }

  initializeCollisionCollectionsAndLogic() {
    this.allPaperColliderCollection = new RectCollisionSystem(`all_papers`)
    for (let i = 0; i < this.totalFloatingLayers; i++) {
      const newCollisionCollection = new RectCollisionSystem(`floatingCollision${i + 1}`);
      newCollisionCollection.setBoundary(0, this.theoreticalBoardWidth, 0, this.theoreticalBoardHeight);
      this.floatingCollisionSystems.push(newCollisionCollection);
    }
  }

  // should be replaced by using a pre initialized array.
  getPaperCollectionSet(layer, bypassRuleForFence, cacheName) {
    if (typeof layer !== 'number' || Number.isNaN(layer)) {
      layer = 0;
    }
    layer = Math.max(Math.min(layer, this.totalFloatingLayers), 0)
    if (this.cacheData[cacheName] && this.cacheData[cacheName][layer]) {
      for (const collectionObj of this.cacheData[cacheName][layer]) {
        if (!collectionObj.collection) {
        }
      }
      return this.cacheData[cacheName][layer];
    }
    const paperCollisionCollectionAtLayer = this.floatingCollisionSystems[layer];

    const fenceCollisionByPassRule = (self, fenceRect) => {
      if (self && fenceRect && self.id === fenceRect.id) {
        return true;
      }
      if (bypassRuleForFence(fenceRect)) {
        return true;
      }
      return false;
    }

    this.cacheData[cacheName] = [];
    const collisionCollectionSet = [
      { collection: paperCollisionCollectionAtLayer, byPassRule: null, addToCollection: true },
      { collection: this.fenceLoader.fenceCollisionSystem, byPassRule: fenceCollisionByPassRule, addToCollection: false, }
    ]

    this.cacheData[cacheName][layer] = collisionCollectionSet;
    return collisionCollectionSet;
  }

  getAllCurrentBasicPapers() {
    return [this.sectionData.sectionLoaderForCanvases.getDiscoveriedCache()];
  }

  getPaperRectFromID(id) {
    return this.sectionData.sectionLoaderForCanvases.getFromSavedData(id);
  }

  async fetchUserRole(wallID, userID = "") {
    if (!userID) {
      return {
        userID: userID,
        roleID: "none",
        power: 0,
      }
    }
    const userRole = await this.system.firestoreActions.fetchFromFirestore(`boards/${wallID}/people/${userID}`, "userID"); // get use' role id
    if (userRole) {
      return userRole
    }
    return {
      userID: userID,
      roleID: "none",
      power: 0,
    }
  }

  getBoardData() {
    return this.boardData;
  }

  getWallID() {
    return this.wallID;
  }

  getUserRoleInWall() {
    return this.userRole;
  }

  getBoardStoragePath() {
    return this.boardStoragePath;
  }

  getPathToColumnsFolder() {
    return `${this.boardStoragePath}/pendingGrids`;
  }

  setDisplayScale(scale) {
    this.columnScale = scale;
  }

  // this should clamp the value to screen value.
  checkOffsetBound(newOffset) {
    const toScrScale = this.boardPositioner.getTheoToScrScale();
    if (this.isHorizontal) {
      const wallWidth = this.theoreticalBoardWidth;
      const maxOffset = (wallWidth + 2 * (this.edgeWidth + this.lengthOverScroll)) - window.innerWidth;
      newOffset = Math.min(Math.max(newOffset, 0), maxOffset * toScrScale || 1);
      return newOffset;
    } else {
      const wallHeight = this.theoreticalBoardHeight;
      const maxOffset = (wallHeight + 2 * (this.edgeWidth + this.lengthOverScroll)) - window.innerHeight;
      newOffset = Math.min(Math.max(newOffset, 0), maxOffset * toScrScale || 1);
      return newOffset;
    }
  }

  getCoordinate(recalculate = false) {
    if (this.isHorizontal) {
      return this.boardPositioner.getCurrentScreenLeftOffset(recalculate, true, true);
    } else {
      return this.boardPositioner.getCurrentScreenTopOffset(recalculate, true, true);
    }
  }

  getCoordinateScr(recalculate = false) {
    if (this.isHorizontal) {
      return this.boardPositioner.getCurrentScreenLeftOffset(recalculate, true, false);
    } else {
      return this.boardPositioner.getCurrentScreenTopOffset(recalculate, true, false);
    }
  }

  getCurrentPaperSection() {
    return this.sectionData.sectionLoaderForCanvases.getSectionIndex(this.coordinate);
  }

  getBoardScrollableSizeTheo() {
    return this.lengthOverScroll * 2 + this.pixelBoundary[1];
  }

  // assigning with screen space value.
  setGlobalOffset(newOffset, checkBound = true, forceful = false) {
    // toTheoScale = 1 / toScrScale
    if (checkBound) {
      // no need to clamp. because if is off the size, then the scroll content will clamp itself into range 0 - max.
      // newOffset = this.checkOffsetBound(newOffset);
    }
    if (this.globalOffset !== newOffset || forceful) {
      // if (true) {
      // this.updateAllSections(this.getCoordinate());
      if (this.setGlobalOffsetHook) {
        this.globalOffset = this.setGlobalOffsetHook(newOffset); // the scroll content will clamp the range to 0 to max
      }
      this.update_current_index(this.getTheoreticalOffset())
      for (const key in this.onGlobalOffsetChange) {
        this.onGlobalOffsetChange[key](this.globalOffset);
      }
      this.updateCoordinate(); // no need to be a callback because update hook doesn't actually use hook, it use ref and direct manipulation.
    }
  }

  setGlobalOffsetTheo(coord, checkBound = true, forceful = false) {
    const toScrScale = this.boardPositioner.getTheoToScrScale();
    const scrOffset = (coord + this.edgeWidth + this.lengthOverScroll) * toScrScale;
    this.setGlobalOffset(scrOffset, checkBound, forceful);
  }

  setGlobalOffsetTheo_middleScreen(coord, checkBound = true, forceful = false) {
    const toScrScale = this.boardPositioner.getTheoToScrScale();
    const scrOffset = (coord + this.edgeWidth + this.lengthOverScroll - (this.isHorizontal ? window.innerWidth : window.innerHeight) / 2) * toScrScale;
    this.setGlobalOffset(scrOffset, checkBound, forceful);
  }

  setLengthOverScroll(newValue) {
    for (const key in this.onLengthOverScrollChange) {
      this.onLengthOverScrollChange[key](newValue);
    }
  }

  updateCoordinate() {
    this.boardPositioner.updateScreenOffsetAndScale();
    this.coordinate = (this.isHorizontal ? this.boardPositioner.getCurrentScreenLeftOffset(false, true) : this.boardPositioner.getCurrentScreenTopOffset(false, true)) || 0;
    for (const key in this.onCoordinateChange) {
      this.onCoordinateChange[key](this.coordinate);
    }
  }

  setGlobalOffsetMiddleScreen(newCoordinate) {
    let screenMiddleSrc = 0;
    if (this.isHorizontal) {
      screenMiddleSrc = window.innerWidth / 2;
    } else {
      screenMiddleSrc = window.innerHeight / 2;
    }
    const screenMiddleTheo = this.screenSpaceToTheoreticalSpace(screenMiddleSrc);
    const actualJumpToCoordinate = newCoordinate - screenMiddleTheo;
    this.setGlobalOffset(actualJumpToCoordinate, true);
  }

  update_current_index(new_coordinate) {
    let index = Math.min(Math.floor(new_coordinate / this.gridWidth), this.totalColumns);
    if (index !== this.current_index) {
      this.current_index = index;
      for (const key in this.on_index_change) {
        this.on_index_change[key](index);
      }
    }
  }

  pureChainOffset(newOffset, checkBound = false) {
    if (checkBound) {
      newOffset = this.checkOffsetBound(newOffset);
    }
    if (this.globalOffset !== newOffset) {

    }
  }

  getCanvasStartScreenSpace() {
    return this.wallSizer.canvasStartPx;
  }

  getCanvasStartTheoSpace() {
    return this.screenSpaceToTheoreticalSpace(this.wallSizer.canvasStartPx);
  }

  getTheoreticalOffset() { // the amount of coordinate offset the absolute space(screen space) offset is equivlent to
    // useful when calculate the relative position. For example drawing position
    return this.globalOffset / this.columnScale;
  }

  getGlobalOffset() { // the offset in px without scaling
    // useful for css when setting the actual position of components. also used when set the globalOffset itself
    return this.globalOffset;
  }

  // where the canvas placed since the start without any boarder
  // the reason it's different is: 
  // in html, there are boarder to make the ui look better, but when calculate the image and columns mapping, there is no boarder. So, subtract the boarder positioning away from the boarder.
  // this is get the distance of left/top screen edge' position of the canvas out of the whole canvas.
  getCanvasOffset_screenSpace() {
    // return this.globalOffset - this.lengthOverScroll
    return this.globalOffset - this.theoreticalSpaceToScreenSpace(this.lengthOverScroll);
  }

  getCanvasOffset_theoreticalSpace() {
    // return (this.globalOffset - this.lengthOverScroll) / this.columnScale;
    return this.screenSpaceToTheoreticalSpace(this.globalOffset) - this.lengthOverScroll
  }

  getWallData() {
    return this.wallData;
  }

  getOnCanvasOffsetAtScreenPosition(screenPosition = 0) {
    return (this.getCanvasOffset_screenSpace() + screenPosition) / this.columnScale;
  }

  getTheoreticalSpaceToScreenSpaceRatio() {
    return this.columnScale;
  }

  getLengthOverScrollTheoreticalSpace() {
    return this.lengthOverScroll;
  }

  getLengthOverScrollScreenSpace() {
    return this.lengthOverScroll * this.columnScale;
  }

  screenSpaceToTheoreticalSpace(screenSpace, addOffest = false) {
    let value = screenSpace / this.columnScale;
    if (addOffest) { value += this.globalOffset } // if this is a coordinate, then translate back to pure screen space position, you need to deductOffset
    return value;
  }

  theoreticalSpaceToScreenSpace(theoreticalValue, deductOffset = false) {
    let value = theoreticalValue * this.columnScale;
    if (deductOffset) { value -= this.globalOffset } // if this is a coordinate, then translate back to pure screen space position, you need to deductOffset
    return value;
  }

  getColumnWidth() {
    return this.gridWidth;
  }
  getGridWidth() {
    return this.gridWidth;
  }
  getGridHeight() {
    return this.gridHeight;
  }

  getGridParallelEdge() {
    return this.isHorizontal ? this.gridWidth : this.gridHeight;
  }

  getGridCrossEdge() {
    return this.isHorizontal ? this.gridHeight : this.gridWidth;
  }

  getBoardCoordinateField() {
    return this.isHorizontal ? "left" : "top";
  }

  getBoardSecondaryCoordinateField() {
    return this.isHorizontal ? "top" : "left";
  }

  getBoardLengthMeasurementField() {
    return this.isHorizontal ? "width" : "height";
  }

  getBoardWidthMeasurementField() {
    return this.isHorizontal ? "height" : "width";
  }

  getAbsoluteWallWidth() { // wall width in px, after considering the scaling
    return this.gridWidth * this.totalColumns * this.columnScale;
  }

  getAbsoluteWallHeight() { // wall width in px, after considering the scaling
    return this.gridHeight * this.totalRows * this.columnScale;
  }

  getScaledScreenSpace(coord) {
    return coord / this.columnScale;
  }

  getScreenSpaceColumnWidth() {
    return Math.ceil(this.gridWidth * this.columnScale);
  }

  /*
  problem solved:
  the scaled image column' start pixel and width sometime have gap. due to the decimal to whole number (pixel) issue
  Solved by: 
  first round up using floor() to round up the start
  for the width, use next start pox, which is already a whole number, subtract current one' start pos. So it's grantee to get another whole number
  */
  getScreenSpaceGridWidth_smart(column) {
    // both gonna be whole number cause the "floor"
    const startAt = this.getScreenSpaceLeftOffsetOfIndex(column);
    const nextStartAt = this.getScreenSpaceLeftOffsetOfIndex(column + 1);
    return (nextStartAt - startAt);
  }

  getScreenSpaceGridHeight_smart(row) {
    // both gonna be whole number cause the "floor"
    const startAt = this.getScreenSpaceTopOffsetOfIndex(row);
    const nextStartAt = this.getScreenSpaceTopOffsetOfIndex(row + 1);
    return (nextStartAt - startAt);
  }

  getTopOffsetOfIndex(row) { // this will return the right offset after scaling.
    // is used to find the right absolute position "left" for column at index.
    return row * this.gridHeight;
  }

  getLeftOffsetOfIndex(column) { // this will return the right offset after scaling.
    // is used to find the right absolute position "left" for column at index.
    return column * this.gridWidth;
  }

  getPixelBoundary() { // the image total px. No scaling applied.
    return this.pixelBoundary;
  }

  getIndexBoundary() {
    return this.indexBoundary;
  }

  getIndexAndMarginalOffsetOfCoordinate(coordinate) {
    const index = Math.floor(coordinate / this.gridWidth)
    if (index > this.indexBoundary || index < 0) {
      return undefined;
    }
    const offset = coordinate % this.gridWidth;
    return [index, offset];
  }

  refresh_wall() {
    if (this.refresh_wall_hook) { this.refresh_wall_hook(); }
  }

  setMouseScreenSpacePosition(x, y) {
    this.mouseScreenSpacePosition = { x: x, y: y }
    this.mouseTheoreticalSpacePosition = { x: this.screenSpaceToTheoreticalSpace(x), y: this.screenSpaceToTheoreticalSpace(y) };
    for (const key in this.onMouseScreenSpacePositionChange) {
      this.onMouseScreenSpacePositionChange[key](this.mouseScreenSpacePosition);
    }
    for (const key in this.onMouseTheoreticalSpacePositionChange) {
      this.onMouseTheoreticalSpacePositionChange[key](this.mouseTheoreticalSpacePosition);
    }
  }

  setAutoScrolling(speed) {
    this.autoScrollSpeed = speed;
    if (this.setAutoScrollingHook) { this.setAutoScrollingHook(speed); }
  }

  getPostOfAllTypeRef() {
    return this.anyPostRef;
  }

  setPostOfAnyType(post) {
    this.anyPostRef = post;
  }

  modifyPostOfAllType(field, value) {
    if (this.anyPostRef) {
      this.anyPostRef[field] = value;
    }
  }

  getShadowBox() {
    if (this.shadowBox) {
      return this.shadowBox;
    } else {
      return { width: 0, height: 0, borderRadius: 0 };
    }
  }

  setShadowBox(shadowBox = { width: 0, height: 0, borderRadius: 0 }) {
    this.shadowBox = shadowBox;
    for (const key in this.onShadowBoxChange) {
      this.onShadowBoxChange[key](shadowBox);
    }
  }

  shadowBoxConfirm() {
    if (this.submitShadowBoxHook && this.anyPostRef) { this.submitShadowBoxHook(this.wallID, this.anyPostRef); }
    this.shadowBox = null;
  }

  assignRefreshDrawingCanvasHook(refreshDrawingCanvasHook) {
    this.refreshDrawingCanvasHook = refreshDrawingCanvasHook;
  }

  refreshDrawingCanvas() {
    if (this.refreshDrawingCanvasHook) {
      this.refreshDrawingCanvasHook();
    }
  }

  assignSetGlobalOffsetHook(setGlobalOffsetHook) {
    this.setGlobalOffsetHook = setGlobalOffsetHook;
  }

  assign_refresh_wall_hook(refresh_wall_hook) {
    this.refresh_wall_hook = refresh_wall_hook;
  }


  add_to_on_coordinate_change(key, func) {
    this.onGlobalOffsetChange[key] = func;
  }

  remove_from_on_coordinate_change(key) {
    delete this.onGlobalOffsetChange[key];
  }

  addToOnTheoreticalSpaceToScreenSpaceScaleChange(key, func) {
    this.onTheoreticalSpaceToScreenSpaceScaleChange[key] = func;
  }

  addToOnWallInfoChange(key, func) {
    this.onWallInfoChange[key] = func;
  }

  removeFromOnWallInfoChange(key) {
    delete this.onWallInfoChange[key];
  }

  get_index_and_offset_from_bondary(bondary) {
    const left = bondary[0];
    const right = bondary[1];

    const left_index = Math.floor(left / this.gridWidth);
    const left_offset = left % this.gridWidth;

    const right_index = Math.floor(right / this.gridWidth);
    const right_offset = right % this.gridWidth;

    return [left_index, right_index, left_offset, right_offset];
  }
}

class FenceLoader {
  constructor(system, coordinator, wallSystem) {
    this.sectionContainer = null;

    this.system = system;
    this.boardData = {};
    this.coordinator = coordinator;
    this.wallSystem = wallSystem;

    this.coordinate = 0;
    this.lastLoadCoordinate = -999999;
    this.loadRange = 9600;
    this.reloadEvery = 6400;

    this.displayFences = [];
    this.fencesFetchedAt = {};
    this.fenceAt = {};

    this.fences = [];
    this.fenceCollisionSystem = new RectCollisionSystem("fences");
    this.fenceColliders = {};
  }

  getFenceGlobalStartIndex(fence) {
    return fence.sectionIndex * this.boardData.sectionSize + fence.start
  }

  getFenceGlobalStartCoordinate(fence) {
    return (fence.sectionIndex * this.boardData.sectionSize + fence.start) * this.coordinator.getGridParallelEdge()
  }

  getFenceGlobalLength(fence) {
    return fence.length * this.coordinator.getGridParallelEdge();
  }

  getFenceGlobalEndIndex(fence) {
    return fence.sectionIndex * this.boardData.sectionSize + fence.start + fence.length;
  }

  getFenceGlobalEndCoordinate(fence) {
    return (fence.sectionIndex * this.boardData.sectionSize + fence.start + fence.length) * this.coordinator.getGridParallelEdge()
  }

  // is called in react components
  createCollisionRectForFence(fence) {
    const fenceDictID = `${fence.fenceID}`;
    this.fenceColliders[fenceDictID] = new CollidableRect(
      this.boardData.isHorizontal ? this.getFenceGlobalStartCoordinate(fence) : 0, this.boardData.isHorizontal ? 0 : this.getFenceGlobalStartCoordinate(fence),
      fence.length * this.coordinator.getGridParallelEdge(), this.coordinator.isHorizontal ? this.coordinator.theoreticalBoardHeight : this.coordinator.theoreticalBoardWidth, fenceDictID, "fence", fence
    );
    this.fenceColliders[fenceDictID].addACollisionCollection(this.fenceCollisionSystem, null, true);
    this.fenceCollisionSystem.addRect(this.fenceColliders[fenceDictID]); // add all fences in
  }
  // is called in react components
  removeFenceCollisionRect(fence) {
    // console.log(this.fenceColliders[fence.fenceID], " where", fence.fenceID);
    this.fenceCollisionSystem.removeRect(this.fenceColliders[fence.fenceID]);
  }

  fenceCollisionByPassRule(fenceRectID) {
    const fence = this.fenceColliders[fenceRectID]?.dataRef;
    if (!fence) {
      return false;
    }
    if (fence.creatorID === this.system.getUserID()) {
      return true;
    } else {
      return false;
    }
  }

  async createFenceLoader(databaseTalker) {
    const sectionLength = this.boardData.sectionSize * this.coordinator.getGridParallelEdge();

    const fetchFunctionForFences = async (sectionIndex, coord, lookBackDistance, lookFrontDistance) => {
      if (sectionIndex in this.coordinator.gridsSections.sectionNames) {
        const fencesData = await this.system.firestoreActions.fetchFromFirestore(`boards/${this.boardData.wallID}/fences/${this.coordinator.gridsSections.sectionNames[sectionIndex]}`, "sectionID");

        if (fencesData && fencesData.fences) {
          for (const fence of fencesData.fences) {
            fence.sectionIndex = fencesData.sectionIndex;
            // this.createCollisionRectForFence(fence);
          }
          return fencesData.fences;
        }
      }
      return []
    }
    const readFunctionForFences = (fence) => {
      return { id: fence.fenceID, start: this.getFenceGlobalStartCoordinate(fence), end: this.getFenceGlobalEndIndex(fence) }
    }

    const sectionDoneFetchingCallback = (sectionIndex, dataArray) => {
      const sectionName = this.coordinator.gridsSections.sectionNames[sectionIndex];
      this.fencesFetchedAt[sectionName] = true;
      const startIndex = sectionIndex * this.coordinator.sectionSize
      for (let i = startIndex; i < startIndex + this.boardData.sectionSize; i++) {
        if (!this.fenceAt[i]) this.fenceAt[i] = null; // allow everything
      }
      for (const fence of dataArray) {
        const fenceGlobalStart = this.getFenceGlobalStartIndex(fence);
        for (let i = fenceGlobalStart; i < fenceGlobalStart + fence.length; i++) {
          this.fenceAt[i] = fence;
        }
      }
      // after only this section is loaded, the result might not be correct. becuase overrun from the last section might exist. a section' grid rules are availiable only if the current section and prev section are both loaded.
    }

    this.sectionLoaderForFences = new SectionLoaderSimple(fetchFunctionForFences, sectionLength, readFunctionForFences, sectionDoneFetchingCallback);

    const onChangeFunction = (coord) => {
      this.sectionLoaderForFences.discover_cache_async(coord).then((resultArray) => {
        this.coordinator.updateFences(resultArray);
      });
    }
    this.coordinator.onCoordinateChange["self_fenceLoaderUpdater"] = onChangeFunction;
  }

  async getFenceAt(globalRow, globalColumn) {
    const sectionIndex = Math.floor((this.coordinator.isHorizontal ? globalRow : globalColumn) / this.boardData.sectionSize);
    const prevIndex = Math.max(sectionIndex - 1, 0);
    const nextIndex = Math.max(sectionIndex + 1, this.coordinator.indexBoundary[1]);

    const [prevName, currName, nextName] = [this.coordinator.gridsSections.sectionNames[prevIndex], this.coordinator.gridsSections.sectionNames[sectionIndex], this.coordinator.gridsSections.sectionNames[nextIndex]]

    if (this.fencesFetchedAt[prevName] && this.fencesFetchedAt[currName] && this.fencesFetchedAt[nextName]) {
      // if all sections are fetched, then we can just look up. also values are already clamped. so only check existing index.
      return this.fenceAt[this.coordinator.isHorizontal ? globalColumn : globalRow];
    } else {
      // over call will not be a problem since only one fetch will be called.
      await this.sectionLoaderForFences.discover((this.coordinator.isHorizontal ? globalColumn : globalRow) * this.coordinator.getGridParallelEdge() + 1);
      return this.fenceAt[this.coordinator.isHorizontal ? globalColumn : globalRow];
    }
  }

  insertNewItemToFenceLoader(fence) {
    this.sectionLoaderForFences.insertToLoadedZone(fence);
    this.sectionLoaderForFences.discover_cache_async(this.coordinator.getTheoreticalOffset()).then((resultArray) => {
      this.coordinator.updateFences(resultArray);
    });
  }

  updateFenceLoaderItemPosition(item) {
    this.sectionLoaderForFences.updatePositionInLoadedZone(item);
    this.sectionLoaderForFences.discover_cache_async(this.coordinator.getTheoreticalOffset()).then((resultArray) => {
      this.coordinator.updateFences(resultArray);
    });
  }

  deleteItemFromFenceLoader(item) {
    this.sectionLoaderForFences.deleteFromLoadedZone(item);
    this.sectionLoaderForFences.discover_cache_async(this.coordinator.getTheoreticalOffset()).then((resultArray) => {
      this.coordinator.updateFences(resultArray);
    });
  }

  async enterAWall(boardData, databaseTalker) {
    this.boardData = boardData;
    this.fenceCollisionSystem.setBoundary(0, this.coordinator.theoreticalBoardWidth, 0, this.coordinator.theoreticalBoardHeight); // by far, the boundary is not used yet.
    await this.createFenceLoader(databaseTalker)
  }

  exitAWall() {
    this.boardData = {};
    this.sectionContainer = null;
    if (this.kDraw?.cleanUp) { this.kDraw.cleanUp(); }
  }

  // changed
  getFences(coordinate) {
    const fenceData = this.sectionContainer.getDataFromCoord_Cache(coordinate, true);
    return fenceData.data;
  }

  // changed
  updateFence(operation, fence) {
    if (operation === "upload") {
      this.insertNewItemToFenceLoader(fence);
    } else if (operation === "remove") {
      this.deleteItemFromFenceLoader(fence);
    }
  }

  checkByPassFence(fence, userRole, canBeBypassed = true) {
    const allowDrawingOther = this.system.fenceCoder.isFlagSetByName(fence.rules, "drawingOther"); // if allowing other people to draw.
    if (canBeBypassed && (fence.creatorID === userRole.userID || allowDrawingOther)) { // fence.protectLevel < userRole.power
      return true;
    } else {
      return false;
    }
  }

  // this function deal with drawing(main canvas operations) specifically.
  getTheoreticalBoundaryOfCoordinate(coordinate, userRole = null, useCache = false) {
    let maxBoundary = this.coordinator.getPixelBoundary();
    let closestEnd = maxBoundary[0];
    let closestStart = maxBoundary[1];
    let previousFence = null;
    let nextFence = null;

    let checkRangeFences = null;
    if (useCache) {
      checkRangeFences = this.sectionContainer.getDataFromCoord_Cache(coordinate, true, false); // reget but don't cache it if return something new.
    } else {
      checkRangeFences = this.fences;
    }

    for (const fence of checkRangeFences) {
      // check the boundary of an event. NO by pass by any chance
      if (!this.checkByPassFence(fence, userRole, false) && fence.start >= coordinate) {
        if (closestStart - coordinate > fence.start - coordinate) {
          nextFence = fence;
          closestStart = fence.start;
        }
      }
      if (!this.checkByPassFence(fence, userRole, false) && fence.end <= coordinate) {
        if (coordinate - closestEnd > coordinate - fence.end) {
          previousFence = fence;
          closestEnd = fence.end;
        }
      }

      // check if the current fence block the event.
      if (fence.start <= coordinate && fence.end >= coordinate) {
        if (this.checkByPassFence(fence, userRole, true)) {
          return { boundary: [fence.start, fence.end], adjacentFences: [previousFence, nextFence], blockingFence: null }
        } else {
          return { boundary: [fence.start, fence.end], adjacentFences: [previousFence, nextFence], blockingFence: fence }
        }
      }
    }

    return { boundary: [closestEnd, closestStart], adjacentFences: [previousFence, nextFence], blockingFence: null }
  }
}

class LikeSystem {
  constructor(system, coordinator) {
    this.system = system;
    this.coordinator = coordinator;
    this.liked = {}; // will be saved here if liked
    this.checked = {}; // will be saved here if checked
  }

  createSetIfNotExist(dict, type) {
    if (!dict[type]) {
      dict[type] = new Set();
    }
  }

  addToLiked(type, id) {
    this.createSetIfNotExist(this.liked, type);
    this.createSetIfNotExist(this.checked, type);
    this.liked[type].add(id);
    this.checked[type].add(id);
  }

  removeFromLiked(type, id) {
    this.createSetIfNotExist(this.checked, type);
    this.checked[type].add(id); // still checked
    if (!this.liked[type] || !this.liked[type].has(id)) {
      return;
    }
    this.liked[type].delete(id);
  }

  async checkLiked(type, container, id) {
    if (this.liked[type] && this.liked[type].has(id)) {
      return true;
    } else if (!this.checked[type] || !this.checked[type].has(id)) {
      const liked = await this.system.firestoreActions.fetchFromFirestore(`users/${this.system.getUserID()}/reactions/${type}/${container}/${id}`)
      this.createSetIfNotExist(this.checked, type);
      this.checked[type].add(id);
      if (liked) {
        this.addToLiked(type, id); // add to liked
        return true;
      }
    }
    return false;
  }

  async constructLikeJsonForCanvas(type, id) {
    return {
      likeFieldName: "likes",
      targetType: type,
      targetRealm: "boards",
      boardID: this.coordinator.wallID,
      targetUserID: this.coordinator.wallID,
      userID: this.system.getUserID(),
      targetID: id,             // to board' path
      reactionTargetID: id,     // to user' path
      userRecord: { liked: true },
      idToken: await this.system.obtainIDToken(),
    }
  }

  async likeAFloatingCanvas(type, id) {
    if (this.liked[type] && this.liked[type].has(id)) { return; }
    const updateJson = await this.constructLikeJsonForCanvas(type, id);
    this.system.restServerConnector.likeOrDislikeAnItem(updateJson, 1); // don't wait, just let it go.
    this.addToLiked(type, id);
  }

  async dislikeAFloatingCanvas(type, id) {
    if ((!this.liked[type] || !this.liked[type].has(id)) && this.checked[type] && this.checked[type][id]) { return; }
    const updateJson = await this.constructLikeJsonForCanvas(type, id);
    this.system.restServerConnector.likeOrDislikeAnItem(updateJson, -1); // don't wait, just let it go.
    this.removeFromLiked(type, id);
  }

  async constructLikeJsonForPost(post) {
    return {
      likeFieldName: "likes",
      targetType: "posts",
      targetRealm: "users",
      boardID: this.coordinator.wallID,
      targetUserID: post.creatorID,
      userID: this.system.getUserID(),
      targetID: post.postID,
      reactionTargetID: post.postID,
      userRecord: { liked: true },
      idToken: await this.system.obtainIDToken(),
    }
  }

  async checkLikedForPost(post) {
    const postIDFull = `${post.creatorID}_${post.postID}`
    if (this.liked["posts"] && this.liked["posts"].has(postIDFull)) {
      return true;
    } else if (!this.checked["posts"] || !this.checked["posts"].has(postIDFull)) {
      const liked = await this.system.firestoreActions.fetchFromFirestore(`users/${this.system.getUserID()}/reactions/posts/${post.creatorID}/${post.postID}`)
      this.createSetIfNotExist(this.checked, "posts");
      this.checked["posts"].add(postIDFull);
      if (liked) {
        this.addToLiked("posts", postIDFull); // add to liked
        return true;
      }
    }
    return false;
  }

  async likeAPost(post) {
    const postInstanceID = (post.postInstanceID || `${this.system.getUserID()}_${post.postID}`);
    if (this.liked["posts"] && this.liked["posts"].has(postInstanceID)) { return; }
    const updateJson = await this.constructLikeJsonForPost(post);
    this.system.restServerConnector.likeOrDislikeAnItem(updateJson, 1); // don't wait, just let it go.
    this.addToLiked("posts", postInstanceID);
  }

  async dislikeAPost(post) {
    const postInstanceID = (post.postInstanceID || `${this.system.getUserID()}_${post.postID}`);
    if ((!this.liked["posts"] || !this.liked["posts"].has(postInstanceID)) && this.checked["posts"] && this.checked["posts"][postInstanceID]) { return; }
    const updateJson = await this.constructLikeJsonForPost(post);
    this.system.restServerConnector.likeOrDislikeAnItem(updateJson, -1); // don't wait, just let it go.
    this.removeFromLiked("posts", postInstanceID);
  }
}

class DrawEventTracker {
  constructor(system) {
    this.eventPosition = [0, 0];          // mouse click position. screen position.
    // this.eventCoordinate = [0, 0];        // the position that is converted to theo position.
    this.eventFloatingCanvasRect = null;
    this.system = system;

    this.eventTheoHeight = 0;
    this.eventTheoWidth = 0;

    this.eventData = {};
  }

  setEventPosition(eventX, eventY) {
    this.eventPosition = [eventX, eventY];
    // this.eventCoordinate = this.getOnCanvasCoordinate(eventX, eventY);
  }

  // we don't want this to be screen sized, becuase it's subject to change.
  setEventTheoSize(theoHeight, theoWidth) {
    this.eventTheoHeight = theoHeight;
    this.eventTheoWidth = theoWidth;
  }

  setEventFloatingCanvasRect(floatingCanvasRect) {
    this.eventFloatingCanvasRect = floatingCanvasRect;
  }

  setEventData(newData) {
    this.eventData = newData;
  }

  // check for collision, and decide where this event goes to...
  castAnEventPositionCheck() {
    let floatingCanvasCollision = null;
    // mouseOnCanvasTheoPos is the global position on canvas.
    const mouseOnCanvasTheoPos = this.system.coordinator.boardPositioner.getTheoPositionFromScreenPosition(this.eventPosition[0], this.eventPosition[1]);
    for (let layer = this.system.coordinator.totalFloatingLayers - 1; layer >= 0; layer--) {
      floatingCanvasCollision = this.system.coordinator.floatingCollisionSystems[layer].checkContainPoint(mouseOnCanvasTheoPos[0], mouseOnCanvasTheoPos[1], false);
      if (floatingCanvasCollision) { break; }
    }

    if (!floatingCanvasCollision || !floatingCanvasCollision.dataRef || !floatingCanvasCollision.elementRef || floatingCanvasCollision.type === "postInstance") {
      this.setEventFloatingCanvasRect(null);
    } else {
      this.setEventFloatingCanvasRect(floatingCanvasCollision.dataRef);
    }
  }

  getAndRemoveTheEventData() {
    this.castAnEventPositionCheck(); // set all the values..
    const result = {
      eventPosition: this.eventPosition,
      // eventCoordinate: this.eventCoordinate,
      eventBoxWidth: this.eventTheoWidth,
      eventBoxHeight: this.eventTheoHeight,
      eventFloatingCanvasRect: this.eventFloatingCanvasRect,
      eventData: this.eventData,
    }

    this.eventPosition = [0, 0];
    // this.eventCoordinate = [0, 0];
    this.eventTheoWidth = 0;
    this.eventTheoHeight = 0;
    this.eventFloatingCanvasRect = null;
    this.eventData = {}

    return result;
  }
}


class BoardSettings {
  constructor(system) {
    this.system = system;

    this.displayMode = 0; // 0 means free, 1 means 1:1, 2 means full screen.
    this.onDisplayModeChanged = {};

    this.crossScrollOn = true;
    this.onCrossScrollOnChanged = {};

    this.allowHandsDraw = true;
    this.onAllowHandsDrawChanged = {};

    this.initialBoardOnScreenRatio = 0.9; // read only.
    this.boardOnScreenRatio = this.initialBoardOnScreenRatio; // by default, the board is scaled to 0.9 of the display screen.
    this.onBoardOnScreenRatioChange = {};
  }

  /* setUser(userData) {
    this.userData = userData;
    this.settingDisplayMode(userData.displayMode !== undefined ? userData.displayMode : 0); // 0 means free, 1 means 1:1, 2 means full screen.
    this.settingCrossScrolling(userData.crossScrollOn !== undefined ? userData.crossScrollOn : true);
    this.settingAllowHandsDraw(userData.allowHandsDraw !== undefined ? userData.allowHandsDraw : true);
  } */

  setBoard(boardData) {
    this.boardData = boardData;
    this.settingDisplayMode(boardData.defaultFullScreenDisplay === true ? 2 : 0); // 0 means free, 1 means 1:1, 2 means full screen.
    this.settingCrossScrolling(boardData.allowCrossScrollingByDefault ? true : false);
    // this.settingAllowHandsDraw(boardData.allowHandsDraw !== undefined ? boardData.allowHandsDraw : true);

    this.initialBoardOnScreenRatio = this.boardData.defaultDisplayRatio || this.initialBoardOnScreenRatio; // read saves or the default.
    this.boardOnScreenRatio = this.initialBoardOnScreenRatio; // set the board display size.
  }

  setBoardOnScreenRatio(newRatio) {
    newRatio = Math.min(Math.max(newRatio, 0.0), 5.0);
    this.boardOnScreenRatio = newRatio;
    for (const key in this.onBoardOnScreenRatioChange) {
      this.onBoardOnScreenRatioChange[key](newRatio);
    }
  }

  settingDisplayMode(newMode) {
    this.displayMode = newMode;
    for (const key in this.onDisplayModeChanged) {
      this.onDisplayModeChanged[key](newMode);
    }
  }

  settingCrossScrolling(isOn = true) {
    this.crossScrollOn = isOn;
    for (const key in this.onCrossScrollOnChanged) {
      this.onCrossScrollOnChanged[key](isOn);
    }
  }

  /* settingAllowHandsDraw(allows = true) {
    this.allowHandsDraw = allows;
    reflectFunction(this.onAllowHandsDrawChanged, allows);
  }*/
}