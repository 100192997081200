import {ImageProcessor} from "./ImageProcessor.js"

export class Base64Utility {
  constructor() {}
  static base64DataToByteData(base64ImageData, needsCleanUp = true) {
    let dataStringClean = base64ImageData;
    if (needsCleanUp) { dataStringClean = ImageProcessor.static_cleanImageDataString(base64ImageData); }
    const imageData = Uint8Array.from(atob(dataStringClean), c => c.charCodeAt(0));
    return imageData;
  }
}