// The lookBackDistance and lookFrontDistance has to be larger than the item' min length.
// readDataFunction is a function that read any data that is providing. must return a json of {start: req, end: nReq, id: req}
export class SectionLoaderSimple {
  constructor(rangeFetchFunction, sectionLength, readDataFunction, sectionFetchCallback = null) {
    this.rangeFetchFunction = rangeFetchFunction;
    this.readDataFunction = readDataFunction;

    this.sectionLength = sectionLength; // how long each range will be.

    this.underDiscoveringSections = {}; // current fetching promise
    this.underFetchingSections = {};    // current fetching promise
    this.cheekyItems = {};              // the items that will always be in the return no matter what.

    this.fetchedSections = new Set(); // already fetched as a side...
    this.discoveredSections = new Set(); // already fetched as the middle one

    // the container that stores all the fetched data
    this.sectionStorage = new SectionContainerSimple(sectionLength, readDataFunction);

    this.sectionFetchCallback = sectionFetchCallback; // func(sectinIndex, itemArray)

    this.cachedIndex = -1;
    this.cachedDataArray = [];
  }

  getFromSavedData(id) {
    return this.sectionStorage.getSavedObjectFromID(id);
  }

  insertToLoadedZone(item) {
    this.sectionStorage.insertToSections(item);
    this.dataModified();
  }

  updatePositionInLoadedZone(item) {
    const updated = this.sectionStorage.positionUpdate(item);
    if (updated) { this.dataModified(); }
  }

  deleteFromLoadedZone(item) {
    this.sectionStorage.deleteFromSections(item);
    const itemData = this.readDataFunction(item);
    delete this.cheekyItems[itemData.id];
    this.dataModified();
  }

  addToCheekyItems(item) {
    if (item) {
      const itemData = this.readDataFunction(item);
      // just add to the cheeky list. don't worry about the current display.
      this.cheekyItems[itemData.id] = item;
      // this.dataModified();
    }
  }

  removeFromCheekyItems(item) {
    if (item) {
      const itemData = this.readDataFunction(item);
      // just remove from the cheeky list. however, if it's being displayed currently, don't worry about it.
      delete this.cheekyItems[itemData.id];
      // this.dataModified();
    }
  }

  emptyCheekyItems() {
    this.cheekyItems = {};
  }

  dataModified() {
    this.cachedIndex = -1;
    this.cachedDataArray = [];
    if (this.onDataChange) {
      this.onDataChange();
    }
  }

  getDiscoveriedCache() {
    return this.cachedDataArray;
  }

  async discover_cache_async(coord, fetchCallback = null) {
    const result = await this.discover_cache(coord, fetchCallback);
    return result
  }

  // might reutrn a promise, might return a value
  discover_cache(coord, fetchCallback = null) {
    const index = this.getSectionIndex(coord);
    if (this.cachedIndex === index) {
      return this.cachedDataArray;
    } else {
      const fetchPromise = this.discover(coord, fetchCallback).then((result) => {
        this.cachedIndex = index;
        this.cachedDataArray = result; // cache it.
        return result;
      })
      return fetchPromise;
    }
  }

  async discover(coord, fetchCallback = null) {
    const index = this.getSectionIndex(coord);
    if (this.discoveredSections.has(index)) {
      return this.sectionStorage.getItemsFromSections(coord, this.cheekyItems);
    } else if (index in this.underDiscoveringSections) {
      // the section is fetched, but the result is not ready yet.
      await this.underDiscoveringSections[index]; // await until the fetching process is done...
      const dataArray = this.sectionStorage.getItemsFromSections(coord, this.cheekyItems);
      if (fetchCallback) { fetchCallback(dataArray) }
      return dataArray;
    } else {
      // should only fetch for unfetch parts. Avoid refetch.
      return await this.fetchAndReturnTheItems(coord, fetchCallback);
    }
  }

  async fetchAndReturnTheItems(coord, fetchCallback) {
    const currentIndex = this.getSectionIndex(coord);
    this.underDiscoveringSections[currentIndex] = this.fetchForSections(coord);
    await this.underDiscoveringSections[this.getSectionIndex(coord)]; // await it
    delete this.underDiscoveringSections[currentIndex]; // as now it's done, delete it from the array...
    // this.sectionStorage.insertManyItemsToSections(result);
    const dataArray = this.sectionStorage.getItemsFromSections(coord, this.cheekyItems);
    if (fetchCallback) {
      fetchCallback(dataArray)
    }
    return dataArray;
  }

  // the point of this function is not to return any result, but to write the data into the storage and flag "fetched" sections.
  async fetchForSections(coord) {
    // fetch 3 section in total, middle one will be marked as discovered, the two other one is loaded.
    const allFetchingSections = this.getSectionsSectionsAndCoords(coord);
    const sections = allFetchingSections.sections;
    const coords = allFetchingSections.coords;
    const currentIndex = sections[1];

    if (!this.discoveredSections.has(currentIndex) && currentIndex >= 0) {
      let fetches = [[], [], []]
      // this.underDiscoveringSection.add(currentIndex); // add it before the fetchings are done to avoid multiple call of the fetch.
      for (let i = 0; i < 3; i++) {
        if (sections[i] >= 0 && !this.underFetchingSections[sections[i]] && !this.fetchedSections.has(sections[i])) {
          const fetchProcess = async () => {
            // console.log("look at", coord, "look distance:", this.sectionLength);
            let returnedArray = await this.rangeFetchFunction(sections[i], coords[i], 0, this.sectionLength); // fetch the section range and get the result
            if (!returnedArray) { returnedArray = [] }
            this.sectionStorage.insertManyItemsToSections(returnedArray); // this will just insert these items into the storage
            if (this.sectionFetchCallback) { this.sectionFetchCallback(sections[i], returnedArray); }
            return returnedArray;
          }
          this.underFetchingSections[sections[i]] = fetchProcess();
        }
      }
      const results = await Promise.all([
        (this.underFetchingSections[sections[0]] || []),
        (this.underFetchingSections[sections[1]] || []),
        (this.underFetchingSections[sections[2]] || []),
      ]);
      this.fetchedSections.add(sections[0]);
      this.fetchedSections.add(sections[1]);
      this.fetchedSections.add(sections[2]);
      delete this.underFetchingSections[sections[0]]
      delete this.underFetchingSections[sections[1]]
      delete this.underFetchingSections[sections[2]]

      this.discoveredSections.add(currentIndex);
    }
    return [];
  }

  getSectionIndex(coord) {
    const sectionIndex = Math.floor(coord / this.sectionLength);
    return sectionIndex;
  }

  // return the section index and beginning coordinate of current, prev, and next section of given coord.
  getSectionsSectionsAndCoords(coord) {
    const currentSection = this.getSectionIndex(coord);
    const currentCoord = this.sectionLength * currentSection;
    // console.log(currentCoord, " after griding section, current section start is");
    const prevCoord = currentCoord - this.sectionLength;
    const nextCoord = currentCoord + this.sectionLength;
    // values are without bound check, so need to check before using them
    return {
      sections: [currentSection - 1, currentSection, currentSection + 1],
      coords: [prevCoord, currentCoord, nextCoord],
    }
  }
}


class SectionContainerSimple {
  constructor(sectionLength, readDataFunction) {
    this.readDataFunction = readDataFunction;
    this.sectionLength = sectionLength;

    this.sections = [];
    this.allExistingItems = {};
  }

  // Main entrence to get items. it will return the result of 3 sections.
  getItemsFromSections(coord, cheekyAdd = {}) {
    const index = this.getSectionIndex(coord);
    let returnSections = [{}, {}, {}];
    let sectionIndex = 0;
    for (let i = index - 1; i <= index + 1; i++) {
      if (i < this.sections.length && i >= 0 && this.sections[i]) {
        returnSections[sectionIndex] = this.sections[i];
        sectionIndex += 1;
      }
    }
    const result = Object.values({ ...returnSections[0], ...returnSections[1], ...returnSections[2], ...cheekyAdd });
    return result;
  }

  // each full section contains 2 small section, that are forward and backward. this function get the small section index
  getSectionIndex(coord) {
    const sectionIndex = Math.floor(coord / this.sectionLength);
    return sectionIndex;
  }

  insertManyItemsToSections(arrayOfItems) {
    for (const item of arrayOfItems) {
      this.insertToSections(item);
    }
  }

  deleteManyItemsToSections(arrayOfItems) {
    for (const item of arrayOfItems) {
      this.deleteFromSections(item);
    }
  }

  insertToSections(item) {
    const newItemData = this.readDataFunction(item);
    let itemToInsert = item;

    if (newItemData.id in this.allExistingItems) {
      const existingItem = this.allExistingItems[newItemData.id].item;
      if (item.mergeRule) {
        const replace = item.mergeRule(existingItem);
        if (!replace) {                                         // the difference is the item' ref. though the id is the same. different part of the code keep the different reference to this item.
          itemToInsert = existingItem;                          // insert the ori item instead
        }                                                       // else, just keep the new item to be inserted.
      }
      this.deleteFromSections(existingItem); // delete from the list first. in case of duplicated
    }
    const startIndex = this.getSectionIndex(newItemData.start);
    if (startIndex < 0) {
      return;
    }
    if (!this.sections[startIndex]) { this.sections[startIndex] = {}; }
    this.sections[startIndex][newItemData.id] = itemToInsert; // add the item at the place
    this.allExistingItems[newItemData.id] = { index: startIndex, item: itemToInsert };
  }

  // the item' position is already changed. this is to check if the contained section has changed.
  positionUpdate(item) {
    const itemData = this.readDataFunction(item);
    let newIndex = this.getSectionIndex(itemData.start);
    if (newIndex < 0) { newIndex = 0; }
    if (itemData.id in this.allExistingItems) {
      const oldIndex = this.allExistingItems[itemData.id].index; // the stored index
      if (oldIndex !== newIndex) {
        delete this.sections[oldIndex][itemData.id]; // remove it from any section. this should be changed later.
        if (!this.sections[newIndex]) { this.sections[newIndex] = {}; }
        this.sections[newIndex][itemData.id] = item; // add the item at the place
        this.allExistingItems[itemData.id].index = newIndex;
        return true; // updated
      } else {
        // the section has not changed.
        return false;
      }
    } else {
      // doesn't contain, then don't do anything
      return false;
    }
  }

  getSavedObjectFromID(id) {
    if (id in this.allExistingItems) {
      return this.allExistingItems[id].item;
    }
    return undefined;
  }

  deleteFromSections(item) {
    const itemData = this.readDataFunction(item);
    const startIndex = this.getSectionIndex(itemData.start);
    if (startIndex < 0) {
      return;
    }
    if (this.sections[startIndex]) {
      delete this.sections[startIndex][itemData.id];
      delete this.allExistingItems[itemData.id];
    }
  }
}



// The lookBackDistance and lookFrontDistance has to be larger than the item' min length.
// readDataFunction is a function that read any data that is providing. must return a json of {start: req, end: nReq, id: req}
export class SectionLoader {
  constructor(rangeFetchFunction, lookBackDistance, lookFrontDistance, readDataFunction) {
    this.rangeFetchFunction = rangeFetchFunction;
    this.readDataFunction = readDataFunction;
    this.lookFrontDistance = lookFrontDistance;
    this.lookBackDistance = lookBackDistance;
    this.fullSectionLength = lookFrontDistance + lookBackDistance; // how long each range will be.

    this.underDiscoveringSection = new Set(); // the middle section that is during fetching process. will be moved to discoveredSectionHalf.
    this.fetchedSection = new Set(); // the section is fetched, no need to fetch it again.
    this.discoveredSection = new Set(); // already fetched as the middle one

    // the container that stores all the fetched data
    this.sectionStorage = new SectionContainer(lookBackDistance, lookFrontDistance, readDataFunction);

    this.cachedIndex = -1;
    this.cachedDataArray = [];
  }

  getFromSavedData(id) {
    return this.sectionStorage.getSavedObjectFromID(id);
  }

  insertToLoadedZone(item) {
    this.sectionStorage.insertToSections(item);
    this.dataModified();
  }

  updatePositionInLoadedZone(item) {
    const updated = this.sectionStorage.positionUpdate(item);
    if (updated) { this.dataModified(); }
  }

  deleteFromLoadedZone(item) {
    this.sectionStorage.deleteFromSections(item);
    this.dataModified();
  }

  dataModified() {
    this.cachedIndex = -1;
    this.cachedDataArray = [];
    if (this.onDataChange) {
      this.onDataChange();
    }
  }

  async discover_cache_async(coord, fetchCallback = null) {
    const result = await this.discover_cache(coord, fetchCallback);
    return result
  }

  // might reutrn a promise, might return a value
  discover_cache(coord, fetchCallback = null) {
    const index = this.getSectionIndex(coord);
    if (this.cachedIndex === index) {
      return this.cachedDataArray;
    } else {
      const fetchPromise = this.discover(coord, fetchCallback).then((result) => {
        this.cachedIndex = index;
        this.cachedDataArray = result; // cache it.
        return result;
      })
      return fetchPromise;
    }
  }

  async discover(coord, fetchCallback = null) {
    const index = this.getSectionIndex(coord);
    if (this.discoveredSection.has(index)) {
      return this.sectionStorage.getItemsFromSections(coord);
    } else if (this.underDiscoveringSection.has(index)) {
      // the section is fetched, but the result is not ready yet.
      return [];
    } else {
      // should only fetch for unfetch parts. Avoid refetch.
      return await this.fetchAndReturnTheItems(coord, fetchCallback);
    }
  }

  async fetchAndReturnTheItems(coord, fetchCallback) {
    const result = await this.fetchForSections(coord);
    // this.sectionStorage.insertManyItemsToSections(result);
    const dataArray = this.sectionStorage.getItemsFromSections(coord);
    if (fetchCallback) {
      fetchCallback(dataArray)
    }
    return dataArray;
  }

  async fetchForSections(coord) {
    // fetch 3 section in total, middle one will be marked as discovered, the two other one is loaded.
    const allFetchingSections = this.getSectionsIndexesAndCoords(coord);
    const indexes = allFetchingSections.indexes;
    const coords = allFetchingSections.coords;
    const currentIndex = indexes[1];

    if (!this.underDiscoveringSection.has(currentIndex) && !this.discoveredSection.has(currentIndex) && currentIndex >= 0) {
      let fetches = [[], [], []]
      this.underDiscoveringSection.add(currentIndex); // add it before the fetchings are done to avoid multiple call of the fetch.

      for (let i = 0; i < 3; i++) {
        if (indexes[i] >= 0 && !this.fetchedSection.has(indexes[i])) {
          this.fetchedSection.add(indexes[i]); // the section is fetched
          const fetchProcess = async () => {
            const returnedArray = await this.rangeFetchFunction(coords[i], 0, indexes[i] % 2 === 0 ? this.lookBackDistance : this.lookFrontDistance); // fetch the section range and get the result
            this.sectionStorage.insertManyItemsToSections(returnedArray);
            return returnedArray;
          }
          fetches[i] = fetchProcess();
        }
      }

      const results = await Promise.all(fetches.filter(Boolean)); // extract []
      const fetchResult = [].concat(...results);

      this.underDiscoveringSection.delete(currentIndex);
      this.discoveredSection.add(currentIndex);

      return fetchResult;
    }

    return [];
  }

  getFullSectionIndex(coord) {
    return Math.floor(coord / this.fullSectionLength);
  }

  getSectionIndex(coord) {
    const fullSectionNumber = this.getFullSectionIndex(coord);
    if (coord % this.fullSectionLength < this.lookBackDistance) { // within the look back range.
      return fullSectionNumber * 2;
    } else {
      return (fullSectionNumber * 2) + 1;
    }
  }

  getSectionsIndexesAndCoords(coord) {
    const fullSectionNumber = this.getFullSectionIndex(coord);
    let [currentIndex, currentCoord, prevCoord, nextCoord] = [-1, -1, -1, -1];
    if (coord % this.fullSectionLength < this.lookBackDistance) { // within the look back range.
      currentIndex = fullSectionNumber * 2;
      currentCoord = fullSectionNumber * this.fullSectionLength;
      prevCoord = currentCoord - this.lookFrontDistance;
      nextCoord = currentCoord + this.lookBackDistance;
    } else {
      currentIndex = (fullSectionNumber * 2) + 1;
      currentCoord = fullSectionNumber * this.fullSectionLength + this.lookBackDistance;
      prevCoord = currentCoord - this.lookBackDistance;
      nextCoord = currentCoord + this.lookFrontDistance;
    }
    // values are without bound check, so need to check before using them
    return {
      indexes: [currentIndex - 1, currentIndex, currentIndex + 1],
      coords: [prevCoord, currentCoord, nextCoord],
    }
  }
}



class SectionContainer {
  constructor(lookBackwardDistance, lookForwardDistance, readDataFunction) {
    this.readDataFunction = readDataFunction;

    this.lookBackwardDistance = lookBackwardDistance;
    this.lookForwardDistance = lookForwardDistance;
    this.fullSectionLength = lookBackwardDistance + lookForwardDistance;

    this.sections = [];
    this.allExistingItems = {};
  }

  // Main entrence to get items. it will return the result of 3 sections.
  getItemsFromSections(coord) {
    const index = this.getSectionIndex(coord);
    let returnSections = [{}, {}, {}];
    let sectionIndex = 0;
    for (let i = index - 1; i <= index + 1; i++) {
      if (i < this.sections.length && i >= 0 && this.sections[i]) {
        returnSections[sectionIndex] = this.sections[i];
        sectionIndex += 1;
      }
    }
    const result = Object.values({ ...returnSections[0], ...returnSections[1], ...returnSections[2] });
    return result;
  }

  // each full section contains 2 small section, that are forward and backward. this function get the small section index
  getSectionIndex(coord) {
    const sectionNumber = Math.floor(coord / this.fullSectionLength);
    // console.log(this.fullSectionLength, this.lookBackwardDistance);
    if (coord % this.fullSectionLength < this.lookBackwardDistance) { // within the look back range.
      const index = sectionNumber * 2;
      return index;
    } else {
      const index = (sectionNumber * 2) + 1;
      return index
    }
  }

  insertManyItemsToSections(arrayOfItems) {
    for (const item of arrayOfItems) {
      this.insertToSections(item);
    }
  }

  deleteManyItemsToSections(arrayOfItems) {
    for (const item of arrayOfItems) {
      this.deleteFromSections(item);
    }
  }

  insertToSections(item) {
    const newItemData = this.readDataFunction(item);
    let itemToInsert = item;

    if (newItemData.id in this.allExistingItems) {
      const existingItem = this.allExistingItems[newItemData.id].item;
      if (item.mergeRule) {
        const replace = item.mergeRule(existingItem);
        if (!replace) {                                         // the difference is the item' ref. though the id is the same. different part of the code keep the different reference to this item.
          itemToInsert = existingItem;                          // insert the ori item instead
        }                                                       // else, just keep the new item to be inserted.
      }
      this.deleteFromSections(existingItem); // delete from the list first. in case of duplicated
    }
    const startIndex = this.getSectionIndex(newItemData.start);
    if (startIndex < 0) {
      return;
    }
    if (!this.sections[startIndex]) { this.sections[startIndex] = {}; }
    this.sections[startIndex][newItemData.id] = itemToInsert; // add the item at the place
    this.allExistingItems[newItemData.id] = { index: startIndex, item: itemToInsert };
  }

  // the item' position is already changed. this is to check if the contained section has changed.
  positionUpdate(item) {
    const itemData = this.readDataFunction(item);
    let newIndex = this.getSectionIndex(itemData.start);
    if (newIndex < 0) { newIndex = 0; }
    if (itemData.id in this.allExistingItems) {
      const oldIndex = this.allExistingItems[itemData.id].index; // the stored index
      if (oldIndex !== newIndex) {
        delete this.sections[oldIndex][itemData.id]; // remove it from any section. this should be changed later.
        if (!this.sections[newIndex]) { this.sections[newIndex] = {}; }
        this.sections[newIndex][itemData.id] = item; // add the item at the place
        this.allExistingItems[itemData.id].index = newIndex;
        return true; // updated
      } else {
        // the section has not changed.
        return false;
      }
    } else {
      // doesn't contain, then don't do anything
      return false;
    }
  }

  getSavedObjectFromID(id) {
    if (id in this.allExistingItems) {
      return this.allExistingItems[id].item;
    }
    return undefined;
  }

  deleteFromSections(item) {
    const itemData = this.readDataFunction(item);
    const startIndex = this.getSectionIndex(itemData.start);
    if (startIndex < 0) {
      return;
    }
    if (this.sections[startIndex]) {
      delete this.sections[startIndex][itemData.id];
      delete this.allExistingItems[itemData.id];
    }
  }
}


export class IndexData {
  constructor(divideLength, visiableFrontIndexes, visibleBackIndexes, indexRange, exactFetchFunctionForColumn) {
    this.data = {}; // array of data
    this.isFetching = new Set();

    this.indexRange = indexRange;
    this.divideLength = divideLength;
    this.visiableFrontIndexes = visiableFrontIndexes;
    this.visibleBackIndexes = visibleBackIndexes;
    this.exactFetchFunctionForColumn = exactFetchFunctionForColumn;
  }

  indexCheck(index) {
    if (index < this.indexRange[0] || index > this.indexRange[1]) {
      return false;
    }
    return true;
  }

  // clamped the range already.
  getIndexesInRange(coord) {
    const index = Math.floor(coord / this.divideLength);
    if (!this.indexCheck(index)) { // return empty for invalid index
      return [];
    }
    return [Math.max(this.indexRange[0], index - this.visibleBackIndexes), Math.min(this.indexRange[1], index + this.visiableFrontIndexes)];
  }

  getDataAtIndex(index, canReturnPromise = false, callback = null) {
    if (!this.indexCheck(index)) { return null; }
    if (index in this.data) {
      return this.data[index];
    } else if (!this.isFetching.has(index)) {
      this.isFetching.add(index)
      const fetchPromise = this.exactFetchFunctionForColumn(index).then((result) => {
        this.data[index] = result; // add to the data after done.
        if (callback) { callback(result) };
      });
      if (canReturnPromise) { return fetchPromise }
    }
    return null;
  }

  // callback if for, if you don't want a promise returned, but you still want to have some mechanism to happen after it's done
  getDataAtCoordinate(coord, canReturnPromise = false, callback = null) {
    const indexes = this.getIndexesInRange(coord);
    const result = []
    for (const index of indexes) {
      // could be the actual data, or it can be a promise
      result.push(this.getDataAtIndex(index, canReturnPromise, null)); // if canReturnPromise, then it can be either data or promise, if can't then it will be data and null.
    }
    const fetchPromises = Promise.all(result).then((resultArray) => {
      if (callback) { callback(resultArray) };
    });

    if (canReturnPromise) {
      return fetchPromises; // a promise that is waitable
    }
    return result; // mix of data and nulls
  }


  softGetDataAtIndex(index, callback = null) { return this.getDataAtIndex(index, false, callback); }
  async hardGetDataAtIndex(index, callback = null) { return await this.getDataAtIndex(index, true, callback); }

  softGetDataAtCoordinate(coord, callback = null) { return this.getDataAtCoordinate(coord, false, callback); }
  async hardGetDataAtCoordinate(coord, callback = null) { return await this.getDataAtCoordinate(coord, true, callback); }
}

/*
note for promise and async:
a async function will return a promise always. await can be used in async function. However, await can't be used in promise. when an async function which contains "await" returns, it returns a promise, but the await is not as await keywords. It's transferred to some mechanism that controls execution order.

if you want a function to be partially async, means can be awaited, but also can return a value immediately, you use if statement to either return a value or a promise. This way the function doesn't have to be a async function, but can also be awaited if needed.
The promise can be the execution of a another async funciton, if some await needed in the async mechanisms.
*/