export class FloatPageController {
  constructor() {
    this.setFloatPageHook = null;
    this.onFloatPageChange = {};
    this.floatPageIndex = 0;
  }

  getFloatPage() {
    return this.floatPageIndex;
  }

  setFloatPage(index) {
    if (this.setFloatPageHook) {
      this.setFloatPageHook(index);
      this.floatPageIndex = index;
      for (const key in this.onFloatPageChange) {
        this.onFloatPageChange[key](index);
      }
    }
  }

  assignSetFloatPageHook(setFloatPage) {
    this.setFloatPageHook = setFloatPage;
  }

  addToOnFloatPageChange(key, func) {
    this.onFloatPageChange[key] = func;
  }
}