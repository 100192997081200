import React, { useState, useRef, useContext, useEffect, useMemo } from 'react';
import { SystemOperationsContext } from "../context/SystemRunnerContext";
import LocalDrawingCanvas from "./LocalDrawingCanvas.jsx";
import PostCanvas from "./PostsCanvas.jsx"
import FloatCanvases from "./FloatCanvases.jsx"
import ButtonsCanvas from "./ButtonsCanvas.jsx"
import FenceDefinition from "./FenceDefinition.jsx";
import FenceLines from "./FenceLines.jsx";
import { BoardAxisGuideline } from "./BoardAxisGuideline.jsx";
import { CoordinateAxis } from "./CoordinateAxis.jsx";
import { RequestDataMaker } from "../system/RestServerConnector.js"
// import ToolCustomizationMenu from "./ToolCustomizationMenu.jsx";
import './styles/Board.scss';

import { useTheme } from '@mui/system';

const getStyles = (system, theme) => {
  let fullScreenMode = false;
  let realSizeDiaplay = false;
  if (system.coordinator.boardSettings.displayMode === 1) { // should only have two display mode. 1:1 should change the ratio not the mode forcing
    fullScreenMode = false;
    realSizeDiaplay = true;
  } else if (system.coordinator.boardSettings.displayMode === 2) {
    fullScreenMode = true;
    realSizeDiaplay = false;
  }

  const isHorizontal = system.coordinator.isHorizontal;
  const boardInnerWidth = system.coordinator.theoreticalBoardWidth;
  const boardInnerHeight = system.coordinator.theoreticalBoardHeight;
  let lengthOverScroll = system.coordinator.lengthOverScroll;
  let edgeWidth = system.coordinator.edgeWidth;
  if (fullScreenMode) {
    lengthOverScroll = 0;
    edgeWidth = 0;
  }

  const fullBoardWidth = boardInnerWidth + 2 * edgeWidth;
  const fullBoardHeight = boardInnerHeight + 2 * edgeWidth;

  const perfectBoardShorterEdgeLength = (fullScreenMode ? 1 : system.coordinator.boardSettings.boardOnScreenRatio) * (isHorizontal ? window.innerHeight : window.innerWidth);
  let widthOverScroll = (isHorizontal ? window.innerHeight : window.innerWidth) * 0.55; // so far, this is theo size. will be scaled later.

  // display scale can be easily obtained by using positioner. using rect actual display size / theoretical size.
  let displayScale = perfectBoardShorterEdgeLength / (isHorizontal ? fullBoardHeight : fullBoardWidth);
  if (fullScreenMode) {
    widthOverScroll = 0; // no extending.
  }
  if (realSizeDiaplay) {
    displayScale = 1;
  }
  widthOverScroll /= displayScale;
  const boardPerfectPlacement = fullScreenMode ? 0 : Math.max(((1 - system.coordinator.boardSettings.boardOnScreenRatio) / 2), 0) * (isHorizontal ? window.innerHeight : window.innerWidth);
  const boardOriginalOverscroll = fullScreenMode ? 0 : widthOverScroll - boardPerfectPlacement;

  const horizonalEdge = {
    backgroundRepeat: "repeat-x",
    whiteSpace: "nowrap",
    backgroundSize: "auto 100%",
    zIndex: 20,
  }
  const verticalEdge = {
    backgroundRepeat: "repeat-y",
    whiteSpace: "nowrap",
    backgroundSize: "auto 100%",
    zIndex: 20,
  }
  const edgeOffset = 3;
  const edgeOverFlow = edgeOffset * 2;

  return {
    perfectBoardShorterEdgeLength: perfectBoardShorterEdgeLength,
    displayScale: displayScale,
    fullScreenMode,
    widthOverScroll, fullBoardWidth, fullBoardHeight, boardPerfectPlacement, boardOriginalOverscroll,
    mainContainer: {
      position: "absolute", zIndex: theme.zIndex.boardPanel,
      display: "flex", flexDirection: isHorizontal ? "row" : "column",
      [isHorizontal ? "top" : "left"]: `${system.coordinator.boardSettings.crossScrollOn ? widthOverScroll : boardPerfectPlacement}px`,
    },

    sideOverScroll: {
      position: "absolute",
      width: isHorizontal ? fullBoardWidth : widthOverScroll,
      height: isHorizontal ? widthOverScroll : fullBoardWidth,
      [isHorizontal ? "left" : "top"]: `${lengthOverScroll}px`,
      [isHorizontal ? "top" : "left"]: (isHorizontal ? fullBoardHeight : fullBoardWidth),
    },
    endOverScroll: {
      position: "absolute",
      width: isHorizontal ? lengthOverScroll : fullBoardWidth,
      height: isHorizontal ? fullBoardHeight : lengthOverScroll,
      [isHorizontal ? "top" : "left"]: `${0}px`,
      [isHorizontal ? "left" : "top"]: ((isHorizontal ? fullBoardWidth : fullBoardHeight) + lengthOverScroll),
    },

    // is used to contain the whole board, itself should have the offset for the overscroll.
    boardContainer: {
      position: "absolute",
      [isHorizontal ? "left" : "top"]: lengthOverScroll, // the left margin come from
      zIndex: theme.zIndex.boardPanel,
    },
    innerBoard: {
      backgroundRepeat: isHorizontal ? 'repeat-x' : 'repeat-y', whiteSpace: 'nowrap', backgroundSize: 'auto 100%',
      // backgroundImage: system.coordinator.boardPanelImage,
      backgroundColor: system.coordinator.boardStyle.colors.M,
      position: "absolute", zIndex: theme.zIndex.boardPanel,

      top: `${edgeWidth}px`, left: `${edgeWidth}px`,
      width: `${boardInnerWidth}px`, height: `${boardInnerHeight}px`,
    },
    topEdge: {
      // backgroundImage: system.coordinator.boardTopEdgeImage,
      ...horizonalEdge,
      boxShadow: `inset 0px 2px 2px ${system.coordinator.boardStyle.colors.S}, 0 5px 10px ${system.coordinator.boardStyle.colors.S}`,
      zIndex: 21,

      backgroundColor: system.coordinator.boardStyle.colors.T,
      position: "absolute", zIndex: theme.zIndex.boardFrame,
      top: `${0}px`, left: `${edgeWidth - edgeOffset}px`,
      height: `${edgeWidth}px`, width: `${boardInnerWidth + edgeOverFlow}px`,
    },
    bottomEdge: {
      // backgroundImage: system.coordinator.boardBottomEdgeImage,
      ...horizonalEdge,
      boxShadow: `inset 0px -2px 2px ${system.coordinator.boardStyle.colors.S}, 0 -5px 10px ${system.coordinator.boardStyle.colors.S}`,
      zIndex: 21,

      backgroundColor: system.coordinator.boardStyle.colors.B,
      position: "absolute", zIndex: theme.zIndex.boardFrame,
      top: `${edgeWidth + boardInnerHeight}px`, left: `${edgeWidth - edgeOffset}px`,
      height: `${edgeWidth}px`, width: `${boardInnerWidth + edgeOverFlow}px`,
    },
    leftEdge: {
      // backgroundImage: system.coordinator.boardLeftEdgeImage,
      ...verticalEdge,
      boxShadow: `inset 2px 0px 2px ${system.coordinator.boardStyle.colors.S}, 5px 0px 10px ${system.coordinator.boardStyle.colors.S}`,
      zIndex: 21,

      backgroundColor: system.coordinator.boardStyle.colors.L,
      position: "absolute", zIndex: theme.zIndex.boardFrame,
      top: `${edgeWidth - edgeOffset}px`, left: `${0}px`,
      height: `${boardInnerHeight + edgeOverFlow}px`, width: `${edgeWidth}px`,
    },
    rightEdge: {
      // backgroundImage: system.coordinator.boardRightEdgeImage,
      ...verticalEdge,
      boxShadow: `inset -2px 0px 2px ${system.coordinator.boardStyle.colors.S}, -5px 0px 10px ${system.coordinator.boardStyle.colors.S}`,
      zIndex: 21,

      backgroundColor: system.coordinator.boardStyle.colors.R,
      position: "absolute", zIndex: theme.zIndex.boardFrame,
      top: `${edgeWidth - edgeOffset}px`, left: `${edgeWidth + boardInnerWidth}px`,
      height: `${boardInnerHeight + edgeOverFlow}px`, width: `${edgeWidth}px`,
    },
    cornerTL: {
      // backgroundImage: system.coordinator.cornerTLImage,
      backgroundColor: system.coordinator.boardStyle.colors.TL,
      boxShadow: `inset 2px 2px 2px ${system.coordinator.boardStyle.colors.S}`,

      position: "absolute", zIndex: theme.zIndex.boardFrame,
      top: `${0}px`, left: `${0}px`,
      height: `${edgeWidth}px`, width: `${edgeWidth}px`,
    },
    cornerTR: {
      // backgroundImage: system.coordinator.cornerTRImage,
      backgroundColor: system.coordinator.boardStyle.colors.TR,
      boxShadow: `inset -2px 2px 2px ${system.coordinator.boardStyle.colors.S}`,

      position: "absolute", zIndex: theme.zIndex.boardFrame,
      top: `${0}px`, left: `${edgeWidth + boardInnerWidth}px`,
      height: `${edgeWidth}px`, width: `${edgeWidth}px`,
    },
    cornerBL: {
      // backgroundImage: system.coordinator.cornerBLImage,
      backgroundColor: system.coordinator.boardStyle.colors.BL,
      boxShadow: `inset 2px -2px 2px ${system.coordinator.boardStyle.colors.S}`,

      position: "absolute", zIndex: theme.zIndex.boardFrame,
      top: `${edgeWidth + boardInnerHeight}px`, left: `${0}px`,
      height: `${edgeWidth}px`, width: `${edgeWidth}px`,
    },
    cornerBR: {
      // backgroundImage: system.coordinator.cornerBRImage,
      backgroundColor: system.coordinator.boardStyle.colors.BR,
      boxShadow: `inset -2px -2px 2px ${system.coordinator.boardStyle.colors.S}`,

      position: "absolute", zIndex: theme.zIndex.boardFrame,
      top: `${edgeWidth + boardInnerHeight}px`, left: `${edgeWidth + boardInnerWidth}px`,
      height: `${edgeWidth}px`, width: `${edgeWidth}px`,
    },
  }
}

let serverWasReady = false;
const Board = ({ }) => {
  // the wall should be initialized when entering this page.
  const { system_operations } = useContext(SystemOperationsContext);
  const [serverReady, setServerReady] = useState(system_operations.socket_connector.serverIsReady);

  const [autoScrolling, setAutoScrolling] = useState(0);
  const [existingFences, setExistingFences] = useState(system_operations.coordinator.currentDisplayingFences);
  const [fenceMode, setFenceMode] = useState(false);
  const [currentTool, setCurrentTool] = useState(system_operations.coordinator.toolpack.get_current_tool());
  const [isMobile, setIsMobile] = useState(system_operations.performanceMeter.isMobile);
  const [performanceLevel, setPerformanceLevel] = useState(system_operations.performanceMeter.currentPerformanceLevel);

  const theme = useTheme();

  const [conditionalStyles, setConditionalStyles] = useState(getStyles(system_operations, theme));

  const boardData = useRef(system_operations.coordinator.getBoardData());

  const needsWidthCorrection = useRef(true);
  const wallPanelRef = useRef(null);

  const wallTopRef = useRef(null);
  const wallBottomRef = useRef(null);
  const wallContainerRef = useRef(null);
  const boardWholeContainer = useRef(null);

  const cornerTLInputRef = useRef(null);
  const cornerTRInputRef = useRef(null);
  const cornerBLInputRef = useRef(null);
  const cornerBRInputRef = useRef(null);
  const frameShortEdge1InputRef = useRef(null);
  const frameShortEdge2InputRef = useRef(null);
  const wallTopBarInputRef = useRef(null);
  const wallBottomBarInputRef = useRef(null);
  const wallPanelInputRef = useRef(null);
  const wallBackgroundInputRef = useRef(null);
  //  const wallFootInputRef = useRef(null);

  const [refresh_counter, set_refresh_counter] = useState(0);
  const refreshCounterRef = useRef(0);
  const allowDrag = useRef(false);

  useEffect(() => {
    const autoScroll = () => {
      if (autoScrolling !== 0) {
        handleScroll(autoScrolling)
      }
    }

    const intervalId = setInterval(autoScroll, 30); // Calls autoScroll every 1000 ms
    return () => clearInterval(intervalId); // cleanup on unmount
  }, [autoScrolling]);

  const refresh_wall = () => {
    refreshCounterRef.current += 1;
    set_refresh_counter(refreshCounterRef.current);
  }

  const setNewGlobalOffset = (newOffset) => {
    // usually, the scroll value is already set when it's here. However, when setting newOffset, if the value is the same, it won't cause the onScroll event to recurse.
    if (wallContainerRef.current) { // in case called before first render
      if (system_operations.coordinator.isHorizontal) {
        if (wallContainerRef.current.scrollLeft !== newOffset) {
          wallContainerRef.current.scrollLeft = newOffset; // setting the value to ref, will immediately reflect to html.
          return wallContainerRef.current.scrollLeft;
        }
      } else {
        if (wallContainerRef.current.scrollTop !== newOffset) {
          wallContainerRef.current.scrollTop = newOffset; // setting the value to ref, will immediately reflect to html.
          return wallContainerRef.current.scrollTop;
        }
      }
    }
    // system_operations.coordinator.updateCoordinate();
    return newOffset;
  }

  const serverStateChange = (ready) => {
    if (ready) {
      serverWasReady = true;
    } else {
      if (serverWasReady) {
        // pop up a warning
        // alert("Oh no... We are sorry, but the server is disconnected... Server is working on its best to get itself back...");
      }
      serverWasReady = false;
    }
    setServerReady(ready);
  }

  useMemo(() => {
    system_operations.coordinator.gridsSections.rerenderHook = refresh_wall;

    system_operations.coordinator.onLengthOverScrollChange["fromWall"] = () => { setConditionalStyles(getStyles(system_operations, theme)) };
    system_operations.coordinator.onFencesChange["fromWall"] = setExistingFences;
    // system_operations.fenceMaker.assignSetFenceModeHook(setFenceMode);
    system_operations.coordinator.toolpack.getToolByName("fence").setFenceCreationHook = setFenceMode;

    system_operations.coordinator.assignSetGlobalOffsetHook(setNewGlobalOffset);
    system_operations.coordinator.assign_refresh_wall_hook(refresh_wall);

    system_operations.coordinator.setAutoScrollingHook = setAutoScrolling;
    system_operations.socket_connector.addToOnServerStageChange("fromWall", serverStateChange)
  }, [])

  useEffect(() => {
    const onToolChange = (new_tool) => {
      setCurrentTool(new_tool);
    }
    const setNewConditionalStyles = () => { setConditionalStyles(getStyles(system_operations, theme)); }

    system_operations.performanceMeter.onDeviceTypeChange["_B"] = setIsMobile;
    system_operations.performanceMeter.onCurrentPerformanceLevelChange["_B"] = setPerformanceLevel;
    system_operations.coordinator.toolpack.onToolChange["_B"] = onToolChange;
    system_operations.coordinator.boardSettings.onDisplayModeChanged["_B"] = setNewConditionalStyles;
    system_operations.coordinator.boardSettings.onCrossScrollOnChanged["_B"] = setNewConditionalStyles;
    system_operations.coordinator.boardSettings.onBoardOnScreenRatioChange["_B"] = setNewConditionalStyles;
    return () => {
      delete system_operations.performanceMeter.onDeviceTypeChange["_B"];
      delete system_operations.performanceMeter.onCurrentPerformanceLevelChange["_B"];
      delete system_operations.coordinator.boardSettings.onDisplayModeChanged["_B"];
      delete system_operations.coordinator.boardSettings.onCrossScrollOnChanged["_B"];
      delete system_operations.coordinator.boardSettings.onBoardOnScreenRatioChange["_B"];
      delete system_operations.coordinator.toolpack.onToolChange["_B"];
    };
  }, []);

  useEffect(() => {
    system_operations.coordinator.setDisplayScale(conditionalStyles.displayScale);
  }, [conditionalStyles])


  const handleScroll = (delta) => {
    delta = system_operations.coordinator.boardPositioner.scaleToTheo(delta);
    const newOffset = system_operations.coordinator.getGlobalOffset() + delta;
    system_operations.coordinator.setGlobalOffsetTheo(newOffset, true);
  };

  useEffect(() => {
    system_operations.coordinator.boardPositioner.setContianer(wallPanelRef.current); // let the positioner know what the container is
    system_operations.coordinator.onGlobalOffsetChange["fromDrawingCanvas"] = () => {
      system_operations.coordinator.drawingCanvas.resetCanvasPosition();
    };
    system_operations.coordinator.setGlobalOffsetTheo(system_operations.coordinator.entrance !== undefined ? system_operations.coordinator.entrance : 0, true, true);

    const container = wallContainerRef.current;
    const handleWheel = (e) => {
      if (system_operations.coordinator.isHorizontal && container) {
        e.preventDefault();
        container.scrollLeft += e.deltaY;
      }
    };
    if (container) { container.addEventListener('wheel', handleWheel); }
    const receiveMouseClick = () => {
      system_operations.setMouseMode(true);
    }
    const eventDown = (event) => {
      system_operations.coordinator.mouseData.setCurrentEventTarget(event);
    }
    const receiveTouch = (event) => {
      const tool = system_operations.coordinator.toolpack.get_current_tool();
      // the mechnism to stop board being able to be dragged for some tools
      // console.log(event.target);
      if (tool.normalHandBehavior || (event.target.className === "pending-canvas" && tool.strictHandBehavior)) {
        allowDrag.current = true;
      } else {
        allowDrag.current = false;
        event.preventDefault();
      }
      system_operations.setMouseMode(false);
    }

    const touchProceed = (event) => {
      if (!allowDrag.current) {
        event.preventDefault();
      }
    }

    // Add the non-passive event listeners
    const handleScrollChange = () => {
      const offset = system_operations.coordinator.isHorizontal ? wallContainerRef.current.scrollLeft : wallContainerRef.current.scrollTop;
      system_operations.coordinator.setGlobalOffset(offset, true, false);
    };
    const element = wallContainerRef.current;
    if (element) { element.addEventListener('scroll', handleScrollChange); }

    window.addEventListener('mousedown', eventDown);
    window.addEventListener('touchstart', eventDown);
    window.addEventListener('pointerup', handleMouseUp);

    container.addEventListener('mousedown', receiveMouseClick);
    container.addEventListener('touchstart', receiveTouch, { passive: false });
    container.addEventListener('touchmove', touchProceed, { passive: false });

    return () => {
      delete system_operations?.coordinator?.onGlobalOffsetChange["fromDrawingCanvas"]

      window.removeEventListener('mousedown', eventDown);
      window.removeEventListener('touchstart', eventDown);
      window.removeEventListener('pointerup', handleMouseUp);

      if (element) { element.removeEventListener('scroll', handleScrollChange); }
      container.removeEventListener('mousedown', receiveMouseClick);
      container.removeEventListener('touchstart', receiveTouch, { passive: false });
      container.removeEventListener('touchmove', touchProceed, { passive: false });

      if (container) {
        container.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  useEffect(() => {
    if (wallContainerRef.current && needsWidthCorrection.current) {
      needsWidthCorrection.current = false;
      if (system_operations.coordinator.boardSettings.crossScrollOn) {
        if (system_operations.coordinator.isHorizontal) {
          wallContainerRef.current.scrollTop = conditionalStyles.boardOriginalOverscroll;
        } else {
          wallContainerRef.current.scrollLeft = conditionalStyles.boardOriginalOverscroll;
        }
      } else {
        if (system_operations.coordinator.isHorizontal) {
          wallContainerRef.current.scrollTop = 0;
        } else {
          wallContainerRef.current.scrollLeft = 0;
        }
      }
    }
  }, [conditionalStyles])

  const handleMouseDown = (e) => {
    dragStart(e, e.pageX, e.pageY)
  };

  const handleMouseMove = (e) => {
    mouseMove(e.clientX, e.clientY);
    dragMove(e, e.pageX, e.pageY);
  };

  const handleMouseUp = () => {
    wallContainerRef.current.isDown = false;
  };

  const handleTouchStart = (e) => {
    mouseMove(e.touches[0].clientX, e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    mouseMove(e.touches[0].clientX, e.touches[0].clientY);
  };

  const dragStart = (event, pageX, pageY) => {
    if (!system_operations.frontBuzy && (currentTool.normalHandBehavior || currentTool.strictHandBehavior && event.target.className === "pending-canvas")) {
      allowDrag.current = true;
      wallContainerRef.current.startX = pageX;
      wallContainerRef.current.scrollLeftStart = wallContainerRef.current.scrollLeft;
      wallContainerRef.current.startY = pageY;
      wallContainerRef.current.scrollTopStart = wallContainerRef.current.scrollTop;
      wallContainerRef.current.isDown = true;
    } else {
      allowDrag.current = false;
    }
  }

  const dragMove = (event, dx, dy) => {
    if (wallContainerRef.current.isDown && allowDrag.current) {
      const walkX = dx - wallContainerRef.current.startX;
      const walkY = dy - wallContainerRef.current.startY;
      let newScrollValueX = wallContainerRef.current.scrollLeftStart - walkX;
      let newScrollValueY = wallContainerRef.current.scrollTopStart - walkY;
      if (!system_operations.coordinator.boardSettings.crossScrollOn || system_operations.coordinator.boardSettings.displayMode === 2) {
        if (system_operations.coordinator.isHorizontal) {
          newScrollValueY = 0;
        } else {
          newScrollValueX = 0;
        }
      }
      wallContainerRef.current.scrollLeft = newScrollValueX;
      wallContainerRef.current.scrollTop = newScrollValueY;
      system_operations.coordinator.setGlobalOffset(system_operations.coordinator.isHorizontal ? newScrollValueX : newScrollValueY, true);
    }
  }

  const mouseMove = (eventX, eventY) => {
    system_operations.coordinator.setMouseScreenSpacePosition(eventX, eventY);
    system_operations.coordinator.mouseData.setMouseOnScreenPosition([eventX, eventY]);
  }

  const onWallPartClick = (place) => {
    if (system_operations.coordinator.toolpack.get_current_tool().name === "editor" && system_operations.coordinator.getUserRoleInWall().power >= 9999) {
      if (place === "topEdge") {
        if (wallTopBarInputRef.current) { wallTopBarInputRef.current.click(); }
      } else if (place === "bottomEdge") {
        if (wallBottomBarInputRef.current) { wallBottomBarInputRef.current.click(); }
      } else if (place === "panel") {
        if (wallPanelInputRef.current) { wallPanelInputRef.current.click(); }
      } else if (place === "background") {
        if (wallBackgroundInputRef.current) { wallBackgroundInputRef.current.click(); }
      } else if (place === "leftEdge") {
        if (frameShortEdge1InputRef.current) { frameShortEdge1InputRef.current.click(); }
      } else if (place === "rightEdge") {
        if (frameShortEdge2InputRef.current) { frameShortEdge2InputRef.current.click(); }
      } else if (place === "cornerTR") {
        if (cornerTRInputRef.current) { cornerTRInputRef.current.click(); }
      } else if (place === "cornerTL") {
        if (cornerTLInputRef.current) { cornerTLInputRef.current.click(); }
      } else if (place === "cornerBR") {
        if (cornerBRInputRef.current) { cornerBRInputRef.current.click(); }
      } else if (place === "cornerBL") {
        if (cornerBLInputRef.current) { cornerBLInputRef.current.click(); }
      }
    }
  };

  const updateCusmatic = async (wallID, varName, newUrl) => {
    system_operations.coordinator[varName] = newUrl;
    system_operations.coordinator.wallData[varName] = newUrl;
  }

  const onUploadWallImage = async (place, e) => {
    system_operations.set_loading(true);
    const wallID = system_operations.coordinator.getWallID();

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = async () => {
          const tempCanvas = document.createElement('canvas');
          tempCanvas.width = img.width;
          tempCanvas.height = img.height;
          const ctx = tempCanvas.getContext('2d');
          ctx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
          ctx.drawImage(img, 0, 0);
          const base64 = tempCanvas.toDataURL();

          const updateData = await RequestDataMaker.getDataToUpdateboard(system_operations, wallID, { usePlace: place, imageData: base64 })
          const updateResult = await system_operations.restServerConnector.updateBoardDisplaySetting(updateData);
          if (updateResult && updateResult.success && updateResult.url) {
            await updateBoardImage(place, updateResult.url);
          }
          system_operations.set_loading(false);
        }
        img.src = e.target.result;
      }
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const updateBoardImage = async (place, newUrl) => {
    const wallID = system_operations.coordinator.getWallID();
    console.trace();
    if (place === "topEdge") {
      await updateCusmatic(wallID, "boardTopEdgeImage", newUrl);
    } else if (place === "bottomEdge") {
      await updateCusmatic(wallID, "boardBottomEdgeImage", newUrl);
    } else if (place === "panel") {
      await updateCusmatic(wallID, "boardPanelImage", newUrl);
    } else if (place === "background") {
      await updateCusmatic(wallID, "backgroundImage", newUrl);
    } else if (place === "leftEdge") {
      await updateCusmatic(wallID, "boardLeftEdgeImage", newUrl);
    } else if (place === "rightEdge") {
      await updateCusmatic(wallID, "boardRightEdgeImage", newUrl);
    } else if (place === "cornerTR") {
      await updateCusmatic(wallID, "cornerTRImage", newUrl);
    } else if (place === "cornerTL") {
      await updateCusmatic(wallID, "cornerTLImage", newUrl);
    } else if (place === "cornerBR") {
      await updateCusmatic(wallID, "cornerBRImage", newUrl);
    } else if (place === "cornerBL") {
      await updateCusmatic(wallID, "cornerBLImage", newUrl);
    }
    refresh_wall();
  }

  const boardContainerStyleOverride = { zIndex: 3 };
  if (!system_operations.coordinator.boardSettings.crossScrollOn || system_operations.coordinator.boardSettings.displayMode === 2) {
    boardContainerStyleOverride[system_operations.coordinator.isHorizontal ? "overflowY" : "overflowX"] = "hidden"
  }
  const theWholeBoard = (
    <div
      ref={wallContainerRef}
      className={system_operations.coordinator.isHorizontal ? "horizontal-scroll-container" : "vertical-scroll-container"}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseUp}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchCancel={handleMouseUp}
      style={boardContainerStyleOverride}
    >
      <div ref={boardWholeContainer} className='main-board' style={{ ...conditionalStyles.mainContainer, transformOrigin: 'top left', transform: `scale(${conditionalStyles.displayScale})` }}>
        {system_operations.coordinator.boardSettings.crossScrollOn && !conditionalStyles.fullScreenMode && <div style={conditionalStyles.sideOverScroll} />}
        {!conditionalStyles.fullScreenMode && <div style={conditionalStyles.endOverScroll} />}
        <div style={conditionalStyles.boardContainer}>
          {!conditionalStyles.fullScreenMode && <>
            <div style={conditionalStyles.leftEdge} />
            <div style={conditionalStyles.rightEdge} />
            <div ref={wallTopRef} style={conditionalStyles.topEdge} />
            <div ref={wallBottomRef} style={conditionalStyles.bottomEdge} />

            <div style={conditionalStyles.cornerBL} />
            <div style={conditionalStyles.cornerBR} />
            <div style={conditionalStyles.cornerTL} />
            <div style={conditionalStyles.cornerTR} />
          </>}

          <div ref={wallPanelRef} style={conditionalStyles.innerBoard}>
            {!conditionalStyles.fullScreenMode &&
              <>
                <FenceLines displayOffset={-22} fences={existingFences} />
                <CoordinateAxis displayOffset={(system_operations.coordinator.isHorizontal ? system_operations.coordinator.theoreticalBoardHeight : system_operations.coordinator.theoreticalBoardWidth) + system_operations.coordinator.edgeWidth + 10} />
              </>
            }
            {fenceMode && <FenceDefinition fences={existingFences} />}
            <FloatCanvases fullPowerMode={system_operations.performanceMeter.getFullPowerMode()}/>
            <LocalDrawingCanvas />
            <BoardBaseGrids />
            <BoardAxisGuideline />
            <BoardBlock />
          </div>
        </div>
      </div>
    </div>
  )

  const displayScrollbar = true;
  const scrollbarSize = 15;

  return (
    <div onContextMenu={(e) => { e.preventDefault() }} style={{ overflow: "hidden", cursor: currentTool.cursor }}>
      <div style={{ position: "fixed", height: "100%", width: "100%", backgroundColor: "white", zIndex: theme.zIndex.backgroundWhiteCover }} />
      <div className="blurry-background" style={{
        position: "fixed", width: "100%", height: "100%",
        backgroundImage: system_operations.coordinator.boardStyle.images.BK, zIndex: theme.zIndex.blurBackground
      }}
      />
      <div>
        <input type="file" accept="image/*" id="uploadCornerTLInputRef" ref={cornerTLInputRef} onChange={(e) => { onUploadWallImage("cornerTL", e) }} style={{ display: "none" }} />
        <input type="file" accept="image/*" id="uploadCornerTRInputRef" ref={cornerTRInputRef} onChange={(e) => { onUploadWallImage("cornerTR", e) }} style={{ display: "none" }} />
        <input type="file" accept="image/*" id="uploadCornerBLInputRef" ref={cornerBLInputRef} onChange={(e) => { onUploadWallImage("cornerBL", e) }} style={{ display: "none" }} />
        <input type="file" accept="image/*" id="uploadCornerBRInputRef" ref={cornerBRInputRef} onChange={(e) => { onUploadWallImage("cornerBR", e) }} style={{ display: "none" }} />

        <input type="file" accept="image/*" id="uploadShortEdge1InputRef" ref={frameShortEdge1InputRef} onChange={(e) => { onUploadWallImage("boardLeftEdge", e) }} style={{ display: "none" }} />
        <input type="file" accept="image/*" id="uploadShortEdge2InputRef" ref={frameShortEdge2InputRef} onChange={(e) => { onUploadWallImage("boardRightEdge", e) }} style={{ display: "none" }} />
        <input type="file" accept="image/*" id="uploadWallTopBarInputRef" ref={wallTopBarInputRef} onChange={(e) => { onUploadWallImage("boardTopEdge", e) }} style={{ display: "none" }} />
        <input type="file" accept="image/*" id="uploadWallBottomBarInputRef" ref={wallBottomBarInputRef} onChange={(e) => { onUploadWallImage("boardBottomEdge", e) }} style={{ display: "none" }} />
        <input type="file" accept="image/*" id="uploadWallPanelInputRef" ref={wallPanelInputRef} onChange={(e) => { onUploadWallImage("boardPanel", e) }} style={{ display: "none" }} />
        <input type="file" accept="image/*" id="uploadWallBackgroundInputRef" ref={wallBackgroundInputRef} onChange={(e) => { onUploadWallImage("background", e) }} style={{ display: "none" }} />
      </div>
      <div style={{ position: "fixed", width: "36px", height: "20px", right: `${displayScrollbar ? scrollbarSize + 2 : 5}px`, top: "5px", backgroundColor: theme.backgroundColor.homePageUIBase, borderRadius: "7px", zIndex: theme.zIndex.baseImages }}>
        {serverReady ? <div style={{ position: "absolute", top: "3px", right: "3px", width: "14px", height: "14px", borderRadius: "7px", backgroundColor: "rgb(0, 255, 0)" }} /> :
          <div style={{ position: "absolute", top: "3px", left: "3px", width: "14px", height: "14px", borderRadius: "7px", backgroundColor: "rgb(255, 0, 0)" }} />}
      </div>
      {boardData.current?.scrollStyle && <Scrollbar maxCoordinate={system_operations.coordinator.pixelBoundary[1]} isHorizontal={system_operations.coordinator.isHorizontal} styleObject={boardData.current.scrollStyle} />}
      <CursorElement currentTool={currentTool} cursorOnMainCanvas={false} />
      {theWholeBoard}
    </div>
  );
};

const _1x1PNG = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

// this function assume imgRef exist already.
const imgElementToDataUrl = (coordinator, imgRef) => {
  return new Promise((resolve, reject) => {
    // Access the actual img element
    const imgElement = imgRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = coordinator.gridWidth;
    canvas.height = coordinator.gridHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imgElement, 0, 0);
    const dataUrl = canvas.toDataURL('image/png');
    resolve(dataUrl);
  });
}

const BoardBaseGrids = React.memo(() => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [refreshCounter, setRefreshCounter] = useState(); // refresh counter
  const refreshCounterRef = useRef(0);
  const theme = useTheme();

  const refresh_wall = () => {
    refreshCounterRef.current += 1;
    setRefreshCounter(refreshCounterRef.current);
  }

  useEffect(() => {
    system_operations.coordinator.gridsDisplay.getDisplayingAtCoordinate();
    system_operations.coordinator.gridsDisplay.rerenderHook = refresh_wall;
    system_operations.coordinator.onCoordinateChange["BBG"] = system_operations.coordinator.gridsDisplay.getDisplayingAtCoordinate.bind(system_operations.coordinator.gridsDisplay);

    return () => {
      delete system_operations.coordinator.onCoordinateChange["BBG"];
    }
  }, [])


  return (
    <div>
      {Object.values(system_operations.coordinator.gridsDisplay.displayingGrids).map((imageData) => {
        return (<ImageGrid imageData={imageData} system_operations={system_operations} zIndex={theme.zIndex.baseImages} key={`grid_${imageData.row}_${imageData.column}`} />)
      })}
    </div>
  )
});

const ImageGrid = ({ imageData, system_operations, zIndex }) => {
  const [urlOverride, setUrlOverride] = useState(null);

  if (!imageData.ref) { imageData.ref = { current: null } }

  useEffect(() => {
    const getDataUrlFromRef = () => {
      if (urlOverride) {
        return urlOverride
      } else if (imageData.ref && imageData.ref.current) {
        return imgElementToDataUrl(system_operations.coordinator, imageData.ref);
      } else {
        return false;
      }
    }
    imageData.getDataUrlFromRef = getDataUrlFromRef;
    imageData.setUrl = setUrlOverride;

    return () => {
      imageData.ref.current = null;
      imageData.setUrl = null;
    }
  }, [urlOverride, imageData])

  return (
    <img
      ref={imageData.ref} crossOrigin="anonymous"
      src={urlOverride ? urlOverride : (imageData.url && imageData.url !== "none" ? imageData.url : _1x1PNG)}
      style={{
        position: "absolute",
        top: system_operations.coordinator.getTopOffsetOfIndex(imageData.row),
        left: system_operations.coordinator.getLeftOffsetOfIndex(imageData.column),
        height: system_operations.coordinator.getGridHeight(),
        width: system_operations.coordinator.getGridWidth(),
        zIndex: zIndex,
      }}
      className="image_pieces"
      draggable="false"
    ></img>
  )
}


const CursorElement = ({ currentTool, cursorOnMainCanvas }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [isMouseMode, setIsMouseMode] = useState(system_operations.isMouseMode);  // Initialize your color state
  const theme = useTheme();

  const circleCursor = useRef({
    borderRadius: '50%',
    border: '1px solid black', // 1px border
    backgroundColor: 'transparent' // Ensure it's not filled
  });
  const textCursor = useRef({
    width: '7px',
    height: '18px',
    border: '1px solid black',
    backgroundColor: 'transparent' // Ensure it's not filled
  });
  const noCursor = useRef({
    display: "none",
  });

  useEffect(() => {
    system_operations.onMouseModeChange["fromCursorElement"] = (mouseMode) => { setIsMouseMode(mouseMode); }
    return () => {
      delete system_operations.onMouseModeChange["fromCursorElement"];
    }
  }, [])

  useEffect(() => {
    const cursor = document.getElementById('custom-cursor');
    const onMouseMove = e => {
      if (currentTool.name === "brush") {
        const cursorSize = currentTool.getSize();
        updateCursorPos(e.clientX, e.clientY, cursorSize);
      }
    }

    const updateCursorSize = () => {
      if (!isMouseMode) { return; }
      if (currentTool.name === "brush") {
        const cursorSize = currentTool.getSize();
        cursor.style.height = `${system_operations.coordinator.boardPositioner.scaleToScr(cursorSize * 2)}px`;
        cursor.style.width = `${system_operations.coordinator.boardPositioner.scaleToScr(cursorSize * 2)}px`;
      }
    }

    const updateCursorPos = (coordX, coordY, cursorSize = 0) => {
      if (!isMouseMode) { return; }
      cursor.style.left = `${coordX - system_operations.coordinator.boardPositioner.scaleToScr(cursorSize)}px`;
      cursor.style.top = `${coordY - system_operations.coordinator.boardPositioner.scaleToScr(cursorSize)}px`;
    }

    if (currentTool.getSize) {
      const currentSize = currentTool.getSize();
      updateCursorSize();
      // updateCursorPos(mousePos[0], mousePos[1]);
    }

    window.addEventListener('mousemove', onMouseMove);
    if (currentTool.name === "brush") { currentTool.onSizeChange["_CE"] = updateCursorSize; }
    if (currentTool.name === "brush") { currentTool.onTypeChange["_CE"] = updateCursorSize; }
    // system_operations.coordinator.toolpack.onToolChange["_CE"] = (newTool) => { updateCursorSize(); };

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      if (currentTool.name === "brush") { delete currentTool.onSizeChange["_CE"] }
      if (currentTool.name === "brush") { delete currentTool.onTypeChange["_CE"] }
      // delete system_operations.coordinator.toolpack.onToolChange["_CE"];
    };
  }, [currentTool, isMouseMode, cursorOnMainCanvas]);

  let cursorStyle = {};
  if (currentTool.additionalCursor === "circle") {
    cursorStyle = circleCursor.current;
  }/*  else if (currentTool.additionalCursor === "text") {
    cursorStyle = textCursor.current;
  }*/ else {
    cursorStyle = noCursor.current;
  }

  return (
    <div>
      {isMouseMode && <div id="custom-cursor" className='round-cursor' style={{ pointerEvents: 'none', ...cursorStyle }} />}
    </div>
  )
}

const Scrollbar = ({ maxCoordinate, isHorizontal, styleObject }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [position, setPosition] = useState(0);
  const [containerSize, setContainerSize] = useState(isHorizontal ? window.innerWidth : window.innerHeight);
  const [dragBarSize, setDragBarSize] = useState(0);
  const dragBarSizeRef = useRef(0);

  const isDragging = useRef(false);
  const containerRef = useRef(null);
  const draggerRef = useRef(null);

  const currentPositionRef = useRef(position);
  const lastEventPosition = useRef(0);

  useEffect(() => {
    const _setDragBarSize = () => {
      dragBarSizeRef.current = (system_operations.coordinator.boardPositioner.scaleToTheo(isHorizontal ? window.innerWidth : window.innerHeight, true) / maxCoordinate) * containerSize;
      setDragBarSize(dragBarSizeRef.current);
    }
    if (system_operations.coordinator.boardPositioner.initialized) {
      setDragBarSize();
    } else {
      system_operations.coordinator.boardPositioner.onInitializedCallback["Scrollbar"] = _setDragBarSize; // add to the call back.
    }

    const startDrag = (e) => {
      isDragging.current = true;
      lastEventPosition.current = isHorizontal ? e.clientX : e.clientY;
      window.addEventListener("pointermove", onPointerMove);
    };

    const setDragerPosition = (position, currentMousePosition) => {
      position = Math.max(Math.min(position, containerSize - dragBarSizeRef.current), 0); // no going over.
      if (position >= 0) {
        currentPositionRef.current = position;
        setPosition(position);
        if (currentMousePosition) { lastEventPosition.current = currentMousePosition; }
      }
      return position;
    }

    const onPointerMove = (e) => {
      if (!isDragging.current) return;

      const currentPosition = isHorizontal ? e.clientX : e.clientY;
      const delta = currentPosition - lastEventPosition.current;

      let newPosition = currentPositionRef.current + delta;
      newPosition = setDragerPosition(newPosition, currentPosition);

      const coordinate = (newPosition / containerSize) * maxCoordinate;
      system_operations.coordinator.setGlobalOffsetTheo(coordinate, true);
    };

    const endDrag = () => {
      isDragging.current = false;
      window.removeEventListener("pointermove", onPointerMove);
    };

    const onCoordinateChange = (coordinate) => {
      if (!isDragging.current) {
        let newPosition = (coordinate / maxCoordinate) * containerSize;
        setDragerPosition(newPosition);
      }
    }

    const onClickContainer = (e) => {
      if (draggerRef.current && draggerRef.current.contains(e.target)) {
        // Don't teleport if the click is on the drag bar itself
        return;
      }
      const clickPosition = isHorizontal ? e.clientX : e.clientY;
      let newPosition = clickPosition;
      newPosition = setDragerPosition(newPosition);

      const coordinate = (newPosition / containerSize) * maxCoordinate;
      system_operations.coordinator.setGlobalOffsetTheo(coordinate, true);
    }

    if (draggerRef.current) {
      draggerRef.current.addEventListener("pointerdown", startDrag);
    }
    if (containerRef.current) {
      containerRef.current.addEventListener("click", onClickContainer);
    }

    window.addEventListener("pointerup", endDrag);
    system_operations.coordinator.onCoordinateChange["_ScrollBar"] = onCoordinateChange;

    return () => {
      if (draggerRef.current) {
        draggerRef.current.removeEventListener("pointerdown", startDrag);
      }
      if (containerRef.current) {
        containerRef.current.removeEventListener("click", onClickContainer);
      }
      window.removeEventListener("pointerup", endDrag);
      window.removeEventListener("pointermove", onPointerMove);
      delete system_operations.coordinator.onCoordinateChange["_ScrollBar"];
    }
  }, [containerSize])

  const containerStyle = {
    boxSizing: 'border-box',
    width: isHorizontal ? '100vw' : '14px',
    height: isHorizontal ? '14px' : '100vh',
    border: `1px solid ${styleObject.borderColor}`,
    backgroundColor: styleObject.containerColor,
    position: 'fixed',
    [isHorizontal ? "bottom" : "right"]: "0px",
    [isHorizontal ? "justifyContent" : "alignItems"]: "center",
    userSelect: 'none',
    zIndex: 100,
  };

  const barStyle = {
    width: isHorizontal ? `${dragBarSize}px` : '12px',
    height: isHorizontal ? '12px' : `${dragBarSize}px`,
    backgroundColor: styleObject.draggerColor,
    position: 'absolute',
    top: isHorizontal ? '0' : `${position}px`,
    left: isHorizontal ? `${position}px` : '0',
    cursor: 'pointer',
  };
  return (
    <div ref={containerRef} style={containerStyle}>
      <div
        ref={draggerRef}
        style={barStyle}
      />
    </div>
  );
};

const BoardBlock = React.memo(({ }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const [coordinate, setCoordinate] = useState(system_operations.coordinator.getCoordinate());  // Initialize your color state
  const theme = useTheme();

  useEffect(() => {
    system_operations.coordinator.onCoordinateChange["BBlock"] = setCoordinate;
    return (() => {
      delete system_operations.coordinator.onCoordinateChange["BBlock"];
    })
  }, [])

  const start = coordinate + system_operations.coordinator.maxCanvasLength
  const max = system_operations.coordinator.pixelBoundary[1];
  return (
    <div>
      {(max > start) ?
        < div style={{
          position: "absolute",
          [system_operations.coordinator.getBoardCoordinateField()]: start,
          [system_operations.coordinator.getBoardSecondaryCoordinateField()]: 0,
          [system_operations.coordinator.getBoardWidthMeasurementField()]: "100%",
          [system_operations.coordinator.isHorizontal ? "right" : "bottom"]: 0,
          background: `linear-gradient(to ${system_operations.coordinator.isHorizontal ? "right" : "bottom"}, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 128px, #fff 1px)`,
          zIndex: theme.zIndex.boardBlock,
        }}> </div > : null}
    </div >
  )
});




export default Board;

