import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { GridContainer, ToolItem, ToolItemICONChildren } from '../../components/toolBar/UltimateControlPanel';
import { SystemOperationsContext } from "../../context/SystemRunnerContext";
import ButtonGroups from "../../cross_page_components/ButtonGroups.jsx";
import { FCMediaControlPanel } from "../../components/contentWindows/FCMediaControlPanel.jsx";
import { PostMainPanel } from "../../cross_page_components/PostMedia.jsx";
import { UploadPostPanel } from "../../cross_page_components/PostMedia.jsx";

import { SketchPicker } from 'react-color';
import { FloatingCanvasText } from '../../components/FloatingCanvasText.jsx';
import { FCLinkPrompt } from '../../components/contentWindows/FCLinkPrompt.jsx';
import { FCJumpPrompt } from '../../components/contentWindows/FCJumpPrompt.jsx';
import { ContentMarkPrompt } from '../../components/ContentMarkPrompt.jsx';
import { RequestDataMaker } from '../../system/RestServerConnector.js';
import { useTheme } from '@mui/system';

import eye_off_red from '../../icons/eye-off-red.svg'
import eye_off_white from '../../icons/eye-off-white.svg'
import picture_green from '../../icons/picture-green.svg'
import picture_white from '../../icons/picture-white.svg'
import text_blue from '../../icons/text-blue.svg'
import text_white from '../../icons/text-white.svg'
import star_white from '../../icons/star-white.svg'
import star_yellow from '../../icons/star-yellow.svg'
import heart_pink from '../../icons/heart-pink.svg'
import heart_white from '../../icons/heart-white.svg'
import palette_white from '../../icons/palette-white.svg'
import radius_white from '../../icons/radius-white.svg'
import coin_shadow_white from '../../icons/coin-shadow-white.svg'
import check_green from '../../icons/check-green.svg'
import check_696969 from '../../icons/check-696969.svg'
import cross_red from '../../icons/cross-red.svg'
import cross_696969 from '../../icons/cross-696969.svg'
import delete_bin_red from '../../icons/delete-bin-red.svg'
import under_text_white from '../../icons/under_text.svg'
import under_text_green from '../../icons/under_text_green.svg'
import link_white from '../../icons/link.svg'
import link_blue from '../../icons/link_blue.svg'
import play_white from '../../icons/play-white.svg'
import play_red from '../../icons/play-red.svg'
import message_white from '../../icons/message-2-fill.svg'
import message_green from '../../icons/message-2-fill-green.svg'
import location_white from '../../icons/location-white.svg';
import location_blue from '../../icons/location-blue.svg';
import post_yellow from '../../icons/image-edit-yellow.svg'
import post_white from '../../icons/image-edit-white.svg'
import unselect from '../../icons/paper/rectangle-fill-cross.svg'
import remove_drawing from '../../icons/paper/image-off-red.svg'

import { baseSubItemStyle } from './Hand.js'


export const FCFullFunctionalPanel = ({ canvasRect, isVertical }) => {
  const subItemBaseStyle = { ...baseSubItemStyle, [isVertical ? "width" : "height"]: "20%" };
  const subItemHoverStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.2)" };
  const subItemSelectedStyle = { ...subItemBaseStyle, color: "#ffffff", border: '2px solid #ffffff', backgroundColor: "rgba(255, 255, 255, 0.5)" };

  return (
    <div>
      <FCReactionPanel canvasRect={canvasRect} isVertical={isVertical} subItemBaseStyle={subItemBaseStyle} subItemHoverStyle={subItemHoverStyle} subItemSelectedStyle={subItemSelectedStyle} />
      <SingleShotDeleteButton rect={canvasRect} isVertical={isVertical} subItemBaseStyle={subItemBaseStyle} subItemHoverStyle={subItemHoverStyle} subItemSelectedStyle={subItemSelectedStyle} />
    </div>
  );
}

export const FCReactionPanel = ({ canvasRect, isVertical, subItemBaseStyle, subItemHoverStyle, subItemSelectedStyle }) => {
  const canvasData = canvasRect.getData();
  const { system_operations } = useContext(SystemOperationsContext);
  const [liked, setLiked] = useState(false);
  const [hasDrawingImage, setHasDrawingImage] = useState(Boolean(canvasRect.getData().imageUrl));
  const [isMarked, setIsMarked] = useState(canvasData.marked);
  const [textModeOn, setTextModeOn] = useState(canvasData.showText);
  const [underTextModeOn, setUnderTextModeOn] = useState(canvasData.showUnderText);
  const [hasExtension, setHasExtension] = useState(Boolean(canvasData.extension));
  const [extensionType, setExtensionType] = useState(canvasData.extension?.type || "");
  const [hasLink, setHasLink] = useState(Boolean(canvasData.link));
  const [hasMediaData, setHasMediaData] = useState(Boolean(canvasData.extension?.type === "media")); // now, it doesn't cover case of {}
  const [interactable, setInteractable] = useState(canvasRect.interactable);
  const [paperSelections, setPaperSelection] = useState(system_operations.coordinator.toolpack.paperSelectionArray);

  const refreshCounter = useRef(0);
  const [refresh, setRefresh] = useState(0);


  const theme = useTheme();
  const isOwner = canvasData.creatorID === system_operations.getUserID();

  useEffect(() => {
    const updateSelections = (selections, selectionArray) => {
      setPaperSelection(selectionArray);
      setRefresh(refreshCounter.current + 1);
      refreshCounter.current += 1;
    }

    system_operations.coordinator.toolpack.onPaperSelectionsChange["_PTP"] = updateSelections;
    return () => {
      delete system_operations.coordinator.toolpack.onPaperSelectionsChange["_PTP"];
    }
  }, []);

  useEffect(() => {
    system_operations.coordinator.likeSystem.checkLiked("canvases", system_operations.coordinator.getWallID(), canvasData.canvasID).then((likedByUser) => {
      if (likedByUser !== liked) {
        setLiked(likedByUser);
      }
    });

    setHasDrawingImage(Boolean(canvasRect.getData().imageUrl));
    setIsMarked(canvasData.marked);
    setTextModeOn(canvasData.showText);
    setUnderTextModeOn(canvasData.showUnderText);
    setHasExtension(Boolean(canvasData.extension));
    setExtensionType(canvasData.extension?.type || "");
    setHasLink(Boolean(canvasData.link));
    setHasMediaData(Boolean(canvasData.extension?.type === "media"));
    setInteractable(canvasRect.interactable);
  }, [canvasRect])


  const likeButtonClicked = () => {
    if (!liked) {
      setLiked(true);
      system_operations.coordinator.likeSystem.likeAFloatingCanvas("canvases", canvasData.canvasID);
    } else {
      setLiked(false);
      system_operations.coordinator.likeSystem.dislikeAFloatingCanvas("canvases", canvasData.canvasID);
    }
  }

  // turn on a confimation window.
  // better make this a component.
  const removeCanvasImageButtonClicked = () => {
    // actually remove
    const removeCanvasImage = async() => {
      // system_operations.set_loading(true);
      RequestDataMaker.dataToRemoveFloatingCanvasImage(system_operations, canvasData.canvasID).then((dataJson) => {
        system_operations.restServerConnector.removeFloatingCanvasImage(dataJson);
      })
      delete canvasRect.getData().imageUrl;
      setHasDrawingImage(false);
      if (canvasRect.func.removeCanvasImage) {
        canvasRect.func.removeCanvasImage();
      }
      // canvasRect.func.removeCanvasContent();
      // system_operations.set_loading(false);
    }
    const buttons = {
      save: {
        name: 'Yes', color: "white",
        backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
        checkDisable: () => { return false },
        onClick: async () => {
          await removeCanvasImage();
        },
      },
      dump: {
        name: "No", color: "white",
        backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
        checkDisable: () => { return false },
        onClick: async () => {
          system_operations.setContentWindow(null)
        },
      }
    }
    system_operations.setContentWindow((
      <div className='float-container' style={{ borderRadius: 8, padding: 5, backgroundColor: "rgba(64, 64, 64, 0.85)", color: "white" }}>
        Remove current drawing?
        <ButtonGroups buttons={buttons} />
      </div>
    ));
  }



  const setTextMode = async () => {
    /* if (canvasData.showText) {
      canvasData.showText = false;
      setTextModeOn(false);
      if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
      const dataJson = await RequestDataMaker.dataToUpdateFloatingCanvas(system_operations, canvasData.canvasID, { showText: false })
      await system_operations.restServerConnector.requestToUpdateFloatingCanvasProperties(dataJson);
    } else { */
    system_operations.setContentWindow((<FloatingCanvasText canvasRect={canvasRect} setUnderText={false} onChangeCallback={(showText, text) => {
      canvasData.showText = showText;
      canvasData.text = text;
      setTextModeOn(showText);
      if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
    }} />));
    // }
  }

  const setUnderTextMode = async () => {
    /* if (canvasData.showUnderText) {
      canvasData.showUnderText = false;
      setUnderTextModeOn(false);
      if (canvasRect.func?.styleRefreshPaperFullComponent) { canvasRect.func.styleRefreshPaperFullComponent(); }
      const dataJson = await RequestDataMaker.dataToUpdateFloatingCanvas(system_operations, canvasData.canvasID, { showUnderText: false })
      await system_operations.restServerConnector.requestToUpdateFloatingCanvasProperties(dataJson);
    } else { */
    system_operations.setContentWindow((<FloatingCanvasText canvasRect={canvasRect} setUnderText={true} onChangeCallback={(showUnderText, underText) => {
      canvasData.showUnderText = showUnderText;
      canvasData.underText = underText;
      setUnderTextModeOn(showUnderText);
      if (canvasRect.func?.styleRefreshPaperFullComponent) { canvasRect.func.styleRefreshPaperFullComponent(); }
    }} />));
    // }
  }

  const setLinkMode = async () => {
    // turn up a window for insert links
    system_operations.setContentWindow((<FCLinkPrompt rect={canvasRect} onChangeCallback={(link) => {
      // canvasData.link = link;
      setHasExtension(Boolean(link));
      setExtensionType(Boolean(link) ? "link" : "");
      if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
    }} />));
  }

  const setJumpMode = async () => {
    // turn up a window for insert links
    system_operations.setContentWindow((<FCJumpPrompt rect={canvasRect} onChangeCallback={(link) => {
      // canvasData.link = link;
      setHasExtension(Boolean(link));
      setExtensionType(Boolean(link) ? "jump" : "");
      if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
    }} />));
  }

  // better way is remove doc only if user enter a content mark which its value is ""
  const openContentMarkWindow = async () => {
    const contentMarker = system_operations.coordinator.sectionData.getItemFromContentMark(`canvas_${canvasData.canvasID}`)
    if (!contentMarker) {
      system_operations.setContentWindow(<ContentMarkPrompt rectRef={canvasRect} baseType="fc" />);
    } else {
      system_operations.set_loading(true);
      system_operations.firestoreActions.removeDocumentAt(`boards/${system_operations.coordinator.getWallID()}/marks/canvas_${canvasData.canvasID}`);
      // system_operations.coordinator.sectionData.deleteItemFromContentMarkLoader(contentMarker);
      setIsMarked(false);
      system_operations.set_loading(false);
    }
  }

  const changeInteractive = () => {
    const willBeInteractable = !interactable;
    setInteractable(willBeInteractable);
    canvasRect.func.setInteractable(willBeInteractable);
  }

  const unselectFunc = () => {
    system_operations.coordinator.toolpack.setPaperSelection({});
  }

  const reFetchExtension = () => {
    system_operations.set_loading(true);
    system_operations.firestoreActions.fetchFromFirestore(
      `boards/${system_operations.coordinator.getWallID()}/canvases/${canvasRect.getID()}`, "canvasID").then((refetchFC) => {
        canvasRect.getData().extension = refetchFC.extension;
        system_operations.set_loading(false);
        if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
      })
  }

  const openMediaControlPanel = () => {
    system_operations.setContentWindow(<FCMediaControlPanel canvasRect={canvasRect} onChangeCallback={(hasItems) => {
      if (hasItems) {
        // fetch and update the rect.
        setHasExtension(true);
        setExtensionType("media");
        reFetchExtension();
      } else if (hasMediaData) { // the upload result says no, but we are current having it.
        // no refetch, just delete the current mediaData and refresh.
        setHasExtension(false);
        setExtensionType("");
        delete canvasRect.getData().extension;
        if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
      }
    }} />);
  }

  const openPostPanel = async () => {
    // don't immediate start the guidebox. because we need user to write the post first
    if (extensionType === "post" && canvasData.extension.type === "post") { // just double check.
      const originalPost = await system_operations.postLoader.getPostFromInstance(canvasData.extension.data);
      system_operations.setContentWindow(<PostMainPanel isCreating={false} existingPost={originalPost} postInstanceRectRef={canvasRect} style={{ borderRadius: "10px" }} deletionCallback={(success) => {
        if (success) {
          setHasExtension(false);
          setExtensionType("");
          // delete canvasRect.getData().extension;
          if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
        }
      }} />)
    } else {
      system_operations.setContentWindow(<UploadPostPanel paperRect={canvasRect} submitCallback={(success) => {
        if (success) {
          setHasExtension(true);
          setExtensionType("post");
          reFetchExtension();
        }
      }} />);
    }
  }


  return (
    <div>
      <GridContainer isVertical={isVertical} title='Interaction'>
        <ToolItem icon={liked ? heart_pink : heart_white} tip={liked ? "Dislike" : "Like"} onClick={likeButtonClicked} selected={liked}
          baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
        <ToolItem icon={!interactable ? eye_off_red : eye_off_white} tip={interactable ? "Unhide" : "Hide"} onClick={changeInteractive} selected={!interactable}
          baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
        {paperSelections.length > 0 && <ToolItem icon={unselect} tip="Unselect" onClick={unselectFunc} selected={false}
          baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />}
      </GridContainer>
      <FCStyeEditor canvasRect={canvasRect} isVertical={isVertical} subItemBaseStyle={subItemBaseStyle} subItemHoverStyle={subItemHoverStyle} subItemSelectedStyle={subItemSelectedStyle} />
      {
        isOwner &&
        <>
          <GridContainer isVertical={isVertical} title='Options'>
            <ToolItem icon={isMarked ? star_yellow : star_white} tip="Mark (Make Searchable)" onClick={openContentMarkWindow} selected={isMarked}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
            {/* hasDrawingImage && <ToolItem icon={remove_drawing} tip="remove current drawing" onClick={removeCanvasImageButtonClicked} selected={false}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} /> */}
            <ToolItem icon={textModeOn ? text_blue : text_white} tip="In Paper Text" onClick={setTextMode} selected={textModeOn}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
            <ToolItem icon={underTextModeOn ? under_text_green : under_text_white} tip="Under Paper Text" onClick={setUnderTextMode} selected={underTextModeOn}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
          </GridContainer>
          <GridContainer isVertical={isVertical} title='Extensions'>
            <ToolItem icon={extensionType === "jump" ? location_blue : location_white} tip="In Board Jump" onClick={setJumpMode} selected={extensionType === "jump"}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
            <ToolItem icon={extensionType === "link" ? link_blue : link_white} tip="External Link" onClick={setLinkMode} selected={extensionType === "link"}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
            <ToolItem icon={extensionType === "media" ? play_red : play_white} tip="Add Media" onClick={openMediaControlPanel} selected={extensionType === "media"}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
            <ToolItem icon={extensionType === "post" ? post_yellow : post_white} tip="Attach a Post" onClick={openPostPanel} selected={extensionType === "post"}
              baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />
          </GridContainer>
        </>
      }
    </div >
  );
}


export const FCStyeEditor = ({ canvasRect, isVertical, subItemBaseStyle, subItemHoverStyle, subItemSelectedStyle }) => {
  const canvasData = canvasRect.getData();
  const { system_operations } = useContext(SystemOperationsContext);
  const [backgroundColor, setBackgroundColor] = useState(canvasRect.getActiveStyle("backgroundColor") || undefined);
  const [borderRadius, setBorderRadius] = useState(canvasRect.getActiveStyle("borderRadius") || undefined);
  const [boxShadow, setBoxShadow] = useState(canvasRect.getActiveStyle("boxShadow") || undefined);
  const [hasBackgroundImage, setHasBackgroundImage] = useState(canvasRect.getActiveStyle("backgroundImage") ? true : false);
  const [newBackgroundImage, setNewBackgroundImage] = useState(canvasRect.styleChange._backgroundImage || null);
  const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 });

  const [showPicker, setShowPicker] = useState(false);
  const [hasChange, setHasChange] = useState(canvasRect.styleChange._backgroundColor !== undefined || canvasRect.styleChange._borderRadius !== undefined || canvasRect.styleChange._boxShadow !== undefined || canvasRect.styleChange._backgroundImage);

  const colorPickerContainerRef = useRef(null);
  const backgroundImageUploadRef = useRef(null);

  const theme = useTheme();
  const isOwner = canvasData.creatorID === system_operations.getUserID();

  const handleBackgroundImageChange = (dataUrl) => {
    setHasChange(true);
    if (dataUrl) {
      canvasRect.styleChange._backgroundImage = dataUrl; // temp...
      setNewBackgroundImage(dataUrl.replace("data:", "").replace(/^.+,/, ""));
      setHasBackgroundImage(true);
    } else if (dataUrl !== undefined) {
      canvasRect.styleChange._backgroundImage = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/1Hc7jgAAAAASUVORK5CYII=`; // temp... nothing
      setNewBackgroundImage("remove"); // this is the signal to tell the backend to remove the current background image.
      setHasBackgroundImage(false);
    }
    if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
  };

  const uploadBackgroundImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function () {
      handleBackgroundImageChange(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const clearBackgroundImage = () => {
    handleBackgroundImageChange(null);
  }

  const refreshStyleChanges = () => {
    setHasBackgroundImage(canvasRect.getActiveStyle("backgroundImage") ? true : false); // this will tell if there is a background image currently.
    setNewBackgroundImage(canvasRect.styleChange._backgroundImage || null);
    setBackgroundColor(canvasRect.getActiveStyle("backgroundColor") || undefined);
    setBorderRadius(canvasRect.getActiveStyle("borderRadius") || undefined);
    setBoxShadow(canvasRect.getActiveStyle("boxShadow") || undefined);
    setHasChange(canvasRect.styleChange._backgroundColor !== undefined || canvasRect.styleChange._borderRadius !== undefined || canvasRect.styleChange._boxShadow !== undefined || canvasRect.styleChange._backgroundImage);
  }

  const cleanStyleChanges = () => {
    canvasRect.clearStyleChanges();
    setHasChange(false);
    refreshStyleChanges();
    if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
  }

  useEffect(() => {
    refreshStyleChanges();
  }, [canvasRect])


  const togglePicker = (event) => {
    if (!showPicker) {  // If the picker is about to be shown, update its position
      const buttonRect = event.target.getBoundingClientRect();
      let activeLeft = buttonRect.left;
      if (activeLeft > window.innerWidth / 2) {
        activeLeft -= 200;  // todo!!!
      }

      setPickerPosition({
        top: buttonRect.bottom + window.scrollY, // Adjust position for scrolling
        left: activeLeft + window.scrollX,
      });
    }
    setShowPicker(!showPicker);
  };

  const handleColorChange = (color) => {
    setHasChange(true);
    const colorStyle = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    canvasRect.styleChange._backgroundColor = colorStyle;
    if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
    setBackgroundColor(colorStyle);
  };

  const triggerBackgroundUploadInput = () => {
    if (backgroundImageUploadRef?.current) { backgroundImageUploadRef.current.click(); }
  }

  const handleBorderRadiusChange = () => {
    setHasChange(true);
    let currentTier = theme.flexRects.borderRadiusTiers.indexOf(borderRadius);
    if (currentTier === -1) { currentTier = theme.flexRects.borderRadiusTiers.length - 1; } // set to max index so next index will be 0
    const nextTier = (currentTier + 1) % theme.flexRects.borderRadiusTiers.length;
    // console.log("tier is ", currentTier, "next tier is ", nextTier);
    const newBorderRadius = theme.flexRects.borderRadiusTiers[nextTier];
    canvasRect.styleChange._borderRadius = newBorderRadius; // set the value to the next tier...
    if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
    setBorderRadius(newBorderRadius);
  };

  const handleBoxShadowChange = () => {
    setHasChange(true);
    let currentTier = theme.flexRects.boxShadowTiers.indexOf(boxShadow);
    if (currentTier === -1) { currentTier = theme.flexRects.boxShadowTiers.length - 1; } // set to max index so next index will be 0
    const nextTier = (currentTier + 1) % theme.flexRects.boxShadowTiers.length;
    // console.log("tier is ", currentTier, "next tier is ", nextTier);
    const newBoxShadow = theme.flexRects.boxShadowTiers[nextTier];
    canvasRect.styleChange._boxShadow = newBoxShadow; // set the value to the next tier...
    if (canvasRect.func?.styleRefreshCanvasCore) { canvasRect.func.styleRefreshCanvasCore(); }
    setBoxShadow(newBoxShadow);
  };

  const saveChange = async () => {
    system_operations.set_loading(true);
    setHasChange(false);
    const style = {};

    // the state stores different value. it stores the cleaned version of the data url.
    if (canvasRect.styleChange["_backgroundImage"]) style.backgroundImage = canvasRect.styleChange["_backgroundImage"]; // only add if there are changes.
    if (backgroundColor !== undefined) style.backgroundColor = backgroundColor;
    if (borderRadius !== undefined) style.borderRadius = borderRadius;
    if (boxShadow !== undefined) style.boxShadow = boxShadow;

    if (canvasRect.isNotUploaded) {
      for (let key in style) {
        canvasData.style[key] = style[key];
      }
    } else {
      delete canvasRect.styleChange["_backgroundImage"];
      delete canvasRect.styleChange["_backgroundColor"];
      delete canvasRect.styleChange["_borderRadius"];
      delete canvasRect.styleChange["_boxShadow"];

      for (let key in style) {
        canvasData.style[key] = style[key];
      }

      const dataJson = await RequestDataMaker.dataToUpdateFloatingCanvas(system_operations, canvasData.canvasID, { style: style })
      const result = await system_operations.restServerConnector.requestToUpdateFloatingCanvasStyles(dataJson);
      // console.log(result);
    }
    system_operations.set_loading(false);
  }

  const colorPickerElement = showPicker ? (
    <div style={{
      position: 'absolute',
      top: `${pickerPosition.top}px`, // Use state values
      left: `${pickerPosition.left}px`,
      zIndex: theme.zIndex.toolBar + 5 // todo!!!
    }}>
      <SketchPicker color={backgroundColor} onChangeComplete={handleColorChange} />
    </div>
  ) : null;

  return (
    <div>
      {
        isOwner && !canvasRect.isNotUploaded &&
        <GridContainer isVertical={isVertical} title='Style Settings'>
          <ToolItem icon={palette_white} tip="Background Color" onClick={togglePicker} selected={false}
            baseStyle={{ ...subItemBaseStyle }} hoverStyle={{ ...subItemHoverStyle }} selectedStyle={{ ...subItemSelectedStyle }} />

          <ToolItem icon={hasBackgroundImage ? picture_green : picture_white} tip="background Image" onClick={hasBackgroundImage ? clearBackgroundImage : triggerBackgroundUploadInput} selected={hasBackgroundImage}
            baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />

          <ToolItem icon={radius_white} tip="Border Radius" onClick={handleBorderRadiusChange} selected={false}
            baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />

          <ToolItem icon={coin_shadow_white} tip="Paper Shadow" onClick={handleBoxShadowChange} selected={false}
            baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} />

          <ToolItem icon={hasChange ? check_green : check_696969} tip="Save" onClick={saveChange} selected={false} disabled={!hasChange}
            baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} disabledStyle={{ ...subItemBaseStyle, color: "rgb(70, 70, 70)", backgroundColor: "rgba(70,70,70, 0.5)", cursor: "default" }} />

          <ToolItem icon={hasChange ? cross_red : cross_696969} tip="Discard Changes" onClick={cleanStyleChanges} selected={false} disabled={!hasChange}
            baseStyle={subItemBaseStyle} hoverStyle={subItemHoverStyle} selectedStyle={subItemSelectedStyle} disabledStyle={{ ...subItemBaseStyle, color: "rgb(70, 70, 70)", backgroundColor: "rgba(70,70,70, 0.5)", cursor: "default" }} />

          {ReactDOM.createPortal(colorPickerElement, document.body)}

          <input type="file" accept="image/*" id="uploadBackground" ref={backgroundImageUploadRef} onChange={(e) => { uploadBackgroundImage(e) }} style={{ display: "none" }} />
        </GridContainer>
      }
    </div>
  );
}


export const SingleShotDeleteButton = ({ rect, layerSet = 0, isVertical = true, subItemBaseStyle, subItemHoverStyle, subItemSelectedStyle }) => {
  const { system_operations } = useContext(SystemOperationsContext);
  const theme = useTheme();

  const deletionWarningButtons = {
    save: {
      name: 'Yes',
      color: "white",
      backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
      checkDisable: () => { return false },
      onClick: async () => {
        system_operations.set_loading(true);
        if (rect.typeName === "floatingCanvases") {
          await system_operations.coordinator.pendingCanvasSystem.deleteAPendingItem(rect);
        } else if (rect.typeName === "posts") {
          await system_operations.coordinator.pendingPostInstancesSystem.deleteAPendingItem(rect);
        } else if (rect.typeName === "buttons") {
          await system_operations.coordinator.pendingButtonsSystem.deleteAPendingItem(rect);
        }
        system_operations.set_loading(false);
        system_operations.setContentWindow(null)
      },
    },
    dump: {
      name: "No",
      color: "white",
      backgroundColor: `darken(${theme.backgroundColor.wallPageUI}, 10%)`,
      checkDisable: () => { return false },
      onClick: async () => { system_operations.setContentWindow(null) },
    }
  };

  const deleteWarning = () => {
    system_operations.setContentWindow((
      <div className='float-container'>
        Delete The Item?
        <ButtonGroups buttons={deletionWarningButtons} />
      </div>
    ));
  }

  return (
    <GridContainer isVertical={isVertical} title='Deletion'>
      <ToolItem icon={delete_bin_red} tip="Delete Paper" onClick={deleteWarning} selected={false}
        baseStyle={{ ...subItemBaseStyle, color: "#e06060", border: '2px solid #e04040' }}
        hoverStyle={{ ...subItemHoverStyle, color: "#ff0000", border: '2px solid #ff0000', backgroundColor: "rgba(255, 0, 0, 0.2)" }}
        selectedStyle={{ ...subItemSelectedStyle, border: '2px solid #ff0000', backgroundColor: "rgba(255, 0, 0, 0.5)" }}
      />
    </GridContainer>
  )
}
